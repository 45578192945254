import { PPE_SERVICE_BASE_URL, apiRoutes } from "../../../constants";
import { useCallback, useState } from "react";
import Axios from "axios";


const getChannelOptions = (channelData, excludeSelectOption) => {
    let channelArray = [];
    if (channelData?.length > 0) {
      _.forEach(_.orderBy(channelData, "name", "asc"), (channel) => {
        channelArray.push({
          label: channel.channelName,
          value: channel.channelId,
        });
      });
    }
    return channelArray;
  };

const usePPEChannelOptions = (excludeSelectOption = false) => {
  const [channels, setChannels] = useState([]);
  const [isChannelLoading, setisChannelLoading] = useState(false);
  const [prevSearchQuery, setPrevSearchQuery] = useState('');

  const getChannelSelectedOption = (channelIDValue) => {
    let channelObj = null;
    if (channels?.length > 0) {
      const selectedOption = _.find(channels, (channelData) => channelData?.value === channelIDValue);
      if(selectedOption) channelObj = {...selectedOption};
    }
    return channelObj;
  };

  const searchChannelOptions = (resolveCallback, searchQuery) => {
    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    let filter = {
        search: searchQuery
    };

    const searchURL = new URL(`${PPE_SERVICE_BASE_URL}${apiRoutes.ppeChannels}`);
    searchURL.search = new URLSearchParams(filter).toString();

    setisChannelLoading(true);
    Axios.get(searchURL.toString(), config).then(({data: apiData}) => {
      if(!apiData?.results?.length) resolveCallback(getChannelOptions([], excludeSelectOption));
      else {
        const newOptions = getChannelOptions(apiData?.results, excludeSelectOption);
        setChannels(newOptions);
        resolveCallback(newOptions);
      }
      setPrevSearchQuery(searchQuery);
    }).finally(() => {
      setisChannelLoading(false);
    })
  };
  const handleChannelSearch = useCallback((searchValue, onResolveCallBack) => {
    // if(prevSearchQuery === searchValue) {
    //   onResolveCallBack(channels)
    //   return;
    // };
    if(String(searchValue ?? "")?.length < 3) {
        onResolveCallBack(getChannelOptions([]));
        return;
    }
    
    if(_.inRange(String(searchValue ?? "").length, 1, 3)) {
      if(String(prevSearchQuery ?? "").length >= 3) {
        searchChannelOptions(onResolveCallBack, '');
        return;
      } else {
        onResolveCallBack(channels);
        return;
      }
    };
    searchChannelOptions(onResolveCallBack, searchValue);
  }, [channels, prevSearchQuery]);

  const getAllChannels = useCallback(() => {
    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    const searchURL = new URL(`${PPE_SERVICE_BASE_URL}${apiRoutes.ppeChannels}`);
    setisChannelLoading(true);
    Axios.get(searchURL.toString(), config).then(({data: apiData}) => {
      const newOptions = getChannelOptions(apiData?.results, excludeSelectOption);
      setChannels(newOptions);
    }).finally(() => {
      setisChannelLoading(false);
    })

  }, [])

  return {
    channels,
    handleChannelSearch,
    getChannelSelectedOption,
    getAllChannels,
    isChannelLoading
  };
};

export default usePPEChannelOptions;
