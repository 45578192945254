import React, { useContext, useEffect } from "react";
import _ from "lodash";
import Select from "react-select";
import { AppContext } from "../../../context/AppContext";
// import { RiRefreshFill } from "react-icons/ri";
import CustomCalendar from "../../CustomCalender";
import moment from "moment";
import { useRef } from "react";
import { checkModuleGeneralPermission } from "../../../helpers/helpers";
import useStorage from "../../../helpers/useStorage";
import useCompanyOptions from "../CommonDeliverables/useCompanyOptions";
import useContractOptions from "../CommonDeliverables/useContractOptions";
import LazyLoadedSelect from "../../LazyLoadedSelect/LazyLoadedSelect";
import ClearButton from "../ClearButton/ClearButton";
import {
  defaultSelectOption,
  selectOptionsDefaultOptionAppender,
  defaultFromDateFilterValue,
  defaultToDateFilterValue,
  formatValueWithDefaultOption,
} from "../../../helpers/utils";
import { reportDatewoTimeFormat } from "../../../constants";
import CustomSelectClearIndicator from "../CustomSelectClearIndicator/CustomSelectClearIndicator";

const ReportFilter = ({
  onSearchInputChange,
  onNationalityChange,
  onCompanyChange,
  onStatusChange,
  // statusList,
  setFilterChanged,
  setCurrentPageNumber,
  onApplicantTypeChange,
  onBeforeDateChange,
  handleUpdateKey,
  onAfterDateChange,
  nationality,
  onContractorChange,
  onExport,
  buttonVisibility,
}) => {
  const { setToStorage, getFromStorage, removeFromStorage } = useStorage();
  const { userRoleDetails } = useContext(AppContext);
  const {
    companies,
    handleCompanySearch,
    loadNextCompanyOptionsPage,
    isCompanyLoading,
  } = useCompanyOptions();
  const {
    contracts,
    handleContractSearch,
    loadNextContractOptionsPage,
    isContractLoading,
  } = useContractOptions();

  const [searchText, setSearchText] = React.useState(
    getFromStorage("applicant_profile_report_filter_search") ?? ""
  );
  const [nation, setNation] = React.useState(
    getFromStorage("applicant_profile_report_filter_nation") ??
      defaultSelectOption()
  );
  const [status, setStatus] = React.useState(
    getFromStorage("applicant_profile_report_filter_status") ?? ""
  );
  const [company, setCompany] = React.useState(
    getFromStorage("applicant_profile_report_filter_company") ??
      defaultSelectOption()
  );
  const [contract, setContract] = React.useState(
    getFromStorage("applicant_profile_report_filter_contract") ??
      defaultSelectOption()
  );
  const [type, setType] = React.useState(
    getFromStorage("applicant_profile_report_filter_type") ?? ""
  );
  //const delayedQuery = React.useCallback(_.debounce(q => onSearchInputChange(q), 500), []);

  const { pagePermissions } = React.useContext(AppContext);
  // const parseToSelectFormat = (options, keys) => _.map(options, option => ({label:option[keys.label] , value: option[keys.value]}));

  const parseToSelectFormat = (options, keys) => {
    let res = _.map(options, (option) => ({
      label: option[keys.label],
      value: option[keys.value],
    }));
    res.unshift(defaultSelectOption());
    return res;
  };

  // const updateSerachText = value => {
  //     setSearchText(value);
  // };

  const [beforeDate, setBeforeDate] = React.useState(
    getFromStorage("applicant_profile_report_filter_before_date") ??
      defaultFromDateFilterValue(reportDatewoTimeFormat)
  );
  const [afterDate, setAfterDate] = React.useState(
    getFromStorage("applicant_profile_report_filter_after_date") ??
      defaultToDateFilterValue(reportDatewoTimeFormat)
  );
  const afterDateRef = useRef(null);
  const beforeDateRef = useRef(null);

  const applyFilter = () => {
    setToStorage(
      {
        applicant_profile_report_filter_nation: nation ?? "",
        applicant_profile_report_filter_status: status ?? "",
        applicant_profile_report_filter_type: type ?? "",
        applicant_profile_report_filter_company: company ?? "",
        applicant_profile_report_filter_contract: contract ?? "",
        applicant_profile_report_filter_before_date: beforeDate ?? "",
        applicant_profile_report_filter_after_date: afterDate ?? "",
        applicant_profile_report_filter_search: searchText ?? "",
      },
      false
    );

    onNationalityChange(nation?.value ?? undefined);
    onStatusChange(status?.value ?? undefined);
    onApplicantTypeChange(type?.value ?? undefined);
    onContractorChange(contract?.value ?? undefined);
    onCompanyChange(company?.value ?? undefined);
    onBeforeDateChange(beforeDate ?? undefined);
    onAfterDateChange(afterDate ?? undefined);
    onSearchInputChange(searchText);
    setCurrentPageNumber(1);
    handleUpdateKey();
  };
  const onFilterChange = (option) => {
    const { name, value, label } = option;

    setFilterChanged(true);
    switch (name) {
      case "nationality":
        setNation({ label, value });

        break;
      case "status":
        setStatus({ label, value });

        break;
      case "type":
        setType({ label, value });

        break;
      case "company":
        setCompany({ label, value });

        break;
      case "contract":
        setContract({ label, value });

        break;
      case "before":
        setBeforeDate(value ? moment(value).format("YYYY-MM-DD") : undefined);

        break;
      case "after":
        setAfterDate(value ? moment(value).format("YYYY-MM-DD") : undefined);

        break;
      default:
        break;
    }
  };

  const handleSingleFieldReset = (field) => {
    switch (field) {
      case "search":
        setSearchText("");
        return;
      case "beforeDate":
        setBeforeDate(undefined);
        beforeDateRef.current.handleChange(undefined);
        onBeforeDateChange(undefined);
        return;
      case "afterDate":
        setAfterDate(undefined);
        afterDateRef.current.handleChange(undefined);
        onAfterDateChange(undefined);
        return;
      default:
        return;
    }
  };

  const resetFilterOptions = () => {
    setNation(defaultSelectOption());
    setFilterChanged(false);
    setStatus(null);
    onNationalityChange(undefined);
    onContractorChange(undefined);
    onStatusChange(undefined);
    setCompany(defaultSelectOption());
    setContract(defaultSelectOption());
    onCompanyChange(undefined);
    setType(null);
    onApplicantTypeChange(undefined);
    onSearchInputChange("");
    setSearchText("");
    setBeforeDate(defaultFromDateFilterValue(reportDatewoTimeFormat));

    setAfterDate(defaultToDateFilterValue(reportDatewoTimeFormat));
    onBeforeDateChange(defaultFromDateFilterValue(reportDatewoTimeFormat));
    beforeDateRef.current.handleChange(defaultFromDateFilterValue());
    afterDateRef.current.handleChange(defaultToDateFilterValue());
    onAfterDateChange(defaultToDateFilterValue(reportDatewoTimeFormat));
    setCurrentPageNumber(1);
    removeFromStorage();
    // handleUpdateKey();
  };

  return (
    <div className="reports-filter-section pb-3 position-relative">
      <div className="row mb-2 ">
        <div className="col-12 col-sm-6 col-md-3 mb-2">
          <span>Company</span>
          <LazyLoadedSelect
            className="cutomSelect"
            classNamePrefix="react-select"
            loadNextOptions={loadNextCompanyOptionsPage}
            name="company"
            onChange={(opt) =>
              onFilterChange({
                name: "company",
                value: opt?.value,
                label: opt?.label,
              })
            }
            onInputChange={(value) => handleCompanySearch(value)}
            options={selectOptionsDefaultOptionAppender(companies)}
            placeholder="Select"
            value={company}
            isLoading={isCompanyLoading}
            isClearable={true}
            defaultValue={defaultSelectOption()}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-3 mb-2">
          <span>Contract</span>
          <LazyLoadedSelect
            className="cutomSelect"
            classNamePrefix="react-select"
            loadNextOptions={loadNextContractOptionsPage}
            name="contract"
            onChange={(opt) =>
              onFilterChange({
                name: "contract",
                value: opt?.value,
                label: opt?.label,
              })
            }
            onInputChange={(value) => handleContractSearch(value)}
            options={selectOptionsDefaultOptionAppender(contracts)}
            placeholder="Select"
            value={contract}
            isLoading={isContractLoading}
            defaultValue={defaultSelectOption()}
            isClearable={true}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-2">
          <span>Report Range</span>
          <div className="d-flex w-100">
            <div
              className={`w-100 ${
                !beforeDate && afterDate ? "hasValidationError" : ""
              }`}
            >
              <CustomCalendar
                maxDate={afterDate ? new Date(afterDate) : moment().toDate()}
                ref={beforeDateRef}
                selected={beforeDate === undefined ? "" : new Date(beforeDate)}
                onChange={(date) =>
                  onFilterChange({ name: "before", value: date, label: "" })
                }
                className="form-control"
                placeholderText="YYYY-MM-DD"
                dateFormat="YYYY-MM-DD"
                isClearable={true}
                onClear={() => handleSingleFieldReset("beforeDate")}
              />
            </div>
            <div className="px-2 d-flex align-items-center"> - </div>
            <div
              className={`w-100 ${
                beforeDate && !afterDate ? "hasValidationError" : ""
              }`}
            >
              <CustomCalendar
                minDate={beforeDate === undefined ? "" : new Date(beforeDate)}
                ref={afterDateRef}
                maxDate={moment().toDate()}
                selected={afterDate === undefined ? "" : new Date(afterDate)}
                onChange={(date) =>
                  onFilterChange({ name: "after", value: date, label: "" })
                }
                className="form-control"
                placeholderText="YYYY-MM-DD"
                dateFormat="YYYY-MM-DD"
                isClearable={true}
                onClear={() => handleSingleFieldReset("afterDate")}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-3 mb-2">
          <span>Nationality</span>
          <Select
            className="cutomSelect"
            classNamePrefix="react-select"
            value={formatValueWithDefaultOption(nation)}
            options={parseToSelectFormat(nationality, {
              label: "display_name",
              value: "value",
            })}
            onChange={(opt) =>
              onFilterChange({
                name: "nationality",
                value: opt?.value,
                label: opt?.label,
              })
            }
            name="nationality"
            placeholder="Select"
            isClearable={true}
            components={{ ClearIndicator: CustomSelectClearIndicator }}
            defaultValue={defaultSelectOption()}
          />
        </div>

        <div className="col-12 col-sm-12 col-md-6 col-lg-3 mb-2">
          <span>Search</span>
          <div
            className="report-search-container searchHolder  mb-0"
            // onSubmit={(e) => onSubmitSearch(e)}
          >
            <input
              type="text"
              className="form-control"
              name="search"
              value={searchText}
              id="search"
              placeholder="Search by Person name or Profile ID"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <ClearButton
              onClick={() => handleSingleFieldReset("search")}
              hideClear={String(searchText ?? "").length == 0}
            />
            {/* <button className="btn btn-primary" type="submit">
              <span className="simple-icon-magnifier"></span>
            </button> */}
          </div>
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span className="show-lg">&nbsp;</span>
          <div className="btn-cover ">
            <button
              onClick={() => applyFilter()}
              className="btn btn-secondary rounded-0 ml-1 "
            >
              Apply
            </button>
            <button
              onClick={resetFilterOptions}
              className="btn btn-secondary reset rounded-0 ml-1 "
            >
              <i className="iconsminds-repeat-2"></i>
            </button>
            {checkModuleGeneralPermission(
              userRoleDetails,
              "man-hour-report",
              "export_report"
            ) && (
              <button
                onClick={onExport}
                className="btn btn-secondary rounded-0 ml-1"
                disabled={buttonVisibility}
              >
                Export
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportFilter;
