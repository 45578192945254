import React from "react";

const DashboardLoader = () => {
  return (
    <div>
      {Array.from({ length: 6 }).map((_, rowIndex) => (
        <div key={rowIndex} className="row">
          {Array.from({ length: (rowIndex % 2 === 0) ? 2 : 4 }).map(
            (_, index) => (
              <div
                key={index}
                className={`col-xl-${(rowIndex % 2 === 0) ? 6 : 3} mb-4 col-lg-${
                  rowIndex === 1 ? 6 : 3
                }`}
              >
                <div className="card mat-card  min-h-full">
                  <div className="card-header">
                    <div className="skeleton w-80"></div>
                  </div>
                  <div className="card-body">
                    <div className="skeleton w-80"></div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ))}
    </div>
  );
};

export default DashboardLoader;
