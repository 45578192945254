/* eslint react-hooks/exhaustive-deps: 0 */
import { useClient, useQuery } from "jsonapi-react";
import React from "react";
import _ from "lodash";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import ExportButton from "../../components/common/Export/Export";
import SearchBar from "../../components/common/Search/Search";
import TableExpandableComponent from "../../components/common/TableExpandableComponent/TableExpandableComponent";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { VW_ROUTES, apiRoutes } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { getIdNumber } from "../../helpers/helpers";
import SelectApplicants from "../../components/common/SelectApplicants/SelectApplicants";
const applicantTypes = [
  { label: "Select", value: "" },
  { label: "Visitor", value: "visitor" },
  { label: "Staff", value: "staff" },
  { label: "Delivery Adhoc", value: "delivery_adhoc" },
  { label: "Delivery Regular", value: "delivery_regular" },
];
const Notification = ({ location }) => {
  const { setToStorage, getFromStorage } = useStorage();
  const [exportData, setexportData] = React.useState({});
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("overstay_rows_perpage") || 10
  );
  const [applicantType, setApplicantType] = React.useState(undefined);
  const [filters, setFilters] = React.useState({ applicantType: undefined });
  const [searchQueryString, setSearchQueryString] = React.useState("");
  const [disableExport, setDisableExport] = React.useState(true);
  const [filterApplied, setFilterApplied] = React.useState(false);

  const {
    initialPageParams,
    updateInitialPageParams,
    currentUser,
    setPageUrlParams,
  } = React.useContext(AppContext);

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(1);

  const token = localStorage.getItem("access_token");
  const history = useHistory();
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const {
    data: applicantsData,
    meta,
    isFetching,
  } = useQuery(
    [
      apiRoutes.createProfileData,
      {
        filter: {
          over_night_stay: "True",
          search: searchQueryString,
          type: applicantType,
        },
        page: paginationSettings,
        include: "company,contract,applicant_photo",
      },
    ],
    { client }
  );
  const getAppTypeValues = (v) => {
    let appTypeObj = { label: "Select", value: "" };
    if (applicantTypes?.length > 0) {
      applicantTypes.forEach((item) => {
        if (item.value === v) {
          appTypeObj = {
            label: item.label,
            value: item.value,
          };
        }
      });
    }
    return appTypeObj;
  };
  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }

    setexportData({
      page: "over_night_stay",
      filters: {
        search: searchQueryString,
        type: applicantType,
      },
    });
  }, []);

  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(initialPageParams.initialPageNumber);
      setactivePage(initialPageParams.initialPageNumber);
      setSearchQueryString(initialPageParams.initialSearchKey);
      setApplicantType(
        initialPageParams.applicantType
          ? initialPageParams.applicantType
          : undefined
      );
    }
  }, [initialPageParams]);

  React.useEffect(() => {
    setexportData({
      page: "over_night_stay",
      filters: {
        search: searchQueryString,
        type: applicantType,
      },
    });
  }, [searchQueryString, applicantType]);

  const viewApplicant = (id, type) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${id}&type=${type}&from=dashboard`,
      state: {
        from: history.location?.pathname,
      },
    });
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewApplicant(mode.applicant.id, mode.applicant.applicantType);
    }
  };
  // const parseIdentificationType = user => {
  //   return user.nricAlpha !== "" ? `${user.nricNumber}${user.nricAlpha}(NRIC)` : user.finAlpha !== "" ? `${user.finNumber}${user.finAlpha}(FIN)` : `${user.passport}(Passport)`
  // };

  const columns = [
    {
      name: "Full Name",
      selector: (personDetail) => personDetail.fullName,
      sortable: true,
      style: "text-transform:capitalize",
      cell: (applicant) => (
        <ProfileCard
          data={applicant}
          mode="overStay"
          actions={["view"]}
          actionsToParent={(mode) => getEvent({ mode, applicant })}
        />
      ),
    },
    {
      name: "Type",
      maxWidth: 150,
      cell: (applicant) => (
        <span className="text-capitalize">
          {applicant.applicantType.replace(/_/g, " ")}
        </span>
      ),
      sortable: false,
      hide: "md",
    },
    {
      name: "ID Number",
      sortable: false,
      hide: "md",
      minWidth: "150px",
      maxWidth: 250,
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {getIdNumber(applicant)}
        </span>
      ),
    },
    // {
    //   name: 'Contract',
    //   selector: 'contract.name',
    //   sortable: true
    // },
    {
      name: "Company",
      hide: "md",
      cell: (applicant) => (
        <>
          <span className="text-capitalize">
            {applicant?.company ? applicant?.company : "--"}
          </span>
        </>
      ),
    },
    {
      name: "Contractor",
      sortable: false,
      left: true,
      minWidth: "200px",
      cell: (applicant) => (
        <>
          <span className="text-capitalize">
            {" "}
            {_.replace(applicant.contract?.name, "_", " ")}{" "}
          </span>
        </>
      ),
      hide: "md",
    },
    {
      name: "Designation",
      selector: "designation",
      sortable: false,
      hide: "md",
    },
    // {
    //   name: 'Status',
    //   selector: 'status',
    //   sortable: true
    // },
    {
      name: "Actions",
      sortable: false,
      cell: (applicant) => (
        <>
          <span
            className="simple-icon-eye user-role-action-icons view"
            onClick={() => viewApplicant(applicant.id, applicant.applicantType)}
          ></span>{" "}
        </>
      ),
      width: 50,
      right: true,
      hide: "md",
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    const setParams = {
      page: page,
      searchQueryString: searchQueryString,
      initialPageParams: initialPageParams,
      applicantType: applicantType,
    };
    setPageUrlParams(setParams);
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({ overstay_rows_perpage: rows }, false);
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    setFilters({ ...filters, search: searchQuery });
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setactivePage(1);
    const setParams = {
      page: 1,
      searchQueryString: searchQuery,
      initialPageParams: initialPageParams,
      applicantType: applicantType,
    };
    setPageUrlParams(setParams);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }
      const applicantType = window.location.hash.split("applicant=")[1];
      if (applicantType) {
        const applicant = applicantType.split("&")[0];
        setApplicantType(applicant);
      } else {
        setApplicantType(undefined);
      }
      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };

  const searchByApplicantType = (e) => {
    if (e !== "") {
      setFilters({ ...filters, applicantType: e.value });
    } else {
      setFilters({ ...filters, applicantType: undefined });
    }
  };

  const FilterSection = () => {
    return (
      <div className="advance-filter">
        <div>
          <span>Category</span>
          <div className="mb-2">
            <SelectApplicants
              searchByApplicantType={(e) => searchByApplicantType(e)}
              applicantType={
                filters?.applicantType
                  ? getAppTypeValues(filters?.applicantType)
                  : { label: "Select", value: "" }
              }
            />
          </div>
        </div>
      </div>
    );
  };
  React.useEffect(() => {
    if (applicantsData?.length > 0) {
      setDisableExport(false);
    } else {
      setDisableExport(true);
    }
  }, [applicantsData]);

  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  const handleApplyFilter = (e) => {
    const setParams = {
      page: 1,
      searchQueryString: filters.search,
      initialPageParams: initialPageParams,
      applicantType: filters.applicantType,
    };
    setPageUrlParams(setParams);
    setFilterApplied(true);
  };
  const handleResetFilter = (e) => {
    setSearchQueryString("");
    setFilters({ applicantType: undefined });
    const setParams = {
      page: 1,
      searchQueryString: "",
      initialPageParams: initialPageParams,
      applicantType: undefined,
    };
    setPageUrlParams(setParams);
    setFilterApplied(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header withExport">
            <h1>Over Stay Applicants Lists</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <div>
                    <FilterSection />

                    <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                      <button
                        className="btn btn-primary mr-2"
                        onClick={(e) => {
                          handleResetFilter(e);
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-secondary m-0"
                        onClick={(e) => {
                          handleApplyFilter(e);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`tableFilterButton rightAlign  ${
                  filterApplied ? "btn-secondary" : ""
                }`}
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                {/* <span></span>
             <span></span>
             <span></span> */}
                <i className="iconsminds-filter-2"></i>
              </div>
              <ExportButton
                exportDetails={exportData}
                disableExport={disableExport}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                isFetching
                  ? "card-body dataTableOuter ProfileWidth table-loader"
                  : "card-body dataTableOuter ProfileWidth"
              }
            >
              <DataTable
                // subHeader={true}
                // subHeaderComponent={<><FilterSection/><SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/> <ExportButton exportDetails={exportData}/></>}

                columns={columns}
                data={applicantsData}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                progressPending={isFetching}
                progressComponent={<p>&nbsp;</p>}
                paginationTotalRows={meta && meta.pagination.count}
                paginationDefaultPage={activePage}
                expandableRows
                expandableRowsComponent={TableExpandableComponent}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
