import React from "react";
import { useHistory } from "react-router-dom";
import NotificationCardItem from "../NotificationCardItem/NotificationCardItem";
const LivePeopleCountWidget = ({ widgetData, slug, title, hasLink, route }) => {
  const history = useHistory();
  const handleRedirection = () => {
    if (hasLink) {
      history.push(route);
    }
  };



    return (
      <NotificationCardItem
        skin="peopleCounter"
        icon={"iconsminds-mens"}
        title={title}
        slug={slug}
        widgetData={widgetData}
        handleRedirection={handleRedirection}
      />
    );
};

export default LivePeopleCountWidget;
