import React from "react";
import axios from "axios";
import {
  API_BASE_URL,
  DEVICE_SERVICE_URL,
  apiRoutes,
} from "../../../constants";
import Loader from "../Loader/Loader";

const ExportButton = ({ exportDetails, disableExport = false }) => {
  const token = localStorage.getItem("access_token");
  const [apiUrlSpec, setApiUrlSpec] = React.useState("");
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showLoader, setshowLoader] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");

  React.useEffect(() => {
    if (exportDetails.page === "expiry") {
      let filter;
      if (exportDetails.filters.search && exportDetails.filters.search !== "") {
        filter = `filter[${
          exportDetails.params.list === "cidb-expiry"
            ? "person_cat"
            : "category"
        }]=${exportDetails.params.type}&include=company&filter[search]=${
          exportDetails.filters?.search
        }`;
      } else {
        filter = `filter[${
          exportDetails.params.list === "cidb-expiry"
            ? "person_cat"
            : "category"
        }]=${exportDetails.params.type}&include=company`;
      }
      if (exportDetails.params.list === "permit-expiring") {
        let apiSpecs = `${apiRoutes.exportWpExpiryList}?&${filter}`;
        setApiUrlSpec(apiSpecs);
      } else if (exportDetails.params.list === "training") {
        let apiSpecs = `${apiRoutes.exportTrainingExpiryList}?&${filter}`;
        setApiUrlSpec(apiSpecs);
      } else if (exportDetails.params.list === "permit-expired") {
        let apiSpecs = `${apiRoutes.exportWpExpiredList}?&${filter}`;
        setApiUrlSpec(apiSpecs);
      } else if (exportDetails.params.list === "cidb-expiry") {
        let apiSpecs = `${apiRoutes.exportCIDBExpiryList}?filter[ic_type]=cidb&${filter}`;
        setApiUrlSpec(apiSpecs);
      }
    } else if (exportDetails.page === "pending_details") {
      let filter;
      if (exportDetails.filters.search && exportDetails.filters.search !== "") {
        filter = `filter[type]=${exportDetails.params.type}&filter[search]=${exportDetails.filters?.search}`;
      } else {
        filter = `filter[type]=${exportDetails.params.type}`;
      }
      let apiSpecs = `${apiRoutes.exportPendingList}?&${filter}`;
      setApiUrlSpec(apiSpecs);
    } else if (exportDetails.page === "over_night_stay") {
      let filter;
      if (
        (exportDetails.filters.search && exportDetails.filters.search !== "") ||
        (exportDetails.filters.type && exportDetails.filters.type !== "")
      ) {
        if (exportDetails.filters.type) {
          filter = `filter[over_night_stay]=True{&filter[search]=${
            exportDetails.filters.search ?? ""
          }&filter[type]=${exportDetails.filters.type}`;
        } else {
          filter = `filter[over_night_stay]=True&filter[search]=${exportDetails.filters.search}`;
        }
      } else {
        filter = `filter[over_night_stay]=True`;
      }

      let apiSpecs = `${apiRoutes.exportOverStayList}?${filter}`;
      setApiUrlSpec(apiSpecs);
    } else if (exportDetails.page === "validity_expiring") {
      let filter = "";
      let filterItems = exportDetails?.filters;
      for (const [, [key, value]] of Object.entries(
        Object.entries(filterItems)
      )) {
        if (value !== "" && value) {
          filter += `&filter[${key}]=${value}`;
        }
      }

      let apiSpecs = `${apiRoutes.exportExpiringPasses}?${filter}`;
      setApiUrlSpec(apiSpecs);
    } else if (exportDetails.page === "live_count_by_company") {
      let filter;
      if (exportDetails.filterType === "all") {
        filter = `${
          exportDetails.filters?.search || exportDetails.filters?.search !== ""
            ? "filter[search]=" + exportDetails.filters.search + "&"
            : ""
        }`;
      } else {
        filter = `filter[company]=${exportDetails.companyId}${
          exportDetails.filters?.search || exportDetails.filters?.search !== ""
            ? "&filter[search]=" + exportDetails.filters.search
            : ""
        }${
          exportDetails.filters?.type ||
          exportDetails.filters?.type !== undefined
            ? "&filter[type]=" + exportDetails.filters.type
            : ""
        }&`;
      }

      let apiSpecs = `${apiRoutes.exportLiveCountWithFilter}?${filter}action=${
        exportDetails.filterType === "all" ? "__all__" : "company"
      }`;
      setApiUrlSpec(apiSpecs);
    } else if (exportDetails.page === "lpr") {
      let filter = "";

      if (exportDetails.filter) {
        for (const [, [key, value]] of Object.entries(
          Object.entries(exportDetails.filter)
        )) {
          if (value !== "" && value && key) {
            filter += `&${key}=${value}`;
          }
        }
      }

      let apiSpecs = `${DEVICE_SERVICE_URL}/lpr/events/export?${filter}
      `;
      setApiUrlSpec(apiSpecs);
    } else if (exportDetails.page === "entry-pass") {
      let filter = "";
      if (exportDetails.filters) {
        for (const [, [key, value]] of Object.entries(
          Object.entries(exportDetails.filters)
        )) {
          if (value !== "" && value && key) {
            filter += `&filter[${key}]=${value}`;
          }
        }
      }
      let apiSpecs = `${apiRoutes.exportPendingPasses}?filter[status]=pending${filter}`;
      setApiUrlSpec(apiSpecs);
    }
  }, [exportDetails]);

  const onExport = () => {
    setshowLoader(true);

    const options = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      url: apiUrlSpec,
    };
    axios(options)
      .then((response) => {
        setshowLoader(false);
        setShowSuccessModal(true);
        setShowSuccessMessage(
          response?.data?.data?.message ?? "Export Success"
        );
      })
      .catch((error) => {
        setshowLoader(false);
        setShowSuccessModal(true);
        setShowSuccessMessage(
          error?.response?.data?.errors?.message ??
            "Export Failed! Something went wrong!"
        );
      });
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };

  return (
    <>
      <button
        className="btn btn-secondary rounded-0 ml-2"
        onClick={() => onExport()}
        disabled={disableExport}
      >
        Export
      </button>

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>

      {showLoader && <Loader />}
    </>
  );
};

export default ExportButton;
