import React, { useState } from "react";
import Slider from "react-slick";
import { useClient } from "jsonapi-react";
import axios from "axios";
import _ from "lodash";
import { API_BASE_URL, apiRoutes } from "../../constants";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import profileimagePlaceholder from "../../../src/assets/images/profileimagePlaceholder.jpg";

import { AppContext } from "../../context/AppContext";
import { Camera } from "react-camera-pro";
import IDLoader from "../common/Loader/IDLoader";

const CaptureImageComponentWithCrop = ({
  status,
  profileImages,
  applicantId,
  onPictureCapture,
}) => {
  const camera = React.useRef(null);
  const { currentUser, updateCurrentUser } =
    React.useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  // const [camOrientation, setCamOrientation] = React.useState(0);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  React.useEffect(() => {
    if (!status) {
      let stream = camera.video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      camera.video.srcObject = null;
    }
  }, [status]);

  //const errBack = () => {};

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const uploadProfileImages = (files) => {
    setIsLoading(true);
    let formData = new FormData();
    // formData.append("applicant", applicantId);

    _.map(files, (file) => {
      formData.append("avatar", file);
    });

    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    const url = apiRoutes.avatar.replace("{profileId}", applicantId);
    axios
      .put(url, formData, config)
      .then((response) => {
        //console.log(response);
        localStorage.setItem("userUpdated", true);
        localStorage.setItem(
          "userAvatar",
          response?.data?.data?.attributes?.avatar
        );
        updateCurrentUser({
          ...currentUser,
          avatar: response?.data?.data?.attributes?.avatar,
        });
        setIsLoading(false);
        onPictureCapture();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const captureImageFromCam = async () => {
    setIsLoading(true);
    const photo = camera.current && camera.current.takePhoto();

    urltoFile(photo, `${_.uniqueId("image-")}.jpeg`, "image/jpeg").then(
      async (file) => {
        let formData = new FormData(); //formdata object
        // formData.append("applicant", applicantId); //append the values with key, value pair
        formData.append("avatar", file);
        const config = {
          headers: {
            Authorization: `JWT ${token}`,
            "content-type": "multipart/form-data",
          },
        };
        const url = apiRoutes.avatar.replace("{profileId}", applicantId);
        axios
          .put(url, formData, config)
          .then((response) => {
            localStorage.setItem("userUpdated", true);
            localStorage.setItem(
              "userAvatar",
              response?.data?.data?.attributes?.avatar
            );
            updateCurrentUser({
              ...currentUser,
              avatar: response?.data?.data?.attributes?.avatar,
            });
            setIsLoading(false);
            onPictureCapture();
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    );
  };

  return (
    <>
      <IDLoader className="fade-background" open={isLoading}/>
      <div className="camera-element-wrapper embed-responsive embed-responsive-16by9">
        {status && <Camera ref={camera} />}

        <div onClick={() => !isLoading && captureImageFromCam()} className="capture-btn">
          <i className="simple-icon-camera"></i>
        </div>
      </div>
      <div className="row">
        <div className="customFileUpload ml-auto mr-auto mt-2">
          Choose File
          <input
            type="file"
            className="hide_file"
            onChange={(e) => uploadProfileImages(e.target.files)}
            accept="image/*"
            id="profileImages"
            name="profileImages"
            disabled={isLoading}
          />
        </div>
        <div className="col-12">
          <div className="icon-cards-row row">
            <Slider {...settings}>
              {_.map(profileImages, (profileImage) => (
                <div
                  key={_.uniqueId("prof-image-")}
                  className="captured-profile-image"
                >
                  <img
                    className="visitor-image"
                    src={profileImage?.avatar}
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = profileimagePlaceholder;
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaptureImageComponentWithCrop;
