import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import CustomSelectClearIndicator from "../common/CustomSelectClearIndicator/CustomSelectClearIndicator";
import { formatValueWithDefaultOption } from "../../helpers/utils";

const LazyLoadedSelect = ({
  loadNextOptions = () => {},
  options = null,
  className = "",
  classNamePrefix = "",
  value = null,
  onChange = () => {},
  name = "",
  placeholder = "Select",
  onInputChange = () => {},
  isLoading = false,
  ...props
}) => {
  const selectRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let _prevInputValue = null;

  useEffect(() => {
    loadNextOptions();
  }, []);

  useEffect(() => {
    const menuListRef = selectRef.current.menuListRef;
    if (!(menuListRef?.scrollHeight > menuListRef?.clientHeight)) return;
    if (
      !menuListRef ||
      !options ||
      !options.length ||
      !selectRef.current.menuListRef?.childNodes?.length
    )
      return;
    const intersectionObserver = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].isIntersecting && !isLoading && isMenuOpen)
          loadNextOptions();
      },
      {
        root: menuListRef,
        threshold: 0.5,
      }
    );
    const lastItemDiff = props?.isMulti
      ? (value?.length ?? 0) + 1
      : props?.hideSelectedOptions
      ? 2
      : 1;
    const targetElement =
      selectRef.current.menuListRef.childNodes[options.length - lastItemDiff];
    if (targetElement && typeof targetElement == "object")
      intersectionObserver.observe(targetElement);
    return () => {
      intersectionObserver.disconnect();
    };
  }, [selectRef.current, options, isMenuOpen, isLoading]);

  return (
    <Select
      options={options}
      className={className}
      classNamePrefix={classNamePrefix}
      value={!props?.isMulti ? formatValueWithDefaultOption(value) : value}
      onChange={onChange}
      name={name}
      placeholder={placeholder}
      onInputChange={(newValue) => {
        if (newValue != _prevInputValue) onInputChange(newValue);
        _prevInputValue = newValue;
      }}
      ref={selectRef}
      onMenuClose={() => setIsMenuOpen(false)}
      onMenuOpen={() => setIsMenuOpen(true)}
      components={{ ClearIndicator: CustomSelectClearIndicator }}
      {...props}
    />
  );
};

export default LazyLoadedSelect;
