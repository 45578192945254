/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from "react";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import _ from "lodash";
import DataTable from "react-data-table-component";
import { AppContext } from "../../context/AppContext";
import { addBodyClass, logout, removeBodyClass } from "../../helpers/utils";
import UserFormComponent from "../../components/UserFormComponent/UserFormComponent";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import ModalRightComponent from "../../components/common/ModalRightComponent/ModalRightComponent";
import SearchBar from "../../components/common/Search/Search";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import {
  checkModuleAddPermission,
  checkModuleDeletePermission,
  checkModuleEditPermission,
  getCurrentWindowParams,
  checkModuleGeneralPermission,
  formatTextWithFallback,
  validatorModule,
} from "../../helpers/helpers";
import { API_BASE_URL, VW_ROUTES, apiRoutes } from "../../constants";
import ContextMenuContentWrapper from "../../components/common/ContextMenuContentWrapper";
import Axios from "axios";
import PillText from "../../components/PillText/PillText";
import { useHistory } from "react-router-dom";

const UserManagement = ({ location }) => {
  const validationFormat = [
    {
      field: "fullName",
      isRequired: true,
      fieldName: "Full Name",
    },
    {
      field: "email",
      isEmail: true,
      isRequired: true,
      fieldName: "Email",
    },
    {
      field: "role",
      isRequired: true,
      fieldName: "Role",
    },
  ];
  const initialState = {
    fullName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: { label: "Role", value: "" },
    contractsId: [],
  };
  const initialErrorState = {
    fullName: "",

    email: "",

    role: "",
  };
  const { addToast } = useToasts();

  const {
    showRightModal,
    manageRightModal,
    showModal,
    manageModal,
    pagePermissions,
    initialPageParams,
    updateInitialPageParams,
    setPageUrlParams,
    userRoleDetails,
    currentUser,
  } = React.useContext(AppContext);
  const [mode, setMode] = React.useState("");

  const [currentUserId, setCurrentUserId] = React.useState(0);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQueryString, setSearchQueryString] = React.useState("");
  const [formState, updateFormState] = React.useState(initialState);
  const [roleType, setRoleType] = React.useState("");
  const [selectedContracts, setSelectedContracts] = React.useState([]);
  const [createUser] = useMutation(["accounts", "users"]);
  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [activePage, setactivePage] = React.useState(null);
  const token = localStorage.getItem("access_token");
  const allowNewUser = JSON.parse(localStorage.getItem("allowNewUser"));

  const [intialDataLoaded, setInitialDataLoaded] = React.useState(false);

  const windowParams = getCurrentWindowParams();
  const pageNumber = windowParams.get("page");
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    Number(pageNumber) || 1
  );

  const client = useClient();
  const history = useHistory();
  const [tableUserId, setTableUserId] = React.useState("");
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const [showResendConfirmationModal, setShowResendConfirmationModal] =
    React.useState(false);
  const [
    showToggleStatusConfirmationModal,
    setShowToggleStatusConfirmationModal,
  ] = React.useState(false);
  const [isUserToggleLoading, setUserToggleLoading] = React.useState(false);
  const [resendEmail, setResendEmail] = React.useState("");

  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  const users = useQuery(
    activePage && [
      "accounts",
      "users",
      {
        filter: {
          search: searchQueryString,
        },
        page: paginationSettings,
      },
    ],
    { client }
  );

  React.useEffect(() => {
    if (users.data && !users.isLoading) {
      setInitialDataLoaded(true);
    } else {
      setInitialDataLoaded(false);
    }
  }, [users]);

  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }
  }, []);

  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(Number(pageNumber));
      // setactivePage(initialPageParams.initialPageNumber);
      setactivePage(Number(pageNumber));
      setSearchQueryString(initialPageParams.initialSearchKey);
    }
  }, [initialPageParams, pageNumber]);

  const contracts = useQuery(
    [
      apiRoutes.contract,
      {
        page: {
          number: 1,
          size: 3000,
        },
      },
    ],
    { client }
  );
  const roles = useQuery(["accounts", "roles"], { client });
  const [updateUser] = useMutation(["accounts", "users", currentUserId], {
    method: "PUT",
    client,
  });
  const onInputChange = (target) => {
    updateFormState({
      ...formState,
      [target.name]: target.value,
    });
  };

  const onContractorChange = (options) => {
    let contractors = _.map(options, (option) => option.value);

    setSelectedContracts(options);
    updateFormState({
      ...formState,
      contractsId: contractors,
    });
  };
  const errorValidate = () => {
    let errors = validatorModule(
      {
        ...formState,
        ...(formState?.role
          ? {
              role:
                formState.role.value !== undefined
                  ? formState.role.value
                  : formState.role.id !== undefined
                  ? formState.role.id
                  : undefined,
            }
          : {}),
      },
      validationFormat
    );
    return errors;
  };
  const manageModalSettings = (status) => {
    manageRightModal(status);
    if (status) {
      addBodyClass("modal-open");
    } else {
      removeBodyClass("modal-open");
    }
  };

  const handleLogoutUser = () => {
    logout();
    history.push(VW_ROUTES.LOGIN);
  };

  const deleteUserDetails = async () => {
    const { error } = await client.delete(["accounts", "users", currentUserId]);
    if (error === undefined) {
      users.refetch();
      addToast(DISPLAY_TEXTS.USER_DELETED, { appearance: "success" });
      if (currentUser?.user_id === currentUserId) handleLogoutUser();
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
    setCurrentUserId(0);
    manageModal(false);
    removeBodyClass("modal-open");
  };

  const submitNewUser = async () => {
    let errors = errorValidate();

    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      if (
        formState.fullName !== "" &&
        formState.email !== "" &&
        formState.role?.value !== ""
      ) {
        const { data, error } = await createUser({
          ...formState,
          role: formState.role?.value,
        });
        if (data && error === undefined) {
          users.refetch();
          addToast(DISPLAY_TEXTS.USER_ADDED, { appearance: "success" });
          manageModalSettings(false);
        } else if (error !== undefined && error.detail) {
          addToast(error.detail, { appearance: "error" });
        } else {
          addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
        }
      }
    }
  };

  const updateUserData = async () => {
    let errors = errorValidate();

    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      if (
        formState.fullName !== "" &&
        formState.email !== "" &&
        formState.role?.value !== ""
      ) {
        const { data, error } = await updateUser({
          ...formState,
          role: formState.role?.value,
          id: currentUserId,
        });
        if (data && error === undefined) {
          users.refetch();
          addToast(DISPLAY_TEXTS.USER_UPDATED, { appearance: "success" });
        } else if (error !== undefined && error.detail) {
          addToast(error.detail, { appearance: "error" });
        } else {
          addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
        }
        setMode("");
        removeBodyClass("modal-open");
        manageModalSettings(false);
      }
    }
  };

  const addNewUser = () => {
    setMode("add");
    updateFormState(initialState);
    setErrorState(initialErrorState);
    setSelectedContracts([]);
    setRoleType("");
    manageRightModal(true);
    addBodyClass("modal-open");
  };

  const updateUserDetails = async (id) => {
    setMode("edit");
    setCurrentUserId(id);

    const exisitingData = _.keyBy(users.data, "id")[id];
    updateRoleInputChange(exisitingData.role);
    // var dataset = _.keyBy(roles.data, "id");
    setRoleType(exisitingData.role?.slug);
    const exisitingContractsId = _.map(contracts.data, (contract) => {
      if (_.includes(exisitingData.contractsId, contract.id)) {
        return {
          label: contract.name,
          value: contract.id,
        };
      }
    });
    const getExistingRoleData = (rl) => {
      let rls = { label: "Role", value: "" };
      roles.data.forEach((item, i) => {
        if (item.id === rl)
          rls = {
            label: item.name,
            id: item.id,

            slug: item.slug,
          };
      });
      return rls;
    };

    setSelectedContracts(exisitingContractsId ?? []);
    updateFormState({
      fullName: exisitingData.fullName,
      email: exisitingData.email,
      phoneNumber: exisitingData.phoneNumber,
      role: getExistingRoleData(exisitingData.role.id),
      contractsId: exisitingData.contractsId,
    });
    setErrorState(initialErrorState);
    manageModalSettings(true);
  };

  const manageDeletedOption = (id) => {
    setCurrentUserId(id);
    addBodyClass("modal-open");
    manageModal(true);
  };

  const openUserToggleStatusModal = (userData) => {
    setSelectedUser(userData);
    setShowToggleStatusConfirmationModal(true);
  };

  const closeUserToggleStatusModal = () => {
    setSelectedUser(null);
    setShowToggleStatusConfirmationModal(false);
  };

  const handleUserToggleStatus = () => {
    const url = new URL(
      `${API_BASE_URL}${apiRoutes.userAction}/${selectedUser?.id}/${
        selectedUser?.isActive ? "disable" : "enable"
      }`
    );
    Axios.put(url, {}, config)
      .then(() => {
        users.refetch();
        addToast(
          selectedUser?.isActive
            ? DISPLAY_TEXTS.USER_INACTIVATED
            : DISPLAY_TEXTS.USER_ACTIVATED,
          { appearance: "success" }
        );
        if (selectedUser?.isActive && currentUser?.user_id === selectedUser?.id)
          handleLogoutUser();
      })
      .catch(() => {
        addToast(
          selectedUser?.isActive
            ? DISPLAY_TEXTS.USER_INACTIVATED_ERROR
            : DISPLAY_TEXTS.USER_ACTIVATED_ERROR,
          { appearance: "warning" }
        );
      });
    closeUserToggleStatusModal();
  };

  const doCloseModal = () => {
    setCurrentUserId(0);
    removeBodyClass("modal-open");
    manageModal(false);
  };

  const showHideContextMenu = (userid) => {
    setTableUserId(userid);
  };

  const [resendInvitation] = useMutation(["accounts", "invitation", "resend"], {
    method: "POST",
    client,
  });

  let menuRef = React.useRef();

  React.useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (
        event.target.className !== "user-role-action-icons" &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      )
        setTableUserId("");
    });
  });

  const showResendConfirmationModalPopUp = (user) => {
    setShowResendConfirmationModal(true);
    setResendEmail(user.email);
  };

  const closeResendConfirm = () => {
    setShowResendConfirmationModal(false);
    setResendEmail("");
  };

  const [isResendInvitationLoading, setIsResendInvitationLoading] =
    useState(false);
  const resndInvitaionOnclick = async () => {
    setIsResendInvitationLoading(true);
    const { data, error } = await resendInvitation({
      email: resendEmail,
    });
    if (error === undefined && data) {
      addToast(data.detail, { appearance: "success" });
    } else {
      addToast(error.detail, { appearance: "warning" });
    }
    setIsResendInvitationLoading(false);
    setTableUserId("");
    closeResendConfirm();
    setResendEmail("");
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "edit") {
      updateUserDetails(mode.users.id);
    }

    if (mode.mode === "delete") {
      manageDeletedOption(mode.users.id);
    }

    if (mode.mode === "resend") {
      showResendConfirmationModalPopUp(mode.users);
    }

    if (mode.mode === "toggleStatus") {
      openUserToggleStatusModal(mode.users);
    }
  };

  const columns = [
    {
      name: "Profile",
      sortable: true,
      minWidth: "200px",
      selector: (user) => user?.fullName,
      cell: (users) => (
        <ProfileCard
          data={users}
          mode="user"
          actions={userRoleDetails}
          actionsToParent={(mode) => getEvent({ mode, users })}
        />
      ),
    },
    {
      name: "Email",
      selector: (user) => user?.email,
      sortable: false,
      hide: "md",
      cell: (user) => <>{formatTextWithFallback(user?.email)}</>,
    },
    {
      name: "Phone",
      selector: (user) => user?.phoneNumber,
      sortable: false,
      maxWidth: "300px",
      hide: "md",
      cell: (user) => <>{formatTextWithFallback(user?.phoneNumber)}</>,
    },
    {
      name: "Status",
      selector: (user) => user?.isActive,
      sortable: false,
      maxWidth: "300px",
      hide: "md",
      cell: (user) => (
        <PillText
          label={user?.isActive ? "Active" : "Inactive"}
          type={user?.isActive ? "active" : "inactive"}
          mapper={{ active: "true", inactive: "false" }}
        />
      ),
    },
    {
      name: "Actions",
      sortable: false,
      maxWidth: 100,
      minWidth: 100,
      cell: (user) => (
        <>
          <div className="contextMenu">
            <span
              className="user-role-action-icons"
              onClick={() => showHideContextMenu(user?.id)}
            >
              <i></i>
            </span>
            {tableUserId === user.id && (
              <ContextMenuContentWrapper
                showContextMenu={tableUserId === user?.id}
                handleContextMenuClose={() => setTableUserId("")}
              >
                <ul ref={menuRef}>
                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    "user",
                    user?.isActive ? "inactivate_user" : "activate_user",
                    user
                  ) && (
                    <li>
                      <button onClick={() => openUserToggleStatusModal(user)}>
                        {" "}
                        <i
                          className={`user-role-action-icons ${
                            user?.isActive
                              ? "simple-icon-close error"
                              : "simple-icon-check success"
                          }`}
                        ></i>
                        {user?.isActive ? "Inactivate" : "Activate"}
                      </button>
                    </li>
                  )}
                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    "user",
                    "resend_invitation",
                    user
                  ) && (
                    <li>
                      <button
                        onClick={() => showResendConfirmationModalPopUp(user)}
                      >
                        {" "}
                        <i className="iconsminds-refresh"></i>Resend Invitation
                      </button>
                    </li>
                  )}
                  {checkModuleEditPermission(
                    userRoleDetails,
                    "user",
                    "user",
                    user
                  ) && (
                    <li>
                      <button onClick={() => updateUserDetails(user.id)}>
                        {" "}
                        <i className="iconsminds-pen"></i>Edit User
                      </button>
                    </li>
                  )}

                  {checkModuleDeletePermission(
                    userRoleDetails,
                    "user",
                    "user",
                    user
                  ) && (
                    <li>
                      <button onClick={() => manageDeletedOption(user.id)}>
                        {" "}
                        <i className="simple-icon-trash"></i>Delete User
                      </button>
                    </li>
                  )}
                </ul>
              </ContextMenuContentWrapper>
            )}
          </div>
        </>
      ),
      right: true,
      hide: "md",
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    const setParams = {
      page: page,
      searchQueryString: searchQueryString,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setactivePage(1);
    const setParams = {
      page: 1,
      searchQueryString: searchQuery,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }

      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };

  const updateRoleInputChange = (target) => {
    let data = {};
    const { label, value, slug } = target;
    if (target.hasOwnProperty("name")) {
      const { id, name, slug } = target;
      data = { label: name, value: id, slug };
    } else {
      data = { label, value, slug };
    }
    setRoleType(slug);
    if (slug !== "account-coordinator") {
      setSelectedContracts([]);
      updateFormState({
        ...formState,
        contractsId: [],
      });
    }
    updateFormState({
      ...formState,
      role: {
        label: data.label || data.name,
        value: data.id || data.value,
        slug: data.slug,
      },
    });
  };

  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                        <ol className="breadcrumb pt-0">
                            <li className="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="#">Library</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Data</li>
                        </ol>
                    </nav> */}

          <div className="page-header">
            <h1>Users</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <div className="mobile-search">
                    <SearchBar
                      onSearchInputChange={onSearchInputChange}
                      popstateText={searchQueryString}
                    />
                  </div>
                  {/* <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <FilterSection />
                  <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={(e) => {
                        handleResetFilter(e);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-secondary m-0"
                      onClick={(e) => {
                        handleApplyFilter(e);
                      }}
                    >
                      Apply
                    </button>
                  </div> */}
                </div>
              </div>
              {/* <div
                className={`tableFilterButton rightAlign`}
                title="Advanced Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div> */}
              {checkModuleAddPermission(userRoleDetails, "user", "user") && (
                <button
                  type="button"
                  title="Add User"
                  className="tableFilterButton ml-2 rightAlign btn-secondary"
                  onClick={() => addNewUser()}
                >
                  <>+</>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                users.isLoading || !intialDataLoaded
                  ? "card-body dataTableOuter table-loader"
                  : "card-body dataTableOuter"
              }
            >
              <DataTable
                subHeader={true}
                columns={columns}
                data={users.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                progressPending={users.isLoading || !intialDataLoaded}
                progressComponent={<p>&nbsp;</p>}
                paginationTotalRows={users.meta && users.meta.pagination.count}
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={<b>Confirm Delete</b>}
        content={
          <>
            {currentUser?.user_id === currentUserId && (
              <div class="warning-text high-level">
                <i className="error-icon" />
                {DISPLAY_TEXTS.USER_SELF_DELETE_WARNING}
              </div>
            )}
            <p>Are you sure you want to delete the selected User details ?</p>
          </>
        }
        onSubmitCallback={deleteUserDetails}
        onCloseCallback={doCloseModal}
        submitButtonText="Delete"
        customClass="delete"
      />

      <ModalRightComponent
        show={showRightModal}
        header={mode === "add" ? "Add User" : "Update User"}
        submitButtonText={mode === "add" ? "Submit" : "Update"}
        classes="normal-right-modal"
        content={
          <UserFormComponent
            mode={mode}
            title={
              mode === "add"
                ? "Submitting below form will add a new user."
                : "Submitting below form will update the user."
            }
            updateInputChange={onInputChange}
            roles={roles}
            errorState={errorState}
            formData={formState}
            contracts={contracts.data}
            onContractorChange={onContractorChange}
            updateRoleInputChange={updateRoleInputChange}
            roleType={roleType}
            selectedContracts={selectedContracts}
          />
        }
        onSubmitCallback={() =>
          mode === "add" ? submitNewUser() : updateUserData()
        }
        onCloseCallback={() => manageModalSettings(false)}
      />

      <ModalComponent
        show={showResendConfirmationModal}
        header={<b>Resend Invitation</b>}
        content={<p>Are you sure you want to resend invitation ?</p>}
        onSubmitCallback={resndInvitaionOnclick}
        onCloseCallback={closeResendConfirm}
        isLoaderActive={isResendInvitationLoading}
        cancelButtonText={"No"}
        submitButtonText={"Yes"}
      />

      <ModalComponent
        show={showToggleStatusConfirmationModal}
        header={
          <b>{`Confirm ${
            selectedUser?.isActive ? "Inactivate" : "Activate"
          }`}</b>
        }
        content={
          <>
            {currentUser?.user_id === selectedUser?.id &&
              selectedUser?.isActive && (
                <div class="warning-text high-level">
                  <i className="error-icon" />
                  {DISPLAY_TEXTS.USER_SELF_INACTIVATE_WARNING}
                </div>
              )}
            <p>
              {`Are you sure you want to ${
                selectedUser?.isActive ? "inactivate" : "activate"
              } `}
              <b>{selectedUser?.fullName}</b>?
            </p>
          </>
        }
        onSubmitCallback={handleUserToggleStatus}
        onCloseCallback={closeUserToggleStatusModal}
        isLoaderActive={isUserToggleLoading}
        cancelButtonText={"No"}
        submitButtonText={"Yes"}
      />
    </>
  );
};

export default UserManagement;
