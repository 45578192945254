export const columnData = {
  fullName: {
    min_width: 300,
    sortable: true,

    label: "Name",
    align: "left",
  },
  category: {
    min_width: 150,
    max_width: 100,
    sortable: false,
    label: "Category",
    align: "left",
  },
  company: {
    min_width: 200,
    max_width: null,
    sortable: false,

    label: "Company",
    align: "left",
  },
  contract: {
    min_width: 200,
    max_width: null,
    sortable: false,
    label: "Contract",
    align: "left",
  },

  idNumber: {
    min_width: 150,
    max_width: 250,
    sortable: false,

    label: "ID Number",
    align: "left",
  },

  workPermitExpiry: {
    min_width: 200,
    max_width: 200,
    sortable: false,

    label: "Work Permit Expiry",
    align: "left",
  },

  registerMode: {
    min_width: 150,
    max_width: 200,
    sortable: false,

    label: "Register Mode",
    align: "left",
  },

  expiryDate: {
    min_width: 200,
    max_width: 200,
    sortable: false,

    label: "Expiry Date",
    align: "left",
  },
};
