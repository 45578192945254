import React from "react";
import "./PillTextStyle.scss";
import { formatTextWithFallback } from "../../helpers/helpers";

const PillText = ({ label, type = 'default', mapper, className = '' }) => (
    <div className={`${className
        } pill-text ${mapper ? mapper[type] ?? "default" : type}`
    }>{String(formatTextWithFallback(label)).split("_").join(" ")}
    </div>
);

export default PillText;