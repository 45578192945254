import React, { useEffect, useState, useRef } from "react";
import LineChart from "./apexCharts/lineChart";
import { getPointColors } from "../../../helpers/helpers";

function DashLineChart(props) {
  const [width, setWidth] = useState("auto");
  const parent = useRef(null);
  useEffect(() => {
    function handleResize() {
      setWidth(parent.current.offsetWidth - 10);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const getData = (data) => {
    let chartdata = [];
    if (data) {
      data.forEach((item) => {
        chartdata.push({
          name: item.label,
          data: item.data.length === 0 ? [0, 0, 0, 0, 0, 0, 0] : item.data,
        });
      });
    }
    return chartdata;
  };
  const getTitle = (data) => {
    let charttitle = "";
    if (data) {
      data.forEach((item, i) => {
        charttitle = `${charttitle} ${
          i < data.length && i !== 0 ? " - " : " "
        }${item.label}`;
      });
    }
    return charttitle;
  };


  const colors = getPointColors(props.data.datasets,"line");
  return (
    <div style={{ width: "100%" }} ref={parent}>
      <LineChart
        xAxis={props.data.labels || []}
        xAxisLabel="Month"
        yAxisLabel="Count"
        key={width}
        width={width}
        dataArray={props.dataArray}
        data={getData(props.data.datasets) || []}
        title={getTitle(props.data.datasets) || ""}
        colors={colors || []}
      />
    </div>
  );
}

export default DashLineChart;
