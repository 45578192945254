/* eslint react-hooks/exhaustive-deps: 0 */
import axios from "axios";
import { useClient, useMutation, useQuery } from "jsonapi-react";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import * as FileSaver from "file-saver";
import React, { useCallback, useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import profileimagePlaceholder from "../../../src/assets/images/profileimagePlaceholder.jpg";
import CaptureImageComponent from "../../components/CaptureImageComponent/CaptureImageComponent";
import EditGatePass from "../../components/common/EditGatePass/EditGatePass";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import RejectApplication from "../../components/common/RejectApplication/RejectApplication";
import ViewGatePass from "../../components/common/ViewGatePass/ViewGatePass";
import {
  API_BASE_URL,
  CATEGORY_SLUGS,
  VW_ROUTES,
  apiRoutes,
} from "../../constants";
import { AppContext } from "../../context/AppContext";
import { BiHistory, BiPencil, BiTrash } from "react-icons/bi";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { addBodyClass, removeBodyClass } from "../../helpers/utils";

import { setTimeout } from "core-js";
import { CardLoader } from "../../components/CardLoader/CardLoader";
import ViewIDCard from "../../components/common/ViewIDCard/ViewIDCard";
import ViewContractorHistory from "../../components/common/ViewContractorHistory/ViewContractorHistory";
import BanProfile from "../../components/common/BanProfile/BanProfile";
import CreateAccessPass from "../../components/common/CreateAccessPass/CreateAccessPass";
import {
  checkModuleGeneralPermission,
  checkModuleViewPermission,
  convertToDayFirst,
  convertToYearFirst,
  formatTextWithFallback,
  getCategoryType,
  getDayFirst,
  getIDNameById,
  getIDTypeId,
  getLocalDateTime,
  getYearFirst,
  validatorModule,
} from "../../helpers/helpers";
import PermissionWrapper from "../../layout/PermissionWrapper";
import CancelApplication from "../../components/common/CancelApplication/CancelApplication";
import { data } from "jquery";
import ActionHistory from "../../components/common/ActionHistory/ActionHistory";
import ContextMenuContentWrapper from "../../components/common/ContextMenuContentWrapper";
import ArchiveProfilePopup from "../../components/ArchiveProfilePopup/ArchiveProfilePopup";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import AdditionalIds from "../../components/common/AdditionalIds/AdditionalIds";
import Select from "react-select";
import DatePicker from "react-datepicker";
import IdInput from "../../components/common/IdInput/IdInput";
const ViewProfile = ({ location }) => {
  const passContainer = useRef();
  const [tableUserId, setTableUserId] = React.useState("");
  const [passData, setPassData] = React.useState({});
  const [isLoaderActive, setLoaderActive] = React.useState(false);
  const [addPassData, setAddPassData] = React.useState({});
  const [addPassDataEditedFields, setAddPassDataEditedFields] = React.useState(
    []
  );
  const [confirmModalType, setConfirmModalType] = React.useState(0);
  const [deleteOption, setDeleteOption] = React.useState(false);
  const [showGatePassModal, setShowGatePassModal] = React.useState(false);
  const [showIDCardModal, setShowIDCardModal] = React.useState(false);
  const [editGatePassModal, setEditGatePassModal] = React.useState(false);
  const [addGatePassModal, setAddGatePassModal] = React.useState(false);
  const [archiveModal, setArchiveModal] = React.useState(false);
  const [addConfirmationModal, setAddConfirmationModal] = React.useState(false);
  const [gatePassApprovalModal, setGatePassApprovalModal] =
    React.useState(false);
  const [gatePassRejectionModal, setGatePassRejectionModal] =
    React.useState(false);
  const [rejectData, setRejectData] = React.useState({ note: "" });
  const [cancelData, setCancelData] = React.useState({ note: "" });
  const [nationality, setNationality] = React.useState([]);
  const [addPassErrors, setAddPassErrors] = React.useState([]);
  const [rejectValidation, setRejectValidation] = React.useState({ msg: "" });
  const [cancelValidation, setCancelValidation] = React.useState({ msg: "" });
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [docRowsPerPage, setDocRowsPerPage] = React.useState(10);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [currentDocPageNumber, setCurrentDocPageNumber] = React.useState(1);
  const [showResendConfirmationModal, setShowResendConfirmationModal] =
    React.useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
    React.useState(false);
  const [showLatestNote, setShowLatestNote] = React.useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = React.useState(false);
  const [showDeleteDocModal, setShowDeleteDocModal] = React.useState(false);
  const [selectedDoc, setSelectedDoc] = React.useState("");
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const [profileHistoryOpen, setProfileHistoryOpen] = React.useState(false);
  const [passHistoryOpen, setPassHistoryOpen] = React.useState(false);
  const [historyType, setHistoryType] = React.useState("");
  const [showContextMenu, setShowContextMenu] = React.useState(false);
  const [synConfrimationModal, setSynConfrimationModal] = React.useState(false);
  const [showSyncsuccessPopup, setShowSyncsuccessPopup] = React.useState(false);
  const [spinnerReady, setSpinnerReady] = React.useState(false);
  const [selectedAccesssPassPersonType, setSelectedAccesssPassPersonType] =
    React.useState();
  const [isDeleteProfileLoading, setIsDeleteProfileLoading] = useState(false);
  const [isPassFormDateLoading, setIsPassFormDateLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isAccessPassPrint, setIsAccessPrint] = useState(false);
  const [pageType, setPageType] = useState("");

  const [activeTab, setActiveTab] = useState("pass");
  const [createIdFile, setCreateIdFile] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = React.useState(false);
  const [showDocumentModal, setShowDocumentModal] = React.useState(false);
  const { addToast } = useToasts();

  const pageParams = queryString.parse(location.search);

  const history = useHistory();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  const {
    showModal,
    manageModal,
    currentUser,
    showBanModal,
    userRoleDetails,
    lastRouteStates,
    appOptions,
  } = React.useContext(AppContext);
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const [isEdit, setIsEdit] = React.useState(false);
  const [profileDocs, setProfileDocs] = React.useState([]);
  const [isPassPort, setIsPassPort] = React.useState(false);
  const initialErrorState = {
    ic_type: "",
    ic_number: "",
    expiry: "",
  };

  const initialFormState = {
    person: "",
    ic_type: "",
    ic_number: "",
    expiry: "",
    document: "",
  };
  const [formState, setFormState] = React.useState(initialFormState);

  const [errorState, setErrorState] = React.useState(initialErrorState);
  const isExpiryRequired = () => {
    const idType = formState.ic_type;
    if (idType !== "nric") {
      return true;
    } else {
      return false;
    }
  };

  const validationFormat = [
    {
      field: "ic_type",
      fieldName: "ID Type",
      isRequired: true,
    },
    {
      field: "ic_number",
      fieldName: formState.ic_type === "cidb" ? "CIDB Number" : "ID Number",
      minLength: formState.ic_type === "cidb" ? 16 : 4,
      isAlphaNumeric: true,
      isRequired: true,
    },
    {
      field: "expiry",
      isRequired: isExpiryRequired(),
      fieldName: "ID Expiry",
    },
  ];

  const getIdOptions = () => {
    let idTypeArray = [{ label: "Select", value: "" }];
    if (appOptions.ic_types?.length > 0) {
      appOptions.ic_types.forEach((item) => {
        idTypeArray.push({
          label: item.display_name,
          value: item.slug,
        });
      });
    }
    return idTypeArray;
  };

  const onInputChange = ({ name, value }) => {
    let formData = { ...formState };
    if (name === "ic_type" && value === "passport") {
      setIsPassPort(true);
    } else if (name === "ic_type" && value !== "passport") {
      setIsPassPort(false);
    }
    if (name === "ic_type") {
      formData.ic_type = value;
      formData.ic_number = "";
    }
    if (
      name === "ic_number" &&
      ((formData.ic_type !== "passport" && value.length <= 16) ||
        (formData.ic_type === "passport" && value.length <= 16) ||
        formData.ic_type === "cidb")
    ) {
      formData[name] = value;
    }

    if (name === "expiry") {
      formData.expiry = value;
    }

    setFormState(formData);
  };

  const getIdValues = (v) => {
    let idTypeObj = { label: "Select", value: "" };
    if (appOptions.ic_types?.length > 0) {
      appOptions.ic_types.forEach((item) => {
        if (item.slug === v)
          idTypeObj = {
            label: item.display_name,
            value: item.slug,
          };
      });
    }
    return idTypeObj;
  };

  const getCreateIdFile = (e) => {
    setCreateIdFile(e.target.files[0]);
  };

  const [approveGatePass] = useMutation(
    [
      ["pass", passData?.id, "authorize"],
      // "approve",
    ],
    { method: "PUT", client }
  );
  const [rejectGatePass] = useMutation(["pass", passData?.id, "authorize"], {
    method: "PUT",
    client,
  });
  const [updateAccessPass] = useMutation(
    apiRoutes.updateAccessPass
      .replace("{profileId}", pageParams?.id)
      .replace("{passId}", passData?.id),
    { method: "PATCH", client }
  );

  const closeLatestNoteModal = () => {
    setShowLatestNote(false);
  };
  const [createAccessPass] = useMutation(
    apiRoutes.accessPasses.replace("{profileId}", pageParams.id)
  );

  const [toggleApplicantProfile] = useMutation(
    apiRoutes.toggleApplicantProfile.replace("{profileId}", pageParams.id),
    { method: "PUT", client }
  );
  const [blacklistApplicantProfile] = useMutation(
    apiRoutes.archiveProfile.replace("{profileId}", pageParams.id),
    { method: "PUT", client }
  );

  const swicthTab = (tab) => {
    setActiveTab(tab);
  };

  React.useEffect(() => {
    if (showBanModal.reload) {
      applicantProfile.refetch();
      gatePasses.refetch();
    }
  }, [showBanModal]);

  React.useEffect(() => {
    const options = {
      method: "OPTIONS",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: apiRoutes.applicantProfile,
    };
    axios(options).then((response) => {
      const {
        data: {
          data: {
            actions: {
              POST: {
                nationality: { choices: nationalityChoices },
              },
            },
          },
        },
      } = response;
      setNationality(_.keyBy(nationalityChoices, "value"));
    });
  }, []);

  const onFocusChange = () => {
    let errors = validatorModule({ ...formState }, validationFormat);
    setErrorState(errors);
  };

  React.useEffect(() => {
    if (submitButtonPressed) {
      onFocusChange();
    }
  }, [formState]);

  const updateGatePassData = async () => {
    setAddGatepAssClicked(true);
    setLoaderActive(true);
    if (validateForm(addPassData)) {
      const editedPassData = _.pick(addPassData, addPassDataEditedFields);
      const { data, error } = await updateAccessPass({
        ...editedPassData,
      });
      if (data && error === undefined) {
        applicantProfile.refetch();
        addToast(DISPLAY_TEXTS.PASS_UPDATED, { appearance: "success" });
      } else if (error !== undefined && error.detail) {
        addToast(error.detail, { appearance: "error" });
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
      manageModalSettings(false, 1);
    }
    setLoaderActive(false);
  };

  const applicantProfile = useQuery(
    pageParams?.id && [
      apiRoutes.updateProfileData.replace("{personId}", pageParams.id),
      {
        include:
          "person_photo,person_access_pass,company,contract,trade,category,person_last_visit",
      },
    ],
    { client }
  );

  useEffect(() => {
    applicantProfile?.refetch();
  }, [pageParams.id]);

  const gatePasses = useQuery(
    !applicantProfile.isLoading &&
      applicantProfile?.data?.id && [
        apiRoutes.accessPasses.replace("{profileId}", pageParams?.id),
        {
          page: {
            number: currentPageNumber,
            size: rowsPerPage,
          },
          include: "type,contract",
        },
      ],
    { client }
  );

  const gateAllDocs = useQuery(
    !applicantProfile.isLoading &&
      applicantProfile?.data?.id && [
        `${apiRoutes.fetchAllIc.replace("{personId}", pageParams?.id)}`,
        {
          page: {
            number: currentDocPageNumber,
            size: docRowsPerPage,
          },
          include: "ic_type",
        },
      ],
    { client }
  );

  useEffect(() => {
    if (_.isEmpty(userRoleDetails)) return;
    if (applicantProfile?.error?.status === "404") {
      history.push({
        pathname: VW_ROUTES.ALL_PROFILES,
      });
    } else if (applicantProfile?.data) {
      setPageType(
        applicantProfile?.data?.category.slug === "employee"
          ? "employee-profile"
          : "visitor-profile"
      );
    }
  }, [applicantProfile]);

  const [addGatePassClicked, setAddGatepAssClicked] = React.useState(false);
  const validateForm = (formData) => {
    let emptyFields = [];
    for (let [key, value] of Object.entries(formData)) {
      if (
        key === "note" &&
        formData.mode === "custom" &&
        String(value ?? "").trim() === ""
      ) {
        emptyFields.push("note");
      } else if (
        (key === "purposeOfVisit" || key === "hostName") &&
        pageParams.type === CATEGORY_SLUGS["visitor-profile"] &&
        value === ""
      ) {
        emptyFields.push(key);
      } else if (
        key === "vehicleNumber" &&
        (selectedAccesssPassPersonType === "frequent-delivery" ||
          selectedAccesssPassPersonType === "delivery-adhoc") &&
        value === ""
      ) {
        emptyFields.push(key);
      } else if (
        key === "contract" &&
        value === "" &&
        pageParams.type === "visitor"
      ) {
        emptyFields.push(key);
      } else if (
        key !== "note" &&
        key !== "mode" &&
        key !== "purposeOfVisit" &&
        key !== "hostName" &&
        key !== "vehicleNumber" &&
        key !== "contract" &&
        value === ""
      ) {
        emptyFields.push(key);
      }
    }
    setAddPassErrors(emptyFields);
    return _.isEmpty(emptyFields);
  };

  React.useEffect(() => {
    validateForm(addPassData);
  }, [addPassData]);

  const addGatePassData = async () => {
    setAddGatepAssClicked(true);
    setLoaderActive(true);
    if (validateForm(addPassData)) {
      const { data, error } = await createAccessPass(addPassData);
      if (data && error === undefined) {
        applicantProfile.refetch();
        setLoaderActive(false);
        addToast(DISPLAY_TEXTS.PASS_ADDED, { appearance: "success" });
        manageModalSettings(false, 3);
      } else if (error !== undefined && error.detail) {
        setLoaderActive(false);
        addToast(error.detail, { appearance: "error" });
      } else {
        setLoaderActive(false);
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
    } else {
      setLoaderActive(false);
    }
  };

  const showAddDocumentModal = () => {
    setShowDocumentModal(true);
  };

  const hideAddDocumentModal = () => {
    setShowDocumentModal(false);
    setErrorState(initialErrorState);
    setIsLoading(false);
    setSubmitButtonPressed(false);
    setFormState(initialFormState);
    setIsEdit(false);
    setCreateIdFile(null);
  };

  const managePhotoBooth = (status) => {
    manageModal(status);
  };
  const refreshOnCaptureImage = () => {
    applicantProfile.refetch();
    managePhotoBooth(false);
  };

  const manageModalSettings = (status, identifier) => {
    if (identifier === 0) {
      setShowGatePassModal(status);
    } else if (identifier === 1) {
      setEditGatePassModal(status);
    } else if (identifier === 2) {
    } else if (identifier === 3) {
      setAddGatePassModal(status);
    } else if (identifier === 4) {
    } else if (identifier === 5) {
      setAddConfirmationModal(status);
    } else if (identifier === 6) {
      setGatePassApprovalModal(status);
    } else if (identifier === 7) {
      setGatePassRejectionModal(status);
    } else if (identifier === 8) {
      setSynConfrimationModal(status);
    } else if (identifier === 9) {
      setShowIDCardModal(status);
    } else if (identifier === 11) {
      setShowCancelConfirmationModal(status);
    }
    if (status) {
      addBodyClass("modal-open");
    } else {
      removeBodyClass("modal-open");
    }
  };

  const [passId, setPassId] = React.useState("");
  const [resendGatePass] = useMutation(
    apiRoutes.resendGatePass
      .replace("{profileId}", pageParams.id)
      .replace("{passId}", passId),
    {
      method: "GET",
      client,
    }
  );
  const [cancelGatePass] = useMutation(
    apiRoutes.cancelAccessPass.replace("{passId}", passData?.id),
    {
      method: "PUT",
      client,
    }
  );

  const resendGatePassClick = (pass) => {
    setShowResendConfirmationModal(true);
    setPassId(pass.id);
  };

  const closeResendConfirm = () => {
    setShowResendConfirmationModal(false);
    setPassId("");
  };

  const [isResendLoading, setIsResendLoading] = useState(false);
  const resendPassInitiate = async () => {
    setIsResendLoading(true);
    const { data, error } = await resendGatePass();
    if (error === undefined && data) {
      addToast(data.detail, { appearance: "success" });
    } else {
      addToast(error.detail, { appearance: "warning" });
    }
    setIsResendLoading(false);
    closeResendConfirm();
  };

  const viewSelectedGatePass = (pass) => {
    setPassData(pass);
    manageModalSettings(true, 0);
  };

  const onAddGatePass = () => {
    setAddPassData({
      type: "",
      mode: "",
      fromDateTime: "",
      toDateTime: "",
      hostName: "",
      purposeOfVisit: "",
      vehicleNumber: "",
      person: pageParams.id,
      note: "",
      contract: applicantProfile?.data?.contract?.id ?? "",
    });
    setAddGatepAssClicked(false);
    setAddPassErrors([]);
    manageModalSettings(true, 3);
  };

  const editSelectedGatePass = (pass) => {
    setPassData(pass);
    setSelectedAccesssPassPersonType(pass?.type?.slug);
    setAddPassData({
      type: pass.type.id,
      fromDateTime: pass.fromDateTime,
      toDateTime: pass.toDateTime,
      note: pass.note,
      mode: pass.mode,
      hostName: pass.hostName,
      purposeOfVisit: pass.purposeOfVisit,
      vehicleNumber: pass.vehicleNumber,
      person: pageParams.id,
      contract: pass?.contract?.id ?? applicantProfile?.data?.contract?.id,
    });
    manageModalSettings(true, 1);
  };

  const getEndDate = (fromDate, format) => {
    setAddPassData({
      ...addPassData,
      fromDateTime: "",
      toDateTime: "",
      note: "",
    });
    handleEditedFieldsChange(["fromDateTime", "toDateTime", "mode", "note"]);
    if (!fromDate) return;
    const token = localStorage.getItem("access_token");
    const fromDateObject = format ? moment(fromDate, format) : moment(fromDate);
    const options = {
      url:
        apiRoutes.accessPassGenerateValidity +
        `?from_date=${fromDateObject.format("DD-MM-YYYY")}&pass_type=${
          addPassData.type
        }`,
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    setIsPassFormDateLoading(true);
    axios(options)
      .then((response) => {
        setAddPassData({
          ...addPassData,
          fromDateTime: convertToDayFirst(
            response.data.data.attributes.validity.fromDt
          ),
          toDateTime: convertToDayFirst(
            response.data.data.attributes.validity.toDt
          ),
        });
      })
      .catch((error) => {})
      .finally(() => setIsPassFormDateLoading(false));
  };

  const handleEditedFieldsChange = (changedFields) => {
    setAddPassDataEditedFields((currFields) => [
      ...currFields,
      ..._.difference(changedFields, currFields),
    ]);
  };
  const handleEditedFieldsReset = () => {
    setAddPassDataEditedFields([]);
  };

  React.useEffect(() => {
    handleEditedFieldsReset();
  }, [editGatePassModal, addGatePassModal]);

  const onAddGatePassInputChange = (target) => {
    if (target.name === "fromDateTime") {
      if (target.custom) {
        setAddPassData({
          ...addPassData,
          fromDateTime: convertToDayFirst(target.value),
          note: "",
        });
        handleEditedFieldsChange([
          "fromDateTime",
          "toDateTime",
          "mode",
          "note",
        ]);
      } else getEndDate(target.value);
    } else if (target.name === "toDateTime") {
      setAddPassData({
        ...addPassData,
        toDateTime: convertToDayFirst(target.value),
        note: "",
      });
      handleEditedFieldsChange(["toDateTime", "fromDateTime", "mode", "note"]);
    } else if (target.name === "mode") {
      if (!target.value && addPassData.type) {
        addPassData.mode = "";
        if (addPassData?.fromDateTime)
          getEndDate(addPassData.fromDateTime, "DD-MM-YYYY");
        handleEditedFieldsChange(["mode"]);
      } else if (target.value && addPassData.type) {
        addPassData.mode = "custom";
        handleEditedFieldsChange(["mode", "note"]);
      }
    } else if (target.name === "type") {
      setAddPassData((prevPassData) => ({
        ...prevPassData,
        mode: "",
        fromDateTime: "",
        toDateTime: "",
        hostName: "",
        purposeOfVisit: "",
        vehicleNumber: "",
        note: "",
        type: target.value,
      }));
      setSelectedAccesssPassPersonType(target.slug);
      handleEditedFieldsChange([
        "mode",
        "fromDateTime",
        "toDateTime",
        "hostName",
        "purposeOfVisit",
        "vehicleNumber",
        "note",
        "type",
      ]);
    } else {
      setAddPassData({
        ...addPassData,
        [target.name]: target.value,
      });
      handleEditedFieldsChange([target.name]);
    }
  };

  const onActionChange = (action, pass) => {
    setPassData(pass);
    if (action === "approve") {
      manageModalSettings(true, 6);
    } else {
      setRejectData({ note: "" });
      manageModalSettings(true, 7);
    }
  };

  const onChangePage = (page) => {
    setCurrentPageNumber(page);
  };

  const onChangeDocPage = (docPage) => {
    setCurrentDocPageNumber(docPage ?? 1);
  };

  const handleHistoryOpen = (type) => {
    setHistoryType(type);
    setHistoryOpen(true);
  };

  const handleProfileHistoryOpen = () => {
    showHideContextMenu();
    setProfileHistoryOpen(true);
  };

  const handlePassHistoryOpen = (pass) => {
    setPassData(pass);
    setPassHistoryOpen(true);
  };

  const handlePassHistoryClose = () => {
    setPassData({});
    setPassHistoryOpen(false);
  };

  const handleHistoryClose = () => {
    setHistoryOpen(false);
  };
  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };
  const onChangeDocRowsPerPage = (docRows) => {
    setDocRowsPerPage(docRows);
  };

  let menuRef1 = React.useRef();

  React.useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (
        event.target.className !== "user-role-action-icons" &&
        menuRef1.current &&
        !menuRef1.current.contains(event.target)
      )
        setTableUserId("");
    });
  });

  const showHideContextMenu1 = (userid) => {
    setTableUserId(userid);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  const applicantBannedStatus = () => {
    return applicantProfile?.data?.status === "banned";
  };

  const editFile = (file) => {
    if (file.icType.slug === "passport") {
      setIsPassPort(true);
    } else {
      setIsPassPort(false);
    }
    setFormState({
      id: file.id,
      ic_type: file.icType.slug,
      ic_number: file.icNumber,
      expiry: getDayFirst(file.expiry),
      documentName: file?.documentName || "",
    });
    setCreateIdFile(null);

    setShowDocumentModal(true);
    setIsEdit(true);
  };

  const deleteFile = () => {
    setIsDeleteProfileLoading(true);
    axios
      .delete(
        `${apiRoutes.createIc.replace("{personId}", pageParams?.id)}/${
          selectedDoc.id
        }`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        addToast(DISPLAY_TEXTS.PROFILE_ID_DELETED, { appearance: "success" });
        gateAllDocs.refetch();
        doCloseDocDeleteModal();
      })
      .catch((error) => {
        addToast(
          error.response.data.errors &&
            error.response.data.errors.length > 0 &&
            error.response.data.errors[0]?.detail
            ? error.response.data.errors[0]?.detail
            : "Something went wrong",
          { appearance: "error" }
        );
        doCloseDocDeleteModal();
      });
  };

  const DocsontextMenu = ({ file, profile }) => {
    const [canUpdateDocument, setCanUpdateDocument] = useState(null);
    const [canDeleteDocument, setCanDeleteDocument] = useState(null);

    useEffect(() => {
      setCanUpdateDocument(
        checkModuleGeneralPermission(
          userRoleDetails,
          "person-document",
          "update_document",
          { ...file, profile: { ...profile } }
        )
      );
      setCanDeleteDocument(
        checkModuleGeneralPermission(
          userRoleDetails,
          "person-document",
          "delete_document",
          { ...file, profile: { ...profile } }
        )
      );
    }, []);

    return (
      <div className="contextMenu">
        <span
          className="user-role-action-icons"
          onClick={() => showHideContextMenu1(file.id)}
        >
          <i></i>
        </span>
        {tableUserId === file.id && (
          <ContextMenuContentWrapper
            showContextMenu={tableUserId === file.id}
            handleContextMenuClose={() => setTableUserId("")}
          >
            <ul ref={menuRef1}>
              {canUpdateDocument && (
                <li>
                  <button onClick={() => editFile(file)} type="button">
                    <i className="iconsminds-pen edit"></i>
                    Edit
                  </button>
                </li>
              )}
              {canDeleteDocument && (
                <li>
                  <button
                    onClick={() => showDocDeleteConfrimBox(file)}
                    type="button"
                  >
                    <i>
                      <BiTrash />
                    </i>
                    Delete
                  </button>
                </li>
              )}
            </ul>
          </ContextMenuContentWrapper>
        )}
      </div>
    );
  };

  const PassContextMenu = ({ pass, profile }) => {
    const [canUpdatePass, setCanUpdatePass] = useState(null);
    const [canViewPassNote, setCanViewPassNote] = useState(null);
    const [canViewPassHistory, setCanViewPassHistory] = useState(null);
    const [canResendPass, setCanResendPass] = useState(null);
    const [canCancelPass, setCanCancelPass] = useState(null);

    useEffect(() => {
      setCanUpdatePass(
        checkModuleGeneralPermission(
          userRoleDetails,
          "access-pass",
          "update_pass",
          { ...pass, profile: { ...profile } }
        )
      );

      setCanViewPassNote(
        checkModuleGeneralPermission(
          userRoleDetails,
          "access-pass",
          "view_passnote",
          { ...pass, profile: { ...profile } }
        )
      );

      setCanViewPassHistory(
        checkModuleGeneralPermission(
          userRoleDetails,
          "access-pass",
          "view_passhistory",
          { ...pass, profile: { ...profile } }
        )
      );

      setCanResendPass(
        checkModuleGeneralPermission(
          userRoleDetails,
          "access-pass",
          "resend_pass",
          { ...pass, profile: { ...profile } }
        )
      );

      setCanCancelPass(
        checkModuleGeneralPermission(
          userRoleDetails,
          "access-pass",
          "cancel_pass",
          { ...pass, profile: { ...profile } }
        )
      );
    }, []);

    if (
      !canUpdatePass &&
      !canResendPass &&
      !canCancelPass &&
      !canViewPassNote &&
      !canViewPassHistory
    )
      return null;

    return (
      <div className="contextMenu">
        <span
          className="user-role-action-icons"
          onClick={() => showHideContextMenu1(pass.id)}
        >
          <i></i>
        </span>
        {tableUserId === pass.id && (
          <ContextMenuContentWrapper
            showContextMenu={tableUserId === pass.id}
            handleContextMenuClose={() => setTableUserId("")}
          >
            <ul ref={menuRef1}>
              {canUpdatePass && (
                <>
                  <li onClick={() => editSelectedGatePass(pass)}>
                    <button>
                      <i className="iconsminds-pen edit"></i>Edit
                    </button>
                  </li>
                </>
              )}
              {canViewPassNote && (
                <>
                  <li
                    onClick={() => {
                      setPassData(pass);
                      setShowLatestNote(true);
                    }}
                  >
                    <button>
                      <i className=" iconsminds-notepad"></i>Notes
                    </button>
                  </li>
                </>
              )}

              {canViewPassHistory && (
                <li>
                  <button
                    onClick={() => handlePassHistoryOpen(pass)}
                    type="button"
                  >
                    <i>
                      <BiHistory />
                    </i>
                    History
                  </button>
                </li>
              )}

              {canResendPass && (
                <>
                  <li onClick={() => resendGatePassClick(pass)}>
                    <button>
                      <i className="iconsminds-Mail-Reply resend"></i>
                      Resend
                    </button>
                  </li>
                </>
              )}
              {canCancelPass && (
                <>
                  <li
                    onClick={() => {
                      setCancelData({ note: "" });
                      setPassData(pass);
                      manageModalSettings(true, 11);
                    }}
                  >
                    <button>
                      <i className="iconsminds-delete-file cancel"></i>
                      Cancel
                    </button>
                  </li>
                </>
              )}
            </ul>
          </ContextMenuContentWrapper>
        )}
      </div>
    );
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.fromDateTime,
      sortable: true,
      maxWidth: 200,
      minWidth: 110,
      format: (pass) => formatTextWithFallback(pass?.fromDateTime),
    },

    {
      name: "Purpose",
      selector: (row) => row.purposeOfVisit,
      sortable: false,
      omit: pageParams?.type === "employee",
      cell: (pass) => formatTextWithFallback(pass?.purposeOfVisit),
    },
    {
      name: "Type",
      selector: (row) => row.type.name,
      sortable: false,
      cell: (pass) => formatTextWithFallback(pass?.type?.name),
    },
    {
      name: "Status",
      sortable: false,
      maxWidth: 150,
      cell: (pass) => (
        <div className="actionContainer">
          {pass?.status === "deactivated" && (
            <span className="deactivated statusIndicator">Deactivated</span>
          )}

          {pass?.status === "approved" && (
            <span className="isApproved statusIndicator">Approved</span>
          )}
          {pass?.status === "rejected" && (
            <span className="isRejected statusIndicator">Rejected</span>
          )}
          {pass?.status === "expired" && (
            <span className="isExpired statusIndicator">Expired</span>
          )}
          {pass?.status === "pending" && (
            <span className="isExpired statusIndicator">Pending</span>
          )}
          {pass?.status === "active" && (
            <span className="isActive statusIndicator">Active</span>
          )}
          {pass?.status === "cancelled" && (
            <span className="isRejected statusIndicator">Cancelled</span>
          )}
        </div>
      ),
    },

    {
      name: "Expiry Date",
      selector: (row) => row.toDateTime,
      maxWidth: 150,
      minWidth: 150,
      sortable: true,
      format: (row) => formatTextWithFallback(row?.toDateTime),
    },

    {
      name: "Actions",
      sortable: false,
      maxWidth: 150,
      minWidth: 150,
      omit: applicantBannedStatus(),
      cell: (pass) => (
        <div className="actionContainer">
          {checkModuleGeneralPermission(
            userRoleDetails,
            "access-pass",
            "approve_pass",
            { ...pass, profile: applicantProfile?.data ?? {} }
          ) && (
            <button
              type="button"
              onClick={() => onActionChange("approve", pass)}
              className="btn btn-round-light icon-button"
              data-tip="Approve"
            >
              <i className="approve-reject approve simple-icon-check"></i>
            </button>
          )}
          {checkModuleGeneralPermission(
            userRoleDetails,
            "access-pass",
            "reject_pass",
            { ...pass, profile: applicantProfile?.data ?? {} }
          ) && (
            <button
              type="button"
              onClick={() => onActionChange("reject", pass)}
              className="btn btn-round-light icon-button"
              data-tip="Reject"
            >
              <i className="approve-reject reject simple-icon-close"></i>
            </button>
          )}

          {checkModuleGeneralPermission(
            userRoleDetails,
            "access-pass",
            "view_pass",
            { ...pass, profile: applicantProfile?.data ?? {} }
          ) && (
            <button
              type="button"
              onClick={() => viewSelectedGatePass(pass)}
              className="btn btn-round-light icon-button"
              data-tip="View Access Pass"
            >
              <i className="simple-icon-eye"></i>
            </button>
          )}

          <PassContextMenu pass={pass} profile={applicantProfile?.data ?? {}} />
        </div>
      ),
      right: true,
    },
  ];

  const docColumns = [
    {
      name: "Type",
      selector: (row) => row?.icType?.name,
      sortable: true,
      maxWidth: 200,
      format: (row) => formatTextWithFallback(row?.icType?.name),
    },
    {
      name: "ID Number",
      selector: (row) => row?.icNumber,
      sortable: true,
      cell: (row) => (
        <span className="text-uppercase">
          {formatTextWithFallback(row?.icNumber)}
        </span>
      ),
    },
    {
      name: "Expiry",
      selector: (row) => row?.expiry,
      sortable: true,
      maxWidth: 200,
      minWidth: 110,
      cell: (row) => formatTextWithFallback(row?.expiry),
    },
    {
      name: "Attachment",
      selector: (row) => row.document,
      sortable: true,
      maxWidth: 200,
      cell: (file) => (
        <div className="file-name-wrap">
          {file.document ? (
            <a href={file.document} className="downloadLink" target="_blank">
              {formatTextWithFallback(file?.documentName)}
            </a>
          ) : (
            "--"
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      selector: (row) => row.attributes?.expiry,
      sortable: true,
      maxWidth: 200,
      cell: (file) => (
        <div className="actionContainer">
          <DocsontextMenu file={file} profile={applicantProfile?.data ?? {}} />
        </div>
      ),
      right: true,
    },
  ];

  const locationHook = useLocation();
  const onBackButtonClick = () => {
    const fromPath = history.location?.state?.from;
    if (!fromPath)
      history.push(`/${getCategoryType(pageParams.type) ?? "all-profile"}`);
    history.push(
      _.has(lastRouteStates, fromPath) ? lastRouteStates[fromPath] : fromPath
    );
  };

  const popConfirmModal = (type) => {
    setConfirmModalType(type);
    manageModalSettings(true, 5);
  };

  const handlePrint = () => {
    setIsAccessPrint(true);
  };

  const handlePDF = useCallback(async () => {
    if (
      !String(applicantProfile?.data?.id ?? "").length ||
      !String(passData?.id ?? "").length
    )
      return;
    setLoaderActive(true);
    const token = localStorage.getItem("access_token");
    const options = {
      url: apiRoutes.downloadAcccessPass
        .replace("{personId}", applicantProfile?.data?.id)
        .replace("{accessPassId}", passData?.id),
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    await axios(options)
      .then((response) => {
        FileSaver.saveAs(
          response.data,
          `${String(applicantProfile?.data?.profileId ?? "person")
            .split(" ")
            .join("-")}-${String(passData?.id ?? "pass")
            .split(" ")
            .join("-")}-access-pass.pdf`
        );
      })
      .finally(() => setLoaderActive(false));
  }, [applicantProfile, passData]);
  const redirectToEditPageBasedonType = () => {
    history.push({
      pathname: VW_ROUTES.MANAGE_PERSON_REGISTRY,
      search: `?id=${pageParams.id}&type=${
        getCategoryType(pageParams.type) ?? pageParams.type
      }&categoryId=${
        applicantProfile?.data?.category?.id
      }&edit=true&fromViewProfile=true${
        pageParams?.fromAllProfile ? "&fromAllProfile=true" : ""
      }`,
      state: {
        from: history.location?.state?.from,
      },
    });
  };

  const confirmApproveGatePass = async () => {
    const approveData = {
      approve: true,
      id: pageParams.id,
    };
    setIsApproveLoading(true);
    const { error } = await approveGatePass(approveData);
    setIsApproveLoading(false);

    setRejectData({ note: "" });
    manageModalSettings(false, 6);
    gatePasses.refetch();
    if (error === undefined) {
      applicantProfile.refetch();
      addToast(DISPLAY_TEXTS.APPROVE_GATE_PASS, { appearance: "success" });
    } else {
      addToast(error.detail, { appearance: "error" });
    }
  };

  const confirmRejectGatePass = async () => {
    if (String(rejectData?.note ?? "").trim() === "") {
      setRejectValidation({ msg: "Reject note required." });
    } else {
      setIsRejectLoading(true);
      const { error } = await rejectGatePass(rejectData);
      setIsRejectLoading(false);

      setRejectData({ note: "" });
      setRejectValidation({ msg: "" });
      manageModalSettings(false, 7);
      if (error === undefined) {
        addToast(DISPLAY_TEXTS.REJECT_GATE_PASS, { appearance: "success" });
        applicantProfile.refetch();
        gatePasses.refetch();
      } else {
        addToast(error.detail, { appearance: "error" });
      }
    }
  };

  const handleCloseRejectConfirm = () => {
    setRejectData({ note: "" });
    setRejectValidation({ msg: "" });
    manageModalSettings(false, 7);
  };

  const confirmCancelGatePass = async () => {
    if (String(cancelData?.note ?? "").trim() === "") {
      setCancelValidation({ msg: "Cancel note required." });
    } else {
      setIsCancelLoading(true);
      const { error } = await cancelGatePass(cancelData);
      setIsCancelLoading(false);

      setCancelData({ note: "" });
      setCancelValidation({ msg: "" });
      manageModalSettings(false, 11);
      if (error === undefined) {
        addToast(DISPLAY_TEXTS.CANCEL_GATE_PASS, { appearance: "success" });
        applicantProfile.refetch();
        gatePasses.refetch();
      } else {
        addToast(error.detail, { appearance: "error" });
      }
    }
  };

  const handleCloseCancelConfirm = () => {
    setCancelData({ note: "" });
    setCancelValidation({ msg: "" });
    manageModalSettings(false, 11);
  };

  const onRejectInputChange = (value) => {
    setRejectValidation({ msg: "" });
    setRejectData({
      id: passData.id,
      note: value,
      approve: false,
    });
  };

  const onCancelInputChange = (value) => {
    setCancelValidation({ msg: "" });
    setCancelData({
      id: passData.id,
      note: value,
    });
  };

  const showUserDeleteConfrimBox = () => {
    setShowDeleteUserModal(true);
  };

  const showDocDeleteConfrimBox = (file) => {
    setSelectedDoc(file);
    setShowDeleteDocModal(true);
  };

  const doCloseModal = () => {
    setShowDeleteUserModal(false);
  };

  const doCloseDocDeleteModal = () => {
    setShowDeleteDocModal(false);
    setSelectedDoc("");
    setIsDeleteProfileLoading(false);
  };

  const deleteUserDetails = async () => {
    setIsDeleteProfileLoading(true);
    const { error } = await client.delete(
      apiRoutes.updateProfileData.replace(
        "{personId}",
        applicantProfile.data.id
      )
    );
    setIsDeleteProfileLoading(false);
    setShowDeleteUserModal(false);
    if (error === undefined) {
      addToast(DISPLAY_TEXTS.PERSON_PROFILE_DELETED, { appearance: "success" });

      onBackButtonClick();
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
  };

  const showHideContextMenu = () => {
    if (showContextMenu) {
      setShowContextMenu(false);
    } else {
      setShowContextMenu(true);
    }
  };

  let menuRef = React.useRef();

  React.useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (
        event.target.className !== "user-role-action-icons" &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      )
        setShowContextMenu(false);
    });
  });

  const syncProfile = () => {
    manageModalSettings(true, 8);
  };

  const confirmSyncProfile = () => {
    const options = {
      url: apiRoutes.resyncProfile.replace("profileId", pageParams.id),
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
    };
    axios(options)
      .then((response) => {
        if (response.status === 200) {
          setShowSyncsuccessPopup(true);
          manageModalSettings(false, 8);
          setTimeout(() => {
            setSpinnerReady(true);
          }, 2000);
        } else {
          manageModalSettings(false, 8);
          addToast(
            response.errors
              ? response.errors[0].detail
              : DISPLAY_TEXTS.SOMETHING_WRONG,
            { appearance: "warning" }
          );
        }
      })
      .catch((error) => {
        manageModalSettings(false, 8);
        // addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
        addToast(
          error.response.data?.errors
            ? error.response.data?.errors[0].detail
            : DISPLAY_TEXTS.SOMETHING_WRONG,
          { appearance: "warning" }
        );
      });
  };

  const confirmSyncProfileOk = () => {
    setShowSyncsuccessPopup(false);
    setSpinnerReady(false);
  };

  const onCidbInput = (value) => {
    onInputChange({ name: "ic_number", value: value });
  };

  const createIC = async (e) => {
    e?.preventDefault();

    setSubmitButtonPressed(true);

    let errors = validatorModule(
      {
        ...formState,
      },
      validationFormat
    );
    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const formData = { ...formState };
      let dataFormData = new FormData();
      const icId = getIDTypeId(formData.ic_type, appOptions.ic_types);
      formData.ic_type = icId;
      formData["person"] = applicantProfile?.data?.id;
      if (createIdFile) {
        formData["document"] = createIdFile;
      }
      if (formData.expiry) {
        formData.expiry = moment(formState.expiry).format("DD-MM-YYYY");
      } else {
        if (formData.hasOwnProperty("expiry")) {
          delete formData["expiry"];
        }
      }

      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const value = formData[key];
          dataFormData.append(key, value);
        }
      }

      if (isEdit) {
        axios
          .patch(
            `${apiRoutes.createIc.replace(
              "{personId}",
              applicantProfile?.data?.id
            )}/${formState.id}`,
            dataFormData,
            {
              headers: {
                Authorization: `JWT ${token}`,
                "content-type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            addToast(DISPLAY_TEXTS.PROFILE_ID_CREATED, {
              appearance: "success",
            });
            gateAllDocs.refetch();
            setErrorState(initialErrorState);
            setIsLoading(false);
            setSubmitButtonPressed(false);
            setFormState(initialFormState);
            hideAddDocumentModal();
          })
          .catch((error) => {
            setIsLoading(false);
            addToast(
              error.response.data.errors &&
                error.response.data.errors.length > 0 &&
                error.response.data.errors[0]?.detail
                ? error.response.data.errors[0]?.code === "unique"
                  ? "Selected ID Type already exits"
                  : error.response.data.errors[0]?.detail
                : "Something went wrong",
              { appearance: "error" }
            );
            setSubmitButtonPressed(false);
          });
      } else {
        axios
          .post(
            `${apiRoutes.createIc.replace(
              "{personId}",
              applicantProfile?.data?.id
            )}`,
            dataFormData,
            {
              headers: {
                Authorization: `JWT ${token}`,
                "content-type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            addToast(DISPLAY_TEXTS.PROFILE_ID_CREATED, {
              appearance: "success",
            });
            gateAllDocs.refetch();
            setErrorState(initialErrorState);
            setIsLoading(false);
            setSubmitButtonPressed(false);
            setFormState(initialFormState);
            hideAddDocumentModal();
          })
          .catch((error) => {
            setIsLoading(false);
            addToast(
              error.response.data.errors &&
                error.response.data.errors.length > 0 &&
                error.response.data.errors[0]?.detail
                ? error.response.data.errors[0]?.code === "unique"
                  ? "Selected ID Type already exits"
                  : error.response.data.errors[0]?.detail
                : "Something went wrong",
              { appearance: "error" }
            );
            setSubmitButtonPressed(false);
          });
      }
    }
  };

  const [viewProfilePermission, setViewProfilePermission] = useState(undefined);
  useEffect(() => {
    if (
      !applicantProfile?.data ||
      !userRoleDetails?.modulePermissions ||
      String(pageType ?? "").length === 0
    )
      return;
    const isPermissionAllowed = checkModuleViewPermission(
      userRoleDetails,
      pageType,
      "profile",
      applicantProfile?.data
    );
    setViewProfilePermission(isPermissionAllowed);
  }, [applicantProfile, userRoleDetails, pageType]);

  const closeProfileHistory = () => {
    setProfileHistoryOpen(false);
  };

  const showArchivePopup = () => {
    setArchiveModal(true);
  };
  const onArchiveSuccess = () => {
    setArchiveModal(false);
    applicantProfile.refetch();
  };
  return (
    <PermissionWrapper
      isAllowed={viewProfilePermission}
      isLoading={viewProfilePermission === undefined}
    >
      {/* {applicantProfile?.error?.status === "404" && (
        <div
          className={`reportStatusPopup show
        `}
        >
          <div className="popUp">
            <div className="content">
              {"The profile you are trying to view is not found !"}
            </div>
            <div className="action">
              <button
                className="btn btn-primary"
                onClick={() => onBackButtonClick()}
              >
                Go Back
              </button>
            </div>
          </div>
        </div>
      )} */}
      {applicantProfile?.data?.isPermitExpired === "yes" &&
        !applicantBannedStatus() && (
          <div className="ribbon">
            <div>
              {`The accessed profile has an expired work permit since ${applicantProfile.data?.workPermitExpiry}. Please take the necessary action.`}
            </div>
          </div>
        )}
      {applicantBannedStatus() && (
        <div className="ribbon">
          <div>BAN REASON: {`${applicantProfile?.data?.note}`}</div>
        </div>
      )}
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <button
              type="button"
              className="btn btn-primary btn-sm float-right backArrow"
              onClick={() => onBackButtonClick()}
            >
              <span></span>
            </button>
            <h1>View {DISPLAY_TEXTS.PAGE_HEADERS[pageParams.type]}</h1>
            <div className="contextMenu dropDownMenu coloredBorder rightAlign">
              <span
                className="user-role-action-icons"
                onClick={() => showHideContextMenu()}
              >
                <i className="simple-icon-equalizer user-role-action-icons"></i>
              </span>
              {showContextMenu && (
                <ul ref={menuRef}>
                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    pageType,
                    "update_profile",
                    applicantProfile.data
                  ) && (
                    <li>
                      <button
                        type="button"
                        onClick={() => redirectToEditPageBasedonType()}
                      >
                        <i className="iconsminds-pen"></i>Edit
                      </button>
                    </li>
                  )}
                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    pageType,
                    "view_idcard",
                    applicantProfile.data
                  ) && (
                    <li>
                      <button
                        type="button"
                        onClick={() => {
                          manageModalSettings(true, 9);

                          showHideContextMenu();
                        }}
                      >
                        <i className="iconsminds-id-card"></i>View ID Card
                      </button>
                    </li>
                  )}
                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    pageType,
                    "resync_permission",
                    applicantProfile.data
                  ) && (
                    <li>
                      <button onClick={() => syncProfile()} type="button">
                        <i className="iconsminds-sync"></i>Re-sync
                      </button>
                    </li>
                  )}

                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    pageType,
                    applicantProfile?.data?.status === "archived"
                      ? "restore_profile"
                      : "archive_profile",
                    applicantProfile.data
                  ) && (
                    <li>
                      <button type="button" onClick={() => showArchivePopup()}>
                        <i
                          className={
                            applicantProfile?.data &&
                            applicantProfile?.data?.status === "archived"
                              ? "iconsminds-inbox-out"
                              : "iconsminds-inbox-into"
                          }
                        ></i>
                        {applicantProfile?.data &&
                        applicantProfile?.data?.status === "archived"
                          ? "Restore"
                          : "Archive"}
                      </button>
                    </li>
                  )}
                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    pageType,
                    applicantProfile?.data?.status === "banned"
                      ? "unban_profile"
                      : "ban_profile",
                    applicantProfile.data
                  ) && (
                    <li>
                      <BanProfile data={applicantProfile.data} />
                    </li>
                  )}
                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    pageType,
                    "view_profilehistory",
                    applicantProfile.data
                  ) && (
                    <li>
                      <button
                        onClick={() => handleProfileHistoryOpen()}
                        type="button"
                      >
                        <i>
                          <BiHistory />
                        </i>
                        Profile History
                      </button>
                    </li>
                  )}
                  {checkModuleGeneralPermission(
                    userRoleDetails,
                    pageType,
                    "delete_profile",
                    applicantProfile.data
                  ) && (
                    <li>
                      <button
                        type="button"
                        onClick={() => showUserDeleteConfrimBox()}
                      >
                        <i className="simple-icon-trash"></i>Delete
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-5 col-lg-4 left-deck">
          <div className="card profile-cover">
            {applicantProfile.isLoading && <CardLoader />}

            <div
              className={
                applicantProfile?.data?.category.slug === "employee"
                  ? "image-holder withCam"
                  : "image-holder"
              }
            >
              <div
                className={
                  "profileStatus " +
                  (applicantProfile?.data && applicantProfile?.data?.status)
                }
              >
                {applicantProfile?.data && applicantProfile?.data?.status}
              </div>
              <img
                src={
                  applicantProfile?.data?.photoAdded &&
                  applicantProfile?.data?.personPhoto?.length
                    ? applicantProfile?.data?.personPhoto[0]?.avatar ??
                      profileimagePlaceholder
                    : profileimagePlaceholder
                }
                // src={
                //   applicantProfile?.data?.applicantPhoto &&
                //   applicantProfile?.data?.applicantPhoto[
                //     applicantProfile?.data?.applicantPhoto?.length - 1
                //   ]
                //     ? applicantProfile.data.applicantPhoto[
                //         applicantProfile.data.applicantPhoto?.length - 1
                //       ]?.avatar
                //     : profileimagePlaceholder
                // }
                alt={
                  applicantProfile?.data?.photoAdded &&
                  applicantProfile?.data?.personPhoto?.length
                    ? applicantProfile?.data?.personPhoto[0]?.id ??
                      pageParams?.id
                    : pageParams?.id
                }
                className="profile-image card-img-top"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = profileimagePlaceholder;
                }}
              />

              {checkModuleGeneralPermission(
                userRoleDetails,
                pageType,
                "update_avatar",
                applicantProfile.data
              ) && (
                <button
                  type="button"
                  className="btn btn-header-light icon-button mr-2"
                  onClick={() => managePhotoBooth(true)}
                >
                  <i className="simple-icon-camera"></i>
                </button>
              )}
            </div>

            <div className="card-body profile-details-box">
              <div className="row">
                <div className="col-md-12 mb-2">
                  <div className="applicantDetails">
                    <h5 className="profile-label-value font-weight-bold text-capitalize">
                      {" "}
                      {formatTextWithFallback(
                        applicantProfile?.data?.fullName
                      )}{" "}
                    </h5>
                    <div className="status-details">
                      <div className="applicant-type">
                        <i className="iconsminds-box-full"></i>
                        {formatTextWithFallback(
                          applicantProfile?.data?.category?.name
                        )}{" "}
                      </div>
                      {/* <div className="applicant-position in">
                        In
                      </div>


                      <div className="applicant-position out">
                        Out
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-2 col-lg-6">
                  <div className="profile-label mt-2"> Profile ID </div>
                  <h5 className="profile-label-value font-weight-bold">
                    {" "}
                    {formatTextWithFallback(
                      applicantProfile?.data?.profileId
                    )}{" "}
                  </h5>
                </div>

                <div className="col-md-12 mb-2 col-lg-6">
                  <div className="profile-label mt-2">
                    Last Visit
                    {String(
                      applicantProfile?.data?.personLastVisit[0]?.location ?? ""
                    ).length > 0 && (
                      <>
                        <i
                          className="iconsminds-information info-icon-small ml-1"
                          data-for="person-last-visit-tooltip"
                          data-tip={`Location: ${applicantProfile?.data?.personLastVisit[0]?.location}`}
                        />
                        <ReactTooltip
                          className="table-tooltip"
                          id="person-last-visit-tooltip"
                        />
                      </>
                    )}
                  </div>
                  <h5 className="profile-label-value font-weight-bold">
                    {" "}
                    {applicantProfile?.data?.personLastVisit?.length ? (
                      <>
                        {getLocalDateTime(
                          applicantProfile?.data?.personLastVisit[0]?.visitTime
                        )}
                      </>
                    ) : (
                      "--"
                    )}{" "}
                  </h5>
                </div>

                <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                  <div className="profile-label mt-2 text-capitalize">
                    {" "}
                    <span className="text-capitalize">
                      {" "}
                      {applicantProfile?.data &&
                        (applicantProfile?.data?.idType !== "passport"
                          ? formatTextWithFallback(
                              String(
                                applicantProfile?.data?.idType ?? ""
                              )?.toUpperCase()
                            )
                          : formatTextWithFallback(
                              applicantProfile?.data?.idType
                            ))}
                    </span>{" "}
                    Number{" "}
                  </div>
                  <h5 className="profile-label-value font-weight-bold text-uppercase">
                    {" "}
                    {formatTextWithFallback(
                      applicantProfile?.data?.idNumber
                    )}{" "}
                  </h5>
                </div>

                <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                  <div className="profile-label mt-2"> Gender </div>
                  <h5 className="profile-label-value font-weight-bold text-capitalize">
                    {" "}
                    {formatTextWithFallback(
                      applicantProfile?.data?.gender === "other"
                        ? "Prefer not to say"
                        : applicantProfile?.data?.gender
                    )}{" "}
                  </h5>
                </div>

                <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                  <div className="profile-label mt-2"> Date Of Birth </div>
                  <h5 className="profile-label-value font-weight-bold">
                    {" "}
                    {formatTextWithFallback(
                      applicantProfile?.data?.dateOfBirth
                    )}{" "}
                  </h5>
                </div>

                <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                  <div className="profile-label mt-2"> Phone </div>
                  <h5 className="profile-label-value font-weight-bold">
                    {" "}
                    {formatTextWithFallback(
                      applicantProfile?.data?.mobileNumber
                    )}{" "}
                  </h5>
                </div>
                <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                  <div className="profile-label mt-2"> Nationality </div>
                  <h5 className="profile-label-value font-weight-bold">
                    {applicantProfile.data &&
                      nationality &&
                      nationality[applicantProfile.data.nationality] &&
                      formatTextWithFallback(
                        nationality[applicantProfile.data.nationality]
                          ?.display_name
                      )}{" "}
                  </h5>
                </div>
                <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                  <div className="profile-label mt-2"> Profile Created On </div>
                  <h5 className="profile-label-value font-weight-bold">
                    {formatTextWithFallback(
                      getLocalDateTime(applicantProfile?.data?.created)
                    )}{" "}
                  </h5>
                </div>
                <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                  <div className="profile-label mt-2"> Register Mode </div>
                  <h5 className="profile-label-value font-weight-bold">
                    {_.startCase(
                      formatTextWithFallback(
                        _.find(
                          appOptions?.registerModes,
                          (regMode) =>
                            regMode?.value ===
                            applicantProfile?.data?.registerMode
                        )?.display_name ?? applicantProfile?.data?.registerMode
                      )
                    )}{" "}
                  </h5>
                </div>
                {applicantProfile?.data?.idType === "fin" && (
                  <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                    <div className="profile-label mt-2">
                      {" "}
                      Work Permit Expiry{" "}
                    </div>
                    <h5 className="profile-label-value font-weight-bold">
                      {applicantProfile?.data &&
                        formatTextWithFallback(
                          applicantProfile?.data?.workPermitExpiry
                        )}{" "}
                    </h5>
                  </div>
                )}
                <div className="col-xs-12 col-md-12 col-lg-12 mb-2">
                  <div className="profile-label mt-2"> Email </div>
                  <h5 className="profile-label-value font-weight-bold">
                    {" "}
                    {applicantProfile.data &&
                      formatTextWithFallback(
                        applicantProfile?.data?.email
                      )}{" "}
                  </h5>
                </div>
              </div>
              <div className="bottom-info">
                <div className="row">
                  {applicantProfile.data && pageParams.type === "employee" && (
                    <div className="col-xs-12 col-md-12 col-lg-6 mb-2">
                      <div className="profile-label mt-2"> Trade </div>
                      <h5 className="profile-label-value font-weight-bold">
                        {formatTextWithFallback(
                          applicantProfile?.data?.trade?.name
                        )}
                      </h5>
                    </div>
                  )}

                  <div
                    className={`col-xs-12 col-md-12 col-lg-${
                      pageParams?.type === "employee" ? 6 : 12
                    } mb-2`}
                  >
                    <div className="profile-label mt-2"> Designation </div>
                    <h5 className="profile-label-value font-weight-bold text-capitalize">
                      {" "}
                      {applicantProfile?.data &&
                        formatTextWithFallback(
                          applicantProfile?.data?.designation
                        )}{" "}
                    </h5>
                  </div>
                </div>
              </div>

              <div className="bottom-info">
                <div className="row">
                  <div className="col-xs-12 col-md-12 col-lg-12 mb-2">
                    <div className="profile-label mt-2">
                      Company Name{" "}
                      {checkModuleGeneralPermission(
                        userRoleDetails,
                        pageType,
                        "view_companyhistory",
                        applicantProfile?.data
                      ) && (
                        <span
                          className="history-icon"
                          onClick={() => handleHistoryOpen("company")}
                        >
                          <BiHistory />
                        </span>
                      )}
                    </div>
                    <h5 className="profile-label-value font-weight-bold">
                      {" "}
                      {formatTextWithFallback(
                        applicantProfile?.data?.company?.name
                      )}{" "}
                    </h5>
                  </div>
                  <div className="col-xs-12 col-md-12 col-lg-12 mb-2">
                    <div className="profile-label mt-2">
                      {" "}
                      Contract Name{" "}
                      {checkModuleGeneralPermission(
                        userRoleDetails,
                        pageType,
                        "view_contracthistory",
                        applicantProfile?.data
                      ) && (
                        <span
                          className="history-icon"
                          onClick={() => handleHistoryOpen("contract")}
                        >
                          <BiHistory />
                        </span>
                      )}
                    </div>
                    <h5 className="profile-label-value font-weight-bold">
                      {formatTextWithFallback(
                        applicantProfile?.data?.contract?.name
                      )}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-7 col-lg-8 mt-5 mt-md-0 right-deck">
          {applicantProfile.isLoading && <CardLoader />}
          <div className="row ">
            <div className="col-md-12 mb-4">
              <div className="card mb-4">
                <div
                  className={
                    applicantProfile.isLoading
                      ? "card-body dataTableOuter table-loader"
                      : "card-body dataTableOuter"
                  }
                >
                  {applicantProfile.data && (
                    <>
                      <div className="view-page-tab">
                        <div className="tab-wrap">
                          <button
                            type="button"
                            onClick={() => swicthTab("pass")}
                            className={
                              activeTab === "pass" ? "tab active" : "tab"
                            }
                          >
                            Access Passes
                          </button>
                          {checkModuleGeneralPermission(
                            userRoleDetails,
                            "person-document",
                            "view_document",
                            { profile: { ...applicantProfile?.data } }
                          ) && (
                            <button
                              type="button"
                              onClick={() => swicthTab("doc")}
                              className={
                                activeTab === "doc" ? "tab active" : "tab"
                              }
                            >
                              Documents
                            </button>
                          )}
                        </div>
                        {activeTab === "doc" &&
                          checkModuleGeneralPermission(
                            userRoleDetails,
                            "person-document",
                            "add_document",
                            { profile: { ...applicantProfile?.data } }
                          ) && (
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm float-right"
                              onClick={() => showAddDocumentModal()}
                              disabled={
                                applicantProfile?.data?.isPermitExpired ===
                                "yes"
                              }
                            >
                              Add Document
                            </button>
                          )}

                        {checkModuleGeneralPermission(
                          userRoleDetails,
                          "access-pass",
                          "add_pass",
                          { profile: applicantProfile?.data }
                        ) &&
                          activeTab === "pass" && (
                            <button
                              type="button"
                              className="btn btn-secondary btn-sm float-right"
                              onClick={() => onAddGatePass()}
                              disabled={
                                applicantProfile?.data?.isPermitExpired ===
                                "yes"
                              }
                            >
                              Add Access Pass
                            </button>
                          )}
                      </div>
                      {activeTab === "pass" && (
                        <DataTable
                          className="no-radius"
                          subHeader={false}
                          progressPending={gatePasses?.data ? false : true}
                          progressComponent={<p>&nbsp;</p>}
                          columns={columns}
                          data={gatePasses?.data}
                          customStyles={customStyles}
                          paginationServer={true}
                          striped={true}
                          onChangePage={onChangePage}
                          onChangeRowsPerPage={onChangeRowsPerPage}
                          pagination={true}
                          paginationPerPage={rowsPerPage}
                          paginationTotalRows={
                            gatePasses?.meta &&
                            gatePasses?.meta.pagination.count
                          }
                        />
                      )}

                      {activeTab === "doc" &&
                        (checkModuleGeneralPermission(
                          userRoleDetails,
                          "person-document",
                          "view_document",
                          { profile: { ...applicantProfile?.data } }
                        ) ? (
                          <DataTable
                            className="no-radius"
                            subHeader={false}
                            progressPending={gatePasses?.data ? false : true}
                            progressComponent={<p>&nbsp;</p>}
                            columns={docColumns}
                            data={gateAllDocs?.data}
                            customStyles={customStyles}
                            paginationServer={true}
                            striped={true}
                            onChangePage={onChangeDocPage}
                            onChangeRowsPerPage={onChangeDocRowsPerPage}
                            pagination={true}
                            paginationPerPage={docRowsPerPage}
                            paginationTotalRows={
                              gateAllDocs?.meta &&
                              gateAllDocs?.meta.pagination.count
                            }
                          />
                        ) : (
                          <p className="text-center pt-3">
                            No permission to view documents
                          </p>
                        ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip place="left" type="success" effect="solid" />
      <ModalComponent
        show={showModal}
        isLoaderActive={isLoaderActive}
        customClass="modal-xl webcam-modal"
        header={<b>Profile Picture</b>}
        content={
          checkModuleGeneralPermission(
            userRoleDetails,
            pageType,
            "update_avatar",
            applicantProfile.data
          ) && showModal ? (
            <CaptureImageComponent
              applicantId={pageParams.id}
              profileImages={
                applicantProfile?.data?.photoAdded &&
                applicantProfile?.data?.personPhoto?.length
                  ? applicantProfile?.data?.personPhoto
                  : []
              }
              status={showModal}
              onPictureCapture={refreshOnCaptureImage}
            />
          ) : (
            ""
          )
        }
        hideFooter={true}
        onSubmitCallback={() => {
          manageModal(false);
        }}
        onCloseCallback={() => {
          manageModal(false);
        }}
      />
      <ModalComponent
        customClass="access-pass-view"
        show={showGatePassModal}
        isLoaderActive={isLoaderActive}
        handlePrint={handlePrint}
        handlePDF={handlePDF}
        cancelButtonText={"Close"}
        header={<b>View Access Pass</b>}
        print={checkModuleGeneralPermission(
          userRoleDetails,
          "access-pass",
          "print_qrcode",
          { ...(passData ?? {}), profile: applicantProfile?.data ?? {} }
        )}
        download={checkModuleGeneralPermission(
          userRoleDetails,
          "access-pass",
          "download_qrcode",
          { ...(passData ?? {}), profile: applicantProfile?.data ?? {} }
        )}
        content={
          showGatePassModal && (
            <ViewGatePass
              loading={true}
              personId={applicantProfile?.data?.id}
              accessPassId={passData?.id}
              isPrint={isAccessPassPrint}
              handlePrint={setIsAccessPrint}
            />
          )
        }
        showSubmit={false}
        onCloseCallback={() => {
          setPassData(null);
          manageModalSettings(false, 0);
        }}
      />
      {showIDCardModal && (
        <ViewIDCard
          show={showIDCardModal}
          applicant={applicantProfile.data}
          onCloseCallback={() => {
            manageModalSettings(false, 9);
          }}
        />
      )}
      <ModalComponent
        show={editGatePassModal}
        isLoaderActive={isLoaderActive}
        header={<b>Edit Access Pass</b>}
        content={
          editGatePassModal ? (
            <CreateAccessPass
              errors={addPassErrors}
              personType={pageParams.type}
              onInputChange={onAddGatePassInputChange}
              passData={addPassData}
              addGatePassClicked={addGatePassClicked}
              isDateLoading={isPassFormDateLoading}
            />
          ) : null
        }
        showSubmit={true}
        onSubmitCallback={() => updateGatePassData()}
        onCloseCallback={() => {
          manageModalSettings(false, 1);
        }}
      />
      <ModalComponent
        show={addGatePassModal}
        isLoaderActive={isLoaderActive}
        header={<b>Add Access Pass</b>}
        content={
          addGatePassModal ? (
            <CreateAccessPass
              errors={addPassErrors}
              personType={pageParams.type}
              onInputChange={onAddGatePassInputChange}
              passData={addPassData}
              isDateLoading={isPassFormDateLoading}
              addGatePassClicked={addGatePassClicked}
            />
          ) : null
        }
        showSubmit={true}
        onSubmitCallback={() => addGatePassData()}
        onCloseCallback={() => {
          manageModalSettings(false, 3);
        }}
      />
      <ModalComponent
        show={gatePassRejectionModal}
        header={<b>Confirm Access Pass Rejection!</b>}
        content={
          <RejectApplication
            updateInputChange={onRejectInputChange}
            validation={rejectValidation}
            value={rejectData.note ?? ""}
          />
        }
        showSubmit={true}
        isLoaderActive={isRejectLoading}
        onSubmitCallback={() => confirmRejectGatePass()}
        onCloseCallback={() => handleCloseRejectConfirm()}
      />
      <ModalComponent
        show={showCancelConfirmationModal}
        header={<b>Confirm Access Pass Cancel</b>}
        content={
          <CancelApplication
            updateInputChange={onCancelInputChange}
            validation={cancelValidation}
            value={cancelData.note ?? ""}
          />
        }
        showSubmit={true}
        isLoaderActive={isCancelLoading}
        onSubmitCallback={() => confirmCancelGatePass()}
        onCloseCallback={() => handleCloseCancelConfirm()}
      />
      <ModalComponent
        show={gatePassApprovalModal}
        header={<b>Confirm</b>}
        content={"Are you sure you want to approve Access Pass?"}
        showSubmit={true}
        isLoaderActive={isApproveLoading}
        onSubmitCallback={() => confirmApproveGatePass()}
        onCloseCallback={() => {
          manageModalSettings(false, 6);
        }}
      />
      {historyOpen && (
        <ViewContractorHistory
          show={historyOpen}
          applicant={applicantProfile.data}
          historyType={historyType}
          onCloseCallback={() => {
            handleHistoryClose();
          }}
          canEdit={
            historyType === "company"
              ? checkModuleGeneralPermission(
                  userRoleDetails,
                  pageType,
                  "update_companyhistory",
                  applicantProfile?.data
                )
              : historyType === "contract"
              ? checkModuleGeneralPermission(
                  userRoleDetails,
                  pageType,
                  "update_contracthistory",
                  applicantProfile?.data
                )
              : false
          }
        />
      )}

      {profileHistoryOpen && (
        <>
          <ActionHistory
            onCloseCallback={closeProfileHistory}
            title="Profile History"
            show={profileHistoryOpen}
            applicant={applicantProfile.data}
            historyType={"profileHistory"}
          />
        </>
      )}
      {passHistoryOpen && (
        <>
          <ActionHistory
            onCloseCallback={handlePassHistoryClose}
            title="Pass History"
            show={passHistoryOpen}
            applicant={applicantProfile.data}
            pass={passData}
            historyType="passHistory"
          />
        </>
      )}
      <ModalComponent
        show={showResendConfirmationModal}
        header={<b>Resend Access Pass</b>}
        content={<p>Are you sure you want to resend Access Pass ?</p>}
        onSubmitCallback={resendPassInitiate}
        onCloseCallback={closeResendConfirm}
        isLoaderActive={isResendLoading}
        cancelButtonText={"No"}
        submitButtonText={"Yes"}
      />
      <ModalComponent
        show={showLatestNote}
        header={<b>Note</b>}
        content={
          String(passData?.note ?? "").trim().length ? (
            <span className="pre-line-area">{passData?.note}</span>
          ) : (
            <span className="opacity-50">No notes to display!</span>
          )
        }
        showSubmit={true}
        onCloseCallback={closeLatestNoteModal}
        onSubmitCallback={closeLatestNoteModal}
        submitButtonText={"Ok"}
        hideCancel={true}
      />
      <ModalComponent
        show={showDeleteUserModal}
        header={<b>Confirm Delete</b>}
        isLoaderActive={isDeleteProfileLoading}
        content={
          <p>
            Are you sure you want to delete the selected applicant details ?
          </p>
        }
        onSubmitCallback={deleteUserDetails}
        onCloseCallback={doCloseModal}
        submitButtonText="Delete"
        customClass="delete"
      />

      <ModalComponent
        show={showDeleteDocModal}
        header={<b>Confirm Delete</b>}
        isLoaderActive={isDeleteProfileLoading}
        content={<p>Are you sure you want to delete the document ?</p>}
        onSubmitCallback={deleteFile}
        onCloseCallback={doCloseDocDeleteModal}
        submitButtonText="Delete"
        customClass="delete"
      />

      <ModalComponent
        show={synConfrimationModal}
        header="Confirm Re-sync profile"
        content={`${
          applicantProfile.data && applicantProfile.data.status === "active"
            ? "Do you need to re-sync this profile ?"
            : "The profile is in " +
              (applicantProfile.data && applicantProfile.data.status) +
              " status, Do you need to re-sync this profile ?"
        }`}
        showSubmit={true}
        onSubmitCallback={() => confirmSyncProfile()}
        onCloseCallback={() => {
          manageModalSettings(false, 8);
        }}
      />
      {archiveModal && (
        <>
          <ArchiveProfilePopup
            useID={[pageParams.id]}
            profileStatus={applicantProfile?.data.status}
            closeArchivePopup={() => setArchiveModal(false)}
            archiveSuccess={() => onArchiveSuccess()}
          />
        </>
      )}
      {/* <ModalComponent
        show={archiveModal}
        header={`${
          applicantProfile?.data?.status === "archived"
            ? "Restore Profile"
            : "Archive Profile"
        }`}
        content={
          <div className="form-group">
            <label htmlFor="archiveNote">
              Reason
            </label>
            <textarea
              id="archiveNote"
              type="text"
              name="note"
              className="form-control"
              value={archiveNote}
              onChange={(e) => {
                setArchiveNote(e.target.value);
              }}
            ></textarea>
          </div>
        }
        showSubmit={true}
        onSubmitCallback={confirmArchiveProfile}
        onCloseCallback={() => {
          manageModalSettings(false, 10);
        }}
        isLoaderActive={isArchiveLoading}
        cancelButtonText={"Cancel"}
        submitButtonText={
          applicantProfile?.data?.status === "archived"
            ? "Restore"
            : "Archive"
        }
      /> */}
      {showSyncsuccessPopup && (
        <div className="sync-success sendLoader">
          <div className="sendLoaderWarpper">
            <div className="box">
              <div
                className={spinnerReady ? "spinner active" : "spinner"}
              ></div>
            </div>

            <div className="text">Your request is being processed</div>
            <div className={spinnerReady ? "sub active" : "sub"}>
              It will take <b>10 minutes</b> to complete
            </div>
            <div className={spinnerReady ? "okBtn active" : "okBtn"}>
              <button type="button" onClick={confirmSyncProfileOk}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div className="d-none">
        <PrintablePass
          ref={passContainer}
          loading={true}
          nationality={nationality}
          personProfile={applicantProfile.data}
          currentUser={currentUser}
          profileType={pageParams.type}
          passData={passData}
        />
      </div> */}

      <ModalComponent
        show={showDocumentModal}
        isLoaderActive={isLoading}
        header={isEdit ? <b>Edit Document</b> : <b>Add Document</b>}
        content={
          <div className="form-row w-100">
            <div
              className={`type col-xl-4 col-lg-4 mb-4 ${
                errorState["ic_type"] ? "hasValidationError" : ""
              }`}
            >
              <div className="label">ID Type</div>
              <Select
                className="formSelect"
                classNamePrefix="react-select-form"
                value={
                  formState.ic_type && formState.ic_type !== ""
                    ? getIdValues(formState.ic_type)
                    : { label: "Select", value: "" }
                }
                onChange={(e) =>
                  onInputChange({ name: "ic_type", value: e.value })
                }
                name="id_type"
                options={getIdOptions()}
              />

              <p className="error-message">
                {errorState && errorState["ic_type"]
                  ? errorState["ic_type"]
                  : ""}
              </p>
            </div>
            <div
              className={`id col-xl-8 col-lg-8  mb-4 ${
                errorState["ic_number"] ? "hasValidationError" : ""
              }`}
            >
              <div className="label">Document ID</div>
              {formState.ic_type === "cidb" ? (
                <>
                  <div className="cidb-wrap-btn">
                    <IdInput
                      value={formState["ic_number"]}
                      onInput={onCidbInput}
                    />
                    {/* <button type="button" className="btn btn-primary btn-sm">
                      Verify
                    </button> */}
                  </div>
                  <p className="error-message">
                    {errorState && errorState["ic_number"]
                      ? errorState["ic_number"]
                      : ""}
                  </p>
                </>
              ) : (
                <div>
                  <input
                    type="text"
                    className="form-control id-number"
                    name="ic_number"
                    value={formState?.ic_number?.toUpperCase()}
                    id="id_number"
                    placeholder="ID Number"
                    onChange={(e) => {
                      onInputChange(e.target);
                    }}
                  />
                  <p className="error-message">
                    {errorState && errorState["ic_number"]
                      ? errorState["ic_number"]
                      : ""}
                  </p>
                </div>
              )}
            </div>
            <div
              className={`expiry col-xl-4 col-lg-4 mb-4 mb-lg-0 ${
                errorState["expiry"] ? "hasValidationError" : ""
              }`}
            >
              <div className="label">ID Expiry</div>
              <DatePicker
                className="form-control"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                minDate={moment().toDate()}
                popperPlacement="top-end"
                popperClassName="some-custom-class"
                selected={getDayFirst(formState.expiry)}
                onChange={(date) =>
                  onInputChange({
                    name: "expiry",
                    value: date,
                  })
                }
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
              />
              <p className="error-message">
                {errorState && errorState["expiry"] ? errorState["expiry"] : ""}
              </p>
            </div>
            <div className="expiry col-xl-8 col-lg-8">
              <div className="label">Document</div>
              <div className={"file-and-btn"}>
                <label className="form-control file-control">
                  <input
                    id="id_file"
                    type="file"
                    name="id_file"
                    className="file"
                    onChange={(e) => getCreateIdFile(e)}
                    value={formState?.document}
                  />
                  <div className="fileName">
                    <span className="fileNameText">
                      {createIdFile?.name || formState?.documentName}
                    </span>
                  </div>
                  <div
                    type="button"
                    className="prompt btn btn-secondary btn-sm"
                  >
                    Upload
                  </div>
                </label>
              </div>
            </div>
          </div>
        }
        showSubmit={true}
        onSubmitCallback={() => createIC()}
        onCloseCallback={() => {
          hideAddDocumentModal();
        }}
      />
    </PermissionWrapper>
  );
};

export default ViewProfile;
