/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { Link } from "react-router-dom";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import _ from "lodash";
import DataTable from "react-data-table-component";
import { AppContext } from "../../context/AppContext";
import ContractorFormComponent from "../../components/ContractorFormComponent/ContractorFormComponent";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import ModalRightComponent from "../../components/common/ModalRightComponent/ModalRightComponent";
import SearchBar from "../../components/common/Search/Search";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import {
  checkModuleAddPermission,
  checkModuleDeletePermission,
  checkModuleEditPermission,
  formatTextWithFallback,
  trimDoubleSpace,
  validatorModule,
  getCurrentWindowParams,
} from "../../helpers/helpers";
import Select from "react-select";
import { apiRoutes } from "../../constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ContextMenuContentWrapper from "../../components/common/ContextMenuContentWrapper";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { defaultSelectOption } from "../../helpers/utils";

const Contractor = ({ location }) => {
  const history = useHistory();
  const initialState = {
    name: "",
  };

  const validationFormat = [
    {
      field: "name",
      isRequired: true,
      fieldName: "Contract Name",
    },
    // {
    //   field: "prefix",
    //   isRequired: true,
    //   fieldName: "Prefix",
    // },
  ];
  const initialErrorState = {
    name: "",
    // prefix: "",
  };
  const { addToast } = useToasts();

  const {
    showRightModal,
    manageRightModal,
    showModal,
    manageModal,
    initialPageParams,
    updateInitialPageParams,
    setPageUrlParams,
    userRoleDetails,
  } = React.useContext(AppContext);
  //useBodyClass('modal-open');
  const [mode, setMode] = React.useState("");
  const [contractorName, setContractorName] = React.useState("");
  // const [contractorPrefix, setContractorPrefix] = React.useState("");
  const [currentContractorId, setCurrentContractorId] = React.useState(0);
  const [currentContractAddedProfiles, setCurrentContractAddedProfiles] =
    React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [intialDataLoaded, setInitialDataLoaded] = React.useState(false);

  const [searchQueryString, setSearchQueryString] = React.useState("");
  const [formState, updateFormState] = React.useState(initialState);
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const windowParams = getCurrentWindowParams();
  const pageNumber = windowParams.get("page");
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    Number(pageNumber) || 1
  );

  const [profilesAdded, setProfilesAdded] = React.useState();

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(null);
  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [createContractor] = useMutation(apiRoutes.contract);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  let initialSearchKey;
  const searchWord = window.location.hash.split("search=")[1];
  if (searchWord) {
    initialSearchKey = decodeURI(searchWord);
  } else {
    initialSearchKey = "";
  }

  const [filter, setFilter] = React.useState({
    search: initialSearchKey,
  });

  const selectProfileLinked = (e) => {
    setProfilesAdded(e);
  };

  const contractors = useQuery(
    activePage && [
      apiRoutes.contract,
      {
        // filter: {
        //   search: searchQueryString,
        // },
        filter: filter,
        page: paginationSettings,
      },
    ],
    { client }
  );

  React.useEffect(() => {
    if (contractors.data && !contractors.isLoading) {
      setInitialDataLoaded(true);
    } else {
      setInitialDataLoaded(false);
    }
  }, [contractors]);

  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }
  }, []);

  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(Number(pageNumber));
      // setactivePage(initialPageParams.initialPageNumber);
      setactivePage(Number(pageNumber));
      setSearchQueryString(initialPageParams.initialSearchKey);
    }
  }, [initialPageParams, pageNumber]);

  const FilterSection = () => {
    return (
      <div className="advance-filter">
        <div>
          <span>Contracts</span>
          <div>
            <Select
              placeholder="Select"
              className="cutomSelect"
              classNamePrefix="react-select"
              value={profilesAdded}
              onChange={(e) => selectProfileLinked(e)}
              options={[
                { label: "Select", value: null },
                { label: "No profiles added", value: 0 },
                { label: "Profiles added", value: 1 },
              ]}
              defaultValue={defaultSelectOption()}
            />
          </div>
        </div>
      </div>
    );
  };

  const [updateContractor] = useMutation(
    apiRoutes.contractUpdate.replace("{id}", currentContractorId),
    { method: "PUT", client }
  );
  const onInputChange = (e) => {
    if (e.target.name === "name") {
      setContractorName(trimDoubleSpace(e.target.value?.toUpperCase()));
    }
    updateFormState({
      ...formState,
      [e.target.name]: trimDoubleSpace(e.target.value?.toUpperCase()),
    });
  };
  const manageModalSettings = (status) => {
    manageRightModal(status);
  };

  const deleteContractorDetails = async () => {
    const { error } = await client.delete(
      apiRoutes.contractUpdate.replace("{id}", currentContractorId)
    );
    if (error === undefined) {
      contractors.refetch();
      addToast(DISPLAY_TEXTS.CONTRACT_DELETED, { appearance: "success" });
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
    setCurrentContractorId(0);
    setCurrentContractAddedProfiles(0);
    manageModal(false);
  };

  const submitNewContractor = async () => {
    let errors = errorValidate();

    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      if (formState.name !== "") {
        const { data, error } = await createContractor({
          ...formState,
        });
        if (data && error === undefined) {
          contractors.refetch();
          addToast(DISPLAY_TEXTS.CONTRACT_ADDED, { appearance: "success" });
        } else if (error !== undefined && error.detail) {
          addToast(error.detail, { appearance: "error" });
        } else {
          addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
        }
        manageModalSettings(false);
        setContractorName("");
      }
    }
  };

  const updateContractorData = async () => {
    let errors = errorValidate();

    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      const { data, error } = await updateContractor({
        ...formState,
        id: currentContractorId,
      });
      if (data && error === undefined) {
        contractors.refetch();
        addToast(DISPLAY_TEXTS.CONTRACT_UPDATED, { appearance: "success" });
      } else if (error !== undefined && error.detail) {
        addToast(error.detail, { appearance: "error" });
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
      setMode("");
      manageModalSettings(false);
      setContractorName("");
    }
  };

  const addNewContractor = () => {
    setMode("add");
    setContractorName("");

    updateFormState({
      name: "",
    });
    setErrorState({
      name: "",
    });
    manageRightModal(true);
  };

  const updateContractorDetails = (id) => {
    setMode("edit");
    setCurrentContractorId(id);
    const exisitingData = _.keyBy(contractors.data, "id")[id];
    setContractorName(exisitingData.name);

    updateFormState({
      name: exisitingData.name,
    });
    setErrorState({
      name: "",
    });
    manageModalSettings(true);
  };

  const manageDeletedOption = (contractToDelete) => {
    setCurrentContractorId(contractToDelete?.id);
    setCurrentContractAddedProfiles(contractToDelete?.profilesAdded);
    manageModal(true);
  };

  const doCloseModal = () => {
    setCurrentContractorId(0);
    setCurrentContractAddedProfiles(0);
    manageModal(false);
  };
  const errorValidate = () => {
    let errors = validatorModule(formState, validationFormat);

    return errors;
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  const getContractParam = (name) => {
    if (String(name ?? "").length) {
      const param = new URLSearchParams();
      param.append("contract", name ?? "");
      return param.toString();
    }
    return "";
  };

  const getEvent = (mode) => {
    if (mode.mode === "edit") {
      updateContractorDetails(mode.contractors.id);
    }

    if (mode.mode === "delete") {
      manageDeletedOption(mode.contractors);
    }
    if (mode.mode === "profileLink") {
      history.push(`/all-profile?${getContractParam(mode.contractors.id)}`);
    }
  };

  let menuRef = React.useRef();
  const [tableUserId, setTableUserId] = React.useState("");

  React.useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (
        event.target.className !== "user-role-action-icons" &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setTableUserId("");
      }
    });
  });

  const showHideContextMenu = (userid) => {
    setTableUserId(userid);
  };

  const showContextActionMenu = (contract) => {
    const hasContractEditPermission = checkModuleEditPermission(
      userRoleDetails,
      "contract",
      "contract",
      contract
    );

    const hasContractDeletePermission = checkModuleDeletePermission(
      userRoleDetails,
      "contract",
      "contract",
      contract
    );

    return (
      (hasContractEditPermission || hasContractDeletePermission) && (
        <div className="contextMenu">
          {tableUserId === contract.id && (
            <ContextMenuContentWrapper
              showContextMenu={tableUserId === contract.id}
              handleContextMenuClose={() => setTableUserId("")}
            >
              <ul ref={menuRef}>
                {hasContractEditPermission && (
                  <>
                    <li onClick={() => updateContractorDetails(contract.id)}>
                      <button>
                        <i className="iconsminds-pen user-role-action-icons"></i>
                        Edit
                      </button>
                    </li>
                  </>
                )}
                {hasContractDeletePermission && (
                  <li onClick={() => manageDeletedOption(contract)}>
                    <button>
                      <i className="simple-icon-trash user-role-action-icons delete"></i>
                      Delete
                    </button>
                  </li>
                )}
              </ul>
            </ContextMenuContentWrapper>
          )}
          <span
            className="user-role-action-icons"
            onClick={() => showHideContextMenu(contract.id)}
          >
            <i></i>
          </span>
        </div>
      )
    );
  };

  const columns = [
    {
      name: "Contract",
      selector: (row) => row["name"],
      sortable: true,
      cell: (contractors) => (
        <ProfileCard
          data={contractors}
          mode="contract"
          actions={userRoleDetails}
          actionsToParent={(mode) => getEvent({ mode, contractors })}
        />
      ),
    },
    {
      name: "Added profiles",
      maxWidth: 200,
      selector: (row) => row["profilesAdded"],
      sortable: true,
      center: true,
      cell: (contract) => <>{formatTextWithFallback(contract.profilesAdded)}</>,
      hide: "md",
    },
    {
      name: "Actions",
      maxWidth: 150,
      minWidth: 150,
      sortable: false,
      cell: (contract) => (
        <>
          {contract.profilesAdded > 0 && (
            <Link
              to={`/all-profile?${getContractParam(contract.id)}`}
              title="View linked profiles"
            >
              <span className="iconsminds-link user-role-action-icons edit"></span>
            </Link>
          )}
          {showContextActionMenu(contract)}
        </>
      ),
      hide: "md",
      right: true,
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    const setParams = {
      page: page,
      searchQueryString: searchQueryString,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setFilter({ ...filter, search: searchQuery });
    setactivePage(1);
    const setParams = {
      page: 1,
      searchQueryString: searchQuery,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
        setFilter({ ...filter, search: wordString });
      } else {
        setSearchQueryString("");
        setFilter({ ...filter, search: "" });
      }

      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  const handleResetFilter = () => {
    for (let key of ["profiles_added", "profiles_added_gt"]) {
      delete filter[key];
    }
    setFilter({ ...filter });
    setProfilesAdded();
  };

  React.useEffect(() => {
    if (
      _.hasIn(filter, "profiles_added") ||
      _.hasIn(filter, "profiles_added_gt")
    ) {
      setFilterApplied(true);
      return;
    }
    setFilterApplied(false);
  }, [filter]);

  const handleApplyFilter = () => {
    setCurrentPageNumber(1);
    showFilter(false);
    if (profilesAdded?.value == 0) {
      const { ["profiles_added_gt"]: omit, ...rest } = filter;
      setFilter({ ...rest, profiles_added: 0 });
    } else if (profilesAdded?.value == 1) {
      const { ["profiles_added"]: omit, ...rest } = filter;
      setFilter({ ...rest, profiles_added_gt: 0 });
    } else {
      for (let key of ["profiles_added", "profiles_added_gt"]) {
        delete filter[key];
      }

      setFilter({ ...filter });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>Contracts</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <div className="mobile-search">
                    <SearchBar
                      onSearchInputChange={onSearchInputChange}
                      popstateText={searchQueryString}
                    />
                  </div>
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <FilterSection />
                  <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={() => {
                        handleResetFilter();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-secondary m-0"
                      onClick={() => {
                        handleApplyFilter();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`tableFilterButton rightAlign ${
                  filterApplied ? "btn-secondary" : ""
                }`}
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>
              {checkModuleAddPermission(
                userRoleDetails,
                "contract",
                "contract"
              ) && (
                <button
                  type="button"
                  title="Add Contract"
                  className="tableFilterButton ml-2 rightAlign btn-secondary"
                  onClick={() => addNewContractor()}
                >
                  <>+</>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                contractors.isLoading || !intialDataLoaded
                  ? "card-body dataTableOuter table-loader"
                  : "card-body dataTableOuter"
              }
            >
              <DataTable
                subHeader={true}
                progressPending={contractors.isLoading || !intialDataLoaded}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={contractors.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={
                  contractors.meta && contractors.meta.pagination.count
                }
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={<b>Confirm Delete</b>}
        content={
          <>
            <p>
              Are you sure you want to delete the selected Contract details ?
            </p>
            {currentContractAddedProfiles > 0 && (
              <p>
                Please note: {currentContractAddedProfiles} profiles are linked
                to this contract{" "}
              </p>
            )}
          </>
        }
        onSubmitCallback={deleteContractorDetails}
        onCloseCallback={doCloseModal}
        submitButtonText="Delete"
        customClass="delete"
      />

      <ModalRightComponent
        show={showRightModal}
        header={mode === "add" ? "Add Contract" : "Update Contract"}
        submitButtonText={mode === "add" ? "Submit" : "Update"}
        classes="normal-right-modal"
        content={
          <ContractorFormComponent
            title={
              mode === "add"
                ? 'Submitting below form will add "a new contract".'
                : 'Submitting below form will update "the contract".'
            }
            contractorName={contractorName}
            errorState={errorState}
            updateInputChange={onInputChange}
          />
        }
        onSubmitCallback={() =>
          mode === "add" ? submitNewContractor() : updateContractorData()
        }
        onCloseCallback={() => manageModalSettings(false)}
      />
    </>
  );
};

export default Contractor;
