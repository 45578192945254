import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./PublicRegistrationLanding.scss";

import logo from "../../assets/images/branding-w.png";
import { API_BASE_URL, apiRoutes } from "../../constants";
import Axios from "axios";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import Loader from "../../components/common/Loader/Loader";
import { getCurrentWindowParams } from "../../helpers/helpers";
import useInviteValidity from "../../hooks/useInviteValidity/useInviteValidity";

const PublicRegistrationLanding = () => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState("new-visitor");
  const { isValidated } = useInviteValidity();

  const handleRedirect = () => {
    if (selectedOption === "new-visitor") {
      history.push(`/self-registration/new-visitor`);
    } else {
      history.push(`/self-registration/existing-visitor`);
    }
  };

  if(isValidated === null) return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Loader />
    </div>
  );
  
  if(!isValidated) return(
    <div className="message-wrapper">
      <div className="success">
        <div className="icon iconsminds-close"></div>
        <div className="message">{DISPLAY_TEXTS.PUBLIC_INVITE_INVALID_LINK}</div>
      </div>
    </div>
  );

  return (
    <>
      {
        <div className="row justify-content-md-center">
          <div className="public-landing col-10 col-xs-8 col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-3 pt-3 pb-3">
            <img src={logo} alt="" className="mx-auto d-block" />
            <div className="row mt-3">
              <div className="col">
                <div>Please select the form to continue:</div>
                <div className="btn-wrap mt-2">
                  <button
                    className={`radio-btn btn-label px-2 py-2 ${selectedOption === "new-visitor" ? "active" : ""}`}
                    onClick={() => { setSelectedOption("new-visitor") }}>
                    <span
                      className="label-text"
                      htmlFor="rdoNewVisitor"
                    >
                      New Visitor
                    </span>
                    <div className="radio-container">
                      <div className="round">
                        <input
                          type="radio"
                          id="rdoNewVisitor"
                          name="visitorOptions"
                          className="radio-input"
                          checked={selectedOption === "new-visitor"}
                          value="new-visitor"
                        />
                        <label for="rdoNewVisitor"></label>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="btn-wrap mt-3">
                  <button
                    className={`radio-btn btn-label px-2 py-2 ${selectedOption === "existing-visitor" ? "active" : ""}`}
                    onClick={() => { setSelectedOption("existing-visitor") }}>
                    <span
                      className="label-text"
                      htmlFor="rdoNewVisitor"
                    >
                      Existing Visitor
                    </span>
                    <div className="radio-container">
                      <div className="round">
                        <input
                          type="radio"
                          id="rdoExistingVisitor"
                          name="visitorOptions"
                          className="radio-input"
                          checked={selectedOption === "existing-visitor"}
                          value="new-visitor"
                        />
                        <label for="rdoExistingVisitor"></label>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col text-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleRedirect}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PublicRegistrationLanding;
