import React, { useContext, useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "react-multi-date-picker/styles/colors/teal.css";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import InputIcon from "react-multi-date-picker/components/input_icon";

import { AppContext } from "../../context/AppContext";
import { useEffect, useImperativeHandle } from "react";
import { forwardRef, useRef } from "react";
import moment from "moment-timezone";
import ClearButton from "../common/ClearButton/ClearButton";

const CustomCalendar = forwardRef((props, ref) => {
  const { theme } = useContext(AppContext);
  const calanderRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(props.selected);
  const handleDateChange = (val) => {
    let date = val ? new DateObject(val) : undefined;
    setSelectedDate(date);
    if (!props.disableOnChange) {
      props.onChange(date ? new Date(date) : undefined);
    }
  };

  const handleIntialTimeChange = () => {
    if (!props.timePicker || String(selectedDate ?? "").length) return;
    ref?.current?.handleChange(new Date());
  };

  useImperativeHandle(ref, () => ({
    handleChange(value) {
      setSelectedDate(value);
    },
    handleFetchDate() {
      let dateValue = selectedDate ? new Date(selectedDate) : undefined;
      return dateValue
        ? moment(dateValue).format("YYYY-MM-DD HH:mm")
        : undefined;
    },
  }));

  useEffect(() => {
    setSelectedDate(props.selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customCalender">
      <DatePicker
        format={props.dateFormat ?? "YYYY-MM-DD hh:mm A"}
        ref={calanderRef}
        render={
          props?.isClearable ? null : <InputIcon className="form-control" />
        }
        inputClass={`form-control`}
        {...(props.disabled ? { disabled: props.disabled } : {})}
        {...(props.readOnly ? { readOnly: props.readOnly } : {})}
        {...(props.maxDate ? { maxDate: props.maxDate } : {})}
        {...(props.minDate ? { minDate: props.minDate } : {})}
        {...(props.popperPlacement ? { fixMainPosition: true } : {})}
        {...(props.popperPlacement
          ? { calendarPosition: props.popperPlacement }
          : {})}
        className={theme === "dark-theme" ? "bg-dark" : "teal"}
        value={selectedDate}
        onChange={(date) => {
          handleDateChange(date);
        }}
        onOpen={handleIntialTimeChange}
        placeholder={props.placeholder ?? ""}
        plugins={[
          ...(props.timePicker
            ? [<TimePicker hideSeconds position="bottom" />]
            : []),
        ]}
      />
      {props?.isClearable && props.selected && (
        <ClearButton onClick={() => props?.onClear && props.onClear()} />
      )}
    </div>
  );
});

export default CustomCalendar;
