import React from "react";
import { useHistory } from "react-router-dom";
import { useClient, useQuery, useMutation } from "jsonapi-react";
import { parseAPIResponseError } from "../../helpers/helpers";
import loginImage from "../../assets/images/login_img.png";
import logowhite from "../../assets/images/logo_w.png";
import logo from "../../assets/images/logo.png";
import { VW_ROUTES } from "../../constants";

export const VerifyUser = (props) => {
  const history = useHistory();
  const {
    match: {
      params: { email_key },
    },
  } = props;
  const initialState = {
    new_password1: "",
    new_password2: "",
  };
  const [formState, updateFormState] = React.useState(initialState);
  const [errorMsgs, updateErrorMessage] = React.useState({});
  const client = useClient();
  let mainError = "";
  const { error: verifyError, data: verifyData } = useQuery([
    "auth",
    "verify",
    "email",
    email_key,
  ]);
  if (verifyError && verifyError.detail) {
    mainError = verifyError.detail;
  } else if (verifyData) {
    client.config.headers = {
      Authorization: `JWT ${verifyData.token}`,
    };
    mainError = "";
  }
  const [updatePassword] = useMutation(["auth", "password", "change"], {
    client,
  });
  const onInputChangeEvent = (e) => {
    updateFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };
  const onResetPasswordSubmit = async (event) => {
    event.preventDefault();
    const { error, data } = await updatePassword(formState);
    if (error) {
      updateErrorMessage(parseAPIResponseError(error));
    } else if (data) {
      history.push(VW_ROUTES.LOGIN);
    }
  };
  return (
    <>
      <div className="login-cover">
        <div className="left-section">
          <div className="content-portion">
            <div className="logo_w">
              <img src={logowhite} alt="v-watch" />
            </div>
            <h1>
              <span>Welcome to </span>v-watch Registration Portal
            </h1>
            <p>
              Vwatch a revolutionary solution for enterprises to become hybrid
              workplaces. We offer a comprehensive suite that will help you
              automate work with AI and optimise your space in alignment with
              the hybrid needs of your workforce.
            </p>
            <p>
              Smart Solutions for smarter places. Delight your workforce with
              features designed to make their workplace experiences more
              seamless.
            </p>
          </div>
          <div className="image-holder">
            <img src={loginImage} alt="v-watch" />
          </div>
        </div>

        <div className="right-section">
          <div className="content-portion">
            <div className="content-inner">
              <div className="logo">
                <img src={logo} alt="v-watch" />
              </div>
              <h2>Setup Password</h2>
              <p>Please enter details to reset your password.</p>

              <p className="nonFieldError mb-3">{mainError}</p>

              <form onSubmit={onResetPasswordSubmit}>
                <label className="form-group has-float-label mb-4">
                  <input
                    onChange={(e) => onInputChangeEvent(e)}
                    name="new_password1"
                    type="password"
                    className="form-control m-0"
                  />
                  <span>Password</span>
                  <p className="login-error-message">
                    {errorMsgs && errorMsgs.field === "newPassword1"
                      ? errorMsgs.errorMsg
                      : ""}
                  </p>
                </label>
                <label className="form-group has-float-label mb-4">
                  <input
                    onChange={(e) => onInputChangeEvent(e)}
                    name="new_password2"
                    type="password"
                    className="form-control m-0"
                  />
                  <span>Confirm Password</span>
                  <p className="login-error-message">
                    {errorMsgs && errorMsgs.field === "newPassword2"
                      ? errorMsgs.errorMsg
                      : ""}
                  </p>
                </label>
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary btn-lg btn-shadow"
                    type="submit"
                  >
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="fixed-background login-background"></div>
            <main>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side login-inner-background">
                                    <p className="text-white h2">Welcome to TWRP Password Reset</p>
                                    <p className="white mb-0">Please enter details to reset your password.<br/>
                                    </p>
                                </div>
                                <div className="form-side"><a href="Dashboard.Default.html"><span className="logo-single"></span></a>
                                <p className="nonFieldError">{mainError}</p>
                                    <h6 className="mb-4">Setup Password</h6>
                                    <form onSubmit={onResetPasswordSubmit}>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="new_password1" type="password" className="form-control" />
                                            <span>Password</span>
                                            <p className="login-error-message">{errorMsgs && errorMsgs.field === 'newPassword1' ? errorMsgs.errorMsg : ''}</p>
                                        </label>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="new_password2" type="password" className="form-control" />
                                            <span>Confirm Password</span>
                                            <p className="login-error-message">{errorMsgs && errorMsgs.field === 'newPassword2' ? errorMsgs.errorMsg : ''}</p>
                                        </label>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <button className="btn btn-primary btn-lg btn-shadow" type="submit">SUBMIT</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main> */}
    </>
  );
};

export default VerifyUser;
