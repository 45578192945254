import { useEffect } from "react";
import _ from "lodash";
import { ACS_EVENT_TYPES_OPTIONS, API_BASE_URL } from "../constants";
import Axios from "axios";
import moment from "moment-timezone";

/* session maintainance starts here  */
export const getSession = () => {
  if (localStorage.getItem("access_token") === null) {
    return false;
  }
  if (isAuthenticated()) {
    return parseJwt(localStorage.getItem("access_token"));
  } else {
    return false;
  }
};

export const setSession = (authResult) => {
  // Set the time that the access token will expire at
  let expiresAt = JSON.stringify(new Date().getTime() + 86400000);
  localStorage.setItem("access_token", authResult.token);
  localStorage.setItem("expires_at", expiresAt);
};

export const logout = () => {
  // Clear access token and ID token from local storage
  localStorage.clear();
  sessionStorage.clear();
};

export const isAuthenticated = () => {
  let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
  if (new Date().getTime() < expiresAt) {
    return true;
  } else {
    return false;
  }
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

/* session maintainance ends here  */

/* add remove class to body tag starts here  */

export const addBodyClass = (className) =>
  document.body.classList.add(className);
export const removeBodyClass = (className) =>
  document.body.classList.remove(className);

export const useBodyClass = (className) => {
  useEffect(() => {
    // Set up
    className instanceof Array
      ? className.map(addBodyClass)
      : addBodyClass(className);

    // Clean up
    return () => {
      className instanceof Array
        ? className.map(removeBodyClass)
        : removeBodyClass(className);
    };
  }, [className]);
};
/* add remove class to body tag ends here  */

/**
 * Theme Colors
 */

export const ThemeColors = () => {
  return {
    themeColor1: "#145388",
    themeColor2: "#10939f",
    themeColor3: "#6c90a1",
    primaryColor: "#212121",
    foregroundColor: "#ffffff",
    separatorColor: "#d7d7d7",
  };
};

/**
 * Create post data format
 * parameters: @type, @attributes
 * return json
 */

export const getAPIFormDataFormat = (type, attributes) => ({
  data: {
    type: type,
    attributes: attributes,
  },
});

/**
 * Update post data format
 * parameters: @type, @id, @attributes
 * return json
 */

export const getAPIUpdateFormDataFormat = (type, id, attributes) => ({
  data: {
    type,
    id,
    attributes,
  },
});

export const userPermissionParser = (permissions) => {
  const parsedPermissions = [];
  const permissionParser = (newPermissions) => {
    _.forEach(newPermissions, (value) => {
      if (value.subMenu) {
        permissionParser(value.subMenu);
      }
      parsedPermissions.push({
        route: value.route,
        actions: value.actions,
      });
    });
  };
  permissionParser(permissions);
  return parsedPermissions;
};

export const schema = {
  auth_login: {
    type: "user_auth",
    status: {
      resolve: (status) => {
        return status.toUpperCase();
      },
    },
  },
};

export const formatDate = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
  return (
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  );
};

export const getLastMonths = (n) => {
  var m = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var last_n_months = [];
  var d = new Date();
  var mnth = d.getMonth();
  for (let i = 0; i < n; i++) {
    let mnt = mnth - i;
    if (mnt >= 0) {
      last_n_months[i] = m[d.getMonth() - i];
    } else {
      last_n_months[i] = m[12 + mnt];
    }
  }

  return last_n_months;
};

export const setToStorage = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
};
export const getFromStorage = (key) => {
  let result = localStorage.getItem(key);
  if (result) {
    return JSON.parse(localStorage.getItem(key));
  }
  return null;
};
export const removeFromStorage = (data) => {
  if (Array.isArray(data)) {
    data.forEach((item) => {
      localStorage.removeItem(item);
    });
  } else {
    localStorage.removeItem(data);
  }
};

export const initialStepper = [
  {
    id: 1,
    title: "Personal Details",
    isCurrent: true,
    isCompleted: false,
  },
  {
    id: 2,
    title: "Additional Info",
    isCurrent: false,
    isCompleted: false,
  },
  {
    id: 3,
    title: "Verification Info",
    isCurrent: false,
    isCompleted: false,
  },
  {
    id: 4,
    title: "Completed",
    isCurrent: false,
    isCompleted: false,
  },
];

export const initialStepperWithTrade = [
  {
    id: 1,
    title: "Personal Details",
    isCurrent: true,
    isCompleted: false,
  },
  {
    id: 2,
    title: "Additional Info",
    isCurrent: false,
    isCompleted: false,
  },
  {
    id: 3,
    title: "Trade Info",
    isCurrent: false,
    isCompleted: false,
  },
  {
    id: 4,
    title: "Verification Info",
    isCurrent: false,
    isCompleted: false,
  },
  {
    id: 5,
    title: "Completed",
    isCurrent: false,
    isCompleted: false,
  },
];

export const updatedStepperData = (currentStep, stepperData) => {
  const stepArray = _.map(stepperData, (item) => {
    if (currentStep === stepperData.length) {
      item["isCurrent"] = true;
      item["isCompleted"] = true;
    } else if (item.hasOwnProperty("id") && item.id < currentStep) {
      item["isCurrent"] = false;
      item["isCompleted"] = true;
    } else if (item.hasOwnProperty("id") && item.id === currentStep) {
      item["isCurrent"] = true;
      item["isCompleted"] = false;
    } else {
      item["isCurrent"] = false;
      item["isCompleted"] = false;
    }
    return item;
  });
  return stepArray;
};

export const getContractorOptions = (contracts) => {
  let contractorArray = [{ label: "Select", value: "" }];

  if (contracts?.data?.length > 0) {
    _.forEach(_.orderBy(contracts?.data, "name", "asc"), (contract) => {
      contractorArray.push({
        label: contract.name,
        value: contract.id,
      });
    });
  }

  return contractorArray;
};

export const getContractOptionFromId = (contracts, contractId) =>
  getContractorOptions(contracts)?.find(
    (contractOption) => contractOption.value === contractId
  ) ?? { label: "Select", value: "" };

export const getKeyName = (key) => {
  switch (key) {
    case "employee":
      return "Employee";
    case "visitor":
      return "Visitor";
    case "staff":
      return "Staff";
    case "frequentDelivery":
      return "Frequent Delivery";
    case "delivery-adhoc":
      return "Delivery Adhoc";
  }
};

export const getIcon = (key) => {
  switch (key) {
    case "employee":
      return "iconsminds-male";
    case "visitor":
      return "iconsminds-business-mens";
    case "staff":
      return "iconsminds-male";
    case "frequentDelivery":
      return "iconsminds-box-close";
    case "delivery-adhoc":
      return "iconsminds-box-close";
  }
};

export const constructFilterString = (filters) => {
  return _.compact(
    _.map(filters, (value, key) => {
      if (value !== "") {
        return `${key}=${value}`;
      }
    })
  ).join("&");
};

export const getACSEventTypeLabel = (value) =>
  ACS_EVENT_TYPES_OPTIONS?.find((opt) => String(opt?.value) === String(value))
    ?.label ?? "";

export const selectOptionsDefaultOptionAppender = (
  options = [],
  isAll = false
) =>
  _.find(options, (item) => String(item?.label) == "All")
    ? options
    : [
        ...[
          isAll ? { label: "All", value: "" } : { label: "Select", value: "" },
        ],
        ...options,
      ];

export const defaultSelectOption = (isAll = false) =>
  isAll ? { label: "All", value: "" } : { label: "Select", value: "" };

export const formatValueWithDefaultOption = (value, isAll = false) =>
  value?.value && String(value?.value).length
    ? value
    : defaultSelectOption(isAll);

const defaultFromDateMoment = () => moment().subtract(30, "days");
const defaultToDateMoment = () => moment();

export const defaultFromDateFilterValue = (format) =>
  format
    ? defaultFromDateMoment().format(format)
    : defaultFromDateMoment().toDate();

export const defaultToDateFilterValue = (format) =>
  format
    ? defaultToDateMoment().format(format)
    : defaultToDateMoment().toDate();
