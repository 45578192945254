// @flow
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { AppContext } from "../../../../context/AppContext";
import { getChartScalingConfig } from "../../../../helpers/helpers";

const chartHeight = 300;
const maxTickHeightPX = 25;

// simple line chart
const LineChart = (props) => {
  const { theme } = React.useContext(AppContext);
  const [colors, setColors] = useState(props.colors);
  const [title, setTitle] = useState(props.title);
  const [categories, setCategories] = useState(props.xAxis);
  const [dataArray, setDataArray] = useState(props.dataArray);
  const [data, setData] = useState(props.data);
  const [width, setWidth] = useState(props.width);

  useEffect(() => {
    setColors(props.colors);
    setTitle(props.title);
    setWidth(props.width);
    setCategories(props.xAxis);
    setData(props.data);
    setDataArray(props.dataArray);
  }, [props.colors, props.title, props.data, props.xAxis, props.width, props.dataArray]);

  const yScaleConfig = getChartScalingConfig(dataArray, chartHeight, maxTickHeightPX);

  const options = {
    chart: {
      height: "auto",
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: colors,

    tooltip: {
      theme: theme === "light-theme" ? "dark" : "light",
      x: { show: false },
      shared: true,
      intersect: false,
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      //   offsetX: 15,
    },
    stroke: {
      width: [3, 3],
      curve: "smooth",
    },
    title: {
      text: title,
      align: "left",
      style: {
        fontSize: "14px",
        color: "transparent",
      },
    },
    grid: {
      show: false,
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 0,
      },
      borderColor: "#f1f3fa10",
    },
    markers: {
      style: "inverted",
      size: 1,
    },
    xaxis: {
      categories: categories,
      type: "category",
      tickPlacement: "between",
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "#78909C20",
      },
      labels: {
        style: {
          colors: "#10939f",
        },
      },
    },

    yaxis: {
     ...yScaleConfig,
      labels: {
        formatter: function (val) {
          let y = val ? val.toFixed(0) : 0;

          return y;
        },
        minWidth: 20,
        maxWidth: 160,
        style: {
          colors: ["#10939f"],
        },
      },
    },
    legend: {
      position: "top",
      showForSingleSeries: true,
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
      labels: {
        useSeriesColors: true,
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          legend: {
            show: true,
          },
        },
      },
    ],
  };

  return (
    <Chart
      options={options}
      series={data}
      type="line"
      width={"100%"}
      height={chartHeight}
      className="apex-charts"
    />
  );
};

export default LineChart;
