import React, { useContext } from "react";
import Slider from "react-slick";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import { VW_ROUTES } from "../../../../constants";
import { AppContext } from "../../../../context/AppContext";
import { useToasts } from "react-toast-notifications";
import Axios from "axios";
import { manageSlidesData } from "../../../../mappers/dashboardMappers";
import {
  checkModuleGeneralPermission,
  isWidgetRefreshable,
} from "../../../../helpers/helpers";
import ReactTooltip from "react-tooltip";

const DashboardSliderComponent = ({ widgetData, title, slug }) => {
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();
  const [sliderData, setSliderData] = React.useState(undefined);
  const [sliderType, setSliderType] = React.useState(undefined);
  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const [sliderDataLoading, setSliderDataLoading] = React.useState(true);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const excelData = [];
  const { currentUser } = React.useContext(AppContext);

  // const fileName = "Contractor_live_People-"+moment().format("DD:MM:YYYY_h:mm_a");

  _.map(_.head(sliderData ?? []), (item) => {
    const obj = {
      "Package Name": item.name,
      "Live person Count": item.count,
    };
    excelData.push(obj);
  });

  const history = useHistory();

  const getSliderRow = (items, rowIdex) => (
    <div key={_.uniqueId("row-")} className="contractor-slide">
      <div className="row">
        {_.map(items, (item) => getBoxItem(item, rowIdex))}
      </div>
    </div>
  );

  const parseContractData = (contracts) => {
    return Object.entries(contracts).map(([key, value]) => ({
      ...value,
      name: key,
    }));
  };

  const parseCompanyData = (companies) => {
    return Object.entries(companies).map(([key, value]) => ({
      ...value,
      name: key,
    }));
  };

  const parseChannelData = (channels) => {
    return channels && channels?.length
      ? channels?.map((channelData) => ({
          id: channelData?.channelId,
          name: channelData?.channelName,
          count: channelData?.count,
        }))
      : [];
  };

  const token = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  const fetchData = () => {
    setSliderDataLoading(true);
    Axios.get(widgetData?.widget.config.url, config)
      .then((response) => {
        if (response?.data?.data?.contracts) {
          setSliderType("contract");
          setSliderData(
            manageSlidesData(parseContractData(response.data.data.contracts))
          );
        } else if (response?.data?.data?.company) {
          setSliderType("company");
          setSliderData(
            manageSlidesData(parseCompanyData(response.data.data.company))
          );
        } else if (response?.data?.channelList) {
          setSliderType("channels");
          setSliderData(
            manageSlidesData(parseChannelData(response?.data?.channelList))
          );
        } else {
          setSliderData([]);
        }
      })
      .catch((error) => {
        setSliderData([]);
      })
      .finally(() => {
        setSliderDataLoading(false);
      });
  };

  const updateData = () => {
    setIsUpdateLoading(true);
    Axios.get(widgetData?.widget.config.url + "/update", config)
      .then((response) => {
        addToast(
          response?.data?.data?.message ?? "Widget updated Successfully!",
          { appearance: "success" }
        );
      })
      .catch((error) => {
        addToast(error.detail ?? "Failed to update widget!", {
          appearance: "error",
        });
      })
      .finally(() => setIsUpdateLoading(false));
  };

  React.useEffect(() => {
    fetchData();
    if (
      widgetData?.widget?.config?.refreshRate &&
      widgetData?.widget?.config?.refreshRate > 0
    ) {
      const timeoutId = setInterval(() => {
        fetchData();
      }, widgetData?.widget?.config?.refreshRate * 1000);
      return () => {
        clearInterval(timeoutId); // Clear the timeout if the component is unmounted or the effect is cleaned up
      };
    }
  }, [title]);

  const getSlides = (sliderData) =>
    _.map(sliderData, (items, rowIdex) => {
      let loopsNumber = Math.ceil(items.length / 9);

      let sliderRow = [];
      if (items.length > 9) {
        for (let i = 0; i < loopsNumber; i++) {
          let countFrom;
          if (i === 0) {
            sliderRow.push(getSliderRow(items.slice(i, 9), rowIdex));
            countFrom = 9;
          } else {
            sliderRow.push(
              getSliderRow(
                items.slice(countFrom, parseInt(countFrom + 6)),
                rowIdex
              )
            );
            countFrom = countFrom + 9;
          }
        }
      } else {
        sliderRow.push(getSliderRow(items.slice(0, items.length), rowIdex));
      }
      return sliderRow;
    });

  const redirectToApplicantListing = (name) => {
    if (!String(name ?? "").length) return;
    const param = new URLSearchParams();
    param.append(sliderType, name);
    history.push({
      pathname: VW_ROUTES.LIVE_PEOPLE_LISTING,
      search: param.toString(),
    });
  };

  const redirectToPPEListing = (id) => {
    if (!String(id ?? "").length) return;
    const param = new URLSearchParams();
    param.append("listType", "channel");
    param.append("id", id);
    history.push({
      pathname: VW_ROUTES.DAILY_PPE_EVENTS_LISTING,
      search: param.toString(),
    });
  };

  const redirectOnBoxClick = (boxItem) => {
    switch (sliderType) {
      case "company":
        redirectToApplicantListing(boxItem?.name);
        break;
      case "contract":
        redirectToApplicantListing(boxItem?.name);
        break;
      case "channels":
        redirectToPPEListing(boxItem?.id);
        break;
      default:
        break;
    }
  };

  const getBoxItem = (item, rowIdex) => {
    return (
      <div key={_.uniqueId("box-")} className="contractorBox col-md-4">
        <div
          onClick={() => redirectOnBoxClick(item)}
          className="countBox d-flex align-self-center position-relative progress-banner gradient-orange"
        >
          <span className="m-auto text-uppercase">
            {item.name}
            <span className="pl-2"> </span>
          </span>
          <span className="position-absolute badge-top-right badge badge-danger badge-pill">
            {item.count}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="card mat-card mb-4 min-h-full">
      <div className="card-header">
        <span>{title}</span>
        <div className="d-flex align-items-center dashboard-loader">
          {sliderDataLoading && (
            <span className="input-loader-container mr-2">
              <div
                className="spinner-border spinner-border-sm"
                role="status"
              ></div>
            </span>
          )}
          {isWidgetRefreshable(userRoleDetails, widgetData) && (
            <span
              onClick={() => {
                !isupdateLoading && updateData();
              }}
              className={`refresh-button ${isupdateLoading ? "spin" : ""}`}
              data-tip="Update Data"
            >
              <i className="iconsminds-refresh" />
            </span>
          )}
        </div>
      </div>
      <ReactTooltip />
      <div
        className={
          "card-body Contractor-slide-wrap Contractor-slide-min-height widget-data-container"
        }
      >
        {!sliderDataLoading && (
          <>
            <Slider {...settings}>{getSlides(sliderData ?? [])}</Slider>
          </>
        )}
        {sliderData !== undefined && sliderData?.length === 0 && (
          <div className="contractor-slide">
            <div className="no-data-overlay no-data-overlay-w-bg">
              <span>No Data Available!</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default DashboardSliderComponent;
