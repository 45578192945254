import React from "react";
import _ from "lodash";
import Select from "react-select";
const UserFormComponent = ({
  mode,
  title,
  roles,
  formData,
  selectedContracts,
  errorState,
  onContractorChange,
  updateInputChange,
  contracts,
  roleType,
  updateRoleInputChange,
}) => {
  const parseToSelectFormat = (options, keys) =>
    _.map(options, (option) => ({
      label: option[keys.label],
      value: option[keys.value],
    }));

  const getRoleData = (rls) => {
    let roleArray = [
      {
        label: "Role",
        value: "",
      },
    ];
    if (rls && rls.length > 0) {
      rls.forEach((item, i) => {
        roleArray.push({
          label: item.name,
          value: item.id,
          key: i,
          slug: item.slug,
        });
      });
    }
    return roleArray;
  };
  return (
    <>
      <p>{title}</p>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState.fullName ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Full Name*</b>
        </label>
        <input
          type="text"
          value={formData.fullName}
          name="fullName"
          className="form-control"
          placeholder="Full Name"
          onChange={(e) => updateInputChange(e.target)}
        />
        <span className="validation-error-message">
          {errorState && errorState.fullName ? errorState.fullName : ""}
        </span>
      </div>
      {/* <div className="form-group position-relative">
				<label><b>Last Name(Optional)</b></label>
				<input type="text" value={formData.lastName} name="lastName" className="form-control" placeholder="Last Name" onChange={(e) => updateInputChange(e.target)} />
				<span className="validation-error-message">{errors.field === "lastName" && errors.errorMsg}</span>
			</div> */}
      {/* <div className="form-group position-relative">
				<label><b>Username</b></label>
				<input type="text" value={formData.username} name="username" className="form-control" placeholder="Username" onChange={(e) => updateInputChange(e.target)} />
				<span className="validation-error-message">{errors.field === "username" && errors.errorMsg}</span>
			</div> */}
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState.email ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Email*</b>
        </label>
        <input
          className={
            mode === "edit" ? "disabled-input form-control" : "form-control"
          }
          type="text"
          value={formData.email}
          name="email"
          placeholder="Email"
          readOnly={mode === "edit"}
          onChange={(e) => updateInputChange(e.target)}
        />
        <span className="validation-error-message">
          {errorState && errorState.email ? errorState.email : ""}
        </span>
      </div>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState.phoneNumber ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Phone</b>
        </label>
        <input
          type="text"
          value={formData.phoneNumber}
          name="phoneNumber"
          className="form-control"
          placeholder="Phone"
          onChange={(e) => updateInputChange(e.target)}
        />
        <span className="validation-error-message">
          {errorState && errorState.phoneNumber ? errorState.phoneNumber : ""}
        </span>
      </div>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState.role ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Role*</b>
        </label>
        <Select
          className="cutomSelect"
          classNamePrefix="react-select"
          value={formData.role}
          placeholder="Role"
          name="role"
          onChange={(e) => updateRoleInputChange(e)}
          options={getRoleData(roles.data)}
        />
        <span className="validation-error-message">
          {errorState && errorState.role ? errorState.role : ""}
        </span>
      </div>
      {roleType === "account-coordinator" && (
        <div className="form-group position-relative">
          <label>
            <b>Contracts</b>
          </label>
          <Select
            value={selectedContracts}
            className="cutomSelect"
            classNamePrefix="react-custom-select"
            onChange={(opt) => onContractorChange(opt)}
            isMulti
            closeMenuOnSelect={false}
            options={parseToSelectFormat(contracts, {
              label: "name",
              value: "id",
            })}
            name="contract"
            placeholder="Select"
          />
          <span className="validation-error-message">
            {errorState && errorState.contracts ? errorState.contracts : ""}
          </span>
        </div>
      )}
    </>
  );
};

export default UserFormComponent;
