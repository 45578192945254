import React, { forwardRef, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import axios from "axios";
import { useReactToPrint } from "react-to-print";
import { apiRoutes } from "../../../constants";
import IDLoader from "../Loader/IDLoader";

const ViewGatePass = ({ personId, accessPassId, isPrint, handlePrint }) => {
  const accessPassRef = useRef();
  const [accessPass, setAccessPass] = useState(null);
  const [accessPassLoading, setAccessPassLoading] = useState(true);
  const [isImagesLoading, setIsImagesLoading] = useState(true);

  const handleAccessPrint = useReactToPrint({
    content: () => accessPassRef.current,
    bodyClass: "",

    copyStyles: true,

    onBeforePrint: () => {},
  });

  useEffect(() => {
    if (!isPrint) return;
    handleAccessPrint();
    handlePrint(false);
  }, [isPrint]);

  React.useEffect(() => {
    if (!String(personId ?? "").length || !String(accessPassId ?? "").length)
      return;
    const token = localStorage.getItem("access_token");
    const options = {
      url: apiRoutes.getAccessPassView
        .replace("{personId}", personId)
        .replace("{accessPassId}", accessPassId),
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    };
    axios(options)
      .then((response) => {
        setAccessPass(response.data);
      })
      .catch((_error) => {})
      .finally(() => {
        setAccessPassLoading(false);
      });
  }, [personId, accessPassId]);

  useEffect(() => {
    if (!accessPassRef?.current || !isImagesLoading || !accessPass) return;
    const imagePromiseArray = [];
    let elements = accessPassRef?.current?.getElementsByTagName("IMG");
    Array.from(elements || [])?.forEach((imageElement) => {
      const imagePromise = new Promise((resolve, reject) => {
        imageElement.onload = () => resolve();
        imageElement.onerror = () => reject();
      });
      imagePromiseArray.push(imagePromise);
    });
    imagePromiseArray.length &&
      Promise.allSettled(imagePromiseArray).then(() =>
        setIsImagesLoading(false)
      );
  }, [accessPassRef, accessPass]);

  return (
    <div className="access-pass-content-box">
      {(accessPassLoading || isImagesLoading) && <IDLoader />}
      <div
        ref={accessPassRef}
        id="idsection"
        className={isImagesLoading ? "invisible" : "visible"}
      >
        {accessPass && parse(accessPass)}
      </div>
    </div>
  );
};
export default ViewGatePass;
