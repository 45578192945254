import React from "react";
import { DashboardReducer, ACTION_TYPES } from "../reducers/DashboardReducer";

const initialState = {
  sumUpCounters: [],
  splitUpCounters: [],
  splitUpPendingApprovals: [],
  splitUpPendingPassApprovals: [],
  contractorCarouselLists: [],
};

export const DashboardContext = React.createContext();
export const DashboardProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(DashboardReducer, initialState);
  const updateSumUpCounters = (counters) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SUMUP_COUNTERS,
      payload: counters,
    });
  };

  const updateSplitUpCounters = (counters) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SPLITUP_COUNTERS,
      payload: counters,
    });
  };
  const updateSplitUpPendingApprovals = (counters) => {
  
    dispatch({
      type: ACTION_TYPES.UPDATE_PENDING_APPROVALS,
      payload: counters,
    });
  };
  const updateSplitUpPendingPassApprovals = (counters) => {
  
    dispatch({
      type: ACTION_TYPES.UPDATE_PENDING_PASS_APPROVALS,
      payload: counters,
    });
  };

  const updateContractorCarouselLists = (data) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_CONTRACTOR_CAROUSEL_LISTS,
      payload: data,
    });
  };

  return (
    <DashboardContext.Provider
      value={{
        sumUpCounters: state.sumUpCounters,
        splitUpCounters: state.splitUpCounters,
        splitUpPendingApprovals: state.splitUpPendingApprovals,
        contractorCarouselLists: state.contractorCarouselLists,
        splitUpPendingPassApprovals:state.splitUpPendingPassApprovals,
        updateSumUpCounters,
        updateSplitUpPendingApprovals,
        updateSplitUpPendingPassApprovals,
        updateSplitUpCounters,
        updateContractorCarouselLists,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
