import React from "react";
import { Route } from "react-router-dom";
import { isAuthenticated } from "../../helpers/utils";
import { Redirect } from "react-router-dom";

const ContextRoute = ({
  context: Provider,
  component: Component,
  layout: Layout,
  isLoginRequired = true,
  ...rest
}) => {
  return (
    <Route
      exact
      {...rest}
      render={(props) => {
        if (!isAuthenticated() && isLoginRequired) {
          return <Redirect to="/login" />;
        }
        return (
          <Layout {...props} {...rest}>
            <Provider>
              <Component {...props} />
            </Provider>
          </Layout>
        );
      }}
    />
  );
};

export default ContextRoute;
