import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useClient, useQuery } from "jsonapi-react";
import { API_BASE_URL, apiRoutes } from "../../../constants";
import ProgressBar from "../ProgressBar/ProgressBar";
import { isWidgetRefreshable } from "../../../helpers/helpers";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";
import { useToasts } from "react-toast-notifications";

const UserStats = ({ widgetData , slug, title }) => {
  const history = useHistory();
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();
  const [chartData, setChartData] = React.useState(0);
  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const [stats, setStats] = React.useState();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const statusData = useQuery([apiRoutes.dashboardWidgetsData + "/" + slug], {
    client,
  });

  const updateData = () => {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    setIsUpdateLoading(true);
    Axios.get(apiRoutes.userStatsUpdate, config)
      .then((response) => {
        addToast(response?.data?.data?.message ?? "Widget updated Successfully!", { appearance: "success" });
      })
      .catch((error) => {
        addToast(error.detail ?? "Failed to update widget!", { appearance: "error" });
      }).finally(() => setIsUpdateLoading(false));
  }

  React.useEffect(() => {
    if(widgetData?.widget?.config?.refreshRate &&  widgetData?.widget?.config?.refreshRate > 0){
      const timeoutId = setInterval(() => {
        statusData.refetch();
      }, widgetData?.widget?.config?.refreshRate * 1000);
      return () => {
        clearInterval(timeoutId); // Clear the timeout if the component is unmounted or the effect is cleaned up
      };
    }
  }, []);

  React.useEffect(() => {
    if (statusData) {
      setStats(statusData.data);
    }
  }, [statusData]);

  React.useEffect(() => {
    if (stats) {
      setChartData(
        (stats?.widgetData?.stats?.total / stats?.widgetData?.quota) * 100
      );
    }
  }, [stats]);

  return (
    <div className="card right-notification">
      <div className="card-header with-count">
        {stats 
          ? <>
              <span>{title}</span>
              {isWidgetRefreshable(userRoleDetails, widgetData) &&  
                <span 
                  onClick={() => {!isupdateLoading && updateData()}}
                  className={`refresh-button ${isupdateLoading ? "spin" : ""}`} 
                  data-tip="Update Data" >
                    <i className="iconsminds-refresh" />
                </span>
              }
            </> 
          : <div className="skeleton w-80"></div>}
      </div>
      <div className="expiry-card-body">
        <ProgressBar size="normal" percentage={chartData} />

        <div className="pt-1 justify-space-between">
          <span>
            User usage :
          </span>
          <span>
            <b>{stats?.widgetData?.stats?.total}</b> /{" "}
            <b>{stats?.widgetData?.quota}</b>
          </span>

        </div>
      </div>
    </div>
  );
};

export default UserStats;
