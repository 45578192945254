import React from "react";

function IDLoader({ open = true, className = '' }) {
  if(!open) return <></>;
  return (
    <div className={`modal-content-loader-container ${className}`}>
        <div className="lds-grid">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
    </div>
  );
}

export default IDLoader;
