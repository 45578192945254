import React from "react";
import { components } from "react-select";
import ClearButton from "../ClearButton/ClearButton";

const CustomSelectClearIndicator = (indicatorProps) => {
  const { selectProps, ...otherProps } = indicatorProps;
  if (!String(selectProps?.value?.value ?? "").length > 0) return null;
  return (
    <components.ClearIndicator selectProps={selectProps} {...otherProps}>
      <ClearButton />
    </components.ClearIndicator>
  );
};

export default CustomSelectClearIndicator;
