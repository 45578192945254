import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import Select from "react-select";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";

import {
  validatorModule,
  getDayFirst,
  convertToDayFirst,
  formatTextWithFallback,
} from "../../helpers/helpers";
import { API_BASE_URL, apiRoutes } from "../../constants";

import logo from "../../assets/images/branding-w.png";
import defaultAvatar from "../../assets/images/profileimagePlaceholder.jpg";

import "./PublicRegistrationExistingUser.scss";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../components/common/Loader/Loader";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import useInviteValidity from "../../hooks/useInviteValidity/useInviteValidity";

const options = {
  method: "OPTIONS",
  headers: {
    "content-type": "application/json",
  },
  url: apiRoutes.publicRegister,
};

const validationVerifyFormRules = [
  {
    field: "idType",
    isRequired: true,
    fieldName: "ID Type",
  },
  {
    field: "idNumber",
    isRequired: true,
    maxLength: 16,
    fieldName: "ID Number",
  },
];

const validationFormRequestPassRules = [
  {
    field: "from_date_time",
    isRequired: true,
    fieldName: "Visit Date",
  },
  {
    field: "host_name",
    fieldName: "Name of PIC being visited",
    isRequired: true,
    minLength: 3,
  },
  {
    field: "purpose_of_visit",
    fieldName: "Purpose of Visit",
    isRequired: true,
    minLength: 3,
  },
];

const initialVerifyFormState = {
  idType: "",
  idNumber: "",
  categoryId: "",
};

const initialFormRequestPassState = {
  from_date_time: "",
  host_name: "",
  purpose_of_visit: "",
};

const postDataForExistsUser = {
  data: {
    type: "person",
    attributes: null,
  },
};

const axiosConfigFormData = {
  headers: {
    "content-type": "multipart/form-data",
  },
};
const defaultErrorMessage = "Something went wrong. Please try later"

const ProfileModal = ({
  showModal,
  onClickCancel,
  profileData,
  onClickConfirm,
}) => {
  const handleModalToggle = () => {
    onClickCancel();
  };

  const handleModalConfirm = () => {
    onClickConfirm();
  };

  return (
    <>
      <div
        className={`modal ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-main personal-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title" id="exampleModalLabel">
                Please confirm your profile
              </h6>
            </div>
            <div className="modal-body vw_profile m-4">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 vw_col_left_wrapper">
                  <div className="vw_col_inner_wrapper container">
                    <img src={profileData?.avatar} className="img-fluid profile-image" />
                    <h5 className="mt-3 mb-3 text-name">
                      <b>{profileData?.attributes?.fullName}</b>
                    </h5>
                    <button type="button" className="user-type">
                      Visitor
                    </button>
                    <label>
                      profile status:{" "}
                      <span
                        className={`${profileData?.attributes?.status || ""}`}
                      >
                        {profileData?.attributes?.status}
                      </span>
                    </label>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex">
                  <div className="row mb-3 py-5 px-3">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                      Profile ID <br />
                      <span>
                        <b>{profileData?.attributes?.profileId || ""}</b>
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                      ID Type <br />
                      <span>
                        <b className="text-uppercase">
                          {profileData?.attributes?.idType || ""} {" "}
                        </b>
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                      Date of birth <br />
                      <span>
                        <b>{profileData?.attributes?.dateOfBirth || ""}</b>
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                      ID Number <br />
                      <span>
                        <b className="text-uppercase">
                          {profileData?.attributes?.idNumber || ""}
                        </b>
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                      Gender <br />
                      <span>
                        <b className="text-capitalize">
                          {profileData?.attributes?.gender || ""}
                        </b>
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                      Nationality <br />
                      <span>
                        <b>{profileData?.attributes?.nationality || ""}</b>
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                      Profile Created Date <br />
                      <span>
                        <b>
                          {formatTextWithFallback(
                            convertToDayFirst(
                              profileData?.attributes?.created?.split("T")[0]
                            )
                          )}
                        </b>
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 mb-2">
                      Company <br />
                      <span>
                        <b>{profileData?.attributes?.company?.name || ""}</b>
                      </span>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleModalToggle}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                disabled={profileData?.attributes?.status != "active"}
                onClick={handleModalConfirm}
              >
                Confirm
              </button>
              {/* Additional buttons or content in the footer */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
      ></div>
    </>
  );
};

const ShowSuccessMessage = ({ message }) => {
  return (
    <div className="form-reg">
      <div className="success">
        <div className="icon"></div>
        <div className="message">{message?.message}</div>
      </div>
    </div>
  );
};

const PublicRegistrationExistingUser = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [icTypes, setIcTypes] = useState([]);
  const [formVerifyState, setFormVerifyState] = useState(
    initialVerifyFormState
  );
  const [formRequestPass, setFormRequestPass] = useState(
    initialFormRequestPassState
  );
  const [errorState, setErrorState] = useState({});
  const [verifyFormSubmissionStatus, setVerifyFormSubmissionStatus] =
    useState(false);
  const [profileData, setProfileData] = useState(null);
  const [confirmedProfileData, setConfirmedProfileData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formRequestPassStatus, setFormRequestPassStatus] = useState(false);
  const [formRequestPassResult, setFormRequestPassResult] = useState(null);
  const { isValidated } = useInviteValidity();

  useEffect(() => {
    axios(options).then((response) => {
      const {
        data: {
          data: {
            related_field_data: {
              ic_types: { choices: icTypes },
              category: { choices: categories },
            },
          },
        },
      } = response;

      // Remove cidb
      setIcTypes(icTypes.filter((icType) => icType.slug !== "cidb"));

      setFormVerifyState((prevState) => ({
        ...prevState,
        categoryId: categories.filter(
          (category) => category.slug === "visitor"
        )[0]?.value,
      }));
    });
  }, []);

  useEffect(() => {
    if (Object.keys(errorState).length) {
      let errors = validatorModule(formVerifyState, validationVerifyFormRules);
      setErrorState(errors);
    } else {
      setErrorState({});
    }
  }, [formVerifyState]);

  useEffect(() => {
    if (Object.keys(errorState).length) {
      let errors = validatorModule(
        formRequestPass,
        validationFormRequestPassRules
      );
      setErrorState(errors);
    } else {
      setErrorState({});
    }
  }, [formRequestPass]);

  const onInputChange = ({ name, value }) => {
    let formData = { ...formVerifyState };
    formData[name] = value ? value : "";
    setFormVerifyState(formData);
  };
  const onInputChangeVisitorForm = ({ name, value }) => {
    let formData = { ...formRequestPass };
    formData[name] = value ? value : "";
    setFormRequestPass(formData);
  };

  const getUserData = async () => {
    const postData = { ...postDataForExistsUser };
    postData.data.attributes = { ...formVerifyState };
    setVerifyFormSubmissionStatus(true);
    setProfileData(null);
    setConfirmedProfileData(null);
    setFormRequestPass(initialFormRequestPassState);
    await axios
      .post(
        `${API_BASE_URL}${apiRoutes.publicRegisterUserExists}?include=person_photo`,
        postData
      )
      .then((response) => {
        if (response.status === 200) {
          const avatar =
            response?.data?.included?.[0]?.attributes?.avatar || defaultAvatar;
          setProfileData({ ...response?.data?.data, avatar });
          setShowModal(true);
        }
      })
      .catch((error) => {
        addToast(
          `${error?.response?.data?.errors?.message ||
          "Something went wrong. Please try again"
          } `,
          {
            appearance: "error",
          }
        );
      })
      .finally(() => {
        setVerifyFormSubmissionStatus(false);
      });
  };

  const postRequestPassForExistsUser = async () => {
    const postData = { pass: { ...formRequestPass } };
    postData.pass.from_date_time = convertToDayFirst(
      postData.pass.from_date_time
    );
    setFormRequestPassStatus(true);
    await axios
      .post(
        `${API_BASE_URL}${apiRoutes.publicRegisterExistsUserRequestPass.replace(
          "{self_visitor_existing_id}",
          confirmedProfileData.id
        )}`,
        postData,
        axiosConfigFormData
      )
      .then((response) => {
        if (response.status === 201) {
          setProfileData(null);
          setConfirmedProfileData(null);
          setFormRequestPass(initialFormRequestPassState);
          setFormVerifyState(initialVerifyFormState);
          setFormRequestPassResult(response?.data?.data);
        }
      })
      .catch((error) => {
        const errors = error?.response?.data?.errors
          errors.forEach(item => {
            addToast(item?.detail || defaultErrorMessage, {
              appearance: "error",
            });
          });
      })
      .finally(() => {
        setFormRequestPassStatus(false);
      });
  };

  const profileModalCancelHandler = () => {
    setShowModal(false);
  };

  const profileModalConfirmHandler = () => {
    setShowModal(false);
    setConfirmedProfileData({ ...profileData });
  };

  const onSubmitVerifyForm = async (e) => {
    e.preventDefault();
    let errors = validatorModule(formVerifyState, validationVerifyFormRules);
    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      getUserData();
    }
  };

  const onSubmitFormRequestPass = async (e) => {
    e.preventDefault();
    let errors = validatorModule(
      formRequestPass,
      validationFormRequestPassRules
    );
    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      postRequestPassForExistsUser();
    }
  };

  if(isValidated === null) return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Loader />
    </div>
  );
  
  if(!isValidated) return(
    <div className="message-wrapper">
      <div className="success">
        <div className="icon iconsminds-close"></div>
        <div className="message">{DISPLAY_TEXTS.PUBLIC_INVITE_INVALID_LINK}</div>
      </div>
    </div>
  );

  return (
    <>
      {!formRequestPassResult && (
        <div className="row justify-content-md-center">
          <div className="public-landing  reg-wrap col-10 col-xs-8 col-sm-9 col-md-8 col-lg-7 col-xl-6 col-xxl-6 pt-3 pb-3 bg-white">
            <img src={logo} alt="" className="mx-auto d-block mb-3" />
            <h3 className="text-center">Self Registration</h3>
            <form
              className="mt-4 needs-validation"
              id="verifyUserForm"
              onSubmit={onSubmitVerifyForm}
              noValidate
            >
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="selectIcType">ID Type*</label>
                  <Select
                    className={`formSelect ${errorState?.idType ? "is-invalid" : ""
                      }`}
                    classNamePrefix="react-select-form"
                    options={icTypes}
                    getOptionLabel={(option) => option.display_name}
                    onChange={(e) =>
                      onInputChange({ name: "idType", value: e.slug })
                    }
                    name="idType"
                    id="selectIcType"
                    placeholder="Select"
                  />
                  <div
                    className={`error-message ${errorState?.idType ? "invalid-feedback" : ""
                      }`}
                  >
                    {errorState?.idType || ""}
                  </div>
                </div>
                <div className="form-group col-md-4 mb-3">
                  <label htmlFor="inputIdNumber" className="mt-md-0 mt-2">ID Number*</label>
                  <input
                    type="text"
                    className={`form-control ${errorState?.idNumber ? "is-invalid" : ""
                      }`}
                    id="inputIdNumber"
                    placeholder="ID Number"
                    name="idNumber"
                    onChange={(e) => onInputChange(e.target)}
                    value={formVerifyState?.idNumber?.toUpperCase()}
                  />
                  <div
                    className={`error-message ${errorState?.idNumber ? "invalid-feedback" : ""
                      }`}
                  >
                    {errorState?.idNumber || ""}
                  </div>
                </div>
                <div className="form-group mt-sm-2 col-md-4 col-sm-12 d-flex align-items-end justify-content-center justify-content-xxl-start justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-center justify-content-xs-center mt-xs-2 mt-2">
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    id="buttonVerify"
                  >
                    {verifyFormSubmissionStatus && (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Loading...</span>
                      </>
                    )}
                    {!verifyFormSubmissionStatus &&
                      confirmedProfileData == null &&
                      "Verify"}
                    {!verifyFormSubmissionStatus &&
                      confirmedProfileData !== null && (
                        <>
                          {" "}
                          {"Verified "}
                          <i className="simple-icon-check"></i>
                        </>
                      )}
                  </button>
                </div>
              </div>
            </form>
            <form
              className="mb-4 needs-validation"
              id="formRegisterPass"
              onSubmit={onSubmitFormRequestPass}
              noValidate
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label htmlFor="inputFullName">Full Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputFullName"
                      placeholder="Full Name"
                      name="inputFullName"
                      disabled={true}
                      value={confirmedProfileData?.attributes?.fullName || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="dateOfVisit">
                      Date of Visit<i>*</i>
                    </label>
                    <div
                      className={`input-group date ${errorState?.from_date_time ? "is-invalid" : ""
                        }`}
                    >
                      <DatePicker
                        className={`form-control ${errorState?.from_date_time ? "is-invalid" : ""
                          }`}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        minDate={moment().toDate()}
                        popperPlacement="top-end"
                        popperClassName="some-custom-class"
                        selected={getDayFirst(formRequestPass?.from_date_time)}
                        onChange={(date) =>
                          onInputChangeVisitorForm({
                            name: "from_date_time",
                            value: date,
                          })
                        }
                        showMonthDropdown
                        useShortMonthInDropdown
                        showYearDropdown
                        disabled={confirmedProfileData == null}
                        popperModifiers={{
                          preventOverflow: {
                            enabled: true,
                          },
                        }}
                      />
                      {/* <input className="dateFocusCrack" ref={dateField} /> */}
                    </div>
                    <div
                      className={`error-message ${errorState?.from_date_time ? "invalid-feedback" : ""
                        }`}
                    >
                      {errorState?.from_date_time || ""}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label htmlFor="inputHostName">
                      Name of PIC being visited<i>*</i>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${errorState?.host_name ? "is-invalid" : ""
                        }`}
                      name="host_name"
                      value={formRequestPass?.host_name}
                      id="inputHostName"
                      placeholder="Enter the name of PIC being visited"
                      onChange={(e) => onInputChangeVisitorForm(e.target)}
                      disabled={confirmedProfileData == null}
                    />
                    <div
                      className={`error-message ${errorState?.host_name ? "invalid-feedback" : ""
                        }`}
                    >
                      {errorState?.host_name || ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <label htmlFor="inputPurposeOfVisit">
                    Purpose of Visit<i>*</i>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${errorState?.purpose_of_visit ? "is-invalid" : ""
                      }`}
                    name="purpose_of_visit"
                    value={formRequestPass?.purpose_of_visit}
                    id="inputPurposeOfVisit"
                    placeholder="Enter your purpose of visit"
                    onChange={(e) => onInputChangeVisitorForm(e.target)}
                    disabled={confirmedProfileData == null}
                  />
                  <div
                    className={`error-message ${errorState?.purpose_of_visit ? "invalid-feedback" : ""
                      }`}
                  >
                    {errorState?.purpose_of_visit || ""}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 d-flex justify-content-end">
                  <button
                    className="btn btn-primary mr-3"
                    type="button"
                    onClick={() => {
                      history.push("/self-registration");
                    }}
                    id="buttonCancel"
                  >
                    Cancel
                  </button>{" "}
                  <button
                    type="submit"
                    className="btn btn-secondary submitButton"
                    disabled={confirmedProfileData === null}
                  >
                    {formRequestPassStatus && (
                      <>
                        <span className="input-loader-container">
                          <div
                            className="spinner-border spinner-border-sm ml-1"
                            role="status"
                          ></div>
                        </span>
                      </>
                    )}
                    {!formRequestPassStatus && "Request Pass"}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <ProfileModal
            showModal={showModal}
            onClickCancel={profileModalCancelHandler}
            profileData={profileData}
            onClickConfirm={profileModalConfirmHandler}
          />
        </div>
      )}
      {formRequestPassResult && (
        <ShowSuccessMessage message={formRequestPassResult} />
      )}
    </>
  );
};
export default PublicRegistrationExistingUser;
