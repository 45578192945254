import React from "react";
import { formatTextWithFallback } from "../../../helpers/helpers";

function PendingTableExpandableComponent({ data }) {
  return (
    <>
      <div className="table-expaned-data">
        <div className="item">
          Profile ID <div className="value">{formatTextWithFallback(data?.personId)}</div>
        </div>
        <div className="item">
          Nationality <div className="value">{formatTextWithFallback(data?.personNationality)}</div>
        </div>
        <div className="item">
          Vehicle No{" "}
          <div className="value">
            {formatTextWithFallback(data?.vehicleNumber)}
          </div>
        </div>
        {String(data?.personCategory ?? "").toLowerCase() !== "employee" &&(
          <>
          <div className="item">
            Host Name <div className="value">{formatTextWithFallback(data?.hostName)}</div>
          </div>
          <div className="item">
            Purpose of Visit{" "}
            <div className="value">{formatTextWithFallback(data?.purposeOfVisit)}</div>
          </div>
          </>
        )}
      </div>
    </>
  );
}

export default PendingTableExpandableComponent;
