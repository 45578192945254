import React, { useContext } from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import Axios from "axios";
import { convertToDayTime, isWidgetRefreshable } from "../../../helpers/helpers";
import trend from "../../../assets/images/trend-graph.png";
import { AppContext } from "../../../context/AppContext";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const NotificationCardItem = ({
  widgetData,
  skin,
  title,
  icon,
  handleRedirection = () => {},
}) => {
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();

  const [displayData, setDisplayData] = React.useState();
  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const fetchData = () => {
    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    Axios.get(widgetData?.widget.config.url, config)
      .then((response) => {
        setDisplayData(response.data.data ?? response.data);
      })
      .catch((error) => {
         setDisplayData({});
      });
  };

  const updateData = () => {
    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    setIsUpdateLoading(true);
    Axios.get(widgetData?.widget.config.url + "/update", config)
      .then((response) => {
        addToast(response?.data?.data?.message ?? "Widget updated Successfully!", { appearance: "success" });
      })
      .catch((error) => {
        addToast(error.detail ?? "Failed to update widget!", { appearance: "error" });
      }).finally(() => setIsUpdateLoading(false));
  }

  React.useEffect(() => {
    fetchData();
    if (
      widgetData?.widget?.config?.refreshRate &&
      widgetData?.widget?.config?.refreshRate > 0
    ) {
      const timeoutId = setInterval(() => {
        fetchData();
      }, widgetData?.widget?.config?.refreshRate * 1000);
      return () => {
        clearInterval(timeoutId); // Clear the timeout if the component is unmounted or the effect is cleaned up
      };
    }
  }, [widgetData]);

  const getTooltipContent = (slug) => {
    if (slug === "people-overstay") {
      return "People exceeded allotted working hours";
    } else if (slug === "vehicle-overstay") {
      return "Vehicles exceeded allotted hours";
    }else if(slug === "live-people-count"){
      return "No. of people currently inside the premises";
    }else if(slug === "live-vehicle-count"){
      return "No. of vehicles currently inside the premises";
    }
  };


  const tooltipPLace = (slug) => {
    if (slug === "vehicle-overstay") {
      return "left";
    } else {
      return "right"
    }
  };


  return (
    <div className="min-h-full">
      <div className={`card notification-card relative ${skin}`}>
        <div className="justify-content-between d-flex flex-row align-items-center card-body topNotificationCard">
          <div className="notification-header-wrapper w-100">
            <div className="notification-header d-flex align-items-center">
              <div className="content">
                {/* {displayData?.trend && displayData?.trend !== 0 ? (
                  <div className="trendCard">
                    <img src={trend} alt="trend" className={displayData?.trend < 0 ? "down" : "up"} />
                    {displayData?.trend
                      ? Math.abs(displayData?.trend) + "%"
                      : " "}
                  </div>
                ) : (
                  ""
                )} */}
                <div className="lead text-capitalize">
                  <i
                    className={`${icon} mb-0 mr-3 align-text-bottom d-inline-block`}
                  ></i>
                  <span
                    data-for={widgetData.widget.slug}
                    data-tip={getTooltipContent(widgetData.widget.slug)}
                  >
                    {displayData ? (
                      <>{displayData?.[widgetData?.widget?.config?.fields[0] ?? "count"] ? displayData?.[widgetData?.widget?.config?.fields[0] ?? "count"] : "0"}</>
                    ) : (
                      <div className="skeleton white w-80"></div>
                    )}
                  </span>
                </div>
                <div className="text-small p-0 m-0">
                  <div className="card-bottom row m-0">
                    <div
                      key={_.uniqueId("link-")}
                      className="text-left col p-0"
                    >
                      {displayData ? (
                        <b>{title}</b>
                      ) : (
                        <div className="skeleton white w-50"></div>
                      )}
                      <div className="mt-1">
                        {displayData ? (
                          <>
                          Last updated on &nbsp;
                            {displayData?.[widgetData?.widget?.config?.fields[1] ?? "last_updated_dt"] ?
                            (widgetData?.widget?.config?.isISO
                              ? moment(displayData?.[widgetData?.widget?.config?.fields[1] ?? "last_updated_dt"]).format('DD-MM-YYYY hh:mm A')
                              : convertToDayTime(displayData?.[widgetData?.widget?.config?.fields[1] ?? "last_updated_dt"]))
                            :"--"}

                          </>
                        ) : (
                          <div className="mt-1 skeleton white w-80"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right" onClick={() => handleRedirection()}>
                <i className="simple-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          { isWidgetRefreshable(userRoleDetails, widgetData) &&
            <span 
              className={`refresh-button ${isupdateLoading ? "spin" : ""}`} 
              data-tip="Update Data" 
              onClick={() => {!isupdateLoading && updateData()}}>
                <i className="iconsminds-refresh" />
              </span>
          }
        </div>
      </div>
      <ReactTooltip
        place={tooltipPLace(widgetData.widget.slug)}
        id={widgetData.widget.slug}
      />
    </div>
  );
};

export default NotificationCardItem;
