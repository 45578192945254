/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import {
  addBodyClass,
  defaultSelectOption,
  removeBodyClass,
} from "../../helpers/utils";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { useHistory } from "react-router-dom";
import { VW_ROUTES, apiRoutes } from "../../constants";
import SearchBar from "../../components/common/Search/Search";
import {
  checkModuleViewPermission,
  checkModuleAddPermission,
  checkModuleDeletePermission,
  checkModuleEditPermission,
  getCategorySlug,
  getIdNumber,
  parseIdentificationType,
  checkModuleGeneralPermission,
  getCurrentWindowParams,
  getCategoryType,
  formatTextWithFallback,
  filterIdsMultiselect,
} from "../../helpers/helpers";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import TableExpandableComponent from "../../components/common/TableExpandableComponent/TableExpandableComponent";
import { columnData } from "./dummyColumns";
import { columnSetter } from "../../helpers/columnSetter";
import BanProfile from "../../components/common/BanProfile/BanProfile";
import useUserListSharedContext from "../../components/common/SharedContexts/userListSharedContext";
import SelectApplicants from "../../components/common/SelectApplicants/SelectApplicants";
import { AppContext } from "../../context/AppContext";
import ContextMenuContentWrapper from "../../components/common/ContextMenuContentWrapper";
import ArchiveProfilePopup from "../../components/ArchiveProfilePopup/ArchiveProfilePopup";
import useStorage from "../../helpers/useStorage";
import useCompanyOptions from "../../components/common/CommonDeliverables/useCompanyOptions";
import ApplicantListFilter from "./ApplicantListFilter";
import PillText from "../../components/PillText/PillText";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      fontSize: "14px",
    },
  },
};

const parseToSelectFormat = (
  options,
  keys,
  hideAll = false,
  isDefaultSelect = true,
  convertToUpperCase = false
) => {
  let res = [];
  if (options) {
    res = _.map(options, (option) => ({
      label: convertToUpperCase
        ? String(option[keys.label]).toUpperCase()
        : option[keys.label],
      value: option[keys.value],
    }));
    if (!hideAll) {
      res.unshift({ label: isDefaultSelect ? "Select" : "All", value: "" });
    }
  }
  return res;
};

const getFilterValues = (v) => {
  let val = [];
  v.forEach((item) => {
    if (item.value && item.value !== "") {
      val.push(item.value);
    }
  });
  return val;
};

const ApplicantsList = ({ location }) => {
  const { setToStorage, getFromStorage } = useStorage();
  const {
    showModal,
    manageModal,
    // initialPageParams,
    // updateInitialPageParams,
    setProfilePageUrlParams,
    // isDeactivate,
    // selectedRows,
    onRowSelect,
    archiveResult,
    doarchiveApplicants,
    isArchive,
    appOptions,
    showBanModal,
  } = useUserListSharedContext();

  const {
    userRoleDetails,
    showBanModalPopup,
    getCategoryFromID,
    getCategoryFromSlug,
  } = React.useContext(AppContext);

  const token = localStorage.getItem("access_token");

  const history = useHistory();
  const url = useLocation();
  const pathname = url.pathname;

  useEffect(() => {
    setSearchQueryFromURL();
    setFiltersFromURL();
    setCurrentPageFromURL();
  }, [url.search]);

  const client = useClient();
  client.config.headers = { Authorization: `JWT ${token}` };

  const { addToast } = useToasts();
  const [showLoader, setShowLoader] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [tableUserId, setTableUserId] = useState("");
  const [currentUserId, setCurrentUserId] = useState("0");

  const [activeData, setAciveData] = useState({});
  const [addNew, setAddNew] = useState(false);

  const [personRegistryType, setPersonRegistryType] = useState(
    pathname.split("/")[1]
  );
  const [pageName, setPageName] = useState("");

  const [currentPageNumber, setCurrentPageNumber] = useState(undefined);
  const [rowsPerPage, setRowsPerPage] = useState(
    getFromStorage("profiles_rows_per_page") ?? 10
  );
  const [activePage, setActivePage] = useState(1);
  const [resetPaginationDataTable, setResetPaginationDataTable] =
    useState(false);

  const [filterProfileCategory, setFilterProfileCategory] = useState(
    defaultSelectOption()
  );
  const [filterProfileStatus, setFilterProfileStatus] = useState([]);
  const [filterContract, setFilterContract] = useState([]);
  const [filterCompany, setFilterCompany] = useState([]);
  const [filterIdType, setFilterIdType] = useState(defaultSelectOption());
  const [filterWorkPermitExpired, setFilterWorkPermitExpired] = useState(
    defaultSelectOption()
  );
  const [filterWithAvatar, setFilterWithAvatar] = useState(
    defaultSelectOption()
  );
  const [filterWithRegisterMode, setFilterWithRegisterMode] = useState(
    defaultSelectOption()
  );
  const [filterNotVisiterFor, setFilterNotVistedFor] = React.useState("");

  const [showMobileDataFilter, setShowMobileDataFilter] = useState(false);
  const [filter, setFilter] = useState({});
  const [filterApplied, setFilterApplied] = useState(false);
  const [intialFilterCheck, setIntialFilterCheck] = useState(false);

  const [searchQueryString, setSearchQueryString] = useState(null);
  const [searchGoingOn, setSearchGoingOn] = useState(false);

  const [apiParams, setApiParams] = useState({});

  const [archiveModal, setArchiveModal] = useState(false);
  const [applicantProfile, setapplicantProfile] = useState([]);

  const [intialDataLoaded, setInitialDataLoaded] = React.useState(false);

  //for archive
  const [archiveRestoreStatusToComponent, setArchiveRestoreStatusToComponent] =
    useState("");
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [archiveButtonPressed, setArchiveButtonPressed] = useState(false);
  const [archiveRestoreStatus, setArchiveRestoreStatus] = React.useState("");

  //for archive ends

  const getPageType = useCallback(() => {
    const currentPageType = url.pathname.split("/")[1];
    if (String(currentPageType ?? "").length === 0) return undefined;
    const pageKeyFromMenu = _.findKey(
      userRoleDetails?.menuItems["person-registry"]?.children ?? {},
      ["code", currentPageType]
    );
    return pageKeyFromMenu &&
      pageKeyFromMenu?.length > 0 &&
      pageKeyFromMenu !== "all-profile"
      ? pageKeyFromMenu
      : undefined;
  }, [url, userRoleDetails]);

  const getCellCategoryType = (cellProfile) =>
    getCategoryType(
      getCategoryFromID(cellProfile?.category?.id, appOptions)?.slug
    );

  const toggleResetPagination = () => {
    setResetPaginationDataTable((prevToggle) => !prevToggle);
  };

  const setFiltersFromURL = () => {
    const windowParams = getCurrentWindowParams();
    const windowFilters = new Object();

    if (getPageType() && personRegistryType)
      windowFilters.category = getCategorySlug(personRegistryType);
    else {
      const windowCategory = windowParams.get("category");
      if (String(windowCategory ?? "").length > 0)
        windowFilters.category = windowCategory;
    }

    const windowProfileStatus = windowParams.get("status");
    if (windowProfileStatus?.length > 0)
      windowFilters.status = windowProfileStatus.split(",");

    const windowContract = windowParams.get("contract");
    if (windowContract?.length > 0)
      windowFilters.contract = windowContract.split(",");

    const windowCompany = windowParams.get("company_name");
    if (String(windowCompany ?? "").length > 0)
      windowFilters.company_name = windowCompany.split(",");

    const windowIdType = windowParams.get("id_type");
    if (String(windowIdType ?? "").length > 0)
      windowFilters.id_type = windowIdType;

    const windowWorkPermitExpired = windowParams.get("expired_work_permit");
    if (String(windowWorkPermitExpired ?? "").length > 0)
      windowFilters.expired_work_permit = windowWorkPermitExpired;

    const windowRegisterMode = windowParams.get("register_mode");
    if (String(windowRegisterMode ?? "").length > 0)
      windowFilters.register_mode = windowRegisterMode;

    const windowIsAvatar = windowParams.get("avatar");
    if (String(windowIsAvatar ?? "").length > 0)
      windowFilters.avatar = Number(windowIsAvatar);

    const windowNotVisiterFor = windowParams.get("not_visited");
    if (String(windowNotVisiterFor ?? "").length > 0)
      windowFilters.not_visited = windowNotVisiterFor;

    setFilter({ ...windowFilters });
  };

  const setSearchQueryFromURL = () => {
    const windowParams = getCurrentWindowParams();

    const searchWord = windowParams.get("search");
    if (String(searchWord ?? "").length > 0)
      setSearchQueryString(decodeURI(searchWord));
    else setSearchQueryString("");
  };

  const setCurrentPageFromURL = () => {
    const windowParams = getCurrentWindowParams();
    const pageNumber = windowParams.get("page");
    if (pageNumber && pageNumber.length > 0) {
      setCurrentPageNumber(Number(pageNumber));
      setActivePage(Number(pageNumber));
      toggleResetPagination();
    } else {
      setCurrentPageNumber(1);
      setActivePage(1);
      toggleResetPagination();
    }
  };

  useEffect(() => {
    setPersonRegistryType(pathname.split("/")[1]);
    handleResetFilter();
  }, [pathname, setPersonRegistryType]);

  useEffect(() => {
    if (!userRoleDetails?.menuItems || !personRegistryType) return;
    setPageName(
      userRoleDetails?.menuItems["person-registry"]?.children[
        personRegistryType
      ]?.name ?? ""
    );
  }, [pathname, userRoleDetails, personRegistryType, setPageName]);

  useEffect(() => {
    setFiltersFromURL();
    setSearchQueryFromURL();
    setCurrentPageFromURL();
    setIntialFilterCheck(true);
  }, []);

  useEffect(() => {
    if (!intialFilterCheck) return;
    const newFilters = { ...filter };

    if (getPageType()) delete newFilters["category"];

    const checkFilter =
      Object.values(newFilters).find(
        (filterValue) => !(filterValue === undefined || filterValue === null)
      ) !== undefined;
    setFilterApplied(checkFilter);
    const checkQueryString = String(searchQueryString ?? "").length > 0;

    let newURLParams = {};
    checkFilter && (newURLParams = { ...newFilters });
    checkQueryString &&
      (newURLParams = { ...newURLParams, search: searchQueryString });
    currentPageNumber &&
      currentPageNumber > 1 &&
      (newURLParams = { ...newURLParams, page: currentPageNumber });
    !getPageType() &&
      filter?.category &&
      (newURLParams.category = filter.category);

    _.isEmpty(newURLParams)
      ? setProfilePageUrlParams(null)
      : setProfilePageUrlParams(newURLParams);

    let { page, ...apiParams } = newURLParams;
    if (getPageType() && filter?.category)
      apiParams = { ...apiParams, category: filter.category };

    _.isEmpty(apiParams) ? setApiParams({}) : setApiParams(apiParams);
    setSearchGoingOn(true);
  }, [
    filter,
    searchQueryString,
    currentPageNumber,
    intialFilterCheck,
    setSearchGoingOn,
  ]);

  // const [applicantIdle, setApplicantIdle] = React.useState({
  //   label: "Select",
  //   value: "",
  // });

  const { data: contract, isLoading: contractIsLoading } = useQuery(
    [
      apiRoutes.contract,
      {
        page: {
          number: 1,
          size: 3000,
        },
      },
    ],
    { client }
  );

  const [archivePersonProfile] = useMutation(
    apiRoutes.archiveProfile.replace("{profileId}", activeData.id),
    { method: "PUT", client }
  );

  const getProfileCategoryOptions = () =>
    parseToSelectFormat(appOptions?.category, {
      label: "display_name",
      value: "value",
    });

  const getProfileStatusOptions = () =>
    parseToSelectFormat(
      appOptions?.status,
      {
        label: "display_name",
        value: "value",
      },
      false,
      true
    );

  const getContractOptions = () =>
    parseToSelectFormat(
      contract,
      {
        label: "name",
        value: "id",
      },
      true
    );

  const getNotVisitedOptions = () =>
    parseToSelectFormat(
      appOptions?.notVisited,
      {
        label: "display_name",
        value: "value",
      },
      false,
      true
    );

  const getIdTypeOptions = () =>
    parseToSelectFormat(
      appOptions?.idType,
      {
        label: "display_name",
        value: "value",
      },
      false,
      true,
      true
    );

  const getAvatarOptions = () => [
    defaultSelectOption(),
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];

  const getRegisterModeOptions = () =>
    parseToSelectFormat(
      appOptions?.registerModes,
      {
        label: "display_name",
        value: "value",
      },
      false,
      true,
      true
    );

  const getWorkPermitExpiredOptions = () => [
    defaultSelectOption(),
    { label: "Yes", value: "True" },
    { label: "No", value: "False" },
  ];

  useEffect(() => {
    if (contractIsLoading) return;
    const {
      category: changedCategory,
      status: changedStatus,
      contract: changedContract,
      company_name: changedCompany,
      id_type: changedIdType,
      expired_work_permit: changedWorkPermitExpired,
      register_mode: changedRegisterMode,
      avatar: changedAvatar,
      not_visited: changedNotVistedFor,
    } = filter;
    if (String(changedCategory ?? "").length > 0 && !getPageType()) {
      const categoryOption = getProfileCategoryOptions()?.find(
        (option) =>
          option?.value ===
          getCategoryFromSlug(changedCategory, appOptions)?.value
      );
      handleFilterProfileCategory(categoryOption ?? defaultSelectOption());
    } else handleFilterProfileCategory(defaultSelectOption());

    if (String(changedStatus ?? "").length > 0) {
      const statusOptions = getProfileStatusOptions()?.filter((option) =>
        _.includes(changedStatus, option?.value)
      );
      handleFilterProfileStatus(statusOptions?.length > 0 ? statusOptions : []);
    } else handleFilterProfileStatus([]);

    if (changedContract?.length > 0) {
      const contractOptions = getContractOptions()?.filter((option) =>
        _.includes(changedContract, option?.value)
      );
      handleFilterContract(contractOptions?.length > 0 ? contractOptions : []);
    } else handleFilterContract([]);

    if (changedCompany?.length > 0) {
      // const companyOptions = companies?.filter((option) => _.includes(changedCompany, option?.value));
      const companyOptions = changedCompany?.map((companyName) => ({
        label: companyName,
        value: companyName,
      }));
      handleFilterCompany(companyOptions?.length > 0 ? companyOptions : []);
    } else handleFilterCompany([]);

    if (String(changedIdType ?? "").length > 0) {
      const idTypeOption = getIdTypeOptions().find(
        (option) => String(option?.value) === String(changedIdType)
      );
      handleFilterIdType(idTypeOption ?? defaultSelectOption());
    } else handleFilterIdType(defaultSelectOption());

    if (String(changedWorkPermitExpired ?? "")?.length > 0) {
      const workPermitExpired = getWorkPermitExpiredOptions()?.find(
        (option) => String(option?.value) === String(changedWorkPermitExpired)
      );
      handleFilterWorkPermitExpired(workPermitExpired ?? defaultSelectOption());
    } else handleFilterWorkPermitExpired(defaultSelectOption());

    if (String(changedRegisterMode ?? "")?.length > 0) {
      const registerModeOption = getRegisterModeOptions()?.find(
        (option) => String(option?.value) === String(changedRegisterMode)
      );
      handleFilterWithRegisterMode(registerModeOption ?? defaultSelectOption());
    } else handleFilterWithRegisterMode(defaultSelectOption());

    if (String(changedAvatar ?? "")?.length > 0) {
      const avatarOption = getAvatarOptions()?.find(
        (option) => String(option?.value) === String(changedAvatar)
      );
      handleFilterWithAvatar(avatarOption ?? defaultSelectOption());
    } else handleFilterWithAvatar(defaultSelectOption());

    if (String(changedNotVistedFor ?? "").length > 0) {
      const notVisitedForOption = getNotVisitedOptions().find(
        (option) => String(option?.value) === String(changedNotVistedFor)
      );
      handleFilterNotVistedFor(
        notVisitedForOption ?? { label: "Select", value: "" }
      );
    } else handleFilterNotVistedFor({ label: "Select", value: "" });
  }, [filter, contractIsLoading, showMobileDataFilter]);

  // let paginationSettings;
  // if (searchGoingOn) {
  //   paginationSettings = {
  //     number: 1,
  //     size: rowsPerPage,
  //   };
  // } else {
  const paginationSettings = {
    number: currentPageNumber,
    size: rowsPerPage,
  };
  // }
  const profiles = useQuery(
    currentPageNumber &&
      userRoleDetails &&
      intialFilterCheck &&
      (getPageType() ? apiParams?.category : true) && [
        apiRoutes.createProfileData,
        {
          filter: apiParams,
          page: paginationSettings,
          include: "person_photo,company,category,person_last_visit",
        },
      ],
    { client }
  );

  React.useEffect(() => {
    if (profiles.data && !profiles.isLoading) {
      setInitialDataLoaded(true);
    } else {
      setInitialDataLoaded(false);
    }
  }, [profiles]);

  if (profiles.data === undefined || null) {
    profiles.data = [];
  }

  useEffect(() => {
    setFiltersFromURL();
    setSearchQueryFromURL();
    setCurrentPageFromURL();
    // profiles.refetch();
  }, [pathname, personRegistryType]);

  // React.useEffect(() => {
  //   if (location) {
  //     updateInitialPageParams(location);
  //     setChangedUrl(location.pathname);
  //   }
  // }, []);

  const viewProfile = (person) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${person.id}&type=${person.category?.slug}${
        !getPageType() ? "&fromAllProfile=true" : ""
      }`,
      state: {
        from: `/${personRegistryType}`,
      },
    });
  };

  const doCloseModal = () => {
    removeBodyClass("modal-open");
    manageModal(false);
    setShowDeactivateModal(false);
    setToggleClearRows(!toggledClearRows);
    onRowSelect({
      selectedRows: [],
    });
  };

  const updateProfile = (person) => {
    history.push({
      pathname: VW_ROUTES.MANAGE_PERSON_REGISTRY,
      search: `?id=${person.id}&type=${getCategoryType(
        getCategoryFromID(person?.category?.id, appOptions)?.slug
      )}&categoryId=${person?.category?.id}&edit=true${
        !getPageType() ? "&fromAllProfile=true" : ""
      }`,
    });
  };

  let menuRef = React.useRef();
  let addNewRef = React.useRef();

  const handleMouseDownMenu = (event) => {
    if (
      event?.target?.className !== "user-role-action-icons" &&
      menuRef?.current &&
      !menuRef?.current?.contains(event?.target)
    )
      setTableUserId("");
  };

  const handleMouseDownAddNew = (event) => {
    if (
      !event?.target?.classList.contains("dropdown-menu") &&
      addNewRef?.current &&
      !addNewRef?.current?.contains(event?.target)
    )
      setAddNew(false);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleMouseDownMenu);
    document.addEventListener("mousedown", handleMouseDownAddNew);
    return () => {
      document.removeEventListener("mousedown", handleMouseDownMenu);
      document.removeEventListener("mousedown", handleMouseDownAddNew);
    };
  });

  const handleFilterProfileCategory = (e) => {
    setFilterProfileCategory(e);
  };
  const handleFilterProfileStatus = (e) => {
    setFilterProfileStatus(e);
  };
  const handleFilterContract = (opt) => {
    setFilterContract(opt);
  };
  const handleFilterCompany = (opt) => {
    setFilterCompany(
      opt.map((arrayOpt) => ({
        label: arrayOpt?.label,
        value: arrayOpt?.label,
      }))
    );
  };
  const handleFilterIdType = (opt) => {
    setFilterIdType(opt);
  };
  const handleFilterWorkPermitExpired = (opt) => {
    setFilterWorkPermitExpired(opt);
  };
  const handleFilterWithRegisterMode = (e) => {
    setFilterWithRegisterMode(e);
  };
  const handleFilterWithAvatar = (e) => {
    setFilterWithAvatar(e);
  };
  const handleFilterNotVistedFor = (e) => {
    // onRowSelect({
    //   selectedRows: [],
    // });
    setFilterNotVistedFor(e);
  };

  const showHideContextMenu = (userid) => {
    setTableUserId(userid);
  };
  const popCloseModal = (data) => {
    setOpenModal(false);
    setAciveData({});
  };
  const popConfirmModal = (data) => {
    setAciveData(data);
    setOpenModal(true);
  };
  // const manageDeactivateOption = () => {
  //   addBodyClass("modal-open");
  //   setShowDeactivateModal(true);
  // };
  const confirmAcivationManagement = () => {
    changeActiveStatus();
  };
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const changeActiveStatus = async () => {
    setIsArchiveLoading(true);
    const { data, error } = await archivePersonProfile({
      archive: activeData?.status === "archived" ? false : true,
      id: activeData?.id,
    });
    setIsArchiveLoading(false);
    setOpenModal(false);
    if (data) {
      addToast(
        activeData?.status === "archived"
          ? DISPLAY_TEXTS.PERSON_PROFILE_RESTORED
          : DISPLAY_TEXTS.PERSON_PROFILE_ARCHIVED,
        { appearance: "success" }
      );
    } else {
      addToast(error.detail, { appearance: "error" });
    }
    setAciveData({});
  };

  const getEvent = (eventDetail) => {
    if (eventDetail.mode === "edit") {
      updateProfile(eventDetail.personDetail);
    }

    if (eventDetail.mode === "delete") {
      manageDeletedOption(eventDetail.personDetail.id);
    }

    if (eventDetail.mode === "view") {
      viewProfile(eventDetail.personDetail);
    }
    if (eventDetail.mode === "activate") {
      popConfirmModal(eventDetail.personDetail);
    }
    if (eventDetail.mode === "archive") {
      setArchiveModal(true);
      // popConfirmModal(eventDetail.personDetail)
    }
    if (eventDetail.mode === "ban") {
      showBanModalPopup({
        show: true,
        data: { data: eventDetail.personDetail },
        reload: false,
      });
    }
  };

  const showArchiveProfile = (profile) => {
    setapplicantProfile([profile.id]);
    setArchiveRestoreStatusToComponent(profile.status);
    setArchiveModal(true);
  };

  const bulkArchiveRestore = () => {
    setArchiveModal(true);
  };

  const onArchiveSuccess = () => {
    setToggleClearRows(!toggledClearRows);
    setapplicantProfile([]);
    setArchiveModal(false);
    profiles.refetch();
  };

  const columns = [
    {
      ...columnSetter("fullName", columnData),
      selector: (personDetail) => personDetail.fullName,
      cell: (personDetail) => (
        <ProfileCard
          data={personDetail}
          actions={userRoleDetails}
          mode={getCellCategoryType(personDetail)}
          fields={[
            {
              value: personDetail?.company?.name?.toUpperCase(),
              name: columnSetter("company", columnData).name,
            },
            {
              value: parseIdentificationType(personDetail),
              name: `${
                personDetail.idType && personDetail.idType !== "passport"
                  ? personDetail.idType.toUpperCase()
                  : personDetail.idType
              } Number`,
            },
            {
              name: columnSetter("registerMode", columnData).name,
              value:
                _.find(
                  appOptions?.registerModes,
                  (regMode) => regMode?.value === personDetail?.registerMode
                )?.display_name ?? personDetail?.registerMode,
            },
          ]}
          actionsToParent={(mode) => getEvent({ mode, personDetail })}
        />
      ),
    },

    {
      ...columnSetter("category", columnData),
      hide: "md",
      omit: getPageType(),
      selector: (row) => row.category,
      cell: (profile) => (
        <>
          <span className="text-capitalize">
            {" "}
            {formatTextWithFallback(
              getCategoryFromID(profile?.category?.id, appOptions)?.display_name
            )}
          </span>
        </>
      ),
    },

    {
      ...columnSetter("company", columnData),
      selector: (profile) => profile?.company?.name,
      hide: "md",
      cell: (profile) => (
        <>
          <span className="text-uppercase">
            {formatTextWithFallback(profile?.company?.name)}
          </span>
        </>
      ),
    },

    {
      ...columnSetter("idNumber", columnData),
      selector: (profile) => profile.idNumber,
      cell: (profile) => (
        <span className="text-capitalize text-left">
          {formatTextWithFallback(getIdNumber(profile))}
        </span>
      ),
      hide: "md",
    },

    {
      ...columnSetter("workPermitExpiry", columnData),
      hide: "md",
      selector: (profile) => profile.workPermitExpiry,
      cell: (profile) => (
        <>{formatTextWithFallback(profile.workPermitExpiry)}</>
      ),
    },

    {
      ...columnSetter("registerMode", columnData),
      hide: "md",
      selector: (profile) => profile.registerMode,
      cell: (profile) => (
        <>
          {profile?.registerMode ? (
            <PillText
              label={
                _.find(
                  appOptions?.registerModes,
                  (regMode) => regMode?.value === profile?.registerMode
                )?.display_name ?? profile?.registerMode
              }
              type={String(profile?.registerMode)}
            />
          ) : (
            "--"
          )}
        </>
      ),
    },

    {
      name: "Actions",
      sortable: false,
      maxWidth: 100,
      minWidth: 100,
      cell: (profile) => (
        <div className="actionColumn">
          {checkModuleViewPermission(
            userRoleDetails,
            getCellCategoryType(profile),
            "profile",
            profile
          ) && (
            <span
              onClick={() => viewProfile(profile)}
              className="simple-icon-eye user-role-action-icons view"
            ></span>
          )}
          {(checkModuleEditPermission(
            userRoleDetails,
            getCellCategoryType(profile),
            "profile",
            profile
          ) ||
            checkModuleDeletePermission(
              userRoleDetails,
              getCellCategoryType(profile),
              "profile",
              profile
            )) && (
            <div className="contextMenu">
              <span
                className="user-role-action-icons"
                onClick={() => showHideContextMenu(profile.id)}
              >
                <i></i>
              </span>
              {tableUserId === profile.id && (
                <ContextMenuContentWrapper
                  showContextMenu={tableUserId === profile.id}
                  handleContextMenuClose={() => setTableUserId("")}
                >
                  <ul ref={menuRef}>
                    {checkModuleEditPermission(
                      userRoleDetails,
                      getCellCategoryType(profile),
                      "profile",
                      profile
                    ) && (
                      <li onClick={() => updateProfile(profile)}>
                        <button>
                          <i className="iconsminds-pen user-role-action-icons edit"></i>
                          Edit
                        </button>
                      </li>
                    )}
                    {checkModuleGeneralPermission(
                      userRoleDetails,
                      getCellCategoryType(profile),
                      profile?.status === "archived"
                        ? "restore_profile"
                        : "archive_profile",
                      profile
                    ) && (
                      <li>
                        <button onClick={() => showArchiveProfile(profile)}>
                          <i
                            className={`${
                              profile?.status === "archived"
                                ? "iconsminds-inbox-out"
                                : "iconsminds-inbox-into"
                            }`}
                          ></i>
                          {profile?.status === "archived"
                            ? "Restore"
                            : "Archive"}
                        </button>
                      </li>
                    )}
                    {checkModuleGeneralPermission(
                      userRoleDetails,
                      getCellCategoryType(profile),
                      profile?.status === "banned"
                        ? "unban_profile"
                        : "ban_profile",
                      profile
                    ) && (
                      <li>
                        <BanProfile data={profile} />
                      </li>
                    )}

                    {checkModuleDeletePermission(
                      userRoleDetails,
                      getCellCategoryType(profile),
                      "profile",
                      profile
                    ) && (
                      <li onClick={() => manageDeletedOption(profile.id)}>
                        <button>
                          <i className="simple-icon-trash user-role-action-icons delete"></i>
                          Delete
                        </button>
                      </li>
                    )}
                  </ul>
                </ContextMenuContentWrapper>
              )}
            </div>
          )}
        </div>
      ),
      right: true,
      hide: "md",
    },
  ];

  const onSelectRows = (rows) => {
    setArchiveRestoreStatusToComponent(
      archiveRestoreStatus === "toRestore" ? "archived" : "active"
    );
    setapplicantProfile(filterIdsMultiselect(rows?.selectedRows));
  };

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({ profiles_rows_per_page: rows }, true);
    setRowsPerPage(rows);
  };

  // let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    // searchGoing = true;
    // setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setCurrentPageNumber(1);
    setActivePage(1);
    toggleResetPagination();
  };

  // window.onpopstate = (e) => {
  // if(searchGoingOn) {
  //   setSearchGoingOn(false);
  //   return;
  // }
  // setSearchQueryFromURL();
  // setFiltersFromURL();
  // if (!searchGoing && !paginationGoing) {
  //   const searchWord = window.location.hash.split("search=")[1];
  //   const windowParams = new URLSearchParams(window.location.hash.split("?")[1] ?? "");
  //   const wordString = decodeURI(windowParams.get("search") ?? "");
  //   if (searchWord) {
  //     searchWord !== searchQueryString && onSearchInputChange(wordString);
  //   } else {
  //     onSearchInputChange(null);
  //   }
  // } else {
  //   searchGoing = false;
  //   paginationGoing = false;
  // }
  // };

  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const permenentDelete = async () => {
    setIsDeleteLoading(true);
    const { error, isLoading } = await client.delete([
      apiRoutes.updateProfileData.replace("{personId}", currentUserId),
    ]);
    if (error === undefined) {
      profiles.refetch();
      addToast(DISPLAY_TEXTS.PERSON_PROFILE_DELETED, { appearance: "success" });
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
    setCurrentUserId(0);
    setIsDeleteLoading(false);
    manageModal(false);
    removeBodyClass("modal-open");
  };

  const deleteUserDetails = async () => {
    if (isArchive) {
      doarchiveApplicants();
      setArchiveButtonPressed(true);
      doCloseModal();
    } else {
      permenentDelete();
    }
  };

  const manageDeletedOption = (item) => {
    setCurrentUserId(item);
    onRowSelect({
      selectedRows: [item],
    });
    addBodyClass("modal-open");
    manageModal(true);
  };

  React.useEffect(() => {
    if (archiveButtonPressed) {
      if (archiveResult) {
        addToast(DISPLAY_TEXTS.PERSON_PROFILE_ARCHIVED, {
          appearance: "success",
        });
        profiles.refetch();
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
    }
    setArchiveButtonPressed(false);
    setToggleClearRows(!toggledClearRows);
    onRowSelect({
      selectedRows: [],
    });
  }, [archiveResult]);

  React.useEffect(() => {
    if (showBanModal.reload) {
      profiles.refetch();
    }
  }, [showBanModal]);

  const handleResetFilter = () => {
    setCurrentPageNumber(1);
    setFilter({
      ...(getPageType()
        ? { category: getCategorySlug(personRegistryType) }
        : {}),
    });

    handleFilterProfileCategory(defaultSelectOption());
    handleFilterProfileStatus([]);
    handleFilterIdType(defaultSelectOption());
    handleFilterWorkPermitExpired(defaultSelectOption());
    handleFilterContract([]);
    handleFilterCompany([]);
    handleFilterWithRegisterMode(defaultSelectOption());
    handleFilterWithAvatar(defaultSelectOption());
    handleFilterNotVistedFor("");

    // setApplicantIdle({ label: "Select", value: "" });
  };
  const handleApplyFilter = () => {
    setCurrentPageNumber(1);
    setActivePage(1);
    toggleResetPagination();
    showFilter(false);
    setFilter({
      ...(getPageType()
        ? { category: getCategorySlug(personRegistryType) }
        : (getCategoryFromID(filterProfileCategory.value, appOptions)?.slug ??
            "") !== ""
        ? {
            category: getCategoryFromID(filterProfileCategory.value, appOptions)
              .slug,
          }
        : {}),

      ...(searchQueryString && searchQueryString !== ""
        ? { search: searchQueryString }
        : {}),

      ...(filterProfileStatus && filterProfileStatus.length > 0
        ? { status: getFilterValues(filterProfileStatus) }
        : {}),

      ...(filterContract && filterContract.length > 0
        ? { contract: getFilterValues(filterContract) }
        : {}),

      ...(filterCompany && filterCompany.length > 0
        ? { company_name: getFilterValues(filterCompany) }
        : {}),

      ...(filterIdType?.value !== "" ? { id_type: filterIdType?.value } : {}),

      ...(filterWorkPermitExpired?.value !== ""
        ? { expired_work_permit: filterWorkPermitExpired?.value }
        : {}),

      ...(filterWithRegisterMode?.value !== ""
        ? { register_mode: filterWithRegisterMode?.value }
        : {}),

      ...(filterWithAvatar?.value !== ""
        ? { avatar: filterWithAvatar?.value }
        : {}),

      ...(filterNotVisiterFor?.value !== ""
        ? { not_visited: filterNotVisiterFor?.value }
        : {}),
    });

    // setApplicantIdle(tempIdle);
  };

  const checkForAddPermissions = () =>
    appOptions?.category?.find((categoryItem) =>
      checkModuleAddPermission(
        userRoleDetails,
        getCategoryType(categoryItem?.slug),
        "profile"
      )
        ? true
        : false
    );

  const confirmCreate = (createInType) => {
    const createInCategory = getCategoryFromSlug(
      getCategorySlug(createInType),
      appOptions
    );
    if (!createInCategory || String(createInCategory.value ?? "").length === 0)
      return;
    const url = `${
      VW_ROUTES.MANAGE_PERSON_REGISTRY
    }?type=${createInType}&categoryId=${createInCategory?.value}${
      !getPageType() ? "&fromAllProfile=true" : ""
    }`;
    history.push(url);
  };
  const createNew = () => {
    !getPageType() ? setAddNew(true) : confirmCreate(personRegistryType);
  };

  const isRowSelectable = (profile) => {
    return !checkModuleGeneralPermission(
      userRoleDetails,
      getCellCategoryType(profile),
      profile?.status === "archived" ? "restore_profile" : "archive_profile",
      profile
    );
  };

  const checkCollectiveArchiveRestorePermission = (permissionType) => {
    if (!permissionType) return false;
    const currentPageType = getPageType();
    const moduleArray = currentPageType
      ? [currentPageType]
      : _.filter(
          [
            ...Object.keys(
              userRoleDetails?.menuItems?.["person-registry"]?.children ?? {}
            ),
          ],
          (moduleKey) => moduleKey !== "all-profile"
        );
    return (
      moduleArray.findIndex(
        (moduleType) =>
          !checkModuleGeneralPermission(
            userRoleDetails,
            moduleType,
            permissionType,
            {},
            true
          )
      ) === -1
    );
  };

  const findArchiveOrResore = (status) => {
    let findArchive;
    if (status?.length > 0) {
      findArchive = status.includes("archived");
    }
    if (findArchive && status?.length > 1) {
      return "notSelectable";
    } else if (
      findArchive &&
      status?.length === 1 &&
      checkCollectiveArchiveRestorePermission("restore_profile")
    ) {
      return "toRestore";
    } else if (
      !findArchive &&
      status?.length >= 1 &&
      checkCollectiveArchiveRestorePermission("archive_profile")
    ) {
      return "toArchive";
    } else {
      return "notSelectable";
    }
  };

  useEffect(() => {
    const status = findArchiveOrResore(filter.status);
    setArchiveRestoreStatus(status);
  }, [filter.status]);

  const checkIsSelectabale = () => {
    return archiveRestoreStatus !== "" &&
      archiveRestoreStatus !== "notSelectable"
      ? true
      : false;
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>{pageName}</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <div className="mobile-search">
                    <SearchBar
                      onSearchInputChange={onSearchInputChange}
                      popstateText={searchQueryString}
                    />
                  </div>
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  {showMobileDataFilter && (
                    <ApplicantListFilter
                      getPageType={getPageType}
                      personRegistryType={personRegistryType}
                      filterCompany={filterCompany}
                      filterContract={filterContract}
                      filterIdType={filterIdType}
                      filterNotVisiterFor={filterNotVisiterFor}
                      filterProfileCategory={filterProfileCategory}
                      filterProfileStatus={filterProfileStatus}
                      filterWithRegisterMode={filterWithRegisterMode}
                      filterWithAvatar={filterWithAvatar}
                      filterWorkPermitExpired={filterWorkPermitExpired}
                      getRegisterModeOptions={getRegisterModeOptions}
                      getAvatarOptions={getAvatarOptions}
                      getIdTypeOptions={getIdTypeOptions}
                      getNotVisitedOptions={getNotVisitedOptions}
                      getProfileStatusOptions={getProfileStatusOptions}
                      getWorkPermitExpiredOptions={getWorkPermitExpiredOptions}
                      handleFilterCompany={handleFilterCompany}
                      handleFilterContract={handleFilterContract}
                      handleFilterIdType={handleFilterIdType}
                      handleFilterNotVistedFor={handleFilterNotVistedFor}
                      handleFilterProfileCategory={handleFilterProfileCategory}
                      handleFilterProfileStatus={handleFilterProfileStatus}
                      handleFilterWithRegisterMode={
                        handleFilterWithRegisterMode
                      }
                      handleFilterWithAvatar={handleFilterWithAvatar}
                      handleFilterWorkPermitExpired={
                        handleFilterWorkPermitExpired
                      }
                    />
                  )}
                  <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={(e) => {
                        handleResetFilter(e);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-secondary m-0"
                      onClick={(e) => {
                        handleApplyFilter(e);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`tableFilterButton rightAlign  ${
                  filterApplied ? "btn-secondary" : ""
                }`}
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>
              {(getPageType()
                ? checkModuleAddPermission(
                    userRoleDetails,
                    personRegistryType,
                    "profile"
                  )
                : checkForAddPermissions()) && (
                // allowNewApplicant &&
                <div className="dropdown ">
                  <div
                    className="tableFilterButton ml-2 rightAlign btn-secondary"
                    onClick={() => createNew()}
                  >
                    <>+</>
                  </div>
                  {!getPageType() && addNew && (
                    <div
                      ref={addNewRef}
                      id="create-dropdown"
                      className={`dropdown-content dropdown-menu dropdown-menu-right mt-1 ${
                        addNew ? "show" : ""
                      }`}
                    >
                      {_.map(
                        appOptions?.category ?? [],
                        (categoryMenu, index) =>
                          checkModuleAddPermission(
                            userRoleDetails,
                            getCategoryType(categoryMenu?.slug),
                            "profile"
                          ) ? (
                            <div
                              onClick={() =>
                                String(categoryMenu.slug ?? "").length > 0 &&
                                confirmCreate(
                                  getCategoryType(categoryMenu.slug)
                                )
                              }
                              key={index}
                              className="dropdown-item"
                            >
                              {categoryMenu?.display_name ?? "--"}
                            </div>
                          ) : (
                            <></>
                          )
                      )}
                    </div>
                  )}
                </div>
              )}
              {archiveRestoreStatus &&
                archiveRestoreStatus !== "notSelectable" && (
                  <button
                    className={
                      archiveRestoreStatus === "toArchive"
                        ? "btn-danger btn-sm float-right archiveLink"
                        : "btn btn-secondary btn-sm float-right archiveLink"
                    }
                    disabled={applicantProfile.length === 0}
                    onClick={() => bulkArchiveRestore()}
                  >
                    {archiveRestoreStatus === "toArchive"
                      ? "Archive"
                      : "Restore"}
                  </button>
                )}

              {/* {checkModuleDeletePermission(userRoleDetails, personRegistryType,  "profile") &&
                isDeactivate &&
                applicantIdle?.value !== "" && (
                  <>
                    <button
                      disabled={selectedRows.length === 0}
                      onClick={manageDeactivateOption}
                      className=" btn-danger btn-sm float-right archiveLink"
                      title="Deactivate"
                    >
                      Deactivate
                    </button>
                  </>
                )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                profiles.isLoading || profiles.isFetching || !intialDataLoaded
                  ? "card-body dataTableOuter table-loader"
                  : "card-body dataTableOuter"
              }
            >
              <DataTable
                className={checkIsSelectabale() ? "selectable" : ""}
                subHeader={true}
                selectableRows={checkIsSelectabale()}
                selectableRowDisabled={isRowSelectable}
                onSelectedRowsChange={onSelectRows}
                clearSelectedRows={toggledClearRows}
                progressPending={
                  profiles.isLoading ||
                  profiles.isFetching ||
                  !profiles?.data ||
                  !intialDataLoaded
                }
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={profiles?.data}
                customStyles={customStyles}
                sortOnServer={false}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={
                  profiles.meta && profiles.meta.pagination.count
                }
                paginationDefaultPage={activePage}
                paginationResetDefaultPage={resetPaginationDataTable}
                expandableRows
                expandableRowsComponent={TableExpandableComponent}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={<b>Confirm Delete</b>}
        content={
          <p>Are you sure you want to delete the selected profile details ?</p>
        }
        onSubmitCallback={deleteUserDetails}
        onCloseCallback={doCloseModal}
        submitButtonText={"Delete"}
        customClass="delete"
        isLoaderActive={isDeleteLoading}
      />
      <ModalComponent
        show={showDeactivateModal}
        header={<b>Confirm Deactivate</b>}
        content={
          <p>Are you sure you want to deactivate the selected profile(s) ?</p>
        }
        onSubmitCallback={doCloseModal}
        onCloseCallback={doCloseModal}
      />
      <ModalComponent
        show={openModal}
        header={`${activeData?.status === "archived" ? "Restore" : "Archive"}`}
        content={`Do you want to  ${
          activeData?.status === "archived" ? "restore" : "archive"
        } person profile?`}
        showSubmit={true}
        onSubmitCallback={() => confirmAcivationManagement()}
        onCloseCallback={() => {
          popCloseModal();
        }}
        isLoaderActive={isArchiveLoading}
        cancelButtonText={"No"}
        submitButtonText={"Yes"}
      />

      {archiveModal && (
        <>
          <ArchiveProfilePopup
            useID={applicantProfile}
            profileStatus={archiveRestoreStatusToComponent}
            closeArchivePopup={() => setArchiveModal(false)}
            archiveSuccess={() => onArchiveSuccess()}
          />
        </>
      )}
    </>
  );
};

export default ApplicantsList;
