import React from "react";
import { useMutation } from "jsonapi-react";
import { parseAPIResponseError } from "../../helpers/helpers";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import loginImage from "../../assets/images/login_img.png";
import logowhite from "../../assets/images/logo_w.png";
import logo from "../../assets/images/logo.png";
import { VW_ROUTES } from "../../constants";

export const ForgotPassword = () => {
  const initialState = {
    email: "",
  };
  const { addToast } = useToasts();
  const [formState, updateFormState] = React.useState(initialState);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, updateErrorMessage] = React.useState({});
  const [forgotUserPassword] = useMutation(["auth", "password", "forgot"]);

  const onInputChangeEvent = (e) => {
    updateFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };
  const onForgotPasswordSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const { error, data } = await forgotUserPassword(formState);
    setIsLoading(false);
    updateErrorMessage(parseAPIResponseError(error));
    if (error) {
      updateErrorMessage(parseAPIResponseError(error));
    } else if (data) {
      updateFormState(initialState);
      addToast(DISPLAY_TEXTS.PASSWORD_RESET_MAIL_SUCCESS, {
        appearance: "success",
      });
    }
    // if (data !== undefined) {
    //          setSession(data);
    //     }
    // doForgotPassword(formState).then((response, error) => {
    //     if(response.status === 200){
    //         history.push(VW_ROUTES.LOGIN);
    //     }
    // });
  };
  return (
    <>
      <div className="login-cover">
        <div className="left-section">
          <div className="content-portion">
            <div className="logo_w">
              <img src={logowhite} alt="v-watch" />
            </div>

            <p>
              Vwatch a revolutionary solution for enterprises to become hybrid
              workplaces. We offer a comprehensive suite that will help you
              automate work with AI and optimise your space in alignment with
              the hybrid needs of your workforce.
            </p>
            <p>
              Smart Solutions for smarter places. Delight your workforce with
              features designed to make their workplace experiences more
              seamless.
            </p>
          </div>
          <div className="image-holder">
            <img src={loginImage} alt="v-watch" />
          </div>
        </div>

        <div className="right-section">
          <div className="content-portion">
            <div className="content-inner">
              <div className="logo">
                <img src={logo} alt="v-watch" />
              </div>
              <h2>Reset Password</h2>
              <p>Please use your e-mail to reset your password.</p>

              <form onSubmit={onForgotPasswordSubmit}>
                <label className="form-group has-float-label mb-4">
                  <input
                    onChange={(e) => onInputChangeEvent(e)}
                    value={formState.email}
                    name="email"
                    type="text"
                    className="form-control"
                    disabled={isLoading}
                  />
                  <span>E-mail</span>
                  <p className="login-error-message">
                    {error && error.field === "email" ? error.errorMsg : ""}
                  </p>
                </label>
                <div className="d-flex justify-content-between align-items-center">
                  <a href={`#${VW_ROUTES.LOGIN}`}>Login</a>

                  <button
                    className="btn btn-secondary btn-lg btn-shadow position-relative"
                    type="submit"
                    disabled={isLoading}
                  >
                    RESET
                    {isLoading && (
                      <span className="button-loader-container">
                        <div
                          className="spinner-border spinner-border-sm ml-1 text-white"
                          role="status"
                        ></div>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
