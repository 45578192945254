import React, { useRef, useEffect } from "react";
import { CiCreditCard1 } from "react-icons/ci";
import _ from "lodash";
import {
  checkModuleDeletePermission,
  checkModuleEditPermission,
  checkModuleGeneralPermission,
  checkModuleViewPermission,
} from "../../../helpers/helpers";
import { apiRoutes } from "../../../constants";
import { BiSolidReport } from "react-icons/bi";

function SwipableCard(props) {
  const listElementRef = useRef();
  const wrapperRef = useRef();
  const backgroundRef = useRef();
  const buttonsRef = useRef();
  const dragStartXRef = useRef(0);
  const leftRef = useRef(0);
  const draggedRef = useRef(false);

  useEffect(() => {
    window.addEventListener("mouseup", onDragEndMouse);
    window.addEventListener("touchend", onDragEndTouch);
    return () => {
      window.removeEventListener("mouseup", onDragEndMouse);
      window.removeEventListener("touchend", onDragEndTouch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onDragStartMouse(evt) {
    onDragStart(evt.clientX);
    window.addEventListener("mousemove", onMouseMove);
  }

  function onDragStartTouch(evt) {
    const touch = evt.targetTouches[0];
    onDragStart(touch.clientX);
    window.addEventListener("touchmove", onTouchMove);
  }

  function onDragStart(clientX) {
    draggedRef.current = true;
    dragStartXRef.current = clientX;

    listElementRef.current.className = "ListItem";

    requestAnimationFrame(updatePosition);
  }

  function updatePosition() {
    if (listElementRef.current) {
      if (draggedRef.current) {
        requestAnimationFrame(updatePosition);
      }

      listElementRef.current.style.transform = `translateX(${leftRef.current}px)`;

      // Fade the opacity
      const opacity = (Math.abs(leftRef.current) / 100).toFixed(2);
      if (
        opacity < 1 &&
        opacity.toString() !== backgroundRef.current.style.opacity
      ) {
        // backgroundRef.current.style.opacity = opacity.toString();
      }
      if (opacity >= 1) {
        backgroundRef.current.style.opacity = "1";
      }
    }
  }

  function onMouseMove(evt) {
    const left = evt.clientX - dragStartXRef.current;
    if (left < 0) {
      leftRef.current = left;
    } else {
      leftRef.current = 0;
    }
  }

  function onTouchMove(evt) {
    const touch = evt.targetTouches[0];
    const left = touch.clientX - dragStartXRef.current;

    if (left < 0) {
      leftRef.current = left;
    } else {
      if (leftRef.current < 0) {
        leftRef.current = leftRef.current + left;
      } else {
        leftRef.current = 0;
      }
    }
  }

  function onDragEndMouse(evt) {
    window.removeEventListener("mousemove", onMouseMove);
    onDragEnd();
  }

  function onDragEndTouch(evt) {
    window.removeEventListener("touchmove", onTouchMove);
    onDragEnd();
  }

  function onDragEnd() {
    if (draggedRef.current) {
      draggedRef.current = false;
      const threshold = props.threshold || 0.3;

      if (
        leftRef.current <
        listElementRef.current.offsetWidth * threshold * -1
      ) {
        leftRef.current = -buttonsRef.current.offsetWidth;

        // wrapperRef.current.style.maxHeight = 0
        onSwiped();
      } else {
        leftRef.current = 0;
      }

      listElementRef.current.className = "BouncingListItem";
      listElementRef.current.style.transform = `translateX(${leftRef.current}px)`;
    }
  }

  function onSwiped() {
    if (props.onSwipe) {
      props.onSwipe();
    }
  }

  return (
    <>
      <div className="swipe-Wrapper" ref={wrapperRef}>
        <div className="swipe-Background" ref={backgroundRef}>
          <div className="swipe-buttons" ref={buttonsRef}>
            {props.isCompany && props.data?.profilesAdded > 0 && (
              <button onClick={() => props.fireEvent("profileLink")}>
                <i className="iconsminds-link user-role-action-icons"></i>{" "}
              </button>
            )}

            {props.isUser &&
              checkModuleGeneralPermission(
                props.actions,
                props.mode,
                props?.data?.isActive ? "inactivate_user" : "activate_user",
                props?.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("toggleStatus")}>
                  <i
                    className={`user-role-action-icons ${
                      props?.data?.isActive
                        ? "simple-icon-close text-danger"
                        : "simple-icon-check text-success"
                    }`}
                  ></i>{" "}
                </button>
              )}
            {props.isUser &&
              checkModuleGeneralPermission(
                props.actions,
                props.mode,
                "resend_invitation",
                props?.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("resend")}>
                  <i className="iconsminds-refresh user-role-action-icons"></i>{" "}
                </button>
              )}

            {props.isPerson &&
              checkModuleGeneralPermission(
                props.actions,
                props.mode,
                props?.data?.status === "archived"
                  ? "restore_profile"
                  : "archive_profile",
                props?.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("archive")}>
                  <i
                    className={`${
                      props?.data?.status === "archived"
                        ? "iconsminds-inbox-out"
                        : "iconsminds-inbox-into"
                    } user-role-action-icons`}
                  ></i>{" "}
                </button>
              )}
            {props.isPerson &&
              checkModuleGeneralPermission(
                props.actions,
                props.mode,
                props?.data?.status === "banned"
                  ? "unban_profile"
                  : "ban_profile",
                props?.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("ban")}>
                  <i
                    className={`${
                      props?.data?.status === "banned"
                        ? "simple-icon-user-following"
                        : "simple-icon-user-unfollow"
                    } user-role-action-icons`}
                  ></i>{" "}
                </button>
              )}
            {props.isVehicle && (
              <>
                {_.includes(props.actions, "plate") && (
                  <CiCreditCard1
                    onClick={() => props.fireEvent("plate")}
                    className="iconsminds-refresh user-role-action-icons"
                  />
                )}
              </>
            )}

            {(props?.isPersonReport || props?.isAccessExpiry) &&
              checkModuleViewPermission(
                props.actions,
                props.cellMode,
                "profile",
                props.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("view")}>
                  <i className="simple-icon-eye user-role-action-icons view"></i>{" "}
                </button>
              )}

            {!props.isCompany &&
              !props.isUser &&
              !props.isPersonReport &&
              !props.isAccessExpiry &&
              !props.isBulkRegistration &&
              checkModuleViewPermission(
                props.actions,
                props?.customMode ? props.cellMode : props.mode,
                `${props.isPerson ? "profile" : props?.type ?? ""}`,
                props.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("view")}>
                  <i className="simple-icon-eye user-role-action-icons view"></i>{" "}
                </button>
              )}
            {checkModuleEditPermission(
              props.actions,
              props.mode,
              `${props.isPerson ? "profile" : ""}`,
              props.data ?? {}
            ) && (
              <button onClick={() => props.fireEvent("edit")}>
                <i className="iconsminds-pen user-role-action-icons"></i>{" "}
              </button>
            )}
            {checkModuleDeletePermission(props.actions, props.mode) && (
              <button
                className="dButton"
                onClick={() => props.fireEvent("delete")}
              >
                <i className="simple-icon-trash user-role-action-icons"></i>{" "}
              </button>
            )}
            {props?.isInvite &&
              String(
                props?.getStatusLabel(props?.data?.status) ?? ""
              )?.toLowerCase() !== "expired" &&
              (String(
                props?.getStatusLabel(props?.data?.status) ?? ""
              )?.toLowerCase() === "inactive"
                ? checkModuleGeneralPermission(
                    props.actions,
                    "self-registration-invites",
                    "activate_invite",
                    props?.data ?? {}
                  )
                : checkModuleGeneralPermission(
                    props.actions,
                    "self-registration-invites",
                    "inactivate_invite",
                    props?.data ?? {}
                  )) && (
                <button onClick={() => props.fireEvent("toggleStatus")}>
                  <i
                    className={`${
                      String(
                        props?.getStatusLabel(props?.data?.status) ?? ""
                      )?.toLowerCase() === "inactive"
                        ? "simple-icon-check"
                        : "simple-icon-close"
                    } user-role-action-icons`}
                  ></i>{" "}
                </button>
              )}
            {props?.isInvite &&
              checkModuleGeneralPermission(
                props.actions,
                "self-registration-invites",
                "send_invite",
                props?.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("sendInvite")}>
                  <i
                    className={`simple-icon-paper-plane user-role-action-icons`}
                  ></i>{" "}
                </button>
              )}
            {props?.isInvite &&
              checkModuleDeletePermission(
                props.actions,
                "self-registration-invites",
                "invite",
                props?.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("deleteInvite")}>
                  <i className={`simple-icon-trash user-role-action-icons`}></i>{" "}
                </button>
              )}
            {props?.isPass &&
              checkModuleGeneralPermission(
                props.actions,
                "access-pass",
                "approve_pass",
                props?.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("approvePass")}>
                  <i
                    className={`simple-icon-check user-role-action-icons text-success`}
                  ></i>{" "}
                </button>
              )}
            {props?.isPass &&
              checkModuleGeneralPermission(
                props.actions,
                "access-pass",
                "reject_pass",
                props?.data ?? {}
              ) && (
                <button onClick={() => props.fireEvent("rejectPass")}>
                  <i
                    className={`simple-icon-close user-role-action-icons text-danger`}
                  ></i>{" "}
                </button>
              )}
            {props?.isDownload && (
              <button>
                <i className={`iconsminds-download`}></i>{" "}
              </button>
            )}
            {props?.isBulkRegistration &&
              checkModuleGeneralPermission(
                props.actions,
                "bulk-registration",
                "download_filereport",
                props?.data ?? {}
              ) && (
                <button>
                  <a
                    data-tip="Download report"
                    className="icon-link"
                    href={
                      String(props?.data?.id ?? "").length
                        ? `${apiRoutes.bulkRegistrationProfileFile}/${props?.data?.id}`
                        : "#"
                    }
                  >
                    <BiSolidReport />
                  </a>
                </button>
              )}
          </div>
        </div>
        <div
          className="ListItem"
          ref={listElementRef}
          onMouseDown={onDragStartMouse}
          onTouchStart={onDragStartTouch}
        >
          <div
            className="w-100"
            onClick={() =>
              checkModuleViewPermission(
                props.actions,
                props.isPersonReport ||
                  props.isAccessExpiry ||
                  props.customMode ||
                  props.isPass
                  ? props.cellMode
                  : props.mode,
                `${
                  props.isPerson || props.isPersonReport
                    ? "profile"
                    : props?.type ?? ""
                }`,
                props.data ?? {}
              ) &&
              !props.isCompany &&
              !props.isUser
                ? props.fireEvent("view")
                : null
            }
          >
            {props.children}
          </div>
        </div>
      </div>
    </>
  );
}

export default SwipableCard;
