/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../../../constants";
import Select from "react-select";
import { AppContext } from "../../../context/AppContext";
import CustomSelectClearIndicator from "../CustomSelectClearIndicator/CustomSelectClearIndicator";
import { formatValueWithDefaultOption } from "../../../helpers/utils";

const SelectApplicants = ({
  searchByApplicantType,
  applicantType,
  additionalOptions,
  showClearButton = false,
}) => {
  const { appOptions } = React.useContext(AppContext);
  const parseToSelectFormat = (options, keys, hideDefault = false) => {
    let res = [];
    if (options) {
      res = _.map(options, (option) => ({
        label: option[keys.label],
        value: option[keys.value],
      }));
      if (!hideDefault) {
        res.unshift({ label: "Select", value: "" });
      }
    }
    if (additionalOptions) {
      res = [...res, additionalOptions];
    }
    return res;
  };

  return (
    <>
      <Select
        isClearable={showClearButton}
        components={{ ClearIndicator: CustomSelectClearIndicator }}
        isSearchable={false}
        className="cutomSelect"
        classNamePrefix="react-select"
        value={formatValueWithDefaultOption(applicantType)}
        placeholder="Select"
        onChange={(e) => searchByApplicantType(e)}
        options={parseToSelectFormat(appOptions.category, {
          label: "display_name",
          value: "value",
        })}
      />
    </>
  );
};

export default SelectApplicants;
