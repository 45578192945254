import React, { useContext, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import Select from "react-select";
// import { RiRefreshFill } from "react-icons/ri";
import { AppContext } from "../../../context/AppContext";
import CustomCalendar from "../../CustomCalender/index";
import { useRef } from "react";
import SelectApplicants from "../SelectApplicants/SelectApplicants";
import { checkModuleGeneralPermission } from "../../../helpers/helpers";
import useStorage from "../../../helpers/useStorage";
import useCompanyOptions from "../CommonDeliverables/useCompanyOptions";
import { ACS_EVENT_TYPES_OPTIONS, reportDateFormat } from "../../../constants";
import LazyLoadedSelect from "../../LazyLoadedSelect/LazyLoadedSelect";
import ClearButton from "../ClearButton/ClearButton";
import {
  defaultSelectOption,
  selectOptionsDefaultOptionAppender,
  defaultFromDateFilterValue,
  defaultToDateFilterValue,
  formatValueWithDefaultOption,
} from "../../../helpers/utils";
import CustomSelectClearIndicator from "../CustomSelectClearIndicator/CustomSelectClearIndicator";
const AcsReportFilter = ({
  requestDataType,
  onSearchInputChange,
  onEventTimeFromChange,
  onApplicantTypeChange,
  onCompanyChange,
  onEventChange,
  handleUpdateKey,
  onEventTimeToChange,
  onExport,
  setCurrentPageNumber,
  setResetPagination,
  onResetInitiated,
  buttonVisibility,
  handleInitialFiltersDone,
}) => {
  const { setToStorage, getFromStorage, removeFromStorage } = useStorage();
  const [initialFilterDone, setInitialFilterDone] = React.useState(false);

  const { userRoleDetails } = useContext(AppContext);
  const {
    companies,
    handleCompanySearch,
    loadNextCompanyOptionsPage,
    isCompanyLoading,
  } = useCompanyOptions();

  const [searchText, setSearchText] = React.useState(
    getFromStorage("acs_report_filter_search") ?? ""
  );
  const [fromDate, setFromDate] = React.useState(
    getFromStorage("acs_report_filter_from_date") ??
      defaultFromDateFilterValue(reportDateFormat)
  );
  const [toDate, setToDate] = React.useState(
    getFromStorage("acs_report_filter_to_date") ??
      defaultToDateFilterValue(reportDateFormat)
  );
  const [type, setType] = React.useState(
    getFromStorage("acs_report_filter_type") ?? defaultSelectOption()
  );
  const [company, setCompany] = React.useState(
    getFromStorage("acs_report_filter_company") ?? defaultSelectOption()
  );
  const [event_type, setEventType] = React.useState(
    getFromStorage("acs_report_filter_event") ?? defaultSelectOption()
  );
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);
  // const onSubmitSearch = (e) => {
  //   e.preventDefault();

  //   onSearchInputChange(searchText);
  // };

  useEffect(() => {
    handleInitialFiltersDone(initialFilterDone);
  }, [initialFilterDone]);

  useEffect(() => {
    if (initialFilterDone) return;
    if (
      fromDate &&
      toDate &&
      moment(fromDate).isValid() &&
      moment(toDate).isValid()
    ) {
      setInitialFilterDone(true);
      applyFilters();
    }
  }, [fromDate, toDate, initialFilterDone]);

  React.useEffect(() => {
    if (!searchText) {
      onSearchInputChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const { pagePermissions } = React.useContext(AppContext);

  const parseToSelectFormat = (options, keys) => {
    let res = _.map(options, (option) => ({
      label: option[keys.label],
      value: option[keys.value],
    }));
    res.unshift(defaultSelectOption());
    return res;
  };

  const onFilterChange = (option) => {
    const { name, value, label } = option;

    switch (name) {
      case "toDate":
        setToDate(
          value === null ? "" : moment(value).format("YYYY-MM-DD HH:mm")
        );

        break;
      case "fromDate":
        setFromDate(
          value === null ? "" : moment(value).format("YYYY-MM-DD HH:mm")
        );

        break;
      case "type":
        setType({ label, value });

        break;
      case "company":
        setCompany({ label, value });
        break;

        break;
      case "event_type":
        setEventType({ label, value });

        break;
      default:
        break;
    }
  };

  const applyFilters = () => {
    removeFromStorage();
    setToStorage({
      acs_report_filter_from_date: fromDate ?? "",
      acs_report_filter_to_date: toDate ?? "",
      acs_report_filter_type: type ?? "",
      acs_report_filter_company: company ?? "",
      acs_report_filter_search: searchText ?? "",
      acs_report_filter_event: event_type ?? "",
    });

    onEventTimeToChange(toDate);
    onEventTimeFromChange(fromDate);
    onApplicantTypeChange(type?.value);
    onEventChange(event_type?.value);
    onCompanyChange(company?.label == "Select" ? "" : company?.label);
    setResetPagination();
    setCurrentPageNumber(1);
    handleUpdateKey();
    onSearchInputChange(searchText);
  };

  const handleSingleFieldReset = (field) => {
    switch (field) {
      case "category":
        setType(defaultSelectOption());
        return;
      case "company":
        setCompany(defaultSelectOption());
        return;
      case "eventType":
        setEventType(defaultSelectOption());
        return;
      case "search":
        setSearchText("");
        return;
      case "fromTime":
        setFromDate("");
        fromDateRef.current.handleChange("");
        return;
      case "toTime":
        setToDate("");
        toDateRef.current.handleChange("");
        return;
      default:
        return;
    }
  };

  const resetFilterOptions = () => {
    setFromDate(defaultFromDateFilterValue(reportDateFormat));
    fromDateRef.current.handleChange(defaultFromDateFilterValue());
    toDateRef.current.handleChange(defaultToDateFilterValue());
    onEventTimeFromChange(defaultFromDateFilterValue(reportDateFormat));
    setToDate(defaultToDateFilterValue(reportDateFormat));
    onEventTimeToChange(defaultToDateFilterValue(reportDateFormat));
    setType(defaultSelectOption());
    setEventType(defaultSelectOption());
    setCompany(defaultSelectOption());
    setSearchText("");
    onResetInitiated();
    setCurrentPageNumber(1);
    removeFromStorage();
    handleUpdateKey();
  };

  return (
    <div className="reports-filter-section pb-3 position-relative">
      <div className="row mb-2">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Category</span>
          <SelectApplicants
            showClearButton={true}
            searchByApplicantType={(opt) =>
              onFilterChange({
                name: "type",
                value: String(opt?.value ?? "").length ? opt?.label : "",
                label: opt?.label,
              })
            }
            applicantType={type}
            additionalOptions={{
              label: "Override Pass",
              value: "OVERRIDE_PASS",
            }}
          />
          {/* <Select
            className="cutomSelect"
            classNamePrefix="react-select"
            value={type}
            options={applicantType}
            onChange={(opt) =>
              onFilterChange({
                name: "type",
                value: opt.value,
                label: opt.label,
              })
            }
            name="type"
            placeholder="Select"
          /> */}
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 select-holder">
          <span>Company</span>
          <LazyLoadedSelect
            className="cutomSelect"
            classNamePrefix="react-select"
            loadNextOptions={loadNextCompanyOptionsPage}
            name="company"
            onChange={(opt) =>
              onFilterChange({
                name: "company",
                value: opt?.value,
                label: opt?.label,
              })
            }
            onInputChange={(value) => handleCompanySearch(value)}
            options={selectOptionsDefaultOptionAppender(companies)}
            placeholder="Select"
            value={company}
            isLoading={isCompanyLoading}
            isClearable={true}
            defaultValue={defaultSelectOption()}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Event Time From</span>
          <CustomCalendar
            maxDate={toDate ? toDate : moment().toDate()}
            // showTimeSelect
            dateFormat="YYYY-MM-DD hh:mm A"
            timePicker
            ref={fromDateRef}
            selected={fromDate === "" ? "" : new Date(fromDate)}
            onChange={(date) =>
              onFilterChange({ name: "fromDate", value: date, label: "" })
            }
            isClearable={true}
            onClear={() => handleSingleFieldReset("fromTime")}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Event Time To</span>
          <CustomCalendar
            maxDate={moment().toDate()}
            minDate={fromDate ? fromDate : ""}
            dateFormat="YYYY-MM-DD hh:mm A"
            ref={toDateRef}
            selected={toDate === "" ? "" : new Date(toDate)}
            onChange={(date) =>
              onFilterChange({ name: "toDate", value: date, label: "" })
            }
            timePicker
            isClearable={true}
            onClear={() => handleSingleFieldReset("toTime")}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 select-holder">
          <span>Event Type</span>
          <Select
            className="cutomSelect"
            classNamePrefix="react-select"
            value={formatValueWithDefaultOption(event_type)}
            options={parseToSelectFormat(ACS_EVENT_TYPES_OPTIONS, {
              label: "label",
              value: "value",
            })}
            onChange={(opt) =>
              onFilterChange({
                name: "event_type",
                value: opt?.value ?? "",
                label: opt?.label ?? "",
              })
            }
            name="event_type"
            placeholder="Select"
            isClearable={true}
            components={{ ClearIndicator: CustomSelectClearIndicator }}
            defaultValue={defaultSelectOption()}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Search</span>
          <div
            className="report-search-container searchHolder mb-0"
            // onSubmit={(e) => onSubmitSearch(e)}
          >
            <input
              type="text"
              className="form-control"
              name="search"
              value={searchText}
              id="search"
              placeholder="Search by Person name or Profile id"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <ClearButton
              onClick={() => handleSingleFieldReset("search")}
              hideClear={String(searchText ?? "").length == 0}
            />
            {/* <button className="btn btn-primary" type="submit">
              <span className="simple-icon-magnifier"></span>
            </button> */}
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span className="show-lg">&nbsp;</span>
          <div className="btn-cover ">
            <button
              onClick={() => applyFilters()}
              className="btn btn-secondary rounded-0 ml-1 "
            >
              Apply
            </button>
            <button
              onClick={resetFilterOptions}
              className="btn btn-secondary reset rounded-0 ml-1 "
            >
              <i className="iconsminds-repeat-2"></i>
            </button>
            {checkModuleGeneralPermission(
              userRoleDetails,
              "acs-event-report",
              "export_report"
            ) && (
              <button
                onClick={onExport}
                className="btn btn-secondary  rounded-0 ml-1"
                disabled={buttonVisibility}
              >
                Export
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcsReportFilter;
