import React from "react";
import DashboardCounterComponent from "../../components/common/DashboardWidgets/DashboardCounterComponent/DashboardCounterComponent";
import LivePeopleCountWidget from "../../components/common/DashboardWidgets/LivePeopleCountWidget";
import PeopleOverstayWidget from "../../components/common/DashboardWidgets/PeopleOverstayWidget";
import LiveVehicleCount from "../../components/common/DashboardWidgets/LiveVehicleCount";
import VehicleOverstayWidget from "../../components/common/DashboardWidgets/VehicleOverstayWidget";
import UserStats from "../../components/common/DashboardWidgets/UserStats";
import PeopleStat from "../../components/common/DashboardWidgets/PeopleStat";
import DashboardSliderComponent from "../../components/common/DashboardWidgets/DashboardSliderComponent/DashboardSliderComponent";
import { VW_ROUTES } from "../../constants";




// Define other widget components...

// Render the appropriate widget component based on the provided slug
const Widget = ({ slug, title, widgetData }) => {
  switch (slug) {
    case "live-people-count":
      return (
        <LivePeopleCountWidget
          widgetData={widgetData}
          title={title}
          slug={slug}
          hasLink
          route={VW_ROUTES.LIVE_PEOPLE_LISTING}
        />
      );
    case "people-overstay":
      return (
        <PeopleOverstayWidget
          widgetData={widgetData}
          title={title}
          slug={slug}
          hasLink
          route={VW_ROUTES.OVER_STAY_PEOPLE_LISTING}
        />
      );
    case "live-vehicle-count":
      return (
        <LiveVehicleCount
          widgetData={widgetData}
          title={title}
          slug={slug}
          hasLink
          route={VW_ROUTES.LIVE_VEHICLE_COUNT_LIST}
        />
      );
    case "vehicle-overstay":
      return (
        <VehicleOverstayWidget
          widgetData={widgetData}
          title={title}
          slug={slug}
          hasLink
          route={VW_ROUTES.OVERSTAY_VEHICLE_COUNT_LIST}
        />
      );
    case "people-stats":
      return <PeopleStat widgetData={widgetData} title={title} slug={slug} />;
    case "user-stats":
      return <UserStats widgetData={widgetData} title={title} slug={slug} />;
    case "contract-based-live-people-count":
      return (
        <DashboardSliderComponent
          widgetData={widgetData}
          title={title}
          slug={slug}
        />
      );
    case "company-based-live-people-count":
      return (
        <DashboardSliderComponent
          widgetData={widgetData}
          title={title}
          slug={slug}
        />
      );
    case "ppe-events":
      return (<LivePeopleCountWidget
        widgetData={widgetData}
        title={title}
        slug={slug}
        hasLink
        route={VW_ROUTES.DAILY_PPE_EVENTS_LISTING}
      />);
    case "camera-based-ppe-events":
      return (
        <DashboardSliderComponent
          widgetData={widgetData}
          title={title}
          slug={slug}
        />
      );
    default:
      return (
        <DashboardCounterComponent
          wData={widgetData}
          title={title}
          slug={slug}
        />
      );
  }
};

export default Widget;
