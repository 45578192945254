import axios from "axios";
import React, { useRef, useState } from "react";
import html2pdf from "html2pdf.js";

import { useReactToPrint } from "react-to-print";
import { API_BASE_URL, apiRoutes } from "../../../constants";
import { AppContext } from "../../../context/AppContext";
import IDLoader from "../Loader/IDLoader";
import { useContext } from "react";
import { checkModuleGeneralPermission } from "../../../helpers/helpers";
import DOMPurify from "dompurify";
const ViewIDCard = ({
  applicant,
  show,
  customClass,
  onCloseCallback = () => {},
}) => {
  const { userRoleDetails } = useContext(AppContext);

  const [idData, setIdData] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [pageType, setPageType] = useState("");
  const idcontainer = useRef();

  React.useEffect(() => {
    const token = localStorage.getItem("access_token");
    const options = {
      url: apiRoutes.idCard.replace("{profileId}", applicant?.id),
      method: "GET",
      headers: {
        // "content-type": "applicantion/xhtml+xml",
        Authorization: `JWT ${token}`,
      },
    };
    axios(options)
      .then((response) => {
        // setIdData({ __html: response.data });

        setIdData(response.data);
        // let data =
        //   "<div><img  src='https://exyte.vwatch.pro/static/media/logo.e355c43e.png'/></div > ";

        // setIdData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [applicant.id]);

  React.useEffect(() => {
    if (applicant) {
      setPageType(
        applicant?.category.slug === "employee"
          ? "employee-profile"
          : "visitor-profile"
      );
    }
  }, [applicant]);

  const printData = useReactToPrint({
    content: () => idcontainer.current,
    // onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: () => {
      setLoading(false);
    },
  });
  const handlePrint = () => {
    setLoading(true);
    printData();
  };
  const downloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };
  const printToPDF = () => {
    const token = localStorage.getItem("access_token");
    const options = {
      url: apiRoutes.idCardDownload.replace("{profileId}", applicant?.id),
      method: "GET",
      headers: {
        "content-type": "application/pdf",
        Authorization: `JWT ${token}`,
      },
      responseType: "blob",
    };
    axios(options)
      .then((response) => {
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition
          ? contentDisposition.split(";")[1].split("=")[1].replace(/"/g, "")
          : undefined;
        const pdfUrl = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = "_blank";
        link.download = filename ? filename : "id_card.pdf";
        link.click();
        setLoading(false);
        onCloseCallback();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handlePDF = () => {
    setLoading(true);
    printToPDF();
  };

  return (
    <>
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        id="ModalComponent"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className={
            customClass
              ? customClass + " modal-dialog modal-dialog-centered"
              : "modal-dialog modal-dialog-centered"
          }
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <b>ID Card</b>
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => onCloseCallback()}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loading && <IDLoader />}
              <div className="id-card-content-box">
                {!loading && idData && (
                  <div ref={idcontainer} id="idsection" dangerouslySetInnerHTML={{__html: idData ? DOMPurify.sanitize(idData) : <></>}} />
                )}
              </div>
            </div>

            <div className="modal-footer">
              {checkModuleGeneralPermission(
                userRoleDetails,
                pageType,
                "print_idcard",
                applicant
              ) && (
                <button
                  type="button"
                  disabled={loading}
                  // onClick={() => onSubmitCallback()}
                  onClick={() => handlePrint()}
                  className="btn btn-secondary "
                >
                  Print
                </button>
              )}

              {checkModuleGeneralPermission(
                userRoleDetails,
                pageType,
                "download_idcard",
                applicant
              ) && (
                <button
                  type="button"
                  disabled={loading}
                  // onClick={() => onSubmitCallback()}
                  onClick={() => {
                    handlePDF();
                  }}
                  className="btn btn-secondary  "
                >
                  Download
                </button>
              )}
              <button
                type="button"
                className="btn btn-primary "
                disabled={loading}
                onClick={() => onCloseCallback()}
              >
                {"Close"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewIDCard;
