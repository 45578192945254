import React from "react";
import { AppContext } from "../../../context/AppContext";

const BanProfile = (data) => {
  const { showBanModalPopup} = React.useContext(AppContext);

  return (
    <>
      <button
        type="button"
        onClick={() => showBanModalPopup({ show: true, data: data, reload:false })}
      >
        <i className={`${data.data?.status === "banned" ? "simple-icon-user-following" : "simple-icon-user-unfollow"}`}></i>
        {data.data.status == "banned" ? "Unban" : "Ban"}
      </button>
    </>
  );
};

export default BanProfile;
