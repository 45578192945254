import React from "react";

const CancelApplication = ({ updateInputChange, validation,value="" }) => {
  return (
    <div className="form-row">
      <div className={`form-group position-relative error-l-75 col-md-12 ${
        String(validation?.msg ?? "").length ? "hasValidationError" : ""
        }`}>
        <label htmlFor="mobileNumber">
          Cancel Note<i>*</i>
        </label>
        <textarea
          type="text"
          className="form-control"
          name="mobileNumber"
          id="mobileNumber"
          value={value??""}
          placeholder="Cancel Note"
          onChange={(e) => updateInputChange(e.target.value)}
        />
        <p className="error-message">
          {validation && validation.msg ? validation.msg : ""}
        </p>
      </div>
    </div>
  );
};

export default CancelApplication;
