/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";

import { useClient } from "jsonapi-react";
import DataTable from "react-data-table-component";
import axios from "axios";
import noPreview from "../../assets/images/nopreview.png";
import moment from "moment-timezone";
import { APP_TIMEZONE, reportDateFormat } from "../../constants";
import { DEVICE_SERVICE_URL } from "../../constants";
import LPREventReportFilter from "../../components/common/ReportFilter/LPREventReportFilter";
import ProfileCard from "../../components/ProfileCard/ProfileCard";

import useState from "react-usestateref";
import { CiCreditCard1 } from "react-icons/ci";

import LPRTransactionReportExpansion from "./components/LPRTransactionReportExpansion.component";

import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import ReactTooltip from "react-tooltip";
import {
  formatTextWithFallback,
  getLocalDateTime,
} from "../../helpers/helpers";
import useStorage from "../../helpers/useStorage";
import {
  defaultFromDateFilterValue,
  defaultToDateFilterValue,
} from "../../helpers/utils";
const LPRTransactionReport = () => {
  const { setToStorage, getFromStorage, removeFromStorage } = useStorage();
  const [isLoading, setIsLoading] = React.useState(true);

  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [updateKey, setUpdateKey] = React.useState(0);
  const [resetPagination, setResetPagination] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("lpr_report_rows_per_page") ?? 10
  );
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [totalEntries, setTotalEntries] = React.useState(0);
  const [markerId, setMarkerId] = React.useState("");
  const [pageAction, setPageAction] = React.useState("first");
  const [searchQueryString, setSearchQueryString] = useState(
    getFromStorage("lpr_report_filter_search") ?? ""
  );
  const [inAndOut, setInAndOut] = useState(
    getFromStorage("lpr_report_filter_inout")?.value ?? ""
  );
  const [exportData, setExportData] = useState({ page: "lpr" });
  const [crossTimeFrom, setcrossTimeFrom] = React.useState(
    getFromStorage("lpr_report_filter_from_date") ??
      defaultFromDateFilterValue(reportDateFormat)
  );
  const [crossTimeTo, setcrossTimeTo] = React.useState(
    getFromStorage("lpr_report_filter_to_date") ??
      defaultToDateFilterValue(reportDateFormat)
  );

  const [lprReport, setlprReport] = React.useState([]);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  const [disableExport, setdisableExport] = React.useState(true);
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const axiosOptions = (method, url) => {
    return {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: `${DEVICE_SERVICE_URL}/${url}`,
    };
  };

  const onResetInitiated = () => {
    setPageAction("first");
    setMarkerId("");
    setcrossTimeFrom(defaultFromDateFilterValue(reportDateFormat));
    setcrossTimeTo(defaultToDateFilterValue(reportDateFormat));
    setCurrentPageNumber(1);
    setInAndOut("");
    setResetPagination(true);

    removeFromStorage();
  };
  const handleUpdateKey = () => {
    setUpdateKey((k) => k + 1);
  };
  React.useEffect(() => {
    if (lprReport?.length > 0) {
      setdisableExport(false);
    } else {
      setdisableExport(true);
    }
  }, [lprReport]);

  const doFilter = () => {
    setIsLoading(true);
    setResetPagination(false);

    let filterItems = {
      search: searchQueryString ?? "",
      inAndOutType: inAndOut ?? "",
      crossTimeFrom:
        crossTimeFrom === ""
          ? ""
          : moment
              .tz(crossTimeFrom, "YYYY-MM-DD HH:mm:ss", APP_TIMEZONE)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
      crossTimeTo:
        crossTimeTo === ""
          ? ""
          : moment
              .tz(crossTimeTo, "YYYY-MM-DD HH:mm:ss", APP_TIMEZONE)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
    };

    let filter = "";
    for (const [, [key, value]] of Object.entries(
      Object.entries(filterItems)
    )) {
      if (value !== "" && value) {
        filter += `&${key}=${value}`;
      }
    }
    if (pageAction !== "first" && pageAction !== "last") {
      filter += `&markerId=${markerId}`;
    }
    setExportData({ ...exportData, filter: filterItems });
    axios(
      axiosOptions(
        "GET",
        `lpr/events?pageAction=${pageAction}&pageSize=${rowsPerPage}${filter}`
      )
    )
      .then((response) => {
        setIsLoading(false);
        const {
          data: {
            data: lprTransactionReportData,
            meta: {
              pagination: { count: totalEntries },
            },
          },
        } = response;
        setlprReport(lprTransactionReportData);
        setTotalEntries(totalEntries);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    doFilter();
  }, [
    crossTimeFrom,
    crossTimeTo,
    currentPageNumber,
    rowsPerPage,
    inAndOut,
    pageAction,
    updateKey,
    searchQueryString,
  ]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "plate") {
      onPictureClick(
        mode.lprReport.plateNoPicUri,
        "Plate No",
        mode.lprReport.isImagesUploaded &&
          mode?.lprReport?.plateNoPicUri &&
          typeof mode.lprReport.plateNoPicUri === "string" &&
          mode.lprReport.plateNoPicUri !== ""
          ? true
          : false
      );
    }
  };
  const columns = [
    {
      name: "Plate No.",
      selector: (lprReport) => lprReport?.plateNo,
      minWidth: "200px",
      sortable: true,
      cell: (lprReport) => (
        <ProfileCard
          data={lprReport}
          mode="lprReport"
          actions={["plate"]}
          fields={[
            {
              name: "Plate No.",
              value: formatTextWithFallback(lprReport?.plateNo),
            },
            {
              name: "Event Time",
              value: (
                <span className="text-lowercase">
                  {getLocalDateTime(lprReport?.crossTime)}
                </span>
              ),
            },
            {
              name: "Roadway ",
              value: formatTextWithFallback(lprReport?.roadwayName),
            },
            {
              name: "Entrance ",
              value: formatTextWithFallback(lprReport?.entranceName),
            },

            {
              name: "Event Type",
              value: formatTextWithFallback(lprReport?.inAndOutType),
            },
          ]}
          actionsToParent={(mode) => getEvent({ mode, lprReport })}
        />
      ),
    },

    {
      name: "Roadway",
      minWidth: "200px",
      selector: (lprReport) => lprReport?.roadwayName,
      sortable: false,
      hide: "md",
      cell: (lprReport) => formatTextWithFallback(lprReport?.roadwayName),
    },

    {
      name: "Entrance",
      minWidth: "200px",
      selector: (lprReport) => lprReport?.entranceName,
      sortable: false,
      hide: "md",
      cell: (lprReport) => formatTextWithFallback(lprReport?.entranceName),
    },

    {
      name: "Event Type",
      minWidth: "200px",
      selector: (lprReport) => lprReport?.inAndOutType,
      sortable: false,
      hide: "md",
      cell: (lprReport) => formatTextWithFallback(lprReport?.inAndOutType),
    },
    {
      name: "Event Time",
      minWidth: 200,
      hide: "md",
      selector: (lprReport) => lprReport?.crossTime,
      style: { position: "sticky", right: 100 },
      cell: (staff) => getLocalDateTime(staff?.crossTime),
    },
    {
      name: "Actions",
      sortable: false,
      style: { position: "sticky", right: 0 },
      center: true,
      selector: (lprReport) => lprReport?.plateNo,
      maxWidth: 100,
      minWidth: 100,
      cell: (staff) => (
        <div className="actionColumn">
          <CiCreditCard1
            data-tip="View Plate no."
            className=" user-role-action-icons view mr-1"
            onClick={() =>
              onPictureClick(
                staff.plateNoPicUri,
                "Plate No",
                staff.isImagesUploaded &&
                  staff.plateNoPicUri &&
                  typeof staff.plateNoPicUri === "string" &&
                  staff.plateNoPicUri !== ""
                  ? true
                  : false
              )
            }
          />
          <ReactTooltip className="table-tooltip" />
        </div>
      ),
      right: true,
      hide: "md",
    },
  ];

  const onChangePage = (page) => {
    let pageActionType = currentPageNumber > page ? "previous" : "next";

    if (page - 1 === currentPageNumber) {
      pageActionType = "next";
    } else {
      if (page === 1) {
        pageActionType = "first";
      } else if (page === Math.ceil(totalEntries / rowsPerPage)) {
        pageActionType = "last";
      }
    }

    setPageAction(pageActionType);
    const currentMarkerId =
      pageActionType === "next"
        ? lprReport[lprReport.length - 1]._id.$oid
        : lprReport[0]._id.$oid;
    setMarkerId(currentMarkerId);

    setCurrentPageNumber(page);
  };

  const onSearchInputChange = (searchQuery) => {
    setSearchQueryString(searchQuery);
    // doFilter();
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({ lpr_report_rows_per_page: rows }, true);
    removeFromStorage();
    setRowsPerPage(rows);
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    setDialogContent(null);
    setDialogTitle(null);
  };
  function imageDialogContentGenVehicle(data, n, f) {
    let imageSrc = data;

    return (
      <div className="d-flex justify-content-center align-items-center">
        <img
          src={f ? imageSrc : noPreview}
          alt={n ?? ""}
          height={f ? "100%" : "150px"}
        />
      </div>
    );
  }
  const onPictureClick = (e, n, f) => {
    setDialogTitle(n);

    setDialogContent(imageDialogContentGenVehicle(e, n, f));

    setShowDialog(true);
  };
  return (
    <>
      <ModalComponent
        showSubmit={true}
        content={dialogContent}
        header={dialogTitle}
        show={showDialog}
        hideCancel
        onSubmitCallback={handleCloseDialog}
        onCloseCallback={handleCloseDialog}
        submitButtonText="Close"
      />
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <div className="d-flex w-100 justify-content-between">
              <div>
                <h1>ANPR Event Report</h1>
              </div>
              <div
                className="tableFilterButton"
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>
            </div>
          </div>
          <div className="ReportFilterHolder">
            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="reportSearch">
                  <LPREventReportFilter
                    oncrossTimeFromChange={setcrossTimeFrom}
                    onInAndOutChange={setInAndOut}
                    handleUpdateKey={handleUpdateKey}
                    loading={isLoading}
                    setPageAction={setPageAction}
                    oncrossTimeToChange={setcrossTimeTo}
                    setUpdateKey={setUpdateKey}
                    exportData={exportData}
                    onSearchInputChange={onSearchInputChange}
                    onResetInitiated={onResetInitiated}
                    setResetPagination={setResetPagination}
                    buttonVisibility={disableExport}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={`hasStickLastColumn hasStickyAction
                ${
                  isLoading
                    ? "card-body remove-header dataTableOuter reportTable table-loader"
                    : "card-body remove-header dataTableOuter reportTable"
                }`}
            >
              <DataTable
                subHeader={true}
                progressPending={isLoading}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={lprReport}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalEntries}
                paginationResetDefaultPage={resetPagination}
                expandableRows
                expandableRowsComponent={LPRTransactionReportExpansion}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LPRTransactionReport;
