/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback } from "react";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import _ from "lodash";
import DataTable from "react-data-table-component";
import { AppContext } from "../../context/AppContext";
//import { useBodyClass } from "../../helpers/utils";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import ModalRightComponent from "../../components/common/ModalRightComponent/ModalRightComponent";
import SearchBar from "../../components/common/Search/Search";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import {
  checkModuleAddPermission,
  checkModuleDeletePermission,
  checkModuleGeneralPermission,
  formatTextWithFallback,
  trimDoubleSpace,
  trimSingleSpace,
  getCurrentWindowParams,
  validatorModule,
} from "../../helpers/helpers";
import Select from "react-select";
import { API_BASE_URL, VW_ROUTES, apiRoutes } from "../../constants";
import ContextMenuContentWrapper from "../../components/common/ContextMenuContentWrapper";
import PublicRegisterInviteFormComponent from "../../components/PublicRegisterInviteFormComponent/PublicRegisterInviteFormComponent";
import moment from "moment-timezone";
import useInviteStatusOptions from "../../components/common/CommonDeliverables/useInviteStatusOptions";
import PillText from "../../components/PillText/PillText";
import Axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { defaultSelectOption } from "../../helpers/utils";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const SelfRegistrationInvites = ({ location }) => {
  const initialState = {
    description: "",
    expiry: null,
  };

  const recipientsValidationFormat = [
    {
      field: "recipients",
      isEmailList: true,
      isRequired: true,
      fieldName: "Recipients",
    },
  ];

  const validationFormat = [
    {
      field: "description",
      minOneAlphabet: true,
      minLength: 3,
      maxLength: 120,
      isRequired: true,
      fieldName: "Description",
    },
    ...recipientsValidationFormat,
  ];
  const initialErrorState = {
    description: "",
    expiry: "",
    recipients: "",
  };
  const initialRecipientsErrorState = {
    recipients: "",
  };
  const { addToast } = useToasts();

  const {
    showRightModal,
    manageRightModal,
    showModal,
    manageModal,
    initialPageParams,
    updateInitialPageParams,
    setPageUrlParams,
    userRoleDetails,
  } = React.useContext(AppContext);
  //useBodyClass('modal-open');
  const [inviteDescription, setInviteDescription] = React.useState("");
  const [inviteRecipients, setInviteRecipients] = React.useState("");
  const [inviteExpiry, setInviteExpiry] = React.useState("");
  const [inviteMultipleUse, setInviteMultipleUse] = React.useState(0);
  const [currentInviteId, setCurrentInviteId] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [intialDataLoaded, setInitialDataLoaded] = React.useState(false);

  const [searchQueryString, setSearchQueryString] = React.useState("");
  const [formState, updateFormState] = React.useState(initialState);
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const [showInviteModal, setShowInviteModal] = React.useState(false);
  const [inviteButtonPressed, setInviteButtonPressed] = React.useState(false);
  const [isInviteRecipientsLoading, setInviteRecipientsLoading] =
    React.useState(false);
  const [resetPaginationDataTable, setResetPaginationDataTable] =
    React.useState(false);

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(null);
  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [newRecipientsErrorState, setNewRecipientsErrorState] = React.useState(
    initialRecipientsErrorState
  );

  const windowParams = getCurrentWindowParams();
  const pageNumber = windowParams.get("page");
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    Number(pageNumber) || 1
  );

  const [createInvite] = useMutation(apiRoutes.publicRegisterInvite);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const token = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "Content-Type": "application/json",
    },
  };
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  let initialSearchKey;
  const searchWord = window.location.hash.split("search=")[1];
  if (searchWord) {
    initialSearchKey = decodeURI(searchWord);
  } else {
    initialSearchKey = "";
  }

  const [filter, setFilter] = React.useState({
    search: initialSearchKey,
  });

  const [filterStatus, setFilterStatus] = React.useState();

  const toggleResetPagination = () => {
    setResetPaginationDataTable((prevToggle) => !prevToggle);
  };

  const selectFilterStatus = (e) => {
    setFilterStatus(e);
  };

  const invites = useQuery(
    activePage && [
      apiRoutes.publicRegisterInvite,
      {
        include: "invite_recipients",
        filter: filter,
        page: paginationSettings,
      },
    ],
    { client }
  );

  React.useEffect(() => {
    if (invites.data && !invites.isLoading) {
      setInitialDataLoaded(true);
    } else {
      setInitialDataLoaded(false);
    }
  }, [invites]);

  const { inviteStatusOptions } = useInviteStatusOptions();

  const getStatusFromValue = useCallback(
    (value) =>
      inviteStatusOptions?.find((item) => item?.value === value) ?? null,
    [inviteStatusOptions]
  );

  const getStatusLabel = (value) => getStatusFromValue(value)?.label ?? null;

  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }
  }, []);

  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(Number(pageNumber));
      setactivePage(Number(pageNumber));
      // setactivePage(initialPageParams.initialPageNumber);
      setSearchQueryString(initialPageParams.initialSearchKey);
    }
  }, [initialPageParams, pageNumber]);

  const FilterSection = () => {
    return (
      <div className="advance-filter">
        <div>
          <span>Status</span>
          <div>
            <Select
              placeholder="Select"
              className="cutomSelect"
              classNamePrefix="react-select"
              value={filterStatus}
              onChange={(e) => selectFilterStatus(e)}
              options={[
                { label: "Select", value: null },
                ...(inviteStatusOptions ? inviteStatusOptions : []),
              ]}
              defaultValue={defaultSelectOption()}
            />
          </div>
        </div>
      </div>
    );
  };

  const onInputChange = (e) => {
    const newState = { [e.target.name]: e.target.value };
    if (_.has(newState, "description")) {
      setInviteDescription(trimDoubleSpace(newState["description"]));
      newState["description"] = trimDoubleSpace(newState["description"]);
    } else if (_.has(newState, "expiry")) {
      newState["expiry"] = newState["expiry"];
      setInviteExpiry(newState["expiry"]);
    } else if (_.has(newState, "recipients")) {
      newState["recipients"] = trimSingleSpace(newState["recipients"]);
      setInviteRecipients(newState["recipients"]);
      if (!newState["recipients"]?.length) {
        delete newState["recipients"];
        delete formState["recipients"];
      }
    } else if (_.has(newState, "multipleUse")) {
      newState["multipleUse"] = !inviteMultipleUse ? 1 : 0;
      setInviteMultipleUse(!inviteMultipleUse);
    }
    updateFormState({
      ...formState,
      ...newState,
    });
  };
  const manageModalSettings = (status) => {
    manageRightModal(status);
  };

  const deleteInvite = async () => {
    const { error } = await client.delete(
      `${apiRoutes.publicRegisterInvite}/${currentInviteId}`
    );
    if (error === undefined) {
      invites.refetch();
      addToast(DISPLAY_TEXTS.INVITE_DELETED, { appearance: "success" });
    } else {
      addToast(DISPLAY_TEXTS.INVITE_DELETED_ERROR, { appearance: "warning" });
    }
    setCurrentInviteId(0);
    manageModal(false);
  };

  const toggleInviteStatus = async (invite) => {
    const currentStatus = String(getStatusLabel(invite?.status))?.toLowerCase();
    if (!currentStatus?.length) {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "error" });
      return;
    }

    const url = new URL(
      `${API_BASE_URL}${apiRoutes.publicRegisterInvite}/${invite?.id}/${
        currentStatus === "active" ? "inactivate" : "activate"
      }`
    );
    Axios.post(url, {}, config)
      .then(() => {
        invites.refetch();
        addToast(
          currentStatus === "active"
            ? DISPLAY_TEXTS.INVITE_INACTIVATED
            : DISPLAY_TEXTS.INVITE_ACTIVATED,
          { appearance: "success" }
        );
      })
      .catch(() => {
        addToast(
          currentStatus === "active"
            ? DISPLAY_TEXTS.INVITE_INACTIVATED_ERROR
            : DISPLAY_TEXTS.INVITE_ACTIVATED_ERROR,
          { appearance: "warning" }
        );
      });
  };

  const handleInviteRecipients = async () => {
    setInviteButtonPressed(true);
    const newRecipientsFormState = {
      recipients: inviteRecipients,
    };
    const errors = validatorModule(
      newRecipientsFormState,
      recipientsValidationFormat
    );
    if (Object.keys(errors).length !== 0) {
      setNewRecipientsErrorState(errors);
      return;
    }
    setNewRecipientsErrorState(initialRecipientsErrorState);

    setInviteRecipientsLoading(true);

    const url = new URL(
      `${API_BASE_URL}${apiRoutes.publicRegisterInvite}/${currentInviteId}/send`
    );
    Axios.post(
      url,
      {
        data: {
          type: "self-register",
          id: currentInviteId ?? "",
          attributes: {
            recipients: inviteRecipients ?? "",
          },
        },
      },
      config
    )
      .then(() => {
        addToast(DISPLAY_TEXTS.INVITE_SENT, { appearance: "success" });
      })
      .catch(() => {
        addToast(DISPLAY_TEXTS.INVITE_SENT_ERROR, { appearance: "warning" });
      })
      .finally(() => {
        setInviteRecipientsLoading(false);
        handleCloseInviteModal();
      });
  };

  const submitNewInvite = async () => {
    let errors = errorValidate();

    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      if (formState.description !== "") {
        const formValues = { ...formState };
        if (formValues?.expiry)
          formValues.expiry = moment(formValues.expiry).format("DD-MM-YYYY");
        const { data, error } = await createInvite(formValues);
        if (data && error === undefined) {
          invites.refetch();
          addToast(DISPLAY_TEXTS.INVITE_ADDED, { appearance: "success" });
        } else if (error !== undefined && error.detail) {
          addToast(error.detail, { appearance: "error" });
        } else {
          console.log(error);
          addToast(DISPLAY_TEXTS.INVITE_ADDED_ERROR, { appearance: "warning" });
        }
        manageModalSettings(false);
        setInviteDescription("");
      }
    }
  };

  const addNewInvite = () => {
    setInviteDescription("");
    setInviteExpiry("");
    setInviteRecipients("");
    setInviteMultipleUse(false);
    updateFormState({
      description: "",
      expiry: null,
      multipleUse: 0,
    });
    setErrorState({
      description: "",
      expiry: "",
      recipients: "",
      multipleUse: "",
    });
    manageRightModal(true);
  };

  const manageDeletedOption = (id) => {
    setCurrentInviteId(id);
    manageModal(true);
  };

  const handleShowInviteModal = (id) => {
    setCurrentInviteId(id);
    setInviteRecipients("");
    setNewRecipientsErrorState(initialErrorState);
    setShowInviteModal(true);
    setInviteButtonPressed(false);
  };

  const handleCloseInviteModal = () => {
    setShowInviteModal(false);
    setInviteButtonPressed(false);
  };

  const doCloseModal = () => {
    setCurrentInviteId(0);
    manageModal(false);
  };
  const errorValidate = () => {
    let errors = validatorModule(formState, validationFormat);

    return errors;
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "toggleStatus") {
      // manageDeletedOption(mode.invites.id, mode.invites);
      toggleInviteStatus(mode.invite);
    } else if (mode.mode === "sendInvite") {
      // manageDeletedOption(mode.invites.id, mode.invites);
      handleShowInviteModal(mode.invite);
    } else if (mode.mode === "deleteInvite") {
      // manageDeletedOption(mode.invites.id, mode.invites);
      manageDeletedOption(mode.invite?.id, mode.invite);
    }
  };

  let menuRef = React.useRef();
  React.useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (
        event.target.className !== "user-role-action-icons" &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setTableUserId("");
      }
    });
  });
  const [tableUserId, setTableUserId] = React.useState("");
  const showHideContextMenu = (userid) => {
    setTableUserId(userid);
  };
  const renderProfileCard = (invite) => (
    <ProfileCard
      data={invite}
      mode="self-registration-invite"
      actions={userRoleDetails}
      actionsToParent={(mode) => getEvent({ mode, invite })}
      getStatusLabel={getStatusLabel}
      fields={[
        {
          name: "Created Timestamp",
          value:
            invite?.createdAt &&
            moment(invite?.createdAt, "DD-MM-YYYY hh:mm a")?.isValid()
              ? moment(invite?.createdAt).format("DD-MM-YYYY hh:mm a")
              : null,
        },
        {
          name: "Expiry",
          value:
            invite?.expiry && moment(invite?.expiry, "DD-MM-YYYY")?.isValid()
              ? moment(invite?.expiry, "DD-MM-YYYY").format("DD-MM-YYYY")
              : null,
        },
        {
          name: "Status",
          value: getStatusLabel(invite?.status),
        },
      ]}
    />
  );
  const showContextActionMenu = (invite) => {
    const hasInviteActivatePermission = checkModuleGeneralPermission(
      userRoleDetails,
      "self-registration-invites",
      "activate_invite",
      invite
    );

    const hasInInviteActivatePermission = checkModuleGeneralPermission(
      userRoleDetails,
      "self-registration-invites",
      "inactivate_invite",
      invite
    );

    const hasInInviteSendPermission = checkModuleGeneralPermission(
      userRoleDetails,
      "self-registration-invites",
      "send_invite",
      invite
    );

    const hasInviteDeletePermission = checkModuleDeletePermission(
      userRoleDetails,
      "self-registration-invites",
      "invite",
      invite
    );
    return (
      ((String(getStatusLabel(invite?.status))?.toLowerCase() === "inactive"
        ? hasInviteActivatePermission
        : hasInInviteActivatePermission) ||
        hasInInviteSendPermission) && (
        <div className="contextMenu">
          {tableUserId === invite.id && (
            <ContextMenuContentWrapper
              showContextMenu={tableUserId === invite.id}
              handleContextMenuClose={() => setTableUserId("")}
            >
              <ul ref={menuRef}>
                {String(getStatusLabel(invite?.status))?.toLowerCase() !==
                  "expired" &&
                  (String(getStatusLabel(invite?.status))?.toLowerCase() ===
                  "inactive"
                    ? hasInviteActivatePermission
                    : hasInInviteActivatePermission) && (
                    <>
                      <li onClick={() => {}}>
                        <button onClick={() => toggleInviteStatus(invite)}>
                          <i
                            className={`user-role-action-icons ${
                              String(
                                getStatusLabel(invite?.status)
                              )?.toLowerCase() === "inactive"
                                ? "simple-icon-check success"
                                : "simple-icon-close error"
                            }`}
                          ></i>
                          {String(
                            getStatusLabel(invite?.status)
                          )?.toLowerCase() === "inactive"
                            ? "Activate"
                            : "Inactivate"}
                        </button>
                      </li>
                    </>
                  )}
                {hasInInviteSendPermission && (
                  <li onClick={() => handleShowInviteModal(invite?.id)}>
                    <button>
                      <i className="simple-icon-paper-plane user-role-action-icons primary"></i>
                      Invite
                    </button>
                  </li>
                )}
                {hasInviteDeletePermission && (
                  <li onClick={() => manageDeletedOption(invite.id, invite)}>
                    <button>
                      <i className="simple-icon-trash user-role-action-icons delete"></i>
                      Delete
                    </button>
                  </li>
                )}
              </ul>
            </ContextMenuContentWrapper>
          )}
          <span
            className="user-role-action-icons"
            onClick={() => showHideContextMenu(invite.id)}
          >
            <i></i>
          </span>
        </div>
      )
    );
  };

  const columns = [
    {
      name: "Description",
      minWidth: 200,
      selector: (row) => row["description"],
      cell: (invite) => renderProfileCard(invite),
    },
    {
      name: "Created Timestamp",
      minWidth: 200,
      maxWidth: 250,
      hide: "md",
      selector: (row) => row["createdAt"],
      cell: (invite) =>
        formatTextWithFallback(
          invite?.createdAt &&
            moment(invite?.createdAt, "DD-MM-YYYY hh:mm a")?.isValid()
            ? moment(invite?.createdAt).format("DD-MM-YYYY hh:mm a")
            : null
        ),
    },
    {
      name: "Expiry",
      minWidth: 200,
      maxWidth: 200,
      hide: "md",
      selector: (row) => row["expiry"],
      cell: (invite) =>
        formatTextWithFallback(
          invite?.expiry && moment(invite?.expiry, "DD-MM-YYYY")?.isValid
            ? moment(invite?.expiry, "DD-MM-YYYY").format("DD-MM-YYYY")
            : null
        ),
    },
    {
      name: "Status",
      minWidth: 200,
      maxWidth: 200,
      selector: (row) => row["status"],
      center: true,
      cell: (invite) => (
        <>
          {String(invite?.status)?.length ? (
            <PillText
              label={getStatusLabel(invite?.status ?? "")}
              type={invite?.status}
              mapper={{ 0: "third_party", 1: "direct", 3: "expired" }}
            />
          ) : (
            "--"
          )}
        </>
      ),
      hide: "md",
    },

    {
      name: "Actions",
      sortable: false,
      maxWidth: 100,
      minWidth: 100,
      center: true,
      cell: (invite) => showContextActionMenu(invite),
      hide: "md",
      right: true,
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    const setParams = {
      page: page,
      searchQueryString: searchQueryString,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setFilter({ ...filter, search: searchQuery });
    paginationGoing = true;
    setactivePage(1);
    setCurrentPageNumber(1);
    toggleResetPagination();
    const setParams = {
      page: 1,
      searchQueryString: searchQuery,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
        setFilter({ ...filter, search: wordString });
      } else {
        setSearchQueryString("");
        setFilter({ ...filter, search: "" });
      }

      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  const handleResetFilter = () => {
    for (let key of ["status"]) {
      delete filter[key];
    }
    setFilter({ ...filter });
    setFilterStatus();
  };

  React.useEffect(() => {
    if (_.hasIn(filter, "status")) {
      setFilterApplied(true);
      return;
    }
    setFilterApplied(false);
  }, [filter]);

  const handleApplyFilter = () => {
    setCurrentPageNumber(1);
    showFilter(false);
    for (let key of ["status"]) {
      delete filter[key];
    }
    setFilter({
      ...filter,
      ...(String(filterStatus?.value ?? "").length
        ? { status: filterStatus?.value }
        : {}),
    });
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>Self Registration Invites</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <div className="mobile-search">
                    <SearchBar
                      onSearchInputChange={onSearchInputChange}
                      popstateText={searchQueryString}
                    />
                  </div>
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <FilterSection />
                  <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={(e) => {
                        handleResetFilter(e);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-secondary m-0"
                      onClick={(e) => {
                        handleApplyFilter(e);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`tableFilterButton rightAlign  ${
                  filterApplied ? "btn-secondary" : ""
                }`}
                title="Advanced Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>
              {checkModuleAddPermission(
                userRoleDetails,
                "self-registration-invites",
                "invite"
              ) && (
                <button
                  type="button"
                  title="Add Invite"
                  className="tableFilterButton ml-2 rightAlign btn-secondary"
                  onClick={() => addNewInvite()}
                >
                  <>+</>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                invites.isLoading || invites.isFetching || !intialDataLoaded
                  ? "card-body dataTableOuter table-loader"
                  : "card-body dataTableOuter"
              }
            >
              <DataTable
                subHeader={true}
                progressPending={
                  invites.isLoading || invites.isFetching || !intialDataLoaded
                }
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={invites.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={
                  invites.meta && invites.meta.pagination.count
                }
                paginationDefaultPage={activePage}
                paginationResetDefaultPage={resetPaginationDataTable}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={<b>Confirm Delete</b>}
        content={
          <>
            <p>Are you sure you want to delete the selected "invite" ?</p>
          </>
        }
        onSubmitCallback={deleteInvite}
        onCloseCallback={doCloseModal}
        submitButtonText="Delete"
        customClass="delete"
        hideClose
      />

      <ModalRightComponent
        show={showRightModal}
        header="Add Invite"
        submitButtonText="Submit"
        classes="normal-right-modal"
        content={
          showRightModal && (
            <PublicRegisterInviteFormComponent
              title='Submitting below form will add "a new invite".'
              description={inviteDescription}
              recipients={inviteRecipients}
              expiry={inviteExpiry}
              multipleUse={inviteMultipleUse}
              errorState={errorState}
              updateInputChange={onInputChange}
              userRoleDetails={userRoleDetails}
            />
          )
        }
        onSubmitCallback={() => submitNewInvite()}
        onCloseCallback={() => manageModalSettings(false)}
        hideClose
      />
      <ModalComponent
        show={showInviteModal}
        header={<b>Add Email(s)</b>}
        content={
          <div
            className={
              String(newRecipientsErrorState?.recipients ?? "").length &&
              inviteButtonPressed
                ? "form-group hasValidationError"
                : "form-group"
            }
          >
            <textarea
              id="banNote"
              type="text"
              name="note"
              className="form-control"
              placeholder="Enter email(s) to invite seperated by ','"
              value={trimSingleSpace(inviteRecipients)}
              disabled={isInviteRecipientsLoading}
              onChange={(e) => {
                !isInviteRecipientsLoading &&
                  setInviteRecipients(e.target.value);
              }}
            />
            <span className="validation-error-message">
              {String(newRecipientsErrorState?.recipients ?? "").length
                ? newRecipientsErrorState?.recipients
                : ""}
            </span>
          </div>
        }
        onSubmitCallback={handleInviteRecipients}
        onCloseCallback={handleCloseInviteModal}
        isLoaderActive={isInviteRecipientsLoading}
        submitButtonText="Invite"
        hideClose
      />
    </>
  );
};

export default SelfRegistrationInvites;
