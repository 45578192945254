// import axios from "axios";
import React, { useState } from "react";
import { useQuery, useClient,  } from "jsonapi-react";
import "./timeline.scss";
import _ from "lodash";
import { apiRoutes } from "../../../constants";
import { useToasts } from "react-toast-notifications";
import { getLocalDateTime } from "../../../helpers/helpers";
import profileimagePlaceholder from "../../../assets/images/profileimagePlaceholder.jpg";


const ActionHistory = ({
  title,
  applicant,
  pass,
  show,
  customClass,
  historyType = 'profileHistory',
  onCloseCallback = () => {},
}) => {
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const getHistoryURL = (type, profileId, passId) => {
    switch(type){
      case "profileHistory": return ([
        apiRoutes.profileHistory.replace("{profileId}", profileId),
        { include: "modifiedBy", page: { size: 100 } },
      ]);

      case "passHistory": return ([
        apiRoutes.accessPassHistory.replace("{profileId}", profileId).replace("{passId}", passId),
        { include: "modified_by", page: { size: 100 } },
      ]);

      default: return null;
    }
  }

  const { data: historyData, isLoading } = useQuery( [...getHistoryURL(historyType, applicant?.id, pass?.id)],
    { client }
  );


  const getProfileEventText=(currentStatus, lastStatus)=>{
    if (!lastStatus) {
      return "Created the profile";
    } else if (lastStatus && currentStatus === lastStatus) {
      return "Modified the profile";
    } else {
      if (currentStatus === "active") {
        return "Activated the profile";
      } else if (currentStatus === "archived") {
        return "Archived the profile";
      } else if (currentStatus === "banned") {
        return "Banned the profile";
      }
    }
  }

  const getPassEventText = (currentStatus, lastStatus) => {
    if (!lastStatus.length) {
      return "Created the pass";
    } else if (lastStatus.length && currentStatus === lastStatus) {
      return "Modified the pass";
    } else {
      if (currentStatus === "active") {
        return "Activated the pass";
      } else if (currentStatus === "approved") {
        return "Approved the pass";
      } else if (currentStatus === "rejected") {
        return "Rejected the pass";
      } else if (currentStatus === "cancelled") {
        return "Cancelled the pass";
      } else if (currentStatus === "expired") {
        return "Pass Expired";
      }
    }
  }

  const getEventText = (index) => {
    const currentStatus = String(historyData[index].status ?? "").toLowerCase();
    const lastStatus = String(historyData[index+1]?.status ?? "").toLowerCase();

    switch (historyType) {
      case 'profileHistory': return getProfileEventText(currentStatus, lastStatus);
      case 'passHistory': return getPassEventText(currentStatus, lastStatus);
      default: return 'Event';
    }
  }


  return (
    <>
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        id="ModalComponent"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className={
            customClass
              ? customClass + " modal-dialog modal-dialog-centered"
              : "modal-dialog modal-dialog-centered"
          }
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="close"
                onClick={() => onCloseCallback()}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body  timeline-body">
              {isLoading ? (
                <ul className="list">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <li key={index} className="item">
                      <div className="timeline-item-cont">
                        <div className="item-title mb-2">
                          <div className="skeleton w-50"></div>
                        </div>
                        <div className="timestamp mb-2">
                          <div className="skeleton w-50"></div>
                        </div>
                        <div className="timestamp">
                          <div className="skeleton w-80"></div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className="list">
                  {historyData?.map((item, index) => (
                    <li key={item.id} className="item">
                      <div className="timeline-item-cont">
                        <div className="item-title">
                          <div className="user">
                            <img
                              className={
                                item?.modifiedBy?.avatar
                                  ? "avatar"
                                  : "avatar default"
                              }
                              src={
                                item?.modifiedBy?.avatar
                                  ? item?.modifiedBy?.avatar
                                  : profileimagePlaceholder
                              }
                              alt="Profile Pic"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = profileimagePlaceholder;
                              }}
                            />
                          </div>
                          <span className="user-name">{item?.modifiedBy?.fullName}</span>
                          <span className="d-block d-md-inline-block ml-4 mt-1 pl-1 ml-md-0 mt-md-0">
                            {getEventText(index)} on{" "}
                            <b>{getLocalDateTime(item?.created)}</b>
                          </span>
                          </div>{" "}

                        {item?.note && (
                          <div className="timestamp note">{item?.note}</div>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                disabled={isLoading}
                onClick={() => onCloseCallback()}
              >
                {"Close"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionHistory;
