import React from "react";
import _ from "lodash";
import { formatTextWithFallback, getLocalDateTime } from "../../../helpers/helpers";

function GeneralReportExpansion({ data }) {
  return (
    <>
      <div className="table-expaned-data">
        <div className="item">
          Profile ID <div className="value">{formatTextWithFallback(data?.profileId)}</div>
        </div>
        <div className="item">
          Nationality
          <div className="value">
            {formatTextWithFallback(data?.nationalityFull)}
          </div>
        </div>
        <div className="item">
          Email <div className="value">{formatTextWithFallback(data?.email)}</div>
        </div>
        <div className="item">
          Phone Number{" "}
          <div className="value">
            {formatTextWithFallback(data?.mobileNumber)}
          </div>
        </div>
        <div className="item">
          Profile Created On <div className="value">{formatTextWithFallback(getLocalDateTime(data?.created))}</div>
        </div>
      </div>
    </>
  );
}

export default GeneralReportExpansion;
