import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useClient, useQuery } from "jsonapi-react";
import { API_BASE_URL, VW_ROUTES, apiRoutes } from "../../../constants";
import ProgressBar from "../ProgressBar/ProgressBar";
import { getIcon, getKeyName } from "../../../helpers/utils";
import ReactTooltip from "react-tooltip";
import { isWidgetRefreshable } from "../../../helpers/helpers";
import Axios from "axios";
import { AppContext } from "../../../context/AppContext";
import { useToasts } from "react-toast-notifications";
import { GLOBAL_CONFIG } from "../../../config";

const PeopleStat = ({ widgetData, slug, title }) => {
  const history = useHistory();
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();
  const [stats, setStats] = React.useState();
  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const [chartData, setChartData] = React.useState({});

  const handleRedirection = (key, status) => {
    if (status) {
      history.push(
        `${
          key === "employee"
            ? VW_ROUTES.EMPLOYEE_PROFILE
            : VW_ROUTES.VISITOR_PROFILES
        }?status=${status}`
      );
    } else {
      history.push(
        `${
          key === "employee"
            ? VW_ROUTES.EMPLOYEE_PROFILE
            : VW_ROUTES.VISITOR_PROFILES
        }`
      );
    }
  };

  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const statusData = useQuery(
    [apiRoutes.dashboardWidgetsData + "/" + slug],
    {
      client,
    }
  );

  const updateData = () => {
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    setIsUpdateLoading(true);
    Axios.get(apiRoutes.peopleStatsUpdate, config)
      .then((response) => {
        addToast(response?.data?.data?.message ?? "Widget updated Successfully!", { appearance: "success" });
      })
      .catch((error) => {
        addToast(error.detail ?? "Failed to update widget!", { appearance: "error" });
      }).finally(() => setIsUpdateLoading(false));
  }

  React.useEffect(() => {
    if(widgetData?.widget?.config?.refreshRate &&  widgetData?.widget?.config?.refreshRate > 0){
      const timeoutId = setInterval(() => {
        statusData.refetch();
      }, widgetData?.widget?.config?.refreshRate * 1000);
      return () => {
        clearInterval(timeoutId);
      };
    }
  }, []);

  React.useEffect(() => {
    if (statusData) {
      setStats(statusData.data);
    }
  }, [statusData]);

  React.useEffect(() => {
    if (stats) {
      setChartData({
        quota: stats?.widgetData?.quota,
        total: stats?.widgetData?.stats?.total,
      });
    }
  }, [stats]);

  const getSumOfObjectValues = (obj) => {
    let sum = 0;
    for (let key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        typeof obj[key] === "number" &&
        (key === "active" || key === "banned")
      ) {
        sum += obj[key];
      }
    }
    return sum;
  };

  const getTotal = (category) => {
    if (stats) {
      return getSumOfObjectValues(stats.widgetData.stats.categories[category]);
    }
  };

  const getItemValue = (category, item) => {
    return stats?.widgetData?.stats?.categories[category][item];
  };

  const getProgressPercentage = (value, total) => {
    return (value / total) * 100;
  };

  const getQuataProgressPercentage = () => {
    if (stats) {
      return (stats?.widgetData?.stats?.count / stats?.widgetData?.quota) * 100;
    }
  };

  return (
    <>
      <div className="card right-notification">
        <div className="card-header with-count">
          <span>{title}</span>
          { isWidgetRefreshable(userRoleDetails, widgetData) && 
            <span 
              onClick={() => {!isupdateLoading && updateData()}}
              className={`refresh-button ${isupdateLoading ? "spin" : ""}`} 
              data-tip="Update Data">
              <i className="iconsminds-refresh" />
            </span>
          }
        </div>
        <div className="expiry-card-body">
          <div className="profile-usage">
            <ProgressBar percentage={getQuataProgressPercentage()} />
            <div className="pt-1 justify-space-between">
              <span>Profile usage :</span>{" "}
              {stats ?
              <span data-tip="Sum of Active and Banned profiles" style={{cursor: "pointer"}}>
                <b>{stats?.widgetData?.stats?.count}</b> /{" "}
                <b>{stats?.widgetData?.quota}</b>
              </span>:<div className="skeleton w-80"></div>}
            </div>
          </div>
          <ReactTooltip />

          <div className="row">
            {stats?.widgetData?.stats?.categories ? (
              Object.keys(stats?.widgetData?.stats?.categories).map(
                (key, index) => {
                  return (
                    <div key={index} className="col-lg-6 col-md-12 col-xl-6">
                      <div className="card notification-card relative standard">
                        <div className="card-body topNotificationCard">
                          <div className="notification-header-wrapper w-100">
                            <div className="notification-header stat-header d-flex align-items-center">
                              <i
                                className={`${getIcon(
                                  key
                                )} mb-0 mr-2 align-text-bottom d-inline-block`}
                              ></i>
                              <div className="content">
                                <div className="justify-content-sb">
                                  {getKeyName(key)}{" "}
                                  <b
                                    className="colored"
                                    onClick={() => {
                                      handleRedirection(key, "");
                                    }}
                                    data-tip="Sum of Active and Banned profiles"
                                  >
                                    {getTotal(key)}
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div className="text-small sections-wrapper-db">
                              <div className="sectionRow">
                                <div
                                  className="section-progress col-lg-12 col-md-12"
                                  onClick={() =>
                                    handleRedirection(key, "active")
                                  }
                                >
                                  <ProgressBar
                                    size={"small"}
                                    percentage={getProgressPercentage(
                                      getItemValue(key, "active"),
                                      getTotal(key)
                                    )}
                                  />
                                  <div className="section-text mt-2">
                                    Active: <b>{getItemValue(key, "active")}</b>
                                  </div>
                                </div>
                              </div>
                              {GLOBAL_CONFIG.profile.archive && (
                                <div className="sectionRow">
                                  <div
                                    className="section-progress col-lg-12 col-md-12"
                                    onClick={() =>
                                      handleRedirection(key, "archived")
                                    }
                                  >
                                    <ProgressBar
                                      size={"small"}
                                      percentage={getProgressPercentage(
                                        getItemValue(key, "archived"),
                                        getTotal(key)
                                      )}
                                    />
                                    <div className="section-text mt-2">
                                      Archived :{" "}
                                      <b>{getItemValue(key, "archived")}</b>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="sectionRow">
                                <div
                                  className="section-progress col-lg-12 col-md-12"
                                  onClick={() =>
                                    handleRedirection(key, "banned")
                                  }
                                >
                                  <ProgressBar
                                    size={"small"}
                                    percentage={getProgressPercentage(
                                      getItemValue(key, "banned"),
                                      getTotal(key)
                                    )}
                                  />
                                  <div className="section-text mt-2">
                                    Banned: <b>{getItemValue(key, "banned")}</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ReactTooltip />
                    </div>
                  );
                }
              )
            ) : (
              <>
                {Array.from({ length: 2 }).map((_, index) => (
                  <div key={index} className="col-lg-6 col-md-12 col-xl-6">
                    <div className="card notification-card relative standard">
                      <div className="card-body topNotificationCard">
                        <div className="notification-header-wrapper w-100">
                          <div className="notification-header stat-header d-flex align-items-center">
                            <i></i>
                            <div className="content">
                              <div className="skeleton w-80"></div>
                            </div>
                          </div>

                          <div className="text-small sections-wrapper-db">
                            <div className="sectionRow">
                              <div className="section-progress col-lg-12 col-md-12">
                                <div className="skeleton w-100"></div>
                                <div className="section-text">
                                  <div className="skeleton w-50"></div>
                                </div>
                              </div>
                            </div>
                            <div className="sectionRow">
                              <div className="section-progress col-lg-12 col-md-12">
                                <div className="skeleton w-80"></div>
                                <div className="section-text">
                                  <div className="skeleton w-50"></div>
                                </div>
                              </div>
                            </div>
                            <div className="sectionRow">
                              <div className="section-progress col-lg-12 col-md-12">
                                <div className="skeleton w-80"></div>
                                <div className="section-text">
                                  <div className="skeleton w-100"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PeopleStat;
