import React from "react";
import "./modal.scss";
const ModalComponent = ({
  show,
  header,
  content,
  showSubmit,
  onSubmitCallback,
  onCloseCallback,
  cancelButtonText,
  submitButtonText,
  isLoaderActive,
  hideFooter,
  hideCancel = false,
  hideClose = false,
  print = false,
  download = false,
  handlePrint = () => {},
  handlePDF = () => {},
  customClass,
}) => (
  <div
    className={`modal fade  ${show ? "show d-block" : ""}`}
    id="ModalComponent"
    tabIndex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      className={
        customClass
          ? customClass + " modal-dialog modal-dialog-centered px-2"
          : "modal-dialog modal-dialog-centered px-2"
      }
      role="document"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{header}</h5>
          {!hideClose && (
            <button
              type="button"
              className="close"
              disabled={isLoaderActive}
              onClick={() => onCloseCallback()}
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div className="modal-body">{content}</div>
        {!hideFooter && (
          <div className="modal-footer">
            {print && (
              <button
                type="button"
                disabled={isLoaderActive}
                // onClick={() => onSubmitCallback()}
                onClick={() => handlePrint()}
                className="btn btn-secondary "
              >
                Print
              </button>
            )}
            {download && (
              <button
                type="button"
                disabled={isLoaderActive}
                // onClick={() => onSubmitCallback()}
                onClick={() => {
                  handlePDF();
                }}
                className="btn btn-secondary  "
              >
                Download
              </button>
            )}
            {!hideCancel && (
              <button
                type="button"
                className="btn btn-primary"
                disabled={isLoaderActive}
                onClick={() => onCloseCallback()}
              >
                {cancelButtonText ? cancelButtonText : "Cancel"}
              </button>
            )}
            {showSubmit !== false && (
              <button
                type="button"
                disabled={isLoaderActive}
                onClick={() => onSubmitCallback()}
                className="btn btn-secondary position-relative"
              >
                {submitButtonText ? submitButtonText : "Submit"}
                {isLoaderActive && (
                  <span className="button-loader-container">
                    <div
                      className="spinner-border spinner-border-sm ml-1 text-white"
                      role="status"
                    ></div>
                  </span>
                )}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default ModalComponent;
