/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react";
import { useClient,useMutation, useQuery } from "jsonapi-react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { VW_ROUTES, apiRoutes } from "../../constants";
import _ from "lodash";
import queryString from "query-string";
import Select from "react-select";
import SearchBar from "../../components/common/Search/Search";
// import axios from 'axios';
// import { API_BASE_URL } from "../../constants";
import ExportButton from "../../components/common/Export/Export";
import { AppContext } from "../../context/AppContext";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { useToasts } from "react-toast-notifications";
import { columnSetter } from "../../helpers/columnSetter";
import { columnData } from "./dummyColumns";
import moment from "moment-timezone";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import {
  addBodyClass,
  removeBodyClass,

} from "../../helpers/utils";
import PendingTableExpandableComponent from "../../components/common/TableExpandableComponent/PendingApplicantsExpansion";
import RejectApplication from "../../components/common/RejectApplication/RejectApplication";
import { checkModuleGeneralPermission, checkModuleViewPermission, formatTextWithFallback, getCategoryType } from "../../helpers/helpers";
import useStorage from "../../helpers/useStorage";
const PendingApprovals = ({ location }) => {
  const { setToStorage, getFromStorage } = useStorage();
  let applicantType = queryString.parse(location.search).type;
  const { pagePermissions, getCategoryFromSlug, appOptions, userRoleDetails } = React.useContext(AppContext);
  const { initialPageParams, updateInitialPageParams, setPageUrlParams } =
    React.useContext(AppContext);
    const [passData, setPassData] = React.useState({});
    const [rejectData, setRejectData] = React.useState({ note: "" });
    const [rejectValidation, setRejectValidation] = React.useState({ msg: "" });
  const [exportData, setexportData] = React.useState({});
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  const [pageParams, setPageParams] = React.useState({});
  const [gatePassApprovalModal, setGatePassApprovalModal] =
    React.useState(false);
  const [gatePassRejectionModal, setGatePassRejectionModal] =
    React.useState(false);

  const [isApproveLoading, setIsApproveLoading] = React.useState(false);
  const [isRejectLoading, setIsRejectLoading] = React.useState(false);

  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("pending_applicant_approvals_rows_per_pge") || 10
  );

  const getCellCategoryType = (cellProfile) => getCategoryType(getCategoryFromSlug(String(cellProfile?.personCategory ?? "").toLowerCase(), appOptions)?.slug,);

  const token = localStorage.getItem("access_token");
  const history = useHistory();
  const { addToast } = useToasts();
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const [searchQueryString, setSearchQueryString] = React.useState("");
  const [filterApplied, setFilterApplied] = React.useState(false);
  const [personTypeFilter, setPersonTypeFilter] = React.useState({ label: "Select", value: "" });
  const [personTypeFilterValue, setPersonTypeFilterValue] = React.useState("");
  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(1);
  const [approveGatePass] = useMutation(
    [
      ["pass",passData?.id, "authorize"],

    ],
    { method: "PUT", client }
  );
  const [rejectGatePass] = useMutation(
    ["pass",passData?.id, "authorize"],
    { method: "PUT", client }
  );
  const userType = {
    staff: "Staffs",
    delivery: "Delivery Regular",
    delivery_adhoc: "Delivery Adhoc",
    delivery_regular: "Delivery Regular",
    visitor: "Visitor",
  };
  const confirmRejectGatePass = async () => {
    if ((!rejectData?.note) || (String(rejectData.note ?? "").trim() === "")) {
      setRejectValidation({ msg: "Reject note required." });
    } else {
      setIsRejectLoading(true);
      const { error } = rejectGatePass(rejectData);
      setIsRejectLoading(false);

      setRejectValidation({ msg : "" });
      setRejectData({ note : "" })

      manageModalSettings(false, 7);
      if (error === undefined) {
        addToast(DISPLAY_TEXTS.REJECT_GATE_PASS, { appearance: "success" });
        staffs.refetch();
      } else {
        addToast(error.detail, { appearance: "error" });
      }
    }
  };
  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);
  const onRejectInputChange = (value) => {
    setRejectValidation({ msg : "" });
    setRejectData({
      id: passData.id,
      note: value,
      approve: false
    });
  };
  const staffs = useQuery(
    [
      "pass",

      {
        filter: {
          status: "pending",
          category: applicantType,
          ...(searchQueryString && searchQueryString !== ""
            ? { search: searchQueryString }
            : {}
          ),
          ...(String(personTypeFilterValue ?? "").length
            ? { type: personTypeFilterValue }
            : {}
          ),
        },
        page: paginationSettings,
      },
    ],
    { client }
  );

  const viewStaff = (id) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${id}&type=${pageParams.type}`,
      state: {
        from: history.location.pathname
      }
    });
  };
  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }
    let params = queryString.parse(location.search);
    setPageParams(params);
    setexportData({
      page: "entry-pass",
      params: pageParams,
      filters: {
        search: "",
        category: pageParams.type,
      },
    });
  }, []);

  // React.useEffect(() => {
  //   if (initialPageParams) {
  //     setCurrentPageNumber(initialPageParams.initialPageNumber);
  //     setactivePage(initialPageParams.initialPageNumber);
  //     setSearchQueryString(initialPageParams.initialSearchKey);
  //   }
  // }, [initialPageParams]);

  React.useEffect(() => {
    setexportData({
      page: "entry-pass",
      params: pageParams,
      filters: {
        search: searchQueryString,
        category: pageParams.type,
        ...(String(personTypeFilterValue ?? "").length
            ? { type: personTypeFilterValue }
            : {}
          ), 
      },
    });
  }, [searchQueryString, pageParams, personTypeFilterValue]);

  const personTypes = useQuery( pageParams?.type &&
    [
      apiRoutes.accessPassType,
      {
        filter: {
          category: pageParams.type,
        },
      },
    ],
    { client }
  );

  const personTypeOptions = (personTypes) => {
    let personTypeOptionsArray = [{ label: "Select", value: "" }];
    _.forEach(_.orderBy(personTypes?.data, "name", "asc"), (item) => {
      personTypeOptionsArray.push({
        label: item.name,
        value: item.id,
        slug: item.slug
      });
    });
    return personTypeOptionsArray;
  };

  const handlePersonTypeFilter = (opt) => {
    setPersonTypeFilter(opt);
  }

  const handleApplyFilter = () => {
    setCurrentPageNumber(1);
    showFilter(false);

    setPersonTypeFilterValue(personTypeFilter?.slug ?? "");
  }

  const handleResetFilter = () => {
    setCurrentPageNumber(1);
    showFilter(false);

    setPersonTypeFilter({ label: "Select", value: ""});
    setPersonTypeFilterValue("");
  }

  useEffect(() => {
    setFilterApplied(String(personTypeFilterValue ?? "").length > 0);
  }, [personTypeFilterValue])

  const FilterSection = () => {
    return (
      <div className="advance-filter">
        <div>
          <span>Type</span>
          <div
            className="mb-2"
            // onSubmit={(e) => onSubmitSearch(e)}
          >
            <Select
              className="cutomSelect"
              classNamePrefix="react-select"
              value={personTypeFilter}
              options={personTypeOptions(personTypes)}
              onChange={(opt) => handlePersonTypeFilter(opt)}
              name="type"
              placeholder="Select"
            />
          </div>
        </div>
      </div>
    );
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewStaff(mode?.staff?.personSystemId);
    } else if (mode.mode === "approvePass") {
      onActionChange("approve", mode?.staff)
    } else if (mode.mode == "rejectPass") {
      onActionChange("reject", mode?.staff)
    }
  };
  const columns = [
    {
      ...columnSetter("fullName", columnData),
      show: "lg",
      selector: (person) => person?.personFullName,

      cell: (applicant) => (
        <ProfileCard
          data={applicant}
          actions={userRoleDetails}
          fields={[
            {
              value: applicant?.personCompany,
              name: columnSetter("company", columnData).name,
            },
            {
              value: applicant?.contract,
              name: columnSetter("contract", columnData).name,
            },
            {
              value: String(applicant.personIdentification ?? "").toUpperCase(),
              name: columnSetter("idNumber", columnData).name,
            },
            {
              value: applicant.passType,
              name: columnSetter("passType", columnData).name,
            },
            {
              value: applicant?.fromDateTime
                ? moment(applicant?.fromDateTime).format("DD MMM YYYY")
                : "--",
              name: columnSetter("validFrom", columnData).name,
            },
            {
              value: applicant?.toDateTime
                ? moment(applicant?.toDateTime).format("DD MMM YYYY")
                : "--",
              name: columnSetter("validTo", columnData).name,
            },
          ]}
          mode="applicantPendingPass"
          cellMode={getCellCategoryType(applicant)}
          actionsToParent={(mode) => getEvent({ mode, staff: applicant })}
        />
      ),
    },

    {
      ...columnSetter("company", columnData),
      hide: "md",
      selector: (person) => person?.personCompany,
      cell: (applicant) => (
        <>
          <span className="text-uppercase">
            {formatTextWithFallback(
              _.replace(applicant.personCompany ?? "", "_", " ")
            )}
          </span>
        </>
      ),
    },
    {
      name: "Contract",

      sortable: false,
      left: true,

      minWidth: "200px",
      selector: (person) => person?.contract,
      cell: (applicant) => (
        <>
          <span className="text-uppercase">
            {" "}
            {formatTextWithFallback(
              _.replace(applicant.contract ?? "", "_", " ")
            )}{" "}
          </span>
        </>
      ),
      hide: "md",
    },
    {
      ...columnSetter("idNumber", columnData),
      hide: "md",

      selector: (person) => person?.personIdentification,
      cell: (applicant) => (
        <span className="text-uppercase text-left">
          {applicant.personIdentification}
        </span>
      ),
    },
    {
      ...columnSetter("passType", columnData),
      hide: "md",

      selector: (person) => person?.passType,
      cell: (applicant) => (
        <span className="text-capitalise text-left">
          {formatTextWithFallback(applicant?.passType)}
        </span>
      ),
    },

    {
      ...columnSetter("validFrom", columnData),
      hide: "md",

      selector: (person) => person?.fromDateTime,
      cell: (applicant) => (
        <>
          {applicant.fromDateTime
            ? formatTextWithFallback(
                moment(applicant.fromDateTime)
                  .utcOffset("+0800")
                  .format("DD-MM-YYYY")
              )
            : "--"}
        </>
      ),
    },
    {
      ...columnSetter("validTo", columnData),
      hide: "md",

      selector: (person) => person?.toDateTime,
      cell: (applicant) => (
        <>
          {applicant.toDateTime
            ? formatTextWithFallback(
                moment(applicant.toDateTime)
                  .utcOffset("+0800")
                  .format("DD-MM-YYYY")
              )
            : "--"}
        </>
      ),
    },
    {
      name: "Actions",
      sortable: false,
      cell: (staff) => (
        <>
          {checkModuleGeneralPermission(
            userRoleDetails,
            "access-pass",
            "approve_pass",
            staff
          ) && (
            <span
              className="simple-icon-check user-role-action-icons view text-success"
              onClick={() => onActionChange("approve", staff)}
            ></span>
          )}
          {checkModuleGeneralPermission(
            userRoleDetails,
            "access-pass",
            "reject_pass",
            staff
          ) && (
            <span
              className="simple-icon-close user-role-action-icons view text-danger ml-2"
              onClick={() => onActionChange("reject", staff)}
            ></span>
          )}
          {checkModuleViewPermission(
            userRoleDetails,
            getCellCategoryType(staff),
            "profile",
            staff
          ) && (
            <span
              className="simple-icon-eye user-role-action-icons view ml-3"
              onClick={() => viewStaff(staff.personSystemId)}
            ></span>
          )}
        </>
      ),
      right: true,
      width: 50,
      hide: "md",
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    const setParams = {
      page: page,
      searchQueryString: searchQueryString,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({"pending_applicant_approvals_rows_per_pge":rows},false);
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setactivePage(1);
    const setParams = {
      page: 1,
      searchQueryString: searchQuery,
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }
      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  const manageModalSettings = (status, identifier) => {
  if (identifier === 6) {
      setGatePassApprovalModal(status);
    } else if (identifier === 7) {
      setGatePassRejectionModal(status);
    }
    if (status) {
      addBodyClass("modal-open");
    } else {
      removeBodyClass("modal-open");
    }
  };
  const onActionChange = (action, pass) => {
    setPassData(pass);
    if (action === "approve") {
      manageModalSettings(true, 6);
    } else {
      manageModalSettings(true, 7);
    }
  };
  const confirmApproveGatePass = async () => {
    const approveData = {
      approve: true,
      id: passData.id,
    };
    setIsApproveLoading(true);
    const { error } = await approveGatePass(approveData);
    setIsApproveLoading(false);
    staffs.refetch();
    manageModalSettings(false, 6);
    if (error === undefined) {
      addToast(DISPLAY_TEXTS.APPROVE_GATE_PASS, { appearance: "success" });
    } else {
      addToast(error.detail, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header withExport">
            <h1>Pending Access Pass {pageParams?.type ? `- ${getCategoryFromSlug(pageParams?.type, appOptions)?.display_name ?? ""}`  : ""}</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <div className="mobile-search">
                    <SearchBar
                      onSearchInputChange={onSearchInputChange}
                      popstateText={searchQueryString}
                    />
                  </div>
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <FilterSection />
                  <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={() => {
                        handleResetFilter();
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-secondary m-0"
                      onClick={() => {
                        handleApplyFilter();
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`tableFilterButton rightAlign  ${
                  filterApplied ? "btn-secondary" : ""
                }`}
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>

              <ExportButton
                exportDetails={exportData}
                disableExport={staffs?.data?.length > 0 ? false : true}
              />
            </div>
            {/* <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="right-holder">
                  <SearchBar
                    onSearchInputChange={onSearchInputChange}
                    popstateText={searchQueryString}
                  />
                  <ExportButton exportDetails={exportData} />
                </div>
              </div>
            </div>
            <div
              className="tableFilterButton right-fix"
              title="Advance Search"
              onClick={() => showFilter(true)}
            >
              <i className="iconsminds-filter-2"></i>
            </div> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                staffs.isLoading || staffs.isFetching
                  ? "card-body dataTableOuter expiryTable table-loader"
                  : "card-body dataTableOuter expiryTable"
              }
            >
              <DataTable
                subHeader={false}
                // subHeaderComponent={<><SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/> {pageParams.list !== "expired"?<ExportButton exportDetails={exportData}/>:""}</>}
                progressPending={staffs.isLoading || staffs.isFetching}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                searchGoingOn={searchGoingOn}
                data={staffs?.data || []}
                currentPageNumber={currentPageNumber}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={
                  staffs.meta && staffs.meta.pagination.count
                }
                paginationDefaultPage={activePage}
                expandableRows
                expandableRowsComponent={PendingTableExpandableComponent }
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
      <ModalComponent
        show={gatePassRejectionModal}
        header={<b>Confirm Access Pass Rejection!</b>}
        content={
          <RejectApplication
            updateInputChange={onRejectInputChange}
            validation={rejectValidation}
            value={rejectData.note ?? ""}
          />
        }
        showSubmit={true}
        onSubmitCallback={() => confirmRejectGatePass()}
        onCloseCallback={() => {
          setRejectData({ note : "" });
          setRejectValidation({ msg: "" });
          manageModalSettings(false, 7);
        }}
      />

      <ModalComponent
        show={gatePassApprovalModal}
        header={<b>Confirm</b>}
        content={"Are you sure you want to approve access Pass?"}
        isLoaderActive={isApproveLoading}
        showSubmit={true}
        onSubmitCallback={() => confirmApproveGatePass()}
        onCloseCallback={() => {
          manageModalSettings(false, 6);
        }}
      />
    </>
  );
};

export default PendingApprovals;
