/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from "jsonapi-react";
import DataTable from "react-data-table-component";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { VW_ROUTES, apiRoutes } from "../../constants";
import { parseIdentificationType } from "../../helpers/helpers";
import SearchBar from "../../components/common/Search/Search";
import ExportButton from "../../components/common/Export/Export";
import { AppContext } from "../../context/AppContext";
import SelectApplicants from "../../components/common/SelectApplicants/SelectApplicants";

const ContractorBasedApplicantLists = ({ location }) => {
  const [exportData, setexportData] = React.useState({});
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [applicantType, setApplicantType] = React.useState(undefined);
  const [searchQueryString, setSearchQueryString] = React.useState("");
  const [contractor, setContractor] = React.useState("");

  const {
    initialPageParams,
    updateInitialPageParams,
    currentUser,
    setPageUrlParams,
  } = React.useContext(AppContext);

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(1);
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const token = localStorage.getItem("access_token");
  const history = useHistory();
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }

    let params = queryString.parse(location.search);
    setContractor(params.id);

    setexportData({
      page: "live_count_by_contract",
      contractorId: params.id,
      filters: {
        search: searchQueryString,
        type: applicantType,
      },
    });
  }, []);

  const {
    data: applicantsData,
    meta,
    isFetching,
  } = useQuery(
    [
      apiRoutes.createProfileData,
      {
        filter: {
          contractor,
          live: "True",
          search: searchQueryString,
          type: applicantType,
        },
        page: paginationSettings,
        include: "company,contract",
      },
    ],
    { client }
  );

  const viewApplicant = (id, type) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${id}&type=${type}&from=dashboard`,
      state: {
        from: history.location?.pathname
      }
    });
  };

  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(initialPageParams.initialPageNumber);
      setactivePage(initialPageParams.initialPageNumber);
      setSearchQueryString(initialPageParams.initialSearchKey);
      setApplicantType(
        initialPageParams.applicantType
          ? initialPageParams.applicantType
          : undefined
      );
    }
  }, [initialPageParams]);

  React.useEffect(() => {
    setexportData({
      page: "live_count_by_contract",
      contractorId: contractor,
      filters: {
        search: searchQueryString,
        type: applicantType,
      },
    });
  }, [searchQueryString, applicantType, contractor]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  // const parseIdentificationType = user => {
  //   return user.nricAlpha !== "" ? `${user.nricNumber}${user.nricAlpha}(NRIC)` : user.finAlpha !== "" ? `${user.finNumber}${user.finAlpha}(FIN)` : `${user.passport}(Passport)`
  // };

  const columns = [
    {
      name: "Full Name",
      selector: "fullName",
      sortable: true,
      style: "text-transform:capitalize",
    },
    {
      name: "Type",
      cell: (applicant) => (
        <span className="text-capitalize">
          {applicant.applicantType.replace(/_/g, " ")}
        </span>
      ),
      sortable: false,
    },
    {
      name: "ID Type",
      sortable: false,
      hide: "md",
      maxWidth: "150px",
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {applicant.idType === "nric" || applicant.idType === "fin"
            ? applicant.idType.toUpperCase()
            : applicant.idType}
        </span>
      ),
    },
    {
      name: "ID Number",
      sortable: false,
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {parseIdentificationType(applicant)}
        </span>
      ),
    },
    // {
    //   name: 'Contract',
    //   selector: 'contract.name',
    //   sortable: true
    // },
    {
      name: "Company",
      selector: "company.name",
      sortable: true,
    },
    {
      name: "Designation",
      selector: "designation",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "Actions",
      sortable: false,
      cell: (applicant) => (
        <>
          <span
            className="simple-icon-eye user-role-action-icons view"
            onClick={() => viewApplicant(applicant.id, applicant.applicantType)}
          ></span>{" "}
        </>
      ),
      right: true,
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    const setParams = {
      page: page,
      searchQueryString: searchQueryString,
      initialPageParams: initialPageParams,
      applicantType: applicantType,
    };
    setPageUrlParams(setParams);
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setactivePage(1);
    const setParams = {
      page: 1,
      searchQueryString: searchQuery,
      initialPageParams: initialPageParams,
      applicantType: applicantType,
    };
    setPageUrlParams(setParams);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }
      const applicantType = window.location.hash.split("applicant=")[1];
      if (applicantType) {
        const applicant = applicantType.split("&")[0];
        setApplicantType(applicant);
      } else {
        setApplicantType(undefined);
      }
      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };

  const searchByApplicantType = (e) => {
    searchGoing = true;
    if (e.target.value !== "") {
      setApplicantType(e.target.value);
      const setParams = {
        page: 1,
        searchQueryString: searchQueryString,
        initialPageParams: initialPageParams,
        applicantType: e.target.value,
      };
      setPageUrlParams(setParams);
    } else {
      setApplicantType(undefined);
      const setParams = {
        page: 1,
        searchQueryString: searchQueryString,
        initialPageParams: initialPageParams,
      };
      setPageUrlParams(setParams);
    }
  };
  const FilterSection = () => {
    return (
      <div className="advance-filter mr-1">
        <SelectApplicants
          searchByApplicantType={(e) => searchByApplicantType(e)}
          applicantType={applicantType}
        />
      </div>
    );
  };

  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header withExport">
            <h1>Company Based Applicants</h1>
            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="right-holder">
                  <FilterSection />
                  <SearchBar
                    onSearchInputChange={onSearchInputChange}
                    popstateText={searchQueryString}
                  />
                  <ExportButton exportDetails={exportData} />
                </div>
              </div>
            </div>
            <div
              className="tableFilterButton right-fix"
              title="Advance Search"
              onClick={() => showFilter(true)}
            >
              {/* <span></span>
             <span></span>
             <span></span> */}
              <i className="iconsminds-filter-2"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                isFetching
                  ? "card-body dataTableOuter cmpnyTable table-loader"
                  : "card-body dataTableOuter cmpnyTable"
              }
            >
              <DataTable
                // subHeader={true}
                // subHeaderComponent={<><FilterSection/><SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/> <ExportButton exportDetails={exportData}/></>}
                columns={columns}
                data={applicantsData}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                progressPending={isFetching}
                progressComponent={<p>&nbsp;</p>}
                paginationTotalRows={meta && meta.pagination.count}
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractorBasedApplicantLists;
