import React, { useEffect, useState } from "react";
import { useQuery, useClient, useMutation, ApiClient } from "jsonapi-react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string";
import Select from "react-select";
import {
  checkModuleAddPermission,
  checkModuleEditPermission,
  convertToYearFirst,
  getDayFirst,
  getIDTypeId,
  removeEmptyValues,
  trimDoubleSpace,
  validatorModule,
} from "../../helpers/helpers";
import { AppContext } from "../../context/AppContext";
import moment from "moment";
import useContracts from "../../components/common/CommonDeliverables/useContracts";
import FinalStepperComponent from "../../components/common/FinalStepperComponent/FinalStepperComponent";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { VW_ROUTES, apiRoutes } from "../../constants";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { getContractorOptions, schema } from "../../helpers/utils";
import PermissionWrapper from "../../layout/PermissionWrapper";
import DuplicateProfiles from "../../components/common/DuplicateProfiles/DuplicateProfiles";
import useCompanyOptions from "../../components/common/CommonDeliverables/useCompanyOptions";
import IdInput from "../../components/common/IdInput/IdInput";
import axios from "axios";
import AdditionalIds from "../../components/common/AdditionalIds/AdditionalIds";
import NavigationGuard from "../../components/common/NavigationGuard/NavigationGuard";
import useContractOptions from "../../components/common/CommonDeliverables/useContractOptions";
import LazyLoadedSelect from "../../components/LazyLoadedSelect/LazyLoadedSelect";

const CreateProfile = ({ location }) => {
  const { addToast } = useToasts();
  const { appOptions, manageLoader, userRoleDetails, lastRouteStates } =
    React.useContext(AppContext);
  const history = useHistory();
  const { search } = history.location;
  let params = queryString.parse(search);
  const [personRegistryType, setpersonRegistryType] = React.useState(
    params.type
  );
  const [newProfileData, setNewProfileData] = useState(null);
  const [profileCategoryId, setprofileCategoryId] = React.useState(
    params.categoryId
  );
  const [pageName, setPageName] = React.useState("");
  const [personId, setPersonId] = React.useState(params.id ?? params.id);
  const [userCreated, setUserCreated] = React.useState(false);
  const [showAdditionalFiles, setShowAdditionalFiles] = React.useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(params.edit ? true : false);
  const [editedFields, setEditedFields] = React.useState([]);
  const [isPassPort, setIsPassPort] = React.useState(false);
  const [idTypeEdited, setIdtypeEdited] = React.useState("");
  const [formDisabled, setFormDisabled] = useState(false);
  const [showDuplicates, setShowDuplicates] = React.useState(false);
  const [toggleFormSubmit, setToggleFormSubmit] = React.useState(null);
  const [duplicateProfilesData, setduplicateProfilesData] = React.useState([]);
  const [cidbFile, setCidbFile] = useState(null);
  const {
    companies,
    handleCompanySearch,
    loadNextCompanyOptionsPage,
    isCompanyLoading,
  } = useCompanyOptions();
  const {
    contracts,
    handleContractSearch,
    loadNextContractOptionsPage,
    isContractLoading,
  } = useContractOptions();
  const isUserRoleAC = userRoleDetails?.slug === "account-coordinator";

  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleToggleFormSubmit = () => {
    setToggleFormSubmit((curr) => !curr);
  };

  React.useEffect(() => {
    const newParams = queryString.parse(search);
    if (userCreated) return;
    setPersonId(newParams.id ?? "");
    setpersonRegistryType(
      newParams.type === "employee"
        ? "employee-profile"
        : newParams.type === "visitor"
        ? "visitor-profile"
        : newParams.type
    );

    setprofileCategoryId(newParams.categoryId);
    setIsEdit(newParams.edit ? true : false);
  }, [userCreated]);

  const getPrevState = (backRoute) =>
    _.has(lastRouteStates, backRoute) ? lastRouteStates[backRoute] : null;

  const goToProfilesList = () => {
    if (params?.fromViewProfile)
      history.replace(
        getPrevState(VW_ROUTES.PERSON_PROFILE_VIEW) ?? {
          pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
          search: `?id=${profileData?.id}&type=${profileData?.category?.slug}`,
          state: {
            from:
              history.location.state?.from ??
              (params?.fromAllProfile
                ? VW_ROUTES.ALL_PROFILES
                : `/${personRegistryType ?? "all-profile"}`),
          },
        }
      );
    else if (params?.fromAllProfile)
      history.replace(
        getPrevState(VW_ROUTES.ALL_PROFILES) ?? VW_ROUTES.ALL_PROFILES
      );
    else if (!personRegistryType) history.replace("/");
    else
      history.replace(
        getPrevState(`/${personRegistryType}`) ?? personRegistryType
      );
  };

  const initialState = {
    category: profileCategoryId,
    id_type: "", //nric, fin, passport
    full_name: "",
    id_number: "",
    mobile_number: "",
    email: "",
    date_of_birth: "",
    nationality: "",
    gender: "male",
    company: { label: "Select", value: "" },
    contract: { label: "Select", value: "" },
    designation: "",
    trade: "",
    "ics[1]ic_number": "",
    "ics[1]expiry": "",
  };
  const initialErrorState = {
    full_name: "",
    id_type: "",
    mobile_number: "",
    date_of_birth: "",
    nationality: "",
    gender: "",
    trade: "",
    "ics[1]ic_number": "",
    "ics[1]expiry": "",
  };

  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [formState, setFormState] = React.useState(initialState);

  const validationFormat = [
    {
      field: "nationality",
      isRequired: true,
      fieldName: "Nationality",
    },
    {
      field: "date_of_birth",
      isBirthDay: true,
      isRequired: true,
      fieldName: "Date Of Birth",
    },
    {
      field: "email",
      isEmail: true,
      isRequired: true,
      fieldName: "Email Address",
    },
    {
      field: "mobile_number",
      isPhoneNumber: true,
      maxLength: 15,
      isRequired: false,
      fieldName: "Mobile Number",
    },

    {
      field: "full_name",
      minLength: 3,
      fieldName: "Full Name",
      isRequired: true,
    },

    {
      field: "id_type",
      fieldName: "ID Type",
      isRequired: true,
    },
    {
      field: "id_number",
      fieldName: "ID Number",
      isAlphaNumeric: true,
      isRequired: true,
      minLength: 4,
    },
    {
      field: "company",
      isRequired: true,
      fieldName: "Company",
    },
    {
      field: "contract",
      isRequired: true,
      fieldName: "Contract",
    },

    {
      field: "designation",
      isRequired: true,
      fieldName: "Designation",
    },
    {
      field: "trade",
      isRequired: personRegistryType === "employee-profile" ? true : false,
      fieldName: "Trade",
    },
    {
      field: "work_permit_expiry",
      isRequired: formState?.id_type === "fin" ? true : false,
      fieldName: "Work Permit Expiry",
    },
    ...(!isEdit
      ? [
          {
            field: "ics[1]ic_number",
            minLength: 16,
            isRequired:
              getIDTypeId("cidb", appOptions?.ic_types) &&
              personRegistryType === "employee-profile"
                ? true
                : false,
            fieldName: "CIDB Number",
          },
          {
            field: "ics[1]expiry",
            isExpiryDate: true,
            isRequired:
              getIDTypeId("cidb", appOptions?.ic_types) &&
              personRegistryType === "employee-profile"
                ? true
                : false,
            fieldName: "CIDB expiry",
          },
        ]
      : []),
  ];

  const onFocusChange = () => {
    if (_.filter(Object.values(errorState)).length !== 0) {
      let errors = validatorModule(
        {
          ...formState,
          company: formState.company.value ? formState.company : undefined,
        },
        validationFormat
      );
      setErrorState(errors);
    }
  };

  const firstInputPersonal = React.useRef();

  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
    "Content-Type": "multipart/form-data",
  };

  const { data: tradeSelectOptions } = useQuery(apiRoutes.tradeInformation, {
    client,
  });

  const onCidbInput = (value) => {
    onInputChange({ name: "ics[1]ic_number", value: value });
  };
  const onCidbExpiryInput = (value) => {
    onInputChange({ name: "ics[1]expiry", value: value });
  };
  const onInputChange = ({ name, value }) => {
    let formData = { ...formState };
    let newEditedFields = [name];
    if (name === "id_type" && value === "passport") {
      setIsPassPort(true);
    } else if (name === "id_type" && value !== "passport") {
      setIsPassPort(false);
    }
    if (name === "id_number") {
      newEditedFields = [...newEditedFields, "id_type"];
      if (value.length <= 16) {
        formData[name] = value;
      }

      formData["ics[0]ic_number"] = value;
    } else if (name === "full_name") {
      formData[name] = value ? trimDoubleSpace(value) : "";
    } else {
      formData[name] = value ? value : "";
    }
    if (name === "id_type") {
      newEditedFields = [...newEditedFields, "id_number"];
      setIdtypeEdited(value);
      formData["ics[0]ic_type"] = getIDTypeId(value, appOptions.ic_types);
      formData.id_number = "";
    }

    if (name === "work_permit_expiry") {
      formData["ics[0]expiry"] = value;
    }

    if (name === "ics[1]ic_number") {
      if (String(formData["ics[1]ic_number"] ?? "").length < 16) {
        formData["ics[1]expiry"] = "";
      } else {
        formData["ics[1]ic_type"] = getIDTypeId("cidb", appOptions.ic_types);
      }
    }

    setFormState(formData);
    const updatedEditedFields = newEditedFields.filter(
      (field) => !_.includes(editedFields, field)
    );
    setEditedFields([...editedFields, ...updatedEditedFields]);

    const fieldsToCheck = Object.keys(formData).filter(
      (key) =>
        key !== "category" &&
        key !== "gender" &&
        key !== "company" &&
        key !== "ics[1]ic_type"
    );
    const isAnyFieldFilled = fieldsToCheck.some(
      (field) => formData[field] !== ""
    );
    setIsFormDirty(isAnyFieldFilled);
  };

  useEffect(() => {
    if (formState.id_number) {
      checkDuplicate();
    }
  }, [idTypeEdited]);

  const getIdOptions = () => {
    let idTypeArray = [{ label: "Select", value: "" }];
    if (appOptions.ic_types?.length > 0) {
      appOptions.ic_types.forEach((item) => {
        if (item.slug !== "cidb") {
          idTypeArray.push({
            label: item.display_name,
            value: item.slug,
          });
        }
      });
    }
    return idTypeArray;
  };

  const getIdValues = (v) => {
    let idTypeObj = { label: "Select", value: "" };
    if (appOptions.ic_types?.length > 0) {
      appOptions.ic_types.forEach((item) => {
        if (item.slug === v)
          idTypeObj = {
            label: item.display_name,
            value: item.slug,
          };
      });
    }
    return idTypeObj;
  };

  const getNationalityOptions = () => {
    let idTypeArray = [{ label: "Select", value: "" }];
    if (appOptions.nationality?.length > 0) {
      appOptions.nationality.forEach((item) => {
        idTypeArray.push({
          label: item.display_name,
          value: item.value,
        });
      });
    }
    return idTypeArray;
  };

  const getNationalityValues = (v) => {
    let idTypeObj = { label: "Select", value: "" };
    if (appOptions.nationality?.length > 0) {
      appOptions.nationality.forEach((item) => {
        if (item.value === v)
          idTypeObj = {
            label: item.display_name,
            value: item.value,
          };
      });
    }
    return idTypeObj;
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    groupHeading: (provided) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "bold",
      borderBottom: "1px solid #666",
      margin: "0 10px 5px",
      padding: "0 0 4px 0",
    }),
  };

  const getTradeSubOption = (array) => {
    let subTradeArray = [];

    if (array?.length > 0) {
      _.forEach(_.orderBy(array, "name", "asc"), (trade) => {
        subTradeArray.push({
          label: trade.name,
          value: trade.id,
        });
      });
    }
    return subTradeArray;
  };
  const getTradeOptions = () => {
    let tradeArray = [{ label: "Select", value: "" }];

    if (tradeSelectOptions?.length > 0) {
      _.forEach(_.orderBy(tradeSelectOptions, "name", "asc"), (trade) => {
        tradeArray.push({
          label: trade.name,
          value: trade.id,
          options: getTradeSubOption(trade.trade),
        });
      });
    }
    return tradeArray;
  };

  const getTradeValues = (v) => {
    let companyObj = { label: "Select", value: "" };
    if (tradeSelectOptions?.length > 0) {
      tradeSelectOptions?.forEach((item) => {
        if (item.trade?.length > 0) {
          item.trade?.forEach((trade) => {
            if (trade.id === v) {
              companyObj = {
                label: trade.name,
                value: trade.id,
              };
            }
          });
        }
      });
    }
    return companyObj;
  };

  const getContractorValues = (v) => {
    let companyObj = { label: "Select", value: "" };
    if (contracts?.data?.length > 0) {
      contracts?.data.forEach((item) => {
        if (item.id === v)
          companyObj = {
            label: item.name,
            value: item.id,
          };
      });
    }
    return companyObj;
  };

  // const [createUpdate] = useMutation([apiRoutes.createProfileData],
  //     {
  //       method: "POST",
  //       client,
  //     }
  //   );

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  const createUpdate = (formData) => {
    let dataFormData = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        dataFormData.append(key, value);
      }
    }
    return axios
      .post(apiRoutes.createProfileDataForm, dataFormData, config)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return {
          error: true,
          errorDetails: error?.response?.data?.errors,
        };
      });
  };

  const updateData = (formData) => {
    let dataFormData = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        dataFormData.append(key, value);
      }
    }
    return axios
      .patch(
        apiRoutes.updateProfileDataForm.replace("{personId}", personId),
        dataFormData,
        config
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return {
          error: true,
          errorDetails: error.response.data.errors,
        };
      });
  };

  const updateProfile = async (e) => {
    e?.preventDefault();
    if (formDisabled) {
      checkDuplicate();
      return;
    }

    let errors = validatorModule(
      {
        ...formState,
        company: formState.company.value ? formState.company : undefined,
        contract: formState.contract.value ? formState.contract : undefined,
      },
      validationFormat
    );

    setErrorState(errors);
    if (!editedFields?.length) {
      addToast(DISPLAY_TEXTS.PROFILE_UNCHANGED, { appearance: "warning" });
      return;
    }
    if (Object.keys(errors).length === 0) {
      manageLoader(true);
      setIsUpdateLoading(true);
      const filteredFormData = editedFields.reduce((formData, newField) => {
        if (!Object.hasOwn(formState, newField)) return;
        const newData = new Object();
        if (
          newField === "work_permit_expiry" ||
          newField === "date_of_birth" ||
          newField === "ics[0]expiry" ||
          newField === "ics[1]expiry"
        ) {
          newData[newField] = moment(formState[newField]).format("DD-MM-YYYY");
        } else if (newField === "contract") {
          if (!(formState[newField] === profileData?.contract?.id))
            newData["contract_id"] = formState[newField]?.value;
        } else if (newField === "company") {
          if (!(formState[newField]?.value === profileData?.company?.id))
            newData["company_id"] = formState[newField]?.value;
        } else if (newField === "trade") {
          if (!(formState[newField] === profileData?.trade?.id))
            newData["trade"] = formState[newField];
        } else {
          newData[newField] = formState[newField];
        }

        return { ...formData, ...newData };
      }, {});

      const sendData = removeEmptyValues(filteredFormData, true);

      const response = await updateData({
        ...sendData,

        ...(cidbFile
          ? {
              "ics[1]document": cidbFile,
            }
          : {}),
      });
      if (response && response.error) {
        addToast(
          response.errorDetails &&
            response.errorDetails.length > 0 &&
            response.errorDetails[0]?.detail
            ? response.errorDetails[0]?.detail
            : "Something went wrong",
          { appearance: "error" }
        );
        manageLoader(false);
      } else {
        if (String(response.data.data.id ?? "").length > 0) {
          setIsFormDirty(false);
          setUserCreated(true);
          setNewProfileData(response.data.data);
          addToast(DISPLAY_TEXTS.PROFILE_UPDATED, { appearance: "success" });
        }
        manageLoader(false);
      }

      setIsUpdateLoading(false);
    }
  };

  const [profileData, setProfileData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [getProfileData] = useMutation(
    [
      apiRoutes.updateProfileData.replace("{personId}", personId),
      {
        include: "company,contract,category,trade",
      },
    ],
    { client, method: "GET" }
  );

  useEffect(async () => {
    if (
      isEdit &&
      !userCreated &&
      !isUpdateLoading &&
      String(personId ?? "").length
    ) {
      setIsLoading(true);
      const { data } = await getProfileData();
      setIsLoading(false);
      setProfileData(data);
    }
  }, [personId, isEdit]);

  // const { data: profileData, isLoading } = (isEdit && personId && !userCreated) ? profileDataQueryHook : {};

  React.useEffect(() => {
    if (!userRoleDetails?.menuItems || !personRegistryType) return;
    setPageName(
      `${isEdit ? "Edit" : "Add"} ${
        userRoleDetails?.menuItems["person-registry"]?.children[
          personRegistryType
        ]?.name?.split(" ")[0] ?? ""
      }` ?? ""
    );
  }, [userRoleDetails, personRegistryType, isEdit, setPageName]);

  React.useEffect(() => {
    if (isLoading && manageProfilePermission !== undefined) {
      manageLoader(true);
    } else {
      manageLoader(false);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (profileData) {
      setFormState({
        ...profileData,
        category: profileData?.category?.id,
        date_of_birth: getDayFirst(profileData?.dateOfBirth),
        work_permit_expiry: profileData?.work_permit_expiry
          ? getDayFirst(profileData?.work_permit_expiry)
          : "",
        company: {
          label: profileData?.company?.name ?? "Select",
          value: profileData?.company?.id ?? "",
        },
        contract: {
          label: profileData?.contract?.name ?? "Select",
          value: profileData?.contract?.id ?? "",
        },
        trade: profileData?.trade?.id,

        id_type: profileData?.idType, //nric, fin, passport
        full_name: profileData?.fullName,
        id_number: profileData?.idNumber,
        work_permit_expiry: profileData?.workPermitExpiry
          ? getDayFirst(profileData?.workPermitExpiry)
          : null,
        mobile_number: profileData?.mobileNumber,
        "ics[1]ic_number": profileData?.cidbData?.icNumber ?? "",
        "ics[1]expiry": profileData?.cidbData?.expiry ?? "",
      });
      String(profileData?.idType ?? "") === "passport"
        ? setIsPassPort(true)
        : setIsPassPort(false);
    }
  }, [profileData]);

  React.useEffect(() => {
    if (submitButtonPressed || isEdit) {
      onFocusChange();
    }
  }, [formState]);

  React.useEffect(() => {
    if (toggleFormSubmit === null) return;
    if (isEdit) {
      updateProfile();
    } else {
      onSubmitForm();
    }
  }, [toggleFormSubmit]);

  const [duplicateProfiles] = useMutation(
    [apiRoutes.duplicateCheck, { include: "category,person_photo" }],
    {
      method: "POST",
      client,
    }
  );

  const checkDuplicate = async (e) => {
    if (
      showDuplicates ||
      !formState?.id_number ||
      (e?.relatedTarget &&
        (e.relatedTarget.id === "backButton" ||
          e.relatedTarget.id === "cancelButton"))
    )
      return;
    setFormDisabled(true);
    manageLoader(true);
    const { data, error } = await duplicateProfiles({
      id_number: formState.id_number,
      id_type: formState.id_type,
      personId: personId ?? "",
    });

    if (data && data.length > 0) {
      manageLoader(false);
      setduplicateProfilesData(data);
      setShowDuplicates(true);
    } else {
      manageLoader(false);
      setFormDisabled(false);

      if (e?.relatedTarget && e.relatedTarget.id === "submitButton") {
        handleToggleFormSubmit();
      }
    }

    if (error) {
      manageLoader(false);
      setFormDisabled(false);
    }
  };

  const closeDuplicateProfiles = () => {
    setShowDuplicates(false);
    setduplicateProfilesData([]);
    setFormDisabled(false);
  };

  const createUpdateAction = async () => {
    manageLoader(true);
    const filteredData = removeEmptyValues(formState);
    const { company, contract, ...dataToSend } = filteredData;

    const response = await createUpdate({
      ...dataToSend,
      ...(String(company?.value ?? "").length
        ? { company_id: company?.value }
        : {}),
      ...(String(contract?.value ?? "").length
        ? { contract_id: contract?.value }
        : {}),
      ...(String(filteredData?.date_of_birth ?? "").length
        ? {
            date_of_birth: moment(filteredData.date_of_birth).format(
              "DD-MM-YYYY"
            ),
          }
        : {}),
      ...(filteredData["ics[1]expiry"]
        ? {
            "ics[1]expiry": moment(filteredData["ics[1]expiry"]).format(
              "DD-MM-YYYY"
            ),
          }
        : {}),

      ...(filteredData["ics[0]expiry"]
        ? {
            "ics[0]expiry": moment(filteredData["ics[0]expiry"]).format(
              "DD-MM-YYYY"
            ),
          }
        : {}),

      ...(filteredData["work_permit_expiry"]
        ? {
            work_permit_expiry: moment(
              filteredData["work_permit_expiry"]
            ).format("DD-MM-YYYY"),
          }
        : {}),

      ...(cidbFile
        ? {
            "ics[1]document": cidbFile,
          }
        : {}),
    });
    if (response && response.error) {
      addToast(
        response.errorDetails &&
          response.errorDetails.length > 0 &&
          response.errorDetails[0]?.detail
          ? response.errorDetails[0]?.detail
          : "Something went wrong",
        { appearance: "error" }
      );
      manageLoader(false);
    } else {
      if (String(response.data.data.id ?? "").length > 0) {
        setIsFormDirty(false);
        setNewProfileData(response.data.data);
        addToast(DISPLAY_TEXTS.PROFILE_CREATED, { appearance: "success" });
        setShowAdditionalFiles(true);
      }
      manageLoader(false);
    }
  };

  const onSubmitForm = async (e) => {
    e?.preventDefault();
    if (formDisabled) {
      checkDuplicate();
      return;
    }
    setSubmitButtonPressed(true);
    let errors = validatorModule(
      {
        ...formState,
        company: formState.company.value ? formState.company : undefined,
      },
      validationFormat
    );
    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      createUpdateAction();
    }
  };

  const resetForm = () => {
    setFormState(initialState);
    setErrorState(initialErrorState);
    setPersonId("");
    setUserCreated(false);
    setNewProfileData(null);
    setSubmitButtonPressed(false);
    setpersonRegistryType("");
    setprofileCategoryId("");
    setIsEdit(false);
    setEditedFields([]);
    setPageName("");
  };

  const [manageProfilePermission, setManageProfilePermission] =
    useState(undefined);
  useEffect(() => {
    if (
      !userRoleDetails?.modulePermissions ||
      String(personRegistryType ?? "").length === 0
    )
      return;
    if (isEdit && !profileData) return;
    const isPermissionAllowed = isEdit
      ? checkModuleEditPermission(
          userRoleDetails,
          personRegistryType,
          "profile",
          profileData ?? {}
        )
      : checkModuleAddPermission(
          userRoleDetails,
          personRegistryType,
          "profile",
          profileData ?? {}
        );
    setManageProfilePermission(isPermissionAllowed);
  }, [profileData, userRoleDetails, personRegistryType, isEdit]);

  const getCidbFile = (e) => {
    setCidbFile(e.target.files[0]);
  };

  const skipAdditionalDocs = () => {
    setShowAdditionalFiles(false);
    setUserCreated(true);
  };

  return (
    <PermissionWrapper
      isAllowed={manageProfilePermission}
      isLoading={manageProfilePermission === undefined}
    >
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <button
              type="button"
              id="backButton"
              className="btn btn-primary btn-sm float-right backArrow"
              onClick={goToProfilesList}
            >
              <span></span>
            </button>

            <h1>{pageName}</h1>
          </div>
        </div>
      </div>
      <div className="row create-profile">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card stepperCard">
            <div className="card-body">
              <div className="row">
                {!userCreated && !showAdditionalFiles && (
                  <div className="col-xl-6 col-lg-9">
                    <form
                      id="form-step-1"
                      className="tooltip-label-right"
                      onSubmit={isEdit ? updateProfile : onSubmitForm}
                    >
                      <div className="form-section-title">
                        <div className="title">Personal Details</div>
                        <div className="line"></div>
                      </div>

                      <div className="idManager">
                        <div className="form-row">
                          <div
                            className={`form-group position-relative error-l-75 col-md-6 ${
                              errorState.full_name ? "hasValidationError" : ""
                            }`}
                          >
                            <label htmlFor="firstName">
                              Full Name<i>*</i>
                            </label>
                            <input
                              type="text"
                              ref={firstInputPersonal}
                              className="form-control"
                              name="full_name"
                              value={formState.full_name}
                              onChange={(e) => onInputChange(e.target)}
                              id="full_name"
                              placeholder="Full Name"
                              disabled={isEdit && isUserRoleAC}
                            />
                            <p className="error-message">
                              {errorState && errorState.full_name
                                ? errorState.full_name
                                : ""}
                            </p>
                          </div>
                        </div>
                        {!isEdit &&
                          getIDTypeId("cidb", appOptions?.ic_types) && (
                            <div className="form-row">
                              <div
                                className={`form-group position-relative error-l-75 col-md-6 col-xl-7 ${
                                  errorState["ics[1]ic_number"]
                                    ? "hasValidationError"
                                    : ""
                                }`}
                              >
                                <label htmlFor="cidb_number">
                                  CIDB Number{" "}
                                  {personRegistryType === "employee-profile" &&
                                  !isEdit ? (
                                    <span>*</span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <div className="cidb-wrapper position-relative d-flex align-items-center">
                                  <IdInput
                                    value={formState["ics[1]ic_number"]}
                                    onInput={onCidbInput}
                                    disabled={isEdit}
                                    className="form-control pr-5"
                                  />
                                </div>

                                <p className="error-message">
                                  {errorState && errorState["ics[1]ic_number"]
                                    ? errorState["ics[1]ic_number"]
                                    : ""}
                                </p>
                              </div>
                              <div
                                className={`form-group position-relative error-l-75 col-md-6 col-xl-5${
                                  errorState["ics[1]expiry"]
                                    ? "hasValidationError"
                                    : ""
                                }`}
                              >
                                <label htmlFor="CIDB Expiry">
                                  CIDB Expiry
                                  {personRegistryType === "employee-profile" &&
                                  !isEdit ? (
                                    <span>*</span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <DatePicker
                                  className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  minDate={moment().toDate()}
                                  popperPlacement="bottom-end"
                                  popperClassName="some-custom-class"
                                  selected={getDayFirst(
                                    formState["ics[1]expiry"]
                                  )}
                                  onChange={(date) =>
                                    onInputChange({
                                      name: "ics[1]expiry",
                                      value: date,
                                    })
                                  }
                                  disabled={
                                    isEdit ||
                                    String(formState["ics[1]ic_number"] ?? "")
                                      .length < 16
                                  }
                                  showMonthDropdown
                                  useShortMonthInDropdown
                                  showYearDropdown
                                  popperModifiers={{
                                    preventOverflow: {
                                      enabled: true,
                                    },
                                  }}
                                />

                                <p className="error-message">
                                  {errorState && errorState["ics[1]expiry"]
                                    ? errorState["ics[1]expiry"]
                                    : ""}
                                </p>
                              </div>
                            </div>
                          )}

                        <div className="form-row">
                          <div
                            className={`form-group position-relative error-l-75 col-sm-4 col-md-3 ${
                              errorState.id_type ? "hasValidationError" : ""
                            }`}
                          >
                            <label htmlFor="passport">ID Type*</label>

                            <Select
                              className="formSelect"
                              classNamePrefix="react-select-form"
                              value={
                                formState.id_type && formState.id_type !== ""
                                  ? getIdValues(formState.id_type)
                                  : { label: "Select", value: "" }
                              }
                              onChange={(e) =>
                                onInputChange({
                                  name: "id_type",
                                  value: e.value,
                                })
                              }
                              name="id_type"
                              // className="form-control select2-single select2-hidden-accessible"

                              options={getIdOptions()}
                              isDisabled={isEdit && isUserRoleAC}
                            />
                            <p className="error-message">
                              {errorState && errorState.id_type
                                ? errorState.id_type
                                : ""}
                            </p>
                          </div>

                          <div
                            className={`form-group position-relative error-l-75 col-sm-8 col-md-3 ${
                              errorState.id_number ? "hasValidationError" : ""
                            }`}
                          >
                            <label htmlFor="id_number">ID Number* </label>
                            <input
                              type="text"
                              disabled={
                                !formState.id_type || (isEdit && isUserRoleAC)
                              }
                              className="form-control"
                              name="id_number"
                              value={formState?.id_number?.toUpperCase()}
                              id="id_number"
                              placeholder="ID Number"
                              onChange={(e) => {
                                setFormDisabled(true);
                                onInputChange(e.target);
                              }}
                              onBlur={(e) => checkDuplicate(e)}
                            />
                            <p className="error-message">
                              {errorState && errorState.id_number
                                ? errorState.id_number
                                : ""}
                            </p>
                          </div>

                          {formState.id_type === "fin" && (
                            <div
                              className={`form-group position-relative error-l-75 col-md-3 ${
                                errorState.work_permit_expiry
                                  ? "hasValidationError"
                                  : ""
                              }`}
                            >
                              <label htmlFor="designation">
                                Work Permit Expiry*
                              </label>
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                popperPlacement="top-end"
                                placeholderText="DD/MM/YYYY"
                                minDate={moment().toDate()}
                                //startDate={new Date("01/01/1950")}
                                selected={formState.work_permit_expiry}
                                onChange={(date) =>
                                  onInputChange({
                                    name: "work_permit_expiry",
                                    value: date,
                                  })
                                }
                              />
                              <p className="error-message">
                                {errorState && errorState.work_permit_expiry
                                  ? errorState.work_permit_expiry
                                  : ""}
                              </p>
                            </div>
                          )}
                        </div>

                        <div className="form-row">
                          <div
                            className={`form-group position-relative error-l-75 col-md-6 ${
                              errorState.mobile_number
                                ? "hasValidationError"
                                : ""
                            }`}
                          >
                            <label htmlFor="mobile_number">Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              name="mobile_number"
                              value={formState.mobile_number}
                              id="mobile_number"
                              placeholder="Mobile Number"
                              onChange={(e) => onInputChange(e.target)}
                            />
                            <p className="error-message">
                              {errorState && errorState.mobile_number
                                ? errorState.mobile_number
                                : ""}
                            </p>
                          </div>
                          <div
                            className={`form-group position-relative error-l-75 col-md-6 ${
                              errorState.email ? "hasValidationError" : ""
                            }`}
                          >
                            <label htmlFor="email">
                              Email address<i>*</i>
                            </label>
                            <input
                              //   disabled={defaultEmail && defaultEmail.readOnlyEmail}
                              type="email"
                              className="form-control"
                              name="email"
                              value={formState.email}
                              id="email"
                              placeholder="Your email address"
                              onChange={(e) => onInputChange(e.target)}
                            />
                            <p className="error-message">
                              {errorState && errorState.email
                                ? errorState.email
                                : ""}
                            </p>
                          </div>
                        </div>
                        <div className="form-row">
                          <div
                            className={`form-group position-relative col-md-6 ${
                              errorState.date_of_birth
                                ? "hasValidationError"
                                : ""
                            }`}
                          >
                            <label>
                              Date Of Birth(You must be at least 16 years)
                              <i>*</i>
                            </label>
                            <div className="input-group date">
                              <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                placeholderText="DD/MM/YYYY"
                                maxDate={moment().subtract(16, "y").toDate()}
                                popperPlacement="top-end"
                                popperClassName="some-custom-class"
                                selected={getDayFirst(formState?.date_of_birth)}
                                onChange={(date) =>
                                  onInputChange({
                                    name: "date_of_birth",
                                    value: date,
                                  })
                                }
                                showMonthDropdown
                                useShortMonthInDropdown
                                showYearDropdown
                                disabled={isEdit && isUserRoleAC}
                                popperModifiers={{
                                  preventOverflow: {
                                    enabled: true,
                                  },
                                }}
                              />
                              {/* <input className="dateFocusCrack" ref={dateField} /> */}
                            </div>
                            <p className="error-message">
                              {errorState && errorState.date_of_birth
                                ? errorState.date_of_birth
                                : ""}
                            </p>
                          </div>

                          <div
                            className={`form-group position-relative error-l-100 col-md-6 ${
                              errorState.nationality ? "hasValidationError" : ""
                            }`}
                          >
                            <label htmlFor="nationality">
                              Nationality<i>*</i>
                            </label>
                            <Select
                              className="formSelect"
                              classNamePrefix="react-select-form"
                              name="nationality"
                              options={getNationalityOptions()}
                              isDisabled={isEdit && isUserRoleAC}
                              value={
                                formState.nationality &&
                                formState.nationality !== ""
                                  ? getNationalityValues(formState.nationality)
                                  : { label: "Select", value: "" }
                              }
                              onChange={(e) =>
                                onInputChange({
                                  name: "nationality",
                                  value: e.value,
                                })
                              }
                            />
                            <p className="error-message">
                              {errorState && errorState.nationality
                                ? errorState.nationality
                                : ""}
                            </p>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group mb-1  col-md-12">
                            <span>
                              Gender<i>*</i> &nbsp;: &nbsp;
                            </span>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  value="male"
                                  className="form-check-input"
                                  onChange={(e) => onInputChange(e.target)}
                                  name="gender"
                                  checked={
                                    formState.gender === "male" ? true : false
                                  }
                                />
                                Male
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  value="female"
                                  className="form-check-input"
                                  onChange={(e) => onInputChange(e.target)}
                                  name="gender"
                                  checked={
                                    formState.gender === "female" ? true : false
                                  }
                                />
                                Female
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  value="other"
                                  className="form-check-input"
                                  onChange={(e) => onInputChange(e.target)}
                                  name="gender"
                                  checked={
                                    formState.gender === "other" ? true : false
                                  }
                                />
                                Prefer not to say
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-section-title mt-20">
                        <div className="title">Work Details</div>
                        <div className="line"></div>
                      </div>
                      <div className="idManager mb-4">
                        <div className="form-row">
                          <div
                            className={`form-group position-relative error-l-75 col-md-6 ${
                              errorState.designation ? "hasValidationError" : ""
                            }`}
                          >
                            <label htmlFor="designation">Designation*</label>
                            <input
                              type="text"
                              className="form-control"
                              name="designation"
                              value={formState.designation}
                              id="designation"
                              placeholder="Job Appointment/Designation"
                              onChange={(e) => onInputChange(e.target)}
                            />
                            <p className="error-message">
                              {errorState && errorState.designation
                                ? errorState.designation
                                : ""}
                            </p>
                          </div>

                          {personRegistryType === "employee-profile" && (
                            <div
                              className={`form-group position-relative error-l-75 col-md-6 ${
                                errorState.trade ? "hasValidationError" : ""
                              }`}
                            >
                              <label htmlFor="company">Trade*</label>
                              <Select
                                value={
                                  formState.trade && formState.trade !== ""
                                    ? getTradeValues(formState.trade)
                                    : { label: "Select", value: "" }
                                }
                                onChange={(e) =>
                                  onInputChange({
                                    name: "trade",
                                    value: e.value,
                                  })
                                }
                                name="trade"
                                className="formSelect"
                                classNamePrefix="react-select-form"
                                options={getTradeOptions()}
                                styles={customStyles}
                              />

                              <p className="error-message">
                                {errorState && errorState.trade
                                  ? errorState.trade
                                  : ""}
                              </p>
                            </div>
                          )}
                          <div
                            className={`form-group position-relative error-l-75 col-md-6 ${
                              errorState.company ? "hasValidationError" : ""
                            }`}
                          >
                            <label htmlFor="company">Company Name*</label>
                            <LazyLoadedSelect
                              className="formSelect"
                              classNamePrefix="react-select-form"
                              loadNextOptions={loadNextCompanyOptionsPage}
                              name="company"
                              onChange={(e) =>
                                onInputChange({
                                  name: "company",
                                  value: { label: e?.label, value: e?.value },
                                })
                              }
                              onInputChange={(value) =>
                                handleCompanySearch(value)
                              }
                              options={companies}
                              placeholder="Select"
                              value={
                                formState.company &&
                                formState.company?.value !== ""
                                  ? formState.company
                                  : { label: "Select", value: "" }
                              }
                              isLoading={isCompanyLoading}
                            />

                            <p className="error-message">
                              {errorState && errorState.company
                                ? errorState.company
                                : ""}
                            </p>
                          </div>
                          <div
                            className={`form-group position-relative error-l-75 col-md-6 ${
                              errorState.contract ? "hasValidationError" : ""
                            }`}
                          >
                            <label htmlFor="contract">Contract Name*</label>
                            <LazyLoadedSelect
                              className="formSelect"
                              classNamePrefix="react-select-form"
                              loadNextOptions={loadNextContractOptionsPage}
                              name="contract"
                              onChange={(e) => {
                                onInputChange({
                                  name: "contract",
                                  value: { label: e?.label, value: e?.value },
                                });
                              }}
                              onInputChange={(value) =>
                                handleContractSearch(value)
                              }
                              options={contracts}
                              placeholder="Select"
                              value={
                                formState.contract && formState.contract !== ""
                                  ? formState.contract
                                  : { label: "Select", value: "" }
                              }
                              isLoading={isContractLoading}
                            />
                            <p className="error-message">
                              {errorState && errorState.contract
                                ? errorState.contract
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="align-items-center justify-content-between float-right">
                        <button
                          className="btn btn-primary mr-3"
                          type="button"
                          onClick={() => {
                            goToProfilesList();
                          }}
                          id="cancelButton"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="btn btn-secondary btn-sm"
                          id="submitButton"
                        >
                          {isEdit ? "Update" : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                )}

                {showAdditionalFiles && (
                  <div className="additionalIdsSection col-xl-6 col-lg-9">
                    <div className="form-section-title">
                      <div className="title">Additional Documents</div>
                      <div className="line"></div>
                    </div>

                    <AdditionalIds profileData={newProfileData} />

                    <div className="align-items-center justify-content-between float-right mt-4">
                      <button
                        className="btn btn-primary mr-3"
                        type="button"
                        onClick={() => {
                          skipAdditionalDocs();
                        }}
                        id="skip"
                      >
                        Skip
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        id="submitButton"
                        onClick={() => {
                          skipAdditionalDocs();
                        }}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                )}

                {!showAdditionalFiles && userCreated && (
                  <div className="create-success-message">
                    <FinalStepperComponent
                      isEdit={isEdit}
                      personRegistryType={
                        params.type === "employee-profile"
                          ? "employee"
                          : "visitor"
                      }
                      profileData={newProfileData}
                      addAgain={() => resetForm()}
                      userRoleDetails={userRoleDetails}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavigationGuard
        when={isFormDirty}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          if (isFormDirty) {
            return true;
          }
          return false;
        }}
      />
      {showDuplicates && (
        <DuplicateProfiles
          show={showDuplicates}
          data={duplicateProfilesData}
          onCloseCallback={closeDuplicateProfiles}
        />
      )}
      <ReactTooltip />
    </PermissionWrapper>
  );
};

export default React.memo(CreateProfile);
