import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import ModalComponent from "../ModalComponent/ModalComponent";

const NavigationGuard = ({ when, navigate, shouldBlockNavigation }) => {
  const location = useLocation();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      setConfirmedNavigation(false);
      navigate(`${lastLocation.pathname}${lastLocation.search || ""}`);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ModalComponent
        show={modalVisible}
        header={"Are you sure you want to leave this page?"}
        content={"Changes you made may not be saved."}
        showSubmit={true}
        submitButtonText={"Leave this Page"}
        cancelButtonText={"Stay on this Page"}
        onSubmitCallback={handleConfirmNavigationClick}
        onCloseCallback={closeModal}
        isLoaderActive={false}
        hideFooter={false}
        customClass=""
      />
    </>
  );
};

export default NavigationGuard;
