export function columnSetter(key, columns) {
  let dynamicColumns = {};
  if (key && columns) {
    dynamicColumns = {
      name: columns[key]?.label ?? "",
      omit: columns[key]?.label ? false : true,
      sortable: columns[key]?.sortable ?? false,
      minWidth: columns[key]?.min_width + "px" ?? null,
      maxWidth: columns[key]?.max_width + "px" ?? null,
      left: columns[key]?.align
        ? columns[key]?.align === "left"
          ? true
          : false
        : true,
      right: columns[key]?.align
        ? columns[key]?.align === "right"
          ? true
          : false
        : false,
      center: columns[key]?.align
        ? columns[key]?.align === "center"
          ? true
          : false
        : false,
    };
  }
  return dynamicColumns;
}
