/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import _ from "lodash";
import axios from "axios";

import DataTable from "react-data-table-component";
import { API_BASE_URL, apiRoutes } from "../../constants";
import { _mapAvailableActions } from "../../mappers/userRoleMappers";
import { UserGroupContext } from "../../context/UserGroupContext";
import { AppContext } from "../../context/AppContext";
//import { useBodyClass } from "../../helpers/utils";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import UserRoleFormComponent from "../../components/UserRoleFormComponent/UserRoleFormComponent";
import ModalRightComponent from "../../components/common/ModalRightComponent/ModalRightComponent";
import SearchBar from "../../components/common/Search/Search";
import ProfileCard from "../../components/ProfileCard/ProfileCard";

const UserRoles = ({ match }) => {
  const initialState = {
    name: "",
    dashboard: { label: "Select Dashboard", value: "" },
    resourcePermissions: [],
  };
  const { addToast } = useToasts();
  const [tableUserId, setTableUserId] = React.useState("");

  const [currentRoleId, setCurrentRoleId] = React.useState(0);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [payloadData, setPayloadData] = React.useState();
  const [mode, setMode] = React.useState("");
  //const [ roleName, setRoleName ] = React.useState('');
  const [searchQueryString, setSearchQueryString] = React.useState("");
  const [formState, updateFormState] = React.useState(initialState);
  const { permissionsSelected, updatePermissionsSelected } =
    React.useContext(UserGroupContext);
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);
  const [showListSearch] = React.useState(false);

  const onInputChange = (target) => {
    //setRoleName(target.value);
    updateFormState({
      ...formState,
      [target.name]: target.value,
    });
  };

  React.useEffect(() => {
    updateFormState({
      ...formState,
      resourcePermissions: permissionsSelected,
    });
  }, [permissionsSelected]);

  const {
    showRightModal,
    manageRightModal,
    showModal,
    manageModal,
    pagePermissions,
  } = React.useContext(AppContext);
  const [systemResources, setSystemResources] = React.useState([]);
  const [dashboardTypes, setDashboardTypes] = React.useState([]);
  const [createUserRole] = useMutation(["accounts", "roles"]);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const systemResourcesResponse = useQuery(apiRoutes.systemResourcesResponse, {
    client,
  });
  const roleListsResponse = useQuery(
    [
      "accounts",
      "roles",
      {
        filter: {
          search: searchQueryString,
        },
        page: {
          number: currentPageNumber,
          size: rowsPerPage,
        },
      },
    ],
    { client }
  );

  const [updateRole] = useMutation(["accounts", "roles", currentRoleId], {
    method: "PUT",
    client,
  });
  React.useEffect(() => {
    setSystemResources(_mapAvailableActions(systemResourcesResponse.data));
  }, [systemResourcesResponse.data]);

  React.useEffect(() => {
    const options = {
      method: "OPTIONS",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: apiRoutes.accountsRoles,
    };
    axios(options).then((response) => {
      const {
        data: {
          data: {
            actions: {
              POST: {
                dashboard: { choices: dashboardChoices },
              },
            },
          },
        },
      } = response;
      setDashboardTypes(dashboardChoices);
    });
  }, []);

  const submitUserRole = async () => {
    if (
      formState.name !== "" &&
      formState.dashboard?.value !== "" &&
      formState.resourcePermissions.length > 0
    ) {
      const { data } = await createUserRole({
        ...formState,
        dashboard: formState.dashboard?.value,
      });
      if (data) {
        roleListsResponse.refetch();
        updatePermissionsSelected([]);
        manageModalSettings(false);
        addToast(DISPLAY_TEXTS.ROLE_ADDED, { appearance: "success" });
        setTimeout(() => {
          /*eslint no-restricted-globals: ["error", "event"]*/
          location.reload();
        }, 1000);
      }
    }
  };

  const updateUserRoleData = async () => {
    //console.log('formState', formState)
    const { data } = await updateRole({
      ...formState,
      dashboard: formState?.dashboard?.value,
      id: currentRoleId,
    });
    if (data) {
      roleListsResponse.refetch();
      updatePermissionsSelected([]);
      manageModalSettings(false);
      addToast(DISPLAY_TEXTS.ROLE_UPDATED, { appearance: "success" });
      setMode("");
      setTimeout(() => {
        /*eslint no-restricted-globals: ["error", "event"]*/
        location.reload();
      }, 1000);
    }
  };

  const addNewRole = () => {
    setMode("add");
    setCurrentRoleId(0);
    manageModalSettings(true);
    setPayloadData([]);
    //setRoleName('');
  };

  const updateUserRole = (id) => {
    setMode("edit");
    setCurrentRoleId(id);
    const getExistingDashDataData = (rl) => {
      let rls = { label: "Select Dashboard", value: "" };
      dashboardTypes.forEach((item, i) => {
        if (item.value === rl)
          rls = {
            label: item.display_name,
            id: item.value,
          };
      });
      return rls;
    };
    const exisitingData = _.keyBy(roleListsResponse.data, "id")[id];
    setPayloadData(exisitingData.resourcePermissions);
    //setRoleName(exisitingData.name);
    updateFormState({
      name: exisitingData.name,
      dashboard: getExistingDashDataData(exisitingData.dashboard),
      resourcePermissions: exisitingData.resourcePermissions,
    });
    manageModalSettings(true);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "edit") {
      updateUserRole(mode.roleListsResponse.id);
    }

    if (mode.mode === "delete") {
      manageDeletedOption(mode.roleListsResponse.id);
    }
  };

  let menuRef = React.useRef();

  React.useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (
        event.target.className !== "user-role-action-icons" &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      )
        setTableUserId("");
    });
  });

  const showHideContextMenu = (userid) => {
    setTableUserId(userid);
  };

  const columns = [
    {
      name: "Role Name",
      sortable: true,
      selector: "name",
      cell: (roleListsResponse) => (
        <ProfileCard
          data={roleListsResponse}
          mode="Company"
          actions={pagePermissions}
          actionsToParent={(mode) => getEvent({ mode, roleListsResponse })}
        />
      ),
    },
    {
      name: "Actions",
      sortable: false,
      cell: (role) => (
        <>
          <div className="contextMenu">
            <span
              className="user-role-action-icons"
              onClick={() => showHideContextMenu(role.id)}
            >
              <i></i>
            </span>
            {tableUserId === role.id && (
              <ul ref={menuRef}>
                {_.includes(pagePermissions, "edit") && (
                  <li onClick={() => updateUserRole(role.id)}>
                    <button>
                      <i className="iconsminds-pen user-role-action-icons edit"></i>
                      Edit
                    </button>
                  </li>
                )}
                {_.includes(pagePermissions, "delete") && (
                  <li onClick={() => manageDeletedOption(role.id)}>
                    <button>
                      <i className="simple-icon-trash user-role-action-icons delete"></i>
                      Delete
                    </button>
                  </li>
                )}
              </ul>
            )}
          </div>
        </>
      ),
      right: true,
      hide: "md",
    },
  ];

  const onChangePage = (page) => {
    setCurrentPageNumber(page);
  };

  //     const tbl = (data) => {
  //         return _.map(data, (role, i) => {
  //             return (
  //                 <tr key={_.uniqueId('userole-')}>
  //                     <th scope="row">{i + 1}</th>
  //                     <td>{role.name}</td>
  //                     <td><span className="iconsminds-pen user-role-action-icons edit" onClick={() => updateUserRole(role.id)}></span> <span onClick={() => manageDeletedOption(role.id)} className="simple-icon-trash user-role-action-icons delete
  // "></span></td>
  //                 </tr>
  //             );
  //         });

  //     };
  //useBodyClass('modal-open');
  const manageModalSettings = (status) => {
    manageRightModal(status);
  };

  const manageDeletedOption = (id) => {
    setCurrentRoleId(id);
    manageModal(true);
  };

  const deleteUserRole = async () => {
    const { error } = await client.delete(["accounts", "roles", currentRoleId]);
    if (error === undefined) {
      roleListsResponse.refetch();
      addToast(DISPLAY_TEXTS.ROLE_DELETED, { appearance: "success" });
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
    setCurrentRoleId(0);
    manageModal(false);
  };

  const doCloseModal = () => {
    setCurrentRoleId(0);
    manageModal(false);
  };

  const onSearchInputChange = (searchQuery) => {
    setSearchQueryString(searchQuery);
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>User Roles</h1>
            <div className="mobile-btn">
              {_.includes(pagePermissions, "create") && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm float-right"
                  title="Add role"
                  onClick={() => addNewRole()}
                >
                  <>+</>
                </button>
              )}
            </div>
            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="right-holder">
                  {showListSearch ? (
                    <SearchBar onSearchInputChange={onSearchInputChange} />
                  ) : (
                    ""
                  )}

                  {_.includes(pagePermissions, "create") && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm dktp-btn float-right"
                      title="Add role"
                      onClick={() => addNewRole()}
                    >
                      <>+</>
                    </button>
                  )}
                </div>
              </div>
            </div>
            {showListSearch ? (
              <div
                className="tableFilterButton"
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>
            ) : (
              ""
            )}
          </div>

          {/* <nav className="breadcrumb-container d-none d-sm-block d-lg-inline-block" aria-label="breadcrumb">
                        <ol className="breadcrumb pt-0">
                            <li className="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li className="breadcrumb-item">
                                <a href="#">Library</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Data</li>
                        </ol>
                    </nav> */}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                roleListsResponse.isLoading
                  ? "card-body dataTableOuter table-loader"
                  : "card-body dataTableOuter"
              }
            >
              <DataTable
                subHeader={true}
                progressPending={roleListsResponse.isLoading}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={roleListsResponse.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={
                  roleListsResponse.meta &&
                  roleListsResponse.meta.pagination.count
                }
              />
              {/* <h5 className="card-title">Roles</h5>
                            {roleListsResponse.data && roleListsResponse.data.length > 0 &&
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Role Name</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tbl(roleListsResponse.data)}
                                    </tbody>
                                </table>
                            } */}
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={<b>Confirm Delete</b>}
        content={
          <p>Are you sure you want to delete the selected User Role ?</p>
        }
        onSubmitCallback={deleteUserRole}
        onCloseCallback={doCloseModal}
        submitButtonText="Delete"
        customClass="delete"
      />

      <ModalRightComponent
        show={showRightModal}
        header={mode === "add" ? "Add New Role" : "Update Role"}
        submitButtonText={mode === "add" ? "Submit" : "Update"}
        classes="normal-right-modal"
        content={
          <UserRoleFormComponent
            title={
              mode === "add"
                ? 'Submitting below form will add "a new role".'
                : 'Submitting below form will update "the role".'
            }
            updateInputChange={onInputChange}
            payloadData={payloadData}
            formState={formState}
            dashboardTypes={dashboardTypes}
            systemResources={systemResources}
            updatePermissions={updatePermissionsSelected}
          />
        }
        onSubmitCallback={() =>
          mode === "add" ? submitUserRole() : updateUserRoleData()
        }
        onCloseCallback={() => manageModalSettings(false)}
      />
    </>
  );
};

export default UserRoles;
