import React from "react";
import { useHistory } from "react-router-dom";
import { VW_ROUTES, CCTV_URL } from "../../constants";
//import NotificationItem from "../../components/common/NotificationItem/NotificationItem";
import { logout } from "../../helpers/utils";
import { CctvIcon } from "../../components/common/Svg/Svg";
import { useMutation } from "jsonapi-react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import logo from "../../assets/images/logo-inner.png";
import logow from "../../assets/images/logo-inner-w.png";
//import _ from "lodash";

export const TopNavigation = ({
  user,
  hideMainSidebar,
  hideSubSidebar,
  mainSidebarHidden,
  subSidebarHidden,
  notifications,
  logoImage,
}) => {
  const [toggleAccountInfo, setToggleAccountInfo] = React.useState(false);
  const { theme, switchTheme, currentUser } = React.useContext(AppContext);
  //const [toggleNotificationInfo, setToggleNotificationInfo] = React.useState(false);
  const [logOutUser] = useMutation(["auth", "logout"]);
  const history = useHistory();
  const onAccountInfoClick = (status) => {
    //setToggleNotificationInfo(false);
    setToggleAccountInfo(status);
  };
  // const onNotificationIconClick = (status) => {
  //     setToggleAccountInfo(false);
  //     //setToggleNotificationInfo(status);
  // }

  //const getNotificationItems = () => _.map(notifications, notification => <NotificationItem key={_.uniqueId('menu_')} item={notification} />);
  const toggleMainSidebar = (e) => {
    e.preventDefault();
    if (mainSidebarHidden && subSidebarHidden) {
      hideMainSidebar(false);
    } else if (!mainSidebarHidden && !subSidebarHidden) {
      hideSubSidebar(true);
    } else if (!mainSidebarHidden && subSidebarHidden) {
      hideMainSidebar(true);
    }
  };

  const doSignOut = async (e) => {
    e.preventDefault();
    await logOutUser();
    logout();

    history.push(VW_ROUTES.LOGIN);
  }; //console.log(user)

  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center align-content-center navbar-left">
        {/* eslint-disable-next-line */}
        <a
          href="#"
          onClick={(e) => toggleMainSidebar(e)}
          className="menu-button d-none d-md-block"
        >
          <div className="buttonIcon">
            <svg
              className="main"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9 17"
            >
              <rect x="0.48" y="0.5" width="7" height="1" />
              <rect x="0.48" y="7.5" width="7" height="1" />
              <rect x="0.48" y="15.5" width="7" height="1" />
            </svg>
            <svg
              className="sub"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 17"
            >
              <rect x="1.56" y="0.5" width="16" height="1" />
              <rect x="1.56" y="7.5" width="16" height="1" />
              <rect x="1.56" y="15.5" width="16" height="1" />
            </svg>
          </div>
        </a>
        {/* eslint-disable-next-line */}
        <a
          href="#"
          onClick={(e) => toggleMainSidebar(e)}
          className="menu-button-mobile d-xs-block d-sm-block d-md-none"
        >
          <div className="buttonIcon">
            <svg
              className="main"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 9 17"
            >
              <rect x="0.48" y="0.5" width="7" height="1" />
              <rect x="0.48" y="7.5" width="7" height="1" />
              <rect x="0.48" y="15.5" width="7" height="1" />
            </svg>
            <svg
              className="sub"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 17"
            >
              <rect x="1.56" y="0.5" width="16" height="1" />
              <rect x="1.56" y="7.5" width="16" height="1" />
              <rect x="1.56" y="15.5" width="16" height="1" />
            </svg>
          </div>
        </a>

        <Link
          className="navbar-logo"
          to={
            currentUser.dashboard === "administrator"
              ? VW_ROUTES.USER_MANAGEMENT
              : currentUser.dashboard === "ac_dashboard" &&
                currentUser.role_slug === "account-coordinator"
              ? VW_ROUTES.APPLICANTS_HISTORY
              : VW_ROUTES.DASHBOARD
          }
        >
          <span className="logo d-xs-block">
            <img src={logoImage?.logo} alt="logo" />
          </span>
          {/* <span className="logo-mobile d-block d-xs-none"></span> */}
        </Link>
      </div>

      <div className="navbar-right">
        <div className="header-icons d-inline-block align-middle">
          <div className="position-relative d-inline-block">
            {currentUser.role_slug !== "client" && (
              <>
                {currentUser.dashboard === "nsg_dashboard" && (
                  <span className="cctv-icon">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={CCTV_URL}
                    >
                      <CctvIcon />
                    </a>
                  </span>
                )}
              </>
            )}
            {/* <button className="header-icon btn btn-empty p-0" type="button" id="refreshButton"
                            // onClick={() => onAccountInfoClick()}
                            aria-haspopup="true" aria-expanded="false">
                            <svg height="512pt" viewBox="0 -43 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg" ><path d="m160 372.679688c-88.222656 0-160-71.777344-160-160 0-88.222657 71.777344-160 160-160h64v32h-64c-70.574219 0-128 57.425781-128 128 0 70.578124 57.425781 128 128 128zm0 0" fill="#4dd0e1" /><path d="m352 372.679688h-64v-32h64c70.574219 0 128-57.421876 128-128 0-70.574219-57.425781-128-128-128v-32c88.222656 0 160 71.777343 160 160 0 88.222656-71.777344 160-160 160zm0 0" fill="#fbc02d" /><path d="m288 52.679688h32v32h-32zm0 0" fill="#80deea" /><path d="m192 340.679688h32v32h-32zm0 0" fill="#fdd835" /><path d="m329.375 420.679688-64-64 64-64c6.242188-6.238282 16.386719-6.238282 22.625 0 6.238281 6.242187 6.238281 16.382812 0 22.625l-41.375 41.375 41.375 41.375c6.238281 6.242187 6.238281 16.386718 0 22.625-6.238281 6.242187-16.382812 6.242187-22.625 0zm0 0" fill="#fdd835" /><path d="m160 132.679688c-6.238281-6.238282-6.238281-16.382813 0-22.625l41.375-41.375-41.375-41.375c-6.238281-6.242188-6.238281-16.382813 0-22.625 6.238281-6.238282 16.382812-6.238282 22.625 0l64 64-64 64c-6.242188 6.242187-16.386719 6.242187-22.625 0zm0 0" fill="#80deea" /></svg>
                        </button> */}
            {/* <button className="header-icon btn btn-empty" type="button" id="notificationButton"
                            //onClick={() => onNotificationIconClick()}
                            onMouseEnter={() => onNotificationIconClick(true)}
                            //onMouseLeave={() => onAccountInfoClick(false)}
                            aria-haspopup="true" aria-expanded="false">
                            <i className="simple-icon-bell"></i>
                            <span className="count">{notifications.length}</span>
                        </button>
                        <div
                            onMouseEnter={() => onNotificationIconClick(true)}
                            onMouseLeave={() => onNotificationIconClick(false)}
                            className={`dropdown-menu dropdown-menu-right mt-3 position-absolute ${toggleNotificationInfo ? 'show' : 'hide'}`}
                            id="notificationDropdown">
                            <div className="scroll">
                                {getNotificationItems()}
                            </div>
                        </div> */}
          </div>
        </div>

        <div
          className="user d-inline-block"
          onClick={() => onAccountInfoClick(true)}
          onMouseEnter={() => onAccountInfoClick(true)}
          onMouseLeave={() => onAccountInfoClick(false)}
        >
          <button
            className="btn btn-empty p-0"
            type="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="name text-capitalize">
              {user ? `${user.full_name}` : ""}
            </span>
            <span>
              <img
                alt="ProfilePic"
                src={
                  user.avatar
                    ? user.avatar
                    : "https://pluspng.com/img-png/user-png-icon-male-user-icon-512.png"
                }
                onError={({ currentTarget: userImageElement }) => {
                  userImageElement.onerror = null;
                  userImageElement.src =
                    "https://pluspng.com/img-png/user-png-icon-male-user-icon-512.png";
                }}
              />
            </span>
          </button>

          <div
            className={`dropdown-menu dropdown-menu-right mt-3 ${
              toggleAccountInfo ? "show" : "hide"
            }`}
          >
            <a
              onClick={(e) => {
                e.preventDefault();
                history.push(VW_ROUTES.PROFILE);
              }}
              className="dropdown-item"
              href="/notfound"
            >
              Profile
            </a>
            {/* eslint-disable-next-line */}
            <div
              className="dropdown-item theme-switch"
              onClick={() => switchTheme(theme)}
            >
              Dark Mode<span></span>
            </div>
            <button onClick={(e) => doSignOut(e)} className="dropdown-item">
              Sign out
            </button>
            {/* <a ></a> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopNavigation;
