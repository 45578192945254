import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "jsonapi-react";
import { VW_ROUTES } from "../../constants";
import { parseAPIResponseError } from "../../helpers/helpers";
import loginImage from "../../assets/images/login_img.png";
import logowhite from "../../assets/images/logo_w.png";
import logo from "../../assets/images/logo.png";

export const ResetPassword = () => {
  const initialState = {
    new_password1: "",
    new_password2: "",
  };
  const history = useHistory();
  const [formState, updateFormState] = React.useState(initialState);
  const [isLoading, setIsLoading] = React.useState(false);
  const [resetUserPassword] = useMutation(["auth", "password", "change"]);
  const [error, updateErrorMessage] = React.useState({});
  const onInputChangeEvent = (e) => {
    updateFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const onResetPasswordSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const { error, data } = await resetUserPassword(formState);
    setIsLoading(false);
    if (error) {
      updateErrorMessage(parseAPIResponseError(error));
    } else if (data) {
      history.push(VW_ROUTES.LOGIN);
      //console.log(data)
      //addToast(data.detail, { appearance: 'success' });
    }
    // if(data !== undefined){
    //     history.push(VW_ROUTES.LOGIN);
    // }
    //console.log(getAPIFormDataFormat('change_password', formState))
    // doForgotPassword(formState).then((response, error) => {
    //     if(response.status === 200){
    //         history.push(VW_ROUTES.LOGIN);
    //     }
    // });
  };
  return (
    <>
      <div className="login-cover">
        <div className="left-section">
          <div className="content-portion">
            <div className="logo_w">
              <img src={logowhite} alt="v-watch" />
            </div>
            <h1>
              <span>Welcome to </span>v-watch Registration Portal
            </h1>
            <p>
              Vwatch a revolutionary solution for enterprises to become hybrid
              workplaces. We offer a comprehensive suite that will help you
              automate work with AI and optimise your space in alignment with
              the hybrid needs of your workforce.
            </p>
            <p>
              Smart Solutions for smarter places. Delight your workforce with
              features designed to make their workplace experiences more
              seamless.
            </p>
          </div>
          <div className="image-holder">
            <img src={loginImage} alt="v-watch" />
          </div>
        </div>
        <div className="right-section">
          <div className="content-portion">
            <div className="content-inner">
              <div className="logo">
                <img src={logo} alt="v-watch" />
              </div>
              <h2>Reset Password</h2>
              <p className="mb-5">Please reset your password.</p>

              <form onSubmit={onResetPasswordSubmit}>
                <label className="form-group has-float-label mb-4">
                  <input
                    onChange={(e) => onInputChangeEvent(e)}
                    name="new_password1"
                    type="password"
                    className="form-control"
                  />
                  <span>Password</span>
                  <p className="login-error-message">
                    {error && error.field === "new_password1"
                      ? error.errorMsg
                      : ""}
                  </p>
                </label>
                <label className="form-group has-float-label mb-4">
                  <input
                    onChange={(e) => onInputChangeEvent(e)}
                    name="new_password2"
                    type="password"
                    className="form-control"
                  />
                  <span>Confirm Password</span>
                  <p className="login-error-message">
                    {error && error.field === "new_password2"
                      ? error.errorMsg
                      : ""}
                  </p>
                </label>
                <div className="d-flex justify-content-end align-items-center position-relative">
                  <button
                    className="btn btn-secondary btn-lg btn-shadow"
                    type="submit"
                    disabled={isLoading}
                  >
                    SUBMIT
                    {isLoading && (
                      <span className="button-loader-container">
                        <div
                          className="spinner-border spinner-border-sm ml-1 text-white"
                          role="status"
                        ></div>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <main>
                <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side login-inner-background">
                                    <p className="text-white h2">Welcome to TWRP Registration Portal</p>
                                    <p className="white mb-0">Please reset your password. <br /></p>
                                </div>
                                <div className="form-side"><span className="logo-single"></span>
                                    <h6 className="my-4">Reset Password</h6>
                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main> */}
    </>
  );
};

export default ResetPassword;
