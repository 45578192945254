import { useState, useEffect } from "react";
import axios from "axios";
import { useClient, useQuery } from "jsonapi-react";
import { apiRoutes } from "../../constants";

const useCheckMaintenance = () => {
  const client = useClient();

  const [isMaintenance, setIsMaintenance] = useState();

  const {
    data: systemInfo,
    isLoading: loading,
    error: errorInfo,
  } = useQuery(apiRoutes.systemInfo, {
    client,
  });

  useEffect(() => {
    if (systemInfo) {
      setIsMaintenance(systemInfo?.base?.maintenanceMode === 0 ? false : true);
    }
  }, [systemInfo]);

  return { isMaintenance, loading, systemInfo };
};

export default useCheckMaintenance;
