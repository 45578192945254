import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useClient } from "jsonapi-react";
import axios from "axios";
import _, { set } from "lodash";
import { API_BASE_URL, apiRoutes } from "../../constants";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import profileimagePlaceholder from "../../../src/assets/images/profileimagePlaceholder.jpg";
import { Camera } from "react-camera-pro";
import IDLoader from "../common/Loader/IDLoader";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

const CaptureImageComponent = ({
  status,
  profileImages,
  applicantId,
  onPictureCapture,
}) => {
  const camera = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [camOrientation, setCamOrientation] = React.useState(0);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  React.useEffect(() => {
    if (!status) {
      let stream = camera.video.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      camera.video.srcObject = null;
    }
  }, [status]);

  //const errBack = () => {};

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const uploadProfileImages = (files) => {
    setIsLoading(true);
    let formData = new FormData();
    formData.append("person", applicantId);

    _.map(files, (file) => {
      formData.append("avatar", file);
    });

    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };

    const url = `${apiRoutes.profilePic.replace("{profileId}", applicantId)}`;
    axios
      .post(url, formData, config)
      .then((response) => {
        //console.log(response);
        setIsLoading(false);
        onPictureCapture();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const borderCornerRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  // const imageRef = useRef(null);
  // const cropRef = useRef(null);
  // const [crop, setCrop] = useState(undefined);
  // const [profileImage, setProfileImage] = useState(null);
  // const [profileAvatar, setProfileAvatar] = useState(null);
  // const [completedCrop, setCompletedCrop] = useState(null);
  // const [aspect, setAspect] = useState(4 / 5);

  // const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  //   let cropWidth = 75;
  //   let cropHeight = 75;

  //   if (window.innerWidth <= 570) {
  //     cropWidth = 65;
  //     cropHeight = 65;
  //   } else if (window.innerWidth <= 767) {
  //     cropWidth = 50;
  //     cropHeight = 50;
  //   } else if (window.innerWidth <= 1023) {
  //     cropWidth = 65;
  //     cropHeight = 65;
  //   }

  //   return centerCrop(
  //     makeAspectCrop(
  //       {
  //         unit: "%",
  //         width: cropHeight,
  //         height: cropHeight,
  //       },
  //       aspect,
  //       mediaWidth,
  //       mediaHeight
  //     ),
  //     mediaWidth,
  //     mediaHeight
  //   );
  // };

  // const onImageLoad = (e) => {
  //   const { width, height } = e.currentTarget;
  //   setCrop(centerAspectCrop(width, height, aspect));
  // };

  // useEffect(() => {
  //   if (completedCrop?.width) canvasPreview(completedCrop);
  // }, [completedCrop]);

  // const canvasPreview = async (crop) => {
  //   setIsLoading(true);
  //   const TO_RADIANS = Math.PI / 180;
  //   const image = imageRef.current;
  //   const rotate = 0;
  //   const scale = 1;
  //   const canvas = document.createElement("canvas");
  //   const ctx = canvas.getContext("2d");
  //   if (!ctx) {
  //     throw new Error("No 2d context");
  //   }
  //   const pixelRatio = window.devicePixelRatio;
  //   canvas.width = Math.floor(crop.width * pixelRatio);
  //   canvas.height = Math.floor(crop.height * pixelRatio);
  //   ctx.scale(pixelRatio, pixelRatio);
  //   ctx.imageSmoothingQuality = "high";
  //   const cropX = crop.x;
  //   const cropY = crop.y;
  //   const rotateRads = rotate * TO_RADIANS;
  //   const centerX = image.naturalWidth / 2;
  //   const centerY = image.naturalHeight / 2;
  //   ctx.save();
  //   ctx.translate(-cropX, -cropY);
  //   ctx.translate(centerX, centerY);
  //   ctx.rotate(rotateRads);
  //   ctx.scale(scale, scale);
  //   ctx.translate(-centerX, -centerY);
  //   ctx.drawImage(
  //     image,
  //     0,
  //     0,
  //     image.naturalWidth,
  //     image.naturalHeight,
  //     0,
  //     0,
  //     image.width,
  //     image.height
  //   );
  //   let dataUri = canvas.toDataURL();
  //   urltoFile(dataUri, `${_.uniqueId("image-")}.jpeg`, "image/jpeg").then(
  //     async (file) => {
  //       let formData = new FormData(); //formdata object
  //       formData.append("person", applicantId); //append the values with key, value pair
  //       formData.append("avatar", file);
  //       const config = {
  //         headers: {
  //           Authorization: `JWT ${token}`,
  //           "content-type": "multipart/form-data",
  //         },
  //       };
  //       const url = apiRoutes.profilePic.replace("{profileId}", applicantId);
  //       axios
  //         .post(url, formData, config)
  //         .then((response) => {
  //           setIsLoading(false);
  //           onPictureCapture();
  //         })
  //         .catch((error) => {
  //           setIsLoading(false);
  //           console.log(error);
  //         });
  //     }
  //   );
  //   setProfileAvatar(dataUri);
  //   ctx.restore();
  // };

  const captureImageFromCam = async () => {
    setIsLoading(true);
    const photo = camera.current && camera.current.takePhoto();
    setCapturedImage(photo);
    urltoFile(photo, `${_.uniqueId("image-")}.jpeg`, "image/jpeg").then(
      async (file) => {
        let formData = new FormData(); //formdata object
        formData.append("person", applicantId); //append the values with key, value pair
        formData.append("avatar", file);
        const config = {
          headers: {
            Authorization: `JWT ${token}`,
            "content-type": "multipart/form-data",
          },
        };
        const url = apiRoutes.profilePic.replace("{profileId}", applicantId);
        axios
          .post(url, formData, config)
          .then((response) => {
            setIsLoading(false);
            setCapturedImage(null);
            onPictureCapture();
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
      }
    );
  };

  return (
    <>
      <IDLoader className="fade-background" open={isLoading} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            {capturedImage === null ? (
              <div className="camera-element-wrapper camera-container embed-responsive embed-responsive-16by9">
                {status && (
                  <>
                    <Camera ref={camera} />
                    <div className="camera-border" ref={borderCornerRef}>
                      {" "}
                    </div>
                  </>
                )}
                <div
                  onClick={() => !isLoading && captureImageFromCam()}
                  className="capture-btn"
                >
                  <i className="simple-icon-camera"></i>
                </div>
              </div>
            ) : (
              <img
                className="camera-element-wrapper"
                src={capturedImage}
                alt="Image"
              />
            )}

            <div className="row">
              {/* <div className="customFileUpload ml-auto mr-auto mt-2">
                Choose File
                <input
                  type="file"
                  className="hide_file"
                  onChange={(e) => uploadProfileImages(e.target.files)}
                  accept="image/*"
                  id="profileImages"
                  name="profileImages"
                  disabled={isLoading}
                />
              </div> */}
              <div className="col-12">
                <div className="icon-cards-row row">
                  <Slider {...settings}>
                    {_.map(profileImages, (profileImage) => (
                      <div
                        key={_.uniqueId("prof-image-")}
                        className="captured-profile-image"
                      >
                        <img
                          className="visitor-image"
                          src={profileImage?.avatar}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = profileimagePlaceholder;
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className=" d-none d-md-block">
              <h3>Guidelines to Follow</h3>
              <ul className="p-3">
                <li>Position your face exactly at the center of the frame.</li>
                <div className="person-bounding-box mt-3 mb-3">
                  <div className="person-border">
                    <div className="person-icon">
                      <i className="simple-icon-user font-size-18"></i>
                    </div>
                  </div>
                </div>
                <li>Once everything is aligned and in focus, take the photo</li>
                <li>Ensure that the captured image is sharp and clear.</li>
              </ul>
            </div>
            {/* <div>
              <div className="profile-crop">
                <ReactCrop
                  ref={cropRef}
                  crop={crop}
                  onComplete={(c) => setCompletedCrop(c)}
                >
                  <img
                    src={capturedImage}
                    ref={imageRef}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CaptureImageComponent;
