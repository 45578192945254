/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import _ from "lodash";
import { DEVICE_SERVICE_URL, VW_ROUTES, apiRoutes } from "../../constants";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import useState from "react-usestateref";
import { AppContext } from "../../context/AppContext";
import {
  checkModuleViewPermission,
  formatTextWithFallback,
  getCategoryType,
  getLocalDateTime,
} from "../../helpers/helpers";
import SearchBar from "../../components/common/Search/Search";
// import ExportButton from "../../components/common/Export/Export";
import LivePeopleExpandable from "../../components/common/TableExpandableComponent/LivePeopleExpandable";
import SelectApplicants from "../../components/common/SelectApplicants/SelectApplicants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useStorage from "../../helpers/useStorage";
const LivePeopleListComponent = ({ location }) => {
  const { setToStorage, getFromStorage, removeFromStorage } = useStorage();
  const [isLoading, setIsLoading] = React.useState(true);
  const {
    userRoleDetails,
    appOptions,
    getCategoryFromSlug,
    currentUser,
    manageLoader,
  } = React.useContext(AppContext);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [updateKey, setUpdateKey] = React.useState(0);
  const [resetPagination, setResetPagination] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState();
  const [totalEntries, setTotalEntries] = React.useState(0);
  const [markerId, setMarkerId] = React.useState("");
  // const [pageAction, setPageAction] = React.useState("first");
  const [pageNumber, setPageNumber] = React.useState(1);

  const [searchQueryString, setSearchQueryString, searchQueryStringRef] =
    useState();
  const [filterCategory, setFilterCategory] = React.useState();

  const [contract, setContract] = React.useState();
  const [company, setCompany] = React.useState();

  const [category, setCategory] = React.useState();
  const [filterApplied, setFilterApplied] = React.useState(false);

  const [personData, setpersonData] = React.useState([]);
  const token = localStorage.getItem("access_token");

  const [exportData, setExportData] = React.useState("");
  const [disableExport, setdisableExport] = React.useState(true);

  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");

  const history = useHistory();
  //const location = useLocation();

  const filterTypes = {
    ["live-people"]: "live",
    ["over-stay-people"]: "over_stay",
  };

  const filterNames = {
    ["live-people"]: "Live",
    ["over-stay-people"]: "Over Stay",
  };

  let filterType = filterTypes[location.pathname.split("/")[1]];

  const getPageTitle = () => {
    if (contract)
      return `Contract Based ${
        filterNames[location.pathname.split("/")[1]]
      } People Listing`;
    else if (String(company ?? "").length)
      return `Company Based ${
        filterNames[location.pathname.split("/")[1]]
      } People Listing`;
    else
      return `${filterNames[location.pathname.split("/")[1]]} People Listing`;
  };

  React.useEffect(() => {
    setRowsPerPage(
      getFromStorage(`${_.snakeCase(getPageTitle())}_rows_per_page`) ?? 10
    );
    setSearchQueryString(
      getFromStorage(`${_.snakeCase(getPageTitle())}_filter_search`) ?? ""
    );
    setFilterCategory(
      getFromStorage(`${_.snakeCase(getPageTitle())}_filter_category`) ?? ""
    );
    setCategory(
      getFromStorage(`${_.snakeCase(getPageTitle())}_filter_category`) ?? ""
    );
  }, []);

  React.useEffect(() => {
    if (!String(location.search ?? "").length) return;

    const searchParams = new URLSearchParams(location.search);
    const contractFromURL = searchParams.get("contract");
    const companyFromURL = searchParams.get("company");
    setCompany("");
    setContract("");
    if (contractFromURL) setContract(contractFromURL);
    else if (companyFromURL) setCompany(companyFromURL);
  }, [location.search]);
  const getCellCategoryType = (cellProfile) =>
    getCategoryType(
      getCategoryFromSlug(
        String(cellProfile?.category ?? "").toLowerCase(),
        appOptions
      )?.slug
    );

  const axiosOptions = (method, url) => {
    return {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: `${DEVICE_SERVICE_URL}/${url}`,
    };
  };

  React.useEffect(() => {
    if (!showMobileDataFilter) return;
    setFilterCategory(category);
  }, [showMobileDataFilter, category]);

  const handleResetFilter = () => {
    setFilterApplied(false);
    // setPageAction("first");
    setPageNumber(1);
    setMarkerId("");
    setCurrentPageNumber(1);
    setResetPagination(true);
    setCategory("");
    setFilterCategory("");
    removeFromStorage();
    showFilter(false);
  };

  const handleApplyFilter = () => {
    // setPageAction("first");
    setMarkerId("");
    setCurrentPageNumber(1);
    setPageNumber(1);
    setResetPagination(true);
    setCategory(filterCategory ?? "");
    setToStorage({
      [`${_.snakeCase(getPageTitle())}_filter_category`]: filterCategory,
      [`${_.snakeCase(getPageTitle())}_page_no`]: 1,
    });
    showFilter(false);
  };

  const handleCategoryFilterChange = (e) => {
    setFilterCategory(e);
  };

  const FilterSection = () => {
    return (
      <div className="advance-filter">
        <div>
          <span>Category</span>
          <div className="mb-2">
            <SelectApplicants
              searchByApplicantType={handleCategoryFilterChange}
              applicantType={filterCategory}
            />
          </div>
        </div>
      </div>
    );
  };

  const jumpToFirstPage = () => {
    setResetPagination(true);
    // setPageAction("first");
    setMarkerId("");
    setPageNumber(1);
  };
  const handleUpdateKey = () => {
    setUpdateKey((k) => k + 1);
  };
  React.useEffect(() => {
    if (personData?.length > 0) {
      setdisableExport(false);
    } else {
      setdisableExport(true);
    }
  }, [personData]);

  const viewDetails = (applicantData) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${applicantData?.person_id}&type=${String(
        applicantData?.category ?? ""
      )?.toLowerCase()}`,
      state: {
        from: String(history.location?.pathname ?? ""),
      },
    });
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewDetails(mode?.applicant);
    }
  };
  const doFilter = (pageNumber) => {
    setIsLoading(true);
    setResetPagination(false);

    if (!String(filterType ?? "").length || !String(rowsPerPage ?? "").length)
      return;

    if (String(category?.value ?? "").length) setFilterApplied(true);
    else setFilterApplied(false);

    const filterParams = new URLSearchParams();
    filterParams.append("module", filterType);
    if (String(searchQueryString ?? "").length)
      filterParams.append("search", searchQueryString);
    if (String(category?.value ?? "").length)
      filterParams.append("category", category?.label);
    if (String(contract ?? "").length)
      filterParams.append("contract", contract);
    else if (String(company ?? "").length)
      filterParams.append("company", company);
    const filter = filterParams.toString();

    setExportData(filter);
    axios(
      axiosOptions(
        "GET",
        `acs/events?page_number=${pageNumber}&page_size=${rowsPerPage}&${filter}`
      )
    )
      .then((response) => {
        setIsLoading(false);
        const {
          data: { data: personData, total: totalEntries },
        } = response;
        console.log(response);
        setpersonData(personData);
        setTotalEntries(totalEntries);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    doFilter(currentPageNumber);
  }, [rowsPerPage, pageNumber, updateKey, category, searchQueryString]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  const columns = [
    {
      name: "Full Name",
      selector: (person) => person?.personName,
      sortable: true,
      minWidth: 250,
      cell: (applicant) => (
        <ProfileCard
          data={applicant}
          mode={"peopleLive"}
          actions={userRoleDetails}
          actionsToParent={(mode) => getEvent({ mode, applicant })}
          cellMode={getCellCategoryType(applicant)}
        />
      ),
    },
    {
      name: "Category",
      sortable: false,
      hide: "md",
      minWidth: 200,
      cell: (applicant) => (
        <span className="text-capitalize">
          {formatTextWithFallback(
            _.replace(applicant?.category ?? "", "_", " ")
          )}
        </span>
      ),
    },
    {
      name: "Company",
      sortable: false,
      left: true,
      minWidth: "200px",
      selector: (person) => person?.company,
      hide: "md",
      cell: (applicant) => (
        <>
          <span className="text-uppercase">
            {formatTextWithFallback(applicant?.company)}
          </span>
        </>
      ),
    },
    {
      name: "Event Time",
      minWidth: 200,
      hide: "md",
      cell: (applicant) => (
        <span className="text-left">{`${getLocalDateTime(
          applicant?.event_time
        )}`}</span>
      ),
    },
    {
      name: "Actions",
      minWidth: 100,
      maxWidth: 100,
      hide: "md",
      sortable: false,
      cell: (applicant) => (
        <>
          {checkModuleViewPermission(
            userRoleDetails,
            getCellCategoryType(applicant),
            "profile",
            applicant
          ) && (
            <span
              className="simple-icon-eye user-role-action-icons view"
              onClick={() => viewDetails(applicant)}
            ></span>
          )}
        </>
      ),
      center: true,
    },
  ];

  const onChangePage = (page) => {
    // let pageActionType = currentPageNumber > page ? "previous" : "next";

    // if (page - 1 === currentPageNumber) {
    //   pageActionType = "next";
    // } else {
    //   if (page === 1) {
    //     pageActionType = "first";
    //   } else if (page === Math.ceil(totalEntries / rowsPerPage)) {
    //     pageActionType = "last";
    //   }
    // }
    // setPageAction(pageActionType);
    // const currentMarkerId =
    //   pageActionType === "next"
    //     ? personData[personData.length - 1]._id.$oid
    //     : personData[0]._id.$oid;
    // setMarkerId(currentMarkerId);
    doFilter(page);
    setCurrentPageNumber(page);
  };

  const onSearchInputChange = (searchQuery) => {
    setSearchQueryString(searchQuery);
    setToStorage(
      {
        [`${_.snakeCase(getPageTitle())}_filter_search`]: filterCategory,
      },
      true
    );
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage(
      { [`${_.snakeCase(getPageTitle())}_rows_per_page`]: rows },
      true
    );
    removeFromStorage(`${_.snakeCase(getPageTitle())}_page_no`, "onlyThis");
    setRowsPerPage(rows);
  };

  const onExport = () => {
    manageLoader(true);
    const options = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      url: `${apiRoutes.acsTransactionExport}?${exportData}`,
    };
    axios(options)
      .then((response) => {
        manageLoader(false);
        setShowSuccessModal(true);
        setShowSuccessMessage(response.data.data.message);
      })
      .finally(() => manageLoader(false));
  };

  const closeExportSuccessModal = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };

  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header withExport">
            <h1>{getPageTitle()}</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <div>
                    <FilterSection />

                    <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                      <button
                        className="btn btn-primary mr-2"
                        onClick={() => {
                          handleResetFilter();
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-secondary m-0"
                        onClick={() => {
                          handleApplyFilter();
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-secondary ml-2"
                onClick={onExport}
                disabled={disableExport}
              >
                Export
              </button>
              <div
                className={`tableFilterButton rightAlign ml-2  ${
                  filterApplied ? "btn-secondary" : ""
                }`}
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                isLoading
                  ? "card-body dataTableOuter cmpnyTable table-loader"
                  : "card-body dataTableOuter cmpnyTable"
              }
            >
              <DataTable
                subHeader={true}
                //subHeaderComponent={<> <button className="btn btn-primary">Export</button><SearchBar onSearchInputChange={onSearchInputChange} /></>}
                progressPending={isLoading}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={personData}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalEntries}
                paginationResetDefaultPage={resetPagination}
                expandableRows
                expandableRowsComponent={LivePeopleExpandable}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeExportSuccessModal}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LivePeopleListComponent;
