/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import _ from "lodash";
import {
  checkModuleViewPermission,
  formatTextWithFallback,
} from "../../helpers/helpers";
import Select from "react-select";

import { PPE_SERVICE_BASE_URL, apiRoutes } from "../../constants";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { AppContext } from "../../context/AppContext";
import useStorage from "../../helpers/useStorage";
import moment from "moment-timezone";
import usePPEChannelOptions from "../../components/common/CommonDeliverables/usePPECameraOptions";
import PPEEventViewModal from "../../components/PPEVideoModal/PpeEventViewModal";
import queryString from "query-string";
import { removeFromStorage } from "../../helpers/utils";
import { useToasts } from "react-toast-notifications";

const DailyPPEEventsListing = ({ location }) => {
  const { setToStorage, getFromStorage } = useStorage();
  const { addToast } = useToasts();
  const params = queryString.parse(location?.search ?? "");
  const getPageTitle = () => {
    switch (params?.listType) {
      case "channel":
        return "Channel Based Daily PPE Events Listing";
      default:
        return "Daily PPE Events Listing";
    }
  };

  const { userRoleDetails } = React.useContext(AppContext);
  const [updateKey, setUpdateKey] = React.useState(0);
  const [ppeEvents, setPPEEvents] = React.useState(null);
  const [eventsLoading, setEventsLoading] = React.useState(true);
  const [selectedPPEEvent, setSelectedPPEEvent] = React.useState(null);
  const { channels, getAllChannels, isChannelLoading } =
    usePPEChannelOptions(true);
  const [filterApplied, setFilterApplied] = React.useState(false);

  const [selectedChannel, setSelectedChannel] = React.useState(
    getFromStorage(`${_.snakeCase(getPageTitle())}_filter_channel`)
      ? JSON.parse(
          getFromStorage(`${_.snakeCase(getPageTitle())}_filter_channel`)
        )
      : undefined
  );
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    getFromStorage(`${_.snakeCase(getPageTitle())}_page_no`) ?? 1
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage(`${_.snakeCase(getPageTitle())}_rows_per_page`) ?? 10
  );

  const { manageLoader } = React.useContext(AppContext);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showDetailModal, setShowDetailModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const [disableExport, setdisableExport] = React.useState(true);

  const token = localStorage.getItem("access_token");

  const axiosOptions = (method, path, params) => {
    const url = new URL(`${PPE_SERVICE_BASE_URL}${path}`);
    if (params) {
      const urlParams = new URLSearchParams();
      Object.entries(params).forEach((paramItem) => {
        urlParams.append(paramItem[0] ?? "", paramItem[1] ?? "");
      });
      url.search = urlParams.toString();
    }
    return {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: url.toString(),
    };
  };

  let filter;

  const doFilter = useCallback(() => {
    setEventsLoading(true);
    filter = {
      mode: "daily",
      ...(selectedChannel !== null &&
        selectedChannel !== undefined &&
        selectedChannel !== "" && {
          channelId: [
            ...(selectedChannel ?? []).map((chnl) => chnl.value),
          ].join(","),
        }),
    };
    axios(
      axiosOptions("GET", apiRoutes.ppeEventReport, {
        ...(filter ? filter : {}),
        ...{
          page: currentPageNumber,
          limit: rowsPerPage,
        },
      })
    )
      .then((res) => setPPEEvents(res?.data))
      .finally(() => setEventsLoading(false));
  }, [selectedChannel, currentPageNumber, rowsPerPage]);

  useEffect(() => {
    getAllChannels();
  }, []);

  useEffect(() => {
    if (!updateKey) return;
    if (
      params?.listType === "channel"
        ? selectedChannel && selectedChannel?.length
        : true
    )
      doFilter();
    else setPPEEvents({ results: [], totalResults: 0 });
  }, [updateKey]);

  useEffect(() => {
    if (params?.listType === "channel" && params?.id && params?.id?.length) {
      const channelData = { label: "", value: params?.id };
      setSelectedChannel(channelData ? [channelData] : undefined);
    }
    handleUpdateKey();
  }, [currentPageNumber, rowsPerPage, location]);

  useEffect(() => {
    if (ppeEvents?.results?.length > 0) {
      setdisableExport(false);
    } else {
      setdisableExport(true);
    }
  }, [ppeEvents?.results]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewDetails(mode.ppeEvent);
    }
  };

  const viewDetails = (eventData) => {
    setSelectedPPEEvent(eventData);
    setShowDetailModal(true);
  };

  const handleCloseViewDetails = () => {
    setSelectedPPEEvent(null);
    setShowDetailModal(false);
  };

  const columns = [
    {
      name: "Event Time",
      selector: (person) => person?.timeStamp,
      sortable: true,
      cell: (ppeEvent) => (
        <ProfileCard
          data={ppeEvent}
          mode="ppe-event"
          cellMode={"ppe-event-report"}
          actions={userRoleDetails}
          fields={[
            {
              value: ppeEvent?.channelName,
              name: "Camera Name",
            },
            {
              value: ppeEvent?.eventComment,
              name: "Description",
            },
          ]}
          actionsToParent={(mode) => getEvent({ mode, ppeEvent })}
        />
      ),
    },
    {
      name: "Camera Name",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
      selector: (ppeEvent) => ppeEvent?.channelName,
      cell: (ppeEvent) => formatTextWithFallback(ppeEvent?.channelName),
      hide: "md",
    },
    {
      name: "Description",
      selector: (ppeEvent) => ppeEvent?.eventComment,
      cell: (ppeEvent) => formatTextWithFallback(ppeEvent?.eventComment),
      sortable: false,
      grow: 1,
      hide: "md",
    },
    {
      name: "Actions",
      sortable: false,

      center: true,
      maxWidth: 100,
      minWidth: 100,
      cell: (ppeEvent) => (
        <>
          {checkModuleViewPermission(
            userRoleDetails,
            "ppe-event-report",
            "report",
            ppeEvent
          ) && (
            <span
              className="simple-icon-eye user-role-action-icons view"
              onClick={() => viewDetails(ppeEvent)}
            ></span>
          )}
        </>
      ),
      right: true,
      hide: "md",
    },
  ];

  const onChangePage = (page) => {
    setCurrentPageNumber(page);
    setToStorage({ applicant_profile_report_page_no: page }, true);
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({ applicant_profile_report_rows_per_page: rows }, true);
    setRowsPerPage(rows);
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };

  const handleUpdateKey = () => {
    setUpdateKey((curr) => curr + 1);
  };

  const handleApplyFilter = () => {
    removeFromStorage();
    setToStorage(
      Object.fromEntries([
        [
          `${_.snakeCase(getPageTitle())}_filter_channel`,
          JSON.stringify(selectedChannel),
        ],
      ]),
      6000
    );
    handleUpdateKey();
    if (selectedChannel && selectedChannel?.length) setFilterApplied(true);
    else setFilterApplied(false);
    showFilter(false);
  };

  const handleResetFilter = () => {
    setSelectedChannel(undefined);
    setCurrentPageNumber(1);
    removeFromStorage();
    handleUpdateKey();
    setFilterApplied(false);
    showFilter(false);
  };

  const onExport = () => {
    manageLoader(true);

    if (ppeEvents?.results?.length > 0) {
      const exportFilter = {
        mode: "daily",
        ...(selectedChannel !== null &&
          selectedChannel !== undefined &&
          selectedChannel !== "" && {
            channelId: [
              ...(selectedChannel ?? []).map((chnl) => chnl.value),
            ].join(","),
          }),
      };
      axios(axiosOptions("GET", apiRoutes.ppeEventReportExport, exportFilter))
        .then((response) => {
          manageLoader(false);
          setShowSuccessModal(true);
          setShowSuccessMessage(
            response?.data?.data?.message ??
              "Request received. We will send the report to your email address."
          );
        })
        .catch((err) => {
          manageLoader(false);
          addToast(
            err?.response?.data?.message ??
              "Failed to export! Please try again later.",
            { appearance: "error" }
          );
        });
    } else {
      manageLoader(false);
      setShowSuccessModal(true);
      setShowSuccessMessage("There is no data to export");
    }
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  const FilterSection = () => {
    return (
      <div className="advance-filter">
        <div>
          <span>Camera</span>
          <div className="mb-2 mt-1">
            <Select
              className="cutomSelect"
              classNamePrefix="react-custom-select"
              value={selectedChannel}
              isMulti
              options={channels}
              onChange={(opts) => {
                setSelectedChannel(
                  opts && opts?.length ? [...opts] : undefined
                );
              }}
              name="channel"
              placeholder="Select"
              hideSelectedOptions
              defaultValue={null}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header withExport">
            <h1 className="mb-3 mb-md-0">{getPageTitle()}</h1>
            <div className="applicantFilter">
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <div>
                    <FilterSection />

                    <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                      <button
                        className="btn btn-primary mr-2"
                        onClick={() => {
                          handleResetFilter();
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-secondary m-0"
                        onClick={() => {
                          handleApplyFilter();
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-secondary ml-2"
                onClick={onExport}
                disabled={disableExport}
              >
                Export
              </button>
              {params?.listType !== "channel" && (
                <div
                  className={`tableFilterButton rightAlign ml-2  ${
                    filterApplied ? "btn-secondary" : ""
                  }`}
                  title="Advance Search"
                  onClick={() => showFilter(true)}
                >
                  <i className="iconsminds-filter-2"></i>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                eventsLoading
                  ? "card-body remove-header dataTableOuter reportTable table-loader"
                  : "card-body remove-header dataTableOuter reportTable"
              }
            >
              <DataTable
                subHeader={true}
                progressPending={eventsLoading}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={ppeEvents?.results}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={ppeEvents?.totalResults}
                paginationDefaultPage={currentPageNumber}
              />
            </div>
          </div>
        </div>
      </div>

      {showDetailModal && (
        <PPEEventViewModal
          data={selectedPPEEvent}
          onClose={handleCloseViewDetails}
        />
      )}

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DailyPPEEventsListing;
