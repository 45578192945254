import _ from "lodash";
import { getLastMonths } from "../helpers/utils";
//manage slide data group by 10

export const manageSlidesData = (data) => {
  let slicedArray = [];
  if (data === undefined) {
    return [];
  }
  const rowsCount = Math.ceil(data.length / 9);
  for (let i = 1; i <= rowsCount; i++) {
    if (i === rowsCount) {
      slicedArray.push(data.slice((i - 1) * 9, data.length));
    } else {
      slicedArray.push(data.slice((i - 1) * 9, i * 9));
    }
  }
  return slicedArray;
};

export const manageCompanySlidesData = (data) => {
  let slicedArray = [];
  if (data === undefined) {
    return [];
  }
  const rowsCount = Math.ceil(data.length / 4);
  for (let i = 1; i <= rowsCount; i++) {
    if (i === rowsCount) {
      slicedArray.push(data.slice((i - 1) * 4, data.length));
    } else {
      slicedArray.push(data.slice((i - 1) * 4, i * 4));
    }
  }
  return slicedArray;
};

export const mapThreeMonthsVisitData = (data) => {
  let plotValues = [];
  const colors = {
    "Staff": "#ff816b",
    "Delivery Adhoc": "#3dc0bb",
    "Frequent Delivery": "#2979dc",
    "Visitor": "#e673ec",
  };
  let responseData = {};
  let last3Months = getLastMonths(3).map((item) => item?.substr(0, 3));
  responseData["labels"] = last3Months.reverse();
  const plotData = (visitsData) => {
    return _.map(last3Months, (month) => {
      const visitData = _.keyBy(visitsData, "monthString");
      plotValues.push(visitData[month] ? visitData[month].visits : 0);
      return visitData[month] ? visitData[month].visits : 0;
    });
  };
  const dataSets = Object.entries(data).map(([key, value]) => {
    return {
      label: key,
      borderColor: colors[key],
      backgroundColor: colors[key],
      data: (value && value?.length) ? plotData(value) : _.fill(Array(last3Months?.length ?? 0), 0),
      borderWidth: 2,
    };
  });
  responseData["datasets"] = dataSets.sort((a, b) => b.label.localeCompare(a.label));
  return {
    maxValue: _.max(plotValues),
    graphData: responseData,
  };
};

export const mapWeeklyVisitData = (data) => {
  let plotValues = [];

  const colors = {
    "Staff": "#ff816b",
    "Delivery Adhoc": "#3dc0bb",
    "Frequent Delivery": "#2979dc",
    "Visitor": "#e673ec",
  };

  let responseData = {};

  let firstFilledKey;
  for (const key in data) {
    if (data[key] && data[key]?.length) {
      firstFilledKey = key;
      break;
    }
  }

  const firstItem = data[firstFilledKey];
  const labels = firstItem ? firstItem.map((item)=> {
    return `${item.date}(${item.day})`;
  }) : [];

  const plotData = (visitsData) => {
    return _.map(visitsData, (visitData) => {
      plotValues.push(visitData.visits);
      return visitData.visits;
    });
  };

  const dataSets = Object.entries(data).map(([key, value]) => {
    return {
      label: key,
      data: (value && value?.length) ? plotData(value) : _.fill(Array(labels?.length ?? 0), 0),
      borderColor: colors[key],
      pointBackgroundColor: "#ffffff",
      pointBorderColor: colors[key],
      pointHoverBackgroundColor: colors[key],
      pointHoverBorderColor: "#ffffff",
      pointRadius: 6,
      pointBorderWidth: 2,
      pointHoverRadius: 8,
      fill: false,
    };
  });

  responseData["datasets"] = dataSets;
  responseData["labels"] = labels?.length > 0 ? labels : [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
  ];
  return {
    maxValue: _.max(plotValues),
    graphData: responseData,
  };
};
