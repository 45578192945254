/* eslint react-hooks/exhaustive-deps: 0 */

import React, { useContext, useRef, useState } from "react";
import { CiCreditCard1 } from "react-icons/ci";
import moment from "moment-timezone";
import DataTable from "react-data-table-component";

import SearchBar from "../../components/common/Search/Search";

import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { DEVICE_SERVICE_URL, APP_TIMEZONE } from "../../constants";
import noPreview from "../../assets/images/nopreview.png";
import {
  removeFromStorage,
} from "../../helpers/utils";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import LPRTransactionReportExpansion from "../Reports/components/LPRTransactionReportExpansion.component";
import axios from "axios";
import CustomCalendar from "../../components/CustomCalender";
import ReactTooltip from "react-tooltip";
import ExportButton from "../../components/common/Export/Export";
import { formatTextWithFallback, getLocalDateTime } from "../../helpers/helpers";
import { AppContext } from "../../context/AppContext";
import useStorage from "../../helpers/useStorage";

const OverstayVehicleList = ({ location }) => {
  const { setToStorage, getFromStorage } = useStorage();
  const { userRoleDetails } = useContext(AppContext);

  const [isLoading, setIsLoading] = React.useState(true);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [resetPagination, setResetPagination] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("lpr_live_live_filter_rows_per_page") ?? 10
  );
  const [showDialog, setShowDialog] = useState(false);
  const [exportData, setExportData] = useState({ page: "lpr" });
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);
  const [totalEntries, setTotalEntries] = React.useState(0);
  const [markerId, setMarkerId] = React.useState("");
  const [pageAction, setPageAction] = React.useState("first");
  const [searchQueryString, setSearchQueryString] = useState(
    getFromStorage("lpr_live_live_filter_filter_search") ?? ""
  );

  const [crossTimeFrom, setcrossTimeFrom] = React.useState("");
  const [crossTimeTo, setcrossTimeTo] = React.useState("");
  const [crossTimeFromFilter, setcrossTimeFromFilter] = React.useState("");
  const [crossTimeToFilter, setcrossTimeToFilter] = React.useState("");
  const [lprReport, setlprReport] = React.useState([]);
  const crossTimeFromRef = useRef(null);
  const crossTimeToRef = useRef(null);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const axiosOptions = (method, url) => {
    return {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: `${DEVICE_SERVICE_URL}/${url}`,
    };
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  const FilterSection = () => {
    return (
      <div className="advance-filter">
        <div onClick={(e) => e.stopPropagation()}>
          <div className="mb-2">
            <span>Event Time From</span>
            <CustomCalendar
              maxDate={moment().toDate()}
              // showTimeSelect
              dateFormat="YYYY-MM-DD hh:mm A"
              timePicker
              disableOnChange={false}
              ref={crossTimeFromRef}
              selected={
                crossTimeFrom && crossTimeFrom === ""
                  ? ""
                  : new Date(crossTimeFrom)
              }
              onChange={(date) =>
                setcrossTimeFrom(
                  date === null
                    ? moment().format("YYYY-MM-DD HH:mm")
                    : moment(date).format("YYYY-MM-DD HH:mm")
                )
              }
            />
          </div>
        </div>
        <div onClick={(e) => e.stopPropagation()}>
          <div className="mb-2">
            <span>Event Time To</span>
            <CustomCalendar
              maxDate={moment().toDate()}
              minDate={new Date(crossTimeFrom)}
              dateFormat="YYYY-MM-DD hh:mm A"
              ref={crossTimeToRef}
              disableOnChange
              selected={
                crossTimeTo && crossTimeTo === "" ? "" : new Date(crossTimeTo)
              }
              onChange={(date) =>
                setcrossTimeTo(
                  date === null
                    ? moment().format("YYYY-MM-DD HH:mm")
                    : moment(date).format("YYYY-MM-DD HH:mm")
                )
              }
              timePicker
            />
          </div>
        </div>
      </div>
    );
  };
  const applyFilter = () => {
    setcrossTimeToFilter(crossTimeToRef.current.handleFetchDate());
    setcrossTimeTo(crossTimeToRef.current.handleFetchDate());
    setcrossTimeFromFilter(crossTimeFromRef.current.handleFetchDate());
    setcrossTimeFrom(crossTimeFromRef.current.handleFetchDate());

    setPageAction("first");
    setMarkerId("");

    setCurrentPageNumber(1);

    setResetPagination(true);
    setFilterApplied(true);
  };
  const doFilter = () => {
    setIsLoading(true);
    setResetPagination(false);

    let filterItems = {
      ...(searchQueryString && searchQueryString !== ""
        ? { search: searchQueryString }
        : {}),
      ...(crossTimeFromFilter && crossTimeFromFilter !== ""
        ? {
            crossTimeFrom: moment
              .tz(crossTimeFromFilter, "YYYY-MM-DD HH:mm:ss", APP_TIMEZONE)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
          }
        : {}),
      ...(crossTimeToFilter && crossTimeToFilter !== ""
        ? {
            crossTimeTo: moment
              .tz(crossTimeToFilter, "YYYY-MM-DD HH:mm:ss", APP_TIMEZONE)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
          }
        : {}),
      module: "over_stay",
    };

    let filter = "";
    for (const [, [key, value]] of Object.entries(
      Object.entries(filterItems)
    )) {
      if (value !== "" && value) {
        filter += `&${key}=${value}`;
      }
    }
    if (pageAction !== "first" && pageAction !== "last") {
      filter += `&markerId=${markerId}`;
    }
    setExportData({ ...exportData, filter: filterItems });

    axios(
      axiosOptions(
        "GET",
        `lpr/events?pageAction=${pageAction}&pageSize=${rowsPerPage}${filter}`
      )
    )
      .then((response) => {
        setIsLoading(false);
        const {
          data: {
            data: lprTransactionReportData,
            meta: {
              pagination: { count: totalEntries },
            },
          },
        } = response;
        setlprReport(lprTransactionReportData);
        setTotalEntries(totalEntries);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);
  React.useEffect(() => {
    doFilter();
  }, [
    currentPageNumber,
    rowsPerPage,
    pageAction,
    searchQueryString,
    crossTimeFromFilter,
    crossTimeToFilter,
  ]);
  const token = localStorage.getItem("access_token");

  const handleResetFilter = () => {
    setPageAction("first");
    crossTimeFromRef.current.handleChange(null);
    crossTimeToRef.current.handleChange(null);
    setMarkerId("");
    setcrossTimeFrom("");
    setcrossTimeTo("");
    setcrossTimeToFilter("");
    setcrossTimeFromFilter("");

    setSearchQueryString("");
    setCurrentPageNumber(1);
    // showFilter(false);
    setResetPagination(true);

    removeFromStorage(["lpr_live_page_no", "lpr_live_rows_per_page"]);
    setFilterApplied(false);
  };

  const getEvent = (mode) => {
    if (mode.mode === "plate") {
      onPictureClick(
        mode.lprReport.plateNoPicUri,
        "Plate No",
        mode.lprReport.isImagesUploaded &&
          mode?.lprReport?.plateNoPicUri &&
          typeof mode.lprReport.plateNoPicUri === "string" &&
          mode.lprReport.plateNoPicUri !== ""
          ? true
          : false
      );
    }
  };
  const columns = [
    {
      name: "Plate No.",
      selector: (lprReport) => lprReport?.plateNo,
      minWidth: "200px",
      sortable: true,
      cell: (lprReport) => (
        <ProfileCard
          data={lprReport}
          mode="lprReport"
          actions={userRoleDetails}
          fields={[
            { name: "Plate No.", value: formatTextWithFallback(lprReport.plateNo) },
            { name: "Event Time", value: getLocalDateTime(lprReport?.crossTime)},
            { name: "Roadway ", value: formatTextWithFallback(lprReport.roadwayName) },
            { name: "Entrance ", value: formatTextWithFallback(lprReport.entranceName) },
          ]}
          actionsToParent={(mode) => getEvent({ mode, lprReport })}
        />
      ),
    },

    {
      name: "Roadway",
      minWidth: "200px",
      selector: (lprReport) => lprReport?.roadwayName,
      sortable: false,
      hide: "md",
      cell: (lprReport) => formatTextWithFallback(lprReport?.roadwayName)
    },

    {
      name: "Entrance",
      minWidth: "200px",
      selector: (lprReport) => lprReport?.entranceName,
      sortable: false,
      hide: "md",
      cell: (lprReport) => formatTextWithFallback(lprReport?.entranceName)
    },
    {
      name: "Event Time",
      minWidth: 200,
      selector: (lprReport) => lprReport?.crossTime,
      hide: "md",
      style: { position: "sticky", right: 100 },
      cell: (staff) => getLocalDateTime(staff?.crossTime),
    },
    {
      name: "Actions",
      sortable: false,
      selector: (lprReport) => lprReport?.plateNo,
      style: { position: "sticky", right: 0 },
      center: true,
      maxWidth: 100,
      minWidth: 100,
      cell: (staff) => (
        <div className="actionColumn">
          <CiCreditCard1
            data-tip="View Plate no."
            className=" user-role-action-icons view mr-1"
            onClick={() =>
              onPictureClick(
                staff.plateNoPicUri,
                "Plate No",
                staff.isImagesUploaded &&
                  staff.plateNoPicUri &&
                  typeof staff.plateNoPicUri === "string" &&
                  staff.plateNoPicUri !== ""
                  ? true
                  : false
              )
            }
          />
          <ReactTooltip className="table-tooltip" />
        </div>
      ),
      right: true,
      hide: "md",
    },
  ];
  const onChangePage = (page) => {
    let pageActionType = currentPageNumber > page ? "previous" : "next";

    if (page - 1 === currentPageNumber) {
      pageActionType = "next";
    } else {
      if (page === 1) {
        pageActionType = "first";
      } else if (page === Math.ceil(totalEntries / rowsPerPage)) {
        pageActionType = "last";
      }
    }

    setPageAction(pageActionType);
    const currentMarkerId =
      pageActionType === "next"
        ? lprReport[lprReport.length - 1]._id.$oid
        : lprReport[0]._id.$oid;
    setMarkerId(currentMarkerId);

    setCurrentPageNumber(page);
  };

  const onSearchInputChange = (searchQuery) => {
    setSearchQueryString(searchQuery);
    setPageAction("first");
    setCurrentPageNumber(1);
    setResetPagination(true);
    // doFilter();
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({"lpr_live_rows_per_page" : rows},false);
    removeFromStorage("lpr_live_page_no");
    setRowsPerPage(rows);
  };

  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    setDialogContent(null);
    setDialogTitle(null);
  };
  function imageDialogContentGenVehicle(data, n, f) {
    let imageSrc = data;

    return (
      <div className="d-flex justify-content-center align-items-center">
        <img
          src={f ? imageSrc : noPreview}
          alt={n ?? ""}
          height={f ? "100%" : "150px"}
        />
      </div>
    );
  }
  const onPictureClick = (e, n, f) => {
    setDialogTitle(n);
    setDialogContent(imageDialogContentGenVehicle(e, n, f));
    setShowDialog(true);
  };

  return (
    <>
      <ModalComponent
        showSubmit={true}
        content={dialogContent}
        header={dialogTitle}
        show={showDialog}
        hideCancel
        onSubmitCallback={handleCloseDialog}
        onCloseCallback={handleCloseDialog}
        submitButtonText="Close"
      />
      <div className="row">
        <div className="col-12">
          <div className="page-header withExport">
            <h1>Vehicles Overstay</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div className="mr-2">
                <ExportButton
                  exportDetails={exportData}
                  disableExport={lprReport?.length > 0 ? false : true}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <div>
                    <FilterSection />

                    <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                      <button
                        className="btn btn-primary mr-2"
                        onClick={(e) => {
                          handleResetFilter(e);
                        }}
                      >
                        Reset
                      </button>
                      <button
                        className="btn btn-secondary m-0"
                        onClick={(e) => {
                          applyFilter(e);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`tableFilterButton rightAlign  ${
                  filterApplied ? "btn-secondary" : ""
                }`}
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                {/* <span></span>
             <span></span>
             <span></span> */}
                <i className="iconsminds-filter-2"></i>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={`hasStickLastColumn hasStickyAction
                 ${
                   isLoading
                     ? "card-body remove-header dataTableOuter reportTable table-loader"
                     : "card-body remove-header dataTableOuter reportTable"
                 }`}
            >
              <DataTable
                subHeader={true}
                progressPending={isLoading}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={lprReport}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalEntries}
                paginationResetDefaultPage={resetPagination}
                expandableRows
                expandableRowsComponent={LPRTransactionReportExpansion }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverstayVehicleList;
