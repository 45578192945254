import React, { useContext, useEffect } from "react";
import _ from "lodash";
import { VW_ROUTES, apiRoutes } from "../../../../constants";
import { useClient, useQuery } from "jsonapi-react";
import { useHistory } from "react-router-dom";
import { getIcon, getKeyName } from "../../../../helpers/utils";
import ReactTooltip from "react-tooltip";
import { checkModuleGeneralPermission, isWidgetRefreshable } from "../../../../helpers/helpers";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "../../../../context/AppContext";

const DashboardCounterComponent = ({ wData, slug, title }) => {
  const token = localStorage.getItem("access_token");
  const client = useClient();
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const [widgetData, setWidgetData] = React.useState();

  const apiUrl = wData.widget.config.dataSource === "external" ? wData.widget.config.url : apiRoutes.dashboardWidgetsData + "/" + slug;
  const getData = useQuery([apiUrl], {
    client,
  });

  const updateData = () => {
    setIsUpdateLoading(true);
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    Axios.get(apiUrl + "/update", config)
      .then((response) => {
        addToast(response?.data?.data?.message ?? "Widget updated Successfully!", { appearance: "success" });
      })
      .catch((error) => {
        addToast(error.detail ?? "Failed to update widget!", { appearance: "error" });
      }).finally(() => setIsUpdateLoading(false));
  }

  React.useEffect(() => {
    if (getData) {
      setWidgetData(getData.data);
    }
  }, [getData]);

  React.useEffect(() => {
    if (widgetData?.config?.refreshRate > 0) {
      const timeoutId = setInterval(() => {
        getData.refetch();
      }, widgetData?.config?.refreshRate * 1000);
      return () => {
        clearInterval(timeoutId); // Clear the timeout if the component is unmounted or the effect is cleaned up
      };
    }
  }, [widgetData?.config?.refreshRate]);

  const formatType = (typeValue) => _.snakeCase(typeValue).split("_").join("-");

  const history = useHistory();
  const navigateToList = (type) => {
    if (slug === "access-pass-pending-approval") {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_PENDING,
        search: `?type=${type}`,
      });
    } else if (slug === "work-permit-expiry") {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING,
        search: `?type=${type}&list=permit-expiring&page=1`,
      });
    } else if (slug === "expired-work-permits") {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING,
        search: `?type=${type}&list=permit-expired&page=1`,
      });
    } else if (slug === "access-pass-expiry") {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_VALIDITY_EXPIRY_STATUS_LISTING,
        search: `?type=${formatType(type)}&page=1`,
      });
    } else if (slug === "cidb-expiry-stats") {
      history.push({
        pathname: VW_ROUTES.PERSON_PROFILE_CIDB_EXPIRY_LISTING,
        search: `?type=${formatType(type)}&list=cidb-expiry&page=1`,
      });
    }
  };

  const getToolTip = (slug) => {
    switch (slug) {
      case "work-permit-expiry":
        return "Work Permits expiring within one month";
      case "access-pass-expiry":
        return "Access passes expiring within one month";
        case "cidb-expiry-stats":
          return "CIDB expiring within one month";
      default:
        return undefined;
    }
  };

  return (
    <div className="card right-notification">
      <div className="card-header">
        <span>{title}</span>
        {isWidgetRefreshable(userRoleDetails, widgetData) && 
          <span 
            onClick={() => {!isupdateLoading && updateData()}}
            className={`refresh-button ${isupdateLoading ? "spin" : ""}`} 
            data-tip="Update Data">
              <i className="iconsminds-refresh" />
          </span>
        }
      </div>
      <ReactTooltip />
      <div className="expiry-card-body">
        <div className="row">
          {widgetData?.widgetData ? (
            Object.keys(widgetData?.widgetData).map((key, index) => {
              return (
                <div className="col-sm-12 mb-2" key={key}>
                  <div className="inner-card">
                    <div className="text" onClick={() => navigateToList(key)}>
                      <i className={getIcon(key)}></i>
                      <p className="card-text mb-0">{getKeyName(key)}</p>
                      <p
                        data-tip={getToolTip(slug)}
                        className={
                          slug === "expired-work-permits"
                            ? "count text-center red"
                            : "count text-center"
                        }
                        currentitem="false"
                      >
                        {widgetData?.widgetData[key]}
                      </p>
                    </div>
                    <ReactTooltip />
                  </div>
                </div>
              );
            })
          ) : (
            <>
              <div className="col-sm-12 mb-2">
                <div className="skeleton w-80"></div>
              </div>

              <div className="col-sm-12 mb-2">
                <div className="skeleton w-50"></div>
              </div>

              <div className="col-sm-12 mb-2">
                <div className="skeleton w-80"></div>
              </div>
              <div className="col-sm-12 mb-2">
                <div className="skeleton w-80"></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default DashboardCounterComponent;
