import React from "react";
import "./ModalRightComponent.scss";

const ModalRightComponent = ({
  show,
  header,
  content,
  classes,
  submitButtonText,
  onSubmitCallback,
  onCloseCallback,
  hideClose,
}) => (
  <div
    className={`modal fade modal-right ${classes} ${
      show ? "show d-block" : ""
    }`}
    id=""
    tabIndex="-1"
    role="dialog"
    aria-labelledby="rightModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="rightModalLabel">
            {header}
          </h5>
          {!hideClose &&(
            <button type="button" className="close" onClick={onCloseCallback}>
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div className="modal-body">{content}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={onCloseCallback}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onSubmitCallback}
            className={`btn btn-secondary`}
          >
            {submitButtonText}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ModalRightComponent;
