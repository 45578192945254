import _ from "lodash";
import { VW_ROUTES } from "../constants";
import { getActivePageCode, getDefaultMenuItem } from "../helpers/helpers";

export const ACTION_TYPES = {
  UPDATE_USER: "updateUser",
  HIDE_MAIN_SIDEBAR: "hideMainSidebar",
  HIDE_SUB_SIDEBAR: "hideSubSidebar",
  UPDATE_LAST_ROUTE_STATES: "updateLastRouteStates",
  SET_LAST_ROUTE_STATES: "setLastRouteStates",
  UPDATE_NOTIFICATIONS: "updateNotifications",
  UPDATE_COMPANY_CONFIG: "updateCompanyConfig",
  MANAGE_MODAL_BACKDROP: "manageModalBackDrop",
  SHOW_RIGHT_MODAL: "showRightModal",
  SHOW_MODAL: "showModal",
  UPDATE_FILE_ALARM_COUNTER: "updateFileAlarmCounter",
  UPDATE_USER_PERMISSIONS: "updateUserPermissions",
  SET_ACTIVE_PAGE_CODE: "setActivePageCode",
  OVERIDE_ACTIVE_PAGE_CODE: "overideActivePageCode",
  UPDATE_PAGE_PERMISSIONS: "updatePagePermissions",
  UPDATE_USER_ROLE_DETAILS: "updateUserRoleDetails",
  MANAGE_LOADER: "manageLoader",
  UPDATE_INITIAL_PAGE_PARAMS: "updateInitialPageParams",
  SWITH_THEME: "switchTheme",
  UPDATE_USER_LIMIT: "updateUserLimit",
  UPDATE_APPLICANT_LIMIT: "updateUserLimit",
  UPDATE_SELECTED_ROWS: "updateSelectedRows",
  UPDATE_ARCHIVE_RESULT: "updateARchiveResult",
  UPDATE_APP_OPTIONS: "updateAppOptions",
  SHOW_BAN_MODAL: "showBanModal",
  UPDATE_FILTER_PARAMS: "updateFilterParams",
};

export const AppReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ACTION_TYPES.UPDATE_COMPANY_CONFIG:
      return {
        ...state,
        companyConfig: action.payload,
      };
    case ACTION_TYPES.HIDE_MAIN_SIDEBAR:
      return {
        ...state,
        mainSidebarHidden: action.payload,
      };
    case ACTION_TYPES.HIDE_SUB_SIDEBAR:
      return {
        ...state,
        subSidebarHidden: action.payload,
      };
    case ACTION_TYPES.UPDATE_LAST_ROUTE_STATES:
      return {
        ...state,
        lastRouteStates: {
          ...state.lastRouteStates,
          ...{ [String(action?.payload?.pathname ?? "")]: action.payload },
        },
      };
    case ACTION_TYPES.SET_LAST_ROUTE_STATES:
      return {
        ...state,
        lastRouteStates: { ...(action.payload ?? {}) },
      };
    case ACTION_TYPES.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };

    case ACTION_TYPES.SHOW_RIGHT_MODAL:
      return {
        ...state,
        showRightModal: action.payload,
        showModalBackDrop: action.payload,
      };
    case ACTION_TYPES.SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload,
        showModalBackDrop: action.payload,
      };
    case ACTION_TYPES.UPDATE_FILE_ALARM_COUNTER:
      return {
        ...state,
        fireAlarmCounter: action.payload,
      };
    case ACTION_TYPES.UPDATE_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    case ACTION_TYPES.SET_ACTIVE_PAGE_CODE:
      return {
        ...state,
        activePageCode: getActivePageCode(action.payload?.menuItems, action.payload?.pathname),
        activePagePath: action?.payload?.pathname
      };
    case ACTION_TYPES.OVERIDE_ACTIVE_PAGE_CODE:
      return {
        ...state,
        activePageCode: action?.payload?.code,
        activePagePath: action?.payload?.path
      };
    case ACTION_TYPES.UPDATE_PAGE_PERMISSIONS:
      const { permissions, pathname } = action.payload;
      const pagePermissions =
        _.keyBy(permissions, "route")[_.invert(VW_ROUTES)[pathname]] &&
        _.keyBy(permissions, "route")[_.invert(VW_ROUTES)[pathname]].actions;
      return {
        ...state,
        pagePermissions,
      };
    case ACTION_TYPES.UPDATE_USER_ROLE_DETAILS:
      return {
        ...state,
        userRoleDetails: action.payload,
        defaultMenuItem: _.values(action?.payload?.menuItems ?? {})?.length
          ? getDefaultMenuItem(_.values(action?.payload?.menuItems)[0] ?? {})
          : "",
      };
    case ACTION_TYPES.MANAGE_LOADER:
      return {
        ...state,
        showLoader: action.payload,
      };

    case ACTION_TYPES.UPDATE_INITIAL_PAGE_PARAMS:
      return {
        ...state,
        initialPageParams: action.payload,
      };

    case ACTION_TYPES.SWITH_THEME:
      return {
        ...state,
        theme: action.payload,
      };

    case ACTION_TYPES.UPDATE_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: action.payload,
      };

    case ACTION_TYPES.UPDATE_ARCHIVE_RESULT:
      return {
        ...state,
        archiveResult: action.payload,
      };

    case ACTION_TYPES.UPDATE_APP_OPTIONS:
      return {
        ...state,
        appOptions: action.payload,
      };
    case ACTION_TYPES.SHOW_BAN_MODAL:
      return {
        ...state,
        showBanModal: action.payload,
      };
    case ACTION_TYPES.UPDATE_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.payload,
      };
    default:
      return state;
  }
};
