/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from "jsonapi-react";
import DataTable from "react-data-table-component";
import { AppContext } from "../../context/AppContext";
import _ from "lodash";
import { addBodyClass, removeBodyClass } from "../../helpers/utils";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { getIdNumber } from "../../helpers/helpers";
import { useHistory } from "react-router-dom";
import { VW_ROUTES, apiRoutes } from "../../constants";
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";
import { API_BASE_URL } from "../../constants";
import axios from "axios";
import SelectApplicants from "../../components/common/SelectApplicants/SelectApplicants";

const ArchivedApplicants = () => {
  const { addToast } = useToasts();
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  let page;

  const pageNumber = window.location.hash.split("page=")[1];
  if (pageNumber) {
    page = Number(pageNumber.split("&")[0]);
  } else {
    page = 1;
  }

  let initialSearchKey;
  const searchWord = window.location.hash.split("search=")[1];
  if (searchWord) {
    initialSearchKey = decodeURI(searchWord);
  } else {
    initialSearchKey = "";
  }

  let initialApplicantType;
  const applicantTypeUrl = window.location.hash.split("applicant=")[1];
  if (applicantTypeUrl) {
    const applicant = applicantTypeUrl.split("&")[0];
    initialApplicantType = decodeURI(applicant);
  } else {
    initialApplicantType = undefined;
  }

  let initialfilterWithPhotos;
  const filterWithPhotosUrl = window.location.hash.split("profilePic=")[1];
  if (filterWithPhotosUrl) {
    initialfilterWithPhotos = "True";
  } else {
    initialfilterWithPhotos = "False";
  }

  const { showModal, manageModal } = React.useContext(AppContext);

  const [currentUserId, setCurrentUserId] = React.useState(0);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(page);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchQueryString, setSearchQueryString] =
    React.useState(initialSearchKey);
  const [applicantType, setApplicantType] =
    React.useState(initialApplicantType);
  const [filterWithPhotos, setFilterWithPhotos] = React.useState(
    initialfilterWithPhotos
  );

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(page);
  const [toRestoreApplicants, setToRestoreApplicants] = React.useState([]);
  const [modelFor, setModelFor] = React.useState("");
  const [dataProcessing, setDataProcessing] = React.useState(false);

  const [toggledClearRows, setToggleClearRows] = React.useState(false);

  const token = localStorage.getItem("access_token");
  const history = useHistory();
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  const applicants = useQuery(
    [
      apiRoutes.createProfileData,
      {
        filter: {
          type: applicantType,
          search: searchQueryString,
          status: "archived",
        },
        page: paginationSettings,
        include: "applicant_photo",
      },
    ],
    { client }
  );

  const deleteUserDetails = async () => {
    setDataProcessing(true);
    const { error } = await client.delete([
      apiRoutes.updateProfileData.replace("{personId}", currentUserId)
    ]);
    if (error === undefined) {
      applicants.refetch();
      addToast(DISPLAY_TEXTS.PERSON_PROFILE_DELETED, { appearance: "success" });
      setDataProcessing(false);
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      setDataProcessing(false);
    }
    setCurrentUserId(0);
    manageModal(false);
    removeBodyClass("modal-open");
  };

  const confirmAction = () => {
    if (modelFor === "delete") {
      deleteUserDetails();
    } else {
      confirmRestoreApplicants();
    }
  };

  const manageDeletedOption = (id) => {
    setModelFor("delete");
    setCurrentUserId(id);
    addBodyClass("modal-open");
    manageModal(true);
  };

  const manageRestoreOption = (id) => {
    setModelFor("restore");
    setToRestoreApplicants([id]);
    addBodyClass("modal-open");
    manageModal(true);
  };

  const viewApplicant = (id, type) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${id}&type=${type}&from=all_applicants`,
      state: {
        from: history.location?.pathname
      }
    });
  };

  const doCloseModal = () => {
    setCurrentUserId(0);
    removeBodyClass("modal-open");
    manageModal(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  // const updateApplicant = (id, type) => {
  //   const routeArray = {
  //     staff: VW_ROUTES.APPLICANTS_STAFF_EDIT,
  //     visitor: VW_ROUTES.APPLICANTS_VISITIOR_EDIT,
  //     delivery_regular: VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_EDIT,
  //     delivery_adhoc: VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_EDIT
  //  };
  //   history.push({
  //     pathname: routeArray[type],
  //     search: `?id=${id}&from=all_applicants`,
  //   });
  // };

  const searchByApplicantType = (e) => {
    setApplicantType(e.target.value === "" ? undefined : e.target.value);
    if (e.target.value !== "") {
      history.push({
        search: `?page=${1}${
          e.target.value ? "&applicant=" + encodeURI(e.target.value) : ""
        }${filterWithPhotos === "True" ? "&profilePic=True" : ""}${
          searchQueryString ? "&search=" + encodeURI(searchQueryString) : ""
        }`,
      });
    } else {
      history.push({
        search: `?page=${1}${
          filterWithPhotos === "True" ? "&profilePic=True" : ""
        }${searchQueryString ? "&search=" + encodeURI(searchQueryString) : ""}`,
      });
    }
    setToggleClearRows(!toggledClearRows);
  };

  const FilterSection = () => {
    return (
      <div className="advance-filter mr-1">
        <SelectApplicants
          searchByApplicantType={(e) => searchByApplicantType(e)}
          applicantType={applicantType}
        />
      </div>
    );
  };

  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  const columns = [
    {
      name: "Full Name",
      selector: "fullName",
      sortable: true,
      cell: (applicant) => (
        <span className="text-capitalize">{`${applicant.fullName}`}</span>
      ),
    },

    {
      name: "ID Number",
      sortable: false,
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {getIdNumber(applicant)}
        </span>
      ),
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    {
      name: "Designation",
      selector: "designation",
      sortable: true,
    },
    {
      name: "Applicant Type",
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {_.replace(applicant.applicantType, "_", " ")}
        </span>
      ),
    },

    {
      name: "Profile Pic",
      sortable: false,
      center: true,
      cell: (applicant) => (
        <div className="actionContainer text-center">
          {applicant.applicantPhoto.length ? (
            <span className="approve-reject approve simple-icon-check m-auto"></span>
          ) : (
            <span className="approve-reject reject simple-icon-close m-auto"></span>
          )}
        </div>
      ),
    },
    {
      name: "Actions",
      sortable: false,
      cell: (applicant) => (
        <>
          <span
            className="simple-icon-eye user-role-action-icons view"
            onClick={() => viewApplicant(applicant.id, applicant.applicantType)}
          ></span>

          <span
            title="Restore"
            onClick={() => manageRestoreOption(applicant.id)}
            className="simple-icon-refresh user-role-action-icons refresh"
          ></span>

          <span
            title="Permenent Delete"
            onClick={() => manageDeletedOption(applicant.id)}
            className="simple-icon-trash user-role-action-icons delete"
          ></span>
        </>
      ),
      right: true,
    },
  ];

  let paginationGoing = false;

  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    if (page > 1) {
      history.push({
        search: `?page=${page}${
          applicantType ? "&applicant=" + applicantType : ""
        }${filterWithPhotos === "True" ? "&profilePic=True" : ""}${
          searchQueryString ? "&search=" + encodeURI(searchQueryString) : ""
        }`,
      });
    } else {
      history.push({
        search: `${applicantType ? "?applicant=" + applicantType : ""}${
          filterWithPhotos === "True" ? "&profilePic=True" : ""
        }${searchQueryString ? "&search=" + encodeURI(searchQueryString) : ""}`,
      });
    }
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setactivePage(1);
    if (searchQuery) {
      history.push({
        search: `?page=${1}${
          applicantType ? "&applicant=" + applicantType : ""
        }${
          filterWithPhotos === "True" ? "&profilePic=True" : ""
        }&search=${encodeURI(searchQuery)}`,
      });
    } else {
      history.push({
        search: `?page=${1}${
          applicantType ? "&applicant=" + applicantType : ""
        }${filterWithPhotos === "True" ? "&profilePic=True" : ""}`,
      });
    }
    setToggleClearRows(!toggledClearRows);
    setShowMobileDataFilter(false);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }

      const applicantType = window.location.hash.split("applicant=")[1];
      if (applicantType) {
        const applicant = applicantType.split("&")[0];
        setApplicantType(applicant);
      } else {
        setApplicantType(undefined);
      }

      const filterWithPhotosUrl = window.location.hash.split("profilePic=")[1];
      if (filterWithPhotosUrl) {
        setFilterWithPhotos("True");
      } else {
        setFilterWithPhotos("False");
      }
      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };

  const onRowSelect = ({ selectedRows }) => {
    let selectedIds = [];
    selectedRows.map((item) => selectedIds.push(item.id));
    setToRestoreApplicants(selectedIds);
  };

  const allApplicants = () => {
    if (applicantType === "visitor") {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_VISITIOR,
      });
    } else if (applicantType === "staff") {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_STAFF,
      });
    } else if (applicantType === "delivery_adhoc") {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC,
      });
    } else if (applicantType === "delivery_regular") {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_DELIVERY_REGULAR,
      });
    } else {
      history.push({
        pathname: VW_ROUTES.APPLICANTS_HISTORY,
      });
    }
  };

  const restoreApplicants = () => {
    setModelFor("restore");
    manageModal(true);
  };

  const confirmRestoreApplicants = () => {
    manageModal(false);
    setDataProcessing(true);
    const options = {
      url: apiRoutes.aplicantArchive,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: {
        data: {
          type: "applicant",
          attributes: {
            archive: false,
            updateMode: "partial",
            applicantIds: toRestoreApplicants,
          },
        },
      },
    };

    axios(options)
      .then((response) => {
        setModelFor("");
        applicants.refetch();

        setDataProcessing(false);
        setToRestoreApplicants([]);

        if (applicantType === "visitor") {
          addToast(DISPLAY_TEXTS.VISITOR_RESTORED, { appearance: "success" });
        } else if (applicantType === "staff") {
          addToast(DISPLAY_TEXTS.STAFF_RESTORED, { appearance: "success" });
        } else if (applicantType === "delivery_adhoc") {
          addToast(DISPLAY_TEXTS.DELIVERY_ADHOC_RESTORED, {
            appearance: "success",
          });
        } else if (applicantType === "delivery_regular") {
          addToast(DISPLAY_TEXTS.DELIVERY_REGULAR_RESTORED, {
            appearance: "success",
          });
        } else {
          addToast(DISPLAY_TEXTS.APPLICANT_ARCHIVED, { appearance: "success" });
        }

        setToggleClearRows(!toggledClearRows);
      })
      .catch(() => {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
        setDataProcessing(false);
        setToRestoreApplicants([]);
      });
  };

  const getPageName = () => {
    switch (applicantType) {
      case "visitor":
        return "Visitors";
      case "staff":
        return "Staffs";
      case "delivery_adhoc":
        return "Delivery Adhoc";
      case "delivery_regular":
        return "Delivery Regular";
      default:
        return "Applicants";
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>Archived {getPageName()}</h1>

            <div className="applicantFilter">
              <button
                disabled={toRestoreApplicants.length === 0}
                onClick={() => restoreApplicants()}
                className="btn btn-primary btn-sm archiveLink"
              >
                Restore {getPageName()}
              </button>
              <button
                onClick={() => allApplicants()}
                className="btn btn-primary btn-sm float-right mrgn-lft-rht"
              >
                All {getPageName()}
              </button>
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <h4>Advanced Search</h4>
                  <SearchBar
                    onSearchInputChange={onSearchInputChange}
                    popstateText={searchQueryString}
                  />
                  <FilterSection />
                  <button
                    type="button"
                    title="Reset"
                    className="btn btn-primary done"
                    onClick={() => showFilter(false)}
                  >
                    Done
                  </button>
                </div>
              </div>
              <div
                className="tableFilterButton rightAlign"
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body dataTableOuter">
              <DataTable
                clearSelectedRows={toggledClearRows}
                subHeader={true}
                selectableRows
                onSelectedRowsChange={onRowSelect}
                // subHeaderComponent={<><FilterSection/><div className="desktopSearch"> <SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/></div></>}
                progressPending={applicants.isLoading || dataProcessing}
                progressComponent={<Loader />}
                columns={columns}
                data={applicants.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={
                  applicants.meta && applicants.meta.pagination.count
                }
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={
          <>
            <b>
              {modelFor === "delete"
                ? "Confirm Delete Permanently"
                : "Confirm Restore"}
            </b>
          </>
        }
        content={
          <>
            <p>
              Are you sure you want to{" "}
              {modelFor === "delete" ? "delete" : "restore"} the selected
              Applicant details ?
            </p>
          </>
        }
        onSubmitCallback={confirmAction}
        onCloseCallback={doCloseModal}
      />
    </>
  );
};

export default ArchivedApplicants;
