import React from "react";
import { formatTextWithFallback, getLocalDateTime } from "../../../helpers/helpers";

function TableExpandableComponent({ data }) {
  return (
    <>
      <div className="table-expaned-data">
        <div className="item">
          Profile ID{" "}
          <div className="value">{formatTextWithFallback(data?.profileId)}</div>
        </div>
        <div className="item">
          Contract{" "}
          <div className="value">{formatTextWithFallback(data?.contract?.name)}</div>
        </div>
        <div className="item">
          Email{" "}
          <div className="value">{formatTextWithFallback(data?.email)}</div>
        </div>
        <div className="item">
          D.O.B{" "}
          <div className="value">
            {formatTextWithFallback(data?.dateOfBirth)}
          </div>
        </div>
        <div className="item">
          Gender{" "}
          <div className="value text-capitalize">
            {formatTextWithFallback(
              data.gender === "other" ? "Prefer not to say" : data.gender
            )}
          </div>
        </div>
        <div className="item">
          Nationality{" "}
          <div className="value">
            {formatTextWithFallback(data?.nationalityFull)}
          </div>
        </div>
        <div className="item">
          Phone{" "}
          <div className="value">
            {formatTextWithFallback(data?.mobileNumber)}
          </div>
        </div>
        <div className="item">
          Last Visit{" "}
          <div className="value">
            {getLocalDateTime(data?.personLastVisit[0]?.visitTime)}
          </div>
        </div>
      </div>
    </>
  );
}

export default TableExpandableComponent;
