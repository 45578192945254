import React from "react";
import { AppReducer, ACTION_TYPES } from "../reducers/AppReducer";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL, apiRoutes } from "../constants";
import { GLOBAL_CONFIG } from "../config";
import { useMemo } from "react";
import { formatRegisterModes } from "../helpers/helpers";

const initialState = {
  currentUser: {},
  mainSidebarHidden: false,
  subSidebarHidden: true,
  notifications: [],
  lastRouteStates: {},
  showModalBackDrop: false,
  showRightModal: false,
  userRoleDetails: {},
  defaultMenuItem: {},
  activePageCode: null,
  activePagePath: null,
  showModal: false,
  showLoader: false,
  fireAlarmCounter: 0,
  userPermissions: [],
  pagePermissions: [],
  companyConfig: {},
  theme: "dark-theme",

  selectedRows: [],
  archiveResult: false,
  isArchive: false,
  isDeactivate: true,

  appOptions: {},
  showBanModal: { show: false, data: null },
  filterParams: {},
};

export const AppContext = React.createContext();
export const AppProvider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = React.useReducer(AppReducer, initialState);

  const showBanModalPopup = (action) => {
    dispatch({
      type: ACTION_TYPES.SHOW_BAN_MODAL,
      payload: action,
    });
  };
  const updateCurrentUser = (user) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_USER,
      payload: user,
    });
  };

  const updateCompanyConfig = (companyConf) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_COMPANY_CONFIG,
      payload: companyConf,
    });
  };

  const hideMainSidebar = (status) => {
    dispatch({
      type: ACTION_TYPES.HIDE_MAIN_SIDEBAR,
      payload: status,
    });
  };

  const hideSubSidebar = (status) => {
    dispatch({
      type: ACTION_TYPES.HIDE_SUB_SIDEBAR,
      payload: status,
    });
  };

  const updateLastRouteStates = (newRouteState) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_LAST_ROUTE_STATES,
      payload: newRouteState,
    });
  };

  const setLastRouteStates = (newRouteStates) => {
    dispatch({
      type: ACTION_TYPES.SET_LAST_ROUTE_STATES,
      payload: newRouteStates,
    });
  };

  const updateNotifications = (notifications) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_NOTIFICATIONS,
      payload: notifications,
    });
  };

  const manageRightModal = (status) => {
    dispatch({
      type: ACTION_TYPES.SHOW_RIGHT_MODAL,
      payload: status,
    });
  };

  const manageModal = (status) => {
    dispatch({
      type: ACTION_TYPES.SHOW_MODAL,
      payload: status,
    });
  };

  const updateFileAlarmCounter = (counter) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_FILE_ALARM_COUNTER,
      payload: counter,
    });
  };

  const updateUserPermissions = (payload) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_USER_PERMISSIONS,
      payload,
    });
  };

  const setActivePageCode = (payload) => {
    dispatch({
      type: ACTION_TYPES.SET_ACTIVE_PAGE_CODE,
      payload,
    });
  };

  const overideActivePageCode = (payload) => {
    dispatch({
      type: ACTION_TYPES.OVERIDE_ACTIVE_PAGE_CODE,
      payload,
    });
  };

  const updatePagePermissionBasedOnRole = (payload) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_PAGE_PERMISSIONS,
      payload,
    });
  };

  const updateUserRoleDetails = (payload) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_USER_ROLE_DETAILS,
      payload,
    });
  };

  const updateFilterParams = (payload) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_FILTER_PARAMS,
      payload,
    });
  };
  const manageLoader = (status) => {
    dispatch({
      type: ACTION_TYPES.MANAGE_LOADER,
      payload: status,
    });
  };

  const updateInitialPageParams = (location) => {
    const qry = queryString.parse(location.search);
    let url;
    if (location.search.split("&page=")) {
      url = location.search.split("&page=")[0];
    } else {
      url = location.search;
    }
    const params = {
      initialPageNumber: Number(qry.page ? qry.page : 1),
      currentParams: url,
      initialSearchKey: qry.search ? qry.search : "",
      applicantType: qry.applicant ? qry.applicant : "",
    };
    dispatch({
      type: ACTION_TYPES.UPDATE_INITIAL_PAGE_PARAMS,
      payload: params,
    });
  };

  const setPageUrlParams = (setParams) => {
    if (setParams.page > 1) {
      history.push({
        search: `${setParams.initialPageParams.currentParams}&page=${
          setParams.page
        }${
          setParams.applicantType
            ? "&applicant=" + encodeURI(setParams.applicantType)
            : ""
        }${
          setParams.searchQueryString
            ? "&search=" + encodeURI(setParams.searchQueryString)
            : ""
        }`,
      });
    } else {
      history.push({
        search: `${setParams.initialPageParams.currentParams}&page=${1}${
          setParams.applicantType
            ? "&applicant=" + encodeURI(setParams.applicantType)
            : ""
        }${
          setParams.searchQueryString
            ? "&search=" + encodeURI(setParams.searchQueryString)
            : ""
        }`,
      });
    }
  };

  const setProfilePageUrlParams = (setParams) => {
    if (!setParams) {
      history.push({ search: "" });
      return;
    }
    const newPageParams = new URLSearchParams(
      _.omitBy(
        {
          ...setParams,
          //...(String(setParams?.search ?? "").length > 0) ? {search: setParams.search} : {},
        },
        (paramValue) => paramValue === undefined || paramValue === null,
      ),
    );

    history.push({
      search: newPageParams.toString(),
    });
  };

  const getCategoryFromID = (searchCategoryID, appOptions) =>
    appOptions?.category?.find(
      (categoryItems) => categoryItems?.value === searchCategoryID,
    ) ?? null;
  const getCategoryFromSlug = (searchCategorySlug, appOptions) =>
    appOptions?.category?.find(
      (categoryItems) => categoryItems?.slug === searchCategorySlug,
    ) ?? null;

  React.useEffect(() => {
    const getThemeFromLocal = localStorage.getItem("theme");
    if (getThemeFromLocal) {
      dispatch({
        type: ACTION_TYPES.SWITH_THEME,
        payload: getThemeFromLocal,
      });
    }

    const token = localStorage.getItem("access_token");
    const options = {
      method: "OPTIONS",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      url: apiRoutes.applicantProfile,
    };

    axios(options).then((response) => {
      const {
        data: {
          data: {
            actions: {
              POST: {
                status: { choices: statusChoices } = { choices: [] },
                nationality: { choices: nationalityChoices } = { choices: [] },
                id_type: { choices: idTypeChoices } = { choices: [] },
                gender: { choices: gender } = { choices: [] },
                register_mode: { choices: registerModeChoices } = { choices: [] },
              },
            },
            related_field_data: {
              category: { choices: categoryChoices } = { choices: [] },
              not_visited_filters: { choices: notVisitedChoices } = { choices: [] },
              ic_types: { choices: icTypeChoices } = { choices: [] },
            },
          },
        },
      } = response;

      dispatch({
        type: ACTION_TYPES.UPDATE_APP_OPTIONS,
        payload: {
          status: [
            ...(GLOBAL_CONFIG.profile.archive
              ? statusChoices
              : _.reject(
                  statusChoices ?? [],
                  (statusOption) => statusOption?.value === "archived",
                )),
          ],
          category: categoryChoices,
          nationality: nationalityChoices,
          idType: idTypeChoices,
          gender: gender,
          notVisited: notVisitedChoices,
          registerModes: formatRegisterModes(registerModeChoices),
          ic_types: icTypeChoices,
        },
      });
    });
  }, []);

  const switchTheme = (theme) => {
    let setThemeto;
    if (theme === "light-theme") {
      setThemeto = "dark-theme";
      localStorage.setItem("theme", "dark-theme");
    } else {
      setThemeto = "light-theme";
      localStorage.setItem("theme", "light-theme");
    }
    dispatch({
      type: ACTION_TYPES.SWITH_THEME,
      payload: setThemeto,
    });
  };

  const updateUserLimit = (userLimit) => {
    dispatch({
      type: ACTION_TYPES.USER_LIMIT,
      payload: userLimit,
    });
  };

  const onRowSelect = (rows) => {
    let selectedIds = [];
    rows.selectedRows.map((item) => selectedIds.push(item.id));
    dispatch({
      type: ACTION_TYPES.UPDATE_SELECTED_ROWS,
      payload: selectedIds,
    });

    if (selectedIds.length === 0) {
      dispatch({
        type: ACTION_TYPES.UPDATE_ARCHIVE_RESULT,
        payload: false,
      });
    }
  };

  const doarchiveApplicants = () => {
    const token = localStorage.getItem("access_token");
    const options = {
      url: apiRoutes.aplicantArchive,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: {
        data: {
          type: "applicant",
          attributes: {
            archive: true,
            updateMode: "partial",
            applicantIds: state.selectedRows,
          },
        },
      },
    };

    axios(options)
      .then((response) => {
        dispatch({
          type: ACTION_TYPES.UPDATE_SELECTED_ROWS,
          payload: [],
        });

        dispatch({
          type: ACTION_TYPES.UPDATE_ARCHIVE_RESULT,
          payload: true,
        });
      })
      .catch((error) => {
        dispatch({
          type: ACTION_TYPES.UPDATE_ARCHIVE_RESULT,
          payload: false,
        });
      });
  };

  const contextValue = useMemo(
    () => ({
      currentUser: state.currentUser,
      companyConfig: state.companyConfig,
      mainSidebarHidden: state.mainSidebarHidden,
      subSidebarHidden: state.subSidebarHidden,
      lastRouteStates: state.lastRouteStates,
      notifications: state.notifications,
      userRoleDetails: state.userRoleDetails,
      defaultMenuItem: state.defaultMenuItem,
      activePageCode: state.activePageCode,
      activePagePath: state.activePagePath,
      showRightModal: state.showRightModal,
      showModal: state.showModal,
      showModalBackDrop: state.showModalBackDrop,
      fireAlarmCounter: state.fireAlarmCounter,
      userPermissions: state.userPermissions,
      pagePermissions: state.pagePermissions,
      showLoader: state.showLoader,
      initialPageParams: state.initialPageParams,
      theme: state.theme,
      selectedRows: state.selectedRows,
      archiveResult: state.archiveResult,
      isArchive: state.isArchive,
      isDeactivate: state.isDeactivate,
      appOptions: state.appOptions,
      showBanModal: state.showBanModal,
      filterParams: state.filterParams,
      updateCurrentUser,
      hideMainSidebar,
      hideSubSidebar,
      updateLastRouteStates,
      setLastRouteStates,
      updateNotifications,
      updateCompanyConfig,
      manageRightModal,
      manageModal,
      updateFileAlarmCounter,
      updateUserPermissions,
      updatePagePermissionBasedOnRole,
      updateUserRoleDetails,
      setActivePageCode,
      overideActivePageCode,
      manageLoader,
      updateInitialPageParams,
      setPageUrlParams,
      setProfilePageUrlParams,
      getCategoryFromID,
      getCategoryFromSlug,
      switchTheme,
      updateUserLimit,
      onRowSelect,
      doarchiveApplicants,
      showBanModalPopup,
      updateFilterParams,
    }),
    [state],
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
