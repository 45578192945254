import React, { useContext, useEffect, useState } from "react";
import { API_BASE_URL, apiRoutes } from "../../constants";
import { PublicContext } from "../../context/PublicContext";
import _ from "lodash";
import Axios from "axios";

const useInviteValidity = () => {
    const { stateStorage, setStateStorage } = useContext(PublicContext);
    const [token, setToken] = useState(null);
    const [isValidated, setIsValidated] = useState(null);

    const checkTokenValidity = async () => {
        if(!token) return handleValidate(false);
        const url = new URL(`${API_BASE_URL}${apiRoutes.publicRegisterInviteValidate}${token}`);
        Axios.post(url).then(() => {
          handleValidate(true);
        }).catch(() => {
          handleValidate(false);
        })
      }
    
      const handleValidate = (validated) => {
        setIsValidated(validated);
        if(validated && token)
          setStateStorage({ "selfInviteToken": token });
      }
    
      useEffect(() => {
        setToken(window.location.hash?.split("?")[1] ?? undefined);
        location.replace(window.location.toString()?.split("?")[0]);
      }, []);
    
      useEffect(() => { 
        if(token === null) return () => {};
        else if(_.has(stateStorage, "selfInviteToken")) handleValidate(stateStorage["selfInviteToken"]);
        else if(!token) handleValidate(false);
        else checkTokenValidity();
      }, [token]);

      return {
        isValidated
      };
}

export default useInviteValidity;