import React, { useContext } from "react";

import { AppContext } from "../../context/AppContext";
import DashLineChart from "../Charts/dashLineChart";
import { useToasts } from "react-toast-notifications";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { isWidgetRefreshable } from "../../helpers/helpers";
import _ from "lodash";

const DashboardLPRChart = ({ widget }) => {
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();

  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const [weeklyVisitData, setWeeklyVisitData] = React.useState(undefined);
  const [weeklyGraphData, setweeklyGraphData] = React.useState(undefined);
  const [weeklyGraphMaxim, setWeeklyGraphMaxim] = React.useState(0);
  const [rawDataArray, setRawDataArray] = React.useState([]);

  const { currentUser } = React.useContext(AppContext);
  const getWeeklyGraphData = (type) => {
    let dataClone;

    if (weeklyVisitData.graphData) {
      dataClone = { ...weeklyVisitData.graphData };
    }

    if (dataClone) {
      const filterBy = dataClone.datasets;
      dataClone.datasets = filterBy;

      if (!dataClone?.labels?.length)
        dataClone.labels = ["1", "2", "3", "4", "5", "6", "7"];
      if(dataClone?.datasets?.length) {
        dataClone.datasets = dataClone?.datasets?.map((dataItem) => {
          if(!dataItem?.data || !dataItem?.data?.length) 
            return ({...dataItem, data: Array(dataClone?.labels?.length ?? 0).fill(0)});
          return { ...dataItem };
        });
      }

      let dataArray = [];
      if (dataClone.datasets.length > 0) {
        dataClone.datasets.map((item) => {
          return item.data.map((value) => {
            return dataArray.push(value);
          });
        });
      }
      const yMax = _.max(dataArray) ?? 0;
      setWeeklyGraphMaxim(yMax === 0 ? 1 : yMax);
      setRawDataArray(dataArray);

      setweeklyGraphData(dataClone);
    } else {
      setweeklyGraphData({});
    }
  };

  const chartOptions = {
    stepSize: Math.floor(500),
    // min: 0,
    // max: weeklyGraphDataMaxim,
    padding: 20,
  };


   const getVehicleWeekDataLabel = (data) => {
     let labels = [];
     data.forEach((item) => {
       labels.push(`${item.date}(${item.day})`);
     });

     return labels;
   };
   const getVehicleWeekDataValues = (data) => {
     let datas = [];
     data.forEach((item) => {
       datas.push(item.visits);
     });
     return datas;
   };

  const token = localStorage.getItem("access_token");
  const axiosOptions = (method, url) => {
    return {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: `${url}`,
    };
  };

  const vehicleWeekCountFetcher = (url) => {
    Axios(axiosOptions("GET", `${url}`)).then((response) => {
      if (!_.isEmpty(response?.data?.data)) {
        setWeeklyVisitData({
          maxValue: 2,
          graphData: {
            datasets: [
              {
                label: "Vehicles",
                data: getVehicleWeekDataValues(response?.data?.data),
              },
            ],
            labels: getVehicleWeekDataLabel(response?.data?.data),
          },
        });
      } else {
        setWeeklyVisitData({});
      }
    }).catch(() => setWeeklyVisitData({}));
  };

  const updateData = () => {
    setIsUpdateLoading(true);
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    const apiUrl =
      widget.widget.config.dataSource === "external"
        ? widget.widget.config.url
        : apiRoutes.dashboardWidgetsData + "/" + slug;
        
    Axios.get(apiUrl + "/update", config)
      .then((response) => {
        addToast(response?.data?.data?.message ?? "Widget updated Successfully!", { appearance: "success" });
      })
      .catch((error) => {
        addToast(error.detail ?? "Failed to update widget!", { appearance: "error" });
      }).finally(() => setIsUpdateLoading(false));
  }

  React.useEffect(() => {
    if (widget) {
      const apiUrl =
        widget.widget.config.dataSource === "external"
          ? widget.widget.config.url
          : apiRoutes.dashboardWidgetsData + "/" + slug;
      vehicleWeekCountFetcher(apiUrl);
      if (
        widget?.widget?.config?.refreshRate &&
        widget?.widget?.config?.refreshRate > 0
      ) {
        const timeoutId = setInterval(() => {
          vehicleWeekCountFetcher(apiUrl);
        }, widget?.widget?.config?.refreshRate * 1000);
        return () => {
          clearInterval(timeoutId);
        };
      }
    }
  }, [widget]);


  React.useEffect(() => {
    if(weeklyVisitData !== undefined){
      if (weeklyVisitData?.graphData?.datasets?.length) {
        getWeeklyGraphData("weekly");
      } else { setweeklyGraphData({}); }
    }
    // eslint-disable-next-line
  }, [weeklyVisitData]);

  return (
    <>

        <div className="card">
          <div className="card-header resp-header">
            <div className="graph-title">
              VEHICLE ENTRY OVERVIEW
            </div>
            <div className="d-flex align-items-center">
              <ul className="nav nav-tabs graph-tab float-right">
                <li>
                  <a data-toggle="tab" href="#menu3" className="active">
                    Last 7 Days
                  </a>
                </li>
              </ul>
              {isWidgetRefreshable(userRoleDetails, widget) && 
                <span 
                  onClick={() => {!isupdateLoading && updateData()}}
                  className={`refresh-button ml-1 ${isupdateLoading ? "spin" : ""}`} 
                  data-tip="Update Data" >
                    <i className="iconsminds-refresh" />
                </span>
              }
            </div>
          </div>
          <ReactTooltip />
          <div className="dashboard-pchart p-2 pt-3 pr-3 dashbrd-btm-graph widget-data-container">
            <div className="tab-content h-100 overflow-hidden">
              <div id="menu3" className="tab-pane fade in active show h-100">
                <DashLineChart
                  chartOptions={chartOptions}
                  dataArray={rawDataArray}
                  shadow
                  data={weeklyGraphData ?? {}}
                />
              </div>
            </div>
            {(weeklyVisitData !== undefined) &&( _.isEmpty(weeklyVisitData) || !weeklyVisitData?.graphData?.datasets?.length) && 
              <div className="no-data-overlay"><span>No Data Available!</span></div>
            }
          </div>
        </div>
    </>
  );
};
export default DashboardLPRChart;
