import React from "react"
import Loader from "../components/common/Loader/Loader"

const PermissionWrapper = ({ isAllowed, isLoading, children }) => (
    <>
        {isAllowed ? (
        <>{children}</>
        ) : (
            (isLoading) ? (<Loader />) :
            (<div className="d-flex align-items-center justify-content-center flex-column h-100 w-100">
            <div>No permission granted to access this page!</div>
            </div>)

        )}
    </>
);

export default PermissionWrapper;