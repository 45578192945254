import React, { useEffect, useState } from "react";
import branding from "../../assets/images/branding.png";
import brandingW from "../../assets/images/branding-w.png";
import { AppContext } from "../../context/AppContext";
import moment from "moment-timezone";
import { formatTextWithFallback } from "../../helpers/helpers";

const Footer = ({ systemInfo }) => {
  const { theme } = React.useContext(AppContext);
  const [browserInfo, setBrowserInfo] = useState(null);
  const [osInfo, setOsInfo] = useState(null);
  useEffect(() => {
    (function () {
      "use strict";
      var module = {
        options: [],
        header: [
          navigator.platform,
          navigator.userAgent,
          navigator.appVersion,
          navigator.vendor,
          window.opera,
        ],
        dataos: [
          { name: "Windows Phone", value: "Windows Phone", version: "OS" },
          { name: "Windows", value: "Windows NT 10.0", version: "Windows NT" },
          { name: "Windows", value: "Win", version: "NT" },
          { name: "Windows", value: "Win", version: "NT 6.3" },
          { name: "Windows", value: "Win", version: "NT 6.2" },
          { name: "Windows", value: "Win", version: "NT 6.1" },
          { name: "iPhone", value: "iPhone", version: "OS" },
          { name: "iPad", value: "iPad", version: "OS" },
          { name: "Kindle", value: "Silk", version: "Silk" },
          { name: "Android", value: "Android", version: "Android" },
          { name: "PlayBook", value: "PlayBook", version: "OS" },
          { name: "BlackBerry", value: "BlackBerry", version: "/" },
          { name: "Macintosh", value: "Mac", version: "OS X" },
          { name: "Linux", value: "Linux", version: "rv" },
          { name: "Palm", value: "Palm", version: "PalmOS" },
        ],
        databrowser: [
          { name: "Chrome", value: "Chrome", version: "Chrome" },
          { name: "Firefox", value: "Firefox", version: "Firefox" },
          { name: "Safari", value: "Safari", version: "Version" },
          { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
          { name: "Opera", value: "Opera", version: "Opera" },
          { name: "BlackBerry", value: "CLDC", version: "CLDC" },
          { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
        ],
        init: function () {
          var agent = this.header.join(" "),
            os = this.matchItem(agent, this.dataos),
            browser = this.matchItem(agent, this.databrowser);
          return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
          var i = 0,
            j = 0,
            html = "",
            regex,
            regexv,
            match,
            matches,
            version;
          for (i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, "i");
            match = regex.test(string);
            if (match) {
              version = "0";
              regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
              matches = string.match(regexv);
              if (matches && matches[1]) {
                matches = matches[1].split(/[._]+/);
                for (j = 0; j < matches.length; j += 1) {
                  version += matches[j] + (j === 0 ? "." : "");
                }
              }
              return {
                name: data[i].name,
                version: parseFloat(version),
              };
            }
          }
          return { name: "unknown", version: 0 };
        },
      };

      var e = module.init(),
        debug = "";
      debug += "os.name = " + e.os.name + "<br/>";
      debug += "browser.name = " + e.browser.name + "<br/>";
      debug += "browser.version = " + e.browser.version + "<br/>";
      setBrowserInfo(`${e.browser.name} ${e.browser.version}`);
      setOsInfo(`${e.os.name}`);
    })();
  }, []);
  return (
    <footer className="page-footer d-sm-block">
      <div className="footer-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-sm-6 m-auto">
              <a
                href="https://vwatch.pro/"
                target="_blank"
                className="btn-link"
                rel="noopener noreferrer"
              >
                <img
                  src={theme === "dark-theme" ? branding : brandingW}
                  alt="v-watch"
                  className="footer-logo"
                />
              </a>
              <ul className="breadcrumb pt-0 pr-0 ">
                <li className="breadcrumb-item mb-0 ">
                  <a
                    href="https://ssgroupz.com/vwatchpro"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-link"
                  >
                    <span className="footer-text">
                      © Copyright {moment().year()} SS Group International Sdn
                      Bhd
                    </span>{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul className="breadcrumb pt-0 pr-0 float-right mt-sm-1">
                <li className="breadcrumb-item mb-0">
                  <p className="details footer-text">
                    {browserInfo ? `Browser: ${browserInfo}` : ""}
                    {osInfo ? `, ${osInfo}` : ""}
                    <span className="d-block">
                      {`Build: ${formatTextWithFallback(
                        systemInfo?.build?.version
                      )}`}
                    </span>
                    {systemInfo?.license?.expiryDate && (
                      <span>{`License Expiry : ${systemInfo?.license?.expiryDate}`}</span>
                    )}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
