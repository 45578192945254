import React from "react";
export const CctvIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 322.719 322.719"
    style={{ enableBackground: "new 0 0 322.719 322.719" }}
    xmlSpace="preserve"
  >
    <g>
      <path d="M322.158,45.444c-1.402-4.012-5.188-6.7-9.439-6.7h-269.2c-5.522,0-10,4.477-10,10v75.496 c0,20.537,16.708,37.245,37.245,37.245h6.583v18.614c0,11.651,9.479,21.129,21.129,21.129h3.879 c-3.021,20.826-20.985,36.877-42.635,36.877c-0.871,0-1.712,0.123-2.518,0.332v-5.333c0-11.651-9.479-21.13-21.13-21.13H10 c-5.522,0-10,4.477-10,10v52c0,5.523,4.478,10,10,10h26.072c11.651,0,21.13-9.479,21.13-21.129v-5.073 c0.806,0.209,1.646,0.332,2.518,0.332c32.691,0,59.649-24.995,62.78-56.877h5.717c11.651,0,21.13-9.479,21.13-21.129v-18.614 h108.584c20.537,0,37.245-16.708,37.245-37.245V75.502l23.773-18.936C322.274,53.918,323.562,49.457,322.158,45.444z M37.202,262.846c0,0.623-0.507,1.129-1.13,1.129H20v-32h16.072c0.623,0,1.13,0.507,1.13,1.13V262.846z M129.347,180.099 c0,0.623-0.507,1.129-1.13,1.129H98.476c-0.622,0-1.129-0.507-1.129-1.129v-16.073h32V180.099z M275.176,124.24 c0,9.509-7.736,17.245-17.245,17.245H70.764c-9.509,0-17.245-7.736-17.245-17.245v-11.125h182.675 c7.585,0,15.028-2.602,20.961-7.328l18.021-14.354V124.24z M244.693,90.143c-2.405,1.916-5.424,2.971-8.5,2.971H53.519v-34.37 h230.595L244.693,90.143z" />
      <path d="M310.719,75.952c-5.522,0-10,4.477-10,10v38.998c0,5.523,4.478,10,10,10s10-4.477,10-10V85.952 C320.719,80.429,316.241,75.952,310.719,75.952z" />
    </g>
  </svg>
);
