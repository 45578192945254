import React, { useContext } from "react";
import loader from "../../../assets/images/loader.gif";
import { AppContext } from "../../../context/AppContext";

const Loader = () => {
  const { theme } = React.useContext(AppContext) || "";

  return (
    <div
      className={
        theme === "dark-theme"
          ? "loader-container dark-theme-loader"
          : "loader-container"
      }
    >
      <img src={loader} alt="loader" />
    </div>
  );
};

export default Loader;
