import _ from "lodash";
/*
export const _mapAvailableActions = (data) => {
    const _getChildNodes = (childNode,ancestorMeta) => {
        return _.map(childNode, item => {
          
          const meta = {
            isNode: true,
            ancestors: [...ancestorMeta.ancestors, item.code]
          };
          const actions = _.map(item.actions, actionItem => {
              const actionMeta = {
                isNode: false,
                ancestors: [...meta.ancestors, actionItem.code]
              };
            return({
            code: actionItem.codename,
            title: actionItem.name,
            meta:actionMeta
          }
          )});
          return {
            id: item.id,
            title: item.title,
            code:item.code,
            meta,
            actions,
            children: _getChildNodes(item.children, meta)
        }
        });
    };
    return _.map(data, item => {
        let meta = {
          isNode: true,
          ancestors: [item.code]
        };
        return {
            id: item.id,
            title: item.title,
            code: item.code,
            actions: _.map(item.actions, actionItem => {
              let actionMeta = {
                isNode: false,
                ancestors: [...meta.ancestors, actionItem.codename]
              };
              return({
                code: actionItem.codename,
                title: actionItem.name,
                meta: actionMeta
            })}),
            meta,
            children: _getChildNodes(item.children, meta)
        }
    })
};
*/

export const _mapAvailableActions = (data) => {
  const removeItem = (data, item) => {
    const index = data.indexOf(item);
    if (index > -1) {
      data.splice(index, 1);
    }
    return data;
  };

  const _getChildNodes = (childNode, parentCode) => {
    return _.map(childNode, (item) => {
      parentCode.push(item.code);
      const actions = _.map(item.actions, (actionItem) => ({
        code: actionItem.codename,
        title: actionItem.name,
        identifier: `${parentCode.join("~")}~${actionItem.codename}`,
      }));

      const children = _getChildNodes(item.children, parentCode);
      removeItem(parentCode, item.code);
      return {
        id: item.id,
        title: item.title,
        code: item.code,
        identifier: `isNode~${parentCode.join("~")}~${item.code}`,
        actions,
        children,
      };
    });
  };
  return _.map(data, (item) => {
    return {
      id: item.id,
      title: item.title,
      code: item.code,
      identifier: `isNode~${item.code}`,
      actions: _.map(item.actions, (actionItem) => ({
        code: actionItem.codename,
        title: actionItem.name,
        identifier: `${item.code}~${actionItem.codename}`,
      })),
      children: _getChildNodes(item.children, [item.code]),
    };
  });
};

export const _mapPayload = (payload) => {
  const parseNode = (item) => {
    if (item.isParent === true) {
      return {
        attributes: {
          children: _mapPayload(item.children),
          code: item.code,
          actions: item.actions,
        },
      };
    } else {
      return {
        children: _mapPayload(item.children),
        code: item.code,
        actions: item.actions,
      };
    }
  };
  return _.map(Object.values(payload), (data) => parseNode(data));
};

export const parseNodeIdentifiers = (payload) => {
  const identifiers = [];
  const drillDownActions = (actions, code) => {
    _.map(actions, (action) => {
      identifiers.push(`${code}~${action}`);
    });
  };

  const drillDownChildren = (children, code) => {
    _.map(children, (child) => {
      drillDownActions(child.actions, `${code}~${child.code}`);
      identifiers.push(`isNode~${code}~${child.code}`);
    });
  };
  _.map(payload, (item) => {
    if (_.has(item, "attributes")) {
      if (item.attributes.children.length > 0) {
        drillDownChildren(item.attributes.children, item.attributes.code);
      }
      drillDownActions(item.attributes.actions, item.attributes.code);
      identifiers.push(`isNode~${item.attributes.code}`);
    }
  });
  return identifiers;
};
