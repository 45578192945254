import React, { useEffect } from "react";
import useContractOptions from "../../components/common/CommonDeliverables/useContractOptions";
import LazyLoadedSelect from "../../components/LazyLoadedSelect/LazyLoadedSelect";

const ValidityExpiryStatusFilter = ({
  contractValue,
  handleContractChange,
}) => {
  const {
    contracts,
    handleContractSearch,
    loadNextContractOptionsPage,
    isContractLoading,
  } = useContractOptions();

  return (
    <div className="advance-filter">
      <div>
        <span>Contract</span>
        <div className="mb-2">
          <LazyLoadedSelect
            className="cutomSelect"
            classNamePrefix="react-custom-select"
            loadNextOptions={loadNextContractOptionsPage}
            name="contractors"
            onChange={handleContractChange}
            onInputChange={(value) => handleContractSearch(value)}
            options={contracts ?? []}
            placeholder="Select"
            value={contractValue}
            isLoading={isContractLoading}
            isMulti
          />
        </div>
      </div>
    </div>
  );
};

export default ValidityExpiryStatusFilter;
