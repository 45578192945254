/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { useClient } from "jsonapi-react";
import _ from "lodash";
import { useMutation } from "jsonapi-react";
import { useToasts } from "react-toast-notifications";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import ChangePassword from "../../components/common/ChangePassword/ChangePassword";
import { validatorModule } from "../../helpers/helpers";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import avatar from "../../assets/images/profileimagePlaceholder.jpg";
import CaptureImageComponent from "../../components/CaptureImageComponent/CaptureImageComponentWithCrop";

const UserProfile = () => {
  const initialState = {
    new_password1: "",
    new_password2: "",
  };

  const initialErrorState = {
    new_password1: "",
    new_password2: "",
  };

  const validationFormat = [
    {
      field: "new_password1",
      isRequired: true,
      fieldName: "Password",
    },
    {
      field: "new_password2",
      fieldName: "Confirm Password",
      isSameAs: {
        field: "new_password1",
        fieldName: "Password",
      },
    },
  ];
  const { currentUser } = React.useContext(AppContext);
  const [avatarImage, setAvatarImage] = React.useState(
    currentUser?.avatar ? currentUser?.avatar : avatar
  );

  useEffect(()=>{
    setAvatarImage((currentUser?.avatar ? currentUser?.avatar : avatar))
  }, [currentUser]);

  const handleImageError = () => {
    setAvatarImage(avatar);
  };
  const { addToast } = useToasts();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  //const {data: roles} = useQuery(['accounts', 'roles'], { client });
  const [changePassword] = useMutation(["auth", "password", "change"], {
    client,
  });
  const { showModal, manageModal } = React.useContext(AppContext);
  const [errors, setErrors] = React.useState(initialErrorState);
  const [passData, setPassData] = React.useState(initialState);
  const [imageModal, setImageModal] = React.useState(false);

  const onPassDataChange = (target) => {
    setPassData({
      ...passData,
      [target.name]: target.value,
    });
  };

  const onChangePasswordSubmit = async () => {
    let errors = validatorModule(passData, validationFormat);
    setErrors(errors);
    if (_.isEmpty(Object.values(errors))) {
      const { error, data } = await changePassword(passData);
      if (error) {
        addToast(error.detail, { appearance: "error" });
      } else if (data) {
        addToast(DISPLAY_TEXTS.PASSWORD_UPDATED, { appearance: "success" });
        setPassData(initialState);
        manageModal(false);
      }
    }
  };
  const managePhotoBooth = (status) => {
    setImageModal(status);
  };
  const refreshOnCaptureImage = () => {
    managePhotoBooth(false);
  };

  return (
    <>
      <ModalComponent
        show={imageModal}
        customClass="camModal"
        header={<b>Profile Picture</b>}
        content={
          imageModal ? (
            <CaptureImageComponent
              applicantId={currentUser?.user_id}
              profileImages={[currentUser?.avatar]}
              status={imageModal}
              onPictureCapture={refreshOnCaptureImage}
            />
          ) : (
            ""
          )
        }
        hideFooter={true}
        onSubmitCallback={() => {
          setImageModal(false);
        }}
        onCloseCallback={() => {
          setImageModal(false);
        }}
      />
      <div className="row">
        <div className="col-12">
          <h1>Profile</h1>
          <button
            type="button"
            className="btn btn-secondary btn-sm float-right"
            onClick={() => {
              setErrors(initialErrorState);
              setPassData(initialState);
              manageModal(true);
            }}
          >
            Change Password
          </button>
          <div className="separator mb-5"></div>
        </div>
      </div>
      <div className="row profile-holder">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-12 col-md-4 col-lg-3  left-deck">
                  <div className="profile-cover">
                    <div className="image-holder">
                      <img
                        src={avatarImage}
                        onError={handleImageError}
                        alt="avatar"
                        className="profile-image card-img-top"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <button
                      type="button"
                      className="btn btn-secondary  mr-2 mt-2"
                      onClick={() => managePhotoBooth(true)}
                    >
                      <i className="simple-icon-camera"></i> Upload
                    </button>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-9 right-deck">
                  <div className="row ">
                    {/* <div className="fieldName col-4">
    Full Name
</div> */}
                    <div className="fieldValue font-weight-bold col-12">
                      <h4>{`${currentUser.full_name}`}</h4>
                      {/* <h6>(  {currentUser.username} )</h6> */}
                      <h5 className="capitalize-first-letter">
                        {currentUser.role_slug.replace(/-/g, " ")}
                      </h5>
                    </div>
                  </div>

                  {/* <div className="row mb-2">
<div className="fieldName col-4">
    User Name
</div>
<div className="fieldValue font-weight-bold col-8">

</div>
</div> */}

                  <div className="row mb-2">
                    {/* <div className="fieldName col-4">
    Email
</div> */}
                    <div className="col-12 mail-info">
                      <p>
                        <i className="iconsminds-Envelope"></i>{" "}
                        <b> {currentUser.email}</b>
                      </p>
                      {currentUser.phone_number && (
                        <p className="phone">
                          <i className="iconsminds-Old-Telephone"></i>{" "}
                          <b> {currentUser.phone_number}</b>
                        </p>
                      )}
                    </div>

                    {/* <div className="fieldValue font-weight-bold col-8">

</div> */}
                  </div>
                </div>
              </div>

              {/* <div className="row">
                                <div className="fieldName col-4">
                                    Role
                            </div>
                                <div className="fieldValue font-weight-bold col-8">

                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={<b>Change Password</b>}
        content={
          <ChangePassword
            passData={passData}
            errors={errors}
            onInputChange={onPassDataChange}
          />
        }
        onSubmitCallback={() => onChangePasswordSubmit()}
        onCloseCallback={() => manageModal(false)}
      />
    </>
  );
};

export default UserProfile;
