export const ACTION_TYPES = {
    SET_TO_STATE_STORAGE: "setToStateStorage",
    UPDATE_TO_STATE_STORAGE: "updateToStateStorage",
    REMOVE_FROM_STATE_STORAGE: "removeFromStateStorage",
    CLEAR_STATE_STORAGE: "clearStateStorage",
  };
  
export const PublicReducer = (state, action) => {
switch (action.type) {
    case ACTION_TYPES.SET_TO_STATE_STORAGE:
        return {
            ...state,
            stateStorage: action.payload,
        };

    case ACTION_TYPES.UPDATE_TO_STATE_STORAGE:
        return {
            ...state,
            stateStorage: {
                ...state.stateStorage,
                ...action.payload
            },
        };

    case ACTION_TYPES.REMOVE_FROM_STATE_STORAGE:
        const newStateValues = state;
        if(state[action.payload]) delete newStateValues[action.payload];
        return {
            ...state,
            stateStorage: null,
        };

    case ACTION_TYPES.CLEAR_STATE_STORAGE:
        return {
            ...state,
            stateStorage: null,
        };

    default:
        return state;
}
};
  