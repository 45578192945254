import { API_BASE_URL, apiRoutes } from "../../../constants";
import { useCallback, useEffect, useState } from "react";
import Axios from "axios";

const formatInviteStatusOptions = (inviteStatusData) => {
    let inviteStatusArray = [];
    if (inviteStatusData?.length > 0) {
      _.forEach(_.orderBy(inviteStatusData, "display_name", "asc"), (inviteStatus) => {
        if(inviteStatus?.display_name)
            inviteStatusArray.push({
            label: inviteStatus?.display_name,
            value: inviteStatus?.value,
            });
      });
    }
    return inviteStatusArray;
  };

const useInviteStatusOptions = (excludeSelectOption = false) => {
  const [inviteStatusOptions, setInviteStatusOptions] = useState([]);
  const [isInviteStatusLoading, setInviteStatusLoading] = useState(false);

  const getInviteStatusOptions = useCallback(() => {
    const token = localStorage.getItem("access_token");
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    const searchURL = new URL(`${API_BASE_URL}${apiRoutes.publicRegisterInvite}`);
    setInviteStatusLoading(true);
    Axios.options(searchURL, config).then(({data: apiData}) => {
      const inviteStatusOptionsData = apiData?.data?.actions?.POST?.status;
      const newOptions = formatInviteStatusOptions(inviteStatusOptionsData?.choices);
      setInviteStatusOptions(newOptions);
    }).finally(() => {
      setInviteStatusLoading(false);
    })

  }, []);

  useEffect(() => { getInviteStatusOptions() }, []);

  return {
    inviteStatusOptions,
    isInviteStatusLoading
  };
};

export default useInviteStatusOptions;
