import _ from "lodash";
import moment from "moment";
import { CATEGORY_SLUGS, REGISTER_MODES_CUSTOM_MAP, VW_ROUTES } from "../constants";

export const parseAPIResponseError = (error) => {
  if (error && error.source && error.source.pointer) {
    return {
      field: error.source.pointer.split("/").pop(),
      errorMsg: error.detail,
    };
  }
  return {
    field: "",
    errorMsg: "",
  };
};

const validationChecker = (
  fieldValue,
  validationType,
  validationCondition,
  formValues,
  validationChecker
) => {
  let errorMessage = "";
  switch (validationType) {
    case "isSameAs":
      errorMessage =
        formValues[validationChecker.field] ===
        formValues[validationChecker.isSameAs.field]
          ? ""
          : `${validationChecker.fieldName} should be same as ${validationChecker.isSameAs.fieldName}!`;
      break;
    case "isPhoneNumber":
      //eslint-disable-next-line
      const regPhone = /^\+?(?:\d[\d-]{8,}|\(\d{9,}\))$/;

      if (!validationChecker.isRequired && !fieldValue) {
        errorMessage = "";
      } else {
        errorMessage = fieldValue.match(regPhone)
          ? ""
          : `${validationChecker.fieldName} is not valid!`;
      }
      break;
    case "requiredIf":
      errorMessage =
        formValues[validationCondition.field] === validationCondition.value
          ? fieldValue && fieldValue.length === 0
            ? `${validationChecker.fieldName} is required!`
            : ""
          : "";
      break;
    case "minLength":
      errorMessage =
        (fieldValue?.length && validationCondition > fieldValue.length)
          ? `${validationChecker.fieldName} must contain atleast ${validationCondition} characters!`
          : "";
      break;
    case "maxLength":
      errorMessage =
        (fieldValue?.length && validationCondition < fieldValue.length)
          ? `${validationChecker.fieldName} maximum length exceeded (${validationCondition} characters max)!`
          : "";
      break;
    case "isLength":
      if (fieldValue !== undefined && fieldValue !== null) {
        errorMessage =
          parseInt(validationCondition) !== fieldValue.toString().length
            ? `${validationChecker.fieldName} must contain ${validationCondition} character(s)!`
            : "";
      }
      break;
    case "isNumber":
      const regexNumber = /^[0-9]*$/;
      errorMessage =
        validationCondition === true && regexNumber.test(fieldValue) === false
          ? `${validationChecker.fieldName} must be a number!`
          : "";
      break;
    case "isAlpha":
      const regexAlpha = /^[A-Za-z]*$/;
      errorMessage =
        validationCondition === true && regexAlpha.test(fieldValue) === false
          ? `${validationChecker.fieldName} must be an alphabet!`
          : "";
      break;

    case "isRequired":
      if(validationCondition === false) break;
      if(fieldValue === undefined || fieldValue === null) {
        errorMessage = `${validationChecker.fieldName} is required!`;
        break;
      }
        errorMessage =
        validationCondition === true
        && ((fieldValue.length === 0)
        || (_.isObject(fieldValue) && (moment(fieldValue).isValid() ? false : (!fieldValue?.value || (_.isString(fieldValue) && fieldValue.length === 0)))))
        ? `${validationChecker.fieldName} is required!`
        : "";
      break;

    case "isEmail":
      //eslint-disable-next-line
      var regEmail =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,25})$/;
      errorMessage =
        validationCondition === true && regEmail.test(fieldValue) === false
          ? `${validationChecker.fieldName} is not valid!`
          : "";
      break;
    case "isEmailList":
      //eslint-disable-next-line
      var regEmail =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,25})$/;
      let validationRes = false;
      
      String(fieldValue ?? '').split(',').find((emailValue) => {
        if(!String(emailValue ?? '').length) {
          validationRes = false;
          return true;
        }
        if(regEmail.test(emailValue?.trim()) === false) {
          validationRes = false;
          return true;
        } 
        validationRes = true;
        return false;
      })
      errorMessage =
        validationCondition === true && !validationRes
          ? `${validationChecker.fieldName} is not valid!`
          : "";
      break;
    case "shouldHaveAlphabet":
      const regexContainAlpha = /^\d*[a-zA-Z]$/;
      errorMessage =
        validationCondition === true &&
        regexContainAlpha.test(fieldValue) === false
          ? `${validationChecker.fieldName} must be last 4 character of NRIC/FIN (3 digits and 1 alphabet)!`
          : "";
      break;
    case "minOneAlphabet":
      const regexContainAtleastOneAlpha = /[a-zA-Z]/;
      errorMessage =
        validationCondition === true &&
        regexContainAtleastOneAlpha.test(fieldValue) === false
          ? `${validationChecker.fieldName} should not only contain special characters or numbers!`
          : "";
      break;
    case "preventCharacters":
      errorMessage =
        validationCondition?.length &&
        _.includes(String(fieldValue), validationCondition)
          ? `${validationChecker.fieldName} cannot contain the following character(s): ${validationCondition.join(" ")}`
          : "";
      break;
    case "isBirthDay":
      const sixteenYearsAgo = moment().subtract(16, "years");
      if (fieldValue.length === 0) {
        errorMessage =
          validationCondition === true ? "Date Of Birth is required!" : "";
      } else {
        const dob = moment(fieldValue);
        if (!dob.isValid()) {
          errorMessage =
            validationCondition === true ? "Date Of Birth is not valid!" : "";
        } else if (sixteenYearsAgo.isAfter(dob)) {
          errorMessage = "";
        } else {
          errorMessage = "You must be atleast 16 years!";
        }
      }
      break;
    case "isAlphaNumeric":
      const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
      errorMessage = ""
      if (!alphaNumericRegex.test(fieldValue.trim())) {
        errorMessage = "Should contain only alphanumeric characters"
      }
      break;
    default:
      break;
  }
  return errorMessage;
};

export const validatorModule = (formValues, validations) => {
  let errors = [];
  _.map(validations, (validator) => {
    let item = formValues[validator.field];
    Object.keys(validator).forEach((val) => {
      if (val !== "field" && val !== "fieldName") {
        let response = validationChecker(
          item,
          val,
          validator[val],
          formValues,
          validator
        );
        if (response !== "") {
          errors[validator.field] = response;
        }
      }
    });
  });
  return errors;
};

export const parseIdentificationType = (user) => {

  const regex = /^NULL?/gi;
  let resp = `${user?.idNumber?.toUpperCase()}`;
  return resp.match(regex) === null ? resp : "-";
};

export const getIdNumber = (applicant) => {
  let id = `${
    applicant.idType === "nric" || applicant.idType === "fin"
      ? applicant?.idType?.toUpperCase()
      : applicant.idType
  } : ${parseIdentificationType(applicant)}`;
  return id;
};


export const manageStepperCommon = (currentStep, stepper, setCurrentStep, direction) => {
  if (direction === "prev") {
    setCurrentStep((step) => (step === 1 ? 1 : step - 1));
  } else {
    setCurrentStep((step) => (step === stepper.length ? step : step + 1));
  }
};

export const mergeDataOnStepCompletionCommon = (
  formData,
  currentStep,
  formState,
  onSubmitForm,
  setFormState
) => {
  const mergedData = { ...formState, ...formData };
  setFormState(mergedData);
  if (currentStep === 4) {
    onSubmitForm();
  }
};

export const handleStepWizardChange = (setMoving) => {
  setMoving(true);

  setTimeout(() => {
    setMoving(false);
  }, 1000);
};

export const stepperTopClasses = (item) => {
  let name = "step";
  if (item.isCompleted) {
    name = `${name} completed`;
  }

  if (item.isCurrent) {
    name = `${name} current`;
  }

  return name;
};

export const toCamelCase = (text, seperator = " ") => {
  let camelText = String(text).split(seperator).reduce((prevText, currText, index) => prevText + (index === 0 ? currText : ""));

}

export const formatTextWithFallback = (text, fallback = "--") => String(text ?? "").length ? String(text) : fallback;

const checkModulePermissionRule = (ruleInfo, data) => {
  const { field, condition, value } = ruleInfo;
  if(!field || !condition || !value || !data) return undefined;
  const fieldValue = String(_.get(data, field) ?? "None").toLowerCase();
  const ruleValue = condition === 'in' 
    ? (value ?? [])?.map((ruleValueItem) => String(ruleValueItem ?? "").toLowerCase()) 
    : String(value ?? "").toLowerCase();
  switch(condition) {
    case 'eq': return fieldValue === ruleValue;
    case 'not_eq': return !_.includes(ruleValue, fieldValue);
    case 'in': return _.includes(ruleValue, fieldValue);
    default: return undefined;
  }
}

const checkModulePermissionRules =(ruleInfoArray, data) => ruleInfoArray?.find(ruleInfo => !checkModulePermissionRule(ruleInfo, data)) === undefined;

const checkModulePermission = (action, actionType, modulePermissionsAndRules, moduleType, ruleCheckData, ignoreRules = false) => {
  const { modulePermissions, modulePermissionRules } = modulePermissionsAndRules;
  if(String(moduleType ?? "").length === 0 || !modulePermissions) return false;
  if(!modulePermissions[moduleType]) return false;
  const permissionValue = modulePermissions[moduleType]?.find((permission) => {
    if(actionType && String(actionType).length > 0) return permission === `${action ? (action+"_") : ""}${actionType}`;
    return String(permission).split("_")[0] === action;
  });
  if(permissionValue === undefined) return false;
  const requiredPermissionRule = modulePermissionRules[moduleType] && modulePermissionRules[moduleType][_.camelCase(permissionValue)];
  if(requiredPermissionRule && !ignoreRules) {
    const ruleCheck = checkModulePermissionRules(requiredPermissionRule, ruleCheckData);
    if(ruleCheck === undefined) return false;
    return ruleCheck;
  }
  return true;
}

export const checkModuleAddPermission = (modulePermissionsAndRules, moduleType, addType, ruleCheckData) =>
  checkModulePermission('add', addType, modulePermissionsAndRules, moduleType, ruleCheckData);

export const checkModuleViewPermission =  (modulePermissionsAndRules, moduleType, viewType, ruleCheckData) =>
  checkModulePermission('view', viewType, modulePermissionsAndRules, moduleType, ruleCheckData);

export const checkModuleEditPermission = (modulePermissionsAndRules, moduleType, editType, ruleCheckData) =>
  checkModulePermission('update', editType, modulePermissionsAndRules, moduleType, ruleCheckData);

export const checkModuleDeletePermission = (modulePermissionsAndRules, moduleType, deleteType, ruleCheckData) =>
  checkModulePermission('delete', deleteType, modulePermissionsAndRules, moduleType, ruleCheckData);

export const checkModuleGeneralPermission = (modulePermissionsAndRules, moduleType, actionType, ruleCheckData, ignoreRules = false) =>
  checkModulePermission('', actionType, modulePermissionsAndRules, moduleType, ruleCheckData, ignoreRules);

export const checkPageViewPermission = (menuItems, currentPage) => {
  if(!menuItems || !currentPage) return false;
  return _.toPairs(menuItems ?? {}).find((menuItem) => {
    if(menuItem[0] === currentPage) return true;
    if(menuItem[1]?.children) return checkPageViewPermission(menuItem[1]?.children, currentPage);
    return false;
  }) !== undefined;
}

export const getMenuItemFromKey = (menuItems, searchKey) => {
  if(!menuItems || !String(searchKey ?? "").length) return null;
  let resultMenuItem = null;
   _.toPairs(menuItems ?? {}).find((menuItem) => {
    if(menuItem[0] === searchKey) {
      resultMenuItem = menuItem[1];
      return true
    };
    if(menuItem[1]?.children){
       const innerSearch = getMenuItemFromKey(menuItem[1]?.children, searchKey);
       if(innerSearch) {
         resultMenuItem = innerSearch;
         return true;
       }
      }
    return false;
  })
  return resultMenuItem;
}

export const getCategorySlug = (moduleCode) => CATEGORY_SLUGS[moduleCode] ?? "";
export const getCategoryType = (categorySlug) => _.findKey(CATEGORY_SLUGS, (item) => item === categorySlug) ?? "";
export const getDefaultMenuItem = (menuItem) => !_.values(menuItem?.children ?? {})?.length
  ? menuItem ?? {}
  : getDefaultMenuItem(_.values(menuItem?.children)[0]);

const getActiveMenuItem = (menuItems, ROUTE_NAME) => {
  if (_.isEmpty(menuItems)) return;
  let matchedSubMenu;
  _.values(menuItems).find((menuItem) => {
    if (_.isEmpty(menuItem?.children)) {
      if (menuItem?.route === ROUTE_NAME) {
        matchedSubMenu = menuItem;
        return true;
      } return false;
    };
    matchedSubMenu = getActiveMenuItem(menuItem?.children, ROUTE_NAME);
    if(matchedSubMenu) return true;
    return false;
  });
  if(matchedSubMenu) return matchedSubMenu;
  const matchedMenu = _.find(menuItems, { route: ROUTE_NAME });
  return matchedMenu ?? null;
}

export const getActivePageCode = (menuItems, path) => {
  const ROUTE_NAME = _.findKey(VW_ROUTES, (route) => route === path);
  if(!ROUTE_NAME) return null;
  const activeMenuItem = getActiveMenuItem(menuItems, ROUTE_NAME);
  if(_.isEmpty(activeMenuItem)) return null;
  return activeMenuItem?.code;
}

export const formatIsoDate = (inputDate) => {
  const timezoneOffset = inputDate.getTimezoneOffset();
  const convertedDate = new Date(
    inputDate.getTime() - timezoneOffset * 60 * 1000
  );
  const formattedDate = convertedDate.toISOString().split(".")[0] + "+08:00";
  return formattedDate;
};

export const getLocalDateTime = (dateTime, showSecond = true) => formatTextWithFallback(dateTime ? moment(moment(dateTime).utc().toDate()).local().format(`DD-MM-YYYY hh:mm${showSecond ? ':ss' : ''} a`) : null);

export const convertToYearFirst = (inputDate) => inputDate ? moment(inputDate, "DD-MM-YYYY").format("YYYY-MM-DD") : null;
export const convertToDayFirst = (inputDate) => inputDate ? moment(inputDate, "YYYY-MM-DD").format("DD-MM-YYYY") : "";
export const getYearFirst = (inputDate) => inputDate ? moment(inputDate, "YYYY-MM-DD").toDate() : null;
export const getDayFirst = (inputDate) => inputDate ? moment(inputDate, "DD-MM-YYYY").toDate() : null;
export const convertToDayTime = (inputDate) => inputDate ? moment(inputDate, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY hh:mm A") : "";

export const getCurrentWindowParams = () => String(window.location.hash.split("?")[1]).length > 0 ? new URLSearchParams(window.location.hash.split("?")[1]) : null;


export const filterIdsMultiselect = (rows) => {
  let selectedIds = [];
  if (rows.length > 0){ rows?.map((item) => selectedIds.push(item.id))};
  return selectedIds;
};

export const getPointColors = (data, mode) => {
  let colorArray = [];
  data?.map((item) => {
    if (mode === "line"){
      colorArray.push(
        item.pointBorderColor ? item.pointBorderColor : "#ff816b"
      );
    }
    if (mode === "bar") {
      colorArray.push(item.backgroundColor ? item.backgroundColor : "#ff816b");
    }
  });
  return colorArray;
};


export const trimDoubleSpace = (data) => {
  return data?.replace(/\s\s+/g, " ");
}

export const trimSingleSpace = (data) => {
  return data?.replace(/\s+/g, "");
}

export const isWidgetRefreshable = (userRoleDetails, widgetData) => 
  checkModuleGeneralPermission(userRoleDetails, "dashboard", "view_widget_update_button")
  && widgetData?.widget?.config?.updateButton === 1;

  export const getIDTypeId = (slug, collection) => {
    let id;
    if (collection?.length > 0) {
      collection.forEach((item) => {
        if (item.slug === slug) {
          id = item.value;
        }
      });
    }
    return id;
  };

  export const getIDNameById = (id, collection) => {
    let idId;
    if (collection?.length > 0) {
      collection.forEach((item) => {
        if (item.value === id) {
          idId = item.slug;
        }
      });
    }
    return idId;
  };

  export const removeEmptyValues = (obj, ignnoreString = false) => {
    for (const key in obj) {
      if ((!ignnoreString && obj[key] === "") || obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  };

  export const formatRegisterModes = (opt = []) => opt?.map((item) =>
     _.has(REGISTER_MODES_CUSTOM_MAP, item?.value)
      ? REGISTER_MODES_CUSTOM_MAP[item?.value]
      : item
  );

  export const getChartScalingConfig = (dataArray = [], chartHeight, maxTickHeightPX, isTopBufferEnabled = false) => {
    const yMax = _.max(dataArray) ?? 0;
    const yMin = _.min(dataArray);

    let roundCoef = String((yMax ?? 0) - (yMin ?? 0)).length
    if(roundCoef !== 0) roundCoef -= 1;
    const roundBeaut = Math.pow(10, roundCoef);
    const roundToBeaut = (value) => _.ceil(value/roundBeaut)*roundBeaut;
  
    const range = roundToBeaut((yMax ?? 0) - (yMin ?? 0));
    const yMinRounded = (yMin < roundBeaut && yMin >= 0) ? roundBeaut : roundToBeaut(yMin);
    let yAxisTickAmount = _.min([
      _.ceil(chartHeight/maxTickHeightPX),
      _.ceil(range/yMinRounded)
    ]) + (isTopBufferEnabled ? 1 : 0);
  
    const yAxisStepSize = roundToBeaut(range/(yAxisTickAmount - (isTopBufferEnabled ? 1 : 0)));
    const yAxisMin = (yMin === 0) ? 0 : roundToBeaut(yMin) - roundBeaut;
    const yAxisMax = (yAxisTickAmount*yAxisStepSize) + yAxisMin;

    return (yMax && _.isNumber(yAxisMin)) 
    ? { 
        max: yAxisMax, 
        tickAmount: yAxisTickAmount,
        stepSize: yAxisStepSize,
        min: yAxisMin,
      } 
    : { tickAmount: 1, max: 1, stepSize: 1, min: 0 }
  }