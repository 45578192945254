import React from "react";

const DashboardLayout = ({ config }) => {
  return (
    <div>
      {config?.map((row, rowIndex) => (
        <div className="row dashboardRow" key={`row-${rowIndex}`}>
          {row.columns.map((column, columnIndex) => (
            <div className={`${column.size} dashboard-column mb-3`} key={`column-${columnIndex}`}>
              {column.widgets.map((widget, widgetIndex) => (
                React.cloneElement(widget, {key: `widget-${widgetIndex}`})
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DashboardLayout;
