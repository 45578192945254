import { useContext } from "react";
import { AppContext } from "../context/AppContext";

const useStorage = () => {
  const { updateFilterParams, filterParams } = useContext(AppContext);

  const setToStorage = (data, append) => {
    if (append) {
      const updatedParams = { ...filterParams, ...data };
      updateFilterParams(updatedParams);
    }else{
      updateFilterParams(data);
    }
  };

  const getFromStorage = (key) => {
    return filterParams[key] || null;
  };

  const removeFromStorage = (key,state) => {
    if (state && state === "onlyThis") {
      const updatedFilterParams = { ...filterParams };
      delete updatedFilterParams[key];
      updateFilterParams(updatedFilterParams);
    } else {
      updateFilterParams({});
    }
  };

  return { setToStorage, getFromStorage, removeFromStorage };
};

export default useStorage;
