import React from "react";

function ValidityExpiryExpansion({ data }) {
  return (
    <>
      <div className="table-expaned-data">
        <div className="item">
          Profile ID <div className="value">{data.personId ?? "--"}</div>
        </div>
        <div className="item">
          Nationality{" "}
          <div className="value">{data.personNationality ?? "--"}</div>
        </div>
      </div>
    </>
  );
}

export default ValidityExpiryExpansion;
