/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react";
import { useQuery, useClient } from "jsonapi-react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import _ from "lodash";
import { getIdNumber, parseIdentificationType } from "../../helpers/helpers";

import {
  API_BASE_URL,
  VW_ROUTES,
  apiRoutes,
  reportDatewoTimeFormat,
} from "../../constants";
import ReportFilter from "../../components/common/ReportFilter/BCAReportFilter";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { AppContext } from "../../context/AppContext";
import GeneralReportExpansion from "./components/GeneralReportExpansion.component";
// import { RiRefreshFill } from "react-icons/ri";
import useStorage from "../../helpers/useStorage";
import {
  defaultFromDateFilterValue,
  defaultToDateFilterValue,
} from "../../helpers/utils";
// import JSONConverter from "./jsonApiconverter";

const GeneralReport = () => {
  const { setToStorage, getFromStorage } = useStorage();
  const [nationality, setNationality] = React.useState([]);
  const { pagePermissions } = React.useContext(AppContext);
  const [statusChoices, setStatusChoices] = React.useState([]);
  const [filterChanged, setFilterChanged] = React.useState(false);
  const [selectedNationality, setSelectedNationality] = React.useState(
    getFromStorage("applicant_profile_report_filter_nation")?.value ?? undefined
  );
  const [selectedStatus, setSelectedStatus] = React.useState(
    getFromStorage("applicant_profile_report_filter_status")?.value ?? undefined
  );
  const [selectedContract, setselectedContract] = React.useState(undefined);
  const [selectedCompany, setSelectedCompany] = React.useState(
    getFromStorage("applicant_profile_report_filter_company")?.value ??
      undefined
  );
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    getFromStorage("applicant_profile_report_page_no") ?? 1
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("applicant_profile_report_rows_per_page") ?? 10
  );
  const [searchQueryString, setSearchQueryString] = React.useState(
    getFromStorage("applicant_profile_report_filter_search") ?? undefined
  );
  const [applicantType, setApplicantType] = React.useState(
    getFromStorage("applicant_profile_report_filter_type")?.value ?? undefined
  );
  const { manageLoader } = React.useContext(AppContext);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const [beforeDate, setBeforeDate] = React.useState(
    getFromStorage("applicant_profile_report_filter_before_date") ??
      defaultFromDateFilterValue(reportDatewoTimeFormat)
  );
  const [afterDate, setAfterDate] = React.useState(
    getFromStorage("applicant_profile_report_filter_after_date") ??
      defaultToDateFilterValue(reportDatewoTimeFormat)
  );
  const [disableExport, setdisableExport] = React.useState(true);

  const history = useHistory();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  useEffect(() => {
    const options = {
      method: "OPTIONS",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      url: apiRoutes.applicantProfile,
    };
    axios(options).then((response) => {
      const {
        data: {
          data: {
            actions: {
              POST: {
                nationality: { choices: nationalityChoices },
                status: { choices: statusChoices },
              },
            },
          },
        },
      } = response;
      setNationality(nationalityChoices);
      setStatusChoices(statusChoices);
    });
  }, []);

  let filter;

  filter = {
    ...(applicantType !== null &&
      applicantType !== undefined &&
      applicantType !== "" && { type: applicantType }),
    ...(searchQueryString !== null &&
      searchQueryString !== undefined &&
      searchQueryString !== "" && { search: searchQueryString }),
    ...(selectedNationality !== null &&
      selectedNationality !== undefined &&
      selectedNationality !== "" && { nationality: selectedNationality }),
    ...(selectedStatus !== null &&
      selectedStatus !== undefined &&
      selectedStatus !== "" && { status: selectedStatus }),
    ...(selectedContract !== null &&
      selectedContract !== undefined &&
      selectedContract !== "" && { contract: selectedContract }),
    ...(selectedCompany !== null &&
      selectedCompany !== undefined &&
      selectedCompany !== "" && { company: selectedCompany }),
    ...(afterDate !== null &&
      afterDate !== undefined &&
      afterDate !== "" && { created_before: afterDate }),
    ...(beforeDate !== null &&
      beforeDate !== undefined &&
      beforeDate !== "" && { created_after: beforeDate }),
  };

  const applicants = useQuery(
    [
      "reports",
      "bca",
      {
        filter: filter,
        page: {
          number: currentPageNumber,
          size: rowsPerPage,
        },
        include: "applicant_photo,company,contract",
      },
    ],
    { client }
  );

  useEffect(() => {
    if (applicants?.data?.length > 0) {
      setdisableExport(false);
    } else {
      setdisableExport(true);
    }
  }, [applicants?.data]);

  const handleUpdateKey = () => {
    if (!filterChanged) {
      applicants.refetch();
    }
    setFilterChanged(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewDetails(mode.applicant);
    }
  };

  const viewDetails = (applicantData) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${applicantData?.id}&type=${applicantData?.applicantType}`,
      state: {
        from: history.location?.pathname,
      },
    });
  };

  const columns = [
    {
      name: "Name",
      selector: (person) => person?.fullName,
      minWidth: 250,
      sortable: true,
      // cell: applicant => <span className="text-capitalize">{`${applicant.fullName}`}</span>,
      cell: (applicant) => (
        <ProfileCard
          data={applicant}
          mode="GReport"
          actions={pagePermissions}
          fields={[
            {
              value: _.replace(applicant.applicantType, "_", " "),
              name: "Type",
            },

            {
              value: parseIdentificationType(applicant),
              name: `${
                applicant.idType && applicant.idType !== "passport"
                  ? applicant.idType.toUpperCase()
                  : applicant.idType
              } Number`,
            },
            {
              value: applicant?.company?.name,
              name: "Company",
            },
            {
              value: applicant?.designation,
              name: "Designation",
            },
          ]}
          actionsToParent={(mode) => getEvent({ mode, applicant })}
        />
      ),
    },
    {
      name: "Applicant Type",
      selector: (person) => person?.applicantType,
      maxWidth: "150px",
      sortable: false,
      cell: (applicant) => (
        <span className="text-capitalize">
          {_.replace(applicant.applicantType, "_", " ")}
        </span>
      ),
      hide: "md",
    },
    {
      name: "ID Number",
      sortable: false,
      hide: "md",
      minWidth: "150px",
      selector: (person) => person?.idType,
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {getIdNumber(applicant)}
        </span>
      ),
    },

    // {
    //   name: "Phone",
    //   selector: "mobileNumber",
    //   sortable: false,
    //   cell: (applicant) => (
    //     <span>{applicant.mobileNumber ? applicant.mobileNumber : "--"}</span>
    //   ),
    //   hide: "md",
    // },

    // {
    //   name: "Nationality",
    //   sortable: false,
    //   hide: "md",
    //   selector: "nationality",
    //   cell: (applicant) =>
    //     _.keyBy(nationality, "value") &&
    //     _.keyBy(nationality, "value")[applicant.nationality] &&
    //     _.keyBy(nationality, "value")[applicant.nationality].display_name,
    // },
    {
      name: "Company",
      sortable: false,
      minWidth: 300,
      selector: (person) => person.company?.name,
      cell: (applicant) => applicant.company && applicant.company.name,
      hide: "md",
    },
    {
      name: "Contractor",

      sortable: false,
      left: true,

      minWidth: "200px",
      selector: (person) => person.contract?.name,
      cell: (applicant) => (
        <>
          <span className="text-capitalize">
            {applicant?.data?.contract?.length > 0 ? (
              <>
                {applicant?.data?.contract?.filter(
                  (c) => c.isActive === true
                )[0]["contractName"] ?? "--"}
              </>
            ) : (
              "--"
            )}
          </span>
        </>
      ),
      hide: "md",
    },

    {
      name: "Designation",
      selector: (person) => person?.designation,
      sortable: false,
      minWidth: 250,
      hide: "md",
    },

    // {
    //   name: "Status",
    //   selector: "status",
    //   sortable: false,
    //   cell: (applicant) => (
    //     <span className="text-capitalize ">{applicant.status}</span>
    //   ),
    //   hide: "md",
    // },
    {
      name: "Actions",
      sortable: false,

      center: true,
      maxWidth: 100,
      minWidth: 100,
      cell: (staff) => (
        <span
          className="simple-icon-eye user-role-action-icons view"
          onClick={() => viewDetails(staff)}
        ></span>
      ),
      right: true,
      hide: "md",
    },
  ];

  const onChangePage = (page) => {
    setCurrentPageNumber(page);
    setToStorage({ applicant_profile_report_page_no: page }, true);
  };

  const onSearchInputChange = (searchQuery) => {
    if (searchQuery) {
      setSearchQueryString(searchQuery);
    } else {
      setSearchQueryString(undefined);
    }
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({ applicant_profile_report_rows_per_page: rows }, false);
    setRowsPerPage(rows);
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };

  const onExport = () => {
    manageLoader(true);

    if (applicants?.data?.length > 0) {
      let filter = "";
      if (searchQueryString !== "" && searchQueryString !== undefined) {
        filter = `&filter[search]=${searchQueryString}`;
      }
      if (selectedNationality !== undefined) {
        filter += `&filter[nationality]=${selectedNationality}`;
      }
      if (selectedStatus !== undefined) {
        filter += `&filter[status]=${selectedStatus}`;
      }
      if (selectedContract !== undefined) {
        filter += `&filter[contract]=${selectedContract}`;
      }
      if (selectedCompany !== undefined) {
        filter += `&filter[company]=${selectedCompany}`;
      }

      if (applicantType !== undefined) {
        filter += `&filter[type]=${applicantType}`;
      }

      if (beforeDate !== undefined && afterDate !== undefined) {
        filter += `&filter[created_before]=${afterDate}&filter[created_after]=${beforeDate}`;
      }

      filter = filter[0] === "&" ? filter.slice(1) : filter;
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          // 'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: `JWT ${token}`,
        },
        url: `${apiRoutes.bcaExport}?${filter}`,
      };
      axios(options).then((response) => {
        manageLoader(false);
        setShowSuccessModal(true);
        setShowSuccessMessage(response.data.data.message);
      });
    } else {
      manageLoader(false);
      setShowSuccessModal(true);
      setShowSuccessMessage("There is no data to export");
    }
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h4>Man-hour Report </h4>
            {/* <sup>
              {" "}
              <span className="d-block d-md-none pl-2">
                <RiRefreshFill
                  className={`${
                    applicants?.isFetching ? "rotating" : ""
                  } text-primary refetch-icon`}
                  onClick={() => handleUpdateKey()}
                />
              </span>
            </sup> */}

            <div className="cmn-btn">
              {/* <button className="btn btn-secondary">Export</button> */}
            </div>

            <div
              className="tableFilterButton"
              title="Advance Search"
              onClick={() => showFilter(true)}
            >
              {/* <span></span>
             <span></span>
             <span></span> */}
              <i className="iconsminds-filter-2"></i>
            </div>
          </div>
          <div className="ReportFilterHolder">
            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="reportSearch">
                  <ReportFilter
                    onNationalityChange={setSelectedNationality}
                    onStatusChange={setSelectedStatus}
                    onCompanyChange={setSelectedCompany}
                    onContractorChange={setselectedContract}
                    onApplicantTypeChange={setApplicantType}
                    nationality={nationality}
                    handleUpdateKey={handleUpdateKey}
                    statusList={statusChoices}
                    setCurrentPageNumber={setCurrentPageNumber}
                    setFilterChanged={setFilterChanged}
                    loading={applicants?.isFetching}
                    onSearchInputChange={onSearchInputChange}
                    onExport={onExport}
                    onBeforeDateChange={setBeforeDate}
                    onAfterDateChange={setAfterDate}
                    buttonVisibility={disableExport}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            {/* <JSONConverter /> */}
            <div
              className={
                applicants.isFetching
                  ? "card-body remove-header dataTableOuter reportTable table-loader"
                  : "card-body remove-header dataTableOuter reportTable"
              }
            >
              <DataTable
                subHeader={true}
                //subHeaderComponent={<> <button className="btn btn-primary">Export</button><SearchBar onSearchInputChange={onSearchInputChange} /></>}
                progressPending={applicants.isFetching}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={applicants?.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={applicants?.meta?.pagination?.count}
                paginationDefaultPage={currentPageNumber}
                expandableRows
                expandableRowsComponent={GeneralReportExpansion}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralReport;
