import React from "react";
import { useHistory } from "react-router-dom";
import NotificationCardItem from "../NotificationCardItem/NotificationCardItem";
const PeopleOverstayWidget = ({ widgetData, slug, title, hasLink, route }) => {
  const history = useHistory();
  const handleRedirection = () => {
    if (hasLink) {
      history.push(route);
    }
  };
  return (
    <NotificationCardItem
      skin="alert-card"
      icon={"iconsminds-conference"}
      title={title}
      slug={slug}
      widgetData={widgetData}
      handleRedirection={handleRedirection}
    />
  );
};

export default PeopleOverstayWidget;
