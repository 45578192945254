/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient } from "jsonapi-react";

import { AppContext } from "../../context/AppContext";
import DashboardChartArea from "../../components/DashboardChartArea/DashboardChartArea";

import {
  mapWeeklyVisitData,
  mapThreeMonthsVisitData,
} from "../../mappers/dashboardMappers";

import DashboardLPRChart from "../../components/DashboardChartArea/DashboardLPRChart";
import DashboardChartAreaBar from "../../components/DashboardChartArea/DashboardChartAreaBar";
import DashboardLPRChartBar from "../../components/DashboardChartArea/DashboardLPRChartBar";
import { DEVICE_SERVICE_URL, apiRoutes } from "../../constants";
import axios from "axios";
import { useContext } from "react";
import DashboardLoader from "../Dashboard/DashboardLoader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Analytics = () => {
  const { activePageCode, activePagePath } = useContext(AppContext);
  const location = useLocation();

  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };



  const [layoutConfig, setLayoutConfig] = React.useState();

  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const { data: dashboardLayout } = useQuery(
    location.pathname === activePagePath &&
    `${apiRoutes.dashboardLayout}${activePageCode}`,
    {
      client,
    }
  );

  React.useEffect(() => {
    if (dashboardLayout) {
      const widgetColumns = dashboardLayout?.layout?.config?.rows.map((row) =>
        row.columns.map(() => [])
      );

      dashboardLayout.widgets.forEach(async (widget) => {
        const [row, column] = widget.position.split(",");
        if (widget.widget.slug === "vehicle-visit-graph-3-months") {
          widgetColumns[row - 1][column - 1].push(
            <DashboardLPRChartBar
              widget={widget}
            />
          );
        } else if (widget.widget.slug === "vehicle-visit-graph-7-days") {
          widgetColumns[row - 1][column - 1].push(
            <DashboardLPRChart
              widget={widget}
            />
          );
        } else if (widget.widget.slug === "people-visit-graph-3-months") {
          widgetColumns[row - 1][column - 1].push(
            <DashboardChartAreaBar widget={widget} />
          );
        } else if (widget.widget.slug === "people-visit-graph-7-days") {
          widgetColumns[row - 1][column - 1].push(
            <DashboardChartArea widget={widget} />
          );
        }


      });
      const finalLayout = dashboardLayout?.layout?.config?.rows.map(
        (row, rowIndex) => ({
          columns: row.columns.map((column, columnIndex) => ({
            size: column.size,
            widgets: widgetColumns[rowIndex][columnIndex],
          })),
        })
      );
      setLayoutConfig(finalLayout);
    }
  }, [dashboardLayout]);

  return (
    <>
      {layoutConfig ? (
        <div>
          {layoutConfig?.map((row, rowIndex) => (
            <div className="row dashboardRow" key={rowIndex}>
              {row.columns.map((column, columnIndex) => (
                <div className={`col-md-${column.size} mb-3`} key={columnIndex}>
                  {column.widgets.map((widget, widgetIndex) => (
                    <div key={widgetIndex}>{widget}</div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <DashboardLoader />
      )}
    </>
  );
};

export default Analytics;
