import React from "react";
import xIcon from "../../../assets/images/x_icon.png";

const ClearButton = ({ onClick = () => {}, hideClear = false }) => {
  if (hideClear) return null;
  return (
    <button className="btn clear-button" onClick={onClick}>
      <img src={xIcon} />
    </button>
  );
};

export default ClearButton;
