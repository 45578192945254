import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import queryString from "query-string";
import Select from "react-select";
import {
  convertToDayFirst,
  getDayFirst,
  trimDoubleSpace,
  validatorModule,
} from "../../helpers/helpers";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import logo from "../../assets/images/branding-w.png";
import profileimagePlaceholder from "../../assets/images/profileimagePlaceholder.jpg";
import Webcam from "react-webcam";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./PublicRegistration.scss";
import { API_BASE_URL, apiRoutes } from "../../constants";
import { useMutation, useQuery } from "jsonapi-react";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import useCompanyOptions from "../../components/common/CommonDeliverables/useCompanyOptions";
import Loader from "../../components/common/Loader/Loader";
import useInviteValidity from "../../hooks/useInviteValidity/useInviteValidity";
import NavigationGuard from "../../components/common/NavigationGuard/NavigationGuard";
import ReactSelect from "react-select";

const defaultErrorMessage = "Something went wrong. Please try later";

const PublicRegistration = ({ location }) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const { search } = history.location;
  let params = queryString.parse(search);
  const { isValidated } = useInviteValidity();
  const [newProfileData, setNewProfileData] = useState(null);
  const [personId, setPersonId] = React.useState(params.id ?? params.id);
  const [userCreated, setUserCreated] = React.useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [submitButtonPressed, setSubmitButtonPressed] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(params.edit ? true : false);
  const [editedFields, setEditedFields] = React.useState([]);
  const [isPassPort, setIsPassPort] = React.useState(false);
  const [idTypeEdited, setIdtypeEdited] = React.useState("");
  const [formDisabled, setFormDisabled] = useState(false);

  const [capturedImage, setCapturedImage] = useState(null);
  const [crop, setCrop] = useState(16 / 9);
  const [croppedImage, setCroppedImage] = useState(null);
  const [savedImage, setSavedImage] = useState(null);
  const [profileAvatar, setProfileAvatar] = useState(null);
  const [showCam, setShowCam] = useState(false);
  const webcamRef = React.createRef();
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [appOptions, setAppOptions] = React.useState({});
  const [showLoader, setShowLoader] = React.useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const {
    companies,
    handleCompanySearch,
    loadNextCompanyOptionsPage,
    isCompanyLoading,
  } = useCompanyOptions();

  const handleCapture = () => {
    const capturedImageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(capturedImageSrc);
    setCroppedImage(capturedImageSrc);
    setCrop({ aspect: 4 / 3 });
    if (capturedImageSrc) {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0);

        canvas.toBlob((blob) => {
          const file = new File([blob], "profile_image.png", {
            type: "image/png",
            lastModified: Date.now(),
          });

          setProfileAvatar(file);
        }, "image/png");
      };
      image.src = capturedImageSrc;
    }
  };

  const handleCrop = (completedCrop) => {
    if (!completedCrop.width || !completedCrop.height) return;
    const image = new Image();
    image.src = capturedImage;
    const canvas = document.createElement("canvas");
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      completedCrop.x,
      completedCrop.y,
      completedCrop.width,
      completedCrop.height,
      0,
      0,
      completedCrop.width,
      completedCrop.height
    );
  };

  const initialState = {
    id_type: "", //nric, fin, passport
    full_name: "",
    id_number: "",
    mobile_number: "",
    email: "",
    date_of_birth: "",
    nationality: "",
    gender: "male",
    from_date_time: "",
    host_name: "",
    purpose_of_visit: "",
    company_id: "",
  };
  const initialErrorState = {
    full_name: "",
    id_type: "",
    mobile_number: "",
    date_of_birth: "",
    nationality: "",
    gender: "",
    trade: "",
    from_date_time: "",
    host_name: "",
    purpose_of_visit: "",
    company_id: "",
  };

  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [formState, setFormState] = React.useState(initialState);

  const validationFormat = [
    {
      field: "nationality",
      isRequired: true,
      fieldName: "Nationality",
    },
    {
      field: "date_of_birth",
      isBirthDay: true,
      isRequired: true,
      fieldName: "Date Of Birth",
    },
    {
      field: "from_date_time",
      isRequired: true,
      fieldName: "Visit Date",
    },

    {
      field: "email",
      isEmail: true,
      isRequired: true,
      fieldName: "Email Address",
    },
    {
      field: "mobile_number",
      isPhoneNumber: true,
      isRequired: false,
      fieldName: "Phone number",
    },

    {
      field: "full_name",
      minLength: 3,
      fieldName: "Full Name",
      isRequired: true,
    },

    {
      field: "id_type",
      fieldName: "ID Type",
      isRequired: true,
    },
    {
      field: "id_number",
      fieldName: "ID Number",
      isAlphaNumeric: true,
      isRequired: true,
      minLength: 4,
    },
    {
      field: "company_id",
      fieldName: "Company",
      isRequired: true,
    },
    {
      field: "host_name",
      fieldName: "Name of PIC being visited",
      isRequired: true,
      minLength: 3,
    },
    {
      field: "purpose_of_visit",
      fieldName: "Purpose of Visit",
      isRequired: true,
      minLength: 3,
    },
  ];

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = "";
        return "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  const onFocusChange = () => {
    if (_.filter(Object.values(errorState)).length !== 0) {
      let errors = validatorModule(formState, validationFormat);
      setErrorState(errors);
    }
  };

  const firstInputPersonal = React.useRef();

  const onInputChange = ({ name, value }) => {
    let formData = { ...formState };
    let newEditedFields = [name];
    if (name === "id_type" && value === "passport") {
      setIsPassPort(true);
    } else if (name === "id_type" && value !== "passport") {
      setIsPassPort(false);
    }
    if (name === "id_number") {
      newEditedFields = [...newEditedFields, "id_type"];
      if (value.length <= 16) {
        formData[name] = value;
      }
    } else if (name === "full_name") {
      formData[name] = value ? trimDoubleSpace(value) : "";
    } else {
      formData[name] = value ? value : "";
    }
    if (name === "id_type") {
      newEditedFields = [...newEditedFields, "id_number"];
      setIdtypeEdited(value);
    }
    setFormState(formData);
    setEditedFields((currFields) =>
      _.includes(currFields, newEditedFields)
        ? currFields
        : [...currFields, ...newEditedFields]
    );

    const fieldsToCheck = Object.keys(formData).filter(
      (key) => key !== "gender"
    );
    const isAnyFieldFilled = fieldsToCheck.some(
      (field) => formData[field] !== ""
    );
    setIsFormDirty(isAnyFieldFilled);
  };

  const getIdOptions = () => {
    let idTypeArray = [{ label: "Select", value: "" }];
    if (appOptions?.idType?.length > 0) {
      appOptions?.idType.forEach((item) => {
        if (item.slug === "cidb") return;
        idTypeArray.push({
          label: _.upperCase(item?.display_name),
          value: item?.slug,
        });
      });
    }
    return idTypeArray;
    return [];
  };

  const getIdValues = (v) => {
    let idTypeObj = { label: "Select", value: "" };
    if (appOptions?.idType?.length > 0) {
      appOptions?.idType.forEach((item) => {
        if (item.value === v)
          idTypeObj = {
            label:
              item.display_name === "Nric"
                ? "NRIC"
                : item.display_name === "Fin"
                ? "FIN"
                : item.display_name,
            value: item.value,
          };
      });
    }
    return idTypeObj;
  };

  const getNationalityOptions = () => {
    let idTypeArray = [{ label: "Select", value: "" }];
    if (appOptions?.nationality?.length > 0) {
      appOptions?.nationality.forEach((item) => {
        idTypeArray.push({
          label: item.display_name,
          value: item.value,
        });
      });
    }
    return idTypeArray;
  };

  const getNationalityValues = (v) => {
    let idTypeObj = { label: "Select", value: "" };
    if (appOptions?.nationality?.length > 0) {
      appOptions?.nationality.forEach((item) => {
        if (item.value === v)
          idTypeObj = {
            label: item.display_name,
            value: item.value,
          };
      });
    }
    return idTypeObj;
  };

  const [profileData, setProfileData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    if (
      isEdit &&
      !userCreated &&
      !isUpdateLoading &&
      String(personId ?? "").length
    ) {
      setIsLoading(true);
      const { data } = await getProfileData();
      setIsLoading(false);
      setProfileData(data);
    }
  }, [personId, isEdit]);

  // const { data: profileData, isLoading } = (isEdit && personId && !userCreated) ? profileDataQueryHook : {};

  React.useEffect(() => {
    if (submitButtonPressed) {
      onFocusChange();
    }
  }, [formState]);

  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const createUpdateAction = async () => {
    const formDataToSend = { ...formState };
    delete formDataToSend.from_date_time;
    delete formDataToSend.host_name;
    delete formDataToSend.purpose_of_visit;
    const postData = {
      profile: {
        ...formDataToSend,
        date_of_birth: formState.date_of_birth
          ? convertToDayFirst(formState.date_of_birth)
          : null,
      },
      photo: {
        avatar: profileAvatar ? profileAvatar : null,
      },
      pass: {
        from_date_time: formState.from_date_time
          ? convertToDayFirst(formState.from_date_time)
          : null,
        host_name: formState.host_name,
        purpose_of_visit: formState.purpose_of_visit,
      },
      ics: [
        {
          ic_type: (() => {
            const idTypeIndex = appOptions?.idType.findIndex(
              (type) => type.slug == formState.id_type
            );
            return appOptions?.idType[idTypeIndex].value;
          })(),
          ic_number: formState.id_number,
          document: uploadedFiles ? uploadedFiles : null,
        },
      ],
    };
    setShowLoader(true);
    await Axios.post(
      `${API_BASE_URL}${apiRoutes.publicRegisterPost}`,
      postData,
      config
    )
      .then((response) => {
        if (response.status === 201) {
          setIsFormDirty(false);
          setShowSuccessMessage(
            response?.data?.data?.message ||
              "Registration successfully completed"
          );
          resetForm();
          setShowSuccess(true);
        }
      })
      .catch((error) => {
        if ([400].includes(error?.response?.status)) {
          console.error(error?.response?.data?.errors);
          addToast(defaultErrorMessage, {
            appearance: "error",
          });
        } else if ([406].includes(error?.response?.status)) {
          const errors = error?.response?.data?.errors;
          errors.forEach((item) => {
            addToast(item?.detail || defaultErrorMessage, {
              appearance: "error",
            });
          });
        } else {
          addToast(error?.detail || defaultErrorMessage, {
            appearance: "error",
          });
        }
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const onSubmitForm = async (e) => {
    e?.preventDefault();
    setSubmitButtonPressed(true);
    let errors = validatorModule(formState, validationFormat);
    setErrorState(errors);
    if (Object.keys(errors).length === 0 && savedImage) {
      createUpdateAction();
    }
  };

  const resetForm = () => {
    setFormState(initialState);
    setErrorState(initialErrorState);
    setPersonId("");
    setUserCreated(false);
    setNewProfileData(null);
    setSubmitButtonPressed(false);
    setIsEdit(false);
    setEditedFields([]);
    setProfileAvatar(null);
  };

  const showCamPopup = () => {
    setShowCam(true);
  };

  const closeImagePopup = () => {
    setShowCam(false);
    setCapturedImage(null);
  };

  const cropDone = () => {
    setShowCam(false);
    setCapturedImage(null);
    setSavedImage(croppedImage ?? null);
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setUploadedFiles(selectedFile);
  };

  React.useEffect(() => {
    const options = {
      method: "OPTIONS",
      headers: {
        "content-type": "application/json",
      },
      url: apiRoutes.publicRegister,
    };

    Axios(options).then((response) => {
      const {
        data: {
          data: {
            actions: {
              POST: {
                nationality: { choices: nationalityChoices } = { choices: [] },
                gender: { choices: gender } = { choices: [] },
              },
            },
            related_field_data: {
              ic_types: { choices: icTypes } = { choices: [] },
            },
          },
        },
      } = response;

      setAppOptions({
        nationality: nationalityChoices,
        idType: icTypes,
        gender: gender,
      });
    });
  }, []);

  if (isValidated === null)
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );

  if (!isValidated)
    return (
      <div className="message-wrapper">
        <div className="success">
          <div className="icon iconsminds-close"></div>
          <div className="message">
            {DISPLAY_TEXTS.PUBLIC_INVITE_INVALID_LINK}
          </div>
        </div>
      </div>
    );

  return (
    <>
      {!showSuccess && (
        <div className="form-reg">
          {showCam && (
            <div className="cam-wrapper">
              <div className="cam">
                <div className="cam-tools">
                  Capture Image
                  <button onClick={closeImagePopup} className="close"></button>
                </div>
                {capturedImage ? (
                  <ReactCrop
                    crop={crop}
                    onChange={(newCrop) => setCrop(newCrop)}
                    onComplete={handleCrop}
                  >
                    <img src={capturedImage} />
                  </ReactCrop>
                ) : (
                  <Webcam
                    audio={false}
                    mirrored={true}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={{ width: "100%" }}
                  />
                )}
                <div className="capture-buttons">
                  {!capturedImage ? (
                    <button onClick={handleCapture}>
                      <i className="simple-icon-camera"></i>
                    </button>
                  ) : (
                    <>
                      <button onClick={() => setCapturedImage(null)}>
                        <i className="simple-icon-refresh"></i>
                      </button>
                      {croppedImage && (
                        <button className="done" onClick={cropDone}>
                          <i className="simple-icon-check"></i>
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="form-header">
            <img src={logo} alt="" />
            <p>Self Registration</p>
          </div>
          <div className="profile-image-holder">
            <div className="profile-image">
              {savedImage ? (
                <img src={savedImage} alt="Cropped" />
              ) : (
                <img src={profileimagePlaceholder} alt="placeholder" />
              )}

              <button className="cam_icon" onClick={showCamPopup}>
                <i className="simple-icon-camera"></i>
              </button>

              {submitButtonPressed && !savedImage && (
                <p className="profile-image-error">
                  Profile image is required!
                </p>
              )}
            </div>
          </div>
          <div className="form-wrapper">
            <form id="form-step-1" onSubmit={onSubmitForm}>
              <div className="form-row">
                <div
                  className={`form-group position-relative error-l-75 col-md-12 ${
                    errorState.full_name ? "hasValidationError" : ""
                  }`}
                >
                  <label htmlFor="firstName">
                    Full Name<i>*</i>
                  </label>
                  <input
                    type="text"
                    ref={firstInputPersonal}
                    className="form-control"
                    name="full_name"
                    value={formState.full_name}
                    onChange={(e) => onInputChange(e.target)}
                    id="full_name"
                    placeholder="Full Name"
                    disabled={isEdit && isUserRoleAC}
                  />
                  <p className="error-message">
                    {errorState && errorState.full_name
                      ? errorState.full_name
                      : ""}
                  </p>
                </div>
              </div>
              <div className="form-row">
                <div
                  className={`form-group position-relative error-l-75 col-sm-4 col-md-4 ${
                    errorState.id_type ? "hasValidationError" : ""
                  }`}
                >
                  <label htmlFor="passport">ID Type*</label>

                  <Select
                    className="formSelect"
                    classNamePrefix="react-select-form"
                    onChange={(e) =>
                      onInputChange({ name: "id_type", value: e.value })
                    }
                    name="id_type"
                    options={getIdOptions()}
                    isDisabled={isEdit && isUserRoleAC}
                    placeholder="Select"
                  />
                  <p className="error-message">
                    {errorState && errorState.id_type ? errorState.id_type : ""}
                  </p>
                </div>

                <div
                  className={`form-group position-relative error-l-75 col-sm-8 col-md-4 ${
                    errorState.id_number ? "hasValidationError" : ""
                  }`}
                >
                  <label htmlFor="id_number">ID Number* </label>
                  <input
                    type="text"
                    disabled={!formState.id_type || (isEdit && isUserRoleAC)}
                    className="form-control"
                    name="id_number"
                    value={formState?.id_number?.toUpperCase()}
                    id="id_number"
                    placeholder="ID Number"
                    onChange={(e) => {
                      setFormDisabled(true);
                      onInputChange(e.target);
                    }}
                  />
                  <p className="error-message">
                    {errorState && errorState.id_number
                      ? errorState.id_number
                      : ""}
                  </p>
                </div>

                {/* <div
                className={`form-group position-relative error-l-75 col-sm-12 col-md-4 ${
                  submitButtonPressed && !uploadedFiles
                    ? "hasValidationError"
                    : ""
                }`}
              > */}

                <div
                  className={`form-group position-relative error-l-75 col-sm-12 col-md-4`}
                >
                  <div className="uploadLabel">
                    Upload ID
                    <div className="image-upload-placeholder">
                      <div className="fileName">{uploadedFiles?.name}</div>
                      <label className="button">
                        Browse Files
                        <input
                          type="file"
                          className="uploadInput"
                          onChange={(e) => handleFileUpload(e)}
                        />
                      </label>
                    </div>
                  </div>
                  {/* {submitButtonPressed && !uploadedFiles && (
                  <p className="error-message">Id file upload required!</p>
                )} */}
                </div>
              </div>

              <div className="form-row">
                <div
                  className={`form-group position-relative error-l-100 col-md-6 ${
                    errorState.nationality ? "hasValidationError" : ""
                  }`}
                >
                  <label htmlFor="nationality">
                    Nationality<i>*</i>
                  </label>
                  <Select
                    className="formSelect"
                    classNamePrefix="react-select-form"
                    name="nationality"
                    options={getNationalityOptions()}
                    isDisabled={isEdit && isUserRoleAC}
                    value={
                      formState.nationality && formState.nationality !== ""
                        ? getNationalityValues(formState.nationality)
                        : { label: "Select", value: "" }
                    }
                    onChange={(e) =>
                      onInputChange({
                        name: "nationality",
                        value: e.value,
                      })
                    }
                  />
                  <p className="error-message">
                    {errorState && errorState.nationality
                      ? errorState.nationality
                      : ""}
                  </p>
                </div>

                <div
                  className={`form-group position-relative error-l-75 col-md-6 ${
                    errorState.company_id ? "hasValidationError" : ""
                  }`}
                >
                  <label htmlFor="company_id">Company*</label>
                  <LazyLoadedSelect
                    className="formSelect"
                    classNamePrefix="react-select-form"
                    loadNextOptions={loadNextCompanyOptionsPage}
                    name="company_id"
                    onChange={(e) =>
                      onInputChange({ name: "company_id", value: e.value })
                    }
                    onInputChange={(value) => handleCompanySearch(value)}
                    options={companies}
                    placeholder="Select"
                    value={company}
                    isLoading={isCompanyLoading}
                    isDisabled={isEdit && isUserRoleAC}
                  />
                  <p className="error-message">
                    {errorState && errorState.company_id
                      ? errorState.company_id
                      : ""}
                  </p>
                </div>
              </div>
              <div className="form-row">
                <div
                  className={`form-group position-relative error-l-75 col-md-6 ${
                    errorState.email ? "hasValidationError" : ""
                  }`}
                >
                  <label htmlFor="email">
                    Email address<i>*</i>
                  </label>
                  <input
                    //   disabled={defaultEmail && defaultEmail.readOnlyEmail}
                    type="email"
                    className="form-control"
                    name="email"
                    value={formState.email}
                    id="email"
                    placeholder="Enter your email address"
                    onChange={(e) => onInputChange(e.target)}
                  />
                  <p className="error-message">
                    {errorState && errorState.email ? errorState.email : ""}
                  </p>
                </div>

                <div
                  className={`form-group position-relative mb-1 col-md-6 ${
                    errorState.date_of_birth ? "hasValidationError" : ""
                  }`}
                >
                  <label>
                    Date of Birth<i>*</i>{" "}
                    <i
                      className="infoButton"
                      data-tip="You must be at least 16 years"
                    >
                      i
                    </i>
                  </label>
                  <div className="input-group date">
                    <DatePicker
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                      maxDate={moment().subtract(16, "y").toDate()}
                      popperPlacement="top-end"
                      popperClassName="some-custom-class"
                      selected={getDayFirst(formState?.date_of_birth)}
                      onChange={(date) =>
                        onInputChange({
                          name: "date_of_birth",
                          value: date,
                        })
                      }
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      disabled={isEdit && isUserRoleAC}
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                        },
                      }}
                    />
                    {/* <input className="dateFocusCrack" ref={dateField} /> */}
                  </div>
                  <p className="error-message">
                    {errorState && errorState.date_of_birth
                      ? errorState.date_of_birth
                      : ""}
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-md-6">
                  <div>
                    <label>
                      Gender<i>*</i>
                    </label>
                    <div>
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            value="male"
                            className="form-check-input"
                            onChange={(e) => onInputChange(e.target)}
                            name="gender"
                            checked={formState.gender === "male" ? true : false}
                          />
                          Male
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            value="female"
                            className="form-check-input"
                            onChange={(e) => onInputChange(e.target)}
                            name="gender"
                            checked={
                              formState.gender === "female" ? true : false
                            }
                          />
                          Female
                        </label>
                      </div>
                      <div className="form-check-inline">
                        <label className="form-check-label">
                          <input
                            type="radio"
                            value="other"
                            className="form-check-input"
                            onChange={(e) => onInputChange(e.target)}
                            name="gender"
                            checked={
                              formState.gender === "other" ? true : false
                            }
                          />
                          Prefer not to say
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`form-group position-relative error-l-75 col-md-6 ${
                    errorState.mobile_number ? "hasValidationError" : ""
                  }`}
                >
                  <label htmlFor="mobileNumber">Phone Number</label>
                  <input
                    type="text"
                    className="form-control"
                    name="mobile_number"
                    value={formState.mobile_number}
                    id="mobileNumber"
                    placeholder="Enter your phone number"
                    onChange={(e) => onInputChange(e.target)}
                  />
                  <p className="error-message">
                    {errorState && errorState.mobile_number
                      ? errorState.mobile_number
                      : ""}
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div
                  className={`form-group position-relative col-md-6 ${
                    errorState.from_date_time ? "hasValidationError" : ""
                  }`}
                >
                  <label>
                    Date of Visit<i>*</i>
                  </label>
                  <div className="input-group date">
                    <DatePicker
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                      minDate={moment().toDate()}
                      popperPlacement="top-end"
                      popperClassName="some-custom-class"
                      selected={getDayFirst(formState?.from_date_time)}
                      onChange={(date) =>
                        onInputChange({
                          name: "from_date_time",
                          value: date,
                        })
                      }
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      disabled={isEdit && isUserRoleAC}
                      popperModifiers={{
                        preventOverflow: {
                          enabled: true,
                        },
                      }}
                    />
                    {/* <input className="dateFocusCrack" ref={dateField} /> */}
                  </div>
                  <p className="error-message">
                    {errorState && errorState.from_date_time
                      ? errorState.from_date_time
                      : ""}
                  </p>
                </div>

                <div
                  className={`form-group position-relative mb-1 col-md-6 ${
                    errorState.host_name ? "hasValidationError" : ""
                  }`}
                >
                  <label>
                    Name of PIC being visited<i>*</i>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="host_name"
                    value={formState.host_name}
                    id="host_name"
                    placeholder="Enter the name of PIC being visited"
                    onChange={(e) => onInputChange(e.target)}
                  />
                  <p className="error-message">
                    {errorState && errorState.host_name
                      ? errorState.host_name
                      : ""}
                  </p>
                </div>
              </div>

              <div className="form-row">
                <div
                  className={`form-group position-relative error-l-75 col-md-12 ${
                    errorState.purpose_of_visit ? "hasValidationError" : ""
                  }`}
                >
                  <label htmlFor="purpose_of_visit">
                    Purpose of Visit<i>*</i>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="purpose_of_visit"
                    value={formState.purpose_of_visit}
                    id="purpose_of_visit"
                    placeholder="Enter your purpose of visit"
                    onChange={(e) => onInputChange(e.target)}
                  />
                  <p className="error-message">
                    {errorState && errorState.purpose_of_visit
                      ? errorState.purpose_of_visit
                      : ""}
                  </p>
                </div>
              </div>

              <div className="align-items-center justify-content-between float-right">
                <button
                  className="btn btn-primary mr-3"
                  type="button"
                  onClick={() => {
                    history.push("/self-registration");
                  }}
                  id="cancelButton"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-secondary btn-sm submitButton"
                  id="submitButton"
                  disabled={showLoader}
                >
                  Register
                  {showLoader && (
                    <span className="input-loader-container">
                      <div
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                      ></div>
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <NavigationGuard
        when={isFormDirty}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          if (isFormDirty) {
            return true;
          }
          return false;
        }}
      />
      {showSuccess && (
        <div className="form-reg">
          <div className="success">
            <div className="icon"></div>
            <div className="message">{showSuccessMessage}</div>
          </div>
        </div>
      )}
      <ReactTooltip />
    </>
  );
};

export default React.memo(PublicRegistration);
