import React from "react";
import { PublicReducer, ACTION_TYPES } from "../reducers/PublicReducer";

const initialState = {
    stateStorage: null,
};

export const PublicContext = React.createContext();
export const PublicContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(PublicReducer, initialState);
  const setStateStorage = (data, replace = false) => {
    if(replace){
        dispatch({
            type: ACTION_TYPES.SET_TO_STATE_STORAGE,
            payload: data,
        });
    } else {
        dispatch({
            type: ACTION_TYPES.UPDATE_TO_STATE_STORAGE,
            payload: data,
        })
    }
  };

  const removeStateStorage = (key, clearAll = false) => {
    if(clearAll) {
        dispatch({
            type: ACTION_TYPES.REMOVE_FROM_STATE_STORAGE,
            payload: key
        });
    } else {
        dispatch({
            type: ACTION_TYPES.CLEAR_STATE_STORAGE,
        });
    }
  }

  return (
    <PublicContext.Provider
      value={{
        stateStorage: state.stateStorage,
        setStateStorage,
        removeStateStorage,
      }}
    >
      {children}
    </PublicContext.Provider>
  );
};
