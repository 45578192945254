import React, { useContext } from "react";

import moment from "moment";
import Select from "react-select";

import CustomCalendar from "../../CustomCalender/index";
import { useRef } from "react";
import ExportButton from "../Export/Export";
import { checkModuleGeneralPermission } from "../../../helpers/helpers";
import { AppContext } from "../../../context/AppContext";
import useStorage from "../../../helpers/useStorage";
import ClearButton from "../ClearButton/ClearButton";
import {
  defaultSelectOption,
  defaultFromDateFilterValue,
  defaultToDateFilterValue,
  formatValueWithDefaultOption,
} from "../../../helpers/utils";
import { reportDateFormat } from "../../../constants";
import CustomSelectClearIndicator from "../CustomSelectClearIndicator/CustomSelectClearIndicator";
const AcsReportFilter = ({
  onSearchInputChange,
  oncrossTimeFromChange,
  onInAndOutChange,
  handleUpdateKey,
  setPageAction,
  exportData,
  buttonVisibility,
  oncrossTimeToChange,
  setResetPagination,
  onResetInitiated,
}) => {
  const { setToStorage, getFromStorage, removeFromStorage } = useStorage();
  const { userRoleDetails } = useContext(AppContext);

  const [searchText, setSearchText] = React.useState(
    getFromStorage("lpr_report_filter_search") ?? ""
  );
  const [crossTimeFrom, setcrossTimeFrom] = React.useState(
    getFromStorage("lpr_report_filter_from_date") ??
      defaultFromDateFilterValue(reportDateFormat)
  );
  const [crossTimeTo, setcrossTimeTo] = React.useState(
    getFromStorage("lpr_report_filter_to_date") ??
      defaultToDateFilterValue(reportDateFormat)
  );
  const [inAndOut, setInAndOut] = React.useState(
    getFromStorage("lpr_report_filter_inout") ?? defaultSelectOption()
  );

  const crossTimeFromRef = useRef(null);
  const crossTimeToRef = useRef(null);

  React.useEffect(() => {
    if (!searchText) {
      onSearchInputChange("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const inOutTypes = [
    defaultSelectOption(),
    { label: "Enter", value: "Enter" },
    { label: "Exit", value: "Exit" },
  ];

  const onFilterChange = (option) => {
    const { name, value, label } = option;

    switch (name) {
      case "crossTimeTo":
        setcrossTimeTo(
          value === null ? "" : moment(value).format("YYYY-MM-DD HH:mm")
        );
        break;
      case "crossTimeFrom":
        setcrossTimeFrom(
          value === null ? "" : moment(value).format("YYYY-MM-DD HH:mm")
        );
        break;
      case "type":
        setInAndOut({ label, value });
        break;
      default:
        break;
    }
  };

  const applyFilters = () => {
    oncrossTimeToChange(crossTimeTo);
    oncrossTimeFromChange(crossTimeFrom);
    setResetPagination(true);
    onInAndOutChange(inAndOut?.value);
    handleUpdateKey();
    onSearchInputChange(searchText);
    setPageAction("first");

    setToStorage(
      {
        lpr_report_filter_from_date: crossTimeFrom,
        lpr_report_filter_to_date: crossTimeTo,
        lpr_report_filter_search: searchText,
        lpr_report_filter_inout: inAndOut,
      },
      false
    );
  };

  const handleSingleFieldReset = (field) => {
    switch (field) {
      case "search":
        setSearchText("");
        return;
      case "fromTime":
        setcrossTimeFrom("");
        crossTimeFromRef.current.handleChange("");
        oncrossTimeFromChange("");
        return;
      case "toTime":
        setcrossTimeTo("");
        crossTimeToRef.current.handleChange("");
        oncrossTimeToChange("");
        return;
      default:
        return;
    }
  };

  const resetFilterOptions = () => {
    setcrossTimeFrom(defaultFromDateFilterValue(reportDateFormat));
    crossTimeFromRef.current.handleChange(defaultFromDateFilterValue());
    crossTimeToRef.current.handleChange(defaultToDateFilterValue());
    oncrossTimeFromChange(defaultFromDateFilterValue(reportDateFormat));
    setResetPagination(true);
    setcrossTimeTo(defaultToDateFilterValue(reportDateFormat));
    oncrossTimeToChange(defaultToDateFilterValue(reportDateFormat));
    setInAndOut(defaultSelectOption());
    setSearchText("");
    setPageAction("first");
    onResetInitiated();
    removeFromStorage();
  };

  return (
    <div className="reports-filter-section pb-3 position-relative">
      <div className="row mb-2">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Event Time From</span>
          <CustomCalendar
            maxDate={moment().toDate()}
            // showTimeSelect
            dateFormat="YYYY-MM-DD hh:mm A"
            timePicker
            ref={crossTimeFromRef}
            selected={crossTimeFrom === "" ? "" : new Date(crossTimeFrom)}
            onChange={(date) =>
              onFilterChange({ name: "crossTimeFrom", value: date, label: "" })
            }
            isClearable={true}
            onClear={() => handleSingleFieldReset("fromTime")}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Event Time To</span>
          <CustomCalendar
            maxDate={moment().toDate()}
            minDate={new Date(crossTimeFrom)}
            dateFormat="YYYY-MM-DD hh:mm A"
            ref={crossTimeToRef}
            selected={crossTimeTo === "" ? "" : new Date(crossTimeTo)}
            onChange={(date) =>
              onFilterChange({ name: "crossTimeTo", value: date, label: "" })
            }
            timePicker
            isClearable={true}
            onClear={() => handleSingleFieldReset("toTime")}
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Event Type</span>
          <Select
            className="cutomSelect"
            classNamePrefix="react-select"
            value={formatValueWithDefaultOption(inAndOut)}
            options={inOutTypes}
            onChange={(opt) =>
              onFilterChange({
                name: "type",
                value: opt?.value ?? "",
                label: opt?.label ?? "",
              })
            }
            name="type"
            placeholder="Select"
            isClearable={true}
            components={{ ClearIndicator: CustomSelectClearIndicator }}
            defaultValue={defaultSelectOption()}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Search</span>
          <div className="report-search-container searchHolder mb-0">
            <input
              type="text"
              className="form-control"
              name="search"
              value={searchText}
              id="search"
              placeholder="Search by Plate no. or Roadway name"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <ClearButton
              onClick={() => handleSingleFieldReset("search")}
              hideClear={String(searchText ?? "").length == 0}
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span className="show-lg">&nbsp;</span>
          <div className="btn-cover ">
            <button
              onClick={() => applyFilters()}
              className="btn btn-secondary rounded-0 ml-1 "
            >
              Apply
            </button>
            <button
              onClick={resetFilterOptions}
              className="btn btn-secondary reset rounded-0 ml-1 "
            >
              <i className="iconsminds-repeat-2"></i>
            </button>
            {checkModuleGeneralPermission(
              userRoleDetails,
              "anpr-event-report",
              "export_report"
            ) && (
              <div className="mr-2">
                <ExportButton
                  exportDetails={exportData}
                  disableExport={buttonVisibility}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcsReportFilter;
