/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useHistory } from "react-router-dom";
import { useClient, useQuery } from "jsonapi-react";
import DataTable from "react-data-table-component";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import {
  APP_TIMEZONE,
  DEVICE_SERVICE_URL,
  VW_ROUTES,
  apiRoutes,
} from "../../constants";
import { API_BASE_URL } from "../../constants";
import AcsReportFilter from "../../components/common/ReportFilter/AcsReportFilter";
import AcsReportFilterOld from "../../components/common/ReportFilter/AcsReportFilterOld";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
// import { RiRefreshFill } from "react-icons/ri";
import useState from "react-usestateref";
import { AppContext } from "../../context/AppContext";
import AcsTransactionReportExpansion from "./components/AcsTransactionReportExpansion.component";
import {
  checkModuleViewPermission,
  formatTextWithFallback,
  getCategoryType,
  getLocalDateTime,
} from "../../helpers/helpers";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import useStorage from "../../helpers/useStorage";
const AcsTransactionReport = () => {
  const { setToStorage, getFromStorage, removeFromStorage } = useStorage();
  const [isLoading, setIsLoading] = React.useState(true);
  const { userRoleDetails, appOptions, getCategoryFromSlug } =
    React.useContext(AppContext);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    getFromStorage("acs_report_page_no") ?? 1
  );
  const [updateKey, setUpdateKey] = React.useState(0);
  const [isInitialFiltersDone, setIsInitialFiltersDone] = React.useState(false);
  const [resetPagination, setResetPagination] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("acs_report_rows_per_page") ?? 10
  );
  const [totalEntries, setTotalEntries] = React.useState(0);
  // const [markerId, setMarkerId] = React.useState("");
  // const [pageAction, setPageAction] = React.useState("first");
  const [pageNumber, setPageNumber] = React.useState(1);

  const [searchQueryString, setSearchQueryString, searchQueryStringRef] =
    useState(getFromStorage("acs_report_filter_search") ?? "");
  const [eventTimeFrom, setEventTimeFrom] = React.useState(
    getFromStorage("acs_report_filter_from_date") ?? ""
  );
  const [eventTimeTo, setEventTimeTo] = React.useState(
    getFromStorage("acs_report_filter_to_date") ?? ""
  );
  const [applicantType, setApplicantType] = React.useState(
    getFromStorage("acs_report_filter_type")?.value ?? ""
  );
  const [eventType, setEventType] = React.useState(
    getFromStorage("acs_report_filter_event")?.value ?? ""
  );
  const [acsReport, setAcsReport] = React.useState([]);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  const [disableExport, setdisableExport] = React.useState(true);
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState(
    getFromStorage("acs_report_filter_company")?.label ?? ""
  );
  const [tab, setTab] = useState("new");
  const history = useHistory();
  const pageURL = useLocation();
  const [pageReportType, setPageReportType] = React.useState("");

  const getCellCategoryType = (cellProfile) =>
    getCategoryType(
      getCategoryFromSlug(
        String(cellProfile?.category ?? "").toLowerCase(),
        appOptions
      )?.slug
    );

  React.useEffect(() => {
    setPageReportType(
      pageURL?.pathname?.split("/")[
        pageURL?.pathname?.split("/").length - 1 ?? 0
      ]
    );
  }, [pageURL]);

  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const axiosOptions = (method, url) => {
    return {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: `${DEVICE_SERVICE_URL}/${url}`,
    };
  };

  const onResetInitiated = (tab) => {
    // setPageAction("first");
    setPageNumber(1);
    // setMarkerId("");
    setEventTimeFrom(
      moment()
        .subtract(tab == "new" ? 30 : 13, tab == "new" ? "days" : "months")
        .format("YYYY-MM-DD HH:mm")
    );
    setEventTimeTo(
      (tab == "new" ? moment() : moment().subtract(1, "year")).format(
        "YYYY-MM-DD HH:mm"
      )
    );
    setCurrentPageNumber(1);
    setResetPagination(true);
    setApplicantType("");
    setEventType("");
    setSelectedCompany("");
    setSearchQueryString("");
    removeFromStorage();
  };
  const jumpToFirstPage = () => {
    setResetPagination(true);
    // setPageAction("first");
    setPageNumber(1);
    // setMarkerId("");
  };
  const handleUpdateKey = () => {
    setUpdateKey((k) => k + 1);
  };
  React.useEffect(() => {
    if (acsReport?.length > 0) {
      setdisableExport(false);
    } else {
      setdisableExport(true);
    }
  }, [acsReport]);

  const viewDetails = (applicantData) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${applicantData?.person_id}&type=${String(
        applicantData?.category ?? ""
      )?.toLowerCase()}`,
      state: {
        from: history.location.pathname,
      },
    });
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewDetails(mode.acsReport);
    }
  };

  const doFilter = (tab, pageNumber) => {
    setIsLoading(true);
    setResetPagination(false);
    let filterItems = {
      search: searchQueryString ?? "",

      company: selectedCompany ?? "",
      category: applicantType ?? "",
      event_type: eventType ?? "",
      event_time_from:
        eventTimeFrom === ""
          ? ""
          : moment(eventTimeFrom, "YYYY-MM-DD HH:mm:ss")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
      event_time_to:
        eventTimeTo === ""
          ? ""
          : moment(eventTimeTo, "YYYY-MM-DD HH:mm:ss")
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
    };

    let filter = "";
    for (const [, [key, value]] of Object.entries(
      Object.entries(filterItems)
    )) {
      if (value !== "" && value) {
        filter += `&${key}=${value}`;
      }
    }
    // if (pageAction !== "first" && pageAction !== "last") {
    //   filter += `&marker_id=${markerId}`;
    // }

    if (tab === "old") {
      axios(
        axiosOptions(
          "GET",
          `acs/events/archived?page_number=${pageNumber}&page_size=${rowsPerPage}${filter}`
        )
      )
        .then((response) => {
          setIsLoading(false);
          const {
            data: { data: acsTransactionReportData, total: totalEntries },
          } = response;
          setAcsReport(acsTransactionReportData);
          setTotalEntries(totalEntries);
        })
        .catch((err) => {
          setAcsReport([]);
          setIsLoading(false);
        });
    } else {
      axios(
        axiosOptions(
          "GET",
          `acs/events?page_size=${rowsPerPage}&page_number=${pageNumber}${filter}`
        )
      )
        .then((response) => {
          setIsLoading(false);
          const {
            data: { data: acsTransactionReportData, total: totalEntries },
          } = response;
          setAcsReport(acsTransactionReportData);
          setTotalEntries(totalEntries);
        })
        .catch((err) => {
          setAcsReport([]);
          setIsLoading(false);
        });
    }
  };

  React.useEffect(() => {
    if (!isInitialFiltersDone) return;
    // if (tab === "old") {
    //   setAcsReport([]);
    // } else {
    //   doFilter();
    // }
    doFilter(tab, currentPageNumber);
  }, [
    // applicantType,
    // eventType,
    // selectedCompany,
    // eventTimeFrom,
    // eventTimeTo,
    rowsPerPage,
    // pageAction,
    pageNumber,
    updateKey,
    isInitialFiltersDone,
    // searchQueryString,
    // tab,
  ]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  const columns = [
    {
      name: "Name",
      selector: (acsReport) => acsReport?.full_name,
      minWidth: "200px",
      sortable: true,
      // cell: applicant => <span className="text-capitalize">{`${applicant.applicantData ? applicant.applicantData.full_name : '-'}`}</span>
      cell: (acsReport) => (
        <ProfileCard
          data={acsReport}
          mode={pageReportType}
          cellMode={getCellCategoryType(acsReport)}
          actions={userRoleDetails}
          fields={[
            {
              name: "Category",
              value: formatTextWithFallback(acsReport?.category),
            },
            {
              name: "Company",
              value: formatTextWithFallback(acsReport?.company),
            },
            {
              name: "Access Control",
              value: formatTextWithFallback(acsReport?.dev_name),
            },
            {
              name: "Event Time ",
              value: (
                <span className="text-lowercase">
                  {getLocalDateTime(acsReport?.event_time)}
                </span>
              ),
            },
          ]}
          actionsToParent={(mode) => getEvent({ mode, acsReport })}
        />
      ),
    },
    // {
    //   name: "ID Number",
    //   sortable: false,
    //   hide: "md",
    //   cell: (applicant) => (
    //     <span className="text-capitalize text-left">
    //       {applicant.applicantData.idNumber.toUpperCase()}
    //     </span>
    //   ),
    // },

    // {
    //   name: "Card No.",
    //   minWidth: "200px",
    //   cell: (applicant) => (
    //     <span className="text-uppercase text-left">{`${applicant.cardNo}`}</span>
    //   ),
    //   sortable: false,
    //   hide: "md",
    // },
    {
      name: "Category",
      hide: "md",
      minWidth: "200px",
      cell: (applicant) => (
        <span className="text-capitalize">
          {formatTextWithFallback(
            _.replace(applicant?.category ?? "", "_", " ")
          )}
        </span>
      ),
    },
    {
      name: "Company",
      sortable: false,
      minWidth: "250px",
      selector: (acsReport) => acsReport?.company,
      cell: (applicant) => formatTextWithFallback(applicant?.company),
      hide: "md",
    },

    {
      name: "Access Control",
      sortable: false,
      minWidth: "200px",
      hide: "md",
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {formatTextWithFallback(applicant?.dev_name)}
        </span>
      ),
    },
    // {
    //   name: "Entry/Exit",
    //   cell: (applicant) => (
    //     <span className="text-capitalize text-left">{`${applicant.inAndOutType}`}</span>
    //   ),
    //   sortable: false,
    //   hide: "md",
    // },
    {
      name: "Event Time",
      minWidth: 200,
      hide: "md",
      style: { position: "sticky", right: 100 },
      cell: (applicant) => (
        <span className="text-left">
          {getLocalDateTime(applicant?.event_time)}
        </span>
      ),
    },
    {
      name: "Actions",
      sortable: false,
      style: { position: "sticky", right: 0 },
      center: true,
      maxWidth: 100,
      minWidth: 100,
      cell: (staff) => (
        // <div className="actionColumn">
        <>
          {checkModuleViewPermission(
            userRoleDetails,
            getCellCategoryType(staff),
            "profile",
            staff
          ) && (
            <span
              className="simple-icon-eye user-role-action-icons view"
              onClick={() => viewDetails(staff)}
            ></span>
          )}
        </>
        // </div>
      ),
      right: true,
      hide: "md",
    },
  ];

  const onChangePage = (page) => {
    // let pageActionType = currentPageNumber > page ? "previous" : "next";

    // if (page - 1 === currentPageNumber) {
    //   pageActionType = "next";
    // } else {
    //   if (page === 1) {
    //     pageActionType = "first";
    //   } else if (page === Math.ceil(totalEntries / rowsPerPage)) {
    //     pageActionType = "last";
    //   }
    // }
    // setPageAction(pageActionType);
    // const currentMarkerId =
    //   pageActionType === "next"
    //     ? acsReport[acsReport.length - 1]?._id?.$oid
    //     : acsReport[0]?._id?.$oid;
    // setMarkerId(currentMarkerId);
    doFilter(tab, page);
    setCurrentPageNumber(page);
    setToStorage({ acs_report_page_no: page }, true);
  };

  const onSearchInputChange = (searchQuery) => {
    setSearchQueryString(searchQuery);
    // doFilter();
  };

  const onChangeRowsPerPage = (rows) => {
    //removeFromStorage("acs_report_page_no", "onlyThis");
    setToStorage({ acs_report_rows_per_page: rows }, true);
    setRowsPerPage(rows);
  };

  const onExport = () => {
    setIsLoading(true);
    let filterItems = {
      search: searchQueryString && searchQueryStringRef.current,
      category: applicantType,
      company: selectedCompany ? selectedCompany : "",
      event_type: eventType ?? "",
      event_time_from:
        eventTimeFrom === ""
          ? ""
          : moment

              .tz(eventTimeFrom, "YYYY-MM-DD HH:mm:ss", APP_TIMEZONE)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
      event_time_to:
        eventTimeTo === ""
          ? ""
          : moment

              // .utc()
              .tz(eventTimeTo, "YYYY-MM-DD HH:mm:ss", APP_TIMEZONE)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
    };
    let filter = "";
    for (const [, [key, value]] of Object.entries(
      Object.entries(filterItems)
    )) {
      if (value !== "" && value) {
        filter += `&${key}=${value}`;
      }
    }

    const options = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      url:
        tab === "old"
          ? `${apiRoutes.acsTransactionArchivedExport}?${filter}`
          : `${apiRoutes.acsTransactionExport}?${filter}`,
    };
    axios(options).then((response) => {
      setIsLoading(false);
      setShowSuccessModal(true);
      setShowSuccessMessage(response.data.data.message);
    });
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  const manageTabs = (t) => {
    setTab(t);
    setResetPagination(true);
    onResetInitiated(t);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <div className="d-flex w-100 justify-content-between mb-3 mb-md-0">
              <h1>ACS Event Report</h1>
              <div
                className="tableFilterButton"
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                {/* <span></span>
              <span></span>
              <span></span> */}
                <i className="iconsminds-filter-2"></i>
              </div>
            </div>
            <div className="d-flex  w-100 report justify-content-between justify-content-md-end">
              <div>
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <span
                      className={`nav-link ${tab === "new" ? "active" : ""}`}
                      aria-current="page"
                      onClick={(e) => manageTabs("new")}
                    >
                      Recent Events
                    </span>
                  </li>
                  <li className="nav-item">
                    <span
                      className={`nav-link ${tab === "old" ? "active" : ""}`}
                      aria-current="page"
                      onClick={(e) => manageTabs("old")}
                    >
                      Archived Events
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="ReportFilterHolder">
            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="reportSearch">
                  {tab === "new" ? (
                    <AcsReportFilter
                      requestDataType={tab}
                      onApplicantTypeChange={setApplicantType}
                      onEventTimeFromChange={setEventTimeFrom}
                      handleUpdateKey={handleUpdateKey}
                      loading={isLoading}
                      onEventTimeToChange={setEventTimeTo}
                      setUpdateKey={setUpdateKey}
                      onSearchInputChange={onSearchInputChange}
                      onExport={onExport}
                      onResetInitiated={() => {
                        onResetInitiated("new");
                      }}
                      setResetPagination={jumpToFirstPage}
                      setCurrentPageNumber={setCurrentPageNumber}
                      buttonVisibility={disableExport}
                      onCompanyChange={setSelectedCompany}
                      onEventChange={setEventType}
                      handleInitialFiltersDone={setIsInitialFiltersDone}
                    />
                  ) : (
                    <AcsReportFilterOld
                      requestDataType={tab}
                      onApplicantTypeChange={setApplicantType}
                      onEventTimeFromChange={setEventTimeFrom}
                      handleUpdateKey={handleUpdateKey}
                      loading={isLoading}
                      onEventTimeToChange={setEventTimeTo}
                      setUpdateKey={setUpdateKey}
                      onSearchInputChange={onSearchInputChange}
                      onExport={onExport}
                      onResetInitiated={() => {
                        onResetInitiated("old");
                      }}
                      setResetPagination={jumpToFirstPage}
                      setCurrentPageNumber={setCurrentPageNumber}
                      buttonVisibility={disableExport}
                      onCompanyChange={setSelectedCompany}
                      onEventChange={setEventType}
                      handleInitialFiltersDone={setIsInitialFiltersDone}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={`hasStickLastColumn hasStickyAction
                ${
                  isLoading
                    ? "card-body remove-header dataTableOuter reportTable table-loader"
                    : "card-body remove-header dataTableOuter reportTable"
                }`}
            >
              <DataTable
                subHeader={true}
                //subHeaderComponent={<> <button className="btn btn-primary">Export</button><SearchBar onSearchInputChange={onSearchInputChange} /></>}
                progressPending={isLoading}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={acsReport}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={totalEntries}
                paginationResetDefaultPage={resetPagination}
                expandableRows
                expandableRowsComponent={AcsTransactionReportExpansion}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcsTransactionReport;
