import React from "react";
import "./progressBar.scss";
const ProgressBar = ({ percentage, size }) => {


  return (
    <div className={`progressBar ${size ? size : ""}`}>
      <div
        className={`map ${
          percentage > 60 && percentage < 75
            ? "stable"
            : percentage > 75
            ? "critical"
            : "normal"
        }`}
        style={{ width: percentage + "%" }}
      ></div>
    </div>
  );
};

export default ProgressBar;
