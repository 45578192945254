import React, { useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
//import { createHashHistory } from "history";
import AppRoute from "./AppRoute";
import ContextRoute from "./ContextRoute";
import AppLayout from "../../layout/AppLayout";
import UserLayout from "../../layout/UserLayout";
import Login from "../../containers/Login/Login";
import ForgotPassword from "../../containers/ForgotPassword/ForgotPassword";
import ResetPassword from "../../containers/ResetPassword/ResetPassword";
import Dashboard from "../../containers/Dashboard/Dashboard";
import Analytics from "../../containers/Analytics/Analytics.jsx";
import VerifyUser from "../../containers/VerifyUser/VerifyUser";
import UserRoles from "../../containers/UserRoles/UserRoles";
import { DashboardProvider } from "../../context/DashboardContext";
import { UserGroupProvider } from "../../context/UserGroupContext";
import { VW_ROUTES, API_BASE_URL, ROUTE_PERMISSION } from "../../constants";
import { schema } from "../../helpers/utils";
import { ApiClient, ApiProvider } from "jsonapi-react";
import Company from "../../containers/Company/Company";
import UserManagement from "../../containers/UserManagement/UserManagement";
import UserProfile from "../../containers/UserProfile/UserProfile";
import UserTraining from "../../containers/UserTraining/UserTraining";
import ExpiryStatus from "../../containers/ExpiryStatus/ExpiryStatus";
import ValidityExpiryStatus from "../../containers/ValidityExpiryStatus/ValidityExpiryStatus";
import ResetForgotPassword from "../../containers/ResetForgotPassword/ResetForgotPassword";
import Notifications from "../../containers/Notifications/Notifications";
import ContractorBasedApplicantsLists from "../../containers/ContractorBasedApplicantsLists/ContractorBasedApplicantsLists";
import GeneralReport from "../../containers/Reports/GeneralReport";
import AcsTransactionReport from "../../containers/Reports/AcsTransactionReport";
import LPRTransactionReport from "../../containers/Reports/LPREventReport";
import Contractor from "../../containers/Contractor/Contractor";
import BCAReport from "../../containers/Reports/BCAReport";
import ScrollToTop from "../../hoc/scrollToTop";
import ArchivedApplicants from "../../containers/Applicants/ArchivedApplicants";
import CompanyBasedApplicantsLists from "../../containers/LivePeoplesList/livePeoplesList";
import PendingApplicants from "../../containers/PendingApplicants/PendingApplicants";
import LiveVehicleList from "../../containers/LiveVehicles/liveVehicleList";
import VehicleOverstay from "../../containers/VehicleOverStay/VehicleOverstay";
import ApplicantsList from "../../containers/ApplicantsList/ApplicantsList";
import ViewProfile from "../../containers/ApplicantsList/ViewProfile";
import CreateProfile from "../../containers/ApplicantsList/CreateProfile";
import LivePeopleListComponent from "../../containers/LivePeoplesList/LivePeopleListComponent";
import PublicRegistration from "../../containers/PublicRegistration/PublicRegistration";
import PublicRegistrationLanding from "../../containers/PublicRegistrationLanding/PublicRegistrationLanding";
import PublicRegistrationExistingUser from "../../containers/PublicRegistrationExistingUser/PublicRegistrationExistingUser";
import SelfRegistrationInvites from "../../containers/PublicRegistrationInvites/PublicRegistrationInvites.jsx";
import { PublicContextProvider } from "../../context/PublicContext.js";
import PPEEventReport from "../../containers/Reports/PPEEventReport.jsx";
import DailyPPEEventsListing from "../../containers/DailyPPEEvents/LivePPEEventsListing.jsx";

const client = new ApiClient({
  url: API_BASE_URL,
  schema,
  mediaType: "application/json",
});

const NotFound = () => <div>Not Found</div>;
const Routes = () => {
  const [path, setPath] = useState(null);
  return (
    <ApiProvider client={client}>
      <HashRouter hashType="noslash">
        <ScrollToTop onRouteChange={setPath} path={path} />
        <Switch>
          <AppRoute
            path={VW_ROUTES.PROFILE}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={UserProfile}
            layout={AppLayout}
          />
          <AppRoute
            path={VW_ROUTES.VERIFY_USER}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={VerifyUser}
            layout={UserLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ExpiryStatus}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.PERSON_PROFILE_CIDB_EXPIRY_LISTING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ExpiryStatus}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_VALIDITY_EXPIRY_STATUS_LISTING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ValidityExpiryStatus}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_PENDING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={PendingApplicants}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_MANAGE}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_EDIT}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_DELIVERY_REGULAR}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_MANAGE}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_EDIT}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.PERSON_PROFILE_VIEW}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ViewProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.ALL_PROFILES}
            pageCode={"all-profile"}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.EMPLOYEE_PROFILE}
            pageCode={"employee-profile"}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.ALL_PROFILES}
            pageCode={"employee-profile"}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.VISITOR_PROFILES}
            pageCode={"visitor-profile"}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.MANAGE_PERSON_REGISTRY}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_STAFF}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_STAFF_MANAGE}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_STAFF_EDIT}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_VISITIOR}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_VISITIOR_ADD}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_VISITIOR_EDIT}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CreateProfile}
            layout={AppLayout}
          />
          <AppRoute
            path={VW_ROUTES.FORGOT_PASSWORD}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={ForgotPassword}
            layout={UserLayout}
          />
          <AppRoute
            path={VW_ROUTES.RESET_PASSWORD}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={ResetPassword}
            layout={UserLayout}
          />
          <AppRoute
            path={VW_ROUTES.RESET_FORGOT_PASSWORD}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={ResetForgotPassword}
            layout={UserLayout}
          />
          <AppRoute
            path={VW_ROUTES.LOGIN}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={Login}
            layout={UserLayout}
          />
          <ContextRoute
            path={VW_ROUTES.SELF_REGISTARTION}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={PublicRegistration}
            layout={UserLayout}
            context={PublicContextProvider}
            isLoginRequired={false}
          />
          <ContextRoute
            path={VW_ROUTES.SELF_REGISTRATION_EXISTING_USER}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={PublicRegistrationExistingUser}
            context={PublicContextProvider}
            layout={UserLayout}
            isLoginRequired={false}
          />
          <ContextRoute
            path={VW_ROUTES.SELF_REGISTRATION_LANDING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            component={PublicRegistrationLanding}
            context={PublicContextProvider}
            layout={UserLayout}
            isLoginRequired={false}
          />

          <ContextRoute
            path={VW_ROUTES.DASHBOARD}
            pageCode={"dashboard"}
            context={DashboardProvider}
            component={Dashboard}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.ANALYTICS}
            pageCode={"analytics"}
            context={DashboardProvider}
            component={Analytics}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.VIDEO_ANALYTICS}
            pageCode={"video-analytics"}
            context={DashboardProvider}
            component={Dashboard}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.ROLES_AND_PERMISSIONS_ROLES}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={UserRoles}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.COMPANIES}
            pageCode={"company"}
            context={UserGroupProvider}
            component={Company}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.CONTRACTS}
            pageCode={"contract"}
            context={UserGroupProvider}
            component={Contractor}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.USERS}
            pageCode={"user"}
            context={UserGroupProvider}
            component={UserManagement}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.TRAINING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={UserTraining}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.APPLICANTS_HISTORY}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ApplicantsList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.ARCHIVED_APPLICANTS}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ArchivedApplicants}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.INFO_DISPLAY_PAGE}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={Notifications}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.CONTRACTOR_APPLICANTS_LISTING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={ContractorBasedApplicantsLists}
            layout={AppLayout}
          />
          {/* <ContextRoute
            path={VW_ROUTES.LIVE_PEOPLE_LISTING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={CompanyBasedApplicantsLists}
            layout={AppLayout}
          /> */}
          <ContextRoute
            path={VW_ROUTES.LIVE_PEOPLE_LISTING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={LivePeopleListComponent}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.OVER_STAY_PEOPLE_LISTING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={LivePeopleListComponent}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.LIVE_VEHICLE_COUNT_LIST}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={LiveVehicleList}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.OVERSTAY_VEHICLE_COUNT_LIST}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={VehicleOverstay}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.DAILY_PPE_EVENTS_LISTING}
            pageCode={ROUTE_PERMISSION.ALWAYS}
            context={UserGroupProvider}
            component={DailyPPEEventsListing}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.PROFILE_REPORT}
            pageCode={"profile-report"}
            context={UserGroupProvider}
            component={GeneralReport}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.ACS_EVENT_REPORT}
            pageCode={"acs-event-report"}
            context={UserGroupProvider}
            component={AcsTransactionReport}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.ANPR_EVENT_REPORT}
            pageCode={"anpr-event-report"}
            context={UserGroupProvider}
            component={LPRTransactionReport}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.MAN_HOUR_REPORT}
            pageCode={"man-hour-report"}
            context={UserGroupProvider}
            component={BCAReport}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.PPE_EVENT_REPORT}
            pageCode={"ppe-event-report"}
            context={UserGroupProvider}
            component={PPEEventReport}
            layout={AppLayout}
          />
          <ContextRoute
            path={VW_ROUTES.SELF_REGISTRATION_INVITES}
            pageCode={"self-registration-invites"}
            context={UserGroupProvider}
            component={SelfRegistrationInvites}
            layout={AppLayout}
          />
          <Route path="/notfound" component={NotFound} />
          <Redirect from="/" to={VW_ROUTES.DASHBOARD} />
          <Route component={NotFound} />
        </Switch>
      </HashRouter>
    </ApiProvider>
  );
};

export default Routes;
