import React from "react";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import ReactTooltip from "react-tooltip";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { getDayFirst } from "../../helpers/helpers";

const PublicRegisterInviteFormComponent = ({
  title,
  description,
  recipients,
  expiry,
  multipleUse,
  errorState,
  updateInputChange,
}) => {
  return (
    <>
      <p>{title}</p>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState?.description ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Description *</b>
        </label>
        <input
          type="text"
          required
          value={description}
          name="description"
          className="form-control"
          placeholder="Enter invite description"
          onChange={(e) => updateInputChange(e)}
        />
        <span className="validation-error-message">
          {errorState && errorState.description ? errorState?.description : ""}
        </span>
      </div>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState?.recipients ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Email(s)</b>
        </label>
        <textarea
            type="text"
            name="recipients"
            className="form-control"
            value={recipients}
            placeholder="Enter email(s) to invite seperated by ','"
            onChange={(e) => updateInputChange(e)}
        />
        <span className="validation-error-message">
          {errorState && errorState?.recipients ? errorState?.recipients : ""}
        </span>
      </div>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState?.expiry ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Expiry</b>{" "}
          <i
            className="infoButton"
            data-tip={DISPLAY_TEXTS.INVITEFORM_EXPIRY_TOOLTIP}
          >
            i
          </i>
        </label>
        <div className="input-group date">
          <DatePicker
            className={`form-control`}
            render={<InputIcon className="form-control" />}
            dateFormat="dd/MM/yyyy"
            placeholderText="DD/MM/YYYY"
            minDate={moment().toDate()}
            popperPlacement="bottom-end"
            selected={getDayFirst(expiry)}
            onChange={(date) => updateInputChange(
              { target: { 
                name: "expiry", 
                value: date ? date : null
              }}
            )}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
              },
            }}
          />
        </div>
        <span className="validation-error-message">
          {errorState && errorState?.expiry ? errorState?.expiry : ""}
        </span>
      </div>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState?.expiry ? "hasValidationError" : ""
        }`}
      >
       <div
          className={`toggle-switch ${!multipleUse ? "off" : "on"}`}
          onClick={() => {}}
        >
          <label>
            <b>Multiple Use</b>{" "}
            <i
              className="infoButton"
              data-tip={DISPLAY_TEXTS.INVITEFORM_MULTIPLEUSE_TOOLTIP}
            >
              i
            </i>
          </label>
          <span onClick={() => updateInputChange({ target: { name: "multipleUse", value: multipleUse}})}></span>
        </div>
        <span className="validation-error-message">
          {errorState && errorState?.expiry ? errorState?.expiry : ""}
        </span>
      </div>
      {multipleUse && !moment(expiry, 'DD-MM-YYYY').isValid() && (
        <div className="warning-text">
          {DISPLAY_TEXTS.INVITEFORM_WARNING}
        </div>
      )}
      {multipleUse && (
        <div className="warning-text">
          {DISPLAY_TEXTS.INVITEFORM_MULTIUSE_WARNING}
        </div>
      )}
      <ReactTooltip place="right" />
    </>
  );
};

export default PublicRegisterInviteFormComponent;
