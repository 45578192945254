import { useClient, useQuery } from "jsonapi-react";
import { apiRoutes } from "../../../constants";

const useContracts = () => {
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const contracts = useQuery(
    [
      apiRoutes.contract,
      {
        page: {
          number: 1,
          size: 3000,
        }
      },
    ],
    { client }
  );

  return { contracts };
};

export default useContracts;
