/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react";
import { useQuery, useClient } from "jsonapi-react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import _ from "lodash";
import {
  checkModuleGeneralPermission,
  checkModuleViewPermission,
  formatTextWithFallback,
  getCategoryType,
  getIdNumber,
  parseIdentificationType,
} from "../../helpers/helpers";

import {
  API_BASE_URL,
  VW_ROUTES,
  apiRoutes,
  reportDatewoTimeFormat,
} from "../../constants";
import ReportFilter from "../../components/common/ReportFilter/ReportFilter";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { AppContext } from "../../context/AppContext";
import GeneralReportExpansion from "./components/GeneralReportExpansion.component";
import useStorage from "../../helpers/useStorage";
import {
  defaultFromDateFilterValue,
  defaultToDateFilterValue,
} from "../../helpers/utils";
// import { RiRefreshFill } from "react-icons/ri";
// import JSONConverter from "./jsonApiconverter";

const GeneralReport = () => {
  const { setToStorage, getFromStorage } = useStorage();
  const { userRoleDetails, getCategoryFromID, appOptions } =
    React.useContext(AppContext);
  const [filterChanged, setFilterChanged] = React.useState(false);
  const [selectedNationality, setSelectedNationality] = React.useState(
    getFromStorage("applicant_profile_report_filter_nation")?.value ?? undefined
  );
  const [selectedStatus, setSelectedStatus] = React.useState(
    getFromStorage("applicant_profile_report_filter_status")?.value ?? undefined
  );
  const [selectedContract, setselectedContract] = React.useState(
    getFromStorage("applicant_profile_report_filter_contract")?.value ??
      undefined
  );
  const [selectedCompany, setSelectedCompany] = React.useState(
    getFromStorage("applicant_profile_report_filter_company")?.value ??
      undefined
  );
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    getFromStorage("applicant_profile_report_page_no") ?? 1
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("applicant_profile_report_rows_per_page") ?? 10
  );
  const [searchQueryString, setSearchQueryString] = React.useState(
    getFromStorage("applicant_profile_report_filter_search") ?? undefined
  );
  const [applicantType, setApplicantType] = React.useState(
    getFromStorage("applicant_profile_report_filter_type")?.value ?? undefined
  );
  const { manageLoader } = React.useContext(AppContext);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const [beforeDate, setBeforeDate] = React.useState(
    getFromStorage("applicant_profile_report_filter_before_date") ??
      defaultFromDateFilterValue(reportDatewoTimeFormat)
  );
  const [afterDate, setAfterDate] = React.useState(
    getFromStorage("applicant_profile_report_filter_after_date") ??
      defaultToDateFilterValue(reportDatewoTimeFormat)
  );
  const [disableExport, setdisableExport] = React.useState(true);

  const getCellCategoryType = (cellProfile) =>
    getCategoryType(
      getCategoryFromID(cellProfile?.category?.id, appOptions)?.slug
    );

  const history = useHistory();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  let filter;
  filter = {
    ...(applicantType !== null &&
      applicantType !== undefined &&
      applicantType !== "" && {
        category:
          getCategoryFromID(applicantType, appOptions)?.slug ?? applicantType,
      }),
    ...(searchQueryString !== null &&
      searchQueryString !== undefined &&
      searchQueryString !== "" && { search: searchQueryString }),
    ...(selectedNationality !== null &&
      selectedNationality !== undefined &&
      selectedNationality !== "" && { nationality: selectedNationality }),
    ...(selectedStatus !== null &&
      selectedStatus !== undefined &&
      selectedStatus !== "" && { status: selectedStatus }),
    ...(selectedContract !== null &&
      selectedContract !== undefined &&
      selectedContract !== "" && { contract: selectedContract }),
    ...(selectedCompany !== null &&
      selectedCompany !== undefined &&
      selectedCompany !== "" && { company: selectedCompany }),
    ...(afterDate !== null &&
      afterDate !== undefined &&
      afterDate !== "" && { created_before: afterDate }),
    ...(beforeDate !== null &&
      beforeDate !== undefined &&
      beforeDate !== "" && { created_after: beforeDate }),
  };

  const applicants = useQuery(
    [
      "reports",
      "profile",
      {
        filter: filter,
        page: {
          number: currentPageNumber,
          size: rowsPerPage,
        },
        include: "person_photo,company,contract,category",
      },
    ],
    { client }
  );

  useEffect(() => {
    if (applicants?.data?.length > 0) {
      setdisableExport(false);
    } else {
      setdisableExport(true);
    }
  }, [applicants?.data]);

  const handleUpdateKey = () => {
    if (!filterChanged) {
      applicants.refetch();
    }
    // onChangePage(1);
    setFilterChanged(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewDetails(mode.applicant);
    }
  };

  const viewDetails = (profileData) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${profileData?.id}&type=${profileData?.category?.slug}`,
      state: {
        from: history.location?.pathname,
      },
    });
  };

  const columns = [
    {
      name: "Name",
      selector: (person) => person?.fullName,
      minWidth: 250,
      sortable: true,
      cell: (applicant) => (
        <ProfileCard
          data={applicant}
          mode="profile-report"
          cellMode={getCellCategoryType(applicant)}
          actions={userRoleDetails}
          fields={[
            {
              value: _.replace(applicant?.category?.name, "_", " "),
              name: "Category",
            },

            {
              value: parseIdentificationType(applicant),
              name: `${
                applicant?.idType && applicant.idType !== "passport"
                  ? applicant.idType?.toUpperCase()
                  : applicant.idType
              } Number`,
            },
            {
              value: applicant?.company?.name,
              name: "Company",
            },
            {
              value: applicant?.contract?.name,
              name: "Contract",
            },
            {
              value: applicant?.designation,
              name: "Designation",
            },
          ]}
          actionsToParent={(mode) => getEvent({ mode, applicant })}
        />
      ),
    },
    {
      name: "Category",
      selector: (person) => person.category?.name,
      maxWidth: "150px",
      sortable: false,
      cell: (applicant) => (
        <span className="text-capitalize">
          {formatTextWithFallback(
            _.replace(applicant?.category?.name ?? "", "_", " ")
          )}
        </span>
      ),
      hide: "md",
    },
    {
      name: "ID Number",
      sortable: false,
      hide: "md",
      minWidth: "150px",
      selector: (person) => person?.idType,
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {formatTextWithFallback(getIdNumber(applicant))}
        </span>
      ),
    },
    {
      name: "Company",
      sortable: false,
      minWidth: 300,
      selector: (person) => person.company?.name,
      cell: (applicant) => formatTextWithFallback(applicant?.company?.name),
      hide: "md",
    },
    {
      name: "Contract",

      sortable: false,
      left: true,

      minWidth: "200px",
      selector: (person) => person.contract?.name,
      cell: (applicant) => formatTextWithFallback(applicant?.contract?.name),
      hide: "md",
    },

    {
      name: "Designation",
      selector: (person) => formatTextWithFallback(person?.designation),
      sortable: false,
      minWidth: 250,
      hide: "md",
    },
    {
      name: "Actions",
      sortable: false,

      center: true,
      maxWidth: 100,
      minWidth: 100,
      cell: (staff) => (
        <>
          {checkModuleViewPermission(
            userRoleDetails,
            getCellCategoryType(staff),
            "profile",
            staff
          ) && (
            <span
              className="simple-icon-eye user-role-action-icons view"
              onClick={() => viewDetails(staff)}
            ></span>
          )}
        </>
      ),
      right: true,
      hide: "md",
    },
  ];

  const onChangePage = (page) => {
    setCurrentPageNumber(page);
    setToStorage({ applicant_profile_report_page_no: page }, true);
  };

  const onSearchInputChange = (searchQuery) => {
    if (searchQuery) {
      setSearchQueryString(searchQuery);
    } else {
      setSearchQueryString(undefined);
    }
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({ applicant_profile_report_rows_per_page: rows }, true);
    setRowsPerPage(rows);
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };

  const onExport = () => {
    manageLoader(true);

    if (applicants?.data?.length > 0) {
      let filter = "";
      if (searchQueryString !== "" && searchQueryString !== undefined) {
        filter = `&filter[search]=${searchQueryString}`;
      }
      if (
        selectedNationality !== undefined &&
        String(selectedNationality)?.length !== 0
      ) {
        filter += `&filter[nationality]=${selectedNationality}`;
      }
      if (
        selectedStatus !== undefined &&
        String(selectedStatus)?.length !== 0
      ) {
        filter += `&filter[status]=${selectedStatus}`;
      }
      if (
        selectedContract !== undefined &&
        String(selectedContract)?.length !== 0
      ) {
        filter += `&filter[contract]=${selectedContract}`;
      }
      if (
        selectedCompany !== undefined &&
        String(selectedCompany)?.length !== 0
      ) {
        filter += `&filter[company]=${selectedCompany}`;
      }

      if (applicantType !== undefined && String(applicantType)?.length !== 0) {
        filter += `&filter[category]=${
          getCategoryFromID(applicantType, appOptions)?.slug ?? applicantType
        }`;
      }

      if (
        beforeDate !== undefined &&
        afterDate !== undefined &&
        String(beforeDate)?.length !== 0 &&
        String(afterDate)?.length !== 0
      ) {
        filter += `&filter[created_before]=${afterDate}&filter[created_after]=${beforeDate}`;
      }

      filter = filter[0] === "&" ? filter.slice(1) : filter;
      const options = {
        method: "GET",
        headers: {
          "content-type": "application/json",
          // 'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: `JWT ${token}`,
        },
        url: `${apiRoutes.profileReportExport}?${filter}`,
      };
      axios(options).then((response) => {
        manageLoader(false);
        setShowSuccessModal(true);
        setShowSuccessMessage(response.data.data.message);
      });
    } else {
      manageLoader(false);
      setShowSuccessModal(true);
      setShowSuccessMessage("There is no data to export");
    }
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>Profile Report</h1>
            {/* <sup>
              {" "}
              <span className="d-block d-md-none pl-2">
                <RiRefreshFill
                  className={`${
                    applicants?.isFetching ? "rotating" : ""
                  } text-primary refetch-icon`}
                  onClick={() => handleUpdateKey()}
                />
              </span>
            </sup> */}

            <div className="cmn-btn">
              {/* {checkModuleGeneralPermission(userRoleDetails, "profile-report", "export_report") &&
              (<button className="btn btn-primary">Export</button>)} */}
            </div>

            <div
              className="tableFilterButton"
              title="Advance Search"
              onClick={() => showFilter(true)}
            >
              {/* <span></span>
             <span></span>
             <span></span> */}
              <i className="iconsminds-filter-2"></i>
            </div>
          </div>
          <div className="ReportFilterHolder">
            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="reportSearch">
                  <ReportFilter
                    onNationalityChange={setSelectedNationality}
                    onStatusChange={setSelectedStatus}
                    onCompanyChange={setSelectedCompany}
                    onContractorChange={setselectedContract}
                    onApplicantTypeChange={setApplicantType}
                    nationality={appOptions?.nationality}
                    setCurrentPageNumber={setCurrentPageNumber}
                    handleUpdateKey={handleUpdateKey}
                    statusList={appOptions?.status}
                    setFilterChanged={setFilterChanged}
                    loading={applicants?.isFetching}
                    onSearchInputChange={onSearchInputChange}
                    onExport={onExport}
                    onBeforeDateChange={setBeforeDate}
                    onAfterDateChange={setAfterDate}
                    buttonVisibility={disableExport}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            {/* <JSONConverter /> */}
            <div
              className={
                applicants.isFetching
                  ? "card-body remove-header dataTableOuter reportTable table-loader"
                  : "card-body remove-header dataTableOuter reportTable"
              }
            >
              <DataTable
                subHeader={true}
                //subHeaderComponent={<> <button className="btn btn-primary">Export</button><SearchBar onSearchInputChange={onSearchInputChange} /></>}
                progressPending={applicants.isFetching}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={applicants?.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={applicants?.meta?.pagination?.count}
                expandableRows
                expandableRowsComponent={GeneralReportExpansion}
                paginationDefaultPage={currentPageNumber}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralReport;
