import React from "react";
const ContractorFormComponent = ({
  title,
  contractorName,
  errorState,

  updateInputChange,
}) => {
  return (
    <>
      <p>{title}</p>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState.name ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Contract Name *</b>
        </label>
        <input
          type="text"
          required
          value={contractorName}
          name="name"
          className="form-control"
          placeholder="Contract Name"
          onChange={(e) => updateInputChange(e)}
        />
        <span className="validation-error-message">
          {errorState && errorState.name ? errorState.name : ""}
        </span>
      </div>

    </>
  );
};

export default ContractorFormComponent;
