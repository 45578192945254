import React, { useCallback, useEffect, useRef, useState } from "react";
import ModalComponent from "../common/ModalComponent/ModalComponent";
import { useClient, useMutation } from "jsonapi-react";
import { apiRoutes } from "../../constants";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { useToasts } from "react-toast-notifications";

const ArchiveProfilePopup = ({
  useID,
  profileStatus,
  closeArchivePopup,
  archiveSuccess,
  customClass,
}) => {
  const { addToast } = useToasts();
  const [archiveNote, setArchiveNote] = useState("");
  const [isArchiveLoading, setIsArchiveLoading] = React.useState(false);

  const token = localStorage.getItem("access_token");
  const client = useClient();

  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const [archivePersonProfile] = useMutation(
    apiRoutes.archiveProfile,
    { method: "POST", client }
  );

  const confirmArchiveProfile = async () => {
    setIsArchiveLoading(true);
    const { data, error } = await archivePersonProfile({
      archive: profileStatus === "archived" ? false : true,
      note: archiveNote ? archiveNote : null,
      profileIds: useID,
    });
    setIsArchiveLoading(false);

    if (data) {
      setArchiveNote("");
      addToast(
        profileStatus === "archived"
          ? DISPLAY_TEXTS.PERSON_PROFILE_RESTORED
          : DISPLAY_TEXTS.PERSON_PROFILE_ARCHIVED,
        { appearance: "success" }
      );
      archiveSuccess();
    } else {
      setArchiveNote("");
      addToast(error.detail, { appearance: "error" });
    }
  };

  const closePopup = () => {
    setArchiveNote("");
    closeArchivePopup();
  };

  return (
    <div
      className={`modal fade show d-block`}
      id="ModalComponent"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className={
          customClass
            ? customClass + " modal-dialog modal-dialog-centered"
            : "modal-dialog modal-dialog-centered"
        }
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title">
              {profileStatus === "archived"
                ? "Restore Profile"
                : "Archive Profile"}
            </h5>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="warning-text">
                Note:{"\n"}
                {
                  profileStatus === "archived" 
                    ? DISPLAY_TEXTS.PERSON_PROFILE_RESTORE_WARNING 
                    : DISPLAY_TEXTS.PERSON_PROFILE_ARCHIVE_WARNING
                }
              </div>
              <label htmlFor="archiveNote">Reason</label>
              <textarea
                id="archiveNote"
                type="text"
                name="note"
                className="form-control"
                value={archiveNote}
                onChange={(e) => {
                  setArchiveNote(e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary "
              onClick={() => closePopup()}
              disabled={isArchiveLoading}
            >
              {"Close"}
            </button>

            <button
              type="button"
              className="btn btn-secondary position-relative"
              onClick={() => confirmArchiveProfile()}
              disabled={isArchiveLoading}
            >
              {profileStatus === "archived" ? "Restore" : "Archive"}

              {isArchiveLoading && (
                <span className="button-loader-container">
                  <div
                    className="spinner-border spinner-border-sm ml-1 text-white"
                    role="status"
                  ></div>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveProfilePopup;
