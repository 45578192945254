import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function ScrollToTop({ onRouteChange, path, history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      let scrollEnabled = true;
      onRouteChange(history.location.pathname);

      if (scrollEnabled) {
        window.scrollTo(0, 0);
      }
    }, []);
    return () => {
      unlisten();
    };
  });

  return null;
}

export default withRouter(ScrollToTop);
