import React from "react";
import { formatTextWithFallback } from "../../../helpers/helpers";

function LivePeopleExpandable({ data }) {
  return (
    <>
      <div className="table-expaned-data">
        <div className="item">
          Profile ID <div className="value">{formatTextWithFallback(data?.profile_id)}</div>
        </div>
        <div className="item">
          Status <div className="value">{_.startCase(formatTextWithFallback(data?.profile_status))}</div>
        </div>
        <div className="item">
          Access Control <div className="value">{formatTextWithFallback(data?.dev_name)}</div>
        </div>
      </div>
    </>
  );
}

export default LivePeopleExpandable;
