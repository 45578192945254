import React from "react";

const CompanyFormComponent = ({
  title,
  companyName,
  errorState,
  updateInputChange,
}) => {
  return (
    <>
      <p>{title}</p>
      <div
        className={`form-group position-relative error-l-75 mb-5 ${
          errorState.name ? "hasValidationError" : ""
        }`}
      >
        <label>
          <b>Company Name *</b>
        </label>
        <input
          type="text"
          required
          value={companyName}
          name="name"
          className="form-control"
          placeholder="Company Name"
          onChange={(e) => updateInputChange(e)}
        />
        <span className="validation-error-message">
          {errorState && errorState.name ? errorState.name : ""}
        </span>
      </div>
    </>
  );
};

export default CompanyFormComponent;
