import React, { useState } from "react";
import avatar from "../../assets/images/profileimagePlaceholder.jpg";
import ppeAvatar from "../../assets/images/ppe-avatar.png";
import vehicleavatar from "../../assets/images/vehicleimagePlaceholder.jpg";
import {
  checkModuleViewPermission,
  formatTextWithFallback,
  getLocalDateTime,
  parseIdentificationType,
} from "../../helpers/helpers";
import SwipableCard from "../common/SwipableCard/SwipableCard";
import { AppContext } from "../../context/AppContext";
import { useEffect } from "react";
import _ from "lodash";
import noPreview from "../../assets/images/nopreview.png";
import ModalComponent from "../common/ModalComponent/ModalComponent";
import { getACSEventTypeLabel } from "../../helpers/utils";
import moment from "moment";

const ProfileCard = ({
  data,
  actions,
  actionsToParent,
  mode,
  cellMode,
  fields = [],
  getStatusLabel = () => "",
}) => {
  const { currentUser } = React.useContext(AppContext);

  const passActions = (mode) => {
    actionsToParent(mode);
  };

  const [filteredActionForApplicants, setfilteredActionForApplicants] =
    React.useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogTitle, setDialogTitle] = useState(null);

  function imageDialogContentGen(data) {
    let imageSrc = data?.applicantPhoto[0]?.avatar;

    return (
      <div className="d-flex justify-content-center align-items-center image-dialog-wrapper">
        <img
          src={imageSrc}
          alt={data.fullName}
          height={"100%"}
          className="trim-img-height-vh"
        />
      </div>
    );
  }
  function imageDialogContentPerson(data) {
    let imageSrc = data?.personPhoto?.length
      ? data?.personPhoto[0]?.avatar
      : "";

    return (
      <div className="d-flex justify-content-center align-items-center image-dialog-wrapper">
        <img
          src={imageSrc}
          alt={data?.fullName}
          height={"100%"}
          className="trim-img-height-vh"
        />
      </div>
    );
  }
  function imageDialogContentGenPending(data) {
    let imageSrc = data?.personAvatar;

    return (
      <div className="d-flex justify-content-center align-items-center image-dialog-wrapper">
        <img
          src={imageSrc}
          alt={data?.personFullName ?? ""}
          height={"100%"}
          className="trim-img-height-vh"
        />
      </div>
    );
  }
  function imageDialogContentUser(data) {
    let imageSrc = data?.avatar;

    return (
      <div className="d-flex justify-content-center align-items-center image-dialog-wrapper">
        <img
          src={imageSrc}
          alt={data?.personFullName ?? ""}
          height={"100%"}
          className="trim-img-height-vh"
        />
      </div>
    );
  }
  function imageDialogContentGenVehicle(data, f) {
    let imageSrc = data?.vehiclePicUri;

    return (
      <div className="d-flex justify-content-center align-items-center image-dialog-wrapper">
        <img
          src={f ? imageSrc : noPreview}
          alt={data?.plateNo ?? ""}
          height={f ? "100%" : "450px"}
          className="trim-img-height-vh"
        />
      </div>
    );
  }

  function imageDialogContentAcsEvent(data) {
    let imageSrc = data?.pic_uri;

    return (
      <div className="d-flex justify-content-center align-items-center image-dialog-wrapper">
        <img
          src={imageSrc}
          alt={data?.full_name ?? ""}
          height={"100%"}
          className="trim-img-height-vh"
        />
      </div>
    );
  }

  const onPictureClick = (e, data, flag = false) => {
    e.stopPropagation();
    if (flag) {
      setDialogTitle(data.plateNo);
      setDialogContent(
        imageDialogContentGenVehicle(
          data,
          data?.isImagesUploaded &&
            data?.vehiclePicUri &&
            typeof data?.vehiclePicUri === "string" &&
            data?.vehiclePicUri !== ""
            ? true
            : false
        )
      );
      setShowDialog(true);
    } else {
      if (data?.applicantPhoto?.length) {
        setDialogTitle(data.fullName);
        setDialogContent(imageDialogContentGen(data));
        setShowDialog(true);
      } else if (data?.personAvatar) {
        setDialogTitle(data.personFullName);
        setDialogContent(imageDialogContentGenPending(data));
        setShowDialog(true);
      } else if (data?.avatar) {
        setDialogTitle(data.fullName);
        setDialogContent(imageDialogContentUser(data));
        setShowDialog(true);
      } else if (data?.pic_uri) {
        setDialogTitle(data?.full_name);
        setDialogContent(imageDialogContentAcsEvent(data));
        setShowDialog(true);
      } else if (data?.personPhoto?.length) {
        setDialogTitle(data?.fullName);
        setDialogContent(imageDialogContentPerson(data));
        setShowDialog(true);
      }
    }
  };

  const HyperLink = (label, href) => (
    <div className="file-name-wrap">
      {href ? (
        <a
          href={href}
          className="downloadLink"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          {formatTextWithFallback(label)}
        </a>
      ) : (
        "--"
      )}
    </div>
  );

  const handleCloseDialog = () => {
    setShowDialog(false);
    setDialogContent(null);
    setDialogTitle(null);
  };

  return (
    <>
      <ModalComponent
        showSubmit={true}
        content={dialogContent}
        header={dialogTitle}
        show={showDialog}
        hideCancel
        onSubmitCallback={handleCloseDialog}
        onCloseCallback={handleCloseDialog}
        submitButtonText="Close"
      />
      {(mode === "applicant" ||
        mode === "staff" ||
        mode === "visitor-profile" ||
        mode === "employee-profile" ||
        mode === "DeliveryReg" ||
        mode === "DeliveryAdhoc") && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${
                data?.photoAdded &&
                data?.personPhoto?.length &&
                "cursor-pointer"
              }`}
              onClick={(e) => data?.photoAdded && onPictureClick(e, data)}
            >
              <img
                alt=""
                className={`${data?.status}`}
                src={
                  data?.photoAdded && data?.personPhoto?.length
                    ? data.personPhoto[0].avatar
                    : avatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div className="data">
              <div className="name">
                <b>{`${data.fullName}`}</b>
              </div>
              <div>
                <div className="text-capitalize">
                  <span className={`statusLabel ${data.status}`}>
                    {data.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              mode={mode}
              data={data}
              isPerson
            >
              <div className="profile-card ">
                <div
                  className={`pic ${
                    data?.photoAdded &&
                    data?.personPhoto?.length &&
                    "cursor-pointer"
                  }`}
                  onClick={(e) => data?.photoAdded && onPictureClick(e, data)}
                >
                  <img
                    alt=""
                    className={`${data.status}`}
                    src={
                      data?.photoAdded && data?.personPhoto?.length
                        ? data.personPhoto[0].avatar
                        : avatar
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = avatar;
                    }}
                  />
                </div>
                <div className="data">
                  <div className="name">
                    <b>{`${data.fullName}`}</b>
                  </div>
                  <div>
                    <div className="text-capitalize">
                      <span className={`statusLabel ${data.status}`}>
                        {data.status}
                      </span>
                    </div>

                    {/* {fieldData1.name&&fieldData1.value&&
                    <div className="text-capitalize hide-md">
                      {fieldData1.name} : {fieldData1.value}
                    </div>}
                    {mode === "applicant" && (
                      <span className="text-capitalize hide-md">
                        {" "}
                        Type : {_.replace(data.applicantType, "_", " ")}{" "}
                      </span>
                    )}
                        {fieldData2.name&&fieldData2.value&&
                   <div className="text-capitalize hide-md">
                      {fieldData2.name} : {fieldData2.value}
                    </div>}
                    <div className="text-capitalize hide-md">
                      Work Permit Expiry :{" "}
                      {data.workPermitExpiry ? data.workPermitExpiry : "--"}
                    </div> */}

                    {fields?.length !== 0 &&
                      fields.map((field, index) => (
                        <React.Fragment key={index}>
                          {field?.name && (
                            <div className="text-capitalize hide-md">
                              {field.name} :{" "}
                              {formatTextWithFallback(field.value)}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "vs_expiry" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${
                String(data?.personAvatar ?? "").length > 0
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={(e) => onPictureClick(e, data)}
            >
              <img
                alt=""
                className={data.status}
                src={
                  String(data?.personAvatar ?? "").length > 0
                    ? data.personAvatar
                    : avatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div className="data">
              <div className="name">
                <b>{`${data.personFullName}`}</b>
              </div>
              <div>
                <div className="text-capitalize">
                  <span className={"statusLabel " + data.status}>
                    {data.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              cellMode={cellMode}
              isAccessExpiry
            >
              <div className="profile-card ">
                <div
                  className={`pic ${
                    String(data?.personAvatar ?? "").length > 0
                      ? "cursor-pointer"
                      : ""
                  }`}
                  onClick={(e) => onPictureClick(e, data)}
                >
                  <img
                    alt=""
                    className={data.status}
                    src={
                      String(data?.personAvatar ?? "").length > 0
                        ? data.personAvatar
                        : avatar
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = avatar;
                    }}
                  />
                </div>
                <div className="data">
                  <div className="name">
                    <b>{`${data.personFullName}`}</b>
                  </div>
                  <div>
                    <div className="text-capitalize">
                      <span className={"statusLabel " + data.status}>
                        {data.status}
                      </span>
                    </div>
                    {fields?.length !== 0 &&
                      fields.map((field, index) => (
                        <React.Fragment key={index}>
                          {field?.name && (
                            <div className="text-capitalize hide-md">
                              {field.name} :{" "}
                              {formatTextWithFallback(field.value)}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "user" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${data?.avatar && "cursor-pointer"}`}
              onClick={(e) => onPictureClick(e, data)}
            >
              <img
                alt=""
                src={data?.avatar ?? avatar}
                className={data?.isActive ? "active" : "inactive"}
              />
            </div>
            <div className="data">
              <div className="name">
                <b>{`${data.fullName}`}</b>
              </div>
              <div>
                <div className="text-capitalize ">{`${data.role.name}`} </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              isUser={true}
              mode={mode}
              data={data}
            >
              <div className="profile-card">
                <div
                  className={`pic ${data?.avatar && "cursor-pointer"}`}
                  onClick={(e) => onPictureClick(e, data)}
                >
                  <img
                    alt=""
                    src={data?.avatar ?? avatar}
                    className={data?.isActive ? "active" : "inactive"}
                  />
                </div>
                <div className="data">
                  <div className="name">
                    <b>{data?.fullName}</b>
                  </div>
                  <div>
                    <div className="text-capitalize ">{data?.role?.name}</div>
                    <div>Email : {formatTextWithFallback(data?.email)}</div>
                    <div>
                      Phone Number : {formatTextWithFallback(data?.phoneNumber)}
                    </div>
                    <div>Status : {data?.isActive ? "Active" : "Inactive"}</div>
                  </div>
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {(mode === "company" || mode === "contract") && (
        <>
          <div className="profile-card visible-big">
            <div className="data">
              <div className="name">
                <b className="text-uppercase">{data.name}</b>
              </div>
              <div></div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              isCompany={true}
              data={data}
              mode={mode}
            >
              <div className="profile-card">
                <div className="data">
                  <div className="name">
                    <b className="text-uppercase">{data.name}</b>
                  </div>
                  {
                    <div>
                      Added Profiles :{" "}
                      {formatTextWithFallback(data.profilesAdded)}
                    </div>
                  }
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "self-registration-invite" && (
        <>
          <div className="profile-card visible-big">
            <div className="data">
              <div className="name">
                <b>{data?.description}</b>
              </div>
              <div></div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              isCompany={true}
              data={data}
              mode={mode}
              isInvite
              getStatusLabel={getStatusLabel}
            >
              <div className="profile-card">
                <div className="data">
                  <div className="name">
                    <b>{data?.description}</b>
                  </div>
                  {fields?.length !== 0 &&
                    fields.map((field, index) => (
                      <React.Fragment key={index}>
                        {field?.name && (
                          <div className="text-capitalize hide-md">
                            {field.name} : {field.value ?? "--"}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "bulk-registration" && (
        <>
          <div className="profile-card visible-big">
            <div className="data">
              <div className="date">
                <b>{getLocalDateTime(data?.created)}</b>
              </div>
              <div></div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              data={data}
              mode={mode}
              isBulkRegistration
              getStatusLabel={getStatusLabel}
            >
              <div className="profile-card">
                <div className="data">
                  {fields?.length !== 0 &&
                    fields.map((field, index) => (
                      <React.Fragment key={index}>
                        {field?.name && (
                          <div className="hide-md">
                            <span className="text-capitalize">
                              {field.name}
                            </span>{" "}
                            : {field.value ?? "--"}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "my-downloads" && (
        <>
          <div className="profile-card visible-big">
            <div className="data">
              <div className="name">
                <b>{data?.fileName}</b>
              </div>
              <div></div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              data={data}
              mode={mode}
              isDownload
              getStatusLabel={getStatusLabel}
            >
              <div className="profile-card">
                <div className="data">
                  <div className="name">
                    <b>{data?.fileName}</b>
                  </div>
                  {fields?.length !== 0 &&
                    fields.map((field, index) => (
                      <React.Fragment key={index}>
                        {field?.name && (
                          <div className="text-capitalize hide-md">
                            {field.name} : {field.value ?? "--"}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "ppe-event" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic cursor-pointer
                `}
              onClick={(e) => passActions("view")}
            >
              <img
                alt={data?.timeStamp}
                src={data?.imageUrl ?? ppeAvatar}
                onError={() => (data.imageUrl = ppeAvatar)}
              />
            </div>
            <div className="name d-flex flex-column justify-content-center align-items-center">
              <p className="m-0">
                {moment(data?.timeStamp).format("DD-MM-YYYY hh:mm:ss A")}
              </p>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              data={data}
              mode={mode}
              cellMode={cellMode}
              customMode
              type="report"
            >
              <div className="profile-card">
                <div
                  className={`pic cursor-pointer
                  `}
                  onClick={(e) => passActions("view")}
                >
                  <img
                    alt={data?.timeStamp}
                    src={data?.imageUrl ?? ppeAvatar}
                    onError={() => (data.imageUrl = ppeAvatar)}
                  />
                </div>
                <div className="data">
                  <div className="name">
                    <p className="m-0">
                      {moment(data?.timeStamp).format("DD-MM-YYYY hh:mm:ss A")}
                    </p>
                  </div>
                  {fields?.length !== 0 &&
                    fields.map((field, index) => (
                      <React.Fragment key={index}>
                        {field?.name && (
                          <div className="text-capitalize hide-md">
                            {field.name} : {field.value ?? "--"}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "Training" && (
        <>
          <div className="profile-card visible-big">
            <div className="data">
              <div className="name">
                <b>{data.topic}</b>
              </div>
              <div></div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              mode={mode}
            >
              <div className="profile-card">
                <div className="data">
                  <div className="name">
                    <b>{data.topic}</b>
                  </div>
                  <div>
                    <div>Date : {data.trainingDateTime}</div>
                  </div>
                  <div></div>
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "profile-report" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${
                data?.personPhoto?.length && data?.personPhoto[0]?.avatar
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={(e) => onPictureClick(e, data)}
            >
              <img
                alt=""
                className={data?.status}
                src={
                  data?.personPhoto?.length
                    ? data?.personPhoto[0]?.avatar
                    : avatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div className="data">
              <div className="name">
                <b>{`${data?.fullName}`}</b>
              </div>
              <div>
                <div className="text-capitalize">
                  <span className={"statusLabel " + data?.status}>
                    {data?.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              mode={mode}
              cellMode={cellMode}
              isPersonReport
            >
              <div className="profile-card ">
                <div
                  className={`pic ${
                    data?.personPhoto?.length && data?.personPhoto[0]?.avatar
                      ? "cursor-pointer"
                      : ""
                  }`}
                  onClick={(e) => onPictureClick(e, data)}
                >
                  <img
                    alt=""
                    className={data?.status}
                    src={
                      data?.personPhoto?.length
                        ? data?.personPhoto[0]?.avatar
                        : avatar
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = avatar;
                    }}
                  />
                </div>
                <div className="data">
                  <div className="name">
                    <b>{`${data?.fullName}`}</b>
                  </div>
                  <div>
                    <div className="text-capitalize">
                      <span className={"statusLabel " + data?.status}>
                        {data?.status}
                      </span>
                    </div>

                    {/* {fieldData1.name&&fieldData1.value&&
                    <div className="text-capitalize hide-md">
                      {fieldData1.name} : {fieldData1.value}
                    </div>}
                    {mode === "applicant" && (
                      <span className="text-capitalize hide-md">
                        {" "}
                        Type : {_.replace(data.applicantType, "_", " ")}{" "}
                      </span>
                    )}
                        {fieldData2.name&&fieldData2.value&&
                   <div className="text-capitalize hide-md">
                      {fieldData2.name} : {fieldData2.value}
                    </div>}
                    <div className="text-capitalize hide-md">
                      Work Permit Expiry :{" "}
                      {data.workPermitExpiry ? data.workPermitExpiry : "--"}
                    </div> */}

                    {fields?.length !== 0 &&
                      fields.map((field, index) => (
                        <React.Fragment key={index}>
                          {field?.name && (
                            <div className="text-capitalize hide-md">
                              {field.name} :{" "}
                              {formatTextWithFallback(field.value)}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "lprReport" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic cursor-pointer
              `}
              onClick={(e) => onPictureClick(e, data, true)}
            >
              <img
                alt=""
                className={data.status}
                src={
                  data?.isImagesUploaded &&
                  data?.vehiclePicUri &&
                  typeof data?.vehiclePicUri === "string" &&
                  data?.vehiclePicUri !== ""
                    ? data.vehiclePicUri
                    : vehicleavatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = vehicleavatar;
                }}
              />
            </div>
            <div className="data">
              <div className="name">
                <b>{`${data.plateNo}`}</b>
              </div>
              <div>
                <div className="text-capitalize">
                  <span className={"statusLabel " + data.inAndOutType}>
                    {data.inAndOutType}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={filteredActionForApplicants}
              fireEvent={passActions}
              isVehicle={true}
            >
              <div className="profile-card ">
                <div
                  className={`pic cursor-pointer`}
                  onClick={(e) => onPictureClick(e, data, true)}
                >
                  <img
                    alt=""
                    className={data.status}
                    src={
                      data?.isImagesUploaded &&
                      data?.vehiclePicUri &&
                      typeof data?.vehiclePicUri === "string" &&
                      data?.vehiclePicUri !== ""
                        ? data.vehiclePicUri
                        : vehicleavatar
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = vehicleavatar;
                    }}
                  />
                </div>
                <div className="data">
                  <div className="name">
                    <b>{`${data.plateNo}`}</b>
                  </div>
                  <div>
                    <div className="text-capitalize">
                      <span className={"statusLabel " + data.inAndOutType}>
                        {data.inAndOutType}
                      </span>
                    </div>

                    {fields?.length !== 0 &&
                      fields.map((field, index) => (
                        <React.Fragment key={index}>
                          {field?.name && (
                            <div className="text-capitalize hide-md">
                              {field.name} : {field.value ?? "--"}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "acs-event-report" && (
        <>
          <div className="profile-card visible-big w-100">
            <div
              className={`pic ${
                data?.is_images_uploaded && data?.pic_uri
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={(e) =>
                data?.is_images_uploaded && onPictureClick(e, data)
              }
            >
              <img
                alt=""
                className={getACSEventTypeLabel(data?.event_type)}
                src={
                  data?.is_images_uploaded &&
                  data?.pic_uri &&
                  typeof data?.pic_uri === "string" &&
                  data?.pic_uri !== ""
                    ? data.pic_uri
                    : avatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div>
              <div className="name">
                <b>{`${data?.full_name}`}</b>
              </div>
              <div>
                <div className="text-capitalize pt-1">
                  <span
                    className={
                      "statusLabel " + getACSEventTypeLabel(data?.event_type)
                    }
                  >
                    {getACSEventTypeLabel(data?.event_type)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              // actions={filteredActionForApplicants}
              actions={actions}
              fireEvent={passActions}
              mode={mode}
              cellMode={cellMode}
              data={data}
              isPersonReport
            >
              <div className="profile-card w-100">
                <div
                  className={`pic ${
                    data?.is_images_uploaded && data?.pic_uri
                      ? "cursor-pointer"
                      : ""
                  }`}
                  onClick={(e) =>
                    data?.is_images_uploaded && onPictureClick(e, data)
                  }
                >
                  <img
                    alt=""
                    className={getACSEventTypeLabel(data?.event_type)}
                    src={
                      data?.is_images_uploaded &&
                      data?.pic_uri &&
                      typeof data?.pic_uri === "string" &&
                      data?.pic_uri !== ""
                        ? data.pic_uri
                        : avatar
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = avatar;
                    }}
                  />
                </div>
                <div className="data w-100">
                  <div className="name d-flex align-items-center">
                    <div>
                      <b className="text-capitalize font-weight-bold">{`${data?.full_name}`}</b>
                    </div>
                    <div className="hide-md px-4">
                      <div
                        className={`p-1 entry-status ${getACSEventTypeLabel(
                          data?.event_type
                        )}`}
                      >
                        {getACSEventTypeLabel(data?.event_type)}
                      </div>
                    </div>
                  </div>

                  {fields?.length !== 0 &&
                    fields.map((field, index) => (
                      <React.Fragment key={index}>
                        {field?.name && (
                          <div className="text-capitalize hide-md">
                            {field.name} : {field.value ?? "--"}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "BCAReport" && (
        <>
          <div className="profile-card visible-big">
            <div className="data">
              <div className="name">
                <b>{data.fullName}</b>
              </div>
              <div></div>
            </div>
          </div>
          <div className="visible-small">
            <div className="profile-card">
              <div className="data">
                <div className="name">
                  <b>{data.fullName}</b>
                </div>
                <div>
                  <div className="text-capitalize">
                    {data.idType && data.idType !== "passport"
                      ? data.idType.toUpperCase()
                      : data.idType}{" "}
                    Number : {parseIdentificationType(data)}
                  </div>
                  <div>Phone : {data.mobileNumber}</div>
                  <div>Email : {data.email}</div>
                  <div>Designation : {data.designation}</div>

                  <div>Nationality : {data.nationality}</div>
                  <div>Company : {data.company?.name}</div>

                  <div>Status : {data.status}</div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      )}
      {mode === "AplcntsPending" && (
        <>
          <div className="profile-card visible-big">
            <div className="data">
              <div className="name">
                <b>{data.fullName}</b>
              </div>
              <div></div>
            </div>
          </div>
          <div className="visible-small">
            <div className="profile-card">
              <div className="data">
                <div className="name">
                  <b>{data.fullName}</b>
                </div>
                <div>
                  <div className="text-capitalize">
                    {data.idType && data.idType !== "passport"
                      ? data.idType.toUpperCase()
                      : data.idType}{" "}
                    Number : {parseIdentificationType(data)}
                  </div>
                  <div>Designation : {data.designation}</div>
                  <div>Company Name : {data.company?.name}</div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      )}
      {(mode === "staffExp" || mode === "statExp") && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${
                (mode === "staffExp"
                  ? data?.personPhoto?.length > 0
                  : String(data?.avatar)?.length > 0) && "cursor-pointer"
              }`}
              onClick={(e) => onPictureClick(e, data)}
            >
              <img
                alt=""
                className={data.personStatus ?? data?.status}
                src={
                  mode === "staffExp"
                    ? data?.personPhoto?.length
                      ? data.personPhoto[0].avatar
                      : avatar
                    : data?.avatar ?? avatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div className="data">
              <div className="name">
                <b>
                  {mode === "staffExp" ? data?.fullName : data?.personFullname}
                </b>
              </div>
              <div>
                <div className="text-capitalize">
                  <span
                    className={
                      "statusLabel " + (data?.personStatus ?? data?.status)
                    }
                  >
                    {data?.personStatus ?? data?.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <div
              className={`profile-card w-100 ${
                checkModuleViewPermission(
                  actions,
                  cellMode,
                  "profile",
                  data ?? {}
                )
                  ? "pointer"
                  : ""
              }`}
              onClick={() =>
                checkModuleViewPermission(
                  actions,
                  cellMode,
                  "profile",
                  data ?? {}
                )
                  ? passActions("view")
                  : null
              }
            >
              <div
                className={`pic ${
                  (mode === "staffExp"
                    ? data?.personPhoto?.length > 0
                    : String(data?.avatar)?.length > 0) && "cursor-pointer"
                }`}
                onClick={(e) => onPictureClick(e, data)}
              >
                <img
                  alt=""
                  className={data.personStatus ?? data?.status}
                  src={
                    mode === "staffExp"
                      ? data?.personPhoto?.length
                        ? data.personPhoto[0].avatar
                        : avatar
                      : data?.avatar ?? avatar
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = avatar;
                  }}
                />
              </div>
              <div className="data">
                <div className="name">
                  <b>{`${
                    mode === "staffExp" ? data?.fullName : data?.personFullname
                  }`}</b>
                </div>
                <div>
                  <div className="text-capitalize">
                    <span
                      className={
                        "statusLabel " + (data.personStatus ?? data?.status)
                      }
                    >
                      {data.personStatus ?? data?.status}
                    </span>
                  </div>
                  {fields?.length !== 0 &&
                    fields.map((field, index) => (
                      <React.Fragment key={index}>
                        {field?.name && (
                          <div className="text-capitalize hide-md">
                            {field.name} :{" "}
                            {String(field.name)?.toLowerCase() === "document"
                              ? HyperLink(
                                  field?.value?.documentName,
                                  field?.value?.document
                                )
                              : formatTextWithFallback(String(field?.value))}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {mode === "applicantPendingPass" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${data?.personAvatar && "cursor-pointer"}`}
              onClick={(e) => onPictureClick(e, data)}
            >
              <img
                alt=""
                className={"active"}
                src={data?.personAvatar ? data.personAvatar : avatar}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div className="data">
              <div className="name">
                <b>{`${data.personFullName}`}</b>
              </div>
              {/* <div>
                <div className="text-capitalize">
                  <span className={"statusLabel " + data.status}>
                    {data.status}
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <div className="visible-small">
            <SwipableCard
              background="red"
              threshold={0.2}
              actions={actions}
              fireEvent={passActions}
              mode={mode}
              data={data}
              isPass
              customMode
              cellMode={cellMode}
              type="profile"
            >
              <div className="profile-card">
                <div
                  className={`pic ${data?.personAvatar && "cursor-pointer"}`}
                  onClick={(e) => onPictureClick(e, data)}
                >
                  <img
                    alt=""
                    className={"active"}
                    src={data?.personAvatar ? data.personAvatar : avatar}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = avatar;
                    }}
                  />
                </div>
                <div className="data">
                  <div className="name">
                    <b>{`${data.personFullName}`}</b>
                  </div>
                  <div>
                    {/* <div className="text-capitalize">
                      <span className={"statusLabel " + data.status}>
                        {data.status}
                      </span>
                    </div> */}
                    {fields?.length !== 0 &&
                      fields.map((field, index) => (
                        <React.Fragment key={index}>
                          {field?.name && (
                            <div className="text-capitalize hide-md">
                              {field.name} :{" "}
                              {formatTextWithFallback(field.value)}
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </SwipableCard>
          </div>
        </>
      )}
      {mode === "valExp" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${data?.personAvatar && "cursor-pointer"}`}
              onClick={(e) => onPictureClick(e, data)}
            >
              <img
                alt=""
                className={data.status}
                src={data?.personAvatar ? data.personAvatar : avatar}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div className="data">
              <div className="name">
                <b>{`${data.personFullName}`}</b>
              </div>
              <div>
                <div className="text-capitalize">
                  <span className={"statusLabel " + data.status}>
                    {data.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <div className="profile-card w-100">
              <div
                className={`pic ${data?.personAvatar && "cursor-pointer"}`}
                onClick={(e) => onPictureClick(e, data)}
              >
                <img
                  alt=""
                  className={data.status}
                  src={data?.personAvatar ? data.personAvatar : avatar}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = avatar;
                  }}
                />
              </div>
              <div className="data">
                <div className="name">
                  <b>{`${data.personFullName}`}</b>
                </div>
                <div>
                  <div className="text-capitalize">
                    <span className={"statusLabel " + data.status}>
                      {data.status}
                    </span>
                  </div>
                  {fields?.length !== 0 &&
                    fields.map((field, index) => (
                      <React.Fragment key={index}>
                        {field?.name && (
                          <div className="text-capitalize hide-md">
                            {field.name} : {field.value ?? "--"}
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {mode === "peopleLive" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${
                data?.is_images_uploaded && data?.pic_uri
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={(e) =>
                data?.is_images_uploaded && onPictureClick(e, data)
              }
            >
              <img
                alt=""
                className={data?.profile_status}
                src={
                  data?.is_images_uploaded &&
                  String(data?.pic_uri ?? "")?.length
                    ? String(data?.pic_uri ?? "")
                    : avatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div className="data d-flex align-items-center">
              <div className="name">
                <b>{`${data.full_name}`}</b>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <div
              className={`profile-card w-100 ${
                checkModuleViewPermission(
                  actions,
                  cellMode,
                  "profile",
                  data ?? {}
                )
                  ? "pointer"
                  : ""
              }`}
              onClick={() =>
                checkModuleViewPermission(
                  actions,
                  cellMode,
                  "profile",
                  data ?? {}
                )
                  ? passActions("view")
                  : null
              }
            >
              <div
                className={`pic ${
                  data?.is_images_uploaded && data?.pic_uri
                    ? "cursor-pointer"
                    : ""
                }`}
                onClick={(e) =>
                  data?.is_images_uploaded && onPictureClick(e, data)
                }
              >
                <img
                  alt=""
                  className={data.profile_status}
                  src={
                    data?.is_images_uploaded &&
                    String(data?.pic_uri ?? "")?.length
                      ? String(data?.pic_uri ?? "")
                      : avatar
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = avatar;
                  }}
                />
              </div>
              <div className="data">
                <div className="name">
                  <b>{`${data?.full_name}`}</b>
                </div>
                <div>
                  <div className="text-capitalize hide-md">
                    {" "}
                    Category : {formatTextWithFallback(data?.category)}{" "}
                  </div>
                  <div className="text-capitalize">
                    Company : {formatTextWithFallback(data?.company)}
                  </div>
                  <div className="text-capitalize">
                    Event Time :{" "}
                    {getLocalDateTime(data?.event_time)?.toUpperCase()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {mode === "overStay" && (
        <>
          <div className="profile-card visible-big">
            <div
              className={`pic ${
                data?.is_images_uploaded && data?.pic_uri
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={(e) =>
                data?.is_images_uploaded && onPictureClick(e, data)
              }
            >
              <img
                alt=""
                className={data.profile_status}
                src={
                  data?.is_images_uploaded && data?.pic_uri?.length
                    ? data.pic_uri
                    : avatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = avatar;
                }}
              />
            </div>
            <div className="data ">
              <div className="name">
                <b>{`${data.full_name}`}</b>
              </div>
              <div>
                <div className="text-capitalize">
                  <span className={"statusLabel " + data.profile_status}>
                    {data.profile_status}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="visible-small">
            <div
              className={`profile-card w-100 ${
                checkModuleViewPermission(
                  actions,
                  cellMode,
                  "profile",
                  data ?? {}
                )
                  ? "pointer"
                  : ""
              }`}
              onClick={() =>
                checkModuleViewPermission(
                  actions,
                  cellMode,
                  "profile",
                  data ?? {}
                )
                  ? passActions("view")
                  : null
              }
            >
              <div
                className={`pic ${
                  data?.is_images_uploaded && data?.pic_uri
                    ? "cursor-pointer"
                    : ""
                }`}
                onClick={(e) =>
                  data?.is_images_uploaded && onPictureClick(e, data)
                }
              >
                <img
                  alt=""
                  className={data.profile_status}
                  src={
                    data?.is_images_uploaded && data?.pic_uri?.length
                      ? data.pic_uri
                      : avatar
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = avatar;
                  }}
                />
              </div>
              <div className="data">
                <div className="name">
                  <b>{`${data.full_name}`}</b>
                </div>
                <div>
                  <div className="text-capitalize">
                    <span className={"statusLabel " + data.profile_status}>
                      {data.profile_status}
                    </span>
                  </div>
                  <div className="text-capitalize">Type : {data.category}</div>

                  {/* <div className="text-capitalize">ID Type : {data.idType}</div> */}

                  <div className="text-capitalize">
                    Company : {data.company?.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProfileCard;
