import React, { useContext } from "react";
import BarChart from "../Charts/dashBarChart";
import { chartTooltip } from "../Charts/util";
import _ from "lodash";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";
import { useToasts } from "react-toast-notifications";
import { mapThreeMonthsVisitData } from "../../mappers/dashboardMappers";
import ReactTooltip from "react-tooltip";
import { isWidgetRefreshable } from "../../helpers/helpers";

const dataKeys = ["Staff", "Frequent Delivery", "Visitor", "Delivery Adhoc"]

const DashboardChartArea = ({ widget }) => {
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();

  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const [monthlyVisitData, setmonthlyVisitData] = React.useState(undefined);
  const [monthGraphData, setmonthGraphData] = React.useState(undefined);
  const [monthGraphMaxim, setMonthGraphMaxim] = React.useState(0);
  const [monthGraphMinim, setMonthGraphMinim] = React.useState(0);
  const [rawDataArray, setRawDataArray] = React.useState([]);
  const [barchartStepSize, setbarchartStepSize] = React.useState(100);

  const [monthChartType, setmonthChartType] = React.useState("sNd");


  const token = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "content-type": "multipart/form-data",
    },
  };
  const apiUrl =
    widget.widget.config.dataSource === "external"
      ? widget.widget.config.url
      : apiRoutes.dashboardWidgetsData + "/" + slug;

 const fetchData = () => {
   Axios.get(apiUrl, config)
     .then((response) => {
        if(!_.isEmpty(response?.data?.data)){
          const apiData = Object.fromEntries(
            dataKeys?.map((key) => [key, response?.data?.data[key] ?? []])
          );
          setmonthlyVisitData(mapThreeMonthsVisitData(apiData));
        } else setmonthlyVisitData({});
     })
     .catch((error) => {
        setmonthlyVisitData({});
        console.log(error);
     });
 };

 const updateData = () => {
  setIsUpdateLoading(true);
  Axios.get(apiUrl + "/update", config)
    .then((response) => {
      addToast(response?.data?.data?.message ?? "Widget updated Successfully!", { appearance: "success" });
    })
    .catch((error) => {
      addToast(error.detail ?? "Failed to update widget!", { appearance: "error" });
    }).finally(() => setIsUpdateLoading(false));
}

 React.useEffect(() => {
   fetchData();
   if (
     widget?.widget?.config?.refreshRate &&
     widget?.widget?.config?.refreshRate > 0
   ) {
     const timeoutId = setInterval(() => {
       fetchData();
     }, widget?.widget?.config?.refreshRate * 1000);
     return () => {
       clearInterval(timeoutId);
     };
   }
 }, [widget]);

 const getMonthGraphData = (switchData, type) => {
    let dataClone;
    setmonthChartType(switchData);
    if (monthlyVisitData?.graphData) {

      dataClone = { ...monthlyVisitData?.graphData };
      if (switchData === "vNd") {
        setbarchartStepSize(100);
      } else {
        setbarchartStepSize(10000);
      }
    }
    if (dataClone) {
      if (switchData === "vNd" || switchData === "vNdLine") {
        const filterBy = dataClone.datasets?.filter((item) => {
          return item.label === "Visitor" || item.label === "Delivery Adhoc";
        });
        dataClone.datasets = filterBy;
      } else {
        const filterBy = dataClone.datasets?.filter((item) => {
          return item.label === "Staff" || item.label === "Frequent Delivery";
        });
        dataClone.datasets = filterBy;
      }

      let dataArray = [];
      if (dataClone.datasets.length > 0) {
        dataClone.datasets.map((item) => {
          return item.data.map((value) => {
            return dataArray.push(value);
          });
        });
      }
      setRawDataArray(dataArray);
      if (type === "monthly") {
        setmonthGraphData(dataClone);
        setMonthGraphMaxim(_.max(dataArray));
        setMonthGraphMinim(_.min(_.reject(dataArray, (dataItem) => !dataItem)));
      }
    }
  };
  const barChartOptions = {
    legend: {
      position: "top",
      align: "end",
      labels: {
        padding: 30,
        usePointStyle: true,
        fontSize: 12,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    with:"100%",
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            lineWidth: 1,
            color: "rgba(0,0,0,0.1)",
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: Math.floor(barchartStepSize),
            min: 0,
            max: monthGraphMaxim,
            padding: 20,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: chartTooltip,
  };

  React.useEffect(() => {
    if(monthlyVisitData !== undefined){
      if (monthlyVisitData?.graphData?.datasets?.length) {
        getMonthGraphData("sNd", "monthly");
      } else { setmonthGraphData({}); }
    }
    // eslint-disable-next-line
  }, [monthlyVisitData]);

  return (
    <>

        <div className="card">
          <div className="card-header resp-header">
            <div className="graph-title">
              PEOPLE ENTRY OVERVIEW
            </div>
            <div className="d-flex align-items-center">
              <ul className="nav nav-tabs graph-tab float-right">
                <li className="d-flex align-items-center">
                  <a data-toggle="tab" className="active" href="#people-view-month">
                    Last 3 Months
                  </a>
                </li>
              </ul>
              {isWidgetRefreshable(userRoleDetails, widget) &&
                <span
                  onClick={() => {!isupdateLoading && updateData()}}
                  className={`refresh-button ml-1 ${isupdateLoading ? "spin" : ""}`}
                  data-tip="Update Data" >
                    <i className="iconsminds-refresh" />
                </span>
              }
            </div>
          </div>
          <ReactTooltip />
          <div className="dashboard-pchart p-2 pt-3 pr-3 dashbrd-btm-graph widget-data-container">
            <div className="tab-content h-100 overflow-hidden">
              <div
                id="people-view-month"
                className="tab-pane fade in active show h-100"
              >
                <BarChart
                  chartOptions={barChartOptions}
                  dataArray={rawDataArray}
                  data={monthGraphData === undefined ? [] : monthGraphData}
                />
                <div className="btn-holder">
                  <button
                    className={monthChartType === "sNd" ? "active" : ""}
                    onClick={() => getMonthGraphData("sNd", "monthly")}
                  >
                    Staff & Frequent Delivery
                  </button>

                  <button
                    className={monthChartType === "vNd" ? "active" : ""}
                    onClick={() => getMonthGraphData("vNd", "monthly")}
                  >
                    Visitor & Delivery Adhoc
                  </button>
                </div>
              </div>
            </div>
            {((monthlyVisitData !== undefined) 
              && (_.isEmpty(monthlyVisitData)) || !(monthlyVisitData?.graphData?.datasets?.length)) 
              && <div className="no-data-overlay"><span>No Data Available!</span></div>
            }
          </div>
        </div>
    </>
  );
};
export default DashboardChartArea;
