import React from "react";
import DatePicker from "react-datepicker";

const UserTrainingFormComponent = ({
  title,
  formData,
  errors,
  updateInputChange,
}) => {
  return (
    <>
      <p>{title}</p>
      <div className="form-group position-relative">
        <label>
          <b>Training Topic</b>
        </label>
        <input
          type="text"
          value={formData.topic}
          name="topic"
          className="form-control"
          placeholder="Topic"
          onChange={(e) => updateInputChange(e.target)}
        />
        <span className="validation-error-message">{errors.topic}</span>
      </div>
      {/* <div className="form-group position-relative">
				<label><b>Training Location</b></label>
				<input type="text" value={formData.location} name="location" className="form-control" placeholder="Location" onChange={(e) => updateInputChange(e.target)} />
				<span className="validation-error-message">{errors.location}</span>
			</div> */}
      <div className="form-group position-relative">
        <label>
          <b>Training Date</b>
        </label>
        <DatePicker
          className="form-control"
          dateFormat="dd/MM/yyyy hh:mm aa"
          placeholderText="DD/MM/YYYY HH:MM"
          selected={formData.training_date}
          onChange={(date) =>
            updateInputChange({ name: "training_date", value: date })
          }
          showTimeSelect
        />
        <span className="validation-error-message">{errors.training_date}</span>
      </div>
    </>
  );
};

export default UserTrainingFormComponent;
