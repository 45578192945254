// import axios from "axios";
import React, { useState } from "react";
import { useQuery, useClient } from "jsonapi-react";
import _ from "lodash";
import "./duplicateProfile.scss";
import profileimagePlaceholder from "../../../assets/images/profileimagePlaceholder.jpg";
import Slider from "react-slick";
import { getLocalDateTime } from "../../../helpers/helpers";
import { VW_ROUTES } from "../../../constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment-timezone";

const DuplicateProfiles = ({
  title,
  show,
  customClass,
  data,
  onCloseCallback,
}) => {

  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getProfileImage = (data) => {
    if (data?.personPhoto?.length > 0 && data?.personPhoto[0]?.avatar) {
      return data?.personPhoto[0]?.avatar;
    } else {
      return profileimagePlaceholder;
    }
  };

  return (
    <>
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        id="ModalComponent"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered wide"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title normal-text">
                There {data.length > 1 ? "are" : "is"} {data.length}{" "}
                {data.length > 1 ? "profiles" : "profile"}{" "}
                {data.length > 1 ? "exist" : "existing"} with the same identification number
              </h5>
            </div>
            <div className="modal-body duplicateSlider">
              <Slider {...settings}>
                {data &&
                  data.map((item) => {
                    return (
                      <div>
                        <div className="duplicateSlide">
                          <div className="image">
                            <div className="image-holder">
                              <img
                                src={getProfileImage(item)}
                                alt="Profile image"
                              />
                              <div className="profile-name">
                                {item.fullName}
                              </div>
                              <div className="profile-type">
                                {item.category?.name}
                              </div>
                              <div>
                                <span className="profile-label mr-2">
                                  {" "}
                                  Profile Status:{" "}
                                </span>
                                <span
                                  className={`profile-label-value font-weight-bold text-capitalize ${item?.status ?? ""
                                    }`}
                                >
                                  {" "}
                                  {item?.status}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="details">
                            <div className="row">
                              <div className="col-md-12 mb-2 col-lg-6">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Profile ID{" "}
                                </div>
                                <div className="profile-label-value font-weight-bold">
                                  {" "}
                                  {item.profileId}{" "}
                                </div>
                              </div>

                              <div className="col-md-12 mb-2 col-lg-6">
                                <div className="profile-label mt-2">
                                  {" "}
                                  ID Type{" "}
                                </div>

                                <div className="profile-label-value font-weight-bold text-uppercase">
                                  {item.idType && item.idType !== "passport"
                                    ? item.idType.toUpperCase()
                                    : item.idType}{" "}
                                  {" "}<br />
                                  {/* <span className="text-uppercase">
                                    {item.idNumber}
                                  </span> */}
                                </div>
                              </div>

                              <div className="col-md-12 mb-2 col-lg-6">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Date of birth{" "}
                                </div>
                                <div className="profile-label-value font-weight-bold">
                                  {" "}
                                  {item.dateOfBirth}{" "}
                                </div>
                              </div>
                              <div className="col-md-12 mb-2 col-lg-6">
                                <div className="profile-label mt-2">
                                  {" "}
                                  ID Number{" "}
                                </div>

                                <div className="profile-label-value font-weight-bold text-uppercase">
                                  {/* {item.idType && item.idType !== "passport"
                                    ? item.idType.toUpperCase()
                                    : item.idType}{" "}
                                  :{" "}<br/> */}
                                  <span className="text-uppercase">
                                    {item.idNumber}
                                  </span>
                                </div>
                              </div>

                              <div className="col-md-12 mb-2 col-lg-6">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Gender{" "}
                                </div>
                                <div className="profile-label-value font-weight-bold text-capitalize">
                                  {" "}
                                  {item.gender === "other" ? "Prefer not to say" : item.gender}{" "}
                                </div>
                              </div>

                              <div className="col-md-12 mb-2 col-lg-6">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Nationality{" "}
                                </div>
                                <div className="profile-label-value font-weight-bold text-capitalize">
                                  {" "}
                                  {item.nationality}{" "}
                                </div>
                              </div>
                              <div className="col-md-12 mb-2 col-lg-6">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Company{" "}
                                </div>
                                <div className="profile-label-value font-weight-bold text-uppercase">
                                  {" "}
                                  {item.company?.name}
                                </div>
                              </div>

                              <div className="col-md-12 mb-2 col-lg-6">
                                <div className="profile-label mt-2">
                                  {" "}
                                  Profile Created Date{" "}
                                </div>
                                <div className="profile-label-value font-weight-bold">
                                  {" "}
                                  <p>{moment(item.created)?.format("DD-MM-YYYY")}{" "}</p>

                                </div>
                              </div>
                            </div>

                            <div className="slider-viewProfile">
                              {item.contract.access ? (
                                <Link
                                  className="btn btn-secondary viewButton"
                                  to={`${VW_ROUTES.PERSON_PROFILE_VIEW}?id=${item.id}&type=${item.category?.slug}`}
                                  target="_blank"
                                >
                                  View Profile
                                </Link>
                              ) : (
                                <span className="note">
                                  This profile is with another package, Contact
                                  manager to know more information
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary "
                onClick={() => onCloseCallback()}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(DuplicateProfiles);
