import React from "react";
import _ from "lodash";
import Select from "react-select";
import { AppContext } from "../../../context/AppContext";
import CustomCalendar from "../../CustomCalender";
import moment from "moment";

import { useRef } from "react";
import { checkModuleGeneralPermission } from "../../../helpers/helpers";
import useStorage from "../../../helpers/useStorage";
import {
  defaultSelectOption,
  defaultFromDateFilterValue,
  defaultToDateFilterValue,
} from "../../../helpers/utils";
import { reportDateFormat } from "../../../constants";

const PPEReportFilter = ({
  channelOptions,
  onChannelChange,
  setFilterChanged,
  setCurrentPageNumber,
  onFromDateChange,
  handleUpdateKey,
  onToDateChange,
  onExport,
  buttonVisibility,
}) => {
  const { setToStorage, getFromStorage, removeFromStorage } = useStorage();

  const [channel, setChannel] = React.useState(
    getFromStorage("ppe_event_report_filter_channel")
      ? JSON.parse(getFromStorage("ppe_event_report_filter_channel"))
      : undefined
  );

  const { userRoleDetails } = React.useContext(AppContext);

  const parseToSelectFormat = (options, keys) => {
    let res = _.map(options, (option) => ({
      label: option[keys.label],
      value: option[keys.value],
    }));
    res.unshift(defaultSelectOption());
    return res;
  };

  const [fromDate, setFromDate] = React.useState(
    getFromStorage("ppe_event_report_filter_from_date") ??
      defaultFromDateFilterValue(reportDateFormat)
  );
  const [toDate, setToDate] = React.useState(
    getFromStorage("ppe_event_report_filter_to_date") ??
      defaultToDateFilterValue(reportDateFormat)
  );
  const toDateRef = useRef(null);
  const fromDateRef = useRef(null);

  const applyFilter = () => {
    removeFromStorage();
    setToStorage(
      {
        ppe_event_report_filter_channel: JSON.stringify(channel) ?? "",
        ppe_event_report_filter_from_date: fromDate ?? "",
        ppe_event_report_filter_to_date: toDate ?? "",
      },
      false
    );
    onChannelChange(channel ?? undefined);
    onFromDateChange(fromDate ?? undefined);
    onToDateChange(toDate ?? undefined);
    setCurrentPageNumber(1);
    handleUpdateKey();
  };
  const onFilterChange = (option) => {
    const { name, value, label } = option;

    setFilterChanged(true);
    switch (name) {
      case "channel":
        setChannel(value && value?.length ? [...value] : undefined);

        break;
      case "fromDate":
        setFromDate(
          value ? moment(value).format("YYYY-MM-DD HH:mm") : undefined
        );

        break;
      case "toDate":
        setToDate(value ? moment(value).format("YYYY-MM-DD HH:mm") : undefined);

        break;
      default:
        break;
    }
  };

  const handleSingleFieldReset = (field) => {
    switch (field) {
      case "fromDate":
        setFromDate(undefined);
        fromDateRef.current.handleChange(undefined);
        return;
      case "toDate":
        setToDate(undefined);
        toDateRef.current.handleChange(undefined);
        return;
      default:
        return;
    }
  };

  const resetFilterOptions = () => {
    setFilterChanged(false);
    setChannel(null);
    onChannelChange(undefined);
    setFromDate(defaultFromDateFilterValue(reportDateFormat));
    setToDate(defaultToDateFilterValue(reportDateFormat));
    onFromDateChange(defaultFromDateFilterValue(reportDateFormat));
    fromDateRef.current.handleChange(defaultFromDateFilterValue());
    toDateRef.current.handleChange(defaultToDateFilterValue());
    onToDateChange(defaultToDateFilterValue(reportDateFormat));
    setCurrentPageNumber(1);
    removeFromStorage();
    handleUpdateKey();
  };

  return (
    <div className="reports-filter-section pb-3 position-relative">
      <div className="row flx-end align-items-start">
        <div className="col-12 col-sm-6 col-md-3 mb-2">
          <span>Camera</span>
          <Select
            className="cutomSelect"
            classNamePrefix="react-custom-select"
            value={channel}
            isMulti
            options={channelOptions}
            onChange={(opts) => {
              onFilterChange({
                value: opts && opts?.length ? [...opts] : null,
                name: "channel",
              });
            }}
            name="channel"
            placeholder="Select"
            hideSelectedOptions
            isClearable={true}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Event Time From</span>
          <CustomCalendar
            maxDate={toDate ? toDate : moment().toDate()}
            // showTimeSelect
            dateFormat="YYYY-MM-DD hh:mm A"
            timePicker
            ref={fromDateRef}
            selected={fromDate === "" ? "" : new Date(fromDate)}
            onChange={(date) =>
              onFilterChange({ name: "fromDate", value: date, label: "" })
            }
            isClearable={true}
            onClear={() => handleSingleFieldReset("fromDate")}
          />
        </div>

        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
          <span>Event Time To</span>
          <CustomCalendar
            maxDate={moment().toDate()}
            minDate={fromDate ? fromDate : ""}
            dateFormat="YYYY-MM-DD hh:mm A"
            ref={toDateRef}
            selected={toDate === "" ? "" : new Date(toDate)}
            onChange={(date) =>
              onFilterChange({ name: "toDate", value: date, label: "" })
            }
            timePicker
            isClearable={true}
            onClear={() => handleSingleFieldReset("toDate")}
          />
        </div>
      </div>
      <div className="row m-0 flx-end w-100 justify-content-end">
        <div className="col-12 col-sm-12 col-md-6 col-lg-3 mb-0  mt-2 p-0 ">
          <div className="btn-cover justify-content-end ">
            <button
              onClick={applyFilter}
              className="btn btn-secondary rounded-0 ml-1 "
            >
              Apply
            </button>
            <button
              onClick={resetFilterOptions}
              className="btn btn-secondary reset rounded-0 ml-1 "
            >
              <i className="iconsminds-repeat-2"></i>
            </button>
            {checkModuleGeneralPermission(
              userRoleDetails,
              "profile-report",
              "export_report"
            ) && (
              <button
                onClick={onExport}
                disabled={buttonVisibility}
                className="btn btn-secondary rounded-0 ml-1"
              >
                Export
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PPEReportFilter;
