import React from "react";
import _ from "lodash";
import { VW_ROUTES } from "../../../constants";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useHref } from "react-router";

const NavigationLink = ({
  item,
  itemKey,
  currentPage,
  onNavigationItemClickCallback,
}) => {
  const dashboards = [
    VW_ROUTES.DASHBOARD,
    VW_ROUTES.ANALYTICS,
    VW_ROUTES.VIDEO_ANALYTICS
  ]

  if(_.includes(dashboards, currentPage)) {
    currentPage = VW_ROUTES.DASHBOARD;
  }

  const applicants = [
    VW_ROUTES.APPLICANTS_HISTORY,
    VW_ROUTES.APPLICANTS_VISITIOR,
    VW_ROUTES.APPLICANTS_VISITIOR_ADD,
    VW_ROUTES.APPLICANTS_VISITIOR_EDIT,
    VW_ROUTES.APPLICANTS_APPLICATION_VIEW,
    VW_ROUTES.APPLICANTS_STAFF,
    VW_ROUTES.APPLICANTS_STAFF_MANAGE,
    VW_ROUTES.APPLICANTS_STAFF_VIEW,
    VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC,
    VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_MANAGE,
    VW_ROUTES.APPLICANTS_DELIVERY_REGULAR,
    VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_MANAGE,
    VW_ROUTES.APPLICANTS_PENDING,
    VW_ROUTES.APPLICANTS_DELIVERY_AD_HOC_EDIT,
    VW_ROUTES.APPLICANTS_DELIVERY_REGULAR_EDIT,
    VW_ROUTES.APPLICANTS_STAFF_EDIT,
    VW_ROUTES.APPLICANTS_EXPIRY_STATUS_LISTING,
    VW_ROUTES.PERSON_PROFILE_CIDB_EXPIRY_LISTING,
    VW_ROUTES.CONTRACTOR_APPLICANTS_LISTING,
    VW_ROUTES.INFO_DISPLAY_PAGE,
    VW_ROUTES.CONTRACTOR_APPLICANTS_LISTING,
  ];
  if (_.includes(applicants, currentPage)) {
    currentPage = VW_ROUTES.APPLICANTS;
  }

  const personRegistry = [
    VW_ROUTES.ALL_PROFILES,
    VW_ROUTES.EMPLOYEE_PROFILE,
    VW_ROUTES.VISITOR_PROFILES
  ]

  if(_.includes(personRegistry, currentPage)) {
    currentPage = VW_ROUTES.PERSON_REGISTRY;
  }

  const organisation = [
    VW_ROUTES.USERS,
    VW_ROUTES.COMPANIES,
    VW_ROUTES.CONTRACTS
  ]

  if(_.includes(organisation, currentPage)) {
    currentPage = VW_ROUTES.ORGANISATION;
  }

  const reports = [
    VW_ROUTES.PROFILE_REPORT,
    VW_ROUTES.ACS_EVENT_REPORT,
    VW_ROUTES.ANPR_EVENT_REPORT,
    VW_ROUTES.MAN_HOUR_REPORT,
    VW_ROUTES.PPE_EVENT_REPORT,
  ];

  if (_.includes(reports, currentPage)) {
    currentPage = VW_ROUTES.REPORTS;
  }
  const toLink = _.isEmpty(item?.children) 
    ? VW_ROUTES[item?.route] ?? "#"
    : VW_ROUTES[_.values(item?.children)[0]?.route] ?? "#";
  return (
    <li className={VW_ROUTES[item.route] === currentPage ? "active" : ""}>
      {!String(toLink).includes("[new-tab]") 
      ?
        <Link 
          onClick={(e) => onNavigationItemClickCallback(e, itemKey)} 
          to={toLink}>
          <i className={item.icon}></i>
          <span>{item.name}</span>
        </Link>
      : 
        <a 
          onClick={(e) => onNavigationItemClickCallback(e, itemKey)} 
          href={toLink.replace("[new-tab]", "")}
        >
          <i className={item.icon}></i>
          <span>{item.name}</span>
        </a>
      }
    </li>
  );
};

export default NavigationLink;
