import React from "react";
import { formatTextWithFallback } from "../../../helpers/helpers";

function ICExpiryExpansion({ data }) {
  return (
    <>
      <div className="table-expaned-data">
        <div className="item">
          Profile ID <div className="value text-capitalize">{formatTextWithFallback(data.profileId)}</div>
        </div>
        <div className="item">
          Contract <div className="value">{formatTextWithFallback(data.contract)}</div>
        </div>
        <div className="item">
          Nationality <div className="value">{formatTextWithFallback(data.nationality)}</div>
        </div>
        <div className="item">
          Designation <div className="value">{formatTextWithFallback(data.designation)}</div>
        </div>
      </div>
    </>
  );
}

export default ICExpiryExpansion;
