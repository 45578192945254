import React, { useState } from "react";
import { useEffect } from "react";

const IdInput = ({ onInput, expiry, value, disabled }) => {
  const [number, setNumber] = useState("");

  useEffect(() => {
    if (value) {
      let trimmedValue = formatValue(value);
      setNumber(trimmedValue);
    }
  }, [value]);
  const formatValue = (value, isDeletingBack) => {
    const sanitizedValue = value.replace(/\D/g, "");
    let formattedValue;
    let trimmedValue;
    if (expiry) {
      formattedValue =
        value.length > 1 && !isDeletingBack
          ? sanitizedValue.replace(/(\d{2})/g, "$1/")
          : sanitizedValue;
      trimmedValue = formattedValue.slice(0, 5);
    } else {
      formattedValue =
        value.length > 1 && !isDeletingBack
          ? sanitizedValue.replace(/(\d{4})/g, "$1-")
          : sanitizedValue;
      trimmedValue = formattedValue.slice(0, 19);
    }

    return trimmedValue;
  };
  const handleChange = (e) => {
    let { value } = e.target;
    const sanitizedValue = value.replace(/\D/g, "");
    let trimmedValue = formatValue(
      value,
      e.nativeEvent.inputType === "deleteContentBackward"
    );
    setNumber(trimmedValue);
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      const cursorPosition = e.target.selectionStart;
      const previousChar = value[cursorPosition - 1];
      if (
        (previousChar === "-" || previousChar === "/") &&
        cursorPosition > 0
      ) {
        value = value.slice(0, cursorPosition) + value.slice(cursorPosition);
        setNumber(value);
        e.target.selectionStart = cursorPosition - 1;
        e.target.selectionEnd = cursorPosition - 1;
      }
    }

    onInput(expiry ? trimmedValue : sanitizedValue.slice(0, 16));
  };

  return (
    <input
      type="text"
      value={number}
      onChange={handleChange}
      placeholder={expiry ? "MM/YY" : "Enter 16 digits CIDB Number"}
      className="form-control"
      disabled={disabled}
    />
  );
};

export default IdInput;
