import React, { useState } from "react";
import logo from "../../assets/images/branding-w.png";
import maintenaceImg from "../../assets/images/maintenance.png";
import Loader from "../../components/common/Loader/Loader";

const Maintenance = ({ isLoading, isMaintenance }) => {
  const [imageLoaded, setImageLoaded] = useState(null);

  const handleImageLoad = (loaded) => {
    setImageLoaded(!!loaded);
  };

  const showLoader =
    isLoading || imageLoaded === null || isMaintenance === null;

  return (
    <>
      {showLoader ? <Loader /> : null}
      <main className={`login-cover ${showLoader ? "d-none" : ""}`}>
        <div className="maintenance-section">
          <div className="card content-portion">
            <div className="content-inner">
              <div>
                <img
                  src={maintenaceImg}
                  className="card-img-top"
                  alt="..."
                  onLoad={() => handleImageLoad(true)}
                  onError={() => handleImageLoad(false)}
                />
                <div className="card-body">
                  <h5 className="card-title message-title">
                    We'll be back soon!
                  </h5>
                  <p className="card-text message">
                    Sorry for the inconvenience, The site is currently under
                    maintenance.
                  </p>
                </div>
              </div>
              <div className="logo mt-3 mb-3">
                <img src={logo} alt="v-watch" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Maintenance;
