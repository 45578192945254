import React from "react";
import { formatTextWithFallback } from "../../../helpers/helpers";

function LPRTransactionReportExpansion({ data }) {
  return (
    <>
      <div className="table-expaned-data">
        <div className="item">
          Plate Color <div className="value">{formatTextWithFallback(data.plateColor)}</div>
        </div>

        <div className="item">
          Vehicle Color <div className="value">{formatTextWithFallback(data.vehicleColor)}</div>
        </div>
 
      
        <div className="item">
          Vehicle Type <div className="value">{formatTextWithFallback(data.vehicleType)}</div>
        </div>
      </div>
    </>
  );
}

export default LPRTransactionReportExpansion;
