import React, { useCallback, useEffect, useRef } from "react";

const ContextMenuContentWrapper = ({ showContextMenu, children, handleContextMenuClose = () => {}}) => {
    const wrapperRef = useRef();
    const setMenuPosition = useCallback((e) => {
        if(!wrapperRef?.current) return;
        if(!wrapperRef.current?.parentElement) return;
        
        const {
            x: parentX, 
            y: parentY, 
            width: parentWidth, 
            height: parentHeight 
        } = wrapperRef?.current?.parentElement?.getBoundingClientRect() ?? { x: 0, y: 0};
       
        wrapperRef.current?.style?.setProperty('--position-x', parentX + "px");
        wrapperRef.current?.style?.setProperty('--position-y', parentY + "px");
        wrapperRef.current?.style?.setProperty('--parent-width', parentWidth + "px");
        wrapperRef.current?.style?.setProperty('--parent-height', parentHeight + "px");

    }, [wrapperRef]);

    useEffect(() => {
        document.addEventListener('scroll', setMenuPosition);
        window.addEventListener('resize', setMenuPosition);
        return () => {
            document.removeEventListener('scroll', setMenuPosition);
            window.removeEventListener('resize', setMenuPosition);
        }
    }, [])

    const handleTableScroll = (e) => {
        // e.stopPropagation();
        setMenuPosition();
    }

    useEffect(() => {
        setMenuPosition();

        if(!wrapperRef.current) return () => {};
        const tableBodyElement = wrapperRef?.current?.closest('.dataTableOuter > div');
        if(!tableBodyElement) return () => {};
        tableBodyElement.addEventListener('scroll', handleTableScroll);
        return () => {
            tableBodyElement.removeEventListener('scroll', handleTableScroll);
        }
    }, [wrapperRef]);

    useEffect(() => {
        if(!wrapperRef.current) return () => {};
        const tableBodyElement = wrapperRef?.current?.closest('.dataTableOuter > div');
        if(!tableBodyElement) return () => {};
        const menuIntersectionObserver = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio <= 0.5) handleContextMenuClose();
            else setMenuPosition();
        }, { threshold: [0.5]});
        menuIntersectionObserver.observe(wrapperRef.current?.parentElement);
        return () => {
            menuIntersectionObserver.disconnect();
        }
    }, [wrapperRef])

    if(!showContextMenu || !children) return null;
    return (
        <div ref={wrapperRef} className="context-menu-wrapper">
            {children}
        </div>
    );

}

export default ContextMenuContentWrapper;