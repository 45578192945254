const DISPLAY_TEXTS = {
  // Common Texts
  SOMETHING_WRONG: "Something went wrong",
  //Dashboard

  //User Role
  ROLE_ADDED: "User role added successfully.",
  ROLE_UPDATED: "User role updated successfully.",
  ROLE_DELETED: "User role deleted successfully",
  //Company
  COMPANY_ADDED: "Company added successfully.",
  COMPANY_UPDATED: "Company updated successfully.",
  COMPANY_DELETED: "Company deleted successfully.",
  COMPANY_SAME: "Please choose a different company.",
  //Contract
  CONTRACT_ADDED: "Contract added successfully.",
  CONTRACT_UPDATED: "Contract updated successfully.",
  CONTRACT_DELETED: "Contract deleted successfully.",
  CONTRACT_SAME: "Please choose a different company.",
  //Users
  USER_ADDED: "User added successfully.",
  USER_UPDATED: "User updated successfully.",
  USER_DELETED: "User deleted successfully.",
  USER_SELF_DELETE_WARNING:
    "You are about to delete your own profile. If you proceed, you will be signed out immediately. Please confirm your action.",
  USER_SELF_INACTIVATE_WARNING:
    "You are about to inactivate your own profile. If you proceed, you will be signed out immediately. Please confirm your action.",
  USER_ACTIVATED: "User activated successfully.",
  USER_INACTIVATED: "User inactivated successfully.",
  USER_ACTIVATED_ERROR: "Failed to activate user!",
  USER_INACTIVATED_ERROR: "Failed to inactivate user!",

  //Self Registartion Invites
  INVITE_ADDED: "Invite added successfully.",
  INVITE_ADDED_ERROR: "Unable to add invite!.",
  INVITE_SENT: "Invites sent successfully.",
  INVITE_SENT_ERROR: "Unable to sent invites.",
  INVITE_ACTIVATED: "Invite activated successfully.",
  INVITE_ACTIVATED_ERROR: "Unable to activate invite!.",
  INVITE_INACTIVATED: "Invite inactivated successfully.",
  INVITE_INACTIVATED_ERROR: "Unable to inactivate invite!.",
  INVITE_DELETED: "Invite deleted successfully.",
  INVITE_DELETED_ERROR: "Unable to delete invite!.",

  //Self Registration Invite Form
  INVITEFORM_EXPIRY_TOOLTIP:
    "Set an expiry date for this invitation to manage its validity",
  INVITEFORM_MULTIPLEUSE_TOOLTIP:
    "Enable to allow the invitation URL to be used multiple times",
  INVITEFORM_WARNING:
    "Warning: It's recommended to set an expiry date when you are enabling multiple use. Are you sure you want to proceed without setting an expiry date?",
  INVITEFORM_MULTIUSE_WARNING:
    "Proceeding with multiple use will permit multiple registrations. Are you sure you want to continue?",

  VISITOR_ADDED: "Visitor added successfully.",
  VISITOR_UPDATED: "Visitor updated successfully.",
  VISITOR_DELETED: "Visitor deleted successfully.",

  STAFF_ADDED: "Staff added successfully.",
  STAFF_UPDATED: "Staff updated successfully.",
  STAFF_DELETED: "Staff deleted successfully.",

  DELIVERY_REGULAR_ADDED: "Delivery Regular added successfully.",
  DELIVERY_REGULAR_UPDATED: "Delivery Regular  updated successfully.",
  DELIVERY_REGULAR_DELETED: "Delivery Regular  deleted successfully.",
  DELIVERY_REGULAR_ARCHIVED: "Delivery Regular  archived successfully.",

  DELIVERY_ADHOC_ADDED: "Delivery Ad-Hoc added successfully.",
  DELIVERY_ADHOC_UPDATED: "Delivery Ad-Hoc updated successfully.",
  DELIVERY_ADHOC_DELETED: "Delivery Ad-Hoc deleted successfully.",

  APPLICANT_APPROVED: "Applicant approved successfully.",
  APPLICANT_REJECTED: "Applicant rejected successfully.",
  PERSON_PROFILE_DELETED: "Person profile deleted successfully.",

  PERSON_PROFILE_BANNED: "Person profile banned successfully.",
  PERSON_PROFILE_BAN_WARNING:
    "Active and approved access passes will be cancelled.\nProfile status will be banned.",
  PERSON_PROFILE_UNBANNED: "Person profile unbanned successfully.",
  PERSON_PROFILE_UNBAN_WARNING:
    "Cancelled access passes will remain in same status. New access passes can be added.\nProfile status will be active. ",

  USER_TRAINING_ADDED: "User Training added successfully.",
  USER_TRAINING_UPDATED: "User Training updated successfully.",
  USER_TRAINING_DELETED: "User Training deleted successfully.",

  PASS_ADDED: "Access Pass added successfully.",
  PASS_UPDATED: "Access Pass updated successfully.",
  PASSWORD_RESET_MAIL_SUCCESS: "Password reset e-mail has been sent",

  APPLICANT_PROFILE_CONVERTED: "Applicant Profile converted successfully",

  PERSON_PROFILE_ARCHIVED: "Profile(s) has been archived successfully",
  PERSON_PROFILE_ARCHIVE_WARNING:
    "Active and approved access passes will be cancelled.\nProfile status will be archived.",
  PERSON_PROFILE_RESTORED: "Profile(s) has been restored successfully",
  PERSON_PROFILE_RESTORE_WARNING:
    "Cancelled access passes will remain in same status. New access passes can be added.\nProfile status will be active. ",

  TRAINING_STATUS_UPDATED: "Applicant Training status updated successfully",

  PASSWORD_UPDATED: "Password changed successfully",

  FILE_ALARM_MESSAGE: "Fire Alarm Raised",

  APPROVE_GATE_PASS: "Access Pass Approved successfully",
  REJECT_GATE_PASS: "Access Pass Rejected successfully",
  CANCEL_GATE_PASS: "Access Pass Cancelled successfully",

  RESEND_INVITATION: "Invitation sent successfully",
  PROFILE_CREATED: "Profile created successfully",
  PROFILE_UPDATED: "Profile updated successfully",
  PROFILE_UNCHANGED: "No fields have been edited!",
  SELF_REGISTRATION: "Registartion completed successfully!",

  PROFILE_ID_CREATED: "ID created successfully",
  PROFILE_ID_UPDATED: "ID updated successfully",
  PROFILE_ID_DELETED: "ID deleted successfully",

  PAGE_HEADERS: {
    visitor: "Visitor",
    employee: "Employee",
  },

  PUBLIC_INVITE_INVALID_LINK: `This link has been expired! \nPlease contact the authorized personnel.`,
  PPE: {
    VIDEO_LOADING: "Video updation still in progress! Please try again later.",
    VIDEO_FAILED: "Failed to fetch event video from VA server",
    VIDEO_FAILED_WO_REFETCH: "Unable to fetch event video from VA server",
    VIDEO_CORRUPTED: "Video file corrupted!",
    VIDEO_CORRUPTED_WO_REFETCH:
      "File corrupted! Unable to fetch from VA server.",
  },
};

export default DISPLAY_TEXTS;
