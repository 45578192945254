import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import SelectApplicants from "../../components/common/SelectApplicants/SelectApplicants";
import { AppContext } from "../../context/AppContext";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import CustomCalendar from "../../components/CustomCalender";
import useCompanyOptions from "../../components/common/CommonDeliverables/useCompanyOptions";
import useContractOptions from "../../components/common/CommonDeliverables/useContractOptions";
import ReactSelect from "react-select";
import LazyLoadedSelect from "../../components/LazyLoadedSelect/LazyLoadedSelect";
import { defaultSelectOption } from "../../helpers/utils";

const ApplicantListFilter = ({
  getPageType,
  personRegistryType,
  filterProfileCategory,
  filterNotVisiterFor,
  filterProfileStatus,
  filterContract,
  filterCompany,
  filterIdType,
  filterWithRegisterMode,
  filterWithAvatar,
  filterWorkPermitExpired,
  handleFilterProfileCategory,
  handleFilterNotVistedFor,
  handleFilterProfileStatus,
  handleFilterContract,
  handleFilterCompany,
  handleFilterIdType,
  handleFilterWorkPermitExpired,
  handleFilterWithRegisterMode,
  handleFilterWithAvatar,
  getNotVisitedOptions,
  getProfileStatusOptions,
  getIdTypeOptions,
  getWorkPermitExpiredOptions,
  getRegisterModeOptions,
  getAvatarOptions,
}) => {
  const filterCompanyOptions = (currentOption) => {
    return !filterCompany?.find(
      (selectedCompany) => selectedCompany?.label === currentOption?.label
    );
  };

  const {
    companies,
    handleCompanySearch,
    loadNextCompanyOptionsPage,
    isCompanyLoading,
  } = useCompanyOptions();
  const {
    contracts,
    handleContractSearch,
    loadNextContractOptionsPage,
    isContractLoading,
  } = useContractOptions();

  return (
    <div className="advance-filter">
      {!getPageType() && (
        <div>
          <span>Category</span>
          <div className="mb-2">
            <SelectApplicants
              searchByApplicantType={(e) => handleFilterProfileCategory(e)}
              applicantType={filterProfileCategory}
            />
          </div>
        </div>
      )}

      <div>
        <span>Not visited for</span>
        <div className="mb-2">
          <Select
            className="cutomSelect"
            classNamePrefix="react-select"
            value={filterNotVisiterFor}
            onChange={handleFilterNotVistedFor}
            options={getNotVisitedOptions()}
          />
        </div>
      </div>

      <div>
        <span>Profile Status</span>
        <div className="mb-2">
          <Select
            isMulti
            className="cutomSelect"
            classNamePrefix="react-custom-select"
            value={filterProfileStatus}
            onChange={handleFilterProfileStatus}
            placeholder="Select"
            options={getProfileStatusOptions()}
          />
        </div>
      </div>
      <div>
        <span>Contracts</span>
        <div className="mb-2">
          <LazyLoadedSelect
            className="cutomSelect"
            classNamePrefix="react-custom-select"
            loadNextOptions={loadNextContractOptionsPage}
            name="contractors"
            onChange={handleFilterContract}
            onInputChange={(value) => handleContractSearch(value)}
            options={contracts}
            placeholder="Select"
            value={filterContract}
            isLoading={isContractLoading}
            defaultValue={defaultSelectOption()}
            hideSelectedOptions
            isMulti
          />
        </div>
      </div>
      <div>
        <span>Company</span>
        <div className="mb-2">
          <LazyLoadedSelect
            className="cutomSelect"
            classNamePrefix="react-custom-select"
            loadNextOptions={loadNextCompanyOptionsPage}
            name="company"
            onChange={handleFilterCompany}
            onInputChange={(value) => handleCompanySearch(value)}
            options={companies}
            placeholder="Select"
            value={filterCompany}
            isLoading={isCompanyLoading}
            filterOption={filterCompanyOptions}
            hideSelectedOptions
            isMulti
          />
        </div>
      </div>
      <div>
        <span>ID Type</span>
        <div className="mb-2">
          <Select
            className="cutomSelect"
            classNamePrefix="react-select"
            value={filterIdType}
            onChange={handleFilterIdType}
            options={getIdTypeOptions()}
            name="idType"
            placeholder="Select"
          />
        </div>
      </div>
      <div>
        <span>Work Permit Expired</span>
        <div className="mb-2">
          <Select
            className="cutomSelect"
            classNamePrefix="react-select"
            value={filterWorkPermitExpired}
            onChange={handleFilterWorkPermitExpired}
            options={getWorkPermitExpiredOptions()}
            name="workPermitExpired"
            placeholder="Select"
          />
        </div>
      </div>
      <div>
        <span>Register Mode</span>
        <div className=" mb-2">
          <Select
            className="cutomSelect"
            classNamePrefix="react-select"
            value={filterWithRegisterMode}
            onChange={handleFilterWithRegisterMode}
            options={getRegisterModeOptions()}
            placeholder="Select"
          />
        </div>
      </div>
      {personRegistryType !== "visitor-profile" && (
        <div>
          <span>Avatar</span>
          <div className=" mb-2">
            <Select
              className="cutomSelect"
              classNamePrefix="react-select"
              value={filterWithAvatar}
              onChange={handleFilterWithAvatar}
              options={getAvatarOptions()}
              placeholder="Select"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicantListFilter;
