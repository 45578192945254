/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { getSession, userPermissionParser } from "../helpers/utils";
import TopNavigation from "../containers/TopNavigation/TopNavigation";
import NavigationSidebar from "../containers/NavigationSidebar/NavigationSidebar";
import Footer from "../components/Footer/Footer";
import { getNotifications } from "../services/appServices";
import {
  API_BASE_URL,
  ROUTE_PERMISSION,
  VW_ROUTES,
  apiRoutes,
} from "../constants";
import { useClient, useQuery } from "jsonapi-react";
import Loader from "../components/common/Loader/Loader";
import ModalComponent from "../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../helpers/displayTexts";
import Axios from "axios";
import { checkPageViewPermission } from "../helpers/helpers";
import useManageLastState from "../hooks/useManageLastState/useManageLastState";
import useStorage from "../helpers/useStorage";
import useCookie from "../helpers/useCookie";
import Maintenance from "../containers/Maintenance/Maintenance";
import useCheckMaintenance from "../hooks/useCheckMaintenance/useCheckMaintenance";
const DataFetchingComponent = ({ children, pageCode = "" }) => {
  const history = useHistory();
  const { pathname } = history.location;
  const { removeFromStorage } = useStorage();
  const { setCookie, getCookie } = useCookie();
  const [showLicenseModal, setShowLicenseModal] = useState(false);
  useEffect(() => {
    const filterNeededPage = [
      "/reports",
      "/all-profile",
      "/employee-profile",
      "/visitor-profile",
      "/person-profile",
    ].some((word) => pathname.includes(word));
    if (!filterNeededPage) {
      removeFromStorage();
    }
  }, [pathname]);

  useEffect(() => {
    const licenseModal = getCookie("license");
    licenseModal ? setShowLicenseModal(true) : setShowLicenseModal(false);
  });

  const handleLicenseClick = () => {
    setCookie("license", "accepted", 1);
    setShowLicenseModal(true);
  };

  const { lastRouteStates } = useManageLastState();
  const { isMaintenance } = useCheckMaintenance();
  const [currentUserRole, setCurrentUserRole] = React.useState(undefined);
  const [pageViewPermission, setPageViewPermission] = React.useState(undefined);
  const [isBanLoading, setIsBanLoading] = React.useState(false);
  const token = localStorage.getItem("access_token");
  const client = useClient();

  const {
    currentUser,
    notifications,
    // companyConfig,
    mainSidebarHidden,
    subSidebarHidden,
    updateCurrentUser,

    hideMainSidebar,
    hideSubSidebar,
    showLoader,
    //userPermissions,
    updateNotifications,
    updateCompanyConfig,
    //updateFileAlarmCounter,
    //updateUserPermissions,
    updatePagePermissionBasedOnRole,
    updateUserRoleDetails,
    defaultMenuItem,
    theme,
    showBanModal,
    showBanModalPopup,
    manageLoader,
    activePageCode,
    setActivePageCode,
    overideActivePageCode,
  } = React.useContext(AppContext);
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const { data: rolePermissions } = useQuery(
    null &&
      apiRoutes.systemMenu.replace(
        "{role}",
        currentUserRole ? currentUserRole : ""
      ),
    { client }
  );

  const {
    data: userRoleDetails,
    isFetching: fetchingUserRole,
    isLoading: loadingUserRole,
    error: errorUserRole,
  } = useQuery(
    apiRoutes.userRoleDetails.replace(
      "{role}",
      currentUserRole ? currentUserRole : ""
    ),
    { client }
  );

  const {
    data: systemInfo,
    error: errorInfo,
    isLoading: loading,
  } = useQuery(apiRoutes.systemInfo, {
    client,
  });

  const { data: systemLimit, error: errorConfig } = useQuery(
    null && apiRoutes.systemConfig,
    { client }
  );

  if (
    (errorInfo !== undefined &&
      errorInfo.detail === "Signature has expired.") ||
    errorInfo?.status === "401" ||
    (errorConfig !== undefined &&
      errorConfig.detail === "Signature has expired.") ||
    errorConfig?.status === "401" ||
    (errorUserRole !== undefined &&
      errorUserRole.detail === "Signature has expired.") ||
    errorUserRole?.status === "401"
  ) {
    localStorage.clear();
    history.push(VW_ROUTES.LOGIN);
  }

  useEffect(() => {
    if (pathname && userRoleDetails)
      setActivePageCode({ menuItems: userRoleDetails?.menuItems, pathname });
    if (pathname !== VW_ROUTES.DASHBOARD) return;
    if (!String(defaultMenuItem?.route ?? "").length) return;
    if (!_.has(VW_ROUTES, defaultMenuItem?.route)) return;
    overideActivePageCode({
      code: defaultMenuItem?.code,
      path: VW_ROUTES[defaultMenuItem?.route],
    });
    history.replace(`${VW_ROUTES[defaultMenuItem?.route]}`);
  }, [pathname, defaultMenuItem]);

  useEffect(() => {
    if (systemLimit) {
      calculateAddUser();
    } else {
      localStorage.removeItem("allowNewUser");
      localStorage.removeItem("allowNewApplicant");
    }
  }, [systemLimit, systemInfo]);

  const getPageViewPermission = () => {
    return checkPageViewPermission(
      userRoleDetails?.menuItems,
      activePageCode ?? pageCode
    );
  };

  useEffect(() => {
    if (!pageCode) return;
    if (pageCode === ROUTE_PERMISSION.ALWAYS) {
      setPageViewPermission(true);
      return;
    }
    setPageViewPermission(getPageViewPermission());
  }, [pageCode, userRoleDetails, activePageCode, setPageViewPermission]);

  const calculateAddUser = () => {
    if (systemInfo && systemLimit) {
      let userLimit = systemLimit.userLimit;

      let applicantLimit = systemLimit.applicantLimit;

      let userCount = systemInfo.userCount;
      let applicantCount = systemInfo.applicantCount;

      if (applicantCount < applicantLimit) {
        localStorage.setItem("allowNewApplicant", true);
      } else {
        localStorage.setItem("allowNewApplicant", false);
      }
      if (userCount < userLimit) {
        localStorage.setItem("allowNewUser", true);
      } else {
        localStorage.setItem("allowNewUser", false);
      }
    } else {
      localStorage.removeItem("allowNewUser");
      localStorage.removeItem("allowNewApplicant");
    }
  };

  React.useEffect(() => {
    hideSubSidebar(true);
    const user = getSession();
    if (!user) {
      history.push(VW_ROUTES.LOGIN);
    } else {
      if (localStorage.getItem("userUpdated")) {
        updateCurrentUser({
          ...user,
          ...(localStorage.getItem("userAvatar")
            ? { avatar: localStorage.getItem("userAvatar") }
            : {}),
        });
      } else {
        updateCurrentUser(user);
      }

      const resp = getNotifications();
      updateNotifications(resp);
    }
  }, []);

  React.useEffect(() => {
    if (rolePermissions && rolePermissions.config) {
      const permissions = userPermissionParser(rolePermissions.config);
      if (pathname !== "") {
        updatePagePermissionBasedOnRole({ permissions, pathname });
      }
    }
  }, [rolePermissions, pathname]);

  React.useEffect(() => {
    if (userRoleDetails) {
      updateUserRoleDetails(userRoleDetails);
    }
  }, [userRoleDetails]);

  const { showModalBackDrop } = React.useContext(AppContext);

  React.useEffect(() => {
    hideSubSidebar(true);
    if (window.innerWidth <= 1199) {
      hideMainSidebar(false);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [children]);

  React.useEffect(() => {
    setCurrentUserRole(currentUser.role);
  }, [currentUser]);
  React.useEffect(() => {
    updateCompanyConfig(systemLimit?.companyConfig);
  }, [systemLimit]);

  const checkExpiryNotificationVisibility = () => {
    if (
      systemInfo &&
      systemInfo?.license &&
      systemInfo?.license?.showNotification &&
      systemInfo?.license?.applicableRolesSlug &&
      currentUser
    ) {
      if (
        systemInfo?.license.applicableRolesSlug.indexOf(currentUser.role_slug) >
        -1
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const { addToast } = useToasts();
  const [banText, setBanText] = React.useState("");
  const [banButtonPressed, setBanButtonPressed] = React.useState(false);
  const banUser = async () => {
    setBanButtonPressed(true);
    const method = showBanModal.data.data.status == "banned" ? "DELETE" : "PUT";
    const options = {
      url: apiRoutes.ban.replace("{profileId}", showBanModal.data.data.id),
      method: method,
      headers: {
        "content-type": "application/json",
        Authorization: `JWT ${token}`,
      },
      data: {
        data: {
          type: "person",
          id: showBanModal.data.data.id,
          attributes: {
            note: banText,
          },
        },
      },
    };

    if (String(banText ?? "").trim().length) {
      setIsBanLoading(true);
      Axios(options)
        .then((response) => {
          addToast(
            showBanModal.data.data.status == "banned"
              ? DISPLAY_TEXTS.PERSON_PROFILE_UNBANNED
              : DISPLAY_TEXTS.PERSON_PROFILE_BANNED,
            { appearance: "success" }
          );
          setIsBanLoading(false);
          showBanModalPopup({ show: false, data: null, reload: true });
          setBanButtonPressed(false);
          setBanText("");
        })
        .catch((error) => {
          setIsBanLoading(false);
          addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
          showBanModalPopup({ show: false, data: null, reload: false });
          setBanButtonPressed(false);
          setBanText("");
        });
    }
  };
  const doCloseBanModal = () => {
    showBanModalPopup({ show: false, data: null, reload: false });
    setBanButtonPressed(false);
    setBanText("");
  };

  if (!(isMaintenance === false) || errorInfo !== undefined) {
    return <Maintenance isLoading={loading} isMaintenance={isMaintenance} />;
  } else {
    return (
      <>
        <div
          id="app-container"
          className={`ltr rounded menu-sub-hidden ${theme} ${
            mainSidebarHidden === true ? "main-hidden" : ""
          } ${subSidebarHidden ? "sub-hidden" : "sub-show-temporary"}`}
        >
          {checkExpiryNotificationVisibility() && (
            <div className="d-md-none">
              <ModalComponent
                show={!showLicenseModal}
                header={<b>Subscription Renewal</b>}
                content={
                  <div className="d-flex align-items-center license-warning">
                    <span className="iconsminds-danger mb-3 mr-2"></span>
                    <p>{systemInfo?.license?.notification}</p>
                  </div>
                }
                showSubmit={true}
                onSubmitCallback={() => handleLicenseClick()}
                hideCancel={true}
                hideClose={true}
                submitButtonText={"OK"}
              />
            </div>
          )}
          {checkExpiryNotificationVisibility() && (
            <div className="expiry-notification d-none d-md-block">
              {systemInfo?.license?.notification}
            </div>
          )}

          {currentUser && currentUser.role && (
            <>
              <TopNavigation
                user={currentUser}
                notifications={notifications}
                mainSidebarHidden={mainSidebarHidden}
                subSidebarHidden={subSidebarHidden}
                hideMainSidebar={hideMainSidebar}
                hideSubSidebar={hideSubSidebar}
                logoImage={systemInfo?.site}
              />
              <NavigationSidebar
                user={currentUser}
                subSidebarHidden={subSidebarHidden}
                hideSubSidebar={hideSubSidebar}
              />
              <main>
                <div className="container-fluid">
                  <>
                    {pageViewPermission ? (
                      <>{children}</>
                    ) : loadingUserRole ||
                      fetchingUserRole ||
                      pageViewPermission === undefined ? (
                      <Loader />
                    ) : (
                      <div className="d-flex align-items-center justify-content-center flex-column h-100 w-100">
                        <div>No permission granted to access this page!</div>
                      </div>
                    )}
                  </>
                </div>
              </main>
              <Footer systemInfo={systemInfo} />
            </>
          )}

          <ModalComponent
            show={showBanModal.show}
            header={
              <b>
                {showBanModal?.data?.data?.status == "banned" ? "Unban" : "Ban"}{" "}
                Profile
              </b>
            }
            content={
              <div
                className={
                  String(banText ?? "").trim().length === 0 && banButtonPressed
                    ? "form-group hasValidationError"
                    : "form-group"
                }
              >
                <div className="warning-text">
                  Note:{"\n"}
                  {showBanModal?.data?.data?.status == "banned"
                    ? DISPLAY_TEXTS.PERSON_PROFILE_UNBAN_WARNING
                    : DISPLAY_TEXTS.PERSON_PROFILE_BAN_WARNING}
                </div>
                <label htmlFor="banNote">
                  Reason<i>*</i>
                </label>
                <textarea
                  id="banNote"
                  type="text"
                  name="note"
                  className="form-control"
                  value={banText}
                  disabled={isBanLoading}
                  onChange={(e) => {
                    !isBanLoading && setBanText(e.target.value);
                  }}
                />
              </div>
            }
            onSubmitCallback={banUser}
            onCloseCallback={doCloseBanModal}
            isLoaderActive={isBanLoading}
            submitButtonText={
              showBanModal?.data?.data?.status == "banned" ? "Unban" : "Ban"
            }
            customClass="delete"
          />
        </div>
        <div
          className={`${showModalBackDrop ? "modal-backdrop fade  show" : ""}`}
        ></div>
        <div
          className={`main-loader ${
            showLoader ? "modal-backdrop fade  show" : ""
          }`}
        >
          {showLoader && <Loader />}
        </div>
      </>
    );
  }
};

export default DataFetchingComponent;
