import React from "react";
import ReactDOM from "react-dom";
import Routes from "./components/Routes/Routes";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";

// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//       .register("./firebase-messaging-sw.js")
//       .then(function(registration) {
//       })
//       .catch(function(err) {
//         console.log("Service worker registration failed, error:", err);
//       });
//   }

ReactDOM.render(<Routes />, document.getElementById("root"));
