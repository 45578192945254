import React from "react";
import { formatTextWithFallback } from "../../../helpers/helpers";

function AcsTransactionReportExpansion({ data }) {
  return (
    <>
      <div className="table-expaned-data">
        <div className="item">
          Profile ID{" "}
          <div className="value">{formatTextWithFallback(data?.profile_id)}</div>
        </div>

        <div className="item">
          Card No. <div className="value">{formatTextWithFallback(data?.pass_number)}</div>
        </div>
      </div>
    </>
  );
}

export default AcsTransactionReportExpansion;
