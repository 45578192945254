// import axios from "axios";
import React, { useState } from "react";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import Select from "react-select";
import "./timeline.scss";
import _ from "lodash";
import { FaCheck, FaTimes } from "react-icons/fa";
import IDLoader from "../Loader/IDLoader";
import { apiRoutes } from "../../../constants";
import useContracts from "../CommonDeliverables/useContracts";
import { getContractorOptions } from "../../../helpers/utils";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../../helpers/displayTexts";
import { getLocalDateTime } from "../../../helpers/helpers";
import useCompanyOptions from "../CommonDeliverables/useCompanyOptions";
import useContractOptions from "../CommonDeliverables/useContractOptions";
import LazyLoadedSelect from "../../LazyLoadedSelect/LazyLoadedSelect";

const ViewContractorHistory = ({
  applicant,
  show,
  customClass,
  historyType,
  canEdit,
  onCloseCallback = () => {},
}) => {
  const { addToast } = useToasts();
  const [contract, setContract] = React.useState(null);
  const [edit, setEdit] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const {
    companies,
    handleCompanySearch,
    loadNextCompanyOptionsPage,
    isCompanyLoading,
  } = useCompanyOptions();
  const {
    contracts,
    handleContractSearch,
    loadNextContractOptionsPage,
    isContractLoading,
  } = useContractOptions();
  const { data: contractsHistory, isLoading } = useQuery(
    [
      (historyType === "company"
        ? apiRoutes.companyHistroy
        : apiRoutes.contractHistroy
      ).replace("{profileId}", applicant.id),
    ],
    { client }
  );

  React.useEffect(() => {
    if (contractsHistory) {
      const activeItem = contractsHistory.filter((item) => item.isActive) ?? [];
      if (activeItem?.length > 0) setCurrentItem(activeItem[0]?.id);
    }
  }, [contractsHistory]);

  const [createUpdate] = useMutation(
    (historyType === "company"
      ? apiRoutes.companyHistroy
      : apiRoutes.contractHistroy
    ).replace("{profileId}", applicant.id),
    {
      method: "POST",
      client,
    }
  );

  const addContract = async () => {
    setLoading(true);
    const { data, error } = await createUpdate(
      historyType === "company"
        ? {
            person: applicant.id,
            company: contract.value,
          }
        : {
            person: applicant.id,
            contract: contract.value,
          }
    );

    if (error === undefined) {
      addToast(
        historyType === "company"
          ? DISPLAY_TEXTS.COMPANY_UPDATED
          : DISPLAY_TEXTS.CONTRACT_UPDATED,
        {
          appearance: "success",
        }
      );
      setLoading(false);
      handleCloseEdit();
    } else {
      setLoading(false);
      addToast(error.detail, { appearance: "error" });
      handleCloseEdit();
    }
  };

  const onFilterChange = (opt) => {
    setContract(opt);
  };
  const handleManageEdit = () => {
    setEdit(true);
  };
  const handleCloseEdit = () => {
    setEdit(false);
    setContract(null);
  };
  return (
    <>
      <div
        className={`modal small fade ${show ? "show d-block" : ""}`}
        id="ModalComponent"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className={
            customClass
              ? customClass + " modal-dialog modal-dialog-centered"
              : "modal-dialog modal-dialog-centered"
          }
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title">
                {historyType === "company" ? (
                  <b>Company History</b>
                ) : (
                  <b>Contract History</b>
                )}
              </h5>
              <button
                type="button"
                className="close"
                onClick={() => onCloseCallback()}
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body  timeline-body">
              {isLoading && <IDLoader />}
              <div>
                <div className="col-12 mb-2  left-0"></div>
                <div>
                  <div className="col-12 mb-2 ">
                    <div className="timeline">
                      <div className="timeline-box">
                        <div className="tlb-container">
                          <ul
                            className={`${edit ? "tlb-edit" : "tlb"} ${
                              canEdit ? "tlb-can-edit" : ""
                            }`}
                          >
                            {(edit || contractsHistory?.length === 0) &&
                            canEdit ? (
                              <li className="tlb-item">
                                <div className="d-flex align-items-center justify-content-center timeline-item-cont">
                                  <div
                                    className="w-100 select-holder"
                                    style={{ minWidth: 0 }}
                                  >
                                    {historyType === "company" ? (
                                      <LazyLoadedSelect
                                        className="cutomSelect history-select"
                                        classNamePrefix="react-select"
                                        loadNextOptions={
                                          loadNextCompanyOptionsPage
                                        }
                                        name="company"
                                        onChange={(opt) => onFilterChange(opt)}
                                        onInputChange={(value) =>
                                          handleCompanySearch(value)
                                        }
                                        options={companies}
                                        placeholder="Select"
                                        value={contract}
                                        isLoading={isCompanyLoading}
                                        isClearable={false}
                                        menuShouldScrollIntoView={false}
                                      />
                                    ) : (
                                      <LazyLoadedSelect
                                        className="cutomSelect history-select"
                                        classNamePrefix="react-select"
                                        loadNextOptions={
                                          loadNextContractOptionsPage
                                        }
                                        name="contract"
                                        onChange={(opt) => onFilterChange(opt)}
                                        onInputChange={(value) =>
                                          handleContractSearch(value)
                                        }
                                        options={contracts}
                                        placeholder="Select"
                                        value={contract}
                                        isLoading={isContractLoading}
                                        isClearable={false}
                                        menuShouldScrollIntoView={false}
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-secondary ml-1 position-relative"
                                      disabled={
                                        !contract?.value ||
                                        contract?.value === ""
                                      }
                                      onClick={addContract}
                                    >
                                      {loading && (
                                        <span className="button-loader-container">
                                          <div
                                            className="spinner-border spinner-border-sm ml-1 text-white"
                                            role="status"
                                          ></div>
                                        </span>
                                      )}

                                      <FaCheck />
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-primary ml-1"
                                      onClick={() => handleCloseEdit()}
                                    >
                                      <FaTimes />
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ) : !canEdit && contractsHistory?.length === 0 ? (
                              <>
                                {historyType === "company" ? (
                                  <b className="row">
                                    Adding company is not allowed
                                  </b>
                                ) : (
                                  <b className="row">
                                    Adding Contract is not allowed
                                  </b>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            {contractsHistory?.map((item, ind) => (
                              <li
                                key={item.id}
                                className="tlb-item"
                                onClick={() =>
                                  item.isActive && canEdit
                                    ? handleManageEdit()
                                    : null
                                }
                              >
                                <div className="timeline-item-cont">
                                  <div className="timestamp text-uppercase">
                                    {historyType === "company"
                                      ? item.companyName
                                      : item.contractName}
                                  </div>
                                  <div className="item-title">
                                    {getLocalDateTime(item?.modified)}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary "
                disabled={loading}
                onClick={() => onCloseCallback()}
              >
                {"Close"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewContractorHistory;
