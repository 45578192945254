import React, { useContext, useEffect, useState } from "react";
import "./ppeEventViewModal.scss";
import { checkModuleGeneralPermission, formatTextWithFallback } from "../../helpers/helpers";
import moment from "moment";
import Loader from "../common/Loader/Loader";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import _ from "lodash";
import { PPE_SERVICE_BASE_URL, apiRoutes } from "../../constants";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { AppContext } from "../../context/AppContext";

const PPEEventViewModal = ({ data, onClose }) => {
  const { addToast } = useToasts();
  const { userRoleDetails } = useContext(AppContext);

  const [videoLoaded, setVideoLoaded] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleModalClose = () => {
    onClose();
  }

  const handleVideoRefetch = () => {
    const token = localStorage.getItem("access_token");
    const urlParams = new URLSearchParams({ id: data?.id });
    const url = new URL(`${PPE_SERVICE_BASE_URL}${apiRoutes.ppeVideoRefetch}`);
    url.search = urlParams.toString();
    axios({ 
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: url.toString(),
    }).then((res) => {
      addToast(res?.data?.message ?? "Request successfull!", { appearance: "success" });
    }).catch((err) => {
      addToast(err?.response?.data?.message ?? "Request Failed!", { appearance: "error" }); 
    }).finally(() => {
      onClose(true);
    });

  }

  useEffect(() => {
    if(!data) return;
    if(!data?.isVideoUploaded)
      setErrorMessage(DISPLAY_TEXTS.PPE.VIDEO_LOADING);
    else
      setErrorMessage('');
  }, [data]);

  return(
    <div
      className="ppe-video-modal modal fade show d-block"
      id="ModalComponent"
      tabIndex="-1"
      aria-labelledby="ModalComponentLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="content-wrapper">
            <div className="video-content-wrapper">
              <div className="video-wrapper position-relative">
              {(errorMessage !== null && !String(errorMessage).length) && videoLoaded === null && <Loader />} 
              {(errorMessage !== null && !!String(errorMessage).length) && videoLoaded === false && (
                <div className={`video-error-container ${_.includes(["-1", "1"], String(data?.isVideoUploaded)) ? 'error-font' : 'warn-font'}`}>
                  <i className="error-icon">&#9888;</i>
                  <p className="video-error-message">{errorMessage}</p>
                  {checkModuleGeneralPermission(userRoleDetails, "ppe-event-report", "do_videorefetch", data) &&
                  ((String(data?.isVideoUploaded) === "1" && data?.videoUrl) ||  (_.includes(["-1", "1"], String(data?.isVideoUploaded))))
                  && (data?.videoRefetchLeft ?? 1) > 0 && (
                    <button className="btn btn-secondary rounded-0 mt-2" onClick={handleVideoRefetch}>
                      Request Refetch
                    </button>
                  )}
                </div>
              )}
              <video 
                controls
                className={`${videoLoaded ? "" : "d-none"}`}
                onCanPlay={() => setVideoLoaded(true)}
                onError={() => {
                  setVideoLoaded(false);
                  if(String(data?.isVideoUploaded) === "1" && data?.videoUrl)
                    setErrorMessage((data?.videoRefetchLeft ?? 1) > 0
                      ? DISPLAY_TEXTS.PPE.VIDEO_CORRUPTED 
                      : DISPLAY_TEXTS.PPE.VIDEO_CORRUPTED_WO_REFETCH
                    );
                  else if(_.includes(["-1", "1"], String(data?.isVideoUploaded)))
                    setErrorMessage((data?.videoRefetchLeft ?? 1) > 0 
                      ? DISPLAY_TEXTS.PPE.VIDEO_FAILED
                      : DISPLAY_TEXTS.PPE.VIDEO_FAILED_WO_REFETCH
                    );
                  else
                    setErrorMessage(DISPLAY_TEXTS.PPE.VIDEO_LOADING);
                }}
              >
                <source src={data?.videoUrl} />
              </video>
              </div>
            </div>
            <div className="details-content-wrapper">
            <div className="pr-md-3">
              <h4 class="modal-heading">
                Event Information
              </h4>
              <div
                className="details"
                style={{
                  fontSize: "14px",
                  paddingTop: "5px",
                  paddingBottom: "15px",
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    display: "block",
                    fontWeight: "normal",
                  }}
                >
                  Event Time
                </span>
                {moment(data?.timeStamp).format("DD-MM-YYYY hh:mm:ss A")}
              </div>
              <div
                className="details"
                style={{
                  fontSize: "14px",
                  paddingTop: "5px",
                  paddingBottom: "15px",
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    display: "block",
                    fontWeight: "normal",
                  }}
                >
                  Camera Name
                </span>
                {formatTextWithFallback(data?.channelName)}
              </div>
              <div
                className="details"
                style={{
                  fontSize: "14px",
                  paddingTop: "5px",
                  paddingBottom: "15px",
                  fontWeight: "bold",
                }}
              >
                <span
                  style={{
                    fontSize: "12px",
                    display: "block",
                    fontWeight: "normal",
                  }}
                >
                  Description
                </span>
                {formatTextWithFallback(data?.eventComment)}
              </div>
            </div>
            </div>
          </div>
          <button
            type="button"
            className="close-button"
            onClick={() => handleModalClose()}
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PPEEventViewModal;
