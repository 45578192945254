import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { VW_ROUTES } from "../../../constants";
import DISPLAY_TEXTS from "../../../helpers/displayTexts";
import { checkModuleAddPermission, getCategoryType } from "../../../helpers/helpers";
import { AppContext } from "../../../context/AppContext";

const FinalStepperComponent = ({ isEdit, profileData, addAgain, personRegistryType, userRoleDetails }) => {
  const history = useHistory();
  const { getCategoryFromID, getCategoryFromSlug, appOptions } = React.useContext(AppContext);
  const [category, setCategory] = useState();
  const { search } = history.location;
  let params = queryString.parse(search);

  const goToProfile = useCallback(() => {
    history.replace({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${profileData?.id}&type=${category?.slug ?? personRegistryType}`,
      state: {
        from: history.location.state?.from 
          ?? (params?.fromAllProfile 
            ? VW_ROUTES.ALL_PROFILES 
            : `/${getCategoryType(category?.slug) ?? "all-profile"}`) 
      }
    });
  }, [history, profileData, category]);

  const goToCreateNew = useCallback(() => {
    addAgain();
    history.replace({
      pathname: VW_ROUTES.MANAGE_PERSON_REGISTRY,
      search: `?type=${category?.slug ?? personRegistryType
        }&categoryId=${category?.value ?? profileData?.relationships?.category?.data?.id
        }${params?.fromAllProfile === "true" ? "&fromAllProfile=true" : ""}`,
    });
  }, [category]);

  useEffect(() => {
    if(!profileData) return;
    if(profileData?.relationships?.category?.data?.id)
      setCategory(getCategoryFromID(profileData?.relationships?.category?.data?.id, appOptions) ?? {});
    else if(personRegistryType)
      setCategory(getCategoryFromSlug(personRegistryType, appOptions) ?? {});
  }, [profileData, appOptions, getCategoryFromID, setCategory]);

  return (
    <div id="step-5">
      <div className="glyph-icon simple-icon-check profile-created-icon"></div>
      <p className="muted text-center p-4 font-weight-bold">
        {isEdit === true
          ? DISPLAY_TEXTS.PROFILE_UPDATED
          : DISPLAY_TEXTS.PROFILE_CREATED}
      </p>
      <div className="successPageButtonHolder">
        <button
          onClick={() => goToProfile()}
          type="button"
          className="btn btn-secondary btn-sm"
        >
          View Profile
        </button>
        {checkModuleAddPermission(userRoleDetails, getCategoryType(category?.slug), "profile") && (
          <button
            onClick={() => goToCreateNew()}
            type="button"
            className="btn btn-primary btn-sm"
          >
            Create new Profile
          </button>
        )}
      </div>
    </div>
  );
};

export default FinalStepperComponent;
