import React, { useContext, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import _ from "lodash";
import useContracts from "../CommonDeliverables/useContracts";
import Select from "react-select";
import {
  getContractOptionFromId,
  getContractorOptions,
} from "../../../helpers/utils";
import { CATEGORY_SLUGS, apiRoutes } from "../../../constants";
import { useClient, useQuery } from "jsonapi-react";
import { useState } from "react";
import {
  checkModuleGeneralPermission,
  getDayFirst,
} from "../../../helpers/helpers";
import { AppContext } from "../../../context/AppContext";
import useContractOptions from "../CommonDeliverables/useContractOptions";
import ReactSelect from "react-select";
import LazyLoadedSelect from "../../LazyLoadedSelect/LazyLoadedSelect";

const CreateAccessPass = ({
  personType,
  passData,
  onInputChange,
  isDateLoading,
  errors,
  addGatePassClicked,
}) => {
  const { userRoleDetails } = useContext(AppContext);

  const {
    contracts,
    handleContractSearch,
    loadNextContractOptionsPage,
    isContractLoading,
  } = useContractOptions();
  const { contracts: allContracts } = useContracts();
  const [isChecked, setIsChecked] = useState(false);
  const [personDetailedType, setPersonDetailedType] = useState("");
  useEffect(() => {
    setIsChecked(
      passData?.mode === "custom" && String(passData?.type ?? "").length > 0
        ? true
        : false
    );
  }, [passData]);

  // const getSitizedDate = (dateArg) => {
  //   if (dateArg) {
  //     return new Date(new Date(dateArg).toISOString().replace(".000Z", ""));
  //   }
  // };

  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const personTypes = useQuery(
    [
      apiRoutes.accessPassType,
      {
        filter: {
          category: personType,
        },
      },
    ],
    { client }
  );

  const personTypeOptions = (personTypes) => {
    let personTypeOptionsArray = [{ label: "Select", value: "", slug: "" }];
    _.forEach(_.orderBy(personTypes?.data, "name", "asc"), (item) => {
      personTypeOptionsArray.push({
        label: item.name,
        value: item.id,
        slug: item.slug,
      });
    });
    return personTypeOptionsArray;
  };

  const handlePersonDetailedType = (opt) => {
    onInputChange({
      name: "type",
      value: opt.value,
      slug: opt.slug,
    });
  };

  const handleContractChange = (opt) => {
    onInputChange({
      name: "contract",
      value: opt?.value ?? "",
      label: opt?.label ?? "Select",
    });
  };

  useEffect(() => {
    if (!personType || !passData?.type) return;
    setPersonDetailedType(
      personTypeOptions(personTypes).find(
        (option) => option.value === passData.type
      )?.slug
    );
  }, [personTypes, passData.type]);

  useEffect(() => {
    onInputChange({ name: "mode", value: isChecked });
  }, [isChecked]);

  return (
    <div className="profile-details-wrapper">
      <div className="row">
        <div className="col-md-6 mb-2">
          <div
            className={`profile-container ${
              _.includes(errors, "type") && addGatePassClicked
                ? "hasValidationError"
                : ""
            }`}
          >
            <div className="profile-label mt-2">Type</div>
            <Select
              name="type"
              className="formSelect"
              classNamePrefix="react-select-form"
              options={personTypeOptions(personTypes)}
              value={personTypeOptions(personTypes).find(
                (option) => option.value === passData.type
              )}
              onChange={(opt) => handlePersonDetailedType(opt)}
              placeholder="Select"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-2">
          <div
            className={`profile-container ${
              _.includes(errors, "fromDateTime") && addGatePassClicked
                ? "hasValidationError"
                : ""
            }`}
          >
            <div className="profile-label mt-2">
              Valid From <i>*</i>
            </div>
            <div className="position-relative">
              <DatePicker
                minDate={moment().toDate()}
                selected={getDayFirst(passData?.fromDateTime)}
                onChange={(date) =>
                  onInputChange({
                    name: "fromDateTime",
                    value: date,
                    custom: isChecked,
                  })
                }
                className="form-control"
                placeholderText="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                disabled={(passData.type ? false : true) || isDateLoading}
              />
              {isDateLoading && (
                <span className="input-loader-container">
                  <div
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                  ></div>
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-2">
          <div
            className={`profile-container ${
              _.includes(errors, "toDateTime") && addGatePassClicked
                ? "hasValidationError"
                : ""
            }`}
          >
            <div className="profile-label withChkbx mt-2">
              Valid To <i>*</i>
              {checkModuleGeneralPermission(
                userRoleDetails,
                "access-pass",
                "custom_validity",
                passData
              ) && (
                <div className="chkHolder">
                  <input
                    className="custom-checkbox-pointer"
                    type="checkbox"
                    name="mode"
                    onChange={(event) => setIsChecked(event.target.checked)}
                    id="custom"
                    value={isChecked}
                    checked={isChecked}
                    disabled={
                      passData.type && passData.fromDateTime ? false : true
                    }
                  />
                  <label htmlFor={"custom"}>Custom</label>
                </div>
              )}
            </div>
            <div className="position-relative">
              <DatePicker
                minDate={getDayFirst(passData?.fromDateTime)}
                selected={getDayFirst(passData?.toDateTime)}
                onChange={(date) =>
                  onInputChange({ name: "toDateTime", value: date })
                }
                className="form-control"
                placeholderText="DD/MM/YYYY"
                dateFormat="dd/MM/yyyy"
                disabled={
                  (passData.fromDateTime && isChecked ? false : true) ||
                  isDateLoading
                }
              />
              {isDateLoading && (
                <span className="input-loader-container">
                  <div
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                  ></div>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {personType === "visitor" && (
        <div className="row">
          <div className="col-md-6 mb-2">
            <div
              className={`profile-container ${
                _.includes(errors, "purposeOfVisit") &&
                personType === CATEGORY_SLUGS["visitor-profile"] &&
                addGatePassClicked
                  ? "hasValidationError"
                  : ""
              }`}
            >
              <div className="profile-label mt-2">
                {" "}
                Purpose Of Visit<i>*</i>
              </div>
              <input
                disabled={passData.type ? false : true}
                type="text"
                className="form-control"
                name="purposeOfVisit"
                value={passData.purposeOfVisit}
                id="purposeOfVisit"
                placeholder="Purpose of Visit"
                onChange={(e) => onInputChange(e.target)}
              />
            </div>
          </div>
          <div className="col-md-6 mb-2">
            <div
              className={`profile-container ${
                _.includes(errors, "hostName") && addGatePassClicked
                  ? "hasValidationError"
                  : ""
              }`}
            >
              <div className="profile-label mt-2">
                {" "}
                Host Name<i>*</i>{" "}
              </div>
              <input
                type="text"
                disabled={passData.type ? false : true}
                className="form-control"
                name="hostName"
                value={passData.hostName}
                id="hostName"
                placeholder="Host Name"
                onChange={(e) => onInputChange(e.target)}
              />
            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-6 mb-2">
          <div
            className={`profile-container ${
              _.includes(errors, "contract") && addGatePassClicked
                ? "hasValidationError"
                : ""
            }`}
          >
            <div className="profile-label mt-2">
              Contract{personType === "visitor" && <i>*</i>}
            </div>
            <LazyLoadedSelect
              className="formSelect"
              classNamePrefix="react-select-form"
              loadNextOptions={loadNextContractOptionsPage}
              name="contract"
              onChange={(opt) => handleContractChange(opt)}
              onInputChange={(value) => handleContractSearch(value)}
              options={contracts}
              placeholder="Select"
              value={getContractOptionFromId(allContracts, passData?.contract)}
              isLoading={isContractLoading}
              isDisabled={
                (passData.type ? false : true) || personType === "employee"
              }
            />
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div
            className={`profile-container ${
              _.includes(errors, "vehicleNumber") && addGatePassClicked
                ? "hasValidationError"
                : ""
            }`}
          >
            <div className="profile-label mt-2">
              Vehicle Number
              {(personDetailedType === "delivery-adhoc" ||
                personDetailedType === "frequent-delivery") &&
                passData.type && <i>*</i>}
            </div>
            <input
              disabled={passData.type ? false : true}
              type="text"
              className="form-control"
              name="vehicleNumber"
              value={passData.vehicleNumber}
              id="vehicleNumber"
              placeholder="Vehicle Number"
              onChange={(e) => onInputChange(e.target)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-2">
          <div
            className={`profile-container ${
              _.includes(errors, "note") && addGatePassClicked
                ? "hasValidationError"
                : ""
            }`}
          >
            <div className="profile-label mt-2">
              Note{isChecked ? <i>*</i> : ""}
            </div>
            <textarea
              type="text"
              className="form-control"
              name="note"
              disabled={passData.type ? false : true}
              value={passData.note}
              onChange={(e) =>
                onInputChange({ name: "note", value: e.target.value })
              }
              placeholder={isChecked ? "Reason for custom validity" : "Note"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccessPass;
