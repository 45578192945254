import { API_BASE_URL, apiRoutes } from "../../../constants";
import { useCallback, useState } from "react";
import Axios from "axios";

const pageSize = 10;

const getSerializedParam = (
  objectToSerialize,
  filterName,
  currentFilter = ""
) => {
  for (const [, [key, value]] of Object.entries(
    Object.entries(objectToSerialize)
  )) {
    if (value !== "" && value) {
      currentFilter += `${
        currentFilter.length ? "&" : ""
      }${filterName}[${key}]=${encodeURIComponent(value)}`;
    }
  }
  return currentFilter;
};

const formatCompanyApiData = (apiRes) =>
  apiRes?.map((resData) => {
    const apiAttributes = resData?.attributes;
    delete resData?.attributes;
    return { ...resData, ...apiAttributes };
  });

const getCompanyOptions = (companyData, excludeSelectOption) => {
  let companyArray = [];
  if (companyData?.length > 0) {
    _.forEach(_.orderBy(companyData, "name", "asc"), (company) => {
      companyArray.push({
        label: company.name,
        value: company.id,
      });
    });
  }
  return companyArray;
};

const useCompanyOptions = (excludeSelectOption = false) => {
  const [companies, setCompanies] = useState([]);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);
  const [prevSearchQuery, setPrevSearchQuery] = useState("");
  const [currSearchQuery, setCurrSearchQuery] = useState("");
  const [currPage, setCurrPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageEnd, setPageEnd] = useState(false);

  const token = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  const getCompanySelectedOption = (companyIDValue) => {
    let companyObj = null;
    if (companies?.length > 0) {
      const selectedOption = _.find(
        companies,
        (companyData) => companyData?.value === companyIDValue
      );
      if (selectedOption) companyObj = { ...selectedOption };
    }
    return companyObj;
  };

  const getPaginationSettings = (page) => ({
    size: pageSize,
    number: page,
  });

  const loadNextCompanyOptionsPage = useCallback(() => {
    if (pageEnd) return;
    if (!(totalCount == 0) && totalCount <= currPage * pageSize) {
      setPageEnd(true);
      return;
    }
    const page = totalCount == 0 ? 1 : currPage + 1;
    let filter = "";
    filter = getSerializedParam(getPaginationSettings(page), "page", filter);
    if (String(currSearchQuery ?? "").length)
      filter = getSerializedParam(
        { search: currSearchQuery },
        "filter",
        filter
      );
    setCurrPage(page);

    const searchURL = new URL(`${API_BASE_URL}${apiRoutes.company}`);
    searchURL.search = filter;

    setIsCompanyLoading(true);
    Axios.get(searchURL, config)
      .then(({ data: apiData }) => {
        if (!apiData?.data?.length) {
          setPageEnd(true);
        } else {
          const formatedData = formatCompanyApiData(apiData?.data);
          const newOptions = getCompanyOptions(
            formatedData,
            excludeSelectOption
          );
          setCompanies((currCompanies) => [...currCompanies, ...newOptions]);
          setTotalCount(apiData?.meta?.pagination?.count);
        }
      })
      .finally(() => {
        setIsCompanyLoading(false);
      });
  }, [totalCount, currPage, pageEnd]);

  const searchCompanyOptions = (resolveCallback, searchQuery) => {
    setCurrSearchQuery(searchQuery);
    setCurrPage(1);
    setPageEnd(false);
    let filter = "";
    filter = getSerializedParam(getPaginationSettings(1), "page", filter);
    if (String(searchQuery ?? "").length)
      filter = getSerializedParam({ search: searchQuery }, "filter", filter);

    const searchURL = new URL(`${API_BASE_URL}${apiRoutes.company}`);
    searchURL.search = filter;
    setIsCompanyLoading(true);
    Axios.get(searchURL, config)
      .then(({ data: apiData }) => {
        if (!apiData?.data?.length) {
          resolveCallback(getCompanyOptions([], excludeSelectOption));
          setTotalCount(0);
          setPageEnd(true);
        } else {
          const formatedData = formatCompanyApiData(apiData?.data);
          const newOptions = getCompanyOptions(
            formatedData,
            excludeSelectOption
          );
          setCompanies(newOptions);
          setTotalCount(apiData?.meta?.pagination?.count);
          resolveCallback(newOptions);
          if (
            apiData?.meta?.pagination?.count <=
            apiData?.meta?.page * pageSize
          )
            setPageEnd(true);
        }
        setPrevSearchQuery(searchQuery);
      })
      .finally(() => {
        setIsCompanyLoading(false);
      });
  };
  const handleCompanySearch = useCallback(
    (searchValue, onResolveCallBack = () => {}) => {
      if (searchValue == "" && String(prevSearchQuery ?? "").length == 0)
        return;
      if (_.inRange(String(searchValue ?? "").length, 1, 3)) {
        if (String(prevSearchQuery ?? "").length >= 3) {
          searchCompanyOptions(onResolveCallBack, "");
          return;
        } else {
          onResolveCallBack(companies);
          return;
        }
      }
      searchCompanyOptions(onResolveCallBack, searchValue);
    },
    [companies, prevSearchQuery]
  );

  const getAllCompanies = useCallback(() => {
    const searchURL = new URL(
      `${API_BASE_URL}${apiRoutes.company}?page[size]=3000`
    );
    setIsCompanyLoading(true);
    Axios.get(searchURL)
      .then(({ data: apiData }) => {
        const formattedData = formatCompanyApiData(apiData?.data);
        const newOptions = getCompanyOptions(
          formattedData,
          excludeSelectOption
        );
        setCompanies(newOptions);
      })
      .finally(() => {
        setIsCompanyLoading(false);
      });
  }, []);

  return {
    companies,
    handleCompanySearch,
    loadNextCompanyOptionsPage,
    getCompanySelectedOption,
    getAllCompanies,
    isCompanyLoading,
  };
};

export default useCompanyOptions;
