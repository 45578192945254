import React, { useCallback, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useManageLastState = () => {
    const history = useHistory();
    const { lastRouteStates, updateLastRouteStates, setLastRouteStates } = useContext(AppContext);

   const handleSaveToLocal = useCallback(() => {
    localStorage.setItem("lastRouteStates", JSON.stringify(lastRouteStates));
   }, [lastRouteStates])

    useEffect(() => {
        const dataFromLocal = JSON.parse(sessionStorage.getItem("lastRouteStates")) ?? {};
        const currentPathname = history.location.pathname;
        _.has(dataFromLocal, currentPathname) && history.replace(dataFromLocal[currentPathname]);
        setLastRouteStates(dataFromLocal);
        return handleSaveToLocal;
    }, []);

    window.onbeforeunload = () => {
        sessionStorage.setItem("lastRouteStates", JSON.stringify(lastRouteStates));
    }

    useEffect(() => {
        updateLastRouteStates(history.location);
    }, [history.location]);

    return ({
        lastRouteStates,
        updateLastRouteStates,
        setLastRouteStates
    });
}

export default useManageLastState;