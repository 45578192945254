/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import _ from "lodash";
import moment from "moment";
import DataTable from "react-data-table-component";
import { AppContext } from "../../context/AppContext";
import { addBodyClass, removeBodyClass } from "../../helpers/utils";
import UserTrainingFormComponent from "../../components/UserTrainingFormComponent/UserTrainingFormComponent";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import ModalRightComponent from "../../components/common/ModalRightComponent/ModalRightComponent";
import { parseAPIResponseError, validatorModule } from "../../helpers/helpers";
import SearchBar from "../../components/common/Search/Search";
import Loader from "../../components/common/Loader/Loader";
//var stillUtcFrom = moment.utc(passData.fromDateTime).toDate();
//var toLocalFromDateTime = moment(stillUtcFrom).local().format('DD/MM/YYYY hh:mm:ss A');
import { useHistory } from "react-router-dom";
import ProfileCard from "../../components/ProfileCard/ProfileCard";

const UserTraining = () => {
  const initialState = {
    topic: "SWSIC Training",
    location: "SWSIC Training Center",
    training_date: "",
    training_time: "",
  };
  const [tableUserId, setTableUserId] = React.useState("");

  const validationFormat = [
    {
      field: "topic",
      isRequired: true,
      fieldName: "Topic",
    },
    {
      field: "location",
      isRequired: true,
      fieldName: "Location",
    },
    {
      field: "training_date",
      isRequired: true,
      fieldName: "Training Date",
    },
  ];

  const { addToast } = useToasts();

  let page;
  const pageNumber = window.location.hash.split("page=")[1];
  if (pageNumber) {
    page = Number(pageNumber.split("&")[0]);
  } else {
    page = 1;
  }

  let initialSearchKey;
  const searchWord = window.location.hash.split("search=")[1];
  if (searchWord) {
    initialSearchKey = decodeURI(searchWord);
  } else {
    initialSearchKey = "";
  }

  const {
    showRightModal,
    manageRightModal,
    showModal,
    manageModal,
    pagePermissions,
  } = React.useContext(AppContext);
  const [mode, setMode] = React.useState("");
  const [validationMessage, setValidationMessage] = React.useState({});
  const [currentTrianingId, setcurrentTrianingId] = React.useState(0);
  const [formState, updateFormState] = React.useState(initialState);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(page);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [searchQueryString, setSearchQueryString] =
    React.useState(initialSearchKey);

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(page);
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const history = useHistory();

  const [createTraining] = useMutation(["training", "schedule"]);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  const trainings = useQuery(
    [
      "training",
      "schedule",
      {
        filter: {
          search: searchQueryString,
        },
        page: paginationSettings,
      },
    ],
    { client }
  );
  const [updateTraining] = useMutation(
    ["training", "schedule", currentTrianingId],
    { method: "PUT", client }
  );
  const onInputChange = (target) => {
    updateFormState({
      ...formState,
      [target.name]: target.value,
    });
  };
  const manageModalSettings = (status) => {
    manageRightModal(status);
    if (status) {
      addBodyClass("modal-open");
    } else {
      removeBodyClass("modal-open");
    }
  };

  const getDatesOnRequiredFormat = (dateValue) => {
    // const timeVal = moment.parseZone(timeValue).format('LT');
    // const createdDate = moment(dateValue).format("YYYY-MM-DD") + " " + moment(timeVal, ["h:mm A"]).format("HH:mm");
    var pDate = new Date(dateValue);
    return moment.utc(moment(pDate)).format();
  };

  const deleteTrainingDetails = async () => {
    const { error } = await client.delete([
      "training",
      "schedule",
      currentTrianingId,
    ]);
    if (error === undefined) {
      trainings.refetch();
      addToast(DISPLAY_TEXTS.USER_TRAINING_DELETED, { appearance: "success" });
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
    setcurrentTrianingId(0);
    manageModal(false);
    removeBodyClass("modal-open");
  };

  const submitNewTraining = async () => {
    let errors = validatorModule(formState, validationFormat);
    setValidationMessage(errors);
    if (Object.keys(errors).length === 0) {
      const formData = {
        topic: formState.topic,
        // location: formState.location,
        trainingDateTime: getDatesOnRequiredFormat(formState.training_date),
      };
      const { data, error } = await createTraining(formData);
      if (data && error === undefined) {
        trainings.refetch();
        addToast(DISPLAY_TEXTS.USER_TRAINING_ADDED, { appearance: "success" });
        manageModalSettings(false);
      } else if (error !== undefined && error.detail) {
        const errorMsg = parseAPIResponseError(error);
        if (initialState.hasOwnProperty(errorMsg.field)) {
          setValidationMessage(errorMsg);
        } else {
          addToast(error.detail, { appearance: "error" });
        }
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
    }
  };

  const updateTrainingData = async () => {
    let errors = validatorModule(formState, validationFormat);
    setValidationMessage(errors);
    if (Object.keys(errors).length === 0) {
      const formData = {
        topic: formState.topic,
        // location: formState.location,
        trainingDateTime: getDatesOnRequiredFormat(formState.training_date),
      };
      const { data, error } = await updateTraining({
        ...formData,
        id: currentTrianingId,
      });
      if (data && error === undefined) {
        trainings.refetch();
        addToast(DISPLAY_TEXTS.USER_TRAINING_UPDATED, {
          appearance: "success",
        });
        manageModalSettings(false);
      } else if (error !== undefined && error.detail) {
        const errorMsg = parseAPIResponseError(error);
        if (initialState.hasOwnProperty(errorMsg.field)) {
          setValidationMessage(errorMsg);
        } else {
          addToast(error.detail, { appearance: "error" });
        }
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
    }
  };

  const addNewTraining = () => {
    setMode("add");
    updateFormState(initialState);
    setValidationMessage({});
    manageRightModal(true);
    addBodyClass("modal-open");
  };

  const updateTrainingDetails = async (id) => {
    setMode("edit");
    setcurrentTrianingId(id);
    setValidationMessage({});
    const exisitingData = _.keyBy(trainings.data, "id")[id];
    var training_date = moment.utc(exisitingData.trainingDateTime).toDate();
    updateFormState({
      topic: exisitingData.topic,
      location: exisitingData.location,
      training_date,
    });
    manageModalSettings(true);
  };

  const manageDeletedOption = (id) => {
    setcurrentTrianingId(id);
    addBodyClass("modal-open");
    manageModal(true);
  };

  const doCloseModal = () => {
    setcurrentTrianingId(0);
    removeBodyClass("modal-open");
    manageModal(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "edit") {
      updateTrainingDetails(mode.trainings.id);
    }

    if (mode.mode === "delete") {
      manageDeletedOption(mode.trainings.id);
    }
  };

  let menuRef = React.useRef();

  React.useEffect(() => {
    document.addEventListener("mousedown", (event) => {
      if (
        event.target.className !== "user-role-action-icons" &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      )
        setTableUserId("");
    });
  });

  const showHideContextMenu = (userid) => {
    setTableUserId(userid);
  };

  const columns = [
    {
      name: "Topic",
      selector: "topic",
      sortable: true,
      cell: (trainings) => (
        <ProfileCard
          data={trainings}
          mode="Training"
          actions={pagePermissions}
          actionsToParent={(mode) => getEvent({ mode, trainings })}
        />
      ),
    },
    // {
    //     name: 'Location',
    //     selector: 'location',
    //     sortable: true
    // },
    {
      name: "Date",
      selector: "trainingDateTime",
      sortable: false,
      format: (row) => moment(row.trainingDateTime).format("lll"),
      hide: "md",
    },
    {
      name: "Actions",
      sortable: false,
      cell: (training) => (
        <>
          <div className="contextMenu">
            <span
              className="user-role-action-icons"
              onClick={() => showHideContextMenu(training.id)}
            >
              <i></i>
            </span>
            {tableUserId === training.id && (
              <ul ref={menuRef}>
                {_.includes(pagePermissions, "edit") && (
                  <li onClick={() => updateTrainingDetails(training.id)}>
                    <button>
                      <i className="iconsminds-pen user-role-action-icons edit"></i>
                      Edit
                    </button>
                  </li>
                )}
                {_.includes(pagePermissions, "delete") && (
                  <li onClick={() => manageDeletedOption(training.id)}>
                    <button>
                      <i className="simple-icon-trash user-role-action-icons delete"></i>
                      Delete
                    </button>
                  </li>
                )}
              </ul>
            )}
          </div>
        </>
      ),
      right: true,
      hide: "md",
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);

    if (page > 1) {
      history.push({
        search: `?page=${page}${
          searchQueryString ? "&search=" + encodeURI(searchQueryString) : ""
        }`,
      });
    } else {
      history.push({
        search: `${
          searchQueryString ? "?search=" + encodeURI(searchQueryString) : ""
        }`,
      });
    }
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setactivePage(1);

    if (searchQuery) {
      history.push({
        search: `?page=${1}&search=${encodeURI(searchQuery)}`,
      });
    } else {
      history.push({
        search: `?page=${1}`,
      });
    }
  };

  const onChangeRowsPerPage = (rows) => {
    setRowsPerPage(rows);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }

      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>Trainings</h1>
            <div className="mobile-btn">
              {_.includes(pagePermissions, "create") && (
                <button
                  type="button"
                  title="Add Training"
                  className="btn btn-primary btn-sm float-right"
                  onClick={() => addNewTraining()}
                >
                  <>+</>
                </button>
              )}
            </div>
            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="right-holder">
                  <SearchBar onSearchInputChange={onSearchInputChange} />
                  {_.includes(pagePermissions, "create") && (
                    <button
                      type="button"
                      title="Add Training"
                      className="btn btn-primary btn-sm dktp-btn float-right"
                      onClick={() => addNewTraining()}
                    >
                      <>+</>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div
              className="tableFilterButton"
              title="Advance Search"
              onClick={() => showFilter(true)}
            >
              {/* <span></span>
             <span></span>
             <span></span> */}
              <i className="iconsminds-filter-2"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div className="card-body dataTableOuter">
              <DataTable
                subHeader={true}
                subHeaderComponent={
                  <SearchBar
                    onSearchInputChange={onSearchInputChange}
                    popstateText={searchQueryString}
                  />
                }
                progressPending={trainings.isLoading}
                progressComponent={<Loader />}
                columns={columns}
                data={trainings.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={
                  trainings.meta && trainings.meta.pagination.count
                }
                paginationDefaultPage={activePage}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalComponent
        show={showModal}
        header={<b>Confirm Delete</b>}
        content={
          <p>Are you sure you want to delete the selected Training details ?</p>
        }
        onSubmitCallback={deleteTrainingDetails}
        onCloseCallback={doCloseModal}
      />

      <ModalRightComponent
        show={showRightModal}
        header={mode === "add" ? "Add Permission" : "Update Permission"}
        submitButtonText={mode === "add" ? "Submit" : "Update"}
        classes="normal-right-modal modal390"
        content={
          <UserTrainingFormComponent
            title={
              mode === "add"
                ? "Submitting below form will add a new permission."
                : "Submitting below form will update the permission."
            }
            updateInputChange={onInputChange}
            errors={validationMessage}
            formData={formState}
          />
        }
        onSubmitCallback={() =>
          mode === "add" ? submitNewTraining() : updateTrainingData()
        }
        onCloseCallback={() => manageModalSettings(false)}
      />
    </>
  );
};

export default UserTraining;
