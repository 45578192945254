/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useCallback, useEffect } from "react";
import { useQuery, useClient } from "jsonapi-react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "axios";
import _ from "lodash";
import {
  checkModuleViewPermission,
  formatTextWithFallback,
} from "../../helpers/helpers";

import {
  PPE_SERVICE_BASE_URL,
  apiRoutes,
  reportDateFormat,
} from "../../constants";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { AppContext } from "../../context/AppContext";
import useStorage from "../../helpers/useStorage";
import PPEReportFilter from "../../components/common/ReportFilter/PpeReportFilter";
import moment from "moment-timezone";
import usePPEChannelOptions from "../../components/common/CommonDeliverables/usePPECameraOptions";
import PPEEventViewModal from "../../components/PPEVideoModal/PpeEventViewModal";
import { useToasts } from "react-toast-notifications";
import {
  defaultFromDateFilterValue,
  defaultToDateFilterValue,
} from "../../helpers/utils";

const PPEEventReport = () => {
  const { setToStorage, getFromStorage } = useStorage();
  const { addToast } = useToasts();
  const { userRoleDetails, getCategoryFromID, appOptions } =
    React.useContext(AppContext);
  const [filterChanged, setFilterChanged] = React.useState(false);
  const [updateKey, setUpdateKey] = React.useState(0);
  const [ppeEvents, setPPEEvents] = React.useState(null);
  const [eventsLoading, setEventsLoading] = React.useState(true);
  const [selectedPPEEvent, setSelectedPPEEvent] = React.useState(null);
  const { channels, getAllChannels } = usePPEChannelOptions(true);

  const [selectedChannel, setSelectedChannel] = React.useState(
    getFromStorage("ppe_event_report_filter_channel")
      ? JSON.parse(getFromStorage("ppe_event_report_filter_channel"))
      : undefined
  );
  const [currentPageNumber, setCurrentPageNumber] = React.useState(
    getFromStorage("ppe_event_report_page_no") ?? 1
  );

  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("ppe_event_report_rows_per_page") ?? 10
  );

  const { manageLoader } = React.useContext(AppContext);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showDetailModal, setShowDetailModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const [fromDate, setFromDate] = React.useState(
    getFromStorage("ppe_event_report_filter_from_date") ??
      defaultFromDateFilterValue(reportDateFormat)
  );
  const [toDate, setToDate] = React.useState(
    getFromStorage("ppe_event_report_filter_to_date") ??
      defaultToDateFilterValue(reportDateFormat)
  );
  const [disableExport, setdisableExport] = React.useState(true);

  const history = useHistory();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const axiosOptions = (method, path, params) => {
    const url = new URL(`${PPE_SERVICE_BASE_URL}${path}`);
    if (params) {
      const urlParams = new URLSearchParams();
      Object.entries(params).forEach((paramItem) => {
        urlParams.append(paramItem[0] ?? "", paramItem[1] ?? "");
      });
      url.search = urlParams.toString();
    }
    return {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: url.toString(),
    };
  };

  let filter;

  const doFilter = useCallback(() => {
    setEventsLoading(true);
    filter = {
      ...(selectedChannel !== null &&
        selectedChannel !== undefined &&
        selectedChannel !== "" && {
          channelId: [
            ...(selectedChannel ?? []).map((chnl) => chnl.value),
          ].join(","),
        }),
      ...(toDate !== null &&
        toDate !== undefined &&
        toDate !== "" && {
          endTime: moment(toDate, "YYYY-MM-DD HH:mm").toISOString(),
        }),
      ...(fromDate !== null &&
        fromDate !== undefined &&
        fromDate !== "" && {
          fromTime: moment(fromDate, "YYYY-MM-DD HH:mm").toISOString(),
        }),
    };
    axios(
      axiosOptions("GET", apiRoutes.ppeEventReport, {
        ...(filter ? filter : {}),
        ...{
          page: currentPageNumber,
          limit: rowsPerPage,
        },
      })
    )
      .then((res) => setPPEEvents(res?.data))
      .finally(() => setEventsLoading(false));
  }, [selectedChannel, toDate, fromDate, currentPageNumber, rowsPerPage]);

  useEffect(() => {
    getAllChannels();
  }, []);

  useEffect(() => {
    doFilter();
  }, [currentPageNumber, rowsPerPage, updateKey]);

  useEffect(() => {
    if (ppeEvents?.results?.length > 0) {
      setdisableExport(false);
    } else {
      setdisableExport(true);
    }
  }, [ppeEvents?.results]);

  const handleUpdateKey = () => {
    setUpdateKey((curr) => curr + 1);
    showFilter(false);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewDetails(mode.ppeEvent);
    }
  };

  const viewDetails = (eventData) => {
    setSelectedPPEEvent(eventData);
    setShowDetailModal(true);
  };

  const handleCloseViewDetails = (shouldReloadList) => {
    setSelectedPPEEvent(null);
    setShowDetailModal(false);
    if (shouldReloadList) {
      handleUpdateKey();
    }
  };

  const columns = [
    {
      name: "Event Time",
      selector: (person) => person?.timeStamp,
      sortable: true,
      cell: (ppeEvent) => (
        <ProfileCard
          data={ppeEvent}
          mode="ppe-event"
          cellMode={"ppe-event-report"}
          actions={userRoleDetails}
          fields={[
            {
              value: ppeEvent?.channelName,
              name: "Camera Name",
            },
            {
              value: ppeEvent?.eventComment,
              name: "Description",
            },
          ]}
          actionsToParent={(mode) => getEvent({ mode, ppeEvent })}
        />
      ),
    },
    {
      name: "Camera Name",
      sortable: false,
      minWidth: 250,
      maxWidth: 300,
      selector: (ppeEvent) => ppeEvent?.channelName,
      cell: (ppeEvent) => formatTextWithFallback(ppeEvent?.channelName),
      hide: "md",
    },
    {
      name: "Description",
      selector: (ppeEvent) => ppeEvent?.eventComment,
      cell: (ppeEvent) => formatTextWithFallback(ppeEvent?.eventComment),
      sortable: false,
      grow: 1,
      hide: "md",
    },
    {
      name: "Actions",
      sortable: false,

      center: true,
      maxWidth: 100,
      minWidth: 100,
      cell: (ppeEvent) => (
        <>
          {checkModuleViewPermission(
            userRoleDetails,
            "ppe-event-report",
            "report",
            ppeEvent
          ) && (
            <span
              className="simple-icon-eye user-role-action-icons view"
              onClick={() => viewDetails(ppeEvent)}
            ></span>
          )}
        </>
      ),
      right: true,
      hide: "md",
    },
  ];

  const onChangePage = (page) => {
    setCurrentPageNumber(page);
    setToStorage({ applicant_profile_report_page_no: page }, true);
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({ applicant_profile_report_rows_per_page: rows }, true);
    setRowsPerPage(rows);
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };

  const onExport = () => {
    manageLoader(true);

    if (ppeEvents?.results?.length > 0) {
      const exportFilter = {
        ...(selectedChannel !== null &&
          selectedChannel !== undefined &&
          selectedChannel !== "" && {
            channelId: [
              ...(selectedChannel ?? []).map((chnl) => chnl.value),
            ].join(","),
          }),
        ...(toDate !== null &&
          toDate !== undefined &&
          toDate !== "" && {
            endTime: moment(toDate, "YYYY-MM-DD HH:mm").toISOString(),
          }),
        ...(fromDate !== null &&
          fromDate !== undefined &&
          fromDate !== "" && {
            fromTime: moment(fromDate, "YYYY-MM-DD HH:mm").toISOString(),
          }),
      };

      axios(axiosOptions("GET", apiRoutes.ppeEventReportExport, exportFilter))
        .then((response) => {
          manageLoader(false);
          setShowSuccessModal(true);
          setShowSuccessMessage(
            response?.data?.data?.message ??
              "Request received. We will send the report to your email address."
          );
        })
        .catch((err) => {
          manageLoader(false);
          addToast(
            err?.response?.data?.message ??
              "Failed to export! Please try again later.",
            { appearance: "error" }
          );
        });
    } else {
      manageLoader(false);
      setShowSuccessModal(true);
      setShowSuccessMessage("There is no data to export");
    }
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header justify-content-between">
            <h1>PPE Event Report</h1>
            <div
              className="tableFilterButton"
              title="Advanced Search"
              onClick={() => showFilter(true)}
            >
              <i className="iconsminds-filter-2"></i>
            </div>
          </div>
          <div className="ReportFilterHolder">
            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4 className="advanced-filter-heading mb-2">
                  Advanced Search
                </h4>
                <div className="reportSearch">
                  <PPEReportFilter
                    channelOptions={channels}
                    onChannelChange={setSelectedChannel}
                    setCurrentPageNumber={setCurrentPageNumber}
                    handleUpdateKey={handleUpdateKey}
                    setFilterChanged={setFilterChanged}
                    loading={eventsLoading}
                    onExport={onExport}
                    onFromDateChange={setFromDate}
                    onToDateChange={setToDate}
                    buttonVisibility={disableExport}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                eventsLoading
                  ? "card-body remove-header dataTableOuter reportTable table-loader"
                  : "card-body remove-header dataTableOuter reportTable"
              }
            >
              <DataTable
                subHeader={true}
                progressPending={eventsLoading}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={ppeEvents?.results}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={ppeEvents?.totalResults}
                paginationDefaultPage={currentPageNumber}
              />
            </div>
          </div>
        </div>
      </div>

      {showDetailModal && (
        <PPEEventViewModal
          data={selectedPPEEvent}
          onClose={handleCloseViewDetails}
        />
      )}

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PPEEventReport;
