import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation, useClient, useQuery } from "jsonapi-react";
import { VW_ROUTES, apiRoutes } from "../../constants";
import { setSession, parseJwt, getSession } from "../../helpers/utils";
import { parseAPIResponseError } from "../../helpers/helpers";
import loginImage from "../../assets/images/login_img.png";
import logowhite from "../../assets/images/logo_w.png";
import logo from "../../assets/images/logo.png";
import eyeOpen from "../../assets/images/eye-open.png";
import eyeClose from "../../assets/images/eye-close.png";
import Maintenance from "../Maintenance/Maintenance";
import useCheckMaintenance from "../../hooks/useCheckMaintenance/useCheckMaintenance";
import Loader from "../../components/common/Loader/Loader";

export const Login = () => {
  const initialState = {
    email: "",
    password: "",
  };
  const history = useHistory();
  const { isMaintenance, loading, systemInfo } = useCheckMaintenance();
  const [formState, updateFormState] = React.useState(initialState);
  const [error, updateErrorMessage] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassWord, setShowPassword] = React.useState(false);
  const client = useClient();
  client.config.headers = {};

  const [logInUser] = useMutation(apiRoutes.login, { client });

  React.useEffect(() => {
    const user = getSession();
    if (user) {
      history.push(VW_ROUTES.DASHBOARD);
    }
  }, [history]);

  const handleShowPassword = () => {
    setShowPassword((currState) => !currState);
  };

  const onInputChangeEvent = (e) => {
    updateFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const doLoginUser = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    // console.log(getAPIFormDataFormat('user_auth',formState))
    const { error, data } = await logInUser(formState);
    setIsLoading(false);
    if (error) {
      updateErrorMessage(parseAPIResponseError(error));
    }

    if (data !== undefined) {
      const resp = parseJwt(data.token);
      setSession(data);
      if (resp.dashboard === "administrator") {
        history.push(VW_ROUTES.USER_MANAGEMENT);
      } else if (
        resp.dashboard === "ac_dashboard" &&
        resp.role_slug === "account-coordinator"
      ) {
        history.push(VW_ROUTES.APPLICANTS_HISTORY);
      } else {
        history.push(VW_ROUTES.DASHBOARD);
      }
    }
  };

  if (!(isMaintenance === false)) {
    return <Maintenance isLoading={loading} isMaintenance={isMaintenance} />;
  }

  return (
    <>
      {/* <div className="fixed-background login-background"></div> */}
      <main className="login-cover">
        <div className="left-section">
          <div className="content-portion">
            <div className="logo_w">
              <img src={logowhite} alt="v-watch" />
            </div>
            <p>
              Vwatch a revolutionary solution for enterprises to become hybrid
              workplaces. We offer a comprehensive suite that will help you
              automate work with AI and optimise your space in alignment with
              the hybrid needs of your workforce.
            </p>
            <p>
              Smart Solutions for smarter places. Delight your workforce with
              features designed to make their workplace experiences more
              seamless.
            </p>
          </div>
          <div className="image-holder">
            <img src={loginImage} alt="v-watch" />
          </div>
        </div>
        <div className="right-section">
          <div className="content-portion">
            <div className="content-inner">
              <div className="logo">
                <img src={systemInfo?.site?.logo} alt="v-watch" />
              </div>
              <h2>Login to Your Account</h2>
              {/* <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at pulvinar quam.
                            </p> */}
              <p className="nonFieldError mb-3">
                {error && error.field === "data" ? error.errorMsg : ""}
              </p>
              <form onSubmit={doLoginUser}>
                <label className="form-group has-float-label mb-4">
                  <input
                    onChange={(e) => onInputChangeEvent(e)}
                    name="email"
                    placeholder="sample@domain.com"
                    type="text"
                    className="form-control m-0"
                  />
                  <span>E-mail</span>
                  <p className="login-error-message">
                    {error && error.field === "email" ? error.errorMsg : ""}
                  </p>
                </label>

                <label className="form-group has-float-label mb-4">
                  <input
                    onChange={(e) => onInputChangeEvent(e)}
                    name="password"
                    className="form-control m-0"
                    type={showPassWord ? "text" : "password"}
                    placeholder=""
                  />
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={handleShowPassword}
                    src={showPassWord ? eyeClose : eyeOpen}
                  />
                  <span>Password</span>
                  <p className="login-error-message">
                    {error && error.field === "password" ? error.errorMsg : ""}
                  </p>
                </label>
                <div className="d-flex justify-content-between align-items-center">
                  <a href={`#${VW_ROUTES.FORGOT_PASSWORD}`}>Forgot password?</a>
                  <button
                    className="btn btn-secondary btn-lg btn-shadow position-relative"
                    type="submit"
                    disabled={isLoading}
                  >
                    LOGIN
                    {isLoading && (
                      <span className="button-loader-container">
                        <div
                          className="spinner-border spinner-border-sm ml-1 text-white"
                          role="status"
                        ></div>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <div className="container">
                    <div className="row h-100">
                        <div className="col-12 col-md-10 mx-auto my-auto">
                            <div className="card auth-card">
                                <div className="position-relative image-side login-inner-background">



                                </div>
                                <div className="form-side">
                                    <a href="Dashboard.Default.html">
                                        <div className="logo-container">
                                            <span className="logo-single"></span>
                                            <span className="jacobs-logo"></span>
                                        </div>


                                    </a>
                                    <p className="nonFieldError">{error && error.field === 'nonFieldErrors' ? error.errorMsg : ''}</p>
                                    <h6 className="mb-4">Login</h6>
                                    <form onSubmit={doLoginUser}>
                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="email" type="text" className="form-control" />
                                            <span>E-mail</span>
                                            <p className="login-error-message">{error && error.field === 'email' ? error.errorMsg : ''}</p>
                                        </label>

                                        <label className="form-group has-float-label mb-4">
                                            <input onChange={(e) => onInputChangeEvent(e)} name="password" className="form-control" type="password" placeholder="" />
                                            <span>Password</span>
                                            <p className="login-error-message">{error && error.field === 'password' ? error.errorMsg : ''}</p>
                                        </label>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <a href={`#${VW_ROUTES.FORGOT_PASSWORD}`}>Forget password?</a>
                                            <button className="btn btn-primary btn-lg btn-shadow" type="submit">LOGIN</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </main>
    </>
  );
};

export default Login;
