import React from "react";

const SearchBar = ({
  onSearchInputChange,
  fromDrawer = false,
  popstateText,
}) => {
  const [searchText, setSearchText] = React.useState("");
  const onSubmitSearch = (e) => {
    e.preventDefault();
    onSearchInputChange(searchText);
  };

  React.useEffect(() => {
    if (popstateText) {
      setSearchText(popstateText);
    } else {
      setSearchText("");
    }
  }, [popstateText]);

  const onResetSearch = () => {
    if (searchText && searchText !== "") {
      setSearchText("");
      onSearchInputChange("");
    }
  };

  const onSearchInput = (val) => {
    if (val) {
      setSearchText(val);
    } else {
      onResetSearch();
    }
  };

  return (
    <form className={`search-container`} onSubmit={(e) => onSubmitSearch(e)}>
      <div className={`searchHolder w-100 ${fromDrawer ? "m-0" : ""}`}>
        {" "}
        <input
          type="text"
          className="form-control rounded-0 rounded-left"
          name="search"
          value={searchText}
          id="search"
          placeholder="Search"
          onChange={(e) => onSearchInput(e.target.value)}
        />
        {/* {searchText !== null && searchText !== undefined && searchText !== "" && ( */}
        <div
          className={`mr-1 d-flex align-items-center justify-content-center ${
            searchText !== null && searchText !== undefined && searchText !== ""
              ? "text-primary cursor-pointer "
              : "opacity-0 "
          }`}
          onClick={(e) => onResetSearch()}
        >
          <span className={`simple-icon-refresh `}></span>
        </div>
        {/* )} */}
        <button type="submit" title="Search" >
          <span className="simple-icon-magnifier"></span>
        </button>
      </div>
      {/* <button
        type="button"
        title="Reset"
        className="btn btn-primary reset"
        onClick={(e) => onResetSearch()}
      >
        <i className="iconsminds-repeat-2"></i>
      </button> */}
    </form>
  );
};

export default SearchBar;
