import React from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { VW_ROUTES, apiRoutes } from "../../constants";
import NavigationLink from "../../components/common/NavigationLink/NavigationLink";
//import { getMenuItems } from "../../services/appServices";
import { useClient, useQuery } from "jsonapi-react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const NavigationSidebar = ({
  subSidebarHidden,
  hideSubSidebar,
  user,
}) => {
  const { hideMainSidebar, userRoleDetails } =
    React.useContext(AppContext);

  const history = useHistory();
  const location = useLocation();
  const [menuItems, setMenuItems] = React.useState([]);
  const [subMenuItems, setSubMenuItems] = React.useState([]);
  const token = localStorage.getItem("access_token");
  const client = useClient();
  let selectedMenu = 0;
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const { data, error } = useQuery(null && apiRoutes.systemMenu.replace("{role}",user.role), { client });

  if (
    (error !== undefined && error.detail === "Signature has expired.") ||
    error?.status === "401"
  ) {
    localStorage.clear();
    history.push(VW_ROUTES.LOGIN);
  }

  React.useEffect(() => {
    if (data && data.config) {
      setMenuItems(data.config);
    }
  }, [data]);

  let menuRef = React.useRef();

  React.useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        !subSidebarHidden &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        if (window.innerWidth <= 1199) {
          hideMainSidebar(false);
          hideSubSidebar(true);
        } else {
          hideSubSidebar(true);
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
    // eslint-disable-next-line
  }, [subSidebarHidden]);

  const onNavigationItemClick = (e, id) => {
    e.preventDefault();
    if(!userRoleDetails?.menuItems) return;
    selectedMenu = userRoleDetails?.menuItems[id];
    setSubMenuItems(selectedMenu.children);
    if (!_.isEmpty(selectedMenu.children)) {
      hideSubSidebar(false);
    } else {
      hideSubSidebar(true);
      const toRoute = String(VW_ROUTES[selectedMenu.route] ?? "");
      if(toRoute.includes("[new-tab]"))
        window.open(toRoute.replace("[new-tab]", ""), "_blank")
      else
        history.push(`${VW_ROUTES[selectedMenu.route]}`);
    }
  };

  const onSubmenuNavigationItemClick = (e, route, item) => {
    e.preventDefault();
    history.push(`${route}`);
  };

  const sidebarUI = () => {
    if(!userRoleDetails?.menuItems) return;
    return _.map(Object.entries(userRoleDetails?.menuItems), (menuItem) => (
      <NavigationLink
        currentPage={location.pathname}
        onNavigationItemClickCallback={onNavigationItemClick}
        key={_.uniqueId("menu_")}
        item={menuItem[1]}
        itemKey={menuItem[0]}
      />
    ));
  };

  const subMenuItem = () => {
    return _.map(subMenuItems, (item) => (
      <li key={_.uniqueId("submenu-")}>
        <Link
          to={VW_ROUTES[item.route]}
          onClick={(e) =>{
            onSubmenuNavigationItemClick(e, VW_ROUTES[item.route], item);
          }
          }
        >
          {/* <i className={item.icon}></i>  */}
          <span className="d-inline-block">{item.name}</span>
        </Link>
        {item.children && (
          <ul className="list-unstyled inner-level-menu ml-4">
            {innerSubMenu(item.children)}
          </ul>
        )}
      </li>
    ));
  };

  const innerSubMenu = (innerSub) => {
    return _.map(innerSub, (item) => {
      return (
        <li key={_.uniqueId("submenu-")}>
          <a href={VW_ROUTES[item.route]}>
            <i className={item.icon}></i>{" "}
            <span className="d-inline-block">{item.title}</span>
          </a>
        </li>
      );
    });
  };

  return (
    <div className="menu" ref={menuRef}>
      <div className="main-menu default-transition">
        <div className="scroll">
          <ul className="list-unstyled">{sidebarUI()}</ul>
        </div>
      </div>

      <div className="sub-menu default-transition">
        <div className="scroll">
          <ul
            className={`list-unstyled ${
              subSidebarHidden ? "d-none" : "d-block"
            }`}
            data-link="applicantlist"
            id="applicantlist"
          >
            <li>
              {/* eslint-disable-next-line */}
              <a
                href="#"
                data-toggle="collapse"
                data-target="#collapseAuthorization"
                aria-expanded="true"
                aria-controls="collapseAuthorization"
                className="rotate-arrow-icon opacity-50"
              >
                <i className="simple-icon-arrow-down"></i>{" "}
                <span className="d-inline-block">Sub Menu</span>
              </a>
              <div id="collapseAuthorization" className="collapse show">
                <ul className="list-unstyled inner-level-menu">
                  {subMenuItem()}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavigationSidebar;
