export const VW_ROUTES = {
  RESET_FORGOT_PASSWORD: "/reset-forgot-password",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  RESET_PASSWORD: "/reset-password",
  ROLES_AND_PERMISSIONS_ROLES: "/rolespermissions-roles",
  ANALYTICS: "/analytics",
  VIDEO_ANALYTICS: "/video-analytics",
  TRAINING: "/applicant-training",
  PROFILE: "/profile",
  VERIFY_USER: "/verification/:email_key",
  ALL_PROFILES: "/all-profile",
  EMPLOYEE_PROFILE: "/employee-profile",
  VISITOR_PROFILES: "/visitor-profile",
  MANAGE_PERSON_REGISTRY: "/manage-person-registry",
  ORGANISATION: "/organisation",
  USERS: "/organisation/users",
  COMPANIES: "/organisation/companies",
  CONTRACTS: "/organisation/contracts",
  PROFILE_REPORT: "/reports/profile-report",
  ACS_EVENT_REPORT: "/reports/acs-event-report",
  ANPR_EVENT_REPORT: "/reports/anpr-event-report",
  MAN_HOUR_REPORT: "/reports/man-hour-report",
  PPE_EVENT_REPORT: "/reports/ppe-event-report",
  SELF_REGISTRATION_INVITES: "/self-registration-invites",
  APPLICANTS: "/applicants",
  PERSON_REGISTRY: "/person-registry",
  ARCHIVED_APPLICANTS: "/applicants/archived",
  APPLICANTS_HISTORY: "/all-applicants",
  APPLICANTS_VISITIOR: "/visitor",
  APPLICANTS_VISITIOR_ADD: "/add-visitor",
  APPLICANTS_VISITIOR_EDIT: "/edit-visitor",
  PERSON_PROFILE_VIEW: "/person-profile/view",
  APPLICANTS_STAFF: "/staff",
  APPLICANTS_STAFF_MANAGE: "/manage-staff",
  APPLICANTS_STAFF_VIEW: "/applicant/view/",
  APPLICANTS_STAFF_EDIT: "/applicant/staff/edit",
  APPLICANTS_DELIVERY_AD_HOC: "/delivery-adhoc",
  APPLICANTS_DELIVERY_AD_HOC_MANAGE: "/manage-delivery-adhoc",
  APPLICANTS_DELIVERY_AD_HOC_EDIT: "/applicant/delivery-adhoc/edit",
  APPLICANTS_DELIVERY_REGULAR: "/delivery-regular",
  APPLICANTS_DELIVERY_REGULAR_MANAGE: "/manage-delivery-regular",
  APPLICANTS_DELIVERY_REGULAR_EDIT: "/applicant/delivery-regular/edit",
  APPLICANTS_PENDING: "/person/profile/pending",
  APPLICANTS_EXPIRY_STATUS_LISTING: "/applicant/work-permit-expiry/lists",
  APPLICANTS_VALIDITY_EXPIRY_STATUS_LISTING:
    "/applicant/validity-expiry-status/lists",
  PERSON_PROFILE_CIDB_EXPIRY_LISTING: "/person/cidb-expiry/lists",
  CONTRACTOR_APPLICANTS_LISTING: "/contractor/applicant/lists",
  DAILY_PPE_EVENTS_LISTING: "/daily-ppe-events/lists",
  LIVE_PEOPLE_LISTING: "/live-people/lists",
  OVER_STAY_PEOPLE_LISTING: "/over-stay-people/lists",
  LIVE_VEHICLE_COUNT_LIST: "/live-vehicles",
  OVERSTAY_VEHICLE_COUNT_LIST: "/vehicle-overstay",
  PENDING_APPLICANTS_LISTING: "/applicant/pending/lists",
  INFO_DISPLAY_PAGE: "/info",
  REPORTS: "/reports",
  HELP: "[new-tab]/help",
  SELF_REGISTARTION: "/self-registration/new-visitor",
  SELF_REGISTRATION_LANDING: "/self-registration",
  SELF_REGISTRATION_EXISTING_USER: "/self-registration/existing-visitor",
  //APPLICANTS_OVERNIGHT_STAY_DETAILS:'/applicants/overnight/stay/details',
};

// export const API_BASE_URL = "https://vwatch.v2.staging.0x1235.pw/api/v1";
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://standard.vwatch.app/api/v2";

export const CCTV_URL =
  process.env.REACT_APP_CCTV_BASE_URL || "http://202.55.92.163:36100/portal/";
export const DEVICE_SERVICE_BASE_URL =
  process.env.REACT_APP_ISM_DEVICE_SERVICE_BASE_URL ||
  "https://standard.vwatch.app/services/ism/device";
export const DEVICE_SERVICE_URL =
  process.env.REACT_APP_ISM_DEVICE_SERVICE_BASE_URL ||
  "https://standard.vwatch.app/services/ism";
export const PPE_SERVICE_BASE_URL =
  process.env.REACT_APP_PPE_SERVICE_BASE_URL ||
  "https://standard.vwatch.app/services/ppe/v1";
export const APP_TIMEZONE =
  process.env.REACT_APP_APP_TIMEZONE || "Asia/Singapore";

export const apiRoutes = {
  applicants: "/applicant",
  applicantPhoto: "/person/profile/{profileId}/photo",
  updateProfileData: "/person/profile/{personId}",
  createProfileData: "/person/profile",
  createProfileDataForm: `${API_BASE_URL}/person/profile`,
  updateProfileDataForm: `${API_BASE_URL}/person/profile/{personId}`,
  fetchAllIc: `/person/profile/{personId}/ic`,
  gteAllIcs: `${API_BASE_URL}/person/profile/{personId}/ic`,
  createIc: `${API_BASE_URL}/person/profile/{personId}/ic`,
  updateIc: `${API_BASE_URL}/person/profile/{personId}/ic/`,

  updateContract: "/person/profile/{personId}/contract",
  updateCompany: "/person/profile/{personId}/company",
  archiveProfile: "/person/archive",
  updateGatePass: "/person/profile/{profileId}/gate-pass/{passId}",
  toggleApplicantProfile: "/person/profile/{profileId}/convert_profile",
  rejectApplicantRequest: "/person/profile/{currentApplicant}/reject",
  approveApplicantRequest: "/person/profile/{currentApplicant}/approve",
  updateApplicantTraining: "/person/profile/{currentApplicant}/training",
  getProfileCategory: "/person/category?filter[slug]={categorySlug}",
  systemInfo: "/system/info",
  systemMenu: "/system/menu/{role}",
  userRoleDetails: "/accounts/roles/{role}",
  userAction: "/accounts/users",
  systemConfig: "/system/config",
  getPersonPhoto: "/person/profile/{personId}/photo",
  downloadAccessPassView:
    "/person/profile/{personId}/pass/{accessPassId}/card/download",

  getWorkPermit: "person/profile/work-permit",
  getICExpiry: "person/ic/expiry",

  aplicantArchive: `${API_BASE_URL}/person/profile/archive`,
  profilePic: `${API_BASE_URL}/person/profile/{profileId}/photo`,

  avatar: `${API_BASE_URL}/accounts/users/{profileId}/avatar`,

  exportWpExpiryList: `${API_BASE_URL}/person/profile/work-permit/expiring/export`,
  exportTrainingExpiryList: `${API_BASE_URL}/person/profile/export_training_expiry_list`,
  exportWpExpiredList: `${API_BASE_URL}/person/profile/work-permit/expired/export`,
  exportPendingList: `${API_BASE_URL}/person/profile/export_pending_list`,
  exportOverStayList: `${API_BASE_URL}/person/profile/export_over_stay_list`,
  exportCIDBExpiryList: `${API_BASE_URL}/person/ic/expiry/export`,

  exportExpiringPasses: `${API_BASE_URL}/pass/export_expiring`,
  exportLiveCountWithFilter: `${API_BASE_URL}/person/profile/export_live_count_with_filter`,
  exportPendingPasses: `${API_BASE_URL}/pass/export_pending`,

  getAccessPassView: `${API_BASE_URL}/person/profile/{personId}/pass/{accessPassId}/card`,
  downloadAcccessPass: `${API_BASE_URL}/person/profile/{personId}/pass/{accessPassId}/card/download`,
  idCard: `${API_BASE_URL}/person/profile/{profileId}/id-card`,
  idCardDownload: `${API_BASE_URL}/person/profile/{profileId}/id-card/download`,
  applicantProfile: `${API_BASE_URL}/person/profile`,
  resyncProfile: `${API_BASE_URL}/person/profile/{profileId}/resync_profile`,

  ppeEventReport: `/events/ppe`,
  ppeChannels: `/events/channel`,
  ppeVideoRefetch: `/events/video/refetch`,

  ppeEventReportExport: `/events/export`,
  acsTransactionExport: `${DEVICE_SERVICE_URL}/acs/events/export`,
  acsTransactionArchivedExport: `${DEVICE_SERVICE_URL}/acs/events/archived/export`,
  bcaExport: `${API_BASE_URL}/reports/bca/export`,
  profileReportExport: `${API_BASE_URL}/reports/profile/export`,
  accountsRoles: `${API_BASE_URL}/accounts/roles`,

  ban: `${API_BASE_URL}/person/profile/{profileId}/ban`,

  company: "/organisation/company",
  contract: "/organisation/contract",
  contractUpdate: "/organisation/contract/{id}",
  tradeInformation: "/organisation/trade",
  companyUpdate: "/organisation/company/{companyId}",
  weeklyVisitData: "/system/graph/visit/weekly",
  threeMonthsVisitData: "/system/graph/visit/last-three-months",
  dashboardStats: "/system/dashboard-stats",
  peopleCounter: "/system/live/people-count",
  alertCounter: "/system/over-night-stay-count",
  systemResourcesResponse: "/system/resource",
  login: "auth/login",
  accessPassType: "/person/profile/pass/type",
  accessPassGenerateValidity: `${API_BASE_URL}/person/profile/pass/validity/generate`,
  accessPasses: "/person/profile/{profileId}/pass",
  updateAccessPass: "/person/profile/{profileId}/pass/{passId}",
  resendGatePass: "/person/profile/{profileId}/pass/{passId}/resend",
  cancelAccessPass: "/pass/{passId}/cancel",
  contractHistroy: "person/profile/{profileId}/contract",
  companyHistroy: "person/profile/{profileId}/company",
  dashboardLayout: `/dashboard/`,
  dashboardWidgetsData: `/dashboard/dashboard/widget`,
  peopleStatsUpdate: `${API_BASE_URL}/person/stats/update`,
  userStatsUpdate: `${API_BASE_URL}/accounts/stats/update`,
  profileHistory: "person/profile/{profileId}/history",
  accessPassHistory: "person/profile/{profileId}/pass/{passId}/history",
  duplicateCheck: "person/profile/exists",
  publicRegister: `${API_BASE_URL}/person/self-register`,
  publicRegisterPost: `/person/self-register`,
  publicRegisterUserExists: `/person/self-register/exists`,
  publicRegisterExistsUserRequestPass: `/person/self-register/{self_visitor_existing_id}/pass`,
  publicRegisterInvite: `/self-register/invite`,
  publicRegisterInviteValidate: `/self-register/invite/validate?token=`,
  bulkRegistrationUpload: `${API_BASE_URL}/person/profile/bulk-file/upload`,
  bulkRegistrationRegister: `/person/profile/bulk-profile/register`,
  bulkRegistrationProfileFile: `${API_BASE_URL}/person/profile/bulk-profile/file`,
};

export const ROUTE_PERMISSION = {
  ALWAYS: "always-allow",
};

export const CATEGORY_SLUGS = {
  "employee-profile": "employee",
  "visitor-profile": "visitor",
};

export const REGISTER_MODES_CUSTOM_MAP = {};

export const ACS_EVENT_TYPES_OPTIONS = [
  {
    label: "Exit",
    value: "0",
  },
  {
    label: "Enter",
    value: "1",
  },
];

export const reportDateFormat = "YYYY-MM-DD HH:mm";
export const reportDatewoTimeFormat = "YYYY-MM-DD";
