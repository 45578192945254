import React from "react";
import { AppContext } from "../../../context/AppContext";

const useUserListSharedContext = () => {
  const {
    showModal,
    manageModal,
    pagePermissions,
    currentUser,
    initialPageParams,
    updateInitialPageParams,
    isDeactivate,
    selectedRows,
    onRowSelect,
    archiveResult,
    doarchiveApplicants,
    isArchive,
    appOptions,
    showBanModal,
    setProfilePageUrlParams,
  } = React.useContext(AppContext);

  return {
    showModal,
    manageModal,
    pagePermissions,
    currentUser,
    initialPageParams,
    updateInitialPageParams,
    setProfilePageUrlParams,
    isDeactivate,
    selectedRows,
    onRowSelect,
    archiveResult,
    doarchiveApplicants,
    isArchive,
    appOptions,
    showBanModal,
  };
};

export default useUserListSharedContext;
