import React from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { VW_ROUTES, apiRoutes } from "../../../constants";
import DISPLAY_TEXTS from "../../../helpers/displayTexts";
import {
  getCategoryType,
  getDayFirst,
  getIDNameById,
  getIDTypeId,
  validatorModule,
} from "../../../helpers/helpers";
import Select from "react-select";
import { AppContext } from "../../../context/AppContext";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
const AdditionalIds = ({ profileData }) => {
  const { addToast } = useToasts();
  const { appOptions } = React.useContext(AppContext);
  const history = useHistory();
  const { search } = history.location;
  let params = queryString.parse(search);
  const token = localStorage.getItem("access_token");
  const initialErrorState = {
    ic_type: "",
    ic_number: "",
    expiry: "",
  };

  const initialFormState = {
    person: "",
    ic_type: "",
    ic_number: "",
    expiry: "",
    document: "",
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorState, setErrorState] = React.useState(initialErrorState);
  const [submitButtonPressed, setSubmitButtonPressed] = React.useState(false);
  const [isPassPort, setIsPassPort] = React.useState(false);
  const [formState, setFormState] = React.useState(initialFormState);

  const [updateFormState, setUpdateFormState] = React.useState({
    person: "",
    ic_type: "",
    ic_number: "",
    expiry: "",
    document: "",
  });

  const [createIdFile, setCreateIdFile] = React.useState(null);
  const [updateIdFile, setUpdateIdFile] = React.useState(null);
  const [existingIds, setExistingIds] = React.useState([]);
  const [currentEdit, setCurrentEdit] = React.useState(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isCreateNew, setIsCreateNew] = React.useState(false);
  const [deleteItem, setDeleteItem] = React.useState("");

  const showHideCreateNew = () => {
    setIsCreateNew(!isCreateNew);
    setCreateIdFile("");
    setSubmitButtonPressed(false);
    setFormState(initialFormState);
    setErrorState(initialErrorState);
  };

  const isExpiryRequired = () => {
    const idType = isEdit ? updateFormState.ic_type : formState.ic_type;
    if (idType !== "nric") {
      return true;
    } else {
      return false;
    }
  };

  const validationFormat = [
    {
      field: "ic_type",
      fieldName: "ID Type",
      isRequired: true,
    },
    {
      field: "ic_number",
      fieldName: "ID Number",
      isAlphaNumeric: true,
      isRequired: true,
      minLength: 4,
    },
    {
      field: "expiry",
      isRequired: isExpiryRequired(),
      fieldName: "ID Expiry",
    },
  ];

  const onFocusChange = () => {
    let errors = validatorModule(
      isEdit
        ? {
            ...updateFormState,
          }
        : {
            ...formState,
          },
      validationFormat
    );
    setErrorState(errors);
  };

  React.useEffect(() => {
    if (submitButtonPressed) {
      onFocusChange();
    }
  }, [formState]);

  React.useEffect(() => {
    if (submitButtonPressed) {
      onFocusChange();
    }
  }, [updateFormState]);

  const getIdOptions = () => {
    let idTypeArray = [{ label: "Select", value: "" }];
    if (appOptions.ic_types?.length > 0) {
      appOptions.ic_types.forEach((item) => {
        if (item.slug !== "cidb") {
          idTypeArray.push({
            label: item.display_name,
            value: item.slug,
          });
        }
      });
    }
    return idTypeArray;
  };

  const getIdValues = (v) => {
    let idTypeObj = { label: "Select", value: "" };
    if (appOptions.ic_types?.length > 0) {
      appOptions.ic_types.forEach((item) => {
        if (item.slug === v)
          idTypeObj = {
            label: item.display_name,
            value: item.slug,
          };
      });
    }
    return idTypeObj;
  };

  const getIdValuesById = (id) => {
    let idTypeObj = { label: "Select", value: "" };
    if (appOptions.ic_types?.length > 0) {
      appOptions.ic_types.forEach((item) => {
        if (item.value === id)
          idTypeObj = {
            label: item.display_name,
            value: item.slug,
          };
      });
    }
    return idTypeObj;
  };

  const getCreateIdFile = (e) => {
    setCreateIdFile(e.target.files[0]);
  };

  const getUpdateIdFile = (e) => {
    setUpdateIdFile(e.target.files[0]);
  };

  const onInputChange = ({ name, value }) => {
    let formData = { ...formState };
    if (name === "ic_type" && value === "passport") {
      setIsPassPort(true);
    } else if (name === "ic_type" && value !== "passport") {
      setIsPassPort(false);
    }
    if (name === "ic_type") {
      formData.ic_type = value;
      formData.ic_number = "";
    }
    if (name === "ic_number") {
      if (formData.ic_type !== "passport") {
        if (value.length <= 16) {
          formData[name] = value;
        }
      } else {
        if (value.length <= 16) {
          formData[name] = value;
        }
      }
    }

    if (name === "expiry") {
      formData.expiry = value;
    }

    setFormState(formData);
  };

  const onEditInputChange = ({ name, value }) => {
    let formData = { ...updateFormState };
    if (name === "ic_type" && value === "passport") {
      setIsPassPort(true);
    } else {
      setIsPassPort(false);
    }
    if (name === "ic_type") {
      formData.ic_type = value;
      formData.ic_number = "";
    }
    if (name === "ic_number") {
      if (formData.ic_type !== "passport") {
        if (value.length <= 16) {
          formData[name] = value;
        }
      } else {
        if (value.length <= 16) {
          formData[name] = value;
        }
      }
    }

    if (name === "expiry") {
      formData.expiry = value;
    }

    setUpdateFormState(formData);
  };

  const createIC = async (e) => {
    e?.preventDefault();

    setSubmitButtonPressed(true);

    let errors = validatorModule(
      {
        ...formState,
      },
      validationFormat
    );
    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const formData = { ...formState };
      let dataFormData = new FormData();
      const icId = getIDTypeId(formData.ic_type, appOptions.ic_types);
      formData.ic_type = icId;
      formData["person"] = profileData?.id;
      if (createIdFile) {
        formData["document"] = createIdFile;
      }
      if (formData.expiry) {
        formData.expiry = moment(formState.expiry).format("DD-MM-YYYY");
      } else {
        if (formData.hasOwnProperty("expiry")) {
          delete formData["expiry"];
        }
      }
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const value = formData[key];
          dataFormData.append(key, value);
        }
      }

      axios
        .post(
          `${apiRoutes.createIc.replace("{personId}", profileData?.id)}`,
          dataFormData,
          {
            headers: {
              Authorization: `JWT ${token}`,
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          addToast(DISPLAY_TEXTS.PROFILE_ID_CREATED, { appearance: "success" });
          setCurrentEdit("");
          gteAllIcs();
          setErrorState(initialErrorState);
          setIsLoading(false);
          setSubmitButtonPressed(false);
          setFormState(initialFormState);
          setIsCreateNew(false);
        })
        .catch((error) => {
          setIsLoading(false);
          addToast(
            error.response.data.errors &&
              error.response.data.errors.length > 0 &&
              error.response.data.errors[0]?.detail
              ? error.response.data.errors[0]?.code === "unique"
                ? "Selected ID Type already exits"
                : error.response.data.errors[0]?.detail
              : "Something went wrong",
            { appearance: "error" }
          );
          setSubmitButtonPressed(false);
        });
    }
  };

  const updateIC = async (e) => {
    e?.preventDefault();

    setSubmitButtonPressed(true);

    let errors = validatorModule(
      {
        ...updateFormState,
      },
      validationFormat
    );
    setErrorState(errors);
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      const formData = { ...updateFormState };
      let dataFormData = new FormData();
      formData["person"] = profileData?.id;
      const icId = getIDTypeId(updateFormState.ic_type, appOptions.ic_types);
      formData.ic_type = icId;
      if (updateIdFile?.size) {
        formData["document"] = updateIdFile;
      }
      if (formData.expiry) {
        formData.expiry = moment(updateFormState.expiry).format("DD-MM-YYYY");
      } else {
        if (formData.hasOwnProperty("expiry")) {
          delete formData["expiry"];
        }
      }

      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const value = formData[key];
          dataFormData.append(key, value);
        }
      }

      axios
        .patch(
          `${apiRoutes.createIc.replace("{personId}", profileData?.id)}/${
            updateFormState.id
          }`,
          dataFormData,
          {
            headers: {
              Authorization: `JWT ${token}`,
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          addToast(DISPLAY_TEXTS.PROFILE_ID_UPDATED, { appearance: "success" });
          setCurrentEdit("");
          setIsEdit(false);
          setUpdateIdFile("");
          setIsCreateNew(false);
          gteAllIcs();
          setErrorState(initialErrorState);
          setSubmitButtonPressed(false);
          setIsLoading(false);
        })
        .catch((error) => {
          addToast(
            error.response.data.errors &&
              error.response.data.errors.length > 0 &&
              error.response.data.errors[0]?.detail
              ? error.response.data.errors[0]?.code === "unique"
                ? "Selected ID Type already exits"
                : error.response.data.errors[0]?.detail
              : "Something went wrong",
            { appearance: "error" }
          );

          setIsLoading(false);
        });
    }
  };

  const gteAllIcs = () => {
    axios
      .get(
        `${apiRoutes.gteAllIcs.replace(
          "{personId}",
          profileData?.id
        )}?include=ic_type`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        setExistingIds(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    gteAllIcs();
  }, []);

  const showEdit = (id) => {
    setIsEdit(true);
    setUpdateIdFile("");
    setIsCreateNew(false);
    setCurrentEdit(id.id);

    setFormState({
      id: "",
      ic_type: "",
      ic_number: "",
      expiry: "",
    });

    if (
      getIDNameById(id.relationships?.icType?.data?.id, appOptions.ic_types) ===
      "passport"
    ) {
      setIsPassPort(true);
    } else {
      setIsPassPort(false);
    }

    setUpdateFormState({
      id: id.id,
      ic_type: getIDNameById(
        id.relationships?.icType?.data?.id,
        appOptions.ic_types
      ),
      ic_number: id.attributes?.icNumber,
      expiry: getDayFirst(id.attributes?.expiry),
    });

    setUpdateIdFile(
      id.attributes?.documentName ? { name: id.attributes.documentName } : null
    );
  };

  const deleteId = (id) => {
    setDeleteItem(id.id);
    setIsLoading(true);
    axios
      .delete(
        `${apiRoutes.createIc.replace("{personId}", profileData?.id)}/${id.id}`,
        {
          headers: {
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((response) => {
        addToast(DISPLAY_TEXTS.PROFILE_ID_DELETED, { appearance: "success" });
        gteAllIcs();
        setIsLoading(false);
        setDeleteItem("");
      })
      .catch((error) => {
        addToast(
          error.response.data.errors &&
            error.response.data.errors.length > 0 &&
            error.response.data.errors[0]?.detail
            ? error.response.data.errors[0]?.detail
            : "Something went wrong",
          { appearance: "error" }
        );
        setDeleteItem("");
        setIsLoading(false);
      });
  };

  const hideEdit = (id) => {
    setCurrentEdit("");
    setIsEdit(false);
    setUpdateIdFile("");
    setIsCreateNew(false);
    setSubmitButtonPressed(false);
    setErrorState(initialErrorState);
    setCreateIdFile("");

    setUpdateFormState({
      id: "",
      ic_type: "",
      ic_number: "",
      expiry: "",
    });
  };

  return (
    <div className="idManager">
      {existingIds?.length !== 0 &&
        existingIds?.map((id, index) => (
          <>
            {getIdValuesById(
              id.relationships.icType.data.id
            ).label.toUpperCase() !== "CIDB" && (
              <div key={id.id} className="id-wrapper">
                <div className="id-row">
                  {currentEdit !== id.id && (
                    <div className="form-row w-100">
                      <div className="type col-xl-4 col-lg-4">
                        <div className="label">Document Type</div>
                        <div className="form-control">
                          {id.relationships.icType.data.id &&
                          id.relationships.icType.data.id !== ""
                            ? getIdValuesById(id.relationships.icType.data.id)
                                .label
                            : ""}
                        </div>
                      </div>
                      <div className="id col-xl-8 col-lg-8">
                        <div className="label">Document ID</div>
                        <div className="form-control">
                          {id.attributes?.icNumber?.toUpperCase()}
                        </div>
                      </div>
                      <div className="expiry col-xl-4 col-lg-4">
                        <div className="label">ID Expiry</div>
                        <div className="form-control">
                          {id.attributes?.expiry ? id.attributes?.expiry : "--"}
                        </div>
                      </div>
                      <div className="expiry col-xl-8 col-lg-8">
                        <div className="label">Document</div>
                        <div className="file-and-btn">
                          <label className="form-control file-control bgGray">
                            <div className="fileName">
                              <span className="fileNameText">
                                {id.attributes?.documentName
                                  ? id.attributes?.documentName
                                  : "--"}
                              </span>
                            </div>
                          </label>
                          <button
                            type="button"
                            className="delete btn btn-primary btn-sm"
                            onClick={() => showEdit(id)}
                          >
                            <i className="simple-icon-pencil"></i>
                          </button>
                          <button
                            type="button"
                            className="delete btn btn-danger bordered btn-sm"
                            onClick={() => deleteId(id)}
                          >
                            {!isLoading && (
                              <i className="simple-icon-trash"></i>
                            )}
                            {isLoading && !isEdit && deleteItem === id.id && (
                              <span className="spinner-loader-container">
                                <span
                                  className="spinner-border spinner-border-sm text-white"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Loading...</span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentEdit === id.id && (
                    <form onSubmit={updateIC}>
                      <div className="form-row w-100">
                        <div
                          className={`form-group type col-xl-4 col-lg-4 ${
                            errorState["ic_type"] && isEdit
                              ? "hasValidationError"
                              : ""
                          }`}
                        >
                          <div className="label">Document Type</div>
                          <Select
                            className="formSelect"
                            classNamePrefix="react-select-form"
                            value={
                              updateFormState.ic_type &&
                              updateFormState.ic_type !== ""
                                ? getIdValues(updateFormState.ic_type)
                                : { label: "Select", value: "" }
                            }
                            onChange={(e) =>
                              onEditInputChange({
                                name: "ic_type",
                                value: e.value,
                              })
                            }
                            name="id_type"
                            options={getIdOptions()}
                          />

                          <p className="error-message">
                            {errorState && errorState["ic_type"] && isEdit
                              ? errorState["ic_type"]
                              : ""}
                          </p>
                        </div>
                        <div
                          className={`form-group id col-xl-8 col-lg-8 ${
                            errorState["ic_number"] && isEdit
                              ? "hasValidationError"
                              : ""
                          }`}
                        >
                          <div className="label">Document ID</div>
                          <input
                            type="text"
                            className="form-control"
                            name="ic_number"
                            value={updateFormState?.ic_number?.toUpperCase()}
                            id="id_number"
                            placeholder="ID Number"
                            onChange={(e) => {
                              onEditInputChange(e.target);
                            }}
                          />

                          <p className="error-message">
                            {errorState && errorState["ic_number"] && isEdit
                              ? errorState["ic_number"]
                              : ""}
                          </p>
                        </div>
                        <div
                          className={`form-group expiry col-xl-4 col-lg-4 ${
                            errorState["expiry"] && isEdit
                              ? "hasValidationError"
                              : ""
                          }`}
                        >
                          <div className="label">ID Expiry</div>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            minDate={moment().toDate()}
                            popperPlacement="top-end"
                            popperClassName="some-custom-class"
                            selected={getDayFirst(updateFormState.expiry)}
                            onChange={(date) =>
                              onEditInputChange({
                                name: "expiry",
                                value: date,
                              })
                            }
                            showMonthDropdown
                            useShortMonthInDropdown
                            showYearDropdown
                            popperModifiers={{
                              preventOverflow: {
                                enabled: true,
                              },
                            }}
                          />
                        </div>
                        <div className="form-group expiry col-xl-8 col-lg-8">
                          <div className="label">Document</div>
                          <div className="file-and-btn">
                            <label className="form-control file-control">
                              <input
                                id="id_file"
                                type="file"
                                name="id_file"
                                className="file"
                                onChange={(e) => getUpdateIdFile(e)}
                              />
                              <div className="fileName">
                                <span className="fileNameText">
                                  {updateIdFile?.name}
                                </span>
                              </div>
                              <div
                                type="button"
                                className="prompt btn btn-secondary btn-sm"
                              >
                                Upload
                              </div>
                            </label>
                            <button
                              type="submit"
                              className="delete btn btn-secondary btn-sm"
                            >
                              {!isLoading && <FaCheck />}
                              {isLoading && (
                                <span className="input-loader-container">
                                  <div
                                    className="spinner-border spinner-border-sm ml-1"
                                    role="status"
                                  ></div>
                                </span>
                              )}
                            </button>
                            <button
                              type="button"
                              className="delete btn btn-primary btn-sm"
                              onClick={() => hideEdit(id)}
                            >
                              <FaTimes />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            )}
          </>
        ))}

      {!isCreateNew && !isEdit && (
        <div className="create-new-btn">
          <button
            type="button"
            onClick={() => showHideCreateNew()}
            className="btn btn-primary btn-sm"
          >
            Add new document
          </button>
        </div>
      )}
      {isCreateNew && !isEdit && (
        <div className="id-wrapper create-row">
          <div className="id-row">
            <form onSubmit={createIC}>
              <div className="form-row w-100">
                <div
                  className={`form-group type col-xl-4 col-lg-4 ${
                    errorState["ic_type"] && !isEdit ? "hasValidationError" : ""
                  }`}
                >
                  <div className="label">Document Type</div>
                  <Select
                    isDisabled={isEdit}
                    className="formSelect"
                    classNamePrefix="react-select-form"
                    value={
                      formState.ic_type && formState.ic_type !== ""
                        ? getIdValues(formState.ic_type)
                        : { label: "Select", value: "" }
                    }
                    onChange={(e) =>
                      onInputChange({ name: "ic_type", value: e.value })
                    }
                    name="id_type"
                    options={getIdOptions()}
                  />

                  <p className="error-message">
                    {errorState && errorState["ic_type"] && !isEdit
                      ? errorState["ic_type"]
                      : ""}
                  </p>
                </div>
                <div
                  className={`form-group id col-xl-8 col-lg-8  ${
                    errorState["ic_number"] && !isEdit
                      ? "hasValidationError"
                      : ""
                  }`}
                >
                  <div className="label">Document ID</div>
                  <input
                    type="text"
                    className="form-control id-number"
                    name="ic_number"
                    value={formState?.ic_number?.toUpperCase()}
                    id="id_number"
                    placeholder="ID Number"
                    disabled={isEdit}
                    onChange={(e) => {
                      onInputChange(e.target);
                    }}
                  />
                  <p className="error-message">
                    {errorState && errorState["ic_number"] && !isEdit
                      ? errorState["ic_number"]
                      : ""}
                  </p>
                </div>
                <div
                  className={`form-group expiry col-xl-4 col-lg-4  ${
                    errorState["expiry"] && !isEdit ? "hasValidationError" : ""
                  }`}
                >
                  <div className="label">ID Expiry</div>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="DD/MM/YYYY"
                    minDate={moment().toDate()}
                    popperPlacement="top-end"
                    popperClassName="some-custom-class"
                    selected={getDayFirst(formState.expiry)}
                    onChange={(date) =>
                      onInputChange({
                        name: "expiry",
                        value: date,
                      })
                    }
                    showMonthDropdown
                    useShortMonthInDropdown
                    showYearDropdown
                    popperModifiers={{
                      preventOverflow: {
                        enabled: true,
                      },
                    }}
                    disabled={isEdit}
                  />
                  <p className="error-message">
                    {errorState && errorState["expiry"] && !isEdit
                      ? errorState["expiry"]
                      : ""}
                  </p>
                </div>
                <div className="form-group expiry col-xl-8 col-lg-8">
                  <div className="label">Document</div>
                  <div
                    className={
                      isEdit ? "disabled file-and-btn" : "file-and-btn"
                    }
                  >
                    <label className="form-control file-control">
                      <input
                        disabled={isEdit}
                        id="id_file"
                        type="file"
                        name="id_file"
                        className="file"
                        onChange={(e) => getCreateIdFile(e)}
                      />
                      <div className="fileName">
                        <span className="fileNameText">
                          {createIdFile?.name}
                        </span>
                      </div>
                      <div
                        type="button"
                        className="prompt btn btn-secondary btn-sm"
                      >
                        Upload
                      </div>
                    </label>
                  </div>
                </div>

                <div className="create-new-btn">
                  <button
                    type="submit"
                    disabled={isEdit}
                    className="add btn btn-primary btn-sm"
                    onClick={() => showHideCreateNew()}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    disabled={isEdit}
                    className="add btn btn-secondary btn-sm position-relative"
                  >
                    Submit
                    {isLoading && !isEdit && submitButtonPressed && (
                      <span className="button-loader-container">
                        <div
                          className="spinner-border spinner-border-sm ml-1 text-white"
                          role="status"
                        ></div>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdditionalIds;
