/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useEffect } from "react";
import _ from "lodash";
import { useQuery, useClient, useMutation } from "jsonapi-react";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { AppContext } from "../../context/AppContext";
import { addBodyClass, removeBodyClass } from "../../helpers/utils";
import axios from "axios";
import queryString from "query-string";
import ModalComponent from "../../components/common/ModalComponent/ModalComponent";
import { useToasts } from "react-toast-notifications";
import DISPLAY_TEXTS from "../../helpers/displayTexts";
import { useHistory } from "react-router-dom";

import { VW_ROUTES, API_BASE_URL, apiRoutes } from "../../constants";
import SearchBar from "../../components/common/Search/Search";

import ProfileCard from "../../components/ProfileCard/ProfileCard";
import TableExpandableComponent from "../../components/common/TableExpandableComponent/ValidityExpiryExpansion";
import { columnSetter } from "../../helpers/columnSetter";
import ExportButton from "../../components/common/Export/Export";
import moment from "moment-timezone";
import { columnData } from "../ApplicantsList/dummyColumns";
import {
  checkModuleViewPermission,
  formatTextWithFallback,
  getCategoryType,
} from "../../helpers/helpers";
import useStorage from "../../helpers/useStorage";
import ValidityExpiryStatusFilter from "./ValidityExpiryStatusFilter";

const Staff = ({ location }) => {
  const { setToStorage, getFromStorage } = useStorage();
  const { addToast } = useToasts();
  let params = queryString.parse(location.search);
  // const [isArchive, setIsArchive] = React.useState(false);
  const [currentUserId, setCurrentUserId] = React.useState("0");
  const [showLoader, setShowLoader] = React.useState(false);
  const [activeData, setAciveData] = React.useState({});

  const [openModal, setOpenModal] = React.useState(false);
  const [tempContract, setTempContract] = React.useState([]);
  const [filterApplied, setFilterApplied] = React.useState(false);
  const {
    showModal,
    manageModal,
    isArchive,
    initialPageParams,
    updateInitialPageParams,
    onRowSelect,
    archiveResult,
    doarchiveApplicants,
    getCategoryFromSlug,
    appOptions,
    userRoleDetails,
  } = React.useContext(AppContext);
  const [showDeactivateModal, setShowDeactivateModal] = React.useState(false);
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("staffs_rows_per_page") ?? 10
  );
  let pageParams = queryString.parse(location.search);
  const [searchQueryString, setSearchQueryString] = React.useState("");

  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(1);
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  const getCellCategoryType = (cellProfile) =>
    getCategoryType(
      getCategoryFromSlug(
        String(cellProfile?.personCategory ?? "").toLowerCase(),
        appOptions
      )?.slug
    );

  //for archive
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [archiveButtonPressed, setArchiveButtonPressed] = React.useState(false);
  //for archive ends
  let initialSearchKey;
  const searchWord = window.location.hash.split("search=")[1];
  if (searchWord) {
    initialSearchKey = decodeURI(searchWord);
  } else {
    initialSearchKey = "";
  }
  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }
  }, []);

  const [filter, setFilter] = React.useState({
    type: pageParams.type,
    search: initialSearchKey,
  });
  const [exportData, setexportData] = React.useState({
    page: "validity_expiring",
    params: params,
    filters: {
      type: pageParams.type,
      search: initialSearchKey,
    },
  });
  const [filterWithPhotos, setFilterWithPhotos] = React.useState({
    label: "Select",
    value: "",
  });
  const token = localStorage.getItem("access_token");

  const history = useHistory();
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const { data: contract } = useQuery(
    [
      apiRoutes.contract,
      {
        page: {
          number: 1,
          size: 3000,
        },
      },
    ],
    { client }
  );
  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  const staffs = useQuery(
    [
      "pass",

      "expiring",
      {
        filter: filter,
        page: paginationSettings,
        include: "applicant_photo,company",
      },
    ],
    { client }
  );

  const [blacklistApplicantProfile] = useMutation(
    apiRoutes.archiveProfile.replace("{profileId}", activeData.id),
    { method: "PUT", client }
  );
  const permenentDelete = async () => {
    manageModal(false);
    removeBodyClass("modal-open");
    setShowLoader(true);
    const { error } = await client.delete(
      apiRoutes.updateProfileData.replace("{personId}", currentUserId)
    );
    if (error === undefined) {
      staffs.refetch();
      addToast(DISPLAY_TEXTS.PERSON_PROFILE_DELETED, { appearance: "success" });
    } else {
      addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
    }
    setCurrentUserId(0);
    setShowLoader(false);
  };

  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }
  }, []);

  const parseToSelectFormat = (
    options,
    keys,
    hideAll = false,
    hideSelect = false
  ) => {
    let res = _.map(options, (option) => ({
      label: option[keys.label],
      value: option[keys.value],
    }));
    if (!hideAll) {
      res.unshift({ label: "All", value: "" });
    }
    if (!hideSelect) {
      res.unshift({ label: "Select", value: "" });
    }
    return res;
  };
  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(initialPageParams.initialPageNumber);
      setactivePage(initialPageParams.initialPageNumber);
      setSearchQueryString(initialPageParams.initialSearchKey);
    }
  }, [initialPageParams]);

  const setContractorValues = (opt) => {
    setTempContract(opt);
  };

  const updateStaff = (id) => {
    history.push({
      pathname: VW_ROUTES.APPLICANTS_STAFF_EDIT,
      search: `?id=${id}`,
    });
  };

  const viewStaff = (id, category) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${id}&type=${String(category ?? "").toLowerCase()}`,
      state: {
        from: history.location?.pathname,
      },
    });
  };

  const doCloseModal = () => {
    removeBodyClass("modal-open");
    manageModal(false);
    setShowDeactivateModal(false);
    setToggleClearRows(!toggledClearRows);
    onRowSelect({
      selectedRows: [],
    });
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };

  const popCloseModal = (data) => {
    setOpenModal(false);
    setAciveData({});
  };

  const getEvent = (mode) => {
    if (mode.mode === "edit") {
      updateStaff(mode.staff.personSystemId);
    }

    if (mode.mode === "delete") {
      manageDeletedOption(mode.staff.personSystemId);
    }

    if (mode.mode === "view") {
      viewStaff(mode.staff.personSystemId, mode?.staff?.personCategory);
    }
    if (mode.mode === "activate") {
      popConfirmModal(mode.staff);
    }
  };
  const popConfirmModal = (data) => {
    setAciveData(data);
    setOpenModal(true);
  };

  const columns = [
    {
      ...columnSetter("fullName", columnData),
      selector: (pass) => pass?.personFullName,

      cell: (staff) => (
        <ProfileCard
          data={staff}
          mode="vs_expiry"
          cellMode={getCellCategoryType(staff)}
          actions={userRoleDetails}
          fields={[
            {
              value: staff?.personCompany,
              name: columnSetter("company", columnData).name,
            },
            {
              value: staff?.contract,
              name: columnSetter("contract", columnData).name,
            },
            {
              value: String(staff?.personIdentification ?? "")?.toUpperCase(),
              name: `ID Number`,
            },
            {
              value: staff?.toDateTime
                ? moment(staff?.toDateTime).format("DD-MM-YYYY")
                : "--",
              name: `Expiry Date`,
            },
          ]}
          actionsToParent={(mode) => getEvent({ mode, staff })}
        />
      ),
    },
    {
      ...columnSetter("company", columnData),
      selector: (pass) => pass?.personCompany,
      hide: "md",
      cell: (applicant) => (
        <>
          <span className="text-uppercase">
            {formatTextWithFallback(applicant?.personCompany)}
          </span>
        </>
      ),
    },
    {
      ...columnSetter("contract", columnData),
      selector: (pass) => pass?.contract,
      cell: (applicant) => (
        <>
          <span className="text-uppercase">
            {formatTextWithFallback(applicant?.contract)}
          </span>
        </>
      ),
      hide: "md",
    },

    {
      ...columnSetter("idNumber", columnData),
      selector: (pass) => pass?.personIdentification,
      cell: (applicant) => (
        <span className="text-capitalize text-left">
          {formatTextWithFallback(
            String(applicant?.personIdentification ?? "")?.toUpperCase()
          )}
        </span>
      ),
      hide: "md",
    },

    {
      ...columnSetter("expiryDate", columnData),
      hide: "md",

      selector: (pass) => pass?.toDateTime,
      cell: (applicant) => (
        <>
          {applicant?.toDateTime
            ? formatTextWithFallback(
                moment(applicant?.toDateTime).format("DD-MM-YYYY")
              )
            : "--"}
        </>
      ),
    },
    {
      name: "Actions",
      sortable: false,
      center: true,
      maxWidth: 100,
      minWidth: 100,
      cell: (staff) => (
        <>
          {checkModuleViewPermission(
            userRoleDetails,
            getCellCategoryType(staff),
            "profile",
            staff
          ) && (
            <span
              className="simple-icon-eye user-role-action-icons view"
              onClick={() =>
                viewStaff(staff.personSystemId, staff?.personCategory)
              }
            ></span>
          )}
        </>
      ),
      right: true,
      hide: "md",
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({ staffs_rows_per_page: rows }, false);
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setFilter({ ...filter, search: searchQuery });

    setexportData({
      page: "validity_expiring",
      params: params,
      filters: {
        ...filter,

        search: searchQuery,
      },
    });
    setactivePage(1);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }

      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };

  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  /// Archive users

  const onSelectRows = (rows) => {
    onRowSelect(rows);
  };

  const deleteUserDetails = async () => {
    if (isArchive) {
      doarchiveApplicants();
      setArchiveButtonPressed(true);
      doCloseModal();
    } else {
      permenentDelete();
    }
  };

  const manageDeletedOption = (item) => {
    setCurrentUserId(item);
    onRowSelect({
      selectedRows: [item],
    });
    addBodyClass("modal-open");
    manageModal(true);
  };

  React.useEffect(() => {
    if (archiveButtonPressed) {
      if (archiveResult) {
        addToast(DISPLAY_TEXTS.APPLICANT_ARCHIVED, { appearance: "success" });
        staffs.refetch();
      } else {
        addToast(DISPLAY_TEXTS.SOMETHING_WRONG, { appearance: "warning" });
      }
    }
    setArchiveButtonPressed(false);
    setToggleClearRows(!toggledClearRows);
    onRowSelect({
      selectedRows: [],
    });
  }, [archiveResult]);

  const confirmAcivationManagement = () => {
    setOpenModal(false);
    changeActiveStatus();
  };

  const changeActiveStatus = async () => {
    const { data, error } = await blacklistApplicantProfile({
      deactivate: activeData.status === "deactivated" ? false : true,
      id: activeData.id,
    });
    if (data) {
      addToast(
        activeData.status === "deactivated"
          ? DISPLAY_TEXTS.APPLICANT_PROFILE_UNBLOCKED
          : DISPLAY_TEXTS.APPLICANT_PROFILE_BLOCKED,
        { appearance: "success" }
      );
    } else {
      addToast(error.detail, { appearance: "error" });
    }
    setAciveData({});
  };
  /// Archive users ends

  const getFilterValues = (v) => {
    let val = [];
    v.forEach((item) => {
      if (item.value && item.value !== "") {
        val.push(item.value);
      }
    });
    return val;
  };
  const handleResetFilter = () => {
    setCurrentPageNumber(1);
    setFilterApplied(false);

    setTempContract("");
    setFilterWithPhotos({ label: "Select", value: "" });
    setSearchQueryString("");

    setFilter({ type: pageParams.type });
    setexportData({
      page: "validity_expiring",
      params: params,
      filters: {
        type: pageParams.type,
      },
    });
  };
  const handleApplyFilter = () => {
    setCurrentPageNumber(1);
    showFilter(false);

    setFilter({
      type: pageParams.type,

      ...(tempContract && tempContract.length > 0
        ? { contract: getFilterValues(tempContract) }
        : {}),

      ...(searchQueryString && searchQueryString !== ""
        ? { search: searchQueryString }
        : {}),
    });
    if (
      (tempContract && tempContract.length > 0) ||
      filterWithPhotos?.value !== "" ||
      (searchQueryString && searchQueryString !== "")
    ) {
      setFilterApplied(true);
    }

    setexportData({
      page: "validity_expiring",
      params: params,
      filters: {
        type: pageParams.type,

        ...(tempContract && tempContract.length > 0
          ? { contract: getFilterValues(tempContract) }
          : {}),

        ...(searchQueryString && searchQueryString !== ""
          ? { search: searchQueryString }
          : {}),
      },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header">
            <h1>{`Access Pass Expiry - ${
              pageParams?.type ? _.startCase(pageParams?.type) : ""
            }`}</h1>
            <div className="applicantFilter">
              <div className="desktopSearch">
                <SearchBar
                  onSearchInputChange={onSearchInputChange}
                  popstateText={searchQueryString}
                />
              </div>
              <div
                className={
                  showMobileDataFilter ? "table-filter active" : "table-filter"
                }
              >
                <div
                  className="filter-hide"
                  onClick={() => showFilter(false)}
                ></div>
                <div className="filter-form">
                  <div className="mobile-search">
                    <SearchBar
                      onSearchInputChange={onSearchInputChange}
                      popstateText={searchQueryString}
                    />
                  </div>
                  <h4 className="advanced-filter-heading">Advanced Search</h4>
                  <ValidityExpiryStatusFilter
                    contractValue={tempContract}
                    handleContractChange={setContractorValues}
                  />
                  <div className="d-flex w-100 align-items-center justify-content-end mt-3">
                    <button
                      className="btn btn-primary mr-2"
                      onClick={(e) => {
                        handleResetFilter(e);
                      }}
                    >
                      Reset
                    </button>
                    <button
                      className="btn btn-secondary m-0"
                      onClick={(e) => {
                        handleApplyFilter(e);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`tableFilterButton rightAlign  ${
                  filterApplied ? "btn-secondary" : ""
                }`}
                title="Advance Search"
                onClick={() => showFilter(true)}
              >
                <i className="iconsminds-filter-2"></i>
              </div>

              <ExportButton
                exportDetails={exportData}
                disableExport={staffs?.data?.length > 0 ? false : true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                staffs.isLoading || staffs.isFetching || showLoader
                  ? "card-body dataTableOuter ProfileWidth StaffData table-loader"
                  : "card-body dataTableOuter ProfileWidth StaffData"
              }
            >
              <DataTable
                subHeader={true}
                onSelectedRowsChange={onSelectRows}
                clearSelectedRows={toggledClearRows}
                columns={columns}
                data={staffs.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                progressPending={
                  staffs.isLoading || staffs.isFetching || showLoader
                }
                progressComponent={<p>&nbsp;</p>}
                paginationTotalRows={
                  staffs.meta && staffs.meta.pagination.count
                }
                paginationDefaultPage={activePage}
                expandableRows
                expandableRowsComponent={TableExpandableComponent}
              />
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        show={showModal}
        header={<b>Confirm {isArchive ? "Archive" : "Delete"}</b>}
        content={
          <p>
            Are you sure you want to {isArchive ? "archive" : "delete"} the
            selected profile details ?
          </p>
        }
        onSubmitCallback={deleteUserDetails}
        onCloseCallback={doCloseModal}
        submitButtonText={isArchive ? "Archive" : "Delete"}
        customClass="delete"
      />
      <ModalComponent
        show={showDeactivateModal}
        header={<b>Confirm Deactivate</b>}
        content={
          <p>Are you sure you want to deactivate the selected profile(s) ?</p>
        }
        onSubmitCallback={doCloseModal}
        onCloseCallback={doCloseModal}
      />
      <ModalComponent
        show={openModal}
        header={`${
          activeData.status === "deactivated"
            ? "Activate Profile"
            : "Deactivate Profile"
        }`}
        content={`Do you want to  ${
          activeData.status === "deactivated" ? "activate" : "deactivate"
        } User Profile!`}
        showSubmit={true}
        onSubmitCallback={() => confirmAcivationManagement()}
        onCloseCallback={() => {
          popCloseModal();
        }}
        cancelButtonText={"No"}
        submitButtonText={"Yes"}
      />
    </>
  );
};

export default Staff;
