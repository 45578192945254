import React from "react";

import ActionsTree from "../ActionsTree/ActionsTree";
import Select from "react-select";
const UserRoleFormComponent = ({
  title,
  formState,
  payloadData,
  systemResources,
  updateInputChange,
  updatePermissions,
  dashboardTypes,
}) => {
  const getDashBoardOptions = () => {

    let dashBoardArray = [
      {
        label: "Select Dashboard",
        value: "",
      },
    ];
    if (dashboardTypes && dashboardTypes.length > 0) {
      dashboardTypes.forEach((item, i) => {
        dashBoardArray.push({
          label: item.display_name,
          value: item.value,
        });
      });
    }
    return dashBoardArray;
  };
  return (
    <>
      <p>{title}</p>
      <div className="form-group position-relative">
        <label>
          <b>Role Name</b>
        </label>
        <input
          type="text"
          name="name"
          value={formState.name}
          className="form-control"
          placeholder="Role Name"
          onChange={(e) => updateInputChange(e.target)}
        />
      </div>
      <div className="form-group position-relative">
        <label>
          <b>Dashboard</b>
        </label>
        <Select
         
          className="cutomSelect"
          classNamePrefix="react-select"
          value={formState.dashboard}
          name="dashboard"
          options={getDashBoardOptions()}
          onChange={(e) => updateInputChange({ name: "dashboard", value: e })}
        />
      </div>
      <div className="form-group position-relative">
        <label>
          <b>Permissions</b>
        </label>
      </div>
      <ActionsTree
        treePayload={payloadData}
        actionsTreeData={systemResources}
        updatePermissionsSelected={updatePermissions}
      />
    </>
  );
};

export default UserRoleFormComponent;
