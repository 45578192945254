import React from "react";
import { AppProvider } from "../context/AppContext";
import DataFetchingComponent from "./DataFetchingComponent";
import { ToastProvider } from "react-toast-notifications";

const AppLayout = (props) =>
{
  return (
    <AppProvider>
      <ToastProvider autoDismissTimeout={5000} autoDismiss={true}>
        <DataFetchingComponent pageCode={props.pageCode}>
          {props.children}
        </DataFetchingComponent>
      </ToastProvider>
    </AppProvider>
  );
};

export default AppLayout;
