import React, { useContext } from "react";


import { AppContext } from "../../context/AppContext";
import DashLineChart from "../Charts/dashLineChart";
import { useToasts } from "react-toast-notifications";
import Axios from "axios";
import { mapWeeklyVisitData, weeklyVisitDataSetGenerator } from "../../mappers/dashboardMappers";
import ReactTooltip from "react-tooltip";
import { isWidgetRefreshable } from "../../helpers/helpers";
import _ from "lodash";

const dataKeys = ["Staff", "Frequent Delivery", "Visitor", "Delivery Adhoc"]

const DashboardChartArea = ({ widget }) => {
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();

  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const [weeklyVisitData, setweeklyVisitData] = React.useState(undefined);
  const [weeklyGraphData, setweeklyGraphData] = React.useState(undefined);
  const [weekChartType, setweekChartType] = React.useState("sNdLine");
  const [weeklyGraphMaxim, setWeeklyGraphMaxim] = React.useState(0);
  const [weeklyGraphMinim, setWeeklyGraphMinim] = React.useState(0);
  const [rawDataArray, setRawDataArray] = React.useState([]);

  const token = localStorage.getItem("access_token");
  const config = {
    headers: {
      Authorization: `JWT ${token}`,
      "content-type": "multipart/form-data",
    },
  };

  const apiUrl =
    widget.widget.config.dataSource === "external"
      ? widget.widget.config.url
      : apiRoutes.dashboardWidgetsData + "/" + slug;

  const fetchData = () => {
    Axios.get(apiUrl, config)
      .then((response) => {
        if(!_.isEmpty(response?.data?.data)){
          const apiData = Object.fromEntries(
            dataKeys?.map((key) => [key, response?.data?.data[key] ?? []])
          );
          setweeklyVisitData(mapWeeklyVisitData(apiData));
        }else {
          setweeklyVisitData({});
        }
      })
      .catch((error) => {
        setweeklyVisitData({});
        console.log(error);
      });
  };

  const updateData = () => {
    setIsUpdateLoading(true);
    Axios.get(apiUrl + "/update", config)
      .then((response) => {
        addToast(response?.data?.data?.message ?? "Widget updated Successfully!", { appearance: "success" });
      })
      .catch((error) => {
        addToast(error.detail ?? "Failed to update widget!", { appearance: "error" });
      }).finally(() => setIsUpdateLoading(false));
  }

  React.useEffect(() => {
    fetchData();
    if (
      widget?.widget?.config?.refreshRate &&
      widget?.widget?.config?.refreshRate > 0
    ) {
      const timeoutId = setInterval(() => {
        fetchData();
      }, widget?.widget?.config?.refreshRate * 1000);
      return () => {
        clearInterval(timeoutId);
      };
    }
  }, [widget]);


  const getMonthGraphData = (switchData, type) => {
    let dataClone;
    setweekChartType(switchData);
    if (weeklyVisitData?.graphData) {
      dataClone = { ...weeklyVisitData.graphData };
    }

    if (dataClone) {
      if (switchData === "vNd" || switchData === "vNdLine") {
        const filterBy = dataClone.datasets?.filter((item) => {
          return item.label === "Visitor" || item.label === "Delivery Adhoc";
        });
        dataClone.datasets = filterBy;
      } else {
        const filterBy = dataClone.datasets?.filter((item) => {
          return item.label === "Staff" || item.label === "Frequent Delivery";
        });
        dataClone.datasets = filterBy;
      }

      let dataArray = [];
      if (dataClone.datasets.length > 0) {
        dataClone.datasets.map((item) => {
          return item.data.map((value) => {
            return dataArray.push(value);
          });
        });
      }

      setWeeklyGraphMaxim(_.max(dataArray));
      setWeeklyGraphMinim(_.min(_.reject(dataArray, (dataItem) => !dataItem)));
      setRawDataArray(dataArray);
      setweeklyGraphData(dataClone);

    }
  };

  const chartOptions = {
    stepSize: Math.floor(500),
    // min: 0,
    // max: weeklyGraphDataMaxim,
    padding: 20,
  };

  React.useEffect(() => {
    if(weeklyVisitData !== undefined){
      if (weeklyVisitData?.graphData?.datasets?.length) {
        getMonthGraphData("sNdLine", "weekly");
      } else { setweeklyGraphData({}); }
    }
    // eslint-disable-next-line
  }, [weeklyVisitData]);

  return (
    <>
        <div className="card">
          <div className="card-header resp-header">
            <div className="graph-title">
              PEOPLE ENTRY OVERVIEW
            </div>
            <div className="d-flex align-items-center">
              <ul className="nav nav-tabs graph-tab float-right">
                <li>
                  <a href="#home" data-toggle="tab" className="active">
                    Last 7 Days
                  </a>
                </li>
              </ul>
              {isWidgetRefreshable(userRoleDetails, widget) && 
                <span 
                  onClick={() => {!isupdateLoading && updateData()}}
                  className={`refresh-button ml-1 ${isupdateLoading ? "spin" : ""}`} 
                  data-tip="Update Data" >
                    <i className="iconsminds-refresh" />
                </span>
              }
            </div>
          </div>
          <ReactTooltip />
          <div className="dashboard-pchart p-2 pt-3 pr-3 dashbrd-btm-graph widget-data-container">
            <div className="tab-content h-100 overflow-hidden">
              <div className="tab-pane fade in active show h-100">
                <DashLineChart
                  chartOptions={chartOptions}
                  shadow
                  dataArray={rawDataArray}
                  data={weeklyGraphData ?? []}
                  chartType={weekChartType}
                />
                <div className="btn-holder">
                  <button
                    className={weekChartType === "sNdLine" ? "active" : ""}
                    onClick={() => getMonthGraphData("sNdLine", "weekly")}
                  >
                    Staff & Frequent Delivery
                  </button>

                  <button
                    className={weekChartType === "vNdLine" ? "active" : ""}
                    onClick={() => getMonthGraphData("vNdLine", "weekly")}
                  >
                    Visitor & Delivery Adhoc
                  </button>
                </div>
              </div>
            </div>
            {((weeklyVisitData !== undefined) 
              && (_.isEmpty(weeklyVisitData) || !weeklyVisitData?.graphData?.datasets?.length)) 
              && <div className="no-data-overlay"><span>No Data Available!</span></div>
            }
          </div>
        </div>
    </>
  );
};
export default DashboardChartArea;
