import React, { useContext, useEffect } from "react";
import BarChart from "../Charts/dashBarChart";
import { chartTooltip } from "../Charts/util";
import _ from "lodash";
import { AppContext } from "../../context/AppContext";
import { useToasts } from "react-toast-notifications";
import Axios from "axios";
import ReactTooltip from "react-tooltip";
import { isWidgetRefreshable } from "../../helpers/helpers";
import { getLastMonths } from "../../helpers/utils";

const DashboardLPRChartBar = ({ widget }) => {
  const { userRoleDetails } = useContext(AppContext);
  const { addToast } = useToasts();

  const [isupdateLoading, setIsUpdateLoading] = React.useState(false);
  const [monthlyVisitData, setMonthlyVisitData] = React.useState(undefined);
  const [monthGraphData, setmonthGraphData] = React.useState(undefined);
  const [monthGraphMaxim, setMonthGraphMaxim] = React.useState(0);
  const [barchartStepSize, setbarchartStepSize] = React.useState(100);
  const [rawDataArray, setRawDataArray] = React.useState([]);




  const getMonthGraphData = (type) => {
    let dataClone;
    if (monthlyVisitData.graphData) {
      dataClone = { ...monthlyVisitData.graphData };

      setbarchartStepSize(100);
    }

    if (dataClone) {
      const filterBy = dataClone.datasets;
      dataClone.datasets = filterBy;

      if (!dataClone?.labels?.length)
        dataClone.labels = getLastMonths(3).map((item) => item?.substr(0, 3))?.reverse();
      if(dataClone?.datasets?.length) {
        dataClone.datasets = dataClone?.datasets?.map((dataItem) => {
          if(!dataItem?.data || !dataItem?.data?.length) 
            return ({...dataItem, data: Array(dataClone?.labels?.length ?? 0).fill(0)});
          return { ...dataItem };
        });
      }

      let dataArray = [];
      if (dataClone.datasets.length > 0) {
        dataClone.datasets.map((item) => {
          return item.data.map((value) => {
            return dataArray.push(value);
          });
        });
      }

      setRawDataArray(dataArray);
      setmonthGraphData(dataClone);
      setMonthGraphMaxim(_.max(dataArray));
    }
  };

  const barChartOptions = {
    legend: {
      position: "top",
      align: "end",
      labels: {
        padding: 30,
        usePointStyle: true,
        fontSize: 12,
      },
    },
    with: "100%",
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            lineWidth: 1,
            color: "rgba(0,0,0,0.1)",
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            stepSize: Math.floor(barchartStepSize),
            min: 0,
            max: monthGraphMaxim,
            padding: 20,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: chartTooltip,
    chart: {
      height: "auto",
      width: "auto"
    },
  };

const token = localStorage.getItem("access_token");
  const axiosOptions = (method, url) => {
    return {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Authorization: `JWT ${token}`,
      },
      url: `${url}`,
    };
  };

  const vehicleMonthCountFetcher = (url) => {
    Axios(axiosOptions("GET", `${url}`)).then((response) => {
      if (!_.isEmpty(response?.data?.data)){
        setMonthlyVisitData({
          graphData: {
            datasets: [
              {
                label: "Vehicles",
                data: getVehicleMonthDataValues(response?.data?.data),
                backgroundColor: "#ff816b",
                //   borderColor: "#ff816b",
                borderWidth: 2,
              },
            ],
            labels: getVehicleMonthDataLabel(response?.data?.data),
          },
        });
      } else {
        setMonthlyVisitData({});
      }

    }).catch(() => {setMonthlyVisitData({})});
  };

  const updateData = () => {
    setIsUpdateLoading(true);
    const config = {
      headers: {
        Authorization: `JWT ${token}`,
        "content-type": "multipart/form-data",
      },
    };
    const apiUrl =
      widget.widget.config.dataSource === "external"
        ? widget.widget.config.url
        : apiRoutes.dashboardWidgetsData + "/" + slug;

    Axios.get(apiUrl + "/update", config)
      .then((response) => {
        addToast(response?.data?.data?.message ?? "Widget updated Successfully!", { appearance: "success" });
      })
      .catch((error) => {
        addToast(error.detail ?? "Failed to update widget!", { appearance: "error" });
      }).finally(() => setIsUpdateLoading(false));
  }


React.useEffect(() => {
  if(monthlyVisitData !== undefined){
    if (monthlyVisitData?.graphData?.datasets?.length) {
      getMonthGraphData("monthly");
    } else { setmonthGraphData({}); }
  }
}, [monthlyVisitData]);

  React.useEffect(()=>{
    if (widget){
      const apiUrl =
        widget.widget.config.dataSource === "external"
          ? widget.widget.config.url
          : apiRoutes.dashboardWidgetsData + "/" + slug;

      vehicleMonthCountFetcher(apiUrl);

      if (
        widget?.widget?.config?.refreshRate &&
        widget?.widget?.config?.refreshRate > 0
      ) {
        const timeoutId = setInterval(() => {
          vehicleMonthCountFetcher(apiUrl);
        }, widget?.widget?.config?.refreshRate * 1000);
        return () => {
          clearInterval(timeoutId);
        };
      }
    }
  },[widget]);


  const getVehicleMonthDataValues = (data) => {
    let datas = [];
    data.forEach((item) => {
      datas.push(item.visits);
    });
    return datas;
  };


  const getVehicleMonthDataLabel = (data) => {
    let labels = [];
    data.forEach((item) => {
      labels.push(`${item.monthString}`);
    });

    return labels;
  };

  return (
    <>

        <div className="card">
          <div className="card-header resp-header">
            <div className="graph-title">
              VEHICLE ENTRY OVERVIEW
            </div>
            <div className="d-flex align-items-center">
              <ul className="nav nav-tabs graph-tab float-right">
                <li>
                  <a data-toggle="tab" className="active">
                    Last 3 Months
                  </a>
                </li>
              </ul>
              {isWidgetRefreshable(userRoleDetails, widget) &&
                <span
                  onClick={() => {!isupdateLoading && updateData()}}
                  className={`refresh-button ml-1 ${isupdateLoading ? "spin" : ""}`}
                  data-tip="Update Data" >
                    <i className="iconsminds-refresh" />
                </span>
              }
            </div>
          </div>
          <ReactTooltip />
          <div className="dashboard-pchart p-2 pt-3 pr-3 dashbrd-btm-graph widget-data-container">
            <div className="tab-content h-100 overflow-hidden">
              <div id="menu4" className="tab-pane fade in active show h-100">
                <BarChart
                  chartOptions={barChartOptions}
                  data={monthGraphData === undefined ? [] : monthGraphData}
                  dataArray={rawDataArray}
                />
              </div>
            </div>
            {((monthlyVisitData !== undefined) && (_.isEmpty(monthlyVisitData) || !monthlyVisitData?.graphData?.datasets?.length)) &&
              <div className="no-data-overlay"><span>No Data Available!</span></div>
            }
          </div>
        </div>
    </>
  );
};
export default DashboardLPRChartBar;
