/* eslint react-hooks/exhaustive-deps: 0 */
import React, { useContext, useEffect } from "react";
import { useQuery, useClient } from "jsonapi-react";

import { DEVICE_SERVICE_URL, apiRoutes } from "../../constants";

import DashboardLayout from "./DashboardLayout";
import Widget from "./Widget";
import { AppContext } from "../../context/AppContext";
import DashboardLoader from "./DashboardLoader";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const Dashboard = () => {
  const { activePageCode, activePagePath } = useContext(AppContext);
  const location = useLocation();
  const [layoutConfig, setLayoutConfig] = React.useState();
  const token = localStorage.getItem("access_token");
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };

  const { data: dashboardLayout } = useQuery(location.pathname === activePagePath && `${apiRoutes.dashboardLayout}${activePageCode ?? ""}`, {
    client,
  });

  useEffect(()=>{
    if(dashboardLayout){
      const widgetColumns = dashboardLayout?.layout?.config?.rows.map((row) =>
        row.columns.map(() => [])
      );

      dashboardLayout.widgets.forEach((widget) => {
        const [row, column] = widget.position.split(",");
        widgetColumns[row - 1][column - 1].push(
          <Widget
            title={widget.widget.title}
            slug={widget.widget.slug}
            widgetData={widget}
          />
        );
      });
      const finalLayout = dashboardLayout?.layout?.config?.rows.map(
        (row, rowIndex) => ({
          columns: row.columns.map((column, columnIndex) => ({
            size: column.size,
            widgets: widgetColumns[rowIndex][columnIndex],
          })),
        })
      );
      setLayoutConfig(finalLayout);
    }
  },[dashboardLayout])
  return (
    <>
      {layoutConfig?
        <DashboardLayout config={layoutConfig} />:<DashboardLoader/>
      }
    </>
  );
};

export default Dashboard;
