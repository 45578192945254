import React from "react";
import { UserGroupReducer, ACTION_TYPES } from "../reducers/UserGroupReducer";

const initialState = {
  permissionsSelected: [],
};

export const UserGroupContext = React.createContext();
export const UserGroupProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(UserGroupReducer, initialState);
  const updatePermissionsSelected = (permissions) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_PERMISSIONS_SELECTED,
      payload: permissions,
    });
  };

  return (
    <UserGroupContext.Provider
      value={{
        permissionsSelected: state.permissionsSelected,
        updatePermissionsSelected,
      }}
    >
      {children}
    </UserGroupContext.Provider>
  );
};
