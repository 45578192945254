import { manageSlidesData } from "../mappers/dashboardMappers";

export const ACTION_TYPES = {
  UPDATE_SUMUP_COUNTERS: "updateSumupCounters",
  UPDATE_SPLITUP_COUNTERS: "updateSplitUpCounters",
  UPDATE_CONTRACTOR_CAROUSEL_LISTS: "updateContractorCarouselLists",
  UPDATE_PENDING_APPROVALS: "updatePendingApprovals",
  UPDATE_PENDING_PASS_APPROVALS: "updatePendingPassApprovals",
};

export const DashboardReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_SUMUP_COUNTERS:
      return {
        ...state,
        sumUpCounters: action.payload,
      };
    case ACTION_TYPES.UPDATE_SPLITUP_COUNTERS:
      return {
        ...state,
        splitUpCounters: action.payload,
      };
    case ACTION_TYPES.UPDATE_CONTRACTOR_CAROUSEL_LISTS:
      return {
        ...state,
        contractorCarouselLists: manageSlidesData(action.payload),
      };
    case ACTION_TYPES.UPDATE_PENDING_APPROVALS:
      return {
        ...state,
        splitUpPendingApprovals: action.payload,
      };
    case ACTION_TYPES.UPDATE_PENDING_PASS_APPROVALS:
      return {
        ...state,
        splitUpPendingPassApprovals: action.payload,
      };
    default:
      return state;
  }
};
