/* eslint react-hooks/exhaustive-deps: 0 */
import React from "react";

import { useQuery, useClient } from "jsonapi-react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { VW_ROUTES, apiRoutes } from "../../constants";
import queryString from "query-string";
import SearchBar from "../../components/common/Search/Search";
// import axios from 'axios';
// import { API_BASE_URL } from "../../constants";
import ExportButton from "../../components/common/Export/Export";
import { AppContext } from "../../context/AppContext";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import WorkPermitExpiryExpansion from "../../components/common/TableExpandableComponent/WorkPermitExpiryExpansion";
import { checkModuleViewPermission, formatTextWithFallback, getCategoryType } from "../../helpers/helpers";
import useStorage from "../../helpers/useStorage";
import ICExpiryExpansion from "../../components/common/TableExpandableComponent/ICExpiryExpansion";

const ExpiryStatus = ({ location }) => {
  const { setToStorage, getFromStorage } = useStorage();
  const { initialPageParams, updateInitialPageParams, setPageUrlParams, getCategoryFromSlug, appOptions, userRoleDetails } =
    React.useContext(AppContext);
  const [exportData, setexportData] = React.useState({});
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState("");
  const [pageParams, setPageParams] = React.useState(
    queryString.parse(location.search)
  );
  const [currentPageNumber, setCurrentPageNumber] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    getFromStorage("work_permit_expiry_rows_per_pge") || 10
  );
  const token = localStorage.getItem("access_token");
  const history = useHistory();
  const client = useClient();
  client.config.headers = {
    Authorization: `JWT ${token}`,
  };
  const [searchQueryString, setSearchQueryString] = React.useState("");
  const [searchGoingOn, setSearchGoingOn] = React.useState(false);
  const [activePage, setactivePage] = React.useState(0);

  const getCellCategoryType = (cellProfile) => getCategoryType(cellProfile?.personCategorySlug ?? cellProfile?.category?.slug) ?? "";


  const listType = {
    ["permit-expiring"]: "Work Permit Expiry",
    ["cidb-expiry"]: "CIDB Expiry",
    training: "Training Expiry",
    ["permit-expired"]: "Expired Work Permits",
  };
  const listName = {
    ["permit-expiring"]: "expiring",
    ["cidb-expiry"]: "cidb_expiry",
    training: "training_expiry_list",
    ["permit-expired"]: "expired",
  };
  const [showMobileDataFilter, setShowMobileDataFilter] = React.useState(false);

  let includes = "";
  let filters;
  let apiRoute = listName[pageParams?.list];

  if (
    listName[pageParams.list] &&
    listName[pageParams.list] === "training_expiry_list"
  ) {
    includes = "company,applicant_training,person_photo";
  }

  if (
    listName[pageParams.list] &&
    (listName[pageParams.list] === "expired" || listName[pageParams.list] === "expiring")
  ) {
    filters = {
      category: pageParams.type,
      ...(searchQueryString && searchQueryString !== ""
        ? { search: searchQueryString }
        : {}),
    };
    includes = "company,category,person_photo";
  } else if (
    listName[pageParams.list] &&
    (listName[pageParams.list] === "cidb_expiry")
  ) {
    filters = {
      person_cat: pageParams.type,
      ic_type: "cidb",
      ...(searchQueryString && searchQueryString !== ""
        ? { search: searchQueryString }
        : {}),
    };
    includes = "ic_type";
  }

  let paginationSettings;
  if (searchGoingOn) {
    paginationSettings = {
      number: 1,
      size: rowsPerPage,
    };
  } else {
    paginationSettings = {
      number: currentPageNumber,
      size: rowsPerPage,
    };
  }

  const staffs = useQuery( apiRoute &&
    [
      ...((listName[pageParams.list] === "cidb_expiry") 
        ? [apiRoutes.getICExpiry] 
        : [apiRoutes.getWorkPermit, apiRoute]
      ),
      {
        filter: filters,
        page: paginationSettings,
        include: includes,
      },
    ],
    { client }
  );

  let expiryColumn;
  if (
    listName[pageParams.list] &&
    listName[pageParams.list] === "training_expiry_list"
  ) {
    expiryColumn = {
      name: "Expiry Date",
      selector: "applicantTraining[0].expiresOn",
      sortable: true,
      hide: "md",
      width: 200,
      cell: (staff) => formatTextWithFallback(staff?.applicantTraining[0]?.expiresOn),
    };
  } else if (
    listName[pageParams.list] &&
    listName[pageParams.list] === "cidb_expiry") {
      expiryColumn = {
        name: "Expiry Date",
        selector: (staff) => staff.workPermitExpiry,
        sortable: true,
        hide: "md",
        width: 200,
        cell: (staff) => formatTextWithFallback(staff?.expiry),
      }
  }else {
    expiryColumn = {
      name: "Expiry Date",
      selector: (workPermitExpiry) => workPermitExpiry.workPermitExpiry,
      sortable: true,
      hide: "md",
      width: 200,
      cell: (staff) => formatTextWithFallback(staff?.workPermitExpiry),
    };
  }
  //

  const viewStaff = (id) => {
    history.push({
      pathname: VW_ROUTES.PERSON_PROFILE_VIEW,
      search: `?id=${id}&from=expiry&list=${pageParams.list}&type=${pageParams.type}`,
      state: {
        from: history.location?.pathname
      }
    });
  };
  React.useEffect(() => {
    if (location) {
      updateInitialPageParams(location);
    }
    let params = queryString.parse(location.search);
    setPageParams(params);
    setexportData({
      page: "expiry",
      params: pageParams,
      filters: {
        ...(searchQueryString && searchQueryString !== ""
          ? { search: searchQueryString }
          : {}),
      },
    });
  }, []);

  React.useEffect(() => {
    if (initialPageParams) {
      setCurrentPageNumber(initialPageParams.initialPageNumber);
      setactivePage(initialPageParams.initialPageNumber);
      setSearchQueryString(initialPageParams.initialSearchKey);
    }
  }, [initialPageParams]);

  React.useEffect(() => {
    setexportData({
      page: "expiry",
      params: pageParams,
      filters: {
        ...(searchQueryString && searchQueryString !== ""
          ? { search: searchQueryString }
          : {}),
      },
    });
  }, [searchQueryString, pageParams]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const getEvent = (mode) => {
    if (mode.mode === "view") {
      viewStaff(isCidb ? mode?.person?.personId : mode.person.id);
    }
  };

  const detailSelector = (field, personDetail) => {
    if(listName[pageParams.list] === "cidb_expiry") {
      switch(field) {
        case 'fullName': return personDetail?.personFullname;
        case 'contract': return personDetail?.contract;
        case 'company': return personDetail?.company;
        case 'nationality': return personDetail?.nationality;
        case 'designation': return personDetail?.designation;
        case 'expiryDate': return personDetail?.expiry;
        case 'icNumber' : return personDetail?.icNumber;
      }
    } else {
      switch(field) {
        case 'fullName': return personDetail?.fullName
        case 'contract': return personDetail?.contract?.name;
        case 'company': return personDetail?.company?.name;
        case 'nationality': return personDetail?.nationalityFull;
        case 'designation': return personDetail?.designation;
        case 'expiryDate': return listName[pageParams.list] &&
                                  listName[pageParams.list] === "training_expiry_list"
                                    ? personDetail?.applicantTraining[0]?.expiresOn
                                    : personDetail?.workPermitExpiry;
      }
    }
    return '--';
  }

  const isCidb = listName[pageParams?.list] === "cidb_expiry";

  const columns = [
    {
      name: "Full Name",
      selector: (personDetail) => detailSelector('fullName', personDetail),
      sortable: true,
      style: "text-transform:capitalize",
      minWidth: "300px",
      cell: (person) => (
        <ProfileCard
          data={person}
          mode={isCidb ? "statExp" : "staffExp"}
          actions={userRoleDetails}
          cellMode={getCellCategoryType(person)}
          fields={[
            ...( isCidb
              ? [
                  {
                    name: "Company",
                    value: detailSelector("company", person),
                  },
                  {
                    name: "ID Number",
                    value:  detailSelector("icNumber", person),
                  },
                ]
              : [
                  {
                    name: "Contract",
                    value: detailSelector("contract", person),
                  },
                  {
                    name: "Nationality",
                    value: detailSelector("nationality", person),
                  },
                  {
                    name: "Designation",
                    value: detailSelector("designation", person),
                  },
                ]
            ),
            {
              name: "Expiry Date",
              value: detailSelector('expiryDate', person)
            },
            ...(isCidb 
              ? [{
                name: "Document",
                value: {
                  document: person?.document,
                  documentName: person?.documentName,
                },
              }] : []
            )
          ]}
          actionsToParent={(mode) => getEvent({ mode, person })}
        />
      ),
    },
    ...( isCidb
      ? [
          {
            name: "Company",
            sortable: false,
            left: true,
            minWidth: "200px",
            cell: (staff) => (
              <>
                <span className="text-uppercase">
                  {formatTextWithFallback(detailSelector('company', staff))}
                </span>
              </>
            ),
            hide: "md",
          },
          {
            name: "ID Number",
            sortable: false,
            hide: "md",
            minWidth: "250px",
            cell: (staff) => <>{formatTextWithFallback(detailSelector('icNumber', staff))}</>,
          },
        ]
      : [
          {
            name: "Contract",
      
            sortable: false,
            left: true,
      
            minWidth: "200px",
            cell: (staff) => (
              <>
                <span className="text-uppercase">
                  {formatTextWithFallback(detailSelector('contract', staff))}
                </span>
              </>
            ),
            hide: "md",
          },
          {
            name: "Nationality",
            sortable: false,
            hide: "md",
            cell: (staff) => <>{formatTextWithFallback(detailSelector('nationality', staff))}</>,
          },
          {
            name: "Designation",
            sortable: false,
            hide: "md",
            cell: (staff) => <>{formatTextWithFallback(detailSelector('designation', staff))}</>,
          },
        ]
    ),
    expiryColumn,
    ...(isCidb ? [
      {
        name: "Document",
        sortable: false,
        hide:"md",
        minWidth: "200px",
        cell: (staff) => (
          <div className="file-name-wrap">
            {staff?.document ? (
              <a href={staff?.document} className="downloadLink" target="_blank">
                {formatTextWithFallback(staff?.documentName)}
              </a>
            ) : (
              "--"
            )}
          </div>
        ),
      }
    ] : []),
    {
      name: "Actions",
      sortable: false,
      cell: (staff) => (
        <>
          {checkModuleViewPermission(
            userRoleDetails,
            getCellCategoryType(staff),
            "profile",
            staff
          ) && (
            <span
              className="simple-icon-eye user-role-action-icons view"
              onClick={() => viewStaff(isCidb ? staff?.personId : staff.id)}
            ></span>
          )}
        </>
      ),
      center: true,
      width: 50,
      hide: "md",
    },
  ];

  let paginationGoing = false;
  const onChangePage = (page) => {
    setSearchGoingOn(false);
    paginationGoing = true;
    setCurrentPageNumber(page);
    const setParams = {
      page: page,
      ...((searchQueryString && String(searchQueryString ?? "").length) ? {searchQueryString: searchQueryString} : {}),
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  const onChangeRowsPerPage = (rows) => {
    setToStorage({"work_permit_expiry_rows_per_pge":rows},false);
    setRowsPerPage(rows);
  };

  let searchGoing = false;
  const onSearchInputChange = (searchQuery) => {
    searchGoing = true;
    setSearchGoingOn(true);
    setSearchQueryString(searchQuery);
    setactivePage(1);
    const setParams = {
      page: 1,
      ...((searchQuery && String(searchQuery ?? "").length) ? {searchQueryString: searchQuery} : {}),
      initialPageParams: initialPageParams,
    };
    setPageUrlParams(setParams);
  };

  window.onpopstate = (e) => {
    if (!searchGoing && !paginationGoing) {
      const searchWord = window.location.hash.split("search=")[1];
      const wordString = decodeURI(searchWord);
      if (searchWord) {
        setSearchQueryString(wordString);
      } else {
        setSearchQueryString("");
      }
      const pageNumber = window.location.hash.split("page=")[1];
      if (pageNumber) {
        const pageFromUrl = pageNumber.split("&")[0];
        setCurrentPageNumber(Number(pageFromUrl));
      } else {
        setCurrentPageNumber(1);
      }
    } else {
      searchGoing = false;
      paginationGoing = false;
    }
  };

  const closeReportSuccessPopup = () => {
    setShowSuccessModal(false);
    setShowSuccessMessage("");
  };
  const showFilter = (mode) => {
    setShowMobileDataFilter(mode);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-header withExport">
            <h1>
              {listType[pageParams.list]} - {getCategoryFromSlug(pageParams?.type, appOptions)?.display_name ?? ""}
            </h1>

            <div
              className={
                showMobileDataFilter ? "table-filter active" : "table-filter"
              }
            >
              <div
                className="filter-hide"
                onClick={() => showFilter(false)}
              ></div>
              <div className="filter-form">
                <h4>Advanced Search</h4>
                <div className="right-holder">
                  <SearchBar
                    onSearchInputChange={onSearchInputChange}
                    popstateText={searchQueryString}
                  />
                  <ExportButton
                    exportDetails={exportData}
                    disableExport={staffs?.data?.length > 0 ? false : true}
                  />
                </div>
              </div>
            </div>
            <div
              className="tableFilterButton right-fix"
              title="Advance Search"
              onClick={() => showFilter(true)}
            >
              <i className="iconsminds-filter-2"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="card">
            <div
              className={
                staffs.isLoading || staffs.isFetching
                  ? "card-body dataTableOuter expiryTable table-loader"
                  : "card-body dataTableOuter expiryTable"
              }
            >
              <DataTable
                subHeader={false}
                // subHeaderComponent={<><SearchBar onSearchInputChange={onSearchInputChange} popstateText={searchQueryString}/> {pageParams.list !== "expired"?<ExportButton exportDetails={exportData}/>:""}</>}
                progressPending={staffs.isLoading}
                progressComponent={<p>&nbsp;</p>}
                columns={columns}
                data={staffs.data}
                customStyles={customStyles}
                paginationServer={true}
                striped={true}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                pagination={true}
                paginationPerPage={rowsPerPage}
                paginationTotalRows={
                  staffs.meta && staffs.meta.pagination.count
                }
                paginationDefaultPage={activePage}
                expandableRows
                expandableRowsComponent={isCidb ? ICExpiryExpansion : WorkPermitExpiryExpansion}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={`reportStatusPopup ${showSuccessModal ? "show" : ""}`}>
        <div className="popUp">
          <div className="content">{showSuccessMessage}</div>
          <div className="action">
            <button
              className="btn btn-secondary"
              onClick={closeReportSuccessPopup}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpiryStatus;