export const columnData = {
  fullName: {
    min_width: 200,
    max_width: null,
    sortable: true,

    label: "Name",
    align: "left",
  },
  applicantType: {
    min_width: null,
    max_width: 150,
    sortable: false,

    label: "Type",
    align: "left",
  },
  company: {
    min_width: 200,
    max_width: null,
    sortable: false,

    label: "Company",
    align: "left",
  },
  contract: {
    min_width: 200,
    max_width: null,
    sortable: false,

    label: "Contract",
    align: "left",
  },
  nationality: {
    min_width: 200,
    max_width: null,
    sortable: false,

    label: "Nationality",
    align: "left",
  },
  passType: {
    min_width: 200,
    max_width: null,
    sortable: false,

    label: "Pass Type",
    align: "left",
  },
  designation: {
    min_width: 200,
    max_width: null,
    sortable: false,

    label: "Designation",
    align: "left",
  },

  idNumber: {
    min_width: 150,
    max_width: 250,
    sortable: false,

    label: "ID Number",
    align: "left",
  },

  validFrom: {
    min_width: 200,
    max_width: 200,
    sortable: false,

    label: "Valid From",
    align: "left",
  },
  validTo: {
    min_width: 200,
    max_width: 200,
    sortable: false,

    label: "Valid To",
    align: "left",
  },
};
